<coreui-modal modalWidth="60" modalHeight="90" #basicModal>
  <ng-template #titleTemplate>
    <h2> Change Log </h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <app-ae-button buttonType="icon" (click)="closeBasicModal()" icon="close"></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <div class="ChangeLogModal-content">
      <h4 class="mb-2">{{ programName }}</h4>
      <coreui-action-bar>
        <ng-template #leftTpl>
          <div class="ChangeLogModal-Form-wrapper">
            <form [formGroup]="changeLogForm" class="ChangeLogModal-Form">
              <div class="ChangeLogModal-DataSelection">
                <coreui-date-picker formControlName="startDate" [maxDate]="startDatePickerMaxDate$ | async"
                  [highlightToday]="false" #startDate></coreui-date-picker>
                <span class="DateSeparator">to</span>
                <coreui-date-picker formControlName="endDate" [minDate]="endDatePickerMinDate$ | async"
                  [maxDate]="endDateMaxDate" [highlightToday]="true" #endDate></coreui-date-picker>
                <button type="button" coreUIButton="primary" (click)="onAuditSearchByDate()" [disabled]="isDataLoading">Go</button>
              </div>
            </form>
          </div>
        </ng-template>
        <ng-template #rightTpl>
          <form [formGroup]="changeLogForm">
            <coreui-autocomplete #autocomplete name="autocomplete" placeholder="Search" formControlName="search"
                  (displayValueChange)="onSearchChange($event)">
            </coreui-autocomplete>
          </form>
      </ng-template>
      </coreui-action-bar>
        <coreui-table class="ChangeLogModal-Table" *ngIf="this.properties">
          <thead coreUITableSort>
            <tr coreUITableHeaderRow>
              <th *ngFor="let header of properties.columns">
                <coreui-table-header-column [disableSort]="true">
                  {{header.headerText}}
                </coreui-table-header-column>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let auditTable of auditHistoryTableData" coreUITableRow coreUIInfiniteScroll
            [scrollTarget]="basicModal.contentScrollRef" (scrollLimitReached)="onScrollLimitReached()">
              <td class="ChangeLogModal-Column-date">
                <div class="ChangeLogModal-Item-date">{{ auditTable.date | coreUILocaleDate }} at
                  {{ auditTable.date | coreUILocalTime }}</div>
                <div class="ChangeLogModal-Item-name">By {{ auditTable.userName }}</div>
              </td>
              <td class="ChangeLogModal-Column-details">
                <div class="ChangeLogModal-details-container">
                  <ng-container *ngFor="let activity of auditTable.activities">
                    <i [coreUIIcon]="activity.icon" class="ChangeLogModal-Item-icon"></i>
                    <div class="ChangeLogModal-Item-action">
                      <div class="ChangeLogModal-Primary">{{ activity.title }}</div>
                      <div class="ChangeLogModal-Secondary">
                        <div class="ChangeLogModal-Secondary-subtitle" *ngFor="let delta of activity.deltas">
                          <ng-container class="u-mb-5" *ngIf="delta">
                            <div *ngIf="delta.name">
                              <p><span>Name:</span> {{ delta.name }}</p>
                            </div>
                            <div *ngIf="isDeltaType(delta.from, 'string')">
                              <p><span>From:</span> {{ delta.from }}</p>
                            </div>
                            <div *ngIf="isDeltaType(delta.from, 'object')">
                              <p><span>From:</span></p>
                              <ng-container *ngFor="let pValue of delta.from | keyvalue">
                                <p><span class="u-mr-20">{{ pValue.key }} </span><span>{{ pValue.value }}</span></p>
                              </ng-container>
                            </div>
                            <div *ngIf="isDeltaType(delta.to, 'string')">
                              <p><span>To:</span> {{ delta.to }}</p>
                            </div>
                            <div *ngIf="isDeltaType(delta.to, 'object')">
                              <p><span>To:</span></p>
                              <div *ngFor="let cValue of delta.to | keyvalue">
                                <p><span class="u-mr-20">{{ cValue.key }} </span><span>{{ cValue.value }}</span></p>
                              </div>
                            </div>
                            <div *ngIf="isDeltaType(delta, 'string')">
                              <p>{{ delta }}</p>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
            <tr *ngIf="!isDataLoading && (auditHistoryTableData == undefined || !auditHistoryTableData.length)"
              coreUITableRow>
              <td colspan="8" class="pt-4">
                <coreui-graphic-message label="No Results Found" graphic="noResults">
                  Sorry, we did not find any results.
                </coreui-graphic-message>
              </td>
            </tr>
            <tr *ngIf="isDataLoading">
              <td colspan="8" class="py-4">
                <coreui-load-mask [active]="isDataLoading">
                </coreui-load-mask>
              </td>
            </tr>
          </tbody>
        </coreui-table>
    </div>
  </ng-template>
</coreui-modal>