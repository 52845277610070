import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Messages } from 'src/app/shared/message';
import { IdNameTuple } from 'src/app/shared/models/idNameTuple';
import { PackageType } from 'src/app/shared/models/package-builder/package-type-enum';
import { RecommendationType } from 'src/app/shared/models/package-builder/recommendartionType';
import { Pattern } from 'src/app/shared/pattern';
import { TooltipMessages } from 'src/app/shared/tooltip-messages';

@Component({
  selector: 'app-iqdecisions-view-model-package-info',
  templateUrl: './iqdecisions-view-model-package-info.component.html',
})
export class IqdecisionsViewModelPackageInfoComponent implements OnInit {


  @Input() configuredRecommendation;
  @Input() packageInfoFormControl: UntypedFormGroup;
  @Input() isEditMode = true;
  @Input() categoryLevelsList;
  @Input() choiceTypeName;

  public messages = Messages;
  public tooltipMessages = TooltipMessages;

  ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    if (this.choiceTypeName) {
      this.categoryLevelsList.forEach(val => {
        if (this.choiceTypeName === val.name) {
          this.packageInfoFormControl.patchValue({
            choiceTypeName: this.choiceTypeName,
            choiceTypeId: val.id
          });
        }
      });
    } else {
      if (this.packageInfoFormControl){
        this.packageInfoFormControl.patchValue({
          choiceTypeName: this.configuredRecommendation.metadataConfiguration?.packageInfo.choiceType?.name,
          choiceTypeId: this.configuredRecommendation.metadataConfiguration?.packageInfo.choiceType?.id,
          description: this.configuredRecommendation?.description,
          maxItemReturned: this.configuredRecommendation.metadataConfiguration?.packageInfo?.itemsToReturn
        });
      }
    }
  }

  public onItemChange(event: string): void {
    this.categoryLevelsList.forEach(val => {
      if (event === val.name) {
        this.packageInfoFormControl.patchValue({
          choiceTypeId: val.id
        });
      }
    });
  }

  public showCategoryLevel(): boolean {
    const value = this.configuredRecommendation.metadataConfiguration?.packageInfo?.recommendationType?.id;
    if (value) {
      return value.toLocaleLowerCase() === RecommendationType.PRODUCT_CATALOG;
    }
  }

  public selectOptionValue(id: string, name: string): IdNameTuple {
    return {
      id,
      name
    };
  }

  public preventZeroStartingNumber(event): void {
    if (!event.target.value.match(Pattern.VALIDATE_ZERO_AT_BEGINNING) && (event.key === '0' || event.ctrlKey)) {
      event.preventDefault();
    }
  }

}
