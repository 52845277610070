import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class UtilService {

  static findDuplicatesInArray(arr): any[] {
    return arr.filter((v, i, a)=>a.filter(k=>k === v).length > 1 && a.indexOf(v) === i);
  }

  static isDuplicateElementInArray(array, findElement): boolean {
    return array.some(dupId => findElement === dupId);
  }

  static convertJsonObjectToMap(jsonObject): Map<string, string> {
    const map = new Map<string, string>();
    for (const value in jsonObject) {
      map.set(value, jsonObject[value]);
    }
    return map;
  }

  static convertMapToJsonObject(map) {
    return Array.from(map).reduce((obj, [key, value]) => {
      obj[key] = value; return obj;
    }, {});
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  public static markFormGroupTouched(formGroup: UntypedFormGroup) : UntypedFormGroup {
    return (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public static delay(milliseconds: number): void {
    const start = new Date().getTime();
    let end = 0;
    while ( (end - start) < milliseconds) {
      end = new Date().getTime();
    }
  }

  public static getTitlecase(value: string): string {
    if (value === null || value === undefined) {
      return null;
    }
    const capitalLetter = value.substring(0, 1).toUpperCase();
    return capitalLetter + value.substring(1).toLowerCase();
  }

  public static unescapedJsonString(value: string): string {
    return value
      .replace(/(\\n)/g, '')
      .replace(/(\\r)/g, '')
      .replace(/(\\t)/g, '')
      .replace(/(\\f)/g, '')
      .replace(/(\\b)/g, '')
      .replace(/(\")/g, '\"')
      .replace(/("{)/g, '{')
      .replace(/(}")/g, '}')
      .replace(/(\\)/g, '')
      .replace(/(\/)/g, '/')
      .replace('}"}', '}}')
      .replace('}",', '},');
  }

  public static getLocalDateToUTC(localDate: Date): number {
    let utc = Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), 
    localDate.getHours(), localDate.getMinutes(), localDate.getSeconds());
    console.log(utc);
    return utc;
  }

  public static getDateInEpochMillis(date: Date, hours: number, minutes: number, seconds: number): number {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds);
  }

}