<div class="modal-header">
  <h2 class="text-style-1 mb-0">{{options.title}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="no()">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <h3 *ngIf="!options.isAlert" class="h2">
    <i class="fa fa-exclamation-circle fa-lg icon-color-warning mr-2" aria-hidden="true"></i>Warning
  </h3>
  <p *ngIf="options.message">{{options.message}}</p>
  <p *ngFor="let item of options.message2" class="mt-4">{{item}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-md" [ngClass]="options.btnOkClass" (click)="yes()">
    {{options.btnOkText}}
  </button>
  <button *ngIf="!options.isAlert" type="button" class="btn btn-text" (click)="no()">
    {{options.btnCancelText}}
  </button>
</div>