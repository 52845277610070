import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReservedAttributeLocatorService {

  private reservedAttributes: string[];

  constructor() {
    this.reservedAttributes = ['parentId', 'companyId', 'eventName', 'sourceSystem'];
  }

  public doesExist(attribute: string) {
    return (this.reservedAttributes.filter(a => attribute === a)).length > 0;
  }

}