<a href="./#mainContent" class="skip-link" (click)="skipToMain()">
  Skip to main content
</a>
<header>
  <app-primary-navigation></app-primary-navigation>
</header>
<div class="app-body">
  <main class="app-main" id="mainContent">
    <ng-content></ng-content>
  </main>
</div>
<app-ae-notification #notification></app-ae-notification>
