import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { OauthActionDestinationService } from 'src/app/shared/services/oauth-action-destination.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { AuthType } from 'src/app/shared/models/auth-type';
import { OauthCredentialComponent } from '../oauth-credential/oauth-credential.component';
import { ModalComponent } from '@epsilon/core-ui';
import { Constants } from '../../../../shared/constants';


interface Alert {
  type: string;
  message: string;
}

enum NotificationType {
  SUCCESS = 'SUCCESS',
  INVALID_DATA = 'INVALID_DATA',
  NETWORK_ERROR = 'NETWORK_ERROR',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM'
}

@Component({
  selector: 'app-add-custom-action-destination-modal',
  templateUrl: './add-custom-action-destination-modal.component.html',
  styleUrls: ['./add-custom-action-destination-modal.component.scss']
})
export class AddCustomActionDestinationModalComponent implements OnInit {

  @Input()
  public customActionDestination = {};
  @Input()
  public oauthCredential = {};
  @Input()
  public isInitializeEditMode = false;
  @Input()
  public existingCustomActionDestinationId: string;
  @ViewChild(OauthCredentialComponent)
  public oauthCredentialComponent: OauthCredentialComponent;
  @Output()
  private isModalDisplayed = new EventEmitter<any>();
  @Output()
  private configuredActionDestination = new EventEmitter<any>();
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;

  public customActionDestinationForm: UntypedFormGroup;
  public statusMessage: string;
  public isValidCredentials: boolean;
  public notification: Alert;
  public customActionDestinationNameMessage: string;

  public operationSuccess = false;
  public testButtonClicked = false;
  public saveButtonClicked = false;
  public isLoading = false;
  public editMode = false;
  private _notification = new Subject<Alert>();

  private isModalShown: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private context: ParentContextService,
    private actionDestinationService: OauthActionDestinationService,
    private popupService: PopupMessageService,
    private ref: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.launchBasicModal();
    this.customActionDestinationForm = this.fb.group({
      customActionDestinationName: ['', [Validators.required]],
      authMethod: [{ value: AuthType.OAUTH}, [Validators.required]],
      customActionDestinationDescription: ['']
    });
    this.setDefaultAuthenticationMethod();

    // Success/Error notification configuration
    this._notification.subscribe(alert => {
      this.notification = alert;
    });
    // Remove duplicate name notification when user begins changing the name
    this.getCustomActionDestinationNameControl().valueChanges.subscribe(changes => {
      this.notification = null;
    });
    // Reload on edit mode
    if (this.isInitializeEditMode) {
      this.onEditModeLoad();
    }
  }

  private setDefaultAuthenticationMethod(): void {
    this.getAuthMethodControl().setValue(AuthType.OAUTH, { onlySelf: true });
  }

  public test(): void {
    this.testButtonClicked = true;
    this.saveButtonClicked = false;
    this.isLoading = true;
    this.actionDestinationService.testCustomActionDestinationConnection(this.context.getParentContext(),
      this.actionDestinationService.buildCredentials(this).buildTestConnectionPayload()).subscribe(
      success => {
        this.operationSuccess = true;
        this.isLoading = false;
        this.isValidCredentials = true;
        this.pushSuccessNotification(this.sendNotification(Messages.successfulConnection, NotificationType.SUCCESS));
        this.finalizeAuthenticationUpdates();
      },
      failed => {
        this.operationSuccess = true;
        this.isLoading = false;
        this.isValidCredentials = false;
        if (failed.status === 400) {
          if (failed.error.statusMessage === 'FAILED') {
            this.pushErrorNotification(this.sendNotification(Messages.credentialsAreInvalid, NotificationType.INVALID_DATA));
            return;
          }
          if (failed.error.statusMessage === 'INVALID_DATA') {
            this.pushErrorNotification(
              this.sendNotification(this.oauthCredentialComponent.getEmptyAuthUrlErrorMessage(), NotificationType.INVALID_DATA));
            return;
          } else {
            this.pushErrorNotification(this.sendNotification(Messages.invalidDataInputOrNetworkError, NotificationType.NETWORK_ERROR));
          }
        } else {
          this.pushErrorNotification(this.sendNotification(Messages.invalidDataInputOrNetworkError, NotificationType.NETWORK_ERROR));
        }
      }
    );
  }

  public saveChanges(): void {
    this.saveButtonClicked = true;
    this.testButtonClicked = false;
    let operationResult: Observable<Object>;
    if (this.existingCustomActionDestinationId.length > 0) {
      this.isLoading = true;
      operationResult = this.actionDestinationService.doPutConfiguredActionDestination(this);
    } else {
      this.isLoading = true;
      operationResult = this.actionDestinationService.doPostConfiguredActionDestination(this);
    }
    operationResult.subscribe(
      success => {
        this.isLoading = false;
        this.operationSuccess = true;
        if (this.existingCustomActionDestinationId.length > 0){
          this.popupService.showSuccessMessage(`ActionDestination
          "${this.customActionDestinationForm.controls.customActionDestinationName.value}" ${Constants.SUCCESSFULLY_UPDATED}`);
        } else {
          this.popupService.showSuccessMessage(`ActionDestination
          "${this.customActionDestinationForm.controls.customActionDestinationName.value}" ${Constants.SUCCESSFULLY_CREATED}`);
        }
        if(this.getAuthMethodControl().value === AuthType.OAUTH) {
          this.editMode = false; // applies to update operation
          this.oauthCredentialComponent.editMode = false;
        }
        this.closeBasicModal();
        this.configuredActionDestination.emit({ refresh: true });
      },
      failureMessage => {
        this.isLoading = false;
        this.operationSuccess = true;
        const errorDetail = failureMessage['error'];
        if (errorDetail.statusMessage === 'DUPLICATE_ITEM') {
          this.pushErrorNotification(this.sendNotification(Messages.duplicateName, NotificationType.DUPLICATE_ITEM));
          return;
        } else {
          this.pushErrorNotification(this.sendNotification(errorDetail['result'], NotificationType.INVALID_DATA));
        }
      }
    );
  }

  public getAuthUrlControl(): boolean {
    if (this.oauthCredentialComponent) {
      return this.oauthCredentialComponent.authUrlChanged;
    } else {
      return false;
    }
  }
  public isValidForTest(): boolean {
    return this.isInitializeEditMode ? false : this.getAuthUrlControl();
  }
  public isValidForSave(): boolean {
    return this.getAuthMethodControl().value !== AuthType.OAUTH
     ? this.customActionDestinationForm.valid
     : this.customActionDestinationForm.valid
        && this.operationSuccess
        && this.oauthCredentialComponent.isValid()
        && this.isValidCredentials
        && !this.hasFormUpdated();
  }
  public getCustomActionDestinationNameControl(): any {
    return this.customActionDestinationForm.get('customActionDestinationName');
  }
  public getCustomActionDestinationDescriptionControl(): any {
    return this.customActionDestinationForm.get('customActionDestinationDescription');
  }
  public getAuthMethodControl(): any {
      return this.customActionDestinationForm.get('authMethod');
  }

  public validateForRequired(c: AbstractControl): any {
    return c.errors && c.touched && c.errors.required;
  }
  public isSuccessOrNetworkErrorNotification(): boolean {
    return this.notification.type === NotificationType.NETWORK_ERROR || this.notification.type === NotificationType.SUCCESS;
  }
  public getEmptyActionDestinationNameErrorMessage(): string {
    return Messages.emptyActionDestinationName;
  }
  public isOAuth(): boolean {
    return this.getAuthMethodControl().value === AuthType.OAUTH;
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayed.emit(this.isModalShown);
  }

  private pushSuccessNotification(alert: Alert) {
    this._notification.next(alert);
  }
  private pushErrorNotification(alert: Alert) {
    this._notification.next(alert);
  }
  private sendNotification(msg: string, type: string): Alert {
    return {
      type: type,
      message: `${msg}`
    };
  }
  private finalizeAuthenticationUpdates(): void {
    this.oauthCredentialComponent.finalizeUpdates();
  }
  private hasFormUpdated() {
    return this.oauthCredentialComponent.hasFormUpdated();
  }
  private onEditModeLoad(): void {
    this.getCustomActionDestinationNameControl().setValue(this.customActionDestination['name']);
    this.getCustomActionDestinationDescriptionControl().setValue(this.customActionDestination['description']);

    this.ref.detectChanges(); // To track component load
    if(this.customActionDestination['authMethod'] === AuthType.NONE) {
      this.getAuthMethodControl().setValue(this.customActionDestination['authMethod']);
    } else {
      this.oauthCredentialComponent.editMode = false;
      this.oauthCredentialComponent.getUserIdControl().setValue(this.customActionDestination['userId']);
      this.oauthCredentialComponent.getClientIdControl().setValue(this.customActionDestination['clientId']);
      this.oauthCredentialComponent.getGrantTypeControl().setValue(this.customActionDestination['grantType']);
      this.oauthCredentialComponent.getScopeControl().setValue(this.customActionDestination['scope']);
      this.oauthCredentialComponent.getAuthUrlControl().setValue(this.customActionDestination['authUrl']);
    }
    this.isInitializeEditMode = false;
  }

}
