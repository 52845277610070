import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ASSOCIATION_API_FIELD_STATUS, ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER,
  ENTITY_TYPE,
  HAS_ASSOCIATIONS_IDENTIFIER, ID_IDENTIFIER } from 'src/app/shared/models/association/association-constants';
import { ConfiguredEntityAssociationParams } from 'src/app/shared/models/association/configured-entity-association-params';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { IconViewDisplayItem } from 'src/app/shared/models/icon-view-display-item';
import { AssociationService } from 'src/app/shared/services/association.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { Messages } from 'src/app/shared/message';
import { EventSourceProviderType } from 'src/app/shared/models/event-source';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { AssociationsTableBuilderService } from 'src/app/shared/services/associations-table-builder.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { WarningType } from 'src/app/shared/warning-options';
import { AEModalInfoComponent } from 'src/app/shared/component/modal/ae-modal-info/ae-modal-info.component';
import { Observable } from 'rxjs';
import { NotificationComponent } from '@epsilon/core-ui';
import { Constants } from '../../../shared/constants';

@Component({
  selector: 'app-event-source-providers',
  templateUrl: './event-source-providers.component.html'
})

export class EventSourceProvidersComponent implements OnInit {

  @ViewChild('formError', { static: true })
  public formError: NotificationComponent;
  @ViewChild('templateTabSwitch') templateTabSwitch;
  @ViewChild('companyAdded') companyControlAdded;
  @ViewChild(CoreuiModalWarningComponent, { static: true })
  private warningModal: CoreuiModalWarningComponent;
  @ViewChild(AEModalInfoComponent, { static: true })
  private infoModal: AEModalInfoComponent;


  readonly CUSTOM_ES_ID = 'Custom';
  readonly ADD_AN_EVENT_SOURCE_PROVIDER = 'Add an Event Source Provider';
  eventSourceProvidersForm: UntypedFormGroup;
  public eventResults: any;
  public isCollapsed: boolean[] = [];
  public addEventSourceRespose: any;
  public deleteCompanyModelDetail: any;
  public deleteEventSourceDetails: any;
  public operationInProgress = false;
  public operationStatusMessage = '';
  public operationFailures: boolean[] = [];
  public hasAssociationMap = {};
  public configuredEntityAssociationMap = {};
  public errorMessage: string;

  public companyAdded = 0;
  parentId: string;

  private eventSourceProviderType = {
    'selfService': EventSourceProviderType.SELF_SERVICE,
    'packaged': EventSourceProviderType.PACKAGED
  };

  private isCustomEventSourceModalShown: boolean;
  private allEventSourceProviders = [];
  private editMode: boolean;
  private customEventSource: {providerName: string; providerDescription: string};
  private editCustomEventSourceIndex: number;
  private associationModalDetail;
  private control;
  private index;
  private eventSourceId;
  private companyId;
  private companyName;

  constructor(private eventSourceService: EventSourceService,
    public modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public parentContext: ParentContextService, route: ActivatedRoute,
    private popupService: PopupMessageService,
    private associationService: AssociationService,
    public authorizationService: AuthorizationService,
    private associationsTableBuilderService: AssociationsTableBuilderService) {
    this.parentId = this.parentContext.getParentContext();
    this.eventSourceProvidersForm = this.fb.group({
      eventSource: this.fb.array([])
    });
  }

  public ngOnInit(): void {
    this.fetchConfiguredEventsources();
  }

  public fetchConfiguredEventsources(): void {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Loading event source providers';
    this.eventSourceService.getEventSources(this.parentId).subscribe(data => {
      this.eventResults = data;
      this.setEventSource();
      this.validateAssociationStatus();
      this.operationInProgress = false;
    }, error => {
      this.operationInProgress = false;
      this.eventResults = null;
      if (error.error.statusMessage === 'NOT_FOUND') {
        // Do nothing
      }
    });
  }

  public open() : void {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Loading';
    const existingEventSourceProviders = this.getExistingEventSourceProvidersAsDisplayItems();
    this.eventSourceService.getEventSourceProvider(this.parentId).subscribe(
      res => {
        this.operationInProgress = false;
        this.allEventSourceProviders = res['result'];
        const allDisplayItems = [];
        this.allEventSourceProviders.map(element => {
          allDisplayItems.push(new IconViewDisplayItem(element.id, element.name));
        });
        this.infoModal.launchModal({
          'title': this.ADD_AN_EVENT_SOURCE_PROVIDER,
          'description': '',
          'content': {
            'type': 'ICON_VIEW',
            'iconViewConfig': {
              'displayItems': allDisplayItems,
              'disabledItems': existingEventSourceProviders
            }
          }
        });
      },
      () => {
        this.operationInProgress = false;
        this.errorMessage = Messages.unauthorizedUser;
        this.formError.show();
      });
  }

  public getExistingEventSourceProvidersAsDisplayItems(): IconViewDisplayItem[] {
    const existingEventSourceProviders = this.eventSourceProvidersForm.controls.eventSource.value.map(function(item) {
      return new IconViewDisplayItem(item['id'], item['displayName']);
    });
    return existingEventSourceProviders;
  }

  public captureSelectedItem(selection: IconViewDisplayItem): void {
    if (selection.Id === this.CUSTOM_ES_ID) {
      this.processAsAddCustomEventSourceRequest();
    } else {
      this.processAsAddPackagedEventSourceRequest(selection);
    }
  }

  processAsAddCustomEventSourceRequest(): void {
    this.editMode = false;
    this.isCustomEventSourceModalShown = true;
    this.customEventSource = {
      providerName: null,
      providerDescription: null
    };
  }

  public isCustomEventSourceModalDisplayed(): boolean {
    return this.isCustomEventSourceModalShown;
  }

  public getCustomEventSource(): Record<string, unknown> {
    return this.customEventSource;
  }

  public getEventSourcesNotAllowed(): any[] {
    return this.allEventSourceProviders;
  }

  public setCustomEventSourceModalDisplayStatus($event: boolean): void {
    this.isCustomEventSourceModalShown = $event;
  }

  public captureConfiguredCustomEventSource(
    incomingConfiguration: {editMode: boolean; configuredEventSource: {providerName: string; providerDescription: string}}): void {
    if (!incomingConfiguration) {
      return null;
    }
    const result = incomingConfiguration.configuredEventSource;
    if (!incomingConfiguration.editMode) {
      const sourceProvider = {
        providerId: result.providerName,
        providerName: result.providerName,
        providerType: EventSourceProviderType.SELF_SERVICE,
        providerDescription: result.providerDescription
      };
      this.addNewEventSource(sourceProvider);
    } else {
      const eventSourcePayload: string = '{  "description":  "' + result.providerDescription + '"}';
      this.operationInProgress = true;
      this.operationStatusMessage = 'Saving changes';
      this.eventSourceService.updateEventSource(this.parentId, result.providerName, eventSourcePayload).subscribe(() => {
        this.operationInProgress = false;
        const control = <UntypedFormArray> this.eventSourceProvidersForm.controls.eventSource;
        control.removeAt(this.editCustomEventSourceIndex);
        const eventSourceDetails = {
          id: result.providerName,
          displayName: result.providerName,
          type: EventSourceProviderType.SELF_SERVICE,
          description: result.providerDescription,
          settings: this.fb.array([])
        };
        control.insert(this.editCustomEventSourceIndex, this.fb.group(eventSourceDetails));
      }, error => {
        this.operationInProgress = false;
        this.popupService.setAsError(error.error.statusMessage);
      });
    }
  }

  processAsAddPackagedEventSourceRequest(selection: IconViewDisplayItem): void {
    if (!selection) {
      return null;
    }
    const sourceProvider = {
      providerId: selection.Id,
      providerName: selection.Name,
      providerType: EventSourceProviderType.PACKAGED,
      providerDescription: null
    };
    this.addNewEventSource(sourceProvider);
  }

  public getEditMode(): boolean {
    return this.editMode;
  }

  public addNewEventSource(value): void {
    const configuredEventSource = {
      parentId: this.parentId,
      eventSource: value.providerId,
      name: value.providerName,
      type: value.providerType,
      description: value.providerDescription,
      settings: []
    };

    if (value.providerType === EventSourceProviderType.SELF_SERVICE) {
      delete configuredEventSource.settings;
    }

    const configuredEventSourcePayload = JSON.stringify(configuredEventSource);
    this.operationInProgress = true;
    this.operationStatusMessage = 'Adding event source provider';
    this.eventSourceService.configureEventSource(this.parentId, configuredEventSourcePayload).subscribe(
      () => {
        this.operationInProgress = false;
        this.updateEventSourceListWithNewlyAddedEventSource(value);
      },
      error => {
        this.operationInProgress = false;
        this.popupService.setAsError(error.error.statusMessage);
      });
  }

  public showDeleteEventSourceAlert(eventSourceId, index): void {
    const control = <UntypedFormArray> this.eventSourceProvidersForm.controls.eventSource;
    this.deleteEventSourceDetails = { control: control, eventSourceId: eventSourceId, index: index };
    this.warningModal.forEntity('EVENT_SOURCE').launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.removeEventSourceProvider,
      msg: Messages.confirmRemoveEventSourceProvider
    });
  }

  public handleDeleteESDecision(decision: boolean): void {
    if (decision) {
      this.performDeleteEventSource();
      this.operationFailures = [];
    }
  }

  public isPackagedEventSource(eventSourceType : string) : boolean {
    return eventSourceType === null || eventSourceType === this.eventSourceProviderType.packaged;
  }

  public isCustomEventSource(eventSourceType : string) : boolean {
    return eventSourceType === null || eventSourceType === this.eventSourceProviderType.selfService;
  }

  public addNewCompany(control: UntypedFormArray): void {
    const isDisabled = false;
    this.companyAdded = this.companyAdded + 1;
    control.push(this.initializeComponayDetails({ companyName: '', companyId: '' }, isDisabled));
  }

  public removeUnsavedCompany(control: UntypedFormArray, index: number) : void {
    this.companyAdded = this.companyAdded - 1;
    control.removeAt(index);
  }

  public saveCompany(eventSourceId : string, companyControrl: UntypedFormArray, index: number) : void {
    const companyId = companyControrl.controls[index].value.companyId;
    const companyName = companyControrl.controls[index].value.companyName;
    const saveCompanyDetailsPayload: string = '{  "settings": [ { "companyId": "' + companyId + '", "companyName": "' + companyName + '" } ] }';
    this.operationInProgress = true;
    this.operationStatusMessage = 'Saving changes';
    this.eventSourceService.updateEventSource(this.parentId, eventSourceId, saveCompanyDetailsPayload).subscribe(result => {
      this.operationInProgress = false;
      this.popupService.showSuccessMessage("Company '" + companyName +"' Added Successfully!");
      this.companyAdded = this.companyAdded - 1;
      companyControrl.removeAt(index);
      const isDisabled = true;
      companyControrl.insert(index, this.initializeComponayDetails({ companyName: companyName, companyId: companyId }, isDisabled));
    }, error => {
      this.operationInProgress = false;
      this.popupService.showErrorMessage(error.error.statusMessage);
      this.popupService.setAsError(error.error.statusMessage);
    });
  }

  public showDeleteCompanyAlert(eventSourceId : string, control : UntypedFormArray, index : number): void {
    const companyId = control.value[index].companyId;
    const companyName = control.value[index].companyName;
    this.deleteCompanyModelDetail = { control: control, index: index, eventSourceId: eventSourceId, companyId: companyId, companyName: companyName };
    this.control = control;
    this.index = index;
    this.eventSourceId = eventSourceId;
    this.companyId = companyId;
    this.companyName = companyName;
    this.warningModal.forEntity('COMPANY').launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteCompany,
      msg: Messages.confirmDeleteCompany
    });
  }

  public handleDeleteCompanyDecision(decision: boolean): void {
    if (decision) {
      this.deleteCompany();
    }
  }

  public deleteCompany(): void {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Deleting company';
    const deleteCompanyDetail: string = '{ "parentId": "' + this.parentId
    + '", "eventSource": "' + this.eventSourceId
    + '", "settings": [ { "companyId": "' + this.companyId
    + '", "companyName": "' + this.companyName + '" } ] }';
    this.eventSourceService.deleteCompany(this.parentId, deleteCompanyDetail).subscribe(
      () => {
        this.control.removeAt(this.index);
        this.operationInProgress = false;
        this.popupService.showSuccessMessage("Company '" + this.companyName + "' deleted successfully!");
      }, failureReason => {
        this.operationInProgress = false;
        this.popupService.showErrorMessage(failureReason.error.statusMessage);
        if (failureReason.status === 403) {
          this.popupService.setAsError(failureReason.error.statusMessage);
        } else {
          this.associationModalDetail = this.associationsTableBuilderService
            .buildAssociationTableDataFromConfig(new ConfiguredEntityAssociationParams(
              failureReason.error.result,
              this.parentId,
              this.companyId,
              ConfiguredEntityType.EVENT_SOURCE));
          this.launchAssociationsModal();
        }
      });
  }

  public getData(): unknown {
    return this.associationModalDetail['data'];
  }

  public getTableProperties(): Record<string, unknown> {
    return this.associationModalDetail['properties'];
  }

  public isTableDataLoading(): boolean {
    return this.associationModalDetail['isDataLoading'];
  }

  public getTableTitle(): string {
    return this.associationModalDetail['title'];
  }

  public setCompanyDetails(x, isDisabled) {
    const arr = new UntypedFormArray([]);
    if (x.settings !== null) {
      x.settings.forEach(y => {
        arr.push(this.initializeComponayDetails(y, isDisabled));
      });
    }
    return arr;
  }

  public initializeComponayDetails(y, isDisabled) {
    return this.fb.group({
      companyName: [{ value: y.companyName, disabled: isDisabled }, Validators.required],
      companyId: [{ value: y.companyId, disabled: isDisabled }, Validators.required]
    });
  }

  public validateEnteredCompanyExists(sources, index): void {
    const compDetails = sources[index].value;
    sources.forEach((source, sourceIndex) => {
      const sourceDetails = source.value;
      if (index !== sourceIndex) {
        if (sourceDetails.companyId === compDetails.companyId) {
          sources[index].controls['companyId'].setErrors({ 'alreadyExists': true });
        }
        if ((sourceDetails.companyName === compDetails.companyName)) {
          sources[index].controls['companyName'].setErrors({ 'alreadyExists': true });
        }
      }
    });
  }

  public editCustomEventSource(customEventsourceControl, index): void {
    this.editMode = true;
    this.isCustomEventSourceModalShown = true;
    this.customEventSource = {
      providerName: customEventsourceControl.controls.id.value,
      providerDescription: customEventsourceControl.controls.description.value
    };
    this.editCustomEventSourceIndex = index;
  }

  public hasAssociation(eventSource): boolean {
    return this.hasAssociationMap[eventSource];
  }

  public getOperationFailureMessage(): string {
    return Messages.eventSourceOperationFailureMessage;
  }

  public getConfiguredEntityAssociationParams(eventSource) {
    return this.configuredEntityAssociationMap[eventSource];
  }

  public setAssociationInProgress(event): boolean {
    if (event === null || event === undefined) {
      return;
    }
    this.operationInProgress = true;
    this.operationStatusMessage = Messages.loadingAssociations;
  }

  public setAssociationDone(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.operationInProgress = false;
    this.hasAssociationMap[event.ID_IDENTIFIER] = event[HAS_ASSOCIATIONS_IDENTIFIER];
  }

  public hasOperationFailed(i, eventSource: string): boolean {
    return this.operationFailures[i] || this.hasAssociation(eventSource);
  }

  public isRemoveDisabled(eventSource): boolean {
    return (eventSource.get('settings').length !== 0) || this.hasAssociation(eventSource.value.id);
  }

  public isEditDisabled(eventSource): boolean {
    return this.hasAssociation(eventSource);
  }

  public handleDeleteDecision(decision: boolean): void {
    if (this.warningModal.getEntityType() === 'EVENT_SOURCE') {
      this.handleDeleteESDecision(decision);
    } else if (this.warningModal.getEntityType() === 'COMPANY') {
      this.handleDeleteCompanyDecision(decision);
    }
  }

  public launchUnsavedChangesModal(): Observable<any> {
    this.warningModal.launchModal(WarningType.UNSAVED_CHANGES_WARNING, {
      msg2: [Messages.loseUnsavedChanges]
    });
    return this.warningModal.decision.asObservable();
  }

  public getAssociationsBtnTxt(): string {
    return Messages.viewAssociationsBtnTxt;
  }

  public openAssociationsModal(eventSource: string): void {
    const entityType = 'EVENT_SOURCE';
    this.associationsTableBuilderService
      .withEntityType(entityType)
      .withConfiguredEntityAssociationParams(this.getConfiguredEntityAssociationParams(eventSource))
      .buildAssociationTableDataAfterLookup()
      .subscribe(associationTableData => {
        if (associationTableData[ENTITY_TYPE] === entityType) {
          this.launchAssociationsTableModal(associationTableData.tableData);
        }
      });
    // Track association status - in progress
    this.associationsTableBuilderService.trackAssociationInProgress().subscribe($event => {
      this.setAssociationInProgress($event);
    });
    // Track association status - done
    this.associationsTableBuilderService.trackAssociationDone().subscribe($event => {
      if ($event[ENTITY_TYPE] !== entityType
        || !(ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER in $event)
        || !(HAS_ASSOCIATIONS_IDENTIFIER in $event)
        || !(ID_IDENTIFIER in $event)) {
        return;
      }
      this.setAssociationDone($event);
    });
  }

  private launchAssociationsTableModal(associationModalDetail: Record<string, any>): void {
    this.infoModal.launchModal({
      'title': associationModalDetail['title'],
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': associationModalDetail['data'],
        'tableConfig': {
          'properties': associationModalDetail['properties'],
          'isDataLoading': associationModalDetail['isDataLoading']
        }
      }
    });
  }

  private launchAssociationsModal(): void {
    this.infoModal.launchModal({
      'title': this.getTableTitle(),
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': this.getData(),
        'tableConfig': {
          'properties': this.getTableProperties(),
          'isDataLoading': this.isTableDataLoading()
        }
      }
    });
  }

  private validateAssociationStatus(): void {
    this.eventResults.result.forEach(eventSource => {
      const eventSourceProvider = eventSource.eventSource;
      this.operationInProgress = true;
      this.associationService.getAssociations(this.parentId, ConfiguredEntityType.EVENT_SOURCE, eventSourceProvider).subscribe(success => {
        this.hasAssociationMap[eventSourceProvider] = success['result'][ASSOCIATION_API_FIELD_STATUS];
        this.configuredEntityAssociationMap[eventSourceProvider] = new ConfiguredEntityAssociationParams(
          null, this.parentId, eventSourceProvider, ConfiguredEntityType.EVENT_SOURCE);
        this.operationInProgress = false;
      }, () => {
        this.hasAssociationMap[eventSourceProvider] = false;
      });
    });
  }

  private updateEventSourceListWithNewlyAddedEventSource(newlyAddedEventSource: any): void {
    const control = <UntypedFormArray> this.eventSourceProvidersForm.controls.eventSource;
    const isDisabled = false;
    this.companyAdded = this.companyAdded + 1;
    const eventSourceDetails = {
      id: newlyAddedEventSource.providerId,
      displayName: newlyAddedEventSource.providerName,
      type: newlyAddedEventSource.providerType,
      description: null,
      settings: this.fb.array([this.initializeComponayDetails({ companyName: '', companyId: '' }, isDisabled)])
    };

    if (newlyAddedEventSource.providerDescription !== null) {
      eventSourceDetails['description'] = newlyAddedEventSource.providerDescription;
    }

    if (newlyAddedEventSource.providerType && newlyAddedEventSource.providerType === EventSourceProviderType.SELF_SERVICE) {
      eventSourceDetails.settings = this.fb.array([]);
      this.companyAdded = this.companyAdded - 1;
    }

    control.push(this.fb.group(eventSourceDetails));
  }

  private performDeleteEventSource(): void {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Deleting event source provider';
    this.eventSourceService.deleteEventSource(this.parentId, this.deleteEventSourceDetails.eventSourceId).subscribe(result => {
      this.deleteEventSourceDetails.control.removeAt(this.deleteEventSourceDetails.index);
      this.popupService.showSuccessMessage(`EventSource "${this.deleteEventSourceDetails.eventSourceId}" ${Constants.SUCCESSFULLY_DELETED}`);
      this.operationInProgress = false;
    }, failureReason => {
      this.operationInProgress = false;
      if (failureReason.status === 403) {
        this.popupService.setAsError(failureReason.error.statusMessage);
      } else {
        this.associationModalDetail = this.associationsTableBuilderService
          .buildAssociationTableDataFromConfig(new ConfiguredEntityAssociationParams(
            failureReason.error.result,
            this.parentId,
            this.deleteEventSourceDetails.eventSourceId,
            ConfiguredEntityType.EVENT_SOURCE));
        this.launchAssociationsModal();
        this.operationFailures[this.deleteEventSourceDetails.index] = true;
      }
    });
  }

  private setEventSource() : void {
    const control = <UntypedFormArray> this.eventSourceProvidersForm.controls.eventSource;
    const isDisabled = true;
    this.eventResults.result.forEach(x => {
      control.push(this.fb.group({
        id: x.eventSource,
        displayName: x.name,
        description: x.description,
        type: x.type,
        settings: this.setCompanyDetails(x, isDisabled)
      }));
    });
  }

}