import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { ActionsHomeComponent } from 'src/app/modules/actions/actions-home/actions-home.component';
import { EventsHomeComponent } from 'src/app/modules/events/events-home/events-home.component';
import { RulesHomeComponent } from 'src/app/modules/rules/rules-home/rules-home.component';
import { EntityStatesHomeComponent } from './modules/entitystates/entity-states-home/entity-states-home.component';
import { IQDecisionsHomeComponent } from './modules/iqdecisions/iqdecisions-home/iqdecisions-home.component';
import { ExclusionsHomeComponent } from './modules/exclusions/exclusions-home/exclusions-home.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { OktaLoginComponent } from './modules/okta-login/okta-login.component';
import { ExternalSystemLoginComponent } from './modules/external-system-login/external-system-login.component';
import { AdminGuard } from 'src/app/modules/admin/admin-guard';
import { RouteResolveGuard } from './route-resolve-guard';
import { AELoadingScreenComponent } from './shared/component/ae-loading-screen/ae-loading-screen.component';
import { ParentsHomeComponent } from './modules/admin/parents-home/parents-home.component';
import { ClientHeaderComponent } from 'src/app/modules/client/client-header/client-header.component';
import { SystemUsersHomeComponent } from 'src/app/modules/admin/system-users-home/system-users-home.component';
import { RolesHomeComponent } from './modules/admin/roles-home/packaged-roles-home.component';
import { SystemUserGroupsHomeComponent } from './modules/admin/system-user-groups-home/system-user-groups-home.component';
import { PackagedRolesConfigurationComponent } from './modules/admin/roles-configuration/packaged-roles-configuration.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [RouteResolveGuard],
    children: [],
    pathMatch: 'full',
    component: AELoadingScreenComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/okta',
    component: OktaLoginComponent
  },
  {
    path: 'login/okta/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'login/external-system',
    component: ExternalSystemLoginComponent
  },
  {
    path: 'admin/roles',
    loadChildren: () => import('src/app/modules/admin/admin.module').then(m => m.AdminModule),
    component: RolesHomeComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'iqdecisions',
    loadChildren: () => import('src/app/modules/iqdecisions/iqdecisions.module').then(m => m.IQDecisionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'entitystates',
    loadChildren: () => import('src/app/modules/entitystates/entity-states.module').then(m => m.EntityStatesModule),
    component: EntityStatesHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'actions',
    loadChildren: () => import('src/app/modules/actions/actions.module').then(m => m.ActionsModule),
    component: ActionsHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/clients',
    loadChildren: () => import('src/app/modules/admin/admin.module').then(m => m.AdminModule),
    component: ParentsHomeComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'admin/users',
    component: SystemUsersHomeComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'admin/usergroups',
    loadChildren: () => import('src/app/modules/admin/admin.module').then(m => m.AdminModule),
    component: SystemUserGroupsHomeComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'iqdecisions',
    loadChildren: () => import('src/app/modules/iqdecisions/iqdecisions.module').then(m => m.IQDecisionsModule),
    component: IQDecisionsHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('src/app/modules/events/events.module').then(m => m.EventsModule),
    component: EventsHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'rules',
    loadChildren: () => import('src/app/modules/rules/rules.module').then(m => m.RulesModule),
    component: RulesHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'exclusions',
    loadChildren: () => import('src/app/modules/exclusions/exclusions.module').then(m => m.ExclusionsModule),
    component: ExclusionsHomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'client',
    loadChildren: () => import('src/app/modules/client/client.module').then(m => m.ClientModule),
    component: ClientHeaderComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
