export class ConfiguredProgramTestData {

  eventAttributeTestDataMap: Map<string, string[]> = new Map<string, string[]>();

  public addEventAttributeTestData(eventAttributePath: string, testData: string[]) {
    this.eventAttributeTestDataMap.set(eventAttributePath, testData);
  }

  public getTestDataByAttribute(attribute: string): string[] {
    return this.eventAttributeTestDataMap.get(attribute);
  }

  public fromJson(json: any): ConfiguredProgramTestData {
    if (json != null && Object.keys(json).length > 0) {
      this.eventAttributeTestDataMapFromJson(json['eventAttributeTestDataMap']);
    }
    return this;
  }

  private eventAttributeTestDataMapFromJson(json: any) {
    const eventAttributePaths: string[] = Object.keys(json);
    eventAttributePaths.forEach((nextEventAttributePaths: string) => {
      this.addEventAttributeTestData(nextEventAttributePaths, json[nextEventAttributePaths]);
    });
  }

  public toJson(): Object {
    const eventAttributeTestDataJson = {};
    this.eventAttributeTestDataMap.forEach((eventAttributeValues, eventAttributePath) => {
      eventAttributeTestDataJson[eventAttributePath] = eventAttributeValues;
    });
    return eventAttributeTestDataJson;
  }

}