<div>
  <form [formGroup]="categoryPermissionsFormGroup">
    <div class="row pt-4 pl-4">
      <div class="col-3 pt-3 permission-row-color permission-row-height">{{categoryPermissionTitle}}</div>
      <div class="col-3 pt-3 permission-row-color permission-row-height">
        <coreui-checkbox-group orientation="horizontal">
          <coreui-form-field label="Read">
            <input type="checkbox" coreUICheckbox formControlName="categoryReadFormControl"
              (change)="onCategoryPermissionClicked($event,'READ')" />
          </coreui-form-field>
          <coreui-form-field label="Write">
            <input type="checkbox" coreUICheckbox formControlName="categoryWriteFormControl"
              (change)="onCategoryPermissionClicked($event,'CREATE')" />
          </coreui-form-field>
          <coreui-form-field label="Delete">
            <input type="checkbox" coreUICheckbox formControlName="categoryDeleteFormControl"
              (change)="onCategoryPermissionClicked($event,'DELETE')" />
          </coreui-form-field>
        </coreui-checkbox-group>
      </div>
    </div>
  </form>
  <app-feature-permissions [inputPermissionList]="this.inputPermissionList" [isFormReadOnly]="this.isFormReadOnly"
    (updateCategoryPermissions)="updateCategoryPermissions($event.selected,$event.actionType)"
    #featurePermissionsComponent></app-feature-permissions>
</div>