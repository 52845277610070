import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { Action, Constants } from 'src/app/shared/constants';
import { Messages } from 'src/app/shared/message';
import { Pattern } from 'src/app/shared/pattern';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { EntityContentService } from 'src/app/shared/services/entity-content.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-entity-content-configuration',
  templateUrl: './entity-content-configuration.component.html'
  })
export class EntityContentConfigurationComponent implements OnInit {

  public entityContentForm: UntypedFormGroup;
  public fieldExists: boolean;
  public invalid = true;
  public isApiServiceCalled = false;
  public messages = Messages;
  public minDate = new Date();
  public operation = Action.CREATE;
  public entityContentTableList = [];
  public entityContentsRes = [];
  public entityContentTableFilteredData = [];
  public entityContentTable = [];
  public selectedFile: File = null;
  private contentIds = [];
  private submitted = false;
  private allowedTypes = ['png', 'jpg', 'jpeg', 'gif'];
  private parentId: string;
  private entityContentKey: string;
  private contentId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    public parentContextService: ParentContextService,
    private entityContentService: EntityContentService,
    private popupService: PopupMessageService,
    private route: ActivatedRoute,
    private router: Router,
    public authorizationService: AuthorizationService,
    private datePipe: DatePipe
  ) {
    this.parentId = parentContextService.getParentContext();
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.entityContentKey = route.snapshot.paramMap.get('companyId');
      this.contentId = route.snapshot.paramMap.get('contentId');
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.routeConfig.path.includes('rmnupdate')) {
      this.operation = Action.EDIT;
      this.getEntityContent(this.entityContentKey);
    }
    this.initEntityContentForm();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public fileSelect(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  public entityContentInsertRecord(formGroupDirective): void {
    this.submitted = true;
    if (this.entityContentForm.invalid) {
      return;
    }
    this.isApiServiceCalled = true;
    if (this.operation === Action.CREATE) {
      this.entityContentService.addContent(this.parentId, this.buildFormData()).subscribe(
        (result: any) => {
          this.handleBudgetResponse(formGroupDirective, Messages.entityContentInsertSuccessMessage);
        }, (error: any) => {
          this.handleErrorResponse(error.error.result[0]);
        });
    } else {
      this.entityContentService.updateBudgetContent(this.parentId, this.buildBudgetPayload()).subscribe(
        (result: any) => {
          this.handleBudgetResponse(formGroupDirective, Messages.entityContentUpdateSuccessMessage);
        }, (error: any) => {
          this.handleErrorResponse(error);
        });
      this.entityContentService.updateEntityContent(this.parentId, this.buildPayload()).subscribe(
        (result: any) => {
          this.handleBudgetResponse(formGroupDirective, Messages.entityContentUpdateSuccessMessage);
        }, (error: any) => {
          this.handleErrorResponse(error);
        });
    }
  }


  public get contentUrl(): AbstractControl {
    return this.entityContentForm.get('contentUrl');
  }

  public get clickThroughUrl(): AbstractControl {
    return this.entityContentForm.get('clickThroughUrl');
  }

  public get contentID(): AbstractControl {
    return this.entityContentForm.get('contentId');
  }

  private checkIfContentIdExists(): ValidatorFn {
    this.entityContentService.getConfiguredEntityContents(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((res: any[]) => {
      const entityContentRes = res['result'];
      entityContentRes.forEach(ec => {
        this.contentIds.push(ec.contentId);
      });
    }, error => {
    });
    return (control: AbstractControl): {[key: string]: any} | null => {
      const contentIdExists = this.contentIds.includes(control.value);
      return contentIdExists ? { 'contentIdExists': { value: control.value } } : null;
    };
  }

  private imageValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const filePath = control.value;
      if (filePath) {
        const extension = filePath.split('.').pop().toLowerCase();
        const isImage = this.allowedTypes.includes(extension);
        return isImage ? null : { 'invalidFileType': { value: control.value } };
      }
    };
  }

  private handleErrorResponse(error: string) {
    this.popupService.showErrorMessage(error);
    this.isApiServiceCalled = false;
  }

  private handleBudgetResponse(formGroupDirective, successMessage: string) {
    this.popupService.showSuccessMessage(successMessage);
    formGroupDirective.resetForm();
    this.entityContentForm.reset();
    this.isApiServiceCalled = false;
    void this.router.navigate([Constants.RMNCONTENT_HOME_ROUTER_LINK]);
  }

  private getEntityContent(entityContentKey: string): any {
    const currCompanyId = entityContentKey;
    this.entityContentService.getEntityContent(currCompanyId, this.contentId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
      this.entityContentService.getBudgetContent(currCompanyId, this.contentId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((res1: any) => {
        res['result']['budget'] = res1['result'].budget;
        this.populateEntityContentValues(res['result']);
      }, error => {
        res['result']['budget'] = null;
        this.populateEntityContentValues(res['result']);
      });
    }, error => {
    });
  }

  private populateEntityContentValues(entityContent: any): void {
    this.entityContentForm.patchValue({
      companyId: entityContent.contentKey.companyId,
      contentId: entityContent.contentId,
      contentUrl: entityContent.contentUrl,
      clickThroughUrl: entityContent.clickThroughUrl,
      budget: entityContent.budget,
      expirationTime: entityContent.expirationTime ? this.datePipe.transform(new Date(entityContent.expirationTime * 1000), Constants.DATE_FORMAT) : null
    });
  }

  private initEntityContentForm(): void {
    if (this.operation === Action.EDIT) {
      this.entityContentForm = this.formBuilder.group({
        companyId: [{ value: '', disabled: true }],
        contentId: [{ value: '', disabled: true }],
        contentUrl: [{ value: '', disabled: true }],
        clickThroughUrl: ['', [Validators.required, Validators.pattern(Pattern.URL)]],
        budget: ['', [Validators.pattern(Pattern.INTEGER)]],
        expirationTime: ['']
      });
    } else {
      this.entityContentForm = this.formBuilder.group({
        companyId: [''],
        contentId: ['', [Validators.required, this.checkIfContentIdExists()]],
        clickThroughUrl: ['', [Validators.required, Validators.pattern(Pattern.URL)]],
        budget: ['', [Validators.pattern(Pattern.INTEGER)]],
        expirationTime: [''],
        image: ['', [Validators.required, this.imageValidator()]]
      });
    }
  }

  private buildPayload(): string {
    const requestBody = {
      'contentKey': {
        'companyId': this.entityContentForm.get('companyId').value
      },
      'parentId': this.parentId,
      'contentName': '',
      'contentType': '',
      'fieldName': '',
      'budget': this.entityContentForm.get('budget').value,
      'contentId': this.entityContentForm.get('contentId').value,
      'contentUrl': this.entityContentForm.get('contentUrl').value,
      'clickThroughUrl': this.entityContentForm.get('clickThroughUrl').value,
      'expirationTime': this.entityContentForm.get('expirationTime').value ? this.getScheduleTimeInEpoch() : null
    };
    return JSON.stringify(requestBody);
  }

  private buildBudgetPayload(): string {
    const requestBody = {
      'partitionKey': {
        'companyId': this.entityContentForm.get('companyId').value
      },
      'id': this.entityContentForm.get('contentId').value,
      'budget': this.entityContentForm.get('budget').value
    };
    return JSON.stringify(requestBody);
  }

  private buildFormData(): FormData {
    const formData = new FormData();
    formData.append('companyId', this.entityContentForm.get('companyId').value);
    formData.append('budget', this.entityContentForm.get('budget').value);
    formData.append('contentId', this.entityContentForm.get('contentId').value);
    formData.append('clickThroughUrl', this.entityContentForm.get('clickThroughUrl').value);
    formData.append('image', this.selectedFile, this.selectedFile.name);
    const expiration = this.entityContentForm.get('expirationTime').value ? this.getScheduleTimeInEpoch() : null;
    if (expiration !== null) {
      formData.append('expiration', expiration.toString());
    }
    return formData;
  }

  private getScheduleTimeInEpoch(): number {
    const date = new Date(this.entityContentForm.get('expirationTime').value);
    date.setHours(date.getHours() + 24);
    date.setMinutes(date.getMinutes());
    return this.datetoEpochTime(date);
  }

  private datetoEpochTime(date: Date): number {
    return date.getTime() - (date.getTimezoneOffset() * 60000);
  }

}