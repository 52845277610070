import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserSource } from '../../shared/models/user-source-enum';
import { ExternalSystemLoginService } from '../../shared/services/external-system-login-service';
import { Messages } from 'src/app/shared/message';
import oktaConfig from '../../app.config';

@Component({
  selector: 'app-external-system-login',
  templateUrl: './external-system-login.component.html'
})
export class ExternalSystemLoginComponent implements OnInit {

  @ViewChild('authorize') authorizeRef: ElementRef;

  public loginError: string;
  public urlError: string;
  public authorizeUrl;
  public parentId = '';
  public isPageLoading;
  public apiResponse: string;
  public isUrlError: boolean;
  public isTokenAvailable: boolean;

  constructor(private route: ActivatedRoute,
    public externalSystemLoginService: ExternalSystemLoginService,
    public http?: HttpClient,
    public domsanitizer?: DomSanitizer) {
  }

  ngOnInit() : void {
    this.route.queryParams
      .subscribe(params => {
        this.externalSystemLoginService.setExternalSource = params.source;
        this.externalSystemLoginService.setExternalTenantId = params.id;
      });
    this.loginError = Messages.externalSystemLoginError;
    this.urlError = Messages.externalSystemUrlError;
    this.authorizeUrl = this.domsanitizer.bypassSecurityTrustResourceUrl(oktaConfig.oidc.authenticationUri);
    if (!this.externalSystemLoginService.getExternalSource || !this.externalSystemLoginService.getExternalTenantId) {
      this.isUrlError = true;
    }
    if (this.externalSystemLoginService.isLoggedIn()) {
      this.isTokenAvailable = true;
      this.externalSystemLoginService.intializePermissionOnLoginFromExternalSystem(this);
    }
  }

  public handleAuthorizeResponse() : void {
    if (this.authorizeRef) {
      try {
        if (this.authorizeRef.nativeElement.contentWindow.document !== undefined) {
          const response: string = this.authorizeRef.nativeElement.contentWindow.document.URL;
          const token = response.split('id_token=')[1].replace('&state=authn', '');
          this.onLoginSuccess(token);
        }
      } catch (excep) {
        this.apiResponse = 'FAILURE';
        const response: string = this.authorizeRef.nativeElement.contentWindow.document.URL;
        console.log(response);
        const error: string = response.split('error_description=')[1].toString().replace(/\+/g, ' ');
        if (error.includes('user is not logged in')) {
          this.loginError = Messages.externalSystemSessionError;
        }
        this.externalSystemLoginService.logout();
      }
    }
  }

  public onSessionExpiry() : void {
    if (this.externalSystemLoginService.isLogedIn === false) {
      this.isUrlError = true;
      this.externalSystemLoginService.logout();
    }
  }

  private onLoginSuccess(token: string): void {
    console.log('User Logged In from External System : Sign In');
    localStorage.setItem('token', token);
    localStorage.setItem('user-source', UserSource.SSO);
    this.initUserDetails();
  }

  private initUserDetails() : void {
    const sessionUri = oktaConfig.oidc.sessionUri;
    this.externalSystemLoginService.getOktaSession(sessionUri)
      .subscribe(
        res => {
          const session = JSON.parse(JSON.stringify(res));
          localStorage.setItem('username', session.login);
          this.buildOktaTokenStorage(session);
          this.externalSystemLoginService.isOktaSession = false;
          this.isPageLoading = true;
          this.externalSystemLoginService.intializePermissionOnLoginFromExternalSystem(this);
        }, error => {
          this.isUrlError = true;
          this.externalSystemLoginService.isOktaSession = false;
          console.log(error);
        });
  }

  private buildOktaTokenStorage(session) : void {
    const oktaTokenStorage
    = {
      'idToken': {
        'value': localStorage.getItem('token'),
        'claims': session,
        'expiresAt': this.convertStringDateToEpoch(session.expiresAt),
        'scopes': ['openid', 'profile', 'email'],
        'authorizeUrl': oktaConfig.oidc.issuer + '/v1/authorize',
        'clientId': session.idp.id
      },
      'accessToken': {}
    };
    localStorage.setItem('okta-token-storage', JSON.stringify(oktaTokenStorage));
  }

  private convertStringDateToEpoch(utcDate: string): string {
    const date = new Date(utcDate);
    const timestamp = Math.floor(date.getTime() / 1000);
    return timestamp.toString();
  }

}