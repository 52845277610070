<app-ae-loading-indicator [isLoading]="isApiServiceCallInProgress" [type]="'loading bar'"></app-ae-loading-indicator>
<app-coreui-modal-warning></app-coreui-modal-warning>
<form *ngIf="!isApiServiceCallInProgress" [formGroup]="configuredEntityForm">
  <div class="container-fluid pt-3 pl-5">
    <div *ngIf="isClientDetails()" class="row">
      <div class="col">
        <app-ae-button
          [buttonText]="messages.parentsBackBtnTxt"
          buttonType="text-primary"
          iconPosition="left"
          icon="chevronLeft"
          routerLink="/admin/clients"
        ></app-ae-button>
      </div>
    </div>
    <div class="row align-items-center mb-3">
      <div *ngIf="inputParams.action === 'add'" class="col" >
        <h1 class="pr-4">{{messages.onBoardingParentTxt}}</h1>
      </div>
      <div *ngIf="inputParams.action === 'edit'" class="col" >
        <h1 class="pr-4">{{messages.parentDetailsTxt}}</h1>
      </div>
      <div class="col-auto pl-0">
        <app-ae-button [buttonText]="messages.saveBtnTxt" (click)="saveParent()"></app-ae-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <coreui-form-field [label]="messages.parentNameLabel" for="parentName">
          <input
            maxlength="150"
            coreUIInput
            id="parentName"
            formControlName="parentName"
            [placeholder]="messages.addParentName"
            (keyup)="checkIfParentNameAlreadyExists()"/>
          <coreui-form-error *ngIf="parentName.invalid && isSaveClicked" class="text-color-warning">
            <div *ngIf="parentName.errors.required || parentName.errors.pattern">
              {{messages.parentNameRequiredError}}
            </div>
            <div *ngIf="parentName.errors.alreadyExist">
              {{messages.parentNameAlreadyExistsError}}
            </div>
          </coreui-form-error>
        </coreui-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <coreui-form-field [label]="messages.parentIdLabel" for="parentId">
          <input maxlength="150" coreUIInput id="parentId" formControlName="parentId" [placeholder]="messages.addParentId" (keyup)="checkIfParentIdAlreadyExists()"/>
          <coreui-form-error *ngIf="parentId.invalid && isSaveClicked" class="text-color-warning">
            <div *ngIf="parentId.errors.required || parentId.errors.pattern">
              {{messages.parentIdRequiredError}}
            </div>
            <div *ngIf="parentId.errors.alreadyExist">
              {{messages.parentIdAlreadyExistsError}}
            </div>
          </coreui-form-error>
        </coreui-form-field>
      </div>
    </div>
    <app-ae-radio-group
      [label] = 'messages.verticalTypeLabel'
      orientation = 'vertical'
      [radioItems] = verticalTypeList
      [formGroup] = configuredEntityForm
      [callback] = onVerticalTypeChange
    >
    </app-ae-radio-group>
    <app-ae-toggle
      [label] = 'messages.reportingEnabledLabel'
      type = 'With Label'
      name = "reportingEnabled"
      uncheckedLabel="No"
      checkedLabel="Yes"
      [enableHammerIcon] = true
      [formGroup] = configuredEntityForm
      [callback] = onReportingEnabledChange
    >
    </app-ae-toggle>
  </div>
</form>
