<coreui-modal #basicModal>
  <ng-template #titleTemplate>
    <h2 class="text-style-1 mb-0">Add a Custom Action Destination</h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
      <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <form novalidate [formGroup]="customActionDestinationForm" class="horizontal-scrollable">
      <div class="row" *ngIf="saveButtonClicked && !isLoading && operationSuccess && notification &&
      !isSuccessOrNetworkErrorNotification() && notification.type!=='DUPLICATE_ITEM'">
        <div class="col">
          <p class="text-color-warning">{{notification.message}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="customActionDestinationName">Action Destination Name</label>
            <input type="text"
              id="customActionDestinationName"
              formControlName="customActionDestinationName"
              class="form-control"
              placeholder="Enter a name for this action destination"
              [readonly]="editMode==true"
              [ngClass]="{ 'is-invalid': validateForRequired(getCustomActionDestinationNameControl()) }"/>
            <div *ngIf="validateForRequired(getCustomActionDestinationNameControl())" class="invalid-feedback">
              {{getEmptyActionDestinationNameErrorMessage()}}
            </div>
            <div *ngIf="saveButtonClicked && !isLoading && operationSuccess && notification &&
            !isSuccessOrNetworkErrorNotification() && notification.type==='DUPLICATE_ITEM'"
            class="text-color-warning">{{notification.message}}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="customActionDestinationDescription">Action Destination Description</label>
            <textarea id="customActionDestinationDescription" cols="50" rows="3"
              formControlName="customActionDestinationDescription"
              class="form-control"
              placeholder="Enter a description for this action destination"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h2>API User Credentials</h2>
        </div>
      </div>
      <div *ngIf="testButtonClicked && !isLoading && operationSuccess && notification && isSuccessOrNetworkErrorNotification()" class="row">
        <div class="col">
          <i *ngIf="notification.type==='SUCCESS'" class="fas fa-check-circle icon-color-success icon-left" aria-hidden="true"></i>
          <i *ngIf="notification.type==='NETWORK_ERROR'" class="fas fa-exclamation-circle icon-color-warning icon-left" aria-hidden="true"></i>
          {{notification.message}}
        </div>
      </div>
      <div *ngIf="testButtonClicked && !isLoading && operationSuccess && notification && !isSuccessOrNetworkErrorNotification()"
        class="row text-color-warning">
        <div class="col">
          {{notification.message}}
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="authenticationMethod">Authentication Method</label>
            <coreui-select id="authenticationMethod" formControlName="authMethod">
              <coreui-option value="OAUTH" [selected]="true">OAUTH</coreui-option>
              <coreui-option value="NONE">NONE</coreui-option>
            </coreui-select>
          </div>
        </div>
      </div>
      <app-oauth-credential *ngIf="isOAuth()"></app-oauth-credential>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <app-ae-button *ngIf="isOAuth()" class="mr-auto" [disabled]="!isValidForTest()" (click)="test()" buttonType="secondary" buttonText="Test Connection"></app-ae-button>
    <app-ae-button (click)="saveChanges()" [disabled]="!isValidForSave()" buttonText="Save"></app-ae-button>
    <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
  </ng-template>
</coreui-modal>
