import { AssociationTemplateDetail } from './association-template-detail';
import { ConfiguredEntityAssociationParams } from './configured-entity-association-params';

export class ConfiguredEntityAssociation {

  private status: boolean;
  private associations: Record<string, any>;
  private parentId: string;
  private entityId: string;
  private associationTemplateDetail: AssociationTemplateDetail;

  constructor(configuredEntityAssociationParams: ConfiguredEntityAssociationParams) {
    if (configuredEntityAssociationParams.ApiResponse) {
      this.status = 'status' in configuredEntityAssociationParams.ApiResponse ? configuredEntityAssociationParams.ApiResponse['status'] : false;
      this.associations = 'associations' in configuredEntityAssociationParams.ApiResponse
        ? configuredEntityAssociationParams.ApiResponse['associations'] : {};
    }
    this.parentId = configuredEntityAssociationParams.ParentId;
    this.entityId = configuredEntityAssociationParams.EntityId;
    this.associationTemplateDetail = configuredEntityAssociationParams.AssociationTemplateDetail;
  }


  public get Status() {
    return this.status;
  }

  public get Associations() {
    return this.associations;
  }

  public get ParentId() {
    return this.parentId;
  }

  public set ParentId(parentId: string) {
    this.parentId = parentId;
  }

  public get EntityId() {
    return this.entityId;
  }
  public set EntityId(entityId: string) {
    this.entityId = entityId;
  }

  public get AssociationTemplateDetail() {
    return this.associationTemplateDetail;
  }

  public set AssociationTemplateDetail(associationTemplateDetail: AssociationTemplateDetail) {
    this.associationTemplateDetail = associationTemplateDetail;
  }

}