import {Injectable} from '@angular/core';
import {Bundle} from "../models/bundle/Bundle";

@Injectable({
  providedIn: 'root'
})
export class BundleService {
  private readonly BOOTSTRAP_BUNDLES = {
    WEB: {
      id: 'WEB',
      displayName: 'Site Abandonment',
      isVisible: true,
      isDefault: true
    },
    APP: {
      id: 'APP',
      displayName: 'App Behavior',
      isVisible: false,
      isDefault: false
    },
    SOCIAL: {
      id: 'SOCIAL',
      displayName: 'Social Media Behavior',
      isVisible: false,
      isDefault: false
    },
    LEAD_GENERATION: {
      id: 'LEAD_GENERATION',
      displayName: 'Lead Generation',
      isVisible: false,
      isDefault: false
    },
    OTHER: {
      id: 'OTHER',
      displayName: 'Other',
      isVisible: true,
      isDefault: false
    }
  }

  constructor() {
  }

  public getBundles(): Bundle[] {
    let availableBundles = [];
    for (let bundle in this.BOOTSTRAP_BUNDLES) {
      if (this.BOOTSTRAP_BUNDLES[bundle]['isVisible']) {
        availableBundles.push(new Bundle(this.BOOTSTRAP_BUNDLES[bundle]['id'],
          this.BOOTSTRAP_BUNDLES[bundle]['displayName'],
          this.BOOTSTRAP_BUNDLES[bundle]['isVisible'],
          this.BOOTSTRAP_BUNDLES[bundle]['isDefault']));
      }
    }
    return availableBundles;
  }

  public getDefaultBundle(): Bundle {
    return this.getBundles().filter(b => b.IsDefault)[0];
  }
}
