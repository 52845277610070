import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationComponent } from '@epsilon/core-ui';
import { takeUntil } from 'rxjs/operators';
import { EntityTypes, FormOnSaveAction } from 'src/app/shared/constants';
import { Messages } from 'src/app/shared/message';
import { AttributeMetadata } from 'src/app/shared/models/custom-rule/conditions/attribute-metadata';
import { EntityStateMetadata } from 'src/app/shared/models/custom-rule/conditions/entity-state-metadata';
import { SourceType } from 'src/app/shared/models/custom-rule/conditions/source-type';
import { TreeNode } from 'src/app/shared/models/custom-rule/tree-node';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { ConfiguredExclusion } from 'src/app/shared/models/exclusion/configured-exclusion';
import { ServiceResponse } from 'src/app/shared/models/service-response';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { EntityStatesService } from 'src/app/shared/services/entitystates.service';
import { EventSchemaParserService } from 'src/app/shared/services/event-schema-parser.service';
import { ExclusionService } from 'src/app/shared/services/exclusion.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ExclusionBuilderHeaderComponent } from '../exclusion-builder-header/exclusion-builder-header.component';
import { Exclusion } from 'src/app/shared/models/exclusion/exclusion';
import { ExclusionConfigurationBuilderComponent } from '../exclusion-configuration-builder/exclusion-configuration-builder.component';
import { WarningType } from 'src/app/shared/warning-options';
import { ReplaySubject } from 'rxjs';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { AssociationsTableBuilderService } from 'src/app/shared/services/associations-table-builder.service';
import { ConfiguredEntityAssociationParams } from 'src/app/shared/models/association/configured-entity-association-params';
import { ASSOCIATION_API_FIELD_STATUS, ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER, ENTITY_TYPE, HAS_ASSOCIATIONS_IDENTIFIER, ID_IDENTIFIER }
  from 'src/app/shared/models/association/association-constants';
import { AssociationService } from 'src/app/shared/services/association.service';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { PopupMessageService } from '../../../shared/services/popup-message.service';
import { EntityType } from 'src/app/shared/models/entity-type';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { ConfiguredEventDetails } from 'src/app/shared/models/configured-event-details';

@Component({
  selector: 'app-exclusion-builder',
  templateUrl: './exclusion-builder.component.html'
})
export class ExclusionBuilderComponent extends BaseFormDirective implements OnInit, OnDestroy {

  @ViewChild(ExclusionBuilderHeaderComponent)
  public exclusionBuilderHeaderComponent:ExclusionBuilderHeaderComponent;
  @ViewChild(ExclusionConfigurationBuilderComponent)
  public exclusionConfigurationBuilderComponent:ExclusionConfigurationBuilderComponent;
  @ViewChild('formError', { static: true })
  public formError: NotificationComponent;
  @ViewChild('formSuccess', { static: true })
  public formSuccess: NotificationComponent;

  public action = FormOnSaveAction.CREATE;
  public entityType: EntityTypes;
  public parentId: string;
  public editExclusionId: string;
  public configuredExclusion: ConfiguredExclusion = new ConfiguredExclusion();
  public configuredExclusions: ConfiguredExclusion[];
  public configuredEntityStates: ConfiguredEntityState[] = [];
  private configuredEventDetails: ConfiguredEventDetails[];
  public attributeMetadataList: AttributeMetadata[] = [];
  public systemEntityStateMetadatList: EntityStateMetadata[] = [];
  public customEntityStateMetadataList: EntityStateMetadata[] = [];
  public customEventMetadataList: TreeNode[] = [];
  public errorMessage = '';
  public successMessage = '';
  public userOperationDelete: boolean;
  public hasAssociation: boolean;
  public associationStatus: ConfiguredEntityAssociationParams;

  private apiCallCompletionStatus = {
    'initEntityStatesCompleted': false,
    'initEventsCompleted': false,
    'initExclusionToEditCompleted': false,
    'initExclusionsCompleted': false,
    'isAssociationChecked': false
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(
    public router: Router,
    public authorizationService: AuthorizationService,
    public route: ActivatedRoute,
    public entityStateService: EntityStatesService,
    public eventSchemaParser: EventSchemaParserService,
    public parentContextService: ParentContextService,
    public exclusionService: ExclusionService,
    private associationsTableBuilderService: AssociationsTableBuilderService,
    private popupService: PopupMessageService,
    private associationService: AssociationService,
    private eventSourceService: EventSourceService) {
    super();
    this.initInputParams();
    this.initEntityStates();
    this.initExclusions();
    this.initExclusion();
    this.initEvents();
  }

  public ngOnInit(): void {
    this.isDataSaved = false;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public onSave(): void {
    const isValid = this.validate();
    if (isValid) {
      this.configuredExclusion = this.buildConfiguredExclusion();
      if (this.action === FormOnSaveAction.CREATE) {
        this.createConfiguredExclusion(this.configuredExclusion);
      }
      if (this.action === FormOnSaveAction.EDIT) {
        this.updateConfiguredExclusion(this.configuredExclusion);
      }
    } else {
      this.showErrorMessage(Messages.errorOnPage);
    }
  }

  public onDelete(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteExclusion,
      msg: Messages.deleteExclusionConsquence,
      msg2: [Messages.confirmDeleteExclusion]
    });
  }

  public handleDecision(userConfirmation: boolean): void {
    if (userConfirmation && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteConfiguredExclusion();
    }
  }

  public isFormReady(): boolean {
    return Object.values(this.apiCallCompletionStatus).every(nextApiCallCompletionStatus => nextApiCallCompletionStatus === true);
  }

  public getExclusionParameter(): Exclusion {
    return this.action === FormOnSaveAction.EDIT ? this.configuredExclusion.getExclusion() : undefined;
  }

  public deleteConfiguredExclusion(): void {
    this.exclusionService.deleteConfiguredExclusionById(this.parentId, this.editExclusionId)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.userOperationDelete = true;
        this.popupService.showDeleteMessage(Messages.exclusionDeleted);
        void this.router.navigate(['exclusions']);
      },
      () => {
        this.showErrorMessage(Messages.exclusionDeleteFailed);
      });
  }

  public isReadOnly(): boolean {
    return this.entityType === EntityTypes.PACKAGED || this.hasAssociation;
  }

  public hasAssociations(): boolean {
    return this.hasAssociation;
  }

  public getOperationFailureMessage(): string {
    return Messages.exclusionOperationFailureMessage;
  }

  public getAssociationsBtnTxt(): string {
    return Messages.viewAssociationsBtnTxt;
  }

  public openAssociationsModal(): void {
    const entityType = 'EXCLUSION';
    this.associationsTableBuilderService
      .withEntityType(entityType)
      .withConfiguredEntityAssociationParams(this.associationStatus)
      .buildAssociationTableDataAfterLookup()
      .subscribe(associationTableData => {
        if (associationTableData[ENTITY_TYPE] === entityType) {
          this.launchAssociationsModal(associationTableData.tableData);
        }
      });
    this.associationsTableBuilderService.trackAssociationInProgress().subscribe($event => {
      this.setAssociationInProgress($event);
    });
    this.associationsTableBuilderService.trackAssociationDone().subscribe($event => {
      if ($event[ENTITY_TYPE] !== entityType
        || !(ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER in $event)
        || !(HAS_ASSOCIATIONS_IDENTIFIER in $event)
        || !(ID_IDENTIFIER in $event)) {
        return;
      }
      this.setAssociationDone($event);
    });
  }

  private validate(): boolean {
    const isValidHeader = this.exclusionBuilderHeaderComponent.validate();
    const isValidExclusion = this.exclusionConfigurationBuilderComponent.validate();
    return isValidHeader && isValidExclusion;
  }

  private buildConfiguredExclusion(): ConfiguredExclusion {
    if (this.action === FormOnSaveAction.EDIT) {
      return this.buildConfiguredExclusionToUpdate();
    }
    if (this.action === FormOnSaveAction.CREATE) {
      return this.buildConfiguredExclusionToCreate();
    }
  }

  private buildConfiguredExclusionToCreate(): ConfiguredExclusion {
    const configuredExclusion: ConfiguredExclusion = new ConfiguredExclusion();
    configuredExclusion.type = EntityTypes.CUSTOM;
    configuredExclusion.parentId = this.parentId;
    configuredExclusion.name = this.exclusionBuilderHeaderComponent.getName();
    configuredExclusion.exclusion = JSON.stringify(this.exclusionConfigurationBuilderComponent.buildExclusion());
    return configuredExclusion;
  }

  private buildConfiguredExclusionToUpdate(): ConfiguredExclusion {
    const configuredExclusion: ConfiguredExclusion = Object.assign(new ConfiguredExclusion(), this.configuredExclusion);
    configuredExclusion.name = this.exclusionBuilderHeaderComponent.getName();
    configuredExclusion.exclusion = JSON.stringify(this.exclusionConfigurationBuilderComponent.buildExclusion());
    return configuredExclusion;
  }

  private initInputParams(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.route.params.subscribe(params => {
      this.action = params.action;
      this.editExclusionId = params.id;
      this.entityType = params.type;
    });
  }

  private initEntityStates() : void {
    this.entityStateService.getAllConfiguredEntityStates(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.configuredEntityStates = data['result'];
      this.initEntityStateTreeNodes();
      this.apiCallCompletionStatus.initEntityStatesCompleted = true;
    }, () => {
      this.apiCallCompletionStatus.initEntityStatesCompleted = true;
    });
  }

  private initEvents() : void {
    this.eventSourceService.getConfiguredEvents(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.configuredEventDetails = data['result'];
      this.initEventTreeNodes();
      this.apiCallCompletionStatus.initEventsCompleted = true;
    }, () => {
      this.apiCallCompletionStatus.initEventsCompleted = true;
    });
  }

  private initEntityStateTreeNodes() {
    this.configuredEntityStates.forEach((configuredEntityState: ConfiguredEntityState) => {
      const entityStateAttributeMap: { [key: string]: AttributeMetadata } = Object.create({});
      const entityStateTreeNode = this.eventSchemaParser.extractAttributes(JSON.parse(configuredEntityState.schema),
        '$', entityStateAttributeMap, SourceType.ENTITY_STATE);
      if (entityStateTreeNode.length > 0 && this.schamaHasMaxDepthOne(entityStateTreeNode)) {
        if (configuredEntityState.type === EntityType.PACKAGED) {
          this.systemEntityStateMetadatList.push(new EntityStateMetadata(configuredEntityState.name, entityStateTreeNode));
        } else {
          this.customEntityStateMetadataList.push(new EntityStateMetadata(configuredEntityState.name, entityStateTreeNode));
        }
      }
      if (entityStateAttributeMap && Object.keys(entityStateAttributeMap).length > 0) {
        Object.keys(entityStateAttributeMap).forEach(nextEntityStateAttributeKey => {
          const entityStateAttribute: AttributeMetadata = entityStateAttributeMap[nextEntityStateAttributeKey];
          entityStateAttribute.entityStateName = configuredEntityState.name;
          entityStateAttribute.entityStateId = configuredEntityState.id;
          this.attributeMetadataList.push(entityStateAttribute);
        });
      }
    });
  }

  private initEventTreeNodes() {
    this.configuredEventDetails.forEach((configuredEvent: ConfiguredEventDetails) => {
      const eventAttributeMap: { [key: string]: AttributeMetadata } = Object.create({});
      const eventTreeNode = this.eventSchemaParser.extractAttributes(JSON.parse(configuredEvent.schema), '$', eventAttributeMap, SourceType.EVENT);
      let customEventMetadataTreeNode = new TreeNode();
      customEventMetadataTreeNode.name = configuredEvent.name;
      customEventMetadataTreeNode.children = eventTreeNode;
      this.customEventMetadataList.push(customEventMetadataTreeNode);
      if (eventAttributeMap && Object.keys(eventAttributeMap).length > 0) {
        Object.keys(eventAttributeMap).forEach(eventAttributeKey => {
          const eventAttribute: AttributeMetadata = eventAttributeMap[eventAttributeKey];
          this.attributeMetadataList.push(eventAttribute);
        });
      }
    });
  }

  private schamaHasMaxDepthOne(entityStateTreeNodes: TreeNode[]): boolean {
    return entityStateTreeNodes.every((entityStateTreeNode: TreeNode) => {
      if (!entityStateTreeNode.children) {
        return true;
      }
      if (entityStateTreeNode.children.length === 0) {
        return true;
      }
      return false;
    });
  }

  private initExclusion(): void {
    if (this.action === FormOnSaveAction.CREATE) {
      this.apiCallCompletionStatus.initExclusionToEditCompleted = true;
      this.apiCallCompletionStatus.isAssociationChecked = true;
      return;
    }
    if (this.entityType === EntityTypes.PACKAGED) {
      this.initPackagedExclusion();
    }
    if (this.entityType === EntityTypes.CUSTOM) {
      this.initConfiguredExclusion();
    }
  }

  private initConfiguredExclusion(): void {
    this.exclusionService.getConfiguredExclusionById(this.parentId, this.editExclusionId)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
        this.configuredExclusion = new ConfiguredExclusion(serviceResponse);
        this.apiCallCompletionStatus.initExclusionToEditCompleted = true;
        this.validateAssociationStatus();
      },
      () => {
        this.apiCallCompletionStatus.initExclusionToEditCompleted = true;
      });
  }

  private initPackagedExclusion(): void {
    this.exclusionService.getPackagedExclusionById(this.parentId, this.editExclusionId)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
        this.configuredExclusion = new ConfiguredExclusion(serviceResponse);
        this.apiCallCompletionStatus.initExclusionToEditCompleted = true;
        this.validateAssociationStatus();
      },
      () => {
        this.apiCallCompletionStatus.initExclusionToEditCompleted = true;
      });
  }

  private initExclusions(): void {
    this.exclusionService.getConfiguredExclusionByParent(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.configuredExclusions = res.result as ConfiguredExclusion[];
        this.apiCallCompletionStatus.initExclusionsCompleted = true;
      }, () => {
        this.apiCallCompletionStatus.initExclusionsCompleted = true;
      }
    );
  }

  private createConfiguredExclusion(configuredExclusion: ConfiguredExclusion): void {
    this.exclusionService.addConfiguredExclusion(this.parentId, configuredExclusion)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse) => {
        this.markAsPristine();
        this.isDataSaved = true;
        this.popupService.showSuccessMessage(Messages.exclusionSaved, true);
        this.exclusionBuilderHeaderComponent.action = FormOnSaveAction.EDIT;
        this.editExclusionId = serviceResponse.result['id'];
        void this.router.navigateByUrl('exclusions', { skipLocationChange: true }).then(() => {
          void this.router.navigate([`exclusions/${EntityTypes.CUSTOM}/${FormOnSaveAction.EDIT}/${this.editExclusionId}`]);
        });
      },
      () => {
        this.isDataSaved = false;
        this.showErrorMessage(Messages.exclusionSaveFailed);
      });
  }

  private updateConfiguredExclusion(configuredExclusion: ConfiguredExclusion): void {
    this.exclusionService.updateConfiguredExclusion(this.parentId, configuredExclusion)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.markAsPristine();
        this.showSuccessMessage(Messages.exclusionSaved);
        this.isDataSaved = false;
      },
      () => {
        this.isDataSaved = false;
        this.showErrorMessage(Messages.exclusionSaveFailed);
      });
  }

  private markAsPristine(): void {
    this.exclusionBuilderHeaderComponent.markAsPristine();
    this.exclusionConfigurationBuilderComponent.markAsPristine();
  }

  private showErrorMessage(message: string): void {
    this.errorMessage = message;
    this.formError.show().then(() => {}).catch(() => {});
  }

  private showSuccessMessage(message: string): void {
    this.successMessage = message;
    this.formSuccess.show().then(() => {}).catch(() => {});
  }

  private validateAssociationStatus(): void {
    this.hasAssociation = false;
    this.apiCallCompletionStatus.isAssociationChecked = false;
    this.associationService.getAssociations(this.parentId, ConfiguredEntityType.EXCLUSION, this.configuredExclusion.id)
      .subscribe(success => {
        this.hasAssociation = ASSOCIATION_API_FIELD_STATUS in success['result'] ? success['result'][ASSOCIATION_API_FIELD_STATUS] : false;
        this.associationStatus = new ConfiguredEntityAssociationParams(null, this.parentId, this.configuredExclusion.id,
          ConfiguredEntityType.EXCLUSION);
        this.apiCallCompletionStatus.isAssociationChecked = true;
      }, error => {
        this.hasAssociation = false;
        this.apiCallCompletionStatus.isAssociationChecked = true;
        if ((!('result' in error.error)) || (error.error.statusMessage === 'FAILED')) {
          this.showErrorMessage(Messages.associationRequestErroredMessage);
        } else {
          this.showErrorMessage(error.error.statusMessage);
        }
      });
  }

  private setAssociationInProgress(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
  }

  private setAssociationDone(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.hasAssociation = event[HAS_ASSOCIATIONS_IDENTIFIER];
  }

  private launchAssociationsModal(associationModalDetail: Record<string, any>): void {
    this.infoModal.launchModal({
      'title': associationModalDetail['title'],
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': associationModalDetail['data'],
        'tableConfig': {
          'properties': associationModalDetail['properties'],
          'isDataLoading': associationModalDetail['isDataLoading']
        }
      }
    });
  }

}