import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DisplayTextService } from 'src/app/shared/services/display-text.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JSONFileService } from 'src/app/shared/services/json-file-service';
import { EntityStatesSharedDataService } from '../entity-states-shared-data.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { AssociationService } from 'src/app/shared/services/association.service';
import { takeUntil } from 'rxjs/operators';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { ASSOCIATION_API_FIELD_STATUS, ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER,
  ENTITY_TYPE, HAS_ASSOCIATIONS_IDENTIFIER, ID_IDENTIFIER } from 'src/app/shared/models/association/association-constants';
import { ConfiguredEntityAssociationParams } from 'src/app/shared/models/association/configured-entity-association-params';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { AssociationsTableBuilderService } from 'src/app/shared/services/associations-table-builder.service';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { EntityTypes, OperationType } from '../../../shared/constants';

@Component({
  selector: 'app-entitystates-rule',
  templateUrl: './entity-states-properties.component.html'
})
export class EntityStatesPropertiesComponent extends BaseFormDirective implements OnInit, OnDestroy {

  apiCallCount = 0;
  isEdit = false;
  configuredEntityState: ConfiguredEntityState;
  configuredEntityStateId: string;
  configuredEntityStateName: string;
  operation: string;
  parentId : string;
  subscription: Subscription;
  public operationFailure = false;
  public hasAssociation: boolean;
  public configuredEntityStateType: string;
  public entityType = EntityTypes;
  public operationType = OperationType;
  public inputParams = {
    id: '',
    action: '',
    name: ''
  };
  public associationStatus: ConfiguredEntityAssociationParams;
  public isApiServiceCalled: boolean;
  private popupService: PopupMessageService;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private associationsTableBuilderService: AssociationsTableBuilderService,
    private associationService: AssociationService,
    private parentContextService?: ParentContextService,
    private entityStatesSharedService?: EntityStatesSharedDataService,
    public displayTexts?: DisplayTextService,
    public modal?: NgbModal,
    public jsonFileService?: JSONFileService,
    public route?: ActivatedRoute,
    public fb?: UntypedFormBuilder,
    private ref?: ChangeDetectorRef) {
    super();
    this.parentId = this.parentContextService.getParentContext();
    this.initInputParam(route);
    this.route.parent.params.subscribe(params => {
      this.operation = params['action'];
      this.configuredEntityStateName = params['name'];
      this.configuredEntityStateId = params['id'];
      this.configuredEntityStateType = params['type'];
    });
    this.subscription = this.entityStatesSharedService.getFormSubscriptions().subscribe(configuredEntityForm => {
      if (configuredEntityForm) {
        this.configuredEntityForm = configuredEntityForm;
        this.ref.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    this.configuredEntityForm = this.entityStatesSharedService.getEntityStateForm();
    this.parentId = this.parentContextService.getParentContext();
    this.validateAssociationStatus();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFormValueChanges() : void {
    this.entityStatesSharedService.setEntityStateForm(this.configuredEntityForm);
  }


  public setAssociationInProgress(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = true;
  }

  public setAssociationDone(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = false;
    this.hasAssociation = event[HAS_ASSOCIATIONS_IDENTIFIER];
    this.entityStatesSharedService.setAssociationSubscriptions(this.hasAssociation);
  }

  public hasAssociations(): boolean {
    return this.hasAssociation;
  }

  public hasOperationFailed(): boolean {
    return this.operationFailure || this.hasAssociations();
  }

  public getOperationFailureMessage(): string {
    return Messages.entityOperationFailureMessage;
  }

  public getAssociationsBtnTxt(): string {
    return Messages.viewAssociationsBtnTxt;
  }

  public openAssociationsModal(): void {
    const entityType = 'CONFIGURED_ENTITY_STATE';
    this.associationsTableBuilderService
      .withEntityType(entityType)
      .withConfiguredEntityAssociationParams(this.associationStatus)
      .buildAssociationTableDataAfterLookup()
      .subscribe(associationTableData => {
        if (associationTableData[ENTITY_TYPE] === entityType) {
          this.launchAssociationsModal(associationTableData.tableData);
        }
      });
    // Track association status - in progress
    this.associationsTableBuilderService.trackAssociationInProgress().subscribe($event => {
      this.setAssociationInProgress($event);
    });
    // Track association status - done
    this.associationsTableBuilderService.trackAssociationDone().subscribe($event => {
      if ($event[ENTITY_TYPE] !== entityType
        || !(ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER in $event)
        || !(HAS_ASSOCIATIONS_IDENTIFIER in $event)
        || !(ID_IDENTIFIER in $event)) {
        return;
      }
      this.setAssociationDone($event);
    });
  }

  private launchAssociationsModal(associationModalDetail: Record<string, any>): void {
    this.infoModal.launchModal({
      'title': associationModalDetail['title'],
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': associationModalDetail['data'],
        'tableConfig': {
          'properties': associationModalDetail['properties'],
          'isDataLoading': associationModalDetail['isDataLoading']
        }
      }
    });
  }

  private isEditMode() {
    return this.inputParams.action === 'edit';
  }

  private initInputParam(route: ActivatedRoute) {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.inputParams.action = route.parent.snapshot.params.action;
      this.inputParams.id = route.parent.snapshot.params.id;
    });
  }

  private validateAssociationStatus(): void {
    if (!this.isEditMode()) {
      this.hasAssociation = false;
    }
    this.isApiServiceCalled = true;
    this.associationService.getAssociations(this.parentId, ConfiguredEntityType.ENTITYSTATES, this.inputParams.id).subscribe(success => {
      this.hasAssociation = ASSOCIATION_API_FIELD_STATUS in success['result'] ? success['result'][ASSOCIATION_API_FIELD_STATUS] : false;
      this.associationStatus = new ConfiguredEntityAssociationParams(null, this.parentId, this.inputParams.id,
        ConfiguredEntityType.ENTITYSTATES, { EntityState: this.inputParams.id });
      this.isApiServiceCalled = false;
      this.entityStatesSharedService.setAssociationSubscriptions(this.hasAssociation);
    },
    error => {
      this.isApiServiceCalled = false;
      this.hasAssociation = false;
      if ((!('result' in error.error)) || (error.error.statusMessage === 'FAILED')) {
        this.popupService.showErrorMessage(Messages.associationRequestErroredMessage);
      } else {
        this.popupService.showErrorMessage(error.error.statusMessage);
      }
    });
    this.entityStatesSharedService.setAssociationSubscriptions(this.hasAssociation);
  }

}