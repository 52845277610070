import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/shared/services/event.service';
import { ConfiguredEvent } from 'src/app/shared/models/configured-event.model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../client/base/base.component';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-events-home',
  templateUrl: './events-home.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EventsHomeComponent extends BaseComponent implements OnInit {

  @ViewChild('nameColumnTemplate', { static: true })
  nameColumnTemplate: TemplateRef<any>;
  @ViewChild('actionColumnTemplate', { static: true })
  actionColumnTemplate: TemplateRef<any>;
  @ViewChild('dateTemplateColumn', { static: true }) dateTemplateColumn: TemplateRef<any>;
  // Table configuration
  public isDataLoading = false;
  searchByName: string;
  readConfiguredEventsList: ConfiguredEvent[];
  public columns = [];
  public sorts = [];
  public properties = {};
  configuredEventTableList = [];
  pageIndices;
  parentId: string;
  configuredEvents: ConfiguredEvent[];
  configuredEventTableFilteredData = [];
  loadingIndicator = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public parentContextService: ParentContextService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    public authorizationService: AuthorizationService,
    private datePipe: DatePipe) {
    super();
    this.initDataTableConfig();
  }

  ngOnInit() {
    this.parentId = this.parentContextService.getParentContext();
    this.getConfiguredEvents();
  }
  handleSearch(searchInput: string) : void {
    if (searchInput === null || searchInput === undefined) {
      searchInput = '';
    }
    if (this.searchByName !== searchInput) {
      this.searchByName = searchInput.trim();
      this.filterConfiguredEvents();
    }
  }

  filterConfiguredEvents(): void {
    if (this.searchByName && this.searchByName.trim()) {
      this.searchByName = this.searchByName.trim();
      this.configuredEvents = this.readConfiguredEventsList
        .filter((event: ConfiguredEvent) => {
          return event.name.toLowerCase().includes(this.searchByName.toLowerCase());
        });
    } else {
      this.configuredEvents = this.readConfiguredEventsList;
    }
    this.initConfiguredEventTableList();
  }

  private actionClick(event: any) {
    void this.router.navigate(['/events/edit/' + event.rowId.eventSourceName + '/' + event.rowId.eventName]);
  }

  private initDataTableConfig() {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Event Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Event Source Provider',
          key: 'eventSourceName',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Event Description',
          key: 'description',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.dateTemplateColumn
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private handleEventSort(sort) {
    this.configuredEventTableList = this.handleSort(sort, this.configuredEventTableFilteredData);
  }

  private handleEventPageChange(pageData) {
    this.configuredEventTableList = this.handlePageChange(pageData, this.configuredEventTableFilteredData);
  }

  private initConfiguredEventTableList() {
    const eventSourceMap = EventSourceService.getPackagedEventSourceMap();
    if (!this.configuredEvents || this.configuredEvents === undefined) {
      return;
    }
    this.configuredEventTableFilteredData = [];
    this.configuredEvents.forEach(event => {
      const configuredEventTableRow = {};
      configuredEventTableRow['id'] = event.id;
      configuredEventTableRow['name'] = event.id.eventName;
      configuredEventTableRow['eventSourceName']
        = eventSourceMap.has(event.id.eventSourceName) ? eventSourceMap.get(event.id.eventSourceName) : event.id.eventSourceName;
      configuredEventTableRow['description'] = event.description;
      configuredEventTableRow['routerLink'] = '/events/edit/' + event.id.eventSourceName + '/' + event.name;
      configuredEventTableRow['modifiedBy'] = event.modifiedBy;
      configuredEventTableRow['modifiedDate'] = this.datePipe.transform(new Date(event.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
      this.configuredEventTableFilteredData.push(configuredEventTableRow);
    });
    this.configuredEventTableList = this.configuredEventTableFilteredData.slice(0, 10);
  }

  getConfiguredEvents() {
    this.loadingIndicator = true;
    this.eventService.getConfiguredEvents(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((res: ConfiguredEvent[]) => {
      this.readConfiguredEventsList = res['result'];
      this.configuredEvents = res['result'];
      this.initConfiguredEventTableList();
      this.loadingIndicator = false;
      this.isDataLoading = false;
    }, error => {
      this.loadingIndicator = false;
      this.isDataLoading = false;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
