export enum ConfiguredEntityType {
    PROGRAM = 'PROGRAM',
    WORKFLOW = 'WORKFLOW',
    EVENT = 'EVENT',
    RULE = 'RULE',
    ACTION = 'ACTION',
    EVENT_SOURCE = 'EVENT_SOURCE',
    CUSTOM_ACTION_DESTINATION = 'CUSTOM_ACTION_DESTINATION',
    PACKAGED_ACTION_DESTINATION = 'PACKAGED_ACTION_DESTINATION',
    ENTITYSTATES = 'ENTITYSTATES',
    EXCLUSION = 'EXCLUSION'
}
