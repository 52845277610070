import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientRoutingModule } from 'src/app/modules/client/client-routing.module';
import { ClientHeaderComponent } from 'src/app/modules/client/client-header/client-header.component';
import { RouterModule } from '@angular/router';
import { EventSourceProvidersComponent } from 'src/app/modules/client/event-source-providers/event-source-providers.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AddBusinessUnitPopupComponent }
  from 'src/app/modules/client/action-destinations/add-business-unit-modal/add-business-unit-modal.component';
import { GeneralPropertiesComponent } from 'src/app/modules/client/general-properties/general-properties.component';
import { ActionDestinationsComponent } from 'src/app/modules/client/action-destinations/action-destinations.component';
import { EventSettingsCanDeactivateGuardService } from 'src/app/modules/client/event-source-providers/event-settings-can-deactivate-guard.service';
import { WarningState } from 'src/app/shared/warning-state';
import { AddCustomEventSourceModalComponent }
  from 'src/app/modules/client/event-source-providers/add-custom-event-source-modal/add-custom-event-source-modal.component';
import { AddCustomActionDestinationModalComponent }
  from 'src/app/modules/client/action-destinations/add-custom-action-destination-modal/add-custom-action-destination-modal.component';
import { OauthCredentialComponent } from 'src/app/modules/client/action-destinations/oauth-credential/oauth-credential.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddAwsActionDestinationModalComponent }
  from 'src/app/modules/client/action-destinations/add-aws-action-destination-modal/add-aws-action-destination-modal.component';
import { AwsUserCredentialComponent } from 'src/app/modules/client/action-destinations/aws-user-credential/aws-user-credential.component';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { BaseComponent } from 'src/app/modules/client/base/base.component';
import { BatchExtractModule } from './batch-extract/batch-extract.module';
import { TableModule, PaginationModule, LoadingIndicatorModule, ActionModule, CollapsiblePanelModule, NavSecondaryModule,
  LandingPageHeaderModule, SearchModule, MultiselectModule } from 'epsilon-blueprint';
import { CustomSettingsComponent } from './custom-settings/custom-settings.component';
import { UserRolesHeaderComponent } from './user-roles/user-roles-header/user-roles-header.component';
import { UsersComponent } from './user-roles/users/users.component';
import { UserRolesComponent } from './user-roles/roles/user-roles.component';
import { UserGroupsComponent } from './user-roles/user-groups/user-groups.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserGroupConfigurationComponent } from './user-roles/user-group-configuration/user-group-configuration.component';
import { UsersConfigurationComponent } from './user-roles/users-configuration/users-configuration.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { RoleConfigurationComponent } from './user-roles/role-configuration/role-configuration.component';
import { CoreUIModule } from '@epsilon/core-ui';
import { SupportHeaderComponent } from './support/support-header/support-header.component';
import { IMSLookUpComponent } from './support/ims-lookup/ims-lookup.component';
import { EntityStateLookupComponent } from './support/entity-state-lookup/entity-state-lookup.component';
import { ImsInsertComponent } from './support/ims-insert/ims-insert.component';
import { ActivityLookupComponent } from './support/activity-lookup/activity-lookup.component';
import { AllPermissionsComponent } from './user-roles/role-configuration/all-permissions/all-permissions.component';
import { CategoryPermissionsComponent } from './user-roles/role-configuration/category-permissions/category-permissions.component';
import { FeaturePermissionsComponent } from './user-roles/role-configuration/feature-permissions/feature-permissions.component';
import { EntityContentConfigurationComponent } from './support/entity-content/entity-content-configuration/entity-content-configuration.component';
import { EntityContentHomeComponent } from './support/entity-content/entity-content-home/entity-content-home.component';
import { ConfiguredRolesService } from 'src/app/shared/services/configured-roles.service';
import { ROLES_SERVICE_TOKEN, ROLE_MODEL, USERS_SERVICE_TOKEN, USER_CLASS_TOKEN, USER_GROUP_SERVICE_TOKEN, USER_GROUP_CLASS_TOKEN } from 'src/app/shared/tokens';
import { ConfiguredRole } from 'src/app/shared/models/permission/role/configured-role';
import { ConfiguredUsersService } from 'src/app/shared/services/configured-users.service';
import { SystemUsersService } from 'src/app/shared/services/system-users.service';
import { ConfiguredUser } from 'src/app/shared/models/user-roles/configured-user';
import { ConfiguredUserGroupService } from 'src/app/shared/services/configured-user-group.service';
import { PackagedUserGroupService } from 'src/app/shared/services/packaged-user-group.service';
import { UserGroupDetails } from 'src/app/shared/models/user-groups/user-group-details';
import { ApiUserConfigurationComponent } from './user-roles/api-user-configuration/api-user-configuration.component';

@NgModule({
  declarations: [
    ClientHeaderComponent,
    EventSourceProvidersComponent,
    GeneralPropertiesComponent,
    ActionDestinationsComponent,
    AddBusinessUnitPopupComponent,
    AddCustomEventSourceModalComponent,
    AddCustomActionDestinationModalComponent,
    OauthCredentialComponent,
    AddAwsActionDestinationModalComponent,
    AwsUserCredentialComponent,
    CustomSettingsComponent,
    UserRolesHeaderComponent,
    UsersComponent,
    UserRolesComponent,
    UserGroupsComponent,
    UserGroupConfigurationComponent,
    UsersConfigurationComponent,
    RoleConfigurationComponent,
    SupportHeaderComponent,
    IMSLookUpComponent,
    EntityStateLookupComponent,
    ImsInsertComponent,
    ActivityLookupComponent,
    AllPermissionsComponent,
    CategoryPermissionsComponent,
    FeaturePermissionsComponent,
    EntityContentConfigurationComponent,
    EntityContentHomeComponent,
    ApiUserConfigurationComponent
  ],
  imports: [
    CommonModule,
    ClientRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BaseTemplateModule,
    BatchExtractModule,
    TableModule,
    PaginationModule,
    LoadingIndicatorModule,
    ActionModule,
    CollapsiblePanelModule,
    NavSecondaryModule,
    LandingPageHeaderModule,
    NgSelectModule,
    MultiselectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SearchModule,
    CoreUIModule
  ],
  providers: [
    EventSettingsCanDeactivateGuardService,
    WarningState,
    BaseComponent,
    {provide: ROLES_SERVICE_TOKEN, useClass: ConfiguredRolesService},
    {provide: ROLE_MODEL, useClass: ConfiguredRole},
    {provide: USERS_SERVICE_TOKEN, useClass: ConfiguredUsersService},
    {provide: USER_CLASS_TOKEN, useClass: ConfiguredUser},
    {provide: USER_GROUP_SERVICE_TOKEN, useClass: ConfiguredUserGroupService},
    {provide: USER_CLASS_TOKEN, useClass: UserGroupDetails}
  ],
  exports: [RouterModule, UserRolesComponent, RoleConfigurationComponent, UsersComponent, UserGroupsComponent, UserGroupConfigurationComponent]
})
export class ClientModule {
  constructor(private rolesService: ConfiguredRolesService,
    private usersService: ConfiguredUsersService){}
 }