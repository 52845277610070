import { AbstractControl, ValidatorFn } from '@angular/forms';

export function placeHolderSyntaxValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    let status = false; // initialization
    const input = control.value;
    const count = (input.match(/\[@[\s\w-]*@\]/g) || []).length;
    if (count == 0) {
      status = true;
    } else {
      const patternCount = (input.match(/(\[@[\w-]+@\])/g) || []).length;
      status = patternCount == count;
    }
    return status ? null : { 'invalidPlaceholderSyntax': { value: control.value } };
  };
}