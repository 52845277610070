import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DragDrop } from '@epsilon/core-ui';
import { ConfiguredExclusion } from 'src/app/shared/models/exclusion/configured-exclusion';
import { CustomProgramExclusionConfiguationComponent } from
  '../custom-program-exclusion-configuation/custom-program-exclusion-configuation.component';

@Component({
  selector: 'app-custom-program-exclusion-builder',
  templateUrl: './custom-program-exclusion-builder.component.html',
  styleUrls: ['./custom-program-exclusion-builder.component.scss']
})
export class CustomProgramExclusionBuilderComponent implements OnInit {

  @ViewChildren(CustomProgramExclusionConfiguationComponent)
  public customProgramExclusionConfiguationComponents: QueryList<CustomProgramExclusionConfiguationComponent>;

  @Input()
  public configuredExclusions: ConfiguredExclusion[];
  @Input()
  public programExclusions: ConfiguredExclusion[];
  @Input()
  public readOnly: boolean;

  public programExclusionsToBuild: ConfiguredExclusion[] = [];

  public constructor() {
  }

  public ngOnInit(): void {
  }

  public onDropped(event: DragDrop<string[]>): void {
    if (!this.programExclusions) {
      this.programExclusions = [];
    }
    this.programExclusions.push(
      Object.assign(new ConfiguredExclusion(), this.configuredExclusions.find(configuredExclusion => configuredExclusion.id === event.item.data))
    );
  }

  public deleteExclusionConfiguration(exclusionIndex: number): void {
    this.programExclusions.splice(exclusionIndex, 1);
  }

  public validate(): boolean {
    const validationStatusList: boolean[] = [];
    this.customProgramExclusionConfiguationComponents.forEach(customProgramExclusionConfiguationComponent => {
      validationStatusList.push(customProgramExclusionConfiguationComponent.validate());
    });
    return validationStatusList.every(isValid => isValid === true);
  }

  public buildExclusions(): ConfiguredExclusion[] {
    const configuredExclusion: ConfiguredExclusion[] = [];
    this.customProgramExclusionConfiguationComponents.forEach(customProgramExclusionConfiguationComponent => {
      configuredExclusion.push(customProgramExclusionConfiguationComponent.buildExclusion());
    });
    return configuredExclusion;
  }

  public isPristine(): boolean {
    return this.customProgramExclusionConfiguationComponents.toArray().every(customProgramExclusionConfiguationComponent => {
      return customProgramExclusionConfiguationComponent.isPristine();
    });
  }

  public markAsPristine(): void {
    this.customProgramExclusionConfiguationComponents.forEach(customProgramExclusionConfiguationComponent => {
      customProgramExclusionConfiguationComponent.markAsPristine();
    });
  }

}