import { DelayType } from './delay-type.enum';

export class ActionDelay {

  delay: string;
  delayType: DelayType;
  delaySubtype: string;

  public constructor(actionDelay = null) {
    if (actionDelay) {
      this.delayType = actionDelay.delayType;
      this.delay = actionDelay.delay;
      this.delaySubtype = actionDelay.delaySubtype;
    }
  }

}