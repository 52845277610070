import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProgramData } from 'src/app/shared/models/program-data';
import { ConfiguredEvent } from '../../shared/models/configured-event.model';
import { ActionType } from '../../shared/models/permission/feature/action-type';
import { AuthorizationService } from '../../shared/services/authorization-service';
import { PackagedProgramAction } from '../../shared/models/action/packaged-program-action';
import { DelayInputDetails, InputSettingDetails } from '../../shared/models/input-setting-details';
import { ProgramsHeaderComponent } from './programs-header/programs-header.component';

@Injectable({
  providedIn: 'root'
})
export class ProgramSharedDataService {

  public programData: ProgramData;
  public isApiCallForNameUpdate = new BehaviorSubject<boolean>(false);
  public currentApiFlag = this.isApiCallForNameUpdate.asObservable();
  public eventSchemaMap = new Map<string, EventRecord>();
  public eventRecord = new EventRecord();
  public ruleApiActionMap: Map<string, PackagedProgramAction[]> = new Map<string, PackagedProgramAction[]>();
  public apiActionIdScheduleMapClone = '[]';
  public apiActionIdScheduleMap = new Map<string, DelayInputDetails>();
  public companyId: string;
  public programInput = new Map<string, InputSettingDetails>();
  public isDataSaved = false;
  public apiCallCount = 0;
  public isApiServiceCalled = false;
  public canNavigateAway = false;
  public isDeleteOperation = false;
  public header: ProgramsHeaderComponent;

  constructor(public authorizationService: AuthorizationService) { }

  getProgramData(): ProgramData {
    return (this.programData === null || this.programData === undefined) ? new ProgramData() : this.programData;
  }
  setProgramData(data: ProgramData) {
    this.programData = data;
  }

  getEventSchemaMap(): Map<string, EventRecord> {
    return this.eventSchemaMap;
  }

  isReadOnly(): boolean {
    if (this.programData.tenantProgram.active) {
      return true;
    } else if (this.programData.tenantProgram.isPackagedProgram()) {
      return !this.authorizationService.isAllowed('PKG_PRG', [ActionType.CREATE, ActionType.UPDATE]);
    } else if (this.programData.tenantProgram.isCustomWithExternalRules() || this.programData.tenantProgram.isCustomWithExternalPackagedRules()) {
      return !this.authorizationService.isAllowed('CUST_PRG', [ActionType.CREATE, ActionType.UPDATE]);
    }
    return false;
  }

  setApiCallForNameUpdate(flag: boolean): void {
    this.isApiCallForNameUpdate.next(flag);
  }

  public addToEventSchemaMap(configuredEvent: ConfiguredEvent): void {
    const name = configuredEvent.eventType ? configuredEvent.eventType : configuredEvent.name;
    this.eventSchemaMap = this.getEventSchemaMap();
    this.eventRecord = new EventRecord();
    this.eventRecord.sample = JSON.stringify(configuredEvent.getSampleEventSchema(), undefined, 4);
    this.eventRecord.schema = configuredEvent.getSchemaForDisplay();
    this.eventRecord.recordAdded = true;
    this.eventRecord.errors = null;
    this.eventSchemaMap.set(name, this.eventRecord);
  }

  public setProgramActionDestinationsFrom(parentCustomActionDestinations: any[]) {
    const programActionDestinationIds = this.programData.tenantProgram.getActionDestinationIds();
    this.programData.actionDestinations = parentCustomActionDestinations.filter(actionDest => programActionDestinationIds.some(id => actionDest.actionDestination === id));
  }

}

export class EventRecord {
  public sample: string;
  public schema: string;
  public recordAdded: boolean;
  public errors: {type: string; data: string[]};
}
