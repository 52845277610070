import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientFormComponent } from 'src/app/shared/component/client-form/client-form.component';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';

@Component({
  selector: 'app-parents-configuration',
  templateUrl: './parents-configuration.component.html'
})
export class ParentsConfigurationComponent extends BaseFormDirective {
  @ViewChild(ClientFormComponent) child: ClientFormComponent;

  public canNavigateAway(): boolean {
    return this.child.canNavigateAway();
  }
}
