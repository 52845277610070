

export class TenantSetting {

  public id: string;
  public name: string;
  public visible: boolean;
  public groups: Group[];

  constructor(result?: Object) {
    Object.assign(this, JSON.parse(JSON.stringify(result)));
  }

}

export class Group {

  public name: string;
  public title: string;
  public settings: Setting[];
  public verticalType: string;
  public parentId: string;

}

export class Setting {

  public description: string;
  public name: string;
  public minValue: string;
  public defaultValue: string;
  public updatedValue: string;
  public maxValue: string;
  public replacementKey: string;
  public subType: string;
  public type: string;
  public isEdit: boolean;

}