import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants, FormOnSaveAction } from 'src/app/shared/constants';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ExclusionService } from '../../../shared/services/exclusion.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfiguredExclusion } from '../../../shared/models/exclusion/configured-exclusion';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '../../client/base/base.component';
import { EntityType } from 'src/app/shared/models/entity-type';

@Component({
  selector: 'app-exclusions-home',
  templateUrl: './exclusions-home.component.html'
})
export class ExclusionsHomeComponent extends BaseComponent implements OnInit, OnDestroy {

  public isDataLoading = false;
  public properties;
  public configuredExclusionTableList = [];
  public createExclusionRouterLink = Constants.CREATE_EXCLUSION_ROUTER_LINK;
  public configuredExclusionTableFilteredData: ConfiguredExclusion[] = [] ;
  searchByName: string;
  readConfiguredExclusionsList: ConfiguredExclusion[];
  private parentId: string;
  private configuredExclusions: ConfiguredExclusion[];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public parentContextService: ParentContextService,
    public authorizationService: AuthorizationService,
    private exclusionService: ExclusionService,
    private datePipe: DatePipe
  ) {
    super();
    this.initDataTableConfig();
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.getAllExclusions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  handleSearch(searchInput: string) : void {
    if (searchInput === null || searchInput === undefined) {
      searchInput = '';
    }
    if (this.searchByName !== searchInput) {
      this.searchByName = searchInput.trim();
      this.filterConfiguredEvents();
    }
  }

  filterConfiguredEvents(): void {
    if (this.searchByName && this.searchByName.trim()) {
      this.searchByName = this.searchByName.trim();
      this.configuredExclusions = this.readConfiguredExclusionsList
        .filter((exclusion: ConfiguredExclusion) => {
          return exclusion.name.toLowerCase().includes(this.searchByName.toLowerCase());
        });
    } else {
      this.configuredExclusions = this.readConfiguredExclusionsList;
    }
    this.initConfiguredExclusionTableList();
  }

  getAllExclusions(): void {
    this.isDataLoading = true;
    this.exclusionService.getConfiguredExclusionByParent(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.readConfiguredExclusionsList = res.result as ConfiguredExclusion[];
        this.configuredExclusions = res.result as ConfiguredExclusion[];
        this.initConfiguredExclusionTableList();
        this.isDataLoading = false;
      }, () => {
        this.isDataLoading = false;
      }
    );
  }

  private initConfiguredExclusionTableList(): void {
    if (!this.configuredExclusions) {
      return;
    }
    this.configuredExclusionTableFilteredData = [];
    this.configuredExclusions.forEach(exclusion => {
      exclusion.typeNameForDisplay = EntityType.getDisplayName(exclusion.type);
      exclusion.modifiedDate = this.datePipe.transform(new Date(exclusion.modifiedDate), Constants.DATE_TIME_FORMAT);
      exclusion.routerLink = Constants.EXCLUSIONS_HOME_ROUTER_LINK + '/' + exclusion.type + '/' + FormOnSaveAction.EDIT + '/' + exclusion.id;
      this.configuredExclusionTableFilteredData.push(exclusion);
    });
    this.configuredExclusionTableList = this.configuredExclusionTableFilteredData.slice(0, 10);
  }

  private initDataTableConfig() {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Type',
          key: 'typeNameForDisplay',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Description',
          key: 'description',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

}
