import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientHeaderComponent } from './client-header/client-header.component';
import { EventSourceProvidersComponent } from './event-source-providers/event-source-providers.component';
import { ActionDestinationsComponent } from './action-destinations/action-destinations.component';
import { EventSettingsCanDeactivateGuardService } from './event-source-providers/event-settings-can-deactivate-guard.service';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { BatchExtractHomeComponent } from './batch-extract/batch-extract-home/batch-extract-home.component';
import { CustomSettingsComponent } from './custom-settings/custom-settings.component';
import { UserRolesHeaderComponent } from './user-roles/user-roles-header/user-roles-header.component';
import { UserGroupsComponent } from './user-roles/user-groups/user-groups.component';
import { UsersComponent } from './user-roles/users/users.component';
import { UserRolesComponent } from './user-roles/roles/user-roles.component';
import { UserGroupConfigurationComponent } from './user-roles/user-group-configuration/user-group-configuration.component';
import { RoleConfigurationComponent } from './user-roles/role-configuration/role-configuration.component';
import { SupportHeaderComponent } from './support/support-header/support-header.component';
import { IMSLookUpComponent } from './support/ims-lookup/ims-lookup.component';
import { EntityStateLookupComponent } from './support/entity-state-lookup/entity-state-lookup.component';
import { UserGroupConfigurationCanDeactivateGuard } from './user-roles/user-group-configuration/user-group-configuration-can-deactivate.guard';
import { RoleConfigurationCanDeactivateGuard } from './user-roles/role-configuration/role-configuration-can-deactivate.guard';
import { ImsInsertComponent } from './support/ims-insert/ims-insert.component';
import { ActivityLookupComponent } from './support/activity-lookup/activity-lookup.component';
import { ClientFormComponent } from 'src/app/shared/component/client-form/client-form.component';
import { AdminGuard } from 'src/app/modules/admin/admin-guard';
import { UnsavedChangesGuard } from '../../shared/guard/unsaved-changes.guard';
import { EntityContentConfigurationComponent } from './support/entity-content/entity-content-configuration/entity-content-configuration.component';
import { EntityContentHomeComponent } from './support/entity-content/entity-content-home/entity-content-home.component';

const routes: Routes = [
  {
    path: 'client',
    component: ClientHeaderComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'details/edit',
        pathMatch: 'prefix'
      },
      {
        path: 'details/edit',
        canActivate: [AdminGuard],
        canDeactivate: [UnsavedChangesGuard],
        component: ClientFormComponent
      },
      {
        path: 'eventSourceProvider',
        component: EventSourceProvidersComponent,
        canDeactivate: [EventSettingsCanDeactivateGuardService]
      },
      {
        path: 'ActionDestinations',
        component: ActionDestinationsComponent
      },
      {
        path: 'BatchExtractHome',
        loadChildren: () => import('src/app/modules/client/batch-extract/batch-extract.module').then(m => m.BatchExtractModule),
        component: BatchExtractHomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'packagedsettings',
        component: CustomSettingsComponent
      },
      {
        path: 'admin',
        component: UserRolesHeaderComponent,
        children: [
          { path: '', redirectTo: 'usergroups', pathMatch: 'prefix' },
          { path: 'usergroups', component: UserGroupsComponent },
          { path: 'users', component: UsersComponent },
          { path: 'roles', component: UserRolesComponent }
        ]
      },
      {
        path: 'support',
        component: SupportHeaderComponent,
        children: [
          { path: '', redirectTo: 'imslookup', pathMatch: 'prefix' },
          { path: 'imslookup', component: IMSLookUpComponent },
          { path: 'imsinsert', component: ImsInsertComponent },
          { path: 'entitystatelookup', component: EntityStateLookupComponent },
          { path: 'activitylookup', component: ActivityLookupComponent },
          { path: 'rmninsert', component: EntityContentConfigurationComponent },
          { path: 'rmncontents', component: EntityContentHomeComponent },
          { path: 'rmnupdate/:contentId', component: EntityContentConfigurationComponent },
          { path: 'rmnupdate/:companyId/:contentId', component: EntityContentConfigurationComponent }
        ]
      },
      {
        path: 'admin/usergroups/:action',
        component: UserGroupConfigurationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UserGroupConfigurationCanDeactivateGuard]
      },
      {
        path: 'admin/usergroups/:type/:action/:id',
        canActivate: [AuthGuard],
        component: UserGroupConfigurationComponent,
        canDeactivate: [UserGroupConfigurationCanDeactivateGuard]
      },
      {
        path: 'admin/roles/:type/:action/:id',
        component: RoleConfigurationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [RoleConfigurationCanDeactivateGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }