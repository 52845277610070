import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { RuleService } from 'src/app/shared/services/rule.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ConfiguredRule } from 'src/app/shared/models/configured-rule.model';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '../../client/base/base.component';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';

@Component({
  selector: 'app-rules-home',
  templateUrl: './rules-home.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RulesHomeComponent extends BaseComponent implements OnInit {

  @ViewChild('nameColumnTemplate', { static: true })
  nameColumnTemplate: TemplateRef<any>;
  @ViewChild('modfiedDateColumnTemplate', { static: true })
  modfiedDateColumnTemplate: TemplateRef<any>;

  parentId: string;
  configuredRules: ConfiguredRule[];
  // Table configuration
  public isDataLoading = false;
  configuredRuleTableList = [];
  searchByName: string;
  readConfiguredRulesList: ConfiguredRule[];
  pageIndices;
  configuredRuleTableFilteredData = [];
  public loadingIndicator = false;
  public properties = {};
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public parentContextService: ParentContextService,
    private ruleService: RuleService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    public authorizationService: AuthorizationService) {
    super();
    this.initDataTableConfig();
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.getConfiguredRules();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  handleSearch(searchInput: string) : void {
    if (searchInput === null || searchInput === undefined) {
      searchInput = '';
    }
    if (this.searchByName !== searchInput) {
      this.searchByName = searchInput.trim();
      this.filterConfiguredEvents();
    }
  }

  filterConfiguredEvents(): void {
    if (this.searchByName && this.searchByName.trim()) {
      this.searchByName = this.searchByName.trim();
      this.configuredRules = this.readConfiguredRulesList
        .filter((rule: ConfiguredRule) => {
          return rule.name.toLowerCase().includes(this.searchByName.toLowerCase());
        });
    } else {
      this.configuredRules = this.readConfiguredRulesList;
    }
    this.initConfiguredRuleTableList();
  }

  private handleRuleSort(sort) {
    this.configuredRuleTableList = this.handleSort(sort, this.configuredRuleTableFilteredData);
  }

  private handleRulePageChange(pageData) {
    this.configuredRuleTableList = this.handlePageChange(pageData, this.configuredRuleTableFilteredData);
  }

  private getConfiguredRules(): void {
    this.loadingIndicator = true;
    this.ruleService.getConfiguredRules(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((res: ConfiguredRule[]) => {
      this.readConfiguredRulesList = res['result'];
      this.configuredRules = res['result'];
      this.initConfiguredRuleTableList();
      this.loadingIndicator = false;
      this.isDataLoading = false;
    }, error => {
      this.loadingIndicator = false;
      this.isDataLoading = false;
    });
  }

  private initDataTableConfig(): void {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Rule Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Event Source Provider',
          key: 'eventSourceName',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Event Name',
          key: 'eventName',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Description',
          key: 'description',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private initConfiguredRuleTableList(): void {
    const eventSourceMap = EventSourceService.getPackagedEventSourceMap();
    if (!this.configuredRules || this.configuredRules === undefined) {
      return;
    }
    this.configuredRuleTableFilteredData = [];
    this.configuredRules.forEach(rule => {
      const configuredRuleTableRow = {};
      configuredRuleTableRow['id'] = rule.id;
      configuredRuleTableRow['name'] = rule.name;
      configuredRuleTableRow['eventSourceName'] = rule.eventKey.eventSourceName
      = eventSourceMap.has(rule.eventKey.eventSourceName) ? eventSourceMap.get(rule.eventKey.eventSourceName) : rule.eventKey.eventSourceName;
      configuredRuleTableRow['eventName'] = rule.eventKey.eventName;
      configuredRuleTableRow['description'] = rule.description;
      const modifiedDate = this.datePipe.transform(new Date(rule.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
      configuredRuleTableRow['modifiedDate'] = modifiedDate;
      configuredRuleTableRow['modifiedBy'] = rule.modifiedBy;
      configuredRuleTableRow['routerLink'] = '/rules/edit/' + rule.id;
      this.configuredRuleTableFilteredData.push(configuredRuleTableRow);
    });
    this.configuredRuleTableList = this.configuredRuleTableFilteredData.slice(0, 10);

  }

}
