import { Component, OnInit, OnChanges, Output, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Permission } from 'src/app/shared/models/permission/role/permission';
import { Action } from 'src/app/shared/models/permission/feature/action';
import { ActionType } from 'src/app/shared/models/permission/feature/action-type';
import { Feature, FeatureDisplayName } from 'src/app/shared/models/permission/feature/role-permission-constants';

@Component({
  selector: 'app-feature-permissions',
  templateUrl: './feature-permissions.component.html',
  styleUrls: ['./feature-permissions.component.scss']
  })
export class FeaturePermissionsComponent implements OnInit, OnChanges {

  @Input() public inputPermissionList: Permission[];
  @Input() public isFormReadOnly: boolean;

  @Output() public updateCategoryPermissions = new EventEmitter<{ isSelected: boolean; selectedActionType: ActionType }>();

  public featurePermissionFormGroup: UntypedFormGroup;

  ngOnInit(): void {
    this.buildPermissionsForm();
    this.loadFeaturePermissionForm();
  }

  ngOnChanges(): void {
    this.buildPermissionsForm();
    this.loadFeaturePermissionForm();
  }

  public get permissions(): UntypedFormArray {
    return this.featurePermissionFormGroup.get('permissions') as UntypedFormArray;
  }

  public buildPermissions(): Permission[] {
    const formArray = this.featurePermissionFormGroup.controls.permissions as UntypedFormArray;
    const permissions: Permission[] = [];
    formArray.controls.forEach((element: UntypedFormGroup) => {
      const permission = new Permission();
      permission.feature = element.controls.featureController.value as Feature;
      permission.actions = [
        this.buildAction(ActionType.UPDATE, element.controls.writeFormControl.value),
        this.buildAction(ActionType.READ, element.controls.readFormControl.value),
        this.buildAction(ActionType.DELETE, element.controls.deleteFormControl.value),
        this.buildAction(ActionType.CREATE, element.controls.writeFormControl.value)
      ];
      permissions.push(permission);
    });
    return permissions;
  }

  public getDisplayNameforFeature(feature: Feature): string {
    return FeatureDisplayName[feature];
  }

  public updateAllFeaturePermission(selected: boolean, actionType: ActionType): void {
    const formArray = this.featurePermissionFormGroup.controls.permissions as UntypedFormArray;
    formArray.controls.forEach((control: UntypedFormGroup) => {
      switch (actionType) {
        case ActionType.READ:
          control.controls.readFormControl.setValue(selected);
          break;
        case ActionType.CREATE:
          control.controls.writeFormControl.setValue(selected);
          break;
        case ActionType.DELETE:
          control.controls.deleteFormControl.setValue(selected);
          break;
      }
    });
  }

  public onFeaturePermissionChange(event, actionType: string): void {
    const selectedActionType = actionType as ActionType;
    const selected = event.target.checked;
    this.updateCategoryPermissions.emit({ isSelected: selected, selectedActionType: selectedActionType });
  }

  public areAllFeaturePermissionsEqual(selected: boolean, actionType: ActionType): boolean {
    const formArray = this.featurePermissionFormGroup.controls.permissions as UntypedFormArray;
    const areAllFeaturePermissionsEqual = element => element.every(function(control: UntypedFormGroup) {
      switch (actionType) {
        case ActionType.READ:
          return control.controls.readFormControl.value === selected;
        case ActionType.CREATE:
          return control.controls.writeFormControl.value === selected;
        case ActionType.DELETE:
          return control.controls.deleteFormControl.value === selected;
      }
    });
    return areAllFeaturePermissionsEqual(formArray.controls);
  }

  private buildPermissionsForm(): void {
    this.featurePermissionFormGroup = new UntypedFormGroup({
      permissions: new UntypedFormArray([
      ])
    });
  }

  private loadFeaturePermissionForm(): void {
    this.inputPermissionList.forEach(permission => {
      if (permission !== undefined) {
        this.addPermission(permission.feature, permission.actions);
      }
    });
  }

  private addPermission(feature: Feature, actions: Action[]): void {
    const control = <UntypedFormArray> this.featurePermissionFormGroup.controls['permissions'];
    control.push(new UntypedFormGroup({
      featureController: new UntypedFormControl({ value: feature, disabled: this.isFormReadOnly }),
      readFormControl: new UntypedFormControl({
        value: Object.values(actions).filter(action =>
          action.actionType === ActionType.READ)[0].allowed, disabled: this.isFormReadOnly
      }),
      writeFormControl: new UntypedFormControl({
        value: Object.values(actions).filter(action =>
          action.actionType === ActionType.CREATE)[0].allowed, disabled: this.isFormReadOnly
      }),
      deleteFormControl: new UntypedFormControl({
        value: Object.values(actions).filter(action =>
          action.actionType === ActionType.DELETE)[0].allowed, disabled: this.isFormReadOnly
      })
    }));
  }

  private buildAction(actionType: ActionType, allowed: boolean): Action {
    const action = new Action();
    action.actionType = actionType;
    action.allowed = allowed;
    return action;
  }

}