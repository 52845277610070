import { Component, OnInit, TemplateRef, ViewChild, Inject, Input } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { ConfiguredRoleTableRow } from 'src/app/shared/models/user-roles/configured-role-table-row';
import { Role } from 'src/app/shared/models/permission/role/role';
import { ReplaySubject } from 'rxjs';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { takeUntil } from 'rxjs/operators';
import { ServiceResponse } from 'src/app/shared/models/service-response';
import { Messages } from 'src/app/shared/message';
import { FormOnSaveAction } from 'src/app/shared/constants';
import { PopupMessageService } from '../../../../shared/services/popup-message.service';
import { Type } from 'src/app/shared/models/roleType';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { RolesService } from 'src/app/shared/services/roles-service-interface';
import { ROLES_SERVICE_TOKEN } from 'src/app/shared/tokens';
import { Router } from '@angular/router';
import { ActionType } from 'src/app/shared/models/permission/feature/action-type';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html'
})
export class UserRolesComponent extends BaseComponent implements OnInit {

  @ViewChild('createDate', { static: true })
  public createDateColumn: TemplateRef<any>;
  @ViewChild('modifiedDate', { static: true })
  public modifiedDateColumn: TemplateRef<any>;
  @ViewChild(CoreuiModalWarningComponent, { static: true })
  private warningModal: CoreuiModalWarningComponent;

  public createRoleRouterLink = this.rolesService.getCreateRouterLink() + '/' + FormOnSaveAction.CREATE + '/id';
  public properties = {};
  public configuredRolesTableList: ConfiguredRoleTableRow[] = [];
  public configuredAllRoles: ConfiguredRoleTableRow[] = [];
  public configuredRolesTableFilteredData: ConfiguredRoleTableRow[] = [];
  public configuredFilteredRole: Role[] = [];
  public loadingIndicator = false;
  public isDataLoading = false;
  public searchByAttribute: string;
  public isSearching = false;
  public userGroupsForParent = new Set<string>();
  public userGroups: any[];
  public minCharForSearchMsg: string;
  private configuredRoles: Role[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private parentId: string;
  private parentContextService: ParentContextService;
  private toBeDeletedId: string;

  public constructor(
    parentContextService: ParentContextService,
    private popupService: PopupMessageService,
    @Inject(ROLES_SERVICE_TOKEN) private rolesService: RolesService,
    private router: Router,
    public authorizationService: AuthorizationService) {
    super();
    this.parentContextService = parentContextService;
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.initDataTableConfig();
    this.getAllRoles();
  }

  public handleRolesPageChange(pageData: { currentPage: number; rowsPerPage: number; indices: { start: number; end: number } }): void {
    this.pageIndices = { ...pageData.indices };
    this.configuredRolesTableList = this.configuredRolesTableFilteredData.slice(
      pageData.indices.start,
      pageData.indices.end
    );
  }

  public handleSearch(searchInput : string): void {
    if (this.searchByAttribute !== searchInput) {
      this.searchByAttribute = searchInput.toString();
      this.searchConfiguredRoles();
    }
  }

  public handleClearSearch(): void {
    this.searchByAttribute = '';
    this.initConfiguredRolesTableList();
  }

  public handleRolesSort(sort): void {
    this.configuredRolesTableList = this.handleSort(sort, this.configuredRolesTableFilteredData);
  }

  public filterByUserGroups(filterVal: string[]): void {
    if (filterVal.length > 0) {
      filterVal.sort((a, b) => a < b ? -1 : 0);
      this.configuredRolesTableFilteredData = this.configuredAllRoles.filter(
        ur => ur.userGroups === filterVal.toString() || filterVal.includes(ur.userGroups) || ur.userGroups.includes(filterVal.toString()));
    } else {
      this.configuredRolesTableFilteredData = this.configuredAllRoles;
    }
    this.configuredRolesTableList = this.configuredRolesTableFilteredData.slice(0, 10);
  }

  public actionClick(event: {action: string; rowId: string}): void {
    if ( event.action === 'delete') {
      this.handleDeleteRole(event.rowId);
    }
  }

  private initDataTableConfig() {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Type',
          key: 'type',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Description',
          key: 'description',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.modifiedDateColumn
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private getAllRoles(): void {
    this.configuredAllRoles = [];
    this.configuredRolesTableFilteredData = [];
    this.configuredRolesTableList = [];
    this.userGroups = [];
    this.loadingIndicator = true;
    this.isDataLoading = true;
    this.rolesService.getAllRoles(
      this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(
      (res: ServiceResponse) => {
        this.configuredRoles = res.result as Role[];
        this.initConfiguredRolesTableList();
        this.loadingIndicator = false;
        this.isDataLoading = false;
      }, () => {
        this.loadingIndicator = false;
        this.isDataLoading = false;
      });
    this.rolesService.getAllRoles(
      this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(
      (res: ServiceResponse) => {
        this.configuredRoles = res.result as Role[];
        this.initConfiguredRolesTableList();
        this.loadingIndicator = false;
        this.isDataLoading = false;
      }, () => {
        this.loadingIndicator = false;
        this.isDataLoading = false;
      });
  }

  public isRoleCreateAllowed(): boolean{
    return this.authorizationService.isAllowed(this.rolesService.getFeatureName(),  [ActionType.CREATE, ActionType.UPDATE]);
  }

  private initConfiguredRolesTableList() {
    if (!this.configuredRoles || this.configuredRoles === undefined) {
      return;
    }
    this.configuredAllRoles = [];
    this.configuredRoles.forEach((nextConfiguredRole: Role) => {
      this.configuredAllRoles.push(this.buildConfiguredRolesTableRow(nextConfiguredRole));
    });
    this.configuredRolesTableFilteredData = this.configuredAllRoles;
    this.configuredRolesTableList = this.configuredRolesTableFilteredData.slice(0, 10);
  }

  private buildConfiguredRolesTableRow(configuredRole: Role): ConfiguredRoleTableRow {
    return new ConfiguredRoleTableRow(configuredRole, this.rolesService);
  }

  private searchConfiguredRoles() {
    this.configuredRolesTableFilteredData = this.configuredAllRoles
      .filter((configuredRole: ConfiguredRoleTableRow) => {
        return configuredRole.name.toLowerCase().includes(this.searchByAttribute.toLowerCase())
           || configuredRole.description.toLowerCase().includes(this.searchByAttribute.toLowerCase())
           || (configuredRole.userGroups === undefined ? '' : configuredRole.userGroups.toLowerCase().includes(this.searchByAttribute.toLowerCase()));
      });
    this.configuredRolesTableList = this.configuredRolesTableFilteredData.slice(0, 10);
  }

  private handleDeleteRole(id: string): void {
    this.toBeDeletedId = id;
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteRole,
      msg: Messages.deleteRoleWarningMessage,
      msg2: [Messages.deleteRoleWarningMessage2]
    });
  }

}
