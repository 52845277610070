import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { UserGroupDetails } from 'src/app/shared/models/user-groups/user-group-details';
import { BaseComponent } from '../../base/base.component';
import { Constants, NotificationStyle } from 'src/app/shared/constants';
import { ProgramStatus } from 'src/app/shared/program-status';
import { Messages } from 'src/app/shared/message';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { UserGroupDetailsTableRow } from 'src/app/shared/models/user-groups/user-group-details-table-row';
import { DatePipe } from '@angular/common';
import { Type } from 'src/app/shared/models/roleType';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { USER_GROUP_SERVICE_TOKEN } from 'src/app/shared/tokens';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { UserGroupService } from 'src/app/shared/services/user-group-service-interface';
import { ActionType } from 'src/app/shared/models/permission/feature/action-type';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html'
})
export class UserGroupsComponent extends BaseComponent implements OnInit {

  @ViewChild('createdDateColumn', { static: true }) public createdDateColumn: TemplateRef<any>;
  @ViewChild('modifiedDateColumn', { static: true }) public modifiedDateColumn: TemplateRef<any>;
  @ViewChild(CoreuiModalWarningComponent, { static: true }) private warningModal: CoreuiModalWarningComponent;

  public createUserGroupRouterLink = this.userGroupService.getNavPath() + '/createUserGroup';
  public isPageLoading = false;
  public properties = {};
  public parentContextService: ParentContextService;
  public userGroupList: UserGroupDetailsTableRow[] = [];
  public readUserGroupList: UserGroupDetailsTableRow[] = [];
  public isSearching = false;
  public userGroupTableFilteredData = [];
  public currentUrl: string;
  public isAdmin: boolean;
  private configuredUserGroups: UserGroupDetails[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private searchByAttribute: string;
  private parentId: string;
  private toBeDeletedId: string;
  private datePipe: DatePipe;
  constructor(parentContextService: ParentContextService,
    @Inject(USER_GROUP_SERVICE_TOKEN) private userGroupService: UserGroupService,
    public router: Router,
    private popupService: PopupMessageService,
    datePipe: DatePipe,
    public authorizationService: AuthorizationService) {
    super();
    this.parentContextService = parentContextService;
    this.parentId = this.parentContextService.getParentContext();
    this.datePipe = datePipe;
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.initDataTableConfig();
    this.getUserGroups();
  }

  public handleUserGroupSort(sort: {column: string; order: string}): void {
    if (sort.order === Constants.TABLE_SORT_ASCENDING) {
      this.sortByKeyAsc(this.userGroupTableFilteredData, sort.column);
    } else {
      this.sortByKeyDesc(this.userGroupTableFilteredData, sort.column);
    }
    this.userGroupList = this.userGroupTableFilteredData.slice(
      this.pageIndices.start,
      this.pageIndices.end
    );
  }

  public actionClick(event: {action: string; rowId: string}): void {
    this.handleDeleteUserGroup(event.rowId);
  }

  public handleUserGroupPageChange(pageData: { currentPage: number; rowsPerPage: number; indices: { start: number; end: number } }): void {
    this.pageIndices = { ...pageData.indices };
    this.userGroupList = this.userGroupTableFilteredData.slice(
      pageData.indices.start,
      pageData.indices.end
    );
  }

  public handleSearch(searchInput: string): void {
    if (this.searchByAttribute !== searchInput) {
      this.searchByAttribute = searchInput.toString();
      this.filterUserGroups();
    }
  }

  public handleClearSearch(): void {
    this.searchByAttribute = '';
    this.initUserGroupTableList();
  }

  public isUserGroupCreateAllowed(): boolean {
    return this.authorizationService.isAllowed(this.userGroupService.getFeatureName(), [ActionType.CREATE, ActionType.UPDATE]);
  }


  private handleDeleteUserGroup(id: string): void {
    this.toBeDeletedId = id;
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteUserGroups,
      msg: Messages.deleteUserGroupWarningMessage,
      msg2: [Messages.deleteUserGroupWarningMessage2]
    });
  }


  private initUserGroupTableList() {
    if (!this.configuredUserGroups || this.configuredUserGroups === undefined) {
      return;
    }
    this.readUserGroupList = [];
    this.configuredUserGroups.forEach((nextUserGroup: UserGroupDetails) => {
      this.readUserGroupList.push(this.setTableListData(nextUserGroup));
    });
    this.userGroupTableFilteredData = this.readUserGroupList;
    this.userGroupList = this.userGroupTableFilteredData.slice(0, 10);
  }

  private filterUserGroups(): void {
    this.userGroupTableFilteredData = this.readUserGroupList
      .filter((userGroup: UserGroupDetailsTableRow) => {
        return userGroup.name.toLowerCase().includes(this.searchByAttribute.toLowerCase());
      });
    this.userGroupList = this.userGroupTableFilteredData.slice(0, 10);
  }

  private getUserGroups(): void {
    this.userGroupService.getAllUserGroups(this.parentId).subscribe(
      (res: any) => {
        this.configuredUserGroups = res.result;
        this.initUserGroupTableList();
        this.isPageLoading = false;
      }, () => {
        this.isPageLoading = false;
      });
  }

  private initDataTableConfig(): void {
    this.isPageLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Type',
          key: 'type',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Description',
          key: 'description',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Status',
          key: 'status',
          isSortable: true,
          isColumnDisplayed: true,
          statusIndicatorMapping: {
            'incomplete-primary': ProgramStatus.INACTIVE,
            'complete': ProgramStatus.ACTIVE
          }
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.modifiedDateColumn
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private setTableListData(nextUserGroup: UserGroupDetails): UserGroupDetailsTableRow {
    const userGroupTableRow: UserGroupDetailsTableRow = new UserGroupDetailsTableRow();
    userGroupTableRow.id = nextUserGroup.id;
    userGroupTableRow.name = nextUserGroup.name;
    userGroupTableRow.description = nextUserGroup.description;
    userGroupTableRow.type = nextUserGroup.type;
    userGroupTableRow.createdDate = this.datePipe.transform(new Date(nextUserGroup.createdDate), 'MM/dd/yyyy hh:mm aaaa');
    userGroupTableRow.modifiedDate = this.datePipe.transform(new Date(nextUserGroup.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
    userGroupTableRow.modifiedBy = nextUserGroup.modifiedBy;
    userGroupTableRow.status = this.getGroupStatus(nextUserGroup.active);
    userGroupTableRow.routerLink = this.userGroupService.getRouterLink(nextUserGroup);
    return userGroupTableRow;
  }

  private getGroupStatus(active: boolean): ProgramStatus {
    if (active) {
      return ProgramStatus.ACTIVE;
    }
    return ProgramStatus.INACTIVE;
  }

}