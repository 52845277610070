import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { EventsHomeComponent } from './events-home/events-home.component';
import { EventsConfigurationComponent } from './events-configuration/events-configuration.component';
import { UnsavedChangesGuard } from '../../shared/guard/unsaved-changes.guard';
const routes: Routes = [
  {
    path: 'events',
    canActivate: [AuthGuard],
    component: EventsHomeComponent
  },
  {
    path: 'events/:action/:eventSourceProvider/:eventName',
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: EventsConfigurationComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }