import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntityCopy } from '../models/entity-copy.model';

@Injectable({
  providedIn: 'root'
})

export class EntityCopyService {

  host = environment.host;
  base = environment.base;
  copyEntityUrl = 'entity/copy';
  constructor(private http: HttpClient) { }

  public copyEntity(parentId: string, payload: EntityCopy): Observable<any> {
    const url = this.host + this.base + this.copyEntityUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post(url, payload, options);
  }

}