export class Bundle {

  constructor(private id: string, private displayName: string, private isVisible: boolean, private isDefault: boolean) {
  }

  get Id() {
    return this.id;
  }

  set Id(id: string) {
    this.id = id;
  }

  get DisplayName() {
    return this.displayName;
  }

  get IsVisible() {
    return this.isVisible;
  }

  set IsVisible(isVisible: boolean) {
    this.isVisible = isVisible;
  }

  get IsDefault() {
    return this.isDefault;
  }
}
