import { NgModule } from '@angular/core';
import { AuthorizationService } from '../services/authorization-service';


@NgModule({
  providers: []
})
export class AuthorizationModule {

  static forRoot() {
    return {
      ngModule: AuthorizationModule,
      providers: [AuthorizationService]
    };
  }

}