import { Company } from './company';

export enum EventSourceProviderType {
  SELF_SERVICE = 'SELF_SERVICE',
  PACKAGED = 'PACKAGED'
}

export class EventSource {

  status: string;
  id: string;
  displayName: string;
  name: string;
  eventSource: string;
  company: Company[];
  type: EventSourceProviderType;

}

export enum PackagedEventSource {
  CONVERSANT = 'CONVERSANT',
  PROACTIV = 'PROACTIV',
  OTHER = 'Any event source provider'
}

export class ConfiuredEventSource {

  name: string;
  eventSource: string;
  settings: {[name: string]: string}[];
  type: EventSourceProviderType;

}

export enum PackagedEventSourceName {
  CONVERSANT = 'Conversant',
  EPSILON_TAG = 'Epsilon Tag'
}
