import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Routable } from './Routable';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<Routable> {

  constructor() {}

  canDeactivate(component: Routable ): boolean | Observable<boolean> {
    return component.canNavigateAway() ? true : component.getWarningModal().launchUnsavedChangesWarningModal();
  }

}