<coreui-modal #basicModal>
    <app-coreui-modal-warning></app-coreui-modal-warning>
    <bp-loading-indicator *ngIf="isApiServiceCalled" [text]="operationStatusMessage" [kind]="'inPage'">
    </bp-loading-indicator>
    <ng-template #titleTemplate>
      <h2 class="text-style-1 mb-0">{{getModalTitle()}} User</h2>
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
      <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
    </ng-template>
    <ng-template #contentTemplate>
      <form name="userApiForm" [formGroup]="userApiFormGroup" class="horizontal-scroll">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <coreui-form-field for="id" label="Username">
                <input coreUIInput type="text" id="id" name="id" formControlName="id" placeholder="Enter your username">
                <ng-container *ngIf="isSaveClicked && userApiFormGroup.controls.id.invalid">
                  <coreui-form-error *ngIf="userApiFormGroup.controls.id.errors.required">
                    <div> {{messages.idRequired}}</div>
                  </coreui-form-error>
                  <coreui-form-error *ngIf="userApiFormGroup.controls.id.errors.pattern">
                    <div> {{messages.invalidEmailPlaceholderSyntaxErrorMsg}}</div>
                  </coreui-form-error>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="action === 'CREATE'" class="row">
          <div class="col">
            <div class="form-group">
              <coreui-form-field for="email" label="Email">
                <input coreUIInput type="text" id="email" name="email" formControlName="email" class="form-control" placeholder="Enter an epsilon email of a person or a group that manages this API user">
                <ng-container *ngIf="isSaveClicked && userApiFormGroup.controls.email.invalid">
                  <coreui-form-error *ngIf="userApiFormGroup.controls.email.errors.required">
                    <div> {{messages.emailRequired}}</div>
                  </coreui-form-error>
                  <coreui-form-error *ngIf="userApiFormGroup.controls.id.errors.pattern">
                    <div> {{messages.invalidEmailPlaceholderSyntaxErrorMsg}}</div>
                  </coreui-form-error>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="action === 'CREATE'" class="row">
          <div class="col">
            <div class="form-group">
              <coreui-form-field for="secretQuestion" label="Secret Question">
                <input coreUIInput type="text" id="secretQuestion" name="secretQuestion" formControlName="secretQuestion" class="form-control" placeholder="Enter a secret question">
                <ng-container *ngIf="isSaveClicked && userApiFormGroup.controls.secretQuestion.invalid">
                  <coreui-form-error *ngIf="userApiFormGroup.controls.secretQuestion.errors.required">
                    <div> {{messages.secretQuestionRequired}}</div>
                  </coreui-form-error>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="action === 'CREATE'" class="row">
          <div class="col">
            <div class="form-group">
              <coreui-form-field for="secretAnswer" label="Secret Answer">
                <input coreUIInput type="text" id="secretAnswer" name="secretAnswer" formControlName="secretAnswer" class="form-control" placeholder="Enter the secret answer">
                <ng-container *ngIf="isSaveClicked && userApiFormGroup.controls.secretAnswer.invalid">
                  <coreui-form-error *ngIf="userApiFormGroup.controls.secretAnswer.errors.required">
                    <div> {{messages.secretAnswerRequired}}</div>
                  </coreui-form-error>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="action === 'CREATE'" class="row">
          <div class="col">
            <div class="form-group">
              <coreui-form-field for="password" label="Password">
                <input coreUIInput type="password" id="password" name="password" formControlName="password" class="form-control" placeholder="Enter a password with 8 characters and at least one uppercase letter, lowercase letter, number, and special character">
                <ng-container *ngIf="isSaveClicked && userApiFormGroup.controls.password.invalid">
                  <coreui-form-error *ngIf="userApiFormGroup.controls.password.errors.pattern">
                    <div> {{messages.invalidPassword}}</div>
                  </coreui-form-error>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template #buttonsTemplate>
      <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
      <div class="px-sm" *ngIf="authorizationService.isAllowed('USR', ['CREATE','UPDATE'])">
        <app-ae-button *ngIf="action === 'CREATE'" (click)="onSave()" buttonText="Save"></app-ae-button>
      </div>
    </ng-template>
    </coreui-modal>
  