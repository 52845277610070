import { AuthenticationCredential } from './AuthenticationCredential';

export class OauthCredential implements AuthenticationCredential {

  userId: string;
  password: string;
  clientId: string;
  clientSecret: string;
  encoded = false;

  public toString(): string {
    this.encoded = true;
    this.password = btoa(this.password);
    this.clientSecret = btoa(this.clientSecret);
    return `{\"userId\":\"${this.userId}\",\"password\":\"${this.password}\",\"clientId\":\"${this.clientId}\",\"clientSecret\":\"${this.clientSecret}\",\"encoded\":${this.encoded}}`;
  }

  public toJSONString() {
    return {
      'userId': `${this.userId}`,
      'password': `${this.password}`,
      'clientId': `${this.clientId}`,
      'clientSecret': `${this.clientSecret}`
    };
  }

}