import { Component, EventEmitter, Input, Output, OnInit, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/modules/client/base/base.component';
import { AEModalInfoComponent } from '../modal/ae-modal-info/ae-modal-info.component';

@Component({
  selector: 'app-ae-table',
  templateUrl: './ae-table.component.html',
  styleUrls: ['./ae-table.component.scss']
})
export class AETableComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public isDataLoading: boolean;
  @Input() public properties: any[];
  @Input() public configuredTableData: any[];

  @Output() actionClick: EventEmitter<any> = new EventEmitter();
  @Output() radioButtonSelected: EventEmitter<any> = new EventEmitter();
  @Output() menuButtonClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild(AEModalInfoComponent, { static: true }) public infoModal: AEModalInfoComponent;

  public propertiesArray = [];
  public configuredTableList: any[];
  public _array = Array;
  public radioButtonSelection = new FormControl();
  public radioButtonSubscription: Subscription;

  constructor(
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.propertiesArray.push(this.properties);
    if (this.hasRadioButton) {
      this.getRadioButtonSelected();
    }
  }

  ngOnChanges(): void {
    this.configuredTableList = this.configuredTableData.slice(0, 10);
  }

  ngOnDestroy(): void {
    if (this.hasRadioButton) {
      this.radioButtonSubscription.unsubscribe();
    }
  }

  handleEventSort(sortOrder, sortTableHeader) {
    const sort = {
      order: (sortOrder.sortDirection === 'ASC' ? 'ascending' : 'descending'),
      column: (this.propertiesArray.map(item => item.columns)[0].map(item => item.key))
        [(this.propertiesArray.map(item => item.columns)[0].map(item => item.headerText)).indexOf(sortTableHeader)]
    };
    this.configuredTableList = this.handleSort(sort, this.configuredTableData);
  }

  public handleMultiHeaderColumnEventSort(sortOrder: any, sortTablekey: string): void {
    const sort = {
      order: (sortOrder.sortDirection === 'ASC' ? 'ascending' : 'descending'),
      column: sortTablekey
    };
    this.configuredTableList = this.handleSort(sort, this.configuredTableData);
  }

  public getRouterLink(item, actionItem: string): boolean {
    if (actionItem === 'button') {
      void this.router.navigate([item.routerLink]);
    } else {
      return item.routerLink;
    }
  }

  public getDefaultSort(header: string): boolean {
    return (this.propertiesArray.map(item => item.columns)[0].map(item => item.headerText))[0] === header;
  }

  handleEventPageChange(pageData) {
    this.configuredTableList = this.handlePageChange(pageData, this.configuredTableData);
  }

  getActionClick(button, item): void {
    this.actionClick.emit({ action: button.editAction.action, rowId: item.id });
  }

  public onMenuButtonClick(configuredPackage: any): void {
    this.menuButtonClicked.emit(configuredPackage);
  }

  public isAllowNewTab(attribute: unknown): boolean {
    return (attribute['link'].isAllowNewTab === undefined || attribute['link'].isAllowNewTab) ? true : false;
  }

  public showTableCellContentInModal(message: string, phrase: number): void {
    let dataValue;
    let modalTitle;
    try {
      dataValue = JSON.stringify(JSON.parse(message.slice(phrase)), null, 4);
      modalTitle = 'Event Schema';
    } catch (e) {
      dataValue = message;
      modalTitle = 'Message';
    }
    this.infoModal.launchModal({
      'title': modalTitle,
      'description': '',
      'content': {
        'type': 'PLAIN_TEXT',
        'data': dataValue
      }
    });
  }

  public isIcon(columnData: string, phrase: string): boolean {
    if (columnData !== null && columnData.indexOf(phrase) > -1) {
      return true;
    }
  }

  public getRadioButtonSelected(): void {
    this.radioButtonSubscription = this.radioButtonSelection.valueChanges.subscribe(value => {
      this.radioButtonSelected.emit({ rowId: value });
    });
  }

  private get hasRadioButton(): boolean {
    return this.properties === undefined ? false : this.properties['columns'][0].radioButton;
  }

  public isActionMenuButtonEnabled(items: any[]): boolean {
    return items.filter(item => item.isAuthorized === undefined || item.isAuthorized).length > 0;
  }

}