<app-base-template>
    <app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
    <app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
    <app-ae-modal-info></app-ae-modal-info>
    <section class="pt-4 pl-5 pr-4">
        <coreui-breadcrumbs>
            <a routerLink="/iqdecisions" coreUIBreadcrumbLink>{{messages.iqDecisions}}</a>
            <span coreUIBreadcrumbSlash></span>
            <a routerLink="/iqdecisions" coreUIBreadcrumbLink>{{messages.packages}}</a>
            <span coreUIBreadcrumbSlash></span>
            <span coreUIBreadcrumbEnd> {{messages.singleModelPackage}}</span>
        </coreui-breadcrumbs>

        <div class="d-flex align-items-center">
            <i id="single-model-package-icon" coreUIIcon="settings"></i>
            <h1 class="ml-2">{{messages.singleModelPackage}}</h1>
        </div>
        <p class="sub-header">{{messages.packageInfoSubHeader}}</p>
        <hr id="divider">
        <form *ngIf="companyList" [formGroup]="configuredEntityForm">
            <div class="row">
                <div class="col-4">
                    <coreui-form-field [label]="messages.company" for="eventSourceProvider">
                        <coreui-select id="eventSourceProvider" formControlName="company"
                            [placeholder]="messages.selectCompanyOption" (valueChange)="doGetPackagesTypes($event)">
                            <coreui-option [value]="" [disabled]="true">{{messages.selectCompanyOption}}</coreui-option>
                            <coreui-option *ngFor="let item of this.companyList | keyvalue"
                                value="{{selectOptionValue(item.value.companyId, item.value.companyName) | json}}">
                                {{item.value.companyName}}
                            </coreui-option>
                        </coreui-select>
                        <coreui-form-error>
                            <div>{{messages.companyError}}</div>
                        </coreui-form-error>
                    </coreui-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="tooltip-container">
                        <ng-template #nameTooltip>
                            {{tooltipMessages.nameTooltip}}
                        </ng-template>
                        <div class="tooltip-container">
                            <coreui-form-field [label]="messages.packageName">
                                <input coreUIInput name="packageName" formControlName="packageName"
                                    [coreUITooltip]="nameTooltip" position="right" />
                                <coreui-form-error *ngIf="packageName.invalid">
                                    <ng-container *ngIf="packageName.errors.required">
                                        {{messages.packageNameRequiredValidationMessage}}
                                    </ng-container>
                                    <ng-container *ngIf="packageName.errors.alreadyExist">
                                        {{messages.packageNameUniqueValidationMessage}}
                                    </ng-container>
                                    <ng-container *ngIf="packageName.errors?.maxlength">
                                        {{messages.packageNameMaxLengthValidationMessage}}
                                    </ng-container>
                                </coreui-form-error>
                            </coreui-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="tooltip-container">
                        <ng-template #packageTypeTooltip>
                            {{tooltipMessages.packageTypeTooltip}}
                        </ng-template>
                        <div class="tooltip-container">
                            <coreui-form-field [label]="messages.packageType" for="packageType">
                                <coreui-select id="packageType" [placeholder]="messages.selectPackageTypeOption"
                                    formControlName="recommendationType" [coreUITooltip]="packageTypeTooltip"
                                    position="right" (valueChange)="addPackageLevelInput($event)">
                                    <coreui-option [value]=""
                                        [disabled]="true">{{messages.selectPackageTypeOption}}</coreui-option>
                                    <coreui-option *ngFor="let item of packageTypesList;"
                                        [value]="selectOptionValue(item.id, item.name) | json">
                                        {{item.name}}
                                    </coreui-option>
                                </coreui-select>
                                <coreui-form-error>
                                    <div>{{messages.packageTypeError}}</div>
                                </coreui-form-error>
                            </coreui-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showCategoryLevel()">
                <div class="col-4">
                    <div class="tooltip-container">
                        <ng-template #packageLevelTooltip>
                            {{tooltipMessages.packageLevelTooltip}}
                        </ng-template>
                        <div class="tooltip-container">
                            <coreui-form-field [label]="messages.categoryLevel" for="categoryLevel">
                                <coreui-select id="categoryLevel" [placeholder]="messages.selectCategoryLevelOption"
                                    formControlName="choiceType" [coreUITooltip]="packageLevelTooltip" position="right">
                                    <coreui-option [value]=""
                                        [disabled]="true">{{messages.selectCategoryLevelOption}}</coreui-option>
                                    <coreui-option *ngFor="let item of categoryLevelsList;"
                                        [value]="selectOptionValue(item.id, item.name) | json">
                                        {{item.name}}
                                    </coreui-option>
                                </coreui-select>
                                <coreui-form-error>
                                    <div>{{messages.packageLevelError}}</div>
                                </coreui-form-error>
                            </coreui-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <coreui-form-field [label]="messages.MaxItemsReturn">
                        <input coreUIInput [coreUIIntegerMask]="{disallowNegative: true}"
                            (keydown)="preventZeroStartingNumber($event)" type="number" name="maxItems"
                            formControlName="maxItemReturned" />
                        <coreui-form-error>
                            <div>{{messages.maxItemError}}</div>
                        </coreui-form-error>
                    </coreui-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="tooltip-container">
                        <ng-template #descriptionTooltip>
                            {{tooltipMessages.descriptionTooltip}}
                        </ng-template>
                        <div class="tooltip-container">
                            <coreui-form-field [label]="messages.description" for="description">
                                <textarea coreUIInput id="description" [placeholder]="messages.addDescriptionLabel"
                                    formControlName="description" rows="6" [coreUITooltip]="descriptionTooltip"
                                    position="right">
                                    </textarea>
                                <coreui-form-error *ngIf="description.invalid">
                                    <ng-container *ngIf="description.errors.required">
                                        {{messages.recommendationDesciption}}
                                    </ng-container>
                                    <ng-container *ngIf="description.errors.pattern">
                                        {{messages.onlyAlphanumericCharactersError}}
                                    </ng-container>
                                    <ng-container *ngIf="description.errors?.maxlength">
                                        {{messages.descriptionValidation}}
                                    </ng-container>
                                </coreui-form-error>
                            </coreui-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <!-- MODELS SECTION -->
            <div class="">
                <p class="sub-header">{{messages.modelsSubHeader}}</p>
                <hr id="divider">
            </div>
            <div class="row">
                <div class="col-4">
                    <label for="">{{messages.primaryModel}}</label>
                    <app-model-selection-modal (isSelectedModel)="getSelectedModel($event, 'primaryModel')"
                        [models]="models" [buttonLabel]="'{{messages.selectModel}}'">
                        <app-ae-button type="button"
                            *ngIf="!configuredEntityForm.get('primaryModel').value; else primaryModelName"
                            buttonText="{{messages.selectModel}}" buttonType="secondary"></app-ae-button>
                        <ng-template #primaryModelName>
                            <div>
                                <span>{{configuredEntityForm.get('primaryModel').value.name}}</span>
                                <app-ae-button type="button" buttonType="icon-large" icon="edit"></app-ae-button>
                            </div>
                        </ng-template>
                    </app-model-selection-modal>
                </div>
            </div>
            <div class="row pt-4" [hidden]="!configuredEntityForm.get('primaryModel').value">
                <div class="col-4">
                    <label for="">{{messages.fallbackModel}}</label>
                    <app-model-selection-modal (isSelectedModel)="getSelectedModel($event, 'fallbackModel')"
                        [models]="fallbackModelList" [buttonLabel]="'Select Model'">
                        <app-ae-button type="button"
                            *ngIf="!configuredEntityForm.get('fallbackModel').value; else modelName"
                            buttonText="Select Model" buttonType="secondary"></app-ae-button>
                        <ng-template #modelName>
                            <div>
                                <span>{{configuredEntityForm.get('fallbackModel').value.name}}</span>
                                <app-ae-button type="button" buttonType="icon-large" icon="edit"></app-ae-button>
                            </div>
                        </ng-template>
                    </app-model-selection-modal>

                </div>
            </div>

            <!-- MODEL PARAMETERS -->
            <div class="">
                <p class="sub-header">{{messages.modelParameterSubheader}}</p>
                <hr id="divider">
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="tooltip-container">
                        <div class="tooltip-container">
                            <coreui-form-field [label]="messages.inputMessage" for="input">
                                <coreui-select id="input" placeholder="{{messages.selectInputText}}"
                                    formControlName="itemsChoiceType" position="right">
                                    <coreui-option [value]=""
                                        [disabled]="true">{{messages.selectInputText}}</coreui-option>
                                    <coreui-option *ngFor="let item of categoryLevelsList;"
                                        [value]="selectOptionValue(item.id, item.name) | json">
                                        {{item.name}}
                                    </coreui-option>
                                </coreui-select>
                                <coreui-form-error>
                                    <div>{{messages.packageLevelError}}</div>
                                </coreui-form-error>
                            </coreui-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <coreui-form-field label="Items per input level">
                        <input coreUIInput coreUIIntegerMask type="text" name="itemsPerInputLevel"
                            formControlName="itemsPerInputLevel" />
                    </coreui-form-field>
                </div>
            </div>

            <!-- ACTION BUTTONS SECTION -->
            <div class="row pb-4">
                <div class="col-5 pt-5">
                    <app-ae-button [buttonText]="messages.publishBtnText" class="mr-2" buttonType="primary" type="button"
                        (click)="saveChanges(true)" [disabled]="configuredEntityForm.invalid"
                        *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['CREATE','UPDATE'])"></app-ae-button>
                    <app-ae-button [buttonText]="messages.saveForLaterBtnText" class="mr-2" buttonType="secondary" type="button"
                        (click)="saveChanges()"
                        [disabled]="!configuredEntityForm.get('company').value || !configuredEntityForm.get('packageName').value || !configuredEntityForm.get('recommendationType').value"
                        *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['CREATE'])"></app-ae-button>
                    <app-ae-button [buttonText]="messages.cancelBtnTxt" buttonType="secondary" type="button"
                        (click)="cancelButtonClicked()"></app-ae-button>
                </div>
            </div>
        </form>
    </section>
</app-base-template>