import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ae-loading-screen',
  templateUrl: './ae-loading-screen.component.html'
})
export class AELoadingScreenComponent {

  constructor(private route: Router) { }

  public getCurrentRoute(): string {
    return this.route.url;
  }

}