import { AwsUserCredential } from './aws-user-credential';
import { AuthenticationMethod } from './authentication-method';
import { ActionSettings } from './ActionSettings';
import { AuthenticationCredential } from './AuthenticationCredential';

export class AwsActionSettings implements ActionSettings {

  awsUserCredential: AuthenticationCredential;
  region: string;

  public buildActionSettings() {
    const awsCred = this.awsUserCredential as AwsUserCredential;
    return {
      'type': AuthenticationMethod.IAM.value,
      'credentials': `${awsCred.toString()}`
    };
  }
  public buildJSONActionSettings() {
    const awsCred = this.awsUserCredential as AwsUserCredential;
    return {
      'type': AuthenticationMethod.IAM.value,
      'credentials': awsCred.toJSONString()
    };
  }

}