<div class="border align-items-center row mx-1 u-mb-10" [formGroup]="ruleActionForm">
  <div class="col px-0 border-right">     
    <div class="row m-1" *ngIf="configuredAction.hasDurationDelay()">
      <div class="col py-1 pl-2">
        <div class="pl-1">
          <time-duration [index]="index" [parentForm]="ruleActionForm" [durationTitle]="'Delay by:'" [duration]="configuredAction.getDelay()"></time-duration>
        </div>
      </div>
    </div>
    <div class="row m-1" *ngIf="configuredAction.hasAttributeDelay()">
      <div class="col py-1 pl-2">
        <div class='pl-1'>
          <app-delay [index]="index" [delayTitle]="'Delay by:'" [delay]="configuredAction.getDelay()" [selectedEventSchema]="selectedEventSchema" [selectedConfiguredAction]="configuredAction"
                   [validationRequired]="validationRequired" [parentForm]="ruleActionForm" (validationStatus)="validateDelayComponent($event)"></app-delay>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="configuredAction.hasDelay()">
      <div class="col-auto pl-5 pr-0 pt-1">
        <p class="font-weight-bold">THEN</p>
      </div>
      <div class="col pl-0"><hr></div>
    </div>     
    <div class="row m-1 py-1 ml-4">
      <div class="col-xs mt-2 px-1">
        <p>Send notification using</p>
      </div>
      <div class="col-xs mt-2 px-1">
        <p class="font-weight-bold"> {{ configuredAction.name }} </p>
      </div>
      <div class="col-xs mt-2 px-1">
        <p>from</p>
      </div>
      <div class="col-xs mt-2 px-1">
        <p class="font-weight-bold"> {{ configuredActionDestination.name }} </p>
      </div>
      <div class="col-xs mt-2 px-1" *ngIf="placeholders && placeholders.length>0">
        <p>for</p>
      </div>
      <div class="col-xs mt-1 pl-3"
           *ngFor="let placeholder of ruleActionForm.get('placeholders').value; let i = index">
        <div class="row">
          <div class="col-xs mt-1 pr-1 pl-1">
            <label [for]="placeholder">{{placeholder}}</label>
          </div>
          <div class="col-xs pl-2">
            <input coreUIInput type="text" class="form-control"
                   [name]="placeholder"
                   [id]="placeholder + '_' + index"
                   [formControlName]="placeholder"
                   [placeholder]="'Enter ' + placeholder"
                   [size]="getInputTextFieldSize(placeholder)">
            <div *ngIf="!validateActionAttributes(placeholder)" class="text-color-warning">
              Please enter a value
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-1" *ngIf="configuredAction.isModeSynchronous()" >
      <div class="col pl-3">
        <span class="bg-dark text-white px-1">AND</span>
      </div>
    </div>
    <div class="row m-1" *ngIf="configuredAction.isModeSynchronous()" >
      <fieldset [disabled]="isReadOnlyRule">
     <div class="col-xs px-2 py-2 pl-3">
        <p>Create an internal event with event source provider as</p> 
      </div>
      <div class="col-xs px-1 pl-2">
        <coreui-select [id]="'internalEventSourceProviderName_' + configuredAction.name"
          [formControlName]="'internalEventSourceProviderName_' + configuredAction.name"
          placeholder="Select an event source provider" (valueChange)="onEventSourceProviderChange($event)">
          <coreui-option value="" [disabled]="true">Select an event source provider</coreui-option>
          <coreui-option *ngFor="let item of eventSources;" [value]="item.eventSource">{{item.name}}</coreui-option>
        </coreui-select>
      </div>
      <div class="col-xs px-2 py-2 pl-3">
        <p>and event as </p> 
      </div>  
      <div class="col-xs px-1 pl-2">
      <coreui-select [id]="'internalEventName_' + configuredAction.name"
        [formControlName]="'internalEventName_' + configuredAction.name" placeholder="Select an event">
        <coreui-option value="" [disabled]="true">Select an event</coreui-option>
        <coreui-option *ngFor="let item of this.filteredEvents;" [value]="item">{{item}}</coreui-option>
      </coreui-select>
      </div>               
      </fieldset>
    </div>
  </div>
  <div class="col-auto" *ngIf="showDeleteButton">
    <app-ae-button (click)="this.onDeleteAction()" buttonType="micro-feedback-error-secondary" [disabled]="isReadOnlyRule" buttonText="Delete"></app-ae-button>
 </div>
</div>
