import { AttributeCondition } from '../custom-rule/conditions/attribute-condition';
import { Condition } from '../custom-rule/conditions/condition';
import { EntityStateSchema } from '../entity-states/entity-state-schema';

export class Exclusion {

  private condition: Condition;
  private entitySchemas: EntityStateSchema[];

  public getAttributeCondition(): AttributeCondition {
    return this.condition['condition']['conditions'][0];
  }

  public setAttributeCondition(attributeCondition: AttributeCondition): void {
    this.condition['condition']['conditions'][0] = attributeCondition;
  }

  public getCondition(): Condition {
    return this.condition;
  }

  public setCondition(condition: Condition): void {
    this.condition = condition;
  }

  public getEntityStateSchema(): EntityStateSchema {
    return this.entitySchemas[0];
  }

  public setEntityStateSchema(entityStateSchema: EntityStateSchema): void {
    this.entitySchemas = [entityStateSchema];
  }

}