<app-ae-loading-indicator [isLoading]="programDataService.isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
<div class="container-fluid">
  <div class="row" *ngIf="!programDataService.isApiServiceCalled">
    <div class="col text-center" *ngIf="doRulesExistForEvents()">
      <p class="text-style-muted-3">Please create at least one rule to continue creating your program</p>
    </div>
  </div>
  <form *ngIf="!doRulesExistForEvents()" [formGroup]="programForm">
    <div class="row">
      <div id="leftPane" class="border-right col-auto">
        <bp-collapsible-panel>
          <div panel-content class="col-auto vertical-scrollable-leftpane ">
        <form name="searchForm" [formGroup]="searchFormGroup">          
          <coreui-form-field>
            <coreui-autocomplete #autocomplete name="autocomplete" placeholder="Search Rule or Exclusion"
              formControlName="searchTerm" (blur)="onSearchValueSelected()"
              (displayValueChange)="onSearchTermInput($event)">
              <coreui-option class="text-style-action" [disabled]="true" *ngIf="filteredPackagedProgramTemplates.length >0">
                Program Templates
              </coreui-option>
              <coreui-option *ngFor="let packagedProgramTemplate of filteredPackagedProgramTemplates"
                [value]="packagedProgramTemplate.displayName">
                {{ packagedProgramTemplate.displayName }}
              </coreui-option>
              <coreui-option class="text-style-action" [disabled]="true" *ngIf="filteredRuleNames.length>0"> Rules
              </coreui-option>
              <coreui-option *ngFor="let ruleName of filteredRuleNames" [value]="ruleName">
                {{ ruleName }}
              </coreui-option>
              <coreui-option class="text-style-action" [disabled]="true"
                *ngIf="filteredPackagedExclusions.length>0 || filteredConfiguredExclusions.length>0">
                Exclusions
              </coreui-option>
              <coreui-option *ngFor="let packagedExclusion of filteredPackagedExclusions"
                [value]="packagedExclusion.name">
                {{ packagedExclusion.name }}
              </coreui-option>
              <coreui-option *ngFor="let configuredExclusion of filteredConfiguredExclusions"
                [value]="configuredExclusion.name">
                {{ configuredExclusion.name }}
              </coreui-option>
              <ng-template #noResults>
                No results found.
              </ng-template>
            </coreui-autocomplete>
          </coreui-form-field>
        </form>
        <div class="row mt-1">
          <div class="col">
            <coreui-accordion>
              <div class="row mb-1">
                <div class="col">
                  <coreui-accordion-pane label="Program Templates" [active]="filteredPackagedProgramTemplateResult">
                    <coreui-accordion-pane *ngFor="let bundle of availableBundles" label="{{bundle.displayName}}"
                      [active]="programTemplateBelongsToBundle(bundle.id)">
                      <ng-template *ngTemplateOutlet="programTemplate; context: {$implicit: bundle.id}"></ng-template>
                    </coreui-accordion-pane>
                  </coreui-accordion-pane>
                </div>
              </div>
            <coreui-accordion-pane label="Custom Builder" [active]="filteredRuleResult || filteredConfiguredExclusionResult || filteredPackagedExclusionResult">
              <div class="row mb-1">
                <div class="col">
                  <coreui-accordion-pane label="Rules" [active]="filteredRuleResult">
                    <coreui-accordion>
                      <coreui-accordion-pane *ngFor="let eventSource of getValuesFromEventSourceMap()" label="{{eventSource.name}}" [active]="filteredRuleResult">
                        <coreui-accordion>
                          <coreui-accordion-pane *ngFor="let eventType of eventSource.getEventTypeList()" label="{{eventType.name}}" [active]="filteredRuleResult">
                            <div class="drop-list" coreUIDropList data="[]" [id]="ruleDragAreaId" [connectedTo]="ruleDragAreaConnectedTo">
                              <div *ngFor="let treeNodeForRules of eventType.getTreeNodefromArray()" class="drag-element"
                                [data]="buildDroppedItem('RULE', treeNodeForRules.name)" coreUIDrag>
                                <em coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></em>
                                <span class="pl-2" [coreUITooltip]="treeNodeForRules.name" position="top">
                                  {{ treeNodeForRules.name }}
                                </span>
                              </div>
                            </div>
                          </coreui-accordion-pane>
                        </coreui-accordion>
                      </coreui-accordion-pane>
                    </coreui-accordion>
                  </coreui-accordion-pane>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  <coreui-accordion-pane label="Exclusions" [active]="filteredConfiguredExclusionResult || filteredPackagedExclusionResult">
                    <coreui-accordion>
                      <coreui-accordion-pane label="Standard Exclusions" [active]="filteredPackagedExclusionResult">
                        <div class="drop-list" coreUIDropList data="[]" id="'exclusionDragArea'" connectedTo="'exclusionDropArea'">
                          <div class="drag-element" *ngFor="let packagedExclusion of filteredpackagedExclusionDetails" data="{{packagedExclusion.id}}" coreUIDrag>
                            <em coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></em>
                            <span class="pl-2" [coreUITooltip]="packagedExclusion.name" position="top">
                              {{ packagedExclusion.name }}
                            </span>
                          </div>
                        </div>
                      </coreui-accordion-pane>
                      <coreui-accordion-pane label="User Defined Exclusions" [active]="filteredConfiguredExclusionResult">
                        <div class="drop-list" coreUIDropList data="[]" id="'exclusionDragArea'"
                          connectedTo="'exclusionDropArea'">
                          <div class="drag-element" *ngFor="let configuredExclusion of filteredConfiguredExclusionDetails"
                            data="{{configuredExclusion.id}}" coreUIDrag>
                            <em coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></em>
                            <span class="pl-2" [coreUITooltip]="configuredExclusion.name" position="top">
                              {{ configuredExclusion.name }}
                            </span>
                          </div>
                        </div>
                      </coreui-accordion-pane>
                    </coreui-accordion>
                  </coreui-accordion-pane>
                </div>
              </div>
            </coreui-accordion-pane>
            </coreui-accordion>
          </div>
        </div>
      </div>
      </bp-collapsible-panel>
      </div>
      <div id="rightPane" class="col">
        <div class="row border-bottom mb-3 justify-content-end" *ngIf="doRulesExistForPrograms()">
          <div class="col-auto mb-2 pr-0">
            <app-ae-button [buttonText]="'Validate'"  class="mr-2"
              (click)="validateProgramRules()" [disabled]="isButtonDisabled()"></app-ae-button>
          </div>
          <div class="col-auto mb-2 pl-0" *ngIf="authorizationService.isAllowed('CUST_PRG', ['CREATE','UPDATE'])">
            <div ngbTooltip="{{displayTexts.DISABLED_SAVE_BTN_TOOLTIP}}"
              [disableTooltip]="!programData.tenantProgram.active" placement="bottom-right auto">
            <app-ae-button [buttonText]="'Save'" (click)="saveProgramRules()"
                [disabled]="isButtonDisabled()"></app-ae-button>
            </div>
          </div>
        </div>
        <div class="row mt-5" *ngIf="showDropAreaForProgramTemplateAndRule()">
          <div class="col">
            <h4 class="text-style-muted-1">Drag and drop template from our Program Template, or start a Program from scratch with Custom Builder.</h4>
            <div class="drop-list" coreUIDropList [id]="dropAreaForProgramTemplateAndRuleId" [connectedTo]="dropAreaForProgramTemplateAndRuleConnectedTo" (dropped)="onProgramTemplateOrRuleDropped($event)" [data]="[]"></div>
          </div>
        </div>
        <div class="row" *ngIf="!showDropAreaForProgramTemplateAndRule()">
          <div class="col">
            <coreui-accordion>
              <div class="row mb-1">
                <div class="col">
                  <coreui-accordion-pane class="mb-1" label="Include" [active]="true">
                    <ng-container *ngIf="!isReadOnlyRule && eventSourceMap?.size > 0">
                      <h4 class="text-style-muted-1">Drag and drop Rule to start building your program.</h4>
                      <div class="drop-list" coreUIDropList [id]="ruleDropAreaId" [connectedTo]="ruleDropAreaConnectedTo" (dropped)="onRuleDropped($event)" [data]="[]"></div>
                    </ng-container>
                    <div class="row u-mt-10">
                      <div class="col">
                        <div formGroupName="configuration">
                          <div formArrayName="rules">
                            <ng-container *ngFor="let rule of getProgramRuleBuilderForm; let ruleIndex = index;">
                              <div [formGroupName]="ruleIndex.toString()">
                                <fieldset [disabled]="isReadOnlyRule">
                                <div class="border u-mt-10 u-mb-10" *ngIf="rule !== undefined">
                                  <div class="row m-0 border-bottom mb-0"
                                    (click)="isCollapseRule[ruleIndex] = !isCollapseRule[ruleIndex]">
                                    <span class="text-style-2 u-ml-30 py-3">{{ rule.controls.ruleName.value
                                      }}
                                      <i aria-hidden="true" [className]="isCollapseRule[ruleIndex] ? 'fa fa-angle-right fa-fw' :
                                                                                'fa fa-angle-down fa-fw'"></i>
                                    </span>
                                    <app-ae-button class="ml-auto u-mr-30 h-50 u-mt-10" [buttonText]="'Delete'"
                                      [classes]="'btn btn-outline-danger'"
                                      (click)="showDeleteAlert(rule,ruleIndex); isCollapseRule[ruleIndex] = !isCollapseRule[ruleIndex]">
                                    </app-ae-button>
                                  </div>
                                  <div class="u-p-20" [ngbCollapse]="isCollapseRule[ruleIndex]">
                                    <app-rule-builder [eventSources]="eventSources"
                                      [configuredEventDetails]="configuredEventDetails" [actionDestinations]="actionDestinations"
                                      [formGroup]="rule" [filteredConfiguredActions]="rule.controls.filteredConfiguredActions.value"
                                      [rule]="rule.controls.configuredRule.value" [isReadOnlyRule]="isReadOnlyRule"
                                      [showDeleteButton]="showDeleteButton" [ruleComponentIndex]="ruleIndex.toString()"
                                      (validationStatus)="setChildValidationStatus($event)">
                                    </app-rule-builder>
                                  </div>
                                </div>
                                </fieldset>
                              </div>
                              <div class="ml-0" *ngIf="getRuleCountForProgram() - ruleIndex > 1">
                                <span class="bg-secondary text-white px-1">AND</span>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </coreui-accordion-pane>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  <coreui-accordion-pane label="Exclude">
                    <app-custom-program-exclusion-builder [configuredExclusions]="packagedAndConfiguredExclusions" [programExclusions]="programExclusions" [readOnly]="isReadOnlyRule"></app-custom-program-exclusion-builder>
                  </coreui-accordion-pane>
                </div>
              </div>
              <div class="row mb-1" *ngIf="doRulesExistForPrograms()">
                <div class="col">
                  <coreui-accordion-pane label="Description">
                    <div class="form-group">
                      <label for="programDescription">Program Description</label>
                      <textarea id="programDescription" formControlName="description" class="form-control" placeholder="Enter program description" rows=4></textarea>
                    </div>
                  </coreui-accordion-pane>
                </div>
              </div>
            </coreui-accordion>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #programTemplate let-bundle>
  <ng-container *ngFor="let packagedProgramTemplate of packagedProgramTemplates">
    <div *ngIf="packagedProgramTemplate.bundles.includes(bundle)">
      <div class="drop-list" coreUIDropList data="[]" [id]="programTemplateDragAreaId"
        [connectedTo]="programTemplateDragAreaConnectedTo">
        <div class="drag-element"
          [data]="buildDroppedItem('PROGRAM_TEMPLATE', packagedProgramTemplate.programName)" coreUIDrag>
          <em coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></em>
          <span class="pl-2" [coreUITooltip]="packagedProgramTemplate.displayName" position="top">
            {{packagedProgramTemplate.displayName}}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>