import { Component, OnInit, OnDestroy, ChangeDetectorRef, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { EntityStatesSharedDataService } from '../entity-states-shared-data.service';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { EntityStatesService } from 'src/app/shared/services/entitystates.service';
import { EntityStatesHeaderComponent } from '../entity-states-header/entity-states-header.component';
import { BaseFormDirective } from '../../../shared/models/base-form-configuration/base-form.directive';
import { OperationType } from '../../../shared/constants';
import { EntityType } from 'src/app/shared/models/entity-type';

@Component({
  selector: 'app-entity-states-nav',
  templateUrl: './entity-states-nav.component.html',
  styleUrls: ['./entity-states-nav.component.scss']
})
export class EntityStatesNavComponent extends BaseFormDirective implements OnInit, OnDestroy, OnChanges {

  @ViewChild(EntityStatesHeaderComponent)
  public header: EntityStatesHeaderComponent;

  tenantProgram = new ConfiguredProgram();
  parentId : string;
  operation : string;
  public configuredEntityState : ConfiguredEntityState;
  configuredEntityStateType : string;
  public entityStateNavTabs = [];
  configuredEntityStateId: string;
  isPageLoading: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private parentContextService: ParentContextService,
    private route: ActivatedRoute,
    private entityStateSharedService: EntityStatesSharedDataService,
    private entityStateService: EntityStatesService,
    private ref: ChangeDetectorRef) {
    super();
    this.parentId = this.parentContextService.getParentContext();
    this.initEntityStateNavTabs();
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.operation = route.snapshot.paramMap.get('action');
      this.configuredEntityStateType = route.snapshot.paramMap.get('type');
      this.configuredEntityStateId = route.snapshot.paramMap.get('id');
    });
  }

  ngOnChanges() : void {
    this.ref.detectChanges();
  }

  ngOnInit() : void {
    if (this.operation === 'add') {
      this.entityStateSharedService.buildEmptyEntityStateForm(this.parentId);
      this.entityStateSharedService.initializeConfiguredEntity();
    } else if (this.operation === 'edit') {
      this.configuredEntityState = this.entityStateSharedService.getEntityStateList()
        .find(entityStateObj => this.configuredEntityStateId === entityStateObj.id);
      if (this.configuredEntityState === undefined) {
        this.entityStateSharedService.buildEmptyEntityStateForm(this.parentId);
        this.getConfiguredSystemEntityStateDetails();
      } else {
        this.entityStateSharedService.initializeEntityStateForm(this.configuredEntityState);
      }
    }
  }

  public canNavigateAway(): boolean {
    if (this.header.isDataSaved || this.header.isDeleteOperation || this.header.hasAssociation) {
      return true;
    }
    return !(this.configuredEntityStateType === EntityType.CUSTOM &&
        (this.operation === OperationType.ADD && this.isCreateFormUpdated() ||
            this.operation === OperationType.EDIT && this.isEditFormUpdated()));

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private isEditFormUpdated(): boolean {
    return JSON.stringify(this.entityStateSharedService.configuredEntityState) !==
        JSON.stringify(this.getUpdatedConfiguredEntityState());
  }

  private isCreateFormUpdated(): boolean {
    return this.entityStateSharedService.entityStateFormGroup.controls.name.value !== '' ||
        this.entityStateSharedService.entityStateFormGroup.controls.description.value !== '' ||
        this.entityStateSharedService.entityStateFormGroup.controls.timeToLive.value.toString() !== '30' ||
        JSON.stringify(this.entityStateSharedService.configuredEntityState.entityStateKey).length !==
        JSON.stringify(this.entityStateSharedService.entityStateFormGroup.controls.entityStateKey.value).length ||
        this.entityStateSharedService.entityStateFormGroup.controls.schema.value !== '{}';
  }

  private getUpdatedConfiguredEntityState(): ConfiguredEntityState {
    const configuredEntityState = new ConfiguredEntityState(this.entityStateSharedService.configuredEntityState);
    configuredEntityState.name = this.entityStateSharedService.entityStateFormGroup.controls.name.value;
    configuredEntityState.description = this.entityStateSharedService.entityStateFormGroup.controls.description.value;
    configuredEntityState.timeToLive = Number(this.entityStateSharedService.entityStateFormGroup.controls.timeToLive.value);
    configuredEntityState.entityStateKey =
        JSON.parse(JSON.stringify(this.entityStateSharedService.entityStateFormGroup.controls.entityStateKey.value));
    configuredEntityState.schema = this.entityStateSharedService.entityStateFormGroup.controls.schema.value;
    return configuredEntityState;
  }

  private getConfiguredSystemEntityStateDetails(): void {
    this.isPageLoading = true;
    this.entityStateService.getConfiguredEntityState(this.parentId, this.configuredEntityStateId, this.configuredEntityStateType)
      .pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.entityStateSharedService.initializeEntityStateForm(new ConfiguredEntityState(res['result']));
          this.isPageLoading = false;
        }, () => {
          this.isPageLoading = false;
        });
  }

  private initEntityStateNavTabs(): void {
    this.entityStateNavTabs = [
      {
        text: 'Properties',
        route: './properties'
      },
      {
        text: 'Schema',
        route: './schema'
      },
      {
        text: 'Connections',
        route: './connections'
      }
    ];
  }

}