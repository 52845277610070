import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/shared/message';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { ConfiguredRecommendation } from 'src/app/shared/models/package-builder/configured-recommendation';
import { Pattern } from 'src/app/shared/pattern';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { IQDecisionsService } from 'src/app/shared/services/iqdecisions.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { WarningType } from 'src/app/shared/warning-options';
import { TooltipMessages } from 'src/app/shared/tooltip-messages';
import { UtilService } from 'src/app/shared/services/util-service';
import { PackageLevel } from 'src/app/shared/models/package-builder/package-level-enum';
import { Constants } from 'src/app/shared/constants';
import { RecommendationType } from 'src/app/shared/models/package-builder/recommendartionType';
import { ModelType } from 'src/app/shared/models/package-builder/modelType';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';

@Component({
  selector: 'app-iqdecisions-configuration-single-model-package',
  templateUrl: './iqdecisions-configuration-single-model-package.component.html',
  styleUrls: ['./iqdecisions-configuration-single-model-package.component.scss']
  })
export class IQdecisionsConfigurationSingleModelPackageComponent extends BaseFormDirective implements OnInit {

  public categoryLevelsList = [];
  public companyList = [];
  public packageTypesList = [];
  public models = [];
  public isApiServiceCalled: boolean;
  public fetchingAllCompanies: boolean;
  public isConfiguredProgramsLoading: boolean;
  public actionDestinationService: any;
  public service: any;
  public tooltipMessages = TooltipMessages;
  public messages = Messages;

  private parentId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private configuredSingleModelPackage;

  constructor(
    public authorizationService: AuthorizationService,
    private fb: UntypedFormBuilder,
    private eventSourceService: EventSourceService,
    private parentContext: ParentContextService,
    private iqDecisionsService: IQDecisionsService,
    private router: Router,
    private popupService: PopupMessageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.parentId = this.parentContext.getParentContext();
    this.buildForm();
    this.doGetCompanyDetails();
  }

  public saveChanges(publishClicked: boolean = false): void {
    let level;
    if (this.configuredEntityForm.valid) {
      level = PackageLevel.REVIEW_PUBLISH;
    } else if (this.configuredEntityForm.get(ModelType.PRIMARY_MODEL).value) {
      level = PackageLevel.MODELS;
    } else {
      level = PackageLevel.PACKAGE_INFO;
    }


    this.configuredSingleModelPackage = new ConfiguredRecommendation(
      { ...this.configuredEntityForm.value, parentId: this.parentId, level }
    );
    this.addSingleModelPackage(this.configuredSingleModelPackage, publishClicked);
  }

  public doGetPackagesTypes(company: string): void {
    const { id } = company ? JSON.parse(company) : '';
    if (id) {
      this.iqDecisionsService.getPackagedTypes(id, this.parentId).subscribe(
        res => {
          this.packageTypesList = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).recommendationTypes;
          this.categoryLevelsList = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).choiceTypes;
          this.models = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).models.map(m => {
            return {
              ...m,
              range: m.trainingStartDate === undefined ? '---' : m.trainingStartDate + '- ' + m.trainingEndDate === undefined ? '---' : m.trainingEndDate
            };
          });
        }
      );
    }
  }

  public selectOptionValue(id: string, name: string): { id: string; name: string } {
    return {
      id,
      name
    };
  }

  public showCategoryLevel(): boolean {
    const name = this.configuredEntityForm.get('recommendationType').value;
    if (name) {
      return JSON.parse(name).id === RecommendationType.PRODUCT_CATALOG;
    }
  }

  public cancelButtonClicked(): void {
    void this.router.navigate(['/iqdecisions']);
  }

  handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
    }
  }

  public addPackageLevelInput(name: string): void {
    const { id } = name ? JSON.parse(name) : '';
    if (id === 'product_catalog') {
      this.configuredEntityForm.addControl('choiceType', new UntypedFormControl('', Validators.required));
    } else {
      this.configuredEntityForm.removeControl('choiceType');
    }
  }

  public getSelectedModel(value, type): void {
    this.configuredEntityForm.patchValue({
      [type]: value
    });
  }

  get fallbackModelList() {
    return [...this.models].filter(m => m.id !== this.configuredEntityForm.get('primaryModel').value.id);
  }


  public preventZeroStartingNumber(event): void {
    if (!event.target.value.match(Pattern.VALIDATE_ZERO_AT_BEGINNING) && (event.key === '0' || event.ctrlKey)) {
      event.preventDefault();
    }
  }

  public get packageName() {
    return this.configuredEntityForm.get('packageName');
  }

  public get description() {
    return this.configuredEntityForm.get('description');
  }

  private buildForm() {
    this.configuredEntityForm = this.fb.group({
      company: new UntypedFormControl('', { validators: [Validators.required] }),
      packageName: new UntypedFormControl('',
        { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC_WITH_SPACE), Validators.maxLength(150)] }),
      recommendationType: new UntypedFormControl('', { validators: Validators.required }),
      maxItemReturned: new UntypedFormControl(1, { validators: [Validators.required, Validators.min(1), Validators.pattern(Pattern.INTEGER)] }),
      description: new UntypedFormControl('', {
        validators: [Validators.required, Validators.maxLength(500), Validators.pattern(Pattern.ALPHA_NUMERIC_WITH_SPACE)]
      }),
      primaryModel: new UntypedFormControl('', Validators.required),
      fallbackModel: null,
      itemsChoiceType: new UntypedFormControl('', Validators.required),
      itemsPerInputLevel:
        new UntypedFormControl(1, { validators: [Validators.required, Validators.min(1), Validators.pattern(Pattern.INTEGER)] })
    });
  }

  private doGetCompanyDetails(): void {
    this.isApiServiceCalled = true;
    this.eventSourceService.getCompanies(this.parentId, '').subscribe(
      res => {
        this.isApiServiceCalled = false;
        this.companyList = res['result'].find(e => e.eventSource === 'Web')['settings'];
      },
      (error: any) => { });
  }


  private addSingleModelPackage(configuredRecommendation: ConfiguredRecommendation, publishClicked: boolean = false): void {
    this.isApiServiceCalled = true;
    this.iqDecisionsService
      .addIQDecisions(configuredRecommendation, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(serviceResponse => {
        this.configuredSingleModelPackage.id = serviceResponse.result.id;
        this.isDataSaved = true;
        this.isApiServiceCalled = false;
        if (publishClicked) {
          this.publishPackage();
        } else {
          this.popupService.showSuccessMessage('Single model package "' + configuredRecommendation.name + '" successfully added', true);
          void this.router.navigate(['/iqdecisions']);
        }
      }, err => {
        this.isDataSaved = false;
        this.isApiServiceCalled = false;
        if (err.error.statusMessage === Constants.NOT_FOUND) {
          this.popupService.showErrorMessage(Messages.parentIdAndCompanyIdNotFound);
        } else if (err.error.statusMessage === Constants.DUPLICATE_ITEM) {
          this.popupService.showErrorMessage(err.error.result);
          this.configuredEntityForm.get('packageName').setErrors({ 'alreadyExist': true });
          console.log(this.packageName );
        } else {
          this.popupService.showErrorMessage(Messages.requiredFields);
        }
      });
  }

  private publishPackage(): void {
    this.iqDecisionsService.publishIQDecisions(
      this.configuredSingleModelPackage.id, this.parentId
    ).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.popupService.showSuccessMessage(`${this.configuredSingleModelPackage.name} ${this.messages.successfullyPublishedMessage}`, true);
      void this.router.navigate(['/iqdecisions']);
    }, () => {
      this.popupService.showErrorMessage(Messages.errorPublishingRecommendation);
    });
  }

}