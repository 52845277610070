export const AssociationTemplate = {
  RULE: {
    displayName: 'Rule',
    apiPathPart: 'rule',
    uiPathPart: {
      RULE: 'src/app/modules/rules/edit/[@id@]'
    }
  },
  WORKFLOW: {
    displayName: 'Workflow',
    apiPathPart: 'workflow',
    uiPathPart: {
      WORKFLOW: 'src/app/modules/programs'
    }
  },
  PROGRAM: {
    displayName: 'Program',
    apiPathPart: 'program',
    uiPathPart: {
      PACKAGED: 'src/app/modules/programs/configure/[@id@]/[@event-source@]/edit',
      SELF_SERVICE: 'src/app/modules/programs/custom/configure/[@id@]/[@event-source@]/edit/rule',
      SELF_SERVICE_EXTERNAL: 'src/app/modules/programs/custom/configure/external/[@id@]/eventSource/edit/program-rule',
      SELF_SERVICE_EXTERNAL_PACKAGED: 'src/app/modules/programs/custom/configure/external/[@id@]/eventSource/edit/program-rule'
    }
  },
  ACTION: {
    displayName: 'Action',
    apiPathPart: 'action',
    uiPathPart: {
      CUSTOM: 'src/app/modules/actions/edit/custom/[@id@]',
      PACKAGED: 'src/app/modules/actions/edit/packaged/[@id@]'
    }
  },
  EVENT: {
    displayName: 'Event',
    apiPathPart: 'event',
    uiPathPart: {
      EVENT: 'src/app/modules/events/edit/[@event-source@]/[@id@]'
    }
  },
  EVENT_SOURCE: {
    displayName: 'Event Source',
    apiPathPart: 'es'
  },
  CUSTOM_ACTION_DESTINATION: {
    displayName: 'Custom Action Destination',
    apiPathPart: 'cad'
  },
  PACKAGED_ACTION_DESTINATION: {
    displayName: 'Action Destination',
    apiPathPart: 'ad'
  },
  EXCLUSION: {
    displayName: 'Exclusion',
    apiPathPart: 'exclusion',
    uiPathPart: {
      PACKAGED: 'src/app/modules/exclusions/Packaged/edit/[@id@]',
      CUSTOM: 'src/app/modules/exclusions/Custom/edit/[@id@]'
    }
  },
  ENTITYSTATES: {
    displayName: 'Entity States',
    apiPathPart: 'entitystate'
  }
};