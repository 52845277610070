<app-coreui-modal-warning></app-coreui-modal-warning>
<div class="container-fluid ml-2">
  <form [formGroup]="configuredEntityForm" (change)="setFormValueChanges()">
    <div>
      <p class="text-style-2">Schema</p>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
            <div (keyup)="setFormValueChanges()">
              <label for="name">Entity State Key</label>
              <input type="text" class="form-control" formControlName="entityStateKeys" id="entityStateKeys" placeholder="Enter an entity state key"
              (change)="setEventAttributeName(configuredEntityForm.controls.entityStateKeys.value)"
                required="required">
            </div>
        </div>
        <div class="text-color-warning" *ngIf="configuredEntityForm.controls.entityStateKeys.touched && configuredEntityForm.controls.entityStateKeys.errors">
          <span *ngIf="configuredEntityForm.controls.entityStateKeys.errors.required">
            This data type is not supported for use as an entity state
            key. Please enter an attribute with a data type that is either
            a string or an integer
          </span>
        </div>
        <div class="text-color-warning" *ngIf="configuredEntityForm.controls.entityStateKeys.touched && configuredEntityForm.controls.entityStateKeys.errors">
          <span *ngIf="configuredEntityForm.controls.entityStateKeys.errors.maxEntityKeys">
            Only 5 entity keys can be added as comma seperated values.
          </span>
        </div>
      </div>
    </div>
    <p class="text-style-4">Enter an attribute from this entity state schema <br>
      or from an existing event schema. The key must <br>
      be a string or an integer data type.
    </p>
    <div class="form-group">
      <label for="schema">Schema (we currently only support JSON schema version 'Draft 7')</label>
      <textarea class="form-control" formControlName="schema" id="schema" rows="10"></textarea>
    </div>
  </form>
</div>