import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Pattern } from 'src/app/shared/pattern';
import { ModalComponent } from '@epsilon/core-ui';
import { PopupMessageService } from '../../../../shared/services/popup-message.service';
import { Constants } from '../../../../shared/constants';

@Component({
  selector: 'app-add-custom-event-source-modal',
  templateUrl: './add-custom-event-source-modal.component.html'
})
export class AddCustomEventSourceModalComponent implements OnInit {

  @Input()
  public existingEventSources = [];
  @Input()
  public customEventSource = {};
  @Input()
  public editMode = false;
  @Input()
  public eventSourcesNotAllowed = [];
  @Output()
  private isModalDisplayed = new EventEmitter<any>();
  @Output()
  private configuredCustomEventSource = new EventEmitter<any>();
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;

  public customEventSourceFormGroup: UntypedFormGroup;

  private isModalShown: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              private popupService: PopupMessageService) {
  }

  ngOnInit(): void {
    this.launchBasicModal();
    this.createAddCustomEventSourceFormGroup();
  }

  public addCustomEventSource(customEventSource: {providerName: string; providerDescription: string}): void {
    if (this.editMode) {
      this.popupService.showSuccessMessage(`EventSource "${customEventSource.providerName}" ${Constants.SUCCESSFULLY_UPDATED}`);
    } else {
      this.popupService.showSuccessMessage(`EventSource "${customEventSource.providerName}" ${Constants.SUCCESSFULLY_CREATED}`);
    }
    this.closeBasicModal();
    this.configuredCustomEventSource.emit({ editMode: this.editMode, configuredEventSource: customEventSource });
  }

  public get providerName(): AbstractControl {
    return this.customEventSourceFormGroup.get('providerName');
  }

  public validateEnteredEventSource(customEventSource): void {
    this.validateEnteredEventSourceProviderExists(customEventSource);
    this.validateEnteredEventSourceProviderIsNotAllowed(customEventSource);
  }

  public validateEnteredEventSourceProviderExists(customEventSource): void {
    const isAleradyExist = this.existingEventSources.find(existingEventSource => existingEventSource.name.toLowerCase() === customEventSource.value.providerName.toLowerCase());
    if (isAleradyExist !== undefined) {
      customEventSource.controls.providerName.setErrors({ 'alreadyExists': true });
    }
  }

  public validateEnteredEventSourceProviderIsNotAllowed(customEventSource): void {
    const isInNotAllowed = this.eventSourcesNotAllowed.find(
      existingEventSource => existingEventSource.id.toLowerCase() === customEventSource.value.providerName.toLowerCase());
    if (isInNotAllowed !== undefined) {
      customEventSource.controls.providerName.setErrors({ 'notAllowed': true });
    }
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayed.emit(this.isModalShown);
  }

  private createAddCustomEventSourceFormGroup() {
    this.customEventSourceFormGroup = this.formBuilder.group({
      providerName: [this.customEventSource['providerName'], [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC)]],
      providerDescription: this.customEventSource['providerDescription']
    });
  }

}