<coreui-modal #basicModal>
  <ng-template #titleTemplate>
    <h2 class="text-style-1 mb-0">Add a Business Unit</h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
      <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <form #documentEditForm="ngForm" (ngSubmit)="submitForm()" class="horizontal-scroll">
      <app-ae-loading-indicator [isLoading]="validationStatus==='LOADING'"  [type]="'loading bar'"></app-ae-loading-indicator>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="businessUnitName">Business Unit Name</label>
          <input id="businessUnitName" type="text" class="form-control" placeholder="Business Unit Name"
            [(ngModel)]="businessUnit.businessUnitName" [ngModelOptions]="{standalone: true}"
            on-focusout="checkDuplicateBU()">
          <div *ngIf="isDuplicateName" class="text-color-warning">
            Business Unit Name already present
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="businessUnitId">Business Unit ID</label>
          <input id="businessUnitId" type="text" class="form-control" placeholder="Business Unit ID"
            [(ngModel)]="businessUnit.businessUnitId" [ngModelOptions]="{standalone: true}"
            on-focusout="checkDuplicateBU()">
          <div *ngIf="isDuplicateId" class="text-color-warning">
            Business Unit ID already present
          </div>
        </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md">
            <label for="company">Company</label>
            <app-ae-multi-select [formGroup]="companyForm" [multiSelectFormControlName]="company"
              [placeholder]="selectCompanyPlaceHolder" [multiSelectDataStream]="multiSelectItems">
            </app-ae-multi-select>
          </div>
        </div>
      <div class="form-row">
        <div class="col">
          <h2 class="h2">API User Credentials</h2>
        </div>
      </div>
      <div class="form-row" *ngIf="actionDestination.id !== SALESFORCE_ACTION_DESTINATION">
        <div class="form-group col-md-6">
          <label for="clientUsername">Client Username</label>
          <input id="clientUsername" type="text" class="form-control" placeholder="Client Username"
            [(ngModel)]="businessUnit.clientUsername" [ngModelOptions]="{standalone: true}">
        </div>
        <div class="form-group col-md-6">
          <label for="clientPassword">Client Password</label>
          <input id="clientPassword" type="password" class="form-control" placeholder="Client Password"
            [(ngModel)]="businessUnit.clientPassword" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="apiClientId">API Client ID</label>
          <input id="apiClientId" type="text" class="form-control" placeholder="API Client ID" [(ngModel)]="businessUnit.apiClientId"
            [ngModelOptions]="{standalone: true}">
        </div>
        <div class="form-group col-md-6">
          <label for="apiClientSecret">API Client Secret</label>
          <input id="apiClientSecret" type="password" class="form-control" placeholder="API Client Secret"
            [(ngModel)]="businessUnit.apiClientSecret" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
      <div class="form-row">
        <div class="col text-center">
          <div *ngIf="validationStatus==='INVALID_DATA'" class="text-color-warning">
            {{messages.userAuthError}}
          </div>
          <div *ngIf="validationStatus==='FAILED'" class="text-color-warning">
            {{messages.invalidBUInfoError}}
          </div>
          <div *ngIf="validationStatus==='OTHER_ERROR'" class="text-color-warning">
            {{messages.invalidDataError}}
          </div>
          <div *ngIf="validationStatus==='COMPANY_MAPPED_ERROR'" class="text-color-warning">
            {{messages.companyIDMappedError}}
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
      <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
      <app-ae-button (click)="submitForm()" [disabled]="isDisabled()" buttonText="Add"></app-ae-button>
  </ng-template>
</coreui-modal>
