<form name="exclusionConfigurationForm" [formGroup]="exclusionConfigurationFormGroup">
  <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMPACT"
       class="col-auto border border-color-secondary rounded align-self-center py-2">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-auto py-2 pl-2 pr-0">
            {{ getAttributeName() | lowercase }}
          </div>
          <div class="col-auto py-2 pl-1 pr-0">
            {{ displayExclusionOperator | lowercase}}
          </div>
          <div class="col-auto pl-2 pr-0 inline-form-field">
            <coreui-select formControlName="conditionValue">
              <coreui-option [value]="true" selected="true">True</coreui-option>
              <coreui-option [value]="false">False</coreui-option>
            </coreui-select>
          </div>
        </div>
      </div>
      <div class="col-auto align-self-center">
        <div class="row">
          <div class="col-auto ml-auto px-2">
            <button coreUIButton="feedback-error-secondary" [disabled]="!canDeleteExclusionConfiguration()"
                    (click)="onEntityStateAttributeDelete()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMFORTABLE" class="container-fluid">
    <div class="row border border-color-secondary rounded my-2">
      <div class="col align-self-center py-3">
        {{ getAttributeName() | lowercase }}
      </div>
      <div class="col-auto py-2 ml-auto">
        <button coreUIButton="icon-large" withBorder [disabled]="!canDeleteExclusionConfiguration()"
                (click)="onEntityStateAttributeDelete()"><i coreUIIcon="trash"></i></button>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-auto border border-color-secondary rounded align-self-center py-2">
        <div class="row">
          <div class="col-auto py-2 pr-1">
            {{ displayExclusionOperator | lowercase }}
          </div>
          <div class="col-auto pl-1 inline-form-field ml-auto">
            <coreui-select formControlName="conditionValue">
              <coreui-option [value]="true" selected="true">True</coreui-option>
              <coreui-option [value]="false">False</coreui-option>
            </coreui-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
