import { Injectable } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { LoginService } from './LoginService';
import { ParentContextService } from './parent-context.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from './authorization-service';
import { BaseLoginService } from './base-login.service';

@Injectable({
  providedIn: 'root'
})

export class OktaLoginService extends BaseLoginService implements LoginService {

  private isLogIn: boolean;
  private loggedInUser: string;
  private expiresAt = 0;
  private returnTo = '/';


  constructor(
    private oktaAuth: OktaAuthService,
    private router: Router,
    activeRoute: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private parentContext?: ParentContextService) {
    super(router);
    activeRoute.queryParams.subscribe(params => {
      if (params['returnTo']) {
        this.returnTo = params['returnTo'];
      } else {
        this.returnTo = '/';
      }
    });
    this.loggedInUser = localStorage.getItem('username');
  }

  private static isEmpty(token: Record<string, any>): boolean {
    return Object.keys(token).length === 0;
  }

  public isLoggedIn(): boolean {
    this.loggedInUser = localStorage.getItem('username');
    this.expiresAt = (this.expiresAt === 0 ? this.getExpiresAt() : this.expiresAt);
    this.isLogIn = !!this.loggedInUser && this.expiresAt !== 0
    && moment().unix() < this.expiresAt;
    return this.isLogIn;
  }

  public getExpiresAt(): number {
    if (localStorage.getItem('okta-token-storage') !== null && JSON.parse(localStorage.getItem('okta-token-storage')).idToken !== undefined) {
      return JSON.parse(localStorage.getItem('okta-token-storage')).idToken.expiresAt;
    }
    return 0;
  }

  public logout(): void {
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('token');
    localStorage.removeItem(ParentContextService.PARENT_LIST);
    localStorage.removeItem('username');
    this.isLogIn = false;
    this.loggedInUser = null;
    this.expiresAt = 0;
    void this.oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/login/okta`
    });
  }

  public cacheOktaIdToken(): void {
    const token = JSON.parse(localStorage.getItem('okta-token-storage'));
    if (OktaLoginService.isEmpty(token)) {
      return;
    }
    this.loggedInUser = token.idToken.claims.preferred_username;
    this.expiresAt = token.idToken.expiresAt;
    localStorage.setItem('username', token.idToken.claims.preferred_username);
    const authToken = token.idToken.idToken !== undefined ? token.idToken.idToken : token.idToken.value;
    localStorage.setItem('token', authToken);
  }

  public onLoginSuccess(): void {
    console.log('In authenticateUser onSuccess callback');
    this.cacheOktaIdToken();
    if (this.authorizationService.isAuthorizationEnabled()) {
      this.authorize();
    } else {
      void this.router.navigateByUrl(this.returnTo);
    }
  }

  public authorizationFailed(message: string) : void {
    console.log('logging out user as authorization failed');
    setTimeout(() => this.logout(), 2000);
    console.log('result: ' + message);
  }

  public getUserName(): string {
    return this.loggedInUser;
  }

  private authorize() : void {
    let parentId = '';
    if (this.parentContext.isParentContextSet()) {
      parentId = this.parentContext.getParentContext();
    }
    this.authorizationService.initializePermissionsOnLogin(parentId, this.loggedInUser, this);
  }

}
