import { Injectable } from '@angular/core';
import { EventType } from '../event-type';
import { EventTypeSchemaMapper } from '../event-type-schema-mapper';
import { JSONFileService } from './json-file-service';
import { ConfiguredEvent } from '../models/configured-event.model';

@Injectable({
  providedIn: 'root'
})
export class EventSchemaService {

  public eventTypeSchemaMapper: EventTypeSchemaMapper;
  isAttrPresent: boolean;

  constructor(public jsonFileService: JSONFileService) {
    this.eventTypeSchemaMapper = new EventTypeSchemaMapper(jsonFileService);
  }

  public validateEventAttributeInSchema(eventSchemaStr: string, eventAttribute: string): boolean {
    const obj = JSON.parse(this.getSampleEvent(eventSchemaStr));
    const iseventAttrPresent = this.validateAttributeInSampleEvent(obj, eventAttribute);
    return iseventAttrPresent;
  }

  private validateAttributeInSampleEvent(sampleEvent: object, eventAttrVal: string): boolean {
    if (eventAttrVal.indexOf('.') !== -1) {
      this.isAttrPresent = true;
    } else if (Object.getOwnPropertyNames(sampleEvent).indexOf(eventAttrVal) !== -1) {
      this.isAttrPresent = true;
    } else {
      for (const property in sampleEvent) {
        if (Object.getOwnPropertyNames(property).indexOf(eventAttrVal) !== -1) {
          this.isAttrPresent = true;
          break;
        } else {
          if (typeof sampleEvent[property] === 'object' && sampleEvent[property] !== null) {
            this.isAttrPresent = false;
            this.validateAttributeInSampleEvent(sampleEvent[property], eventAttrVal);
          }
        }
      }
    }
    return this.isAttrPresent;
  }

  private getEventSchema(eventName: string): any {
    return JSON.stringify(this.eventTypeSchemaMapper.getSchema(eventName as EventType));
  }

  private getSampleEvent(schemaStr: string): string {
    const configuredEvent: ConfiguredEvent = new ConfiguredEvent();
    const sampleEventRecord = {};
    let sampleEventStr = '';
    const eventSchema = JSON.parse(schemaStr);
    const sampleEvent = configuredEvent.computeSampleEventRecord(eventSchema, sampleEventRecord);
    sampleEventStr = JSON.stringify(sampleEvent, null, '\t');
    return sampleEventStr;
  }

}
