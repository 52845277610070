import { ActionElement } from './action-element';
import { ActionType } from '../../../models/action-type';

export class HarmonyRTMAction {

  actionDestination = 'HARMONY';
  actionService = 'RTM';
  businessUnitId: string;
  templateId: string;

  public getAction(): ActionElement {
    const action: ActionElement = new ActionElement(this.actionDestination);
    action.service = this.actionService;
    action.actionType = ActionType.API;

    const actionSettingsTemplate = {
      'businessUnitId': this.businessUnitId,
      'templateId': this.templateId,
      'serviceInput': `{\"buId\":\"${this.businessUnitId}\",\"templateId\":\"${this.templateId}\"}`,
      'ruleInput': `{\"productIdPath\":\"NOT_AVAILABLE\"}`
    };
    action.actionSettingsInput = actionSettingsTemplate;
    return action;
  }

}
