<coreui-modal modalWidth="40" #basicModal>
  <ng-template #titleTemplate>
    <h2 class="px-2"> Schedule </h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <app-ae-button buttonType="icon" (click)="closeBasicModal()" icon="close"></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <div class="col">
      <form name="scheduleForm" [formGroup]="scheduleForm">
        <div class="row pb-4">
          <div class="col">
            <p class="text-style-3">Current Status of Program {{ programName }}</p>
            <coreui-badge color={{getBadgeColor()}}>{{ programStatus }}</coreui-badge>
          </div>
        </div>
        <div class="row u-mb-5">
          <div class="col-6">
            <coreui-form-field label="Select Status Change">
              <coreui-select formControlName="scheduleAction" placeholder="Select" #shortSelect
                coreUIFixedWidth="short">
                <coreui-option [value]="1">Live</coreui-option>
                <coreui-option [value]="0">Inactive</coreui-option>
              </coreui-select>
              <ng-container *ngIf="validateFormSubmit('scheduleAction')">
                <div class="text-color-warning">
                  Please select a status change
                </div>
              </ng-container>
            </coreui-form-field>
          </div>
        </div>
        <div class="p-4 background-cover">
          <div class="row pl-4 pt-2">
            <span class="col-1 pt-2 px-2">Date</span>
            <div class="col-auto px-2">
              <coreui-form-field [fullWidth]="false">
                <coreui-date-picker formControlName="date"></coreui-date-picker>
                <ng-container *ngIf="validateFormSubmit('date')">
                  <div class="text-color-warning">
                    Please select a date
                  </div>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
          <div class="row pl-4">
            <span class="col-1 pt-2 px-2">Time</span>
            <div class="col-auto px-2">
              <coreui-form-field [fullWidth]="false">
                <coreui-time-picker formControlName="time"></coreui-time-picker>
                <ng-container *ngIf="validateFormSubmit('time')">
                  <div class="text-color-warning">
                    Please select a time
                  </div>
                </ng-container>
              </coreui-form-field>
            </div>
            <div class="col-auto pt-2 px-2">
              <coreui-badge color="Slate" [hasIcon]="true" [filled]="false"><i coreUIIcon="clock"></i>Timezone: {{
                this.zone }}</coreui-badge>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <ng-template #buttonsTemplate>
    <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
    <app-ae-button (click)="onSubmit()" buttonText="Submit"></app-ae-button>
  </ng-template>
</coreui-modal>