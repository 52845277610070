import { ConfiguredProgramTestData } from './configured-program-test-data';
import { Constants } from 'src/app/shared/constants';
import { TestDataSet } from '../configured-progra-test-data/test-data-set.model';

export class ConfiguredProgramTestDataMap {

  testDataMap: Map<string, ConfiguredProgramTestData> = new Map<string, ConfiguredProgramTestData>();

  public addConfiguredProgramTestData(testDataKey: string, configuredProgramTestData: ConfiguredProgramTestData): void {
    this.testDataMap.set(testDataKey, configuredProgramTestData);
  }

  public getConfiguredProgramTestData(testDataKey: string): ConfiguredProgramTestData {
    return this.testDataMap.get(testDataKey);
  }

  public fromJson(json: any): ConfiguredProgramTestDataMap {
    if (json != null && Object.keys(json).length > 0) {
      const testDataKeys: string[] = Object.keys(json);
      testDataKeys.forEach((testDataKey: string) => {
        const configuredProgramTestData: ConfiguredProgramTestData = new ConfiguredProgramTestData();
        this.addConfiguredProgramTestData(testDataKey, configuredProgramTestData.fromJson(json[testDataKey]));
      });
    }
    return this;
  }

  public toJson() {
    const configuredProgramTestDataJson = {};
    this.testDataMap.forEach((configuredProgramTestData: ConfiguredProgramTestData, testDataKey: string) => {
      configuredProgramTestData = Object.assign(new ConfiguredProgramTestData(), configuredProgramTestData);
      configuredProgramTestDataJson[testDataKey] = { eventAttributeTestDataMap: configuredProgramTestData.toJson() };
    });
    return configuredProgramTestDataJson;
  }

  public getTestDataMapFromDatasetList(testDatasetList: TestDataSet[]): ConfiguredProgramTestDataMap {
    testDatasetList.forEach(dataset => {
      const eventSourceEventTypeKey: string = dataset.eventSource + Constants.DB_KEY_SEPARATOR + dataset.event;
      let configuredProgramTestData: ConfiguredProgramTestData = this.getConfiguredProgramTestData(eventSourceEventTypeKey);
      let testDataList: string[] = dataset.testData.trim().split(/\s*,\s*/);
      testDataList = testDataList.filter(testData => testData != '');
      if (configuredProgramTestData) {
        configuredProgramTestData.addEventAttributeTestData(dataset.eventAttribute, testDataList);
      } else {
        configuredProgramTestData = new ConfiguredProgramTestData();
      }
      configuredProgramTestData.addEventAttributeTestData(dataset.eventAttribute, testDataList);
      this.addConfiguredProgramTestData(eventSourceEventTypeKey, configuredProgramTestData);
    });
    return this;
  }

}