import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Action } from 'src/app/shared/constants';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModalComponent } from '@epsilon/core-ui';
import { ConfiguredSchedule } from 'src/app/shared/models/configured-schedule/configured-schedule.model';
import { ScheduleStatus } from 'src/app/shared/models/configured-schedule/schedule-status';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { ProgramStatus } from 'src/app/shared/program-status';
import { ScheduleAction } from 'src/app/shared/models/configured-schedule/schedule-action';
import * as moment from 'moment';
import { UtilService } from 'src/app/shared/services/util-service';

@Component({
  selector: 'app-schedule-configuration',
  templateUrl: './schedule-configuration.component.html'
})
export class ScheduleConfigurationComponent implements OnInit {

  @Input()
  public action: string;
  @Input()
  public programName: string;
  @Input()
  public programStatus: string;
  @Input()
  public configuredSchedule: ConfiguredSchedule;
  @Input()
  public zone: string;
  @Output()
  public scheduledInfo = new EventEmitter<any>();
  @Output()
  public isModalDisplayInfo = new EventEmitter<boolean>();

  public scheduleForm: UntypedFormGroup;
  public isSaveClicked: boolean;

  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  private isModalShown: boolean;
  
  public constructor(
    private fb: UntypedFormBuilder,
    public authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
    this.buildConfiguredScheduleForm();
    this.launchBasicModal();
  }

  private buildConfiguredScheduleForm(): void {
    this.scheduleForm = this.fb.group({
      scheduleAction: new UntypedFormControl('', { validators: [Validators.required] }),
      date: new UntypedFormControl('', { validators: [Validators.required] }),
      time: new UntypedFormControl('', { validators: [Validators.required] }),
      zone: new UntypedFormControl('')
    });
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayInfo.emit(this.isModalShown);
    this.isSaveClicked = false;
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  public onSubmit(): void {
    this.isSaveClicked = true;
    if (this.scheduleForm.valid) {
      this.updateConfiguredSchedule();
      this.scheduledInfo.emit({ action: Action.CREATE, configuredSchedule: this.configuredSchedule });
      this.closeBasicModal();
    }
  }

  private updateConfiguredSchedule(): void {
    this.configuredSchedule.scheduleAction = this.getScheduleAction();
    this.configuredSchedule.scheduleStatus = ScheduleStatus.SCHEDULED;
    this.configuredSchedule.scheduleTime = this.getScheduleTimeInEpoch();
  }

  private getScheduleTimeInEpoch(): number {
    let date = new Date(this.scheduleForm.get('date').value);
    let time = this.scheduleForm.get('time').value;
    date.setHours(date.getHours() + time['hour']);
    date.setMinutes(date.getMinutes() + time['minute']);
    if (time['period'] === "PM") {
      date.setHours(date.getHours() + 12);
    }
    return UtilService.getLocalDateToUTC(date);
  }

  public getScheduleAction(): ScheduleAction {
    if (this.scheduleForm.get('scheduleAction').value === 1) {
      return ScheduleAction.ACTIVATION;
    }
    return ScheduleAction.DEACTIVATION;
  }

  public getBadgeColor(): string {
    if (this.programStatus === ProgramStatus.ACTIVE) {
      return "Lime";
    }
    return "Slate";
  }

  public validateFormSubmit(formAttrName: string): boolean {
    let formName = this.scheduleForm.controls[formAttrName];
    if (this.isSaveClicked && formName['invalid'] && formName['errors']['required']) {
      return true;
    }
    return false;
  }

}