import { Action } from 'src/app/shared/models/action';
import { ActionDelay } from '../../action-delay';

export class ActionElement {

  id: string;
  actionId: string;
  product: string;
  service: string;
  serviceId: string;
  actionType: string;
  actionInputs: string[];
  actionSettingsInput: object;
  actionDelay : ActionDelay;
  private mode: string;

  constructor(product: string, configuredAction?: Action, actionDelay?: ActionDelay, actionInput?: any,
    internalEventSourceProviderName?: string, internalEventName?: string) {

    this.actionInputs = ['RULEID', 'ENTITY'];
    this.actionId = configuredAction.id;
    this.actionType = configuredAction.actionType;
    this.product = product;
    this.service = configuredAction.id;
    this.serviceId = configuredAction.serviceId;
    this.mode = configuredAction.getMode();
    if (this.actionType === 'API_ACTION') {
      this.actionInputs.unshift(product, this.service);
    }
    if (actionInput) {
      const actionSettingsTemplate = {
        serviceInput: `${JSON.stringify(actionInput)}`,
        ruleInput: `{}`
      };
      Object.keys(actionInput).forEach(placeholder => {
        actionSettingsTemplate[placeholder] = actionInput[placeholder];
      });
      this.actionSettingsInput = actionSettingsTemplate;
    }
    if (actionDelay) {
      this.actionDelay = actionDelay;
    }
    if (configuredAction.isModeSynchronous()) {
      this.mode = configuredAction.getMode();
      this.actionInputs = ['RULEID', this.product, this.service, 'ENTITY', internalEventName, internalEventSourceProviderName,
        'EVENT', 'OUTBOUND_RESPONSE'];
    }
  }

  public getMode(): string {
    return this.mode;
  }

}
