import { Component, OnInit, OnDestroy, Injector, ViewChild, TemplateRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProgramHomeFilterItems } from 'src/app/shared/models/program-home-filter-items';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { BaseComponent } from '../../client/base/base.component';
import { EntityStatesService } from 'src/app/shared/services/entitystates.service';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { EntityStatesSharedDataService } from '../entity-states-shared-data.service';
import { DatePipe } from '@angular/common';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { EntityType } from 'src/app/shared/models/entity-type';


@Component({
  selector: 'app-entity-states-home',
  templateUrl: './entity-states-home.component.html'
})
export class EntityStatesHomeComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('expandableRowsTemplate', { static: true }) expandableRowsTemplate: TemplateRef<any>;
  @ViewChild('dateTemplateColumn', { static: true }) dateTemplateColumn: TemplateRef<any>;
  public parentId: string;
  public configuredEntityStateList: ConfiguredEntityState[];
  public navigationSubscription;
  public filterItems = ProgramHomeFilterItems.FILTER_ITEMS;
  // Table configuration
  public isPageLoading = false;
  public properties = {};
  searchByName: string;
  readConfiguredEntityStateList: ConfiguredEntityState[];
  public configuredEntityStateTableList = [];
  public configuredEntityStateTableFilteredData = [];

  constructor(
    public entityStateService: EntityStatesService,
    public entityStateSharedService: EntityStatesSharedDataService,
    public parentContext: ParentContextService,
    public router: Router,
    public injector: Injector,
    private datePipe: DatePipe,
    public authorizationService: AuthorizationService) {
    super();
    this.parentId = this.parentContext.getParentContext();
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = true;
        const value = this.parentContext.getParentContext();
        if (value !== '') {
          this.parentId = value;
          if (value !== 'NO_PARENT_CONFIGURED') {
            this.getAllConfiguredEntityStates();
          }
        }
      }
    });
  }

  ngOnInit() : void {
    this.initDataTableConfig();
  }

  handleSearch(searchInput: string) : void {
    if (searchInput === null || searchInput === undefined) {
      searchInput = '';
    }
    if (this.searchByName !== searchInput) {
      this.searchByName = searchInput.trim();
      this.filterConfiguredEvents();
    }
  }

  filterConfiguredEvents(): void {
    if (this.searchByName && this.searchByName.trim()) {
      this.searchByName = this.searchByName.trim();
      this.configuredEntityStateList = this.readConfiguredEntityStateList
        .filter((entity: ConfiguredEntityState) => {
          return entity.name.toLowerCase().includes(this.searchByName.toLowerCase());
        });
    } else {
      this.configuredEntityStateList = this.readConfiguredEntityStateList;
    }
    this.setTableListData();
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.router.url.includes('/add/properties')) {
      this.entityStateSharedService.getEntityStateFormGroup(this.parentId);
    }
  }

  private getAllConfiguredEntityStates() : void {
    this.entityStateService.getAllConfiguredEntityStates(this.parentId).subscribe(
      (res: any) => {
        this.readConfiguredEntityStateList = res.result;
        this.configuredEntityStateList = res.result;
        this.entityStateSharedService.setEntityStateList(this.configuredEntityStateList);
        this.setTableListData();
        this.isPageLoading = false;
      }, () => {
        this.isPageLoading = false;
      });
  }

  private getRouterLink(configuredEntityState: ConfiguredEntityState) : string {
    let routerLink = '';
    if (configuredEntityState.type === EntityType.PACKAGED) {
      routerLink = 'entitystates/system/configure/' + configuredEntityState.id + '/' + configuredEntityState.type + '/edit';
    } else if (configuredEntityState.type === EntityType.CUSTOM) {
      routerLink = 'entitystates/custom/configure/' + configuredEntityState.id + '/' + configuredEntityState.type + '/edit';
    }
    return routerLink;
  }

  private initDataTableConfig() {
    this.isPageLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Type',
          key: 'type',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Description',
          key: 'description',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true,
      expandableRowsTemplate: this.expandableRowsTemplate
    };
  }

  private setTableListData() {
    if (!this.configuredEntityStateList || this.configuredEntityStateList === undefined) {
      return;
    }
    this.configuredEntityStateTableFilteredData = [];
    this.configuredEntityStateList.forEach(entityState => {
      const configuredEntityStateTableRow = {};
      configuredEntityStateTableRow['id'] = entityState.id;
      configuredEntityStateTableRow['name'] = entityState.name;
      configuredEntityStateTableRow['type'] = EntityType.getDisplayName(entityState.type);
      configuredEntityStateTableRow['description'] = entityState.description;
      configuredEntityStateTableRow['timeToLive'] = entityState.timeToLive;
      configuredEntityStateTableRow['entityStateKey'] = entityState.entityStateKey;
      configuredEntityStateTableRow['modifiedDate'] = this.datePipe.transform(new Date(entityState.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
      configuredEntityStateTableRow['modifiedBy'] = entityState.modifiedBy;
      configuredEntityStateTableRow['routerLink'] = this.getRouterLink(entityState);
      this.configuredEntityStateTableFilteredData.push(configuredEntityStateTableRow);
    });
    this.configuredEntityStateTableList = this.configuredEntityStateTableFilteredData.slice(0, 10);
  }

  private sortTableData(sort): void {
    this.configuredEntityStateTableList = this.handleSort(sort, this.configuredEntityStateTableFilteredData);
  }

  private onPageChange(pageData): void {
    this.configuredEntityStateTableList = this.handlePageChange(pageData, this.configuredEntityStateTableFilteredData);
  }

}
