import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-properties',
  templateUrl: './general-properties.component.html'
})
export class GeneralPropertiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}