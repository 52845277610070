import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BluePrintTableColumnType } from 'src/app/shared/blueprint-table-column-type';
import { EVENT_SOURCE, EVENT_SOURCE_PLACEHOLDER, ID_IDENTIFIER,
  ID_PLACEHOLDER, NAME_IDENTIFIER, ROUTE_IDENTIFIER, TYPE_IDENTIFIER } from '../models/association/association-constants';
import { AssociationTemplate } from '../models/association/association-template';
import { ConfiguredEntityAssociation } from '../models/association/configured-entity-association';
import { ConfiguredEntityAssociationParams } from '../models/association/configured-entity-association-params';
import { AssociationService } from './association.service';
import { AuthorizationService } from './authorization-service';

@Injectable({
  providedIn: 'root'
})
export class AssociationsTableBuilderService {

  public isDataLoading = false;
  public properties = {};
  private associationRows: any;
  private modalTableList = [];
  private modalTableData = [];
  private configuredEntityAssociationParams: ConfiguredEntityAssociationParams;
  private entityType: string;
  private associationTableData = new EventEmitter<any>();
  private routerLinks: Map<string, boolean>;

  constructor(
    private associationService: AssociationService,
    private authorizationService: AuthorizationService
  ) { }

  public withEntityType(entityType: string): AssociationsTableBuilderService {
    this.entityType = entityType;
    return this;
  }

  public withConfiguredEntityAssociationParams(configuredEntityAssociationParams: Record<string, any>): AssociationsTableBuilderService {
    this.configuredEntityAssociationParams = configuredEntityAssociationParams as ConfiguredEntityAssociationParams;
    return this;
  }

  public buildAssociationTableDataFromConfig(configuredEntityAssociationParams: ConfiguredEntityAssociationParams): Record<string, any> {
    return this.getAssociationRowsModalDetail(this.buildAssociationRows(new ConfiguredEntityAssociation(configuredEntityAssociationParams)));
  }

  public buildAssociationTableDataAfterLookup(): Observable<any> {
    this.buildAuthorizationForAssociation();
    this.associationService
      .withEntityType(this.entityType)
      .withConfiguredEntityAssociationParams(this.configuredEntityAssociationParams)
      .loadAssociations().subscribe(cea => {
        this.configuredEntityAssociationParams = cea;
        const associationRows = this.buildAssociationRows(new ConfiguredEntityAssociation(this.configuredEntityAssociationParams));
        this.associationTableData.emit({
          entityType: this.entityType,
          tableData: this.getAssociationRowsModalDetail(associationRows)
        });
      });
    return this.associationTableData;
  }

  public getAssociationRowsModalDetail(associationRows: Record<string, any>): Record<string, any> {
    const associationModalTableDetail = {};
    this.associationRows = associationRows;
    associationModalTableDetail['title'] = 'Associations';
    this.initTableConfig();
    associationModalTableDetail['properties'] = this.properties;
    associationModalTableDetail['isTable'] = true;
    associationModalTableDetail['isDataLoading'] = this.isDataLoading ? !this.isDataLoading : this.isDataLoading;
    associationModalTableDetail['data'] = this.initModalTableList();
    associationModalTableDetail['tableData'] = this.modalTableData;
    return associationModalTableDetail;
  }

  public trackAssociationInProgress(): Observable<any> {
    return this.associationService.trackAssociationInProgress();
  }

  public trackAssociationDone(): Observable<any> {
    return this.associationService.trackAssociationDone();
  }

  public buildAssociationRows(configuredEntityAssociation: ConfiguredEntityAssociation): Record<string, any>[] {
    const associationRows = [];
    Object.keys(configuredEntityAssociation.Associations).forEach(entityType => {
      const values = configuredEntityAssociation.Associations[entityType] as Array<Record<string, any>>;
      values.forEach(element => {
        const associationRow = {};
        associationRow[ID_IDENTIFIER] = element[ID_IDENTIFIER];
        associationRow[NAME_IDENTIFIER] = element[NAME_IDENTIFIER];
        associationRow[TYPE_IDENTIFIER] = AssociationTemplate[entityType.toUpperCase()].displayName;
        associationRow[ROUTE_IDENTIFIER] = this.getRoute(configuredEntityAssociation.AssociationTemplateDetail.Routes, entityType, element);
        associationRows.push(associationRow);
      });
    });
    return associationRows;
  }

  private buildAuthorizationForAssociation(): AssociationsTableBuilderService {
    if (!this.routerLinks) {
      this.routerLinks = new Map<string, boolean>();
      this.routerLinks.set(AssociationTemplate.RULE.displayName, this.authorizationService.isCategoryAccessible('RULES', 'OR'));
      this.routerLinks.set(AssociationTemplate.ACTION.displayName, this.authorizationService.isCategoryAccessible('ACTIONS', 'OR'));
      this.routerLinks.set(AssociationTemplate.PROGRAM.displayName, this.authorizationService.isCategoryAccessible('PROGRAMS', 'OR'));
      this.routerLinks.set(AssociationTemplate.EVENT.displayName, this.authorizationService.isCategoryAccessible('EVENTS', 'OR'));
      this.routerLinks.set(AssociationTemplate.EXCLUSION.displayName, this.authorizationService.isCategoryAccessible('EXCLUSIONS', 'OR'));
    }
    return this;
  }

  private initTableConfig(): void {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.LINK,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}',
            action: 'view'
          }
        },
        {
          key: 'type',
          headerText: 'Type',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: false,
      hasDisplayDensity: false
    };
  }

  private initModalTableList(): any {
    if (!this.associationRows || this.associationRows === undefined) {
      return;
    }
    this.isDataLoading = true;
    this.modalTableData = [];
    this.associationRows.forEach(row => {
      const modalTableRow = {};
      modalTableRow['id'] = row.id;
      modalTableRow['name'] = row.name;
      modalTableRow['type'] = row.type;
      if (this.routerLinks.get(row.type) !== undefined && this.routerLinks.get(row.type)) {
        modalTableRow['routerLink'] = row.route.slice('src/app/modules'.length);
      }
      this.modalTableData.push(modalTableRow);
    });
    this.modalTableList = this.modalTableData.slice(0, 10);
    this.isDataLoading = false;
    return this.modalTableList;
  }

  private getRoute(routes: Record<string, any>, entityType: string, element) {
    const routeDetail = routes[entityType.toUpperCase()];
    let route = routeDetail[element[TYPE_IDENTIFIER]];
    if (EVENT_SOURCE in element) {
      route = route.replace(EVENT_SOURCE_PLACEHOLDER, element[EVENT_SOURCE]);
    }
    return route.replace(ID_PLACEHOLDER, element[ID_IDENTIFIER]);
  }


}