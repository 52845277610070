<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <p class="text-style-muted-1 m-0">Select query parameter and input search criteria.</p>
    </div>
  </div>
  <form [formGroup]="imsLookupForm">
    <div class="row mt-3">
      <div class="col-3">
        <div class="form-group">
          <coreui-form-field label="Select query parameter" for="lookUpName">
            <coreui-select id="lookUpName" formControlName="lookUpName" placeholder="Select a lookup key"
              (valueChange)="onItemChange($event)" #defaultSelect>
              <coreui-option *ngFor="let item of lookUpKeys()" [value]="item">{{item}}</coreui-option>
            </coreui-select>
            <coreui-form-error>
                Please select a lookup key
            </coreui-form-error>
          </coreui-form-field>
        </div>
      </div>
      <div class="col-3">
        <coreui-form-field label="Key">
          <input coreUIInput formControlName="key" placeholder="Enter key" />
          <coreui-form-error>Required</coreui-form-error>
        </coreui-form-field>
      </div>
      <div class="col pt-4">
        <app-ae-button (click)="handleSearch()" [disabled]="imsLookupForm.invalid" buttonText="Lookup">
        </app-ae-button>
      </div>
    </div>
    <div class="row" *ngIf="!noResults && imsLookupRecords.length > 0">
      <div class="col">
        <app-ae-table [isDataLoading]="isPageLoading" [properties]="properties"
        [configuredTableData]="imsLookupRecords">
        </app-ae-table>
      </div>
    </div>
    <div class="row" *ngIf="noResults">
      <div class="col">
        <coreui-graphic-message label="No Results Found" graphic="noResults">
            Sorry, we did not find any results.
        </coreui-graphic-message>
      </div>
    </div>
  </form>
</div>
