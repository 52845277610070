<form class="row border mx-1 mb-3" [formGroup]="operationFormGroup">
  <div class="col ml-2 border-right">
    <div class="row">
      <div class="col ml-4">
        <div class="row">
          <div *ngIf="!showOperationNameToggleButton()" class="col-auto pt-4 pl-0 pr-1">
            {{ getOperationNameToDisplay() | titlecase }}
          </div>
          <div *ngIf="showOperationNameToggleButton()" class="col-auto pt-4 pl-0 pr-1">
            <coreui-form-field>
              <coreui-toggle [checked]="operatioNameToggleConfig.checked" [checkedLabel]="operatioNameToggleConfig.checkedLabel | titlecase " [uncheckedLabel]="operatioNameToggleConfig.unCheckedLabel | titlecase" [disabled]="isReadOnlyRule" (valueChange)="onOperationNameToggleValueChange($event)"></coreui-toggle>
            </coreui-form-field>
          </div>
          <div class="col-auto pt-4 pl-0 pr-1" *ngIf="!isAddOperation()">
            <span class="mx-1 h3">{{getEntityStateAttributePath()}}</span>{{getEntityStateAttributeDataType() === dataTypes.number && getFormControlOperationName().value === operationNames.INCREMENT ? 'by' : 'to' }}
          </div>
          <div *ngIf="isValueTypeConstant() && !isEntityStateAttributeOfTypeDateTime() && !isAddOperation()" class="col-auto pt-3 pl-1">
            <coreui-form-field>
              <input *ngIf="getEntityStateAttributeDataType() === dataTypes.string" type="text" coreUIInput id="value" name="value" formControlName="value" placeholder="Enter a value" />
              <input *ngIf="getEntityStateAttributeDataType() === dataTypes.number" type="text" coreUIInput id="value" name="value" formControlName="value" [coreUIDecimalMask]="decimalMaskConfig" placeholder="Enter a number">
              <input *ngIf="getEntityStateAttributeDataType() === dataTypes.array" type="text" coreUIInput name="value" formControlName="value" placeholder="Enter comma separated values" />
              <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
            </coreui-form-field>
          </div>
          <div *ngIf="isValueTypeEventAttribute() && !isAddOperation()" class="col-auto py-4 pl-0">
            <span class="h3">{{getEventAttributePath()}}</span>
          </div>
          <ng-container *ngIf="isValueTypeConstant() && isEntityStateAttributeOfTypeDateTime() && getEntityStateAttributeDataType() !== dataTypes.array">
            <div class="col-auto pt-3 pl-1">
              <coreui-form-field>
                <div class="date-time-picker-wrapper">
                  <input type="text" coreUIInput id="value" name="value" formControlName="value" placeholder="Enter a date" withIcon [owlDateTime]="valuePicker">
                  <i coreUIIcon="calendar" class="date-time-picker-calendar-icon" [owlDateTimeTrigger]="valuePicker"></i>
                  <owl-date-time #valuePicker></owl-date-time>
                </div>
                <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
              </coreui-form-field>
            </div>
            <div class="col-auto pt-4 pl-0">
              <coreui-checkbox-group orientation="horizontal">
                <coreui-form-field label="Set to current date & time">
                  <input type="checkbox" coreUICheckbox formControlName="setCurrentDateTime"/>
                </coreui-form-field>
              </coreui-checkbox-group>
            </div>
          </ng-container>
          <!-- Handle array ADD operation -->
          <ng-container *ngIf="getEntityStateAttributeDataType() === dataTypes.array && !isEntityStateAttributeOfTypeDateTime() && getFormControlOperationName().value === operationNames.ADD">
            <div class="col-auto pt-3 px-1">
              <coreui-form-field>
                <input type="text" coreUIInput name="value" formControlName="value" placeholder="Enter comma separated values" />
                <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
              </coreui-form-field>
            </div>
            <div class="col-auto pt-4 pl-0 pr-1">
              <span class="mx-1">to</span>
              <span class="mx-1 h3">{{getEntityStateAttributePath()}}</span>
            </div>
          </ng-container>
          <!-- Handle array date-time SET, ADD and APPEND_CURRENT_DATE_TIME operation -->
          <ng-container *ngIf="getEntityStateAttributeDataType() === dataTypes.array && isEntityStateAttributeOfTypeDateTime()">
            <div class="col-auto pt-3 px-1">
              <coreui-form-field>
                <div class="date-time-picker-wrapper">
                  <input type="text" coreUIInput id="value" name="value" formControlName="value" placeholder="Enter a date" withIcon [owlDateTime]="valuePicker">
                  <i coreUIIcon="calendar" class="date-time-picker-calendar-icon" [owlDateTimeTrigger]="valuePicker"></i>
                  <owl-date-time #valuePicker></owl-date-time>
                </div>
                <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
              </coreui-form-field>
            </div>
            <ng-container *ngIf="isAddOperation()">
              <div class="col-auto pt-4 pl-0 pr-1">
                <span class="mx-1">to</span>
                <span class="mx-1 h3">{{getEntityStateAttributePath()}}</span>
              </div>
              <div class="col-auto pt-4 pl-0">
                <coreui-checkbox-group orientation="horizontal">
                  <coreui-form-field label="Add current date & time">
                    <input type="checkbox" coreUICheckbox formControlName="addCurrentDateTime"/>
                  </coreui-form-field>
                </coreui-checkbox-group>
              </div>
            </ng-container>
          </ng-container>
          <div class="col p-1 drop-attributes" [treeAllowDrop]="canDropEventAttributeToSet.bind(this)" (treeDrop)="onEventAttributeDrop($event)"></div>      
        </div>
      </div>
    </div>
    <div class="row border-top ml-2" *ngIf="isAddOperation()">
      <div class="col">
        <div class="row mb-3">
          <div>
            <div class="my-1 h3" (click)="showHideListDataSettings()">
              Data Settings 
              <ng-container *ngIf="!isListDataSettingsExpanded">
                <em class="mx-1" coreUIIcon="chevronRight" scale="1.5"></em>
              </ng-container>           
              <ng-container *ngIf="isListDataSettingsExpanded">
                <em class="mx-1" coreUIIcon="chevronDown" scale="1.5"></em>
              </ng-container>
            </div>
            <ng-container *ngIf="isListDataSettingsExpanded">  
              <div> 
              <coreui-checkbox-group>
                <coreui-form-field label="Dedupe">
                  <input type="checkbox" coreUICheckbox formControlName="deDupe" />
                </coreui-form-field>
                <a [coreUITooltip]="deDupeTooltip">
                  <em coreUIIcon="help" class="u-colorBlue50 u-fontSizeInherit mx-1" scale="1"></em>
                </a>
              </coreui-checkbox-group>
                <ng-template #deDupeTooltip>
                  Remove duplicate entries in the list
                </ng-template>
              </div>
              <coreui-radio-group orientation="horizontal">
                <coreui-form-field label="LimitTop">
                    <input type="radio" coreUIRadio value="TOP" name="trimFrom" id="trimFrom" formControlName="trimFrom" />
                </coreui-form-field>
                <ng-template #topTooltip>
                  Remove/Replace the right-most elements from the list
                </ng-template>
                <a [coreUITooltip]="topTooltip">
                  <em coreUIIcon="help" class="u-colorBlue50 u-fontSizeInherit mx-1" scale="1"></em>
                </a>
                <coreui-form-field label="LimitBottom">
                    <input type="radio" coreUIRadio value="BOTTOM" name="trimFrom" id="trimFrom" formControlName="trimFrom" />
                </coreui-form-field>
                <ng-template #bottomTooltip>
                  Remove/Replace the left-most elements from the list
                </ng-template>
                <a [coreUITooltip]="bottomTooltip">
                  <em coreUIIcon="help" class="u-colorBlue50 u-fontSizeInherit mx-1" scale="1"></em>
                </a>
              </coreui-radio-group>
              <coreui-form-field>
                <input type="text" coreUIInput name="maxLength" id="maxLength" formControlName="maxLength" placeholder="System limit 100"  coreUIIntegerMask/>
                <coreui-form-error>{{messages.maxLength}}</coreui-form-error>
              </coreui-form-field>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-auto align-self-center text-right" *ngIf="showDeleteButton">
  <app-ae-button (click)="this.onDeleteOperation()" buttonType="micro-feedback-error-secondary" [disabled]="isReadOnlyRule" buttonText="Delete"></app-ae-button>
  </div>
</form>