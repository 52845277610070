import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormOnSaveAction } from 'src/app/shared/constants';
import { DurationUnitList, DurationUnits } from 'src/app/shared/models/exclusion/duration-units';
import { Duration } from 'luxon';
import { ExclusionConfigurationBaseOperator } from 'src/app/shared/exclusion/exclusion-configuration/exclusion-configuration-base-operator';
import { AttributeMetadata } from 'src/app/shared/models/custom-rule/conditions/attribute-metadata';
import { DataTypes } from 'src/app/shared/models/custom-rule/conditions/data-types';
import { ValueType } from 'src/app/shared/models/custom-rule/conditions/value-type';
import { AttributeCondition } from 'src/app/shared/models/custom-rule/conditions/attribute-condition';

@Component({
  selector: 'app-exclusion-configuration-last-n-occurrences',
  templateUrl: './exclusion-configuration-last-n-occurrences.component.html',
  styleUrls: ['./exclusion-configuration-last-n-occurrences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExclusionConfigurationLastNOccurrencesComponent extends ExclusionConfigurationBaseOperator implements OnInit {

  public readonly QUANTITY = 'quantity';
  public formControlNames = {
    'OCCURRENCES': 'occurrences',
    'OCCURRENCES_UNIT': 'occurrencesUnit',
    'DURATION': 'duration',
    'DURATION_UNIT': 'durationUnit'
  };
  public decimalMaskConfig = {
    disallowNegative: true,
    thousandsSeparator: ',',
    includeThousandsSeparator: false,
    decimalPrecision: 0,
    decimalPoint: '.'
  };
  public durationUnitList = DurationUnitList;

  private inputOccurences: number;
  private inputDuration: number;
  private inputDurationUnit: DurationUnits;
  protected inputOccurencesAttribute: AttributeCondition;
  protected inputDurationAttribute: AttributeCondition;
  private validDataTypesForOccurenceAndDuration : DataTypes[] = [DataTypes.string];

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.initInput();
    this.initFormGroup();
  }

  public validate(): boolean {
    const occurrences = this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).value;
    const duration = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).value;
    const isValidOccurences = this.isInputOccurencesAttributeConfigured() || this.validateOccurences(occurrences);
    const isValidduration = this.isInputDurationAttributeConfigured() || this.validateDuration(duration);
    return isValidOccurences && isValidduration;
  }

  public buildAttributeConditionValues(): unknown[] {
    const occurrences = this.isInputOccurencesAttributeConfigured() ? this.inputOccurencesAttribute :
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).value;
    if (!this.isInputDurationAttributeConfigured()) {
      const duration = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).value;
      const durationUnit: string = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).value;
      const durationJSON = Object.create({});
      durationJSON[durationUnit] = duration;
      const durationInISO = Duration.fromObject(durationJSON);
      return [occurrences, durationInISO.toISO()];
    }
    return [occurrences, this.inputDurationAttribute];
  }

  buildAttributeConditionValue(attributeMetadata: AttributeMetadata): AttributeCondition {
    const attribute: AttributeCondition = new AttributeCondition(attributeMetadata.name, attributeMetadata.path, attributeMetadata.type, attributeMetadata.sourceType);
    attribute.operator = undefined;
    attribute.valueType = undefined;
    return attribute;
  }

  private initInput(): void {
    this.inputOccurences = undefined;
    this.inputDuration = undefined;
    this.inputDurationUnit = DurationUnits.MINUTES;
    if (this.action === FormOnSaveAction.EDIT) {
      const inputOccurencesValue = this.attributeConditionValues[0];
      const inputDurationValue = this.attributeConditionValues[1];
      if (typeof inputOccurencesValue === 'object') {
        this.inputOccurencesAttribute = Object.assign(AttributeCondition.getEmptyAttributeCondition(), inputOccurencesValue);
      } else {
        this.inputOccurences = inputOccurencesValue as number;
      }
      if (typeof inputDurationValue === 'object') {
        this.inputDurationAttribute = Object.assign(AttributeCondition.getEmptyAttributeCondition(), inputDurationValue);
      } else {
        this.initDurationDetails(inputDurationValue as string);
      }
    }
  }

  private initDurationDetails(duration: string): void {
    const jsonDuration = Duration.fromISO(duration).toObject();
    const jsonDurationUnit = Object.keys(jsonDuration).shift();
    this.inputDurationUnit = DurationUnits[jsonDurationUnit.toLocaleUpperCase()];
    this.inputDuration = jsonDuration[jsonDurationUnit];
  }

  private initFormGroup(): void {
    this.exclusionConfigurationFormGroup = new UntypedFormGroup({
      occurrences: new UntypedFormControl(this.inputOccurences),
      occurrencesUnit: new UntypedFormControl(this.QUANTITY),
      duration: new UntypedFormControl(this.inputDuration),
      durationUnit: new UntypedFormControl(this.inputDurationUnit.toString())
    });
    if (this.readOnly) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES_UNIT).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).disable();
    }
  }

  private validateOccurences(occurences: number): boolean {
    const isValid = this.isValidOccurences(occurences);
    if (!isValid) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).setErrors({ required: true });
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).markAsDirty();
    }
    return isValid;
  }

  private isValidOccurences(occurences: number): boolean {
    return occurences && !isNaN(occurences);
  }

  private validateDuration(duration: number): boolean {
    const isValid = this.isValidduration(duration);
    if (!isValid) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).setErrors({ required: true });
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).markAsDirty();
    }
    return isValid;
  }

  private isValidduration(duration: number): boolean {
    return duration && !isNaN(duration);
  }

  public isInputOccurencesAttributeConfigured(): boolean {
    return this.inputOccurencesAttribute !== undefined && this.inputOccurencesAttribute !== null;
  }

  public isInputDurationAttributeConfigured(): boolean {
    return this.inputDurationAttribute !== undefined && this.inputDurationAttribute !== null;
  }

  public inputOccurencesAttributeDropped(event: any): void {
    let attribute = this.attributeMetadataList.find(attribute => attribute.id === event.item.data);
    if (this.validDataTypesForOccurenceAndDuration.indexOf(attribute.type) >= 0) {
      this.inputOccurencesAttribute = this.buildAttributeConditionValue(attribute);
      this.exclusionConfigurationFormGroup.setControl(this.formControlNames.OCCURRENCES, new UntypedFormControl(undefined));
    }
  }

  public inputDurationAttributeDropped(event: any): void {
    let attribute = this.attributeMetadataList.find(attribute => attribute.id === event.item.data);
    if (this.validDataTypesForOccurenceAndDuration.indexOf(attribute.type) >= 0) {
      this.inputDurationAttribute = this.buildAttributeConditionValue(attribute);
      this.exclusionConfigurationFormGroup.setControl(this.formControlNames.DURATION, new UntypedFormControl(undefined));
    }
  }

  public getConditionValueType(): ValueType {
    return this.isInputOccurencesAttributeConfigured() || this.isInputDurationAttributeConfigured() ? ValueType.LIST : ValueType.CONSTANT;
  }

}