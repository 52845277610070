import { v4 as uuid } from 'uuid';
import { ActionDestination } from './action-destination';
import { ActionSettings } from './ActionSettings';
import { AwsActionSettings } from './aws-action-settings';
import { ActionDestinationType } from './action-destination-type';

export class AwsActionDestination implements ActionDestination {

  parentId: string;
  name: string;
  description: string;
  authenticationMethod: string;
  url: string;
  awsActionSettings: ActionSettings;

  public buildPostPayload() {
    const awsActionSettingInstance = this.awsActionSettings as AwsActionSettings;
    return {
      'parentId': `${this.parentId}`,
      'actionDestination': `${uuid()}`,
      'type': ActionDestinationType.AWS,
      'name': `${this.name}`,
      'description': `${this.description}`,
      'actionSettings': [awsActionSettingInstance.buildActionSettings()]
    };
  }

  public buildUpdatePayload() {
    const awsActionSettingInstance = this.awsActionSettings as AwsActionSettings;
    return {
      'name': `${this.name}`,
      'description': `${this.description}`,
      'type': ActionDestinationType.AWS,
      'actionSettings': [awsActionSettingInstance.buildActionSettings()]
    };
  }
  public buildTestConnectionPayload() {
    const awsActionSettingInstance = this.awsActionSettings as AwsActionSettings;
    return awsActionSettingInstance.buildJSONActionSettings();

  }

}