import { Feature } from '../permission/feature/role-permission-constants';
import { ScheduleAction } from './schedule-action';
import { ScheduleStatus } from './schedule-status';

export class ConfiguredSchedule {

  parentId: string;
  scheduleId: string;
  featureId: string;
  feature: Feature;
  scheduleStatus: ScheduleStatus;
  scheduleAction: ScheduleAction;
  scheduleTime: number;
  expirationTime: number;
  
  constructor(result?: Object) {
    if (result) {
      Object.assign(this, JSON.parse(JSON.stringify(result)));
    }
  }


}