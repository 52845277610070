import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ConfiguredRecommendation } from '../models/package-builder/configured-recommendation';

@Injectable({ providedIn: 'root' })
export class IQDecisionsService {

  private host = environment.host;
  private base = environment.base;
  private packagesTypesUrl = 'recommendations/packaged/';
  private iqDecisionsUrl = 'recommendations/configured/';


  constructor(private http: HttpClient) { }

  public getAllConfiguredIQDecisionsRequest(parentId: string) : Observable<any> {
    const url = this.getBaseUrl;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };

    return this.http.get(url, options);
  }

  public addIQDecisions(configuredRecommendation: ConfiguredRecommendation, parentId: string): Observable<any> {
    const url = this.getBaseUrl;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.post(url, configuredRecommendation, options);
  }

  public getIQDecisions(id: string, parentId: string): Observable<any> {
    const url = this.getBaseUrl + id;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };

    return this.http.get(url, options);
  }

  public getPackagedTypes(companyId: string, parentId: string): Observable<any> {
    const url = this.host + this.base + this.packagesTypesUrl + companyId;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  public publishIQDecisions(id: string, parentId: string): Observable<any> {
    const url = this.getBaseUrl + 'publish/' + id;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };

    return this.http.put(url, {}, options);
  }

  public unpublishIQDecisions(id: string, parentId: string): Observable<any> {
    const url = this.getBaseUrl + 'unpublish/' + id;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };

    return this.http.put(url, {}, options);
  }

  public updateIQDecisions(id: string, configuredRecommendation: ConfiguredRecommendation, parentId: string): Observable<any> {
    const url = this.getBaseUrl + id;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.put(url, configuredRecommendation, options);
  }

  public deleteIQDecisions(id: string, parentId: string): Observable<any> {
    const url = this.getBaseUrl + id;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.delete(url, options);
  }

  private get getBaseUrl() {
    return this.host + this.base + this.iqDecisionsUrl;
  }

}