import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { auditTime, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-ae-table-search',
  templateUrl: './ae-table-search.component.html',
  styleUrls: ['./ae-table-search.component.scss']
})
export class AeTableSearchComponent implements OnInit {

  @Input()
  label = '';

  @Input()
  placeholder = '';

  @Input()
  errorMsg: string;

  @Output() searchEmitter: EventEmitter<string> = new EventEmitter<string>();

  formGroup: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      search: new UntypedFormControl('', Validators.minLength(3))
    });
    this.emitter();
  }

  private emitter(): void {
    this.formGroup.get('search').valueChanges.pipe(
      auditTime(250),
      startWith('')
    ).subscribe((search: string) => {
      this.searchEmitter.emit(search);
    });
  }

  public getInput(): string{
    return this.formGroup.get('name').toString();
  }

}
