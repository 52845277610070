import { Messages } from './message';

export class WarningOptions {

  title: string;
  message: string;
  message2: Array<any>;
  btnOkText: string;
  btnCancelText: string;
  btnOkClass: string;
  isAlert: boolean;

  public static unsavedChanges(msg:Array<any> = []): WarningOptions {
    const w = this.commonAssignments(Messages.unsavedChanges, Messages.leaveWithoutSaving, msg);
    w.btnOkText = Messages.discardChanges;
    w.btnCancelText = Messages.keepEditing;
    w.btnOkClass = 'btn-danger';
    w.isAlert = false;
    return w;
  }

  public static deleteEntity(title: string, msg: string, msg2: Array<any> = []): WarningOptions {
    const w = this.commonAssignments(title, msg, msg2);
    w.btnOkText = Messages.deleteBtnTxt;
    w.btnCancelText = Messages.cancelBtnTxt;
    w.btnOkClass = 'btn-danger';
    w.isAlert = false;
    return w;
  }

  public static changeMode(title: string, msg: string, msg2: Array<any> = []): WarningOptions {
    const w = this.commonAssignments(title, msg, msg2);
    w.btnOkClass = 'btn-primary';
    w.btnCancelText = Messages.cancelBtnTxt;
    w.btnOkText = Messages.continue;
    return w;
  }

  public static alert(title: string, msg: string, msg2: Array<any> = []): WarningOptions {
    const w = this.commonAssignments(title, msg, msg2);
    w.btnCancelText = Messages.closeTxt,
    w.isAlert = true;
    return w;
  }

  private static commonAssignments(title: string, msg: string, msg2: Array<any> = []): WarningOptions {
    const w = new WarningOptions();
    w.title = title;
    w.message = msg;
    w.message2 = msg2;
    return w;
  }

}

export enum WarningType {
  UNSAVED_CHANGES_WARNING,
  DELETE_ENTITY_WARNING,
  CHANGE_PROGRAM_MODE_WARNING,
  ALERT
}