import { HttpMethod } from './http-method';
import { Header } from './header';
import { ActionDelay } from './action-delay';
import { EntityType } from '../entity-type';

export class ServiceSettings {

  serviceName: string;
  type: string;
  path: string;
  payload: string;
  contentType: string;
  actionDelay: ActionDelay;
  httpHeaders: Map<string, string>;
  httpMethod: HttpMethod;

  constructor() {
    this.serviceName = '';
    this.type = '';
    this.path = '';
    this.payload = '';
    this.contentType = '';
    this.actionDelay = new ActionDelay();
  }

  public getHeaders(entityType: string) : Header[] {
    if (this.httpHeaders) {
      return this.getHeaderAsArray(this.httpHeaders, entityType);
    }
    return [];
  }

  private getHeaderAsArray(obj : Map<string, string>, entityType: string) : Header[] {
    const httpHeaders = entityType === EntityType.PACKAGED.toLowerCase() ? JSON.parse(JSON.parse(JSON.stringify(obj))) : obj;
    const headers : Header[] = [];
    Object.keys(httpHeaders).forEach(function(key) {
      headers.push(new Header(key, httpHeaders[key]));
    });
    return headers;
  }

  public setHeaders(headers: Header[]) {
    this.httpHeaders = new Map<string, string>();
    headers.forEach(header => {
      this.httpHeaders[header.headerName.trim()] = header.headerValue.trim();
    });
  }

  public getDelayType() {
    if (this.actionDelay) {
      return this.actionDelay.delayType;
    }
    return null;
  }

  public getDelay() {
    if (this.actionDelay) {
      return this.actionDelay.delay;
    }
    return null;
  }

}