import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableColumnType } from 'epsilon-blueprint';
import { BluePrintTableColumnType } from 'src/app/shared/blueprint-table-column-type';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { takeUntil, catchError } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { Messages } from 'src/app/shared/message';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { ConfiguredUserGroupService } from 'src/app/shared/services/configured-user-group.service';
import { PackagedUserGroupService } from 'src/app/shared/services/packaged-user-group.service';
import { UserGroupDetails } from 'src/app/shared/models/user-groups/user-group-details';
import { ConfiguredRolesService } from 'src/app/shared/services/configured-roles.service';
import { ConfiguredRole } from 'src/app/shared/models/user-roles/configured-role';
import { RoleInfo } from 'src/app/shared/models/user-roles/role-info';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { Type } from 'src/app/shared/models/roleType';
import { WarningType } from 'src/app/shared/warning-options';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { UserGroupService } from 'src/app/shared/services/user-group-service-interface';
import { USER_GROUP_CLASS_TOKEN, USER_GROUP_SERVICE_TOKEN } from 'src/app/shared/tokens';

@Component({
  selector: 'app-user-group-configuration',
  templateUrl: './user-group-configuration.component.html'
})
export class UserGroupConfigurationComponent extends BaseFormDirective implements OnInit {

  public isApiServiceCalled: boolean;
  public properties = {};
  public userGroupsLandingPageRouterLink: string;
  public configuredProgramTableList = [];
  public configuredProgramTableFilteredData = [];
  public operationStatusMessage = '';
  public operation: string;
  public currentUrl: string;
  public userGroupId: string;
  public usergroupType: string;
  public isAdmin: boolean;
  public userGroupsForParent = new Set<string>();
  public configuredRoles: ConfiguredRole[] = [];
  public roleInfo: RoleInfo[];
  public shouldShowActionButtons = true;
  public userGroupDetails: UserGroupDetails;
  public isDeleteOperation: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private parentId: string;
  private multiSelectItems: BehaviorSubject<RoleInfo[]> = new BehaviorSubject<RoleInfo[]>([]);

  constructor(
    private parentContextService: ParentContextService,
    @Inject(USER_GROUP_SERVICE_TOKEN) private userGroupService: UserGroupService,
    private packagedUserGroupService: PackagedUserGroupService,
    private configuredUserGroupService: ConfiguredUserGroupService,
    private popupService: PopupMessageService,
    private router: Router,
    public rolesService: ConfiguredRolesService,
    public fb: UntypedFormBuilder, private route?: ActivatedRoute,
    public authorizationService?: AuthorizationService) {
    super();
    this.route.params.subscribe(params => {
      this.operation = params.action;
      this.userGroupId = params.id;
      this.usergroupType = params.type;
    });
  }

  public ngOnInit(): void {
    this.userGroupsLandingPageRouterLink = this.userGroupService.getNavPath();
    this.isDataSaved = false;
    this.parentId = this.parentContextService.getParentContext();
    this.operationStatusMessage = 'Loading User Group Details';
    this.buildUserGroupDetailsForm();
    this.initUserRolesDataTableConfig();
    this.getEntities();
  }

  public saveUserGoupeDetails(): void {
    this.isSaveClicked = true;
    this.validateForm();
    if (this.configuredEntityForm.invalid) {
      this.popupService.showErrorMessage(Messages.userGroupMandatoryFieldErrorMessage);
    } else if (!this.configuredEntityForm.invalid && this.operation === 'createUserGroup') {
      this.userGroupDetails = this.getUserDetails();
      this.createUserGroup(this.userGroupDetails);
    } else if (!this.configuredEntityForm.invalid && this.operation === 'editUserGroup') {
      this.userGroupDetails = this.getUserDetails();
      this.updateUserGroup(this.userGroupDetails);
    }
  }

  public getUserDetails(): UserGroupDetails {
    const userGroupDetails = new UserGroupDetails();
    userGroupDetails.id = this.configuredEntityForm.get('id').value;
    userGroupDetails.name = this.configuredEntityForm.get('name').value;
    userGroupDetails.description = this.configuredEntityForm.get('description').value;
    userGroupDetails.active = this.configuredEntityForm.get('isActive').value;
    userGroupDetails.roles = this.configuredEntityForm.get('roles').value;
    return userGroupDetails;
  }

  public validateForm(): void {
    if (this.configuredEntityForm.get('name').hasError('required')) {
      return;
    }
  }

  public showDeleteUserGroupAlert(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteUserGroups,
      msg: Messages.deleteUserGroupWarningMessage,
      msg2: [Messages.deleteUserGroupWarningMessage2]
    });
  }

  public handleDecision(userConfirmation: boolean): void {
    if (userConfirmation && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteUserGroup();
    }
  }

  public get RolesFormControlName(): string {
    return 'roles';
  }

  public get RolesLabel(): string {
    return Messages.rolesLabel;
  }

  public get SelectRolesPlaceholderTxt(): string {
    return Messages.selectRolesPlaceholderTxt;
  }

  public get MultiSelectDataStream(): BehaviorSubject<RoleInfo[]> {
    return this.multiSelectItems;
  }

  private deleteUserGroup(): void {
    this.operationStatusMessage = Messages.deletedUserGroups;
    this.userGroupService.deleteUserGroupById(this.parentId, this.userGroupId)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.isDeleteOperation = true;
        this.popupService.showSuccessMessage(Messages.deletedUserGroups);
        void this.router.navigate([this.userGroupService.postDeleteRouterLink()]);
      }, error => {
        this.popupService.showErrorMessage(Messages.unableToDeleteUserGroups + ' ' + error.error.statusMessage);
      });
  }

  private buildUserGroupDetailsForm(): void {
    this.buildEmptyUserGroupDetails();
    if (this.operation === 'editUserGroup') {
      this.shouldShowActionButtons = this.usergroupType === Type.CLIENT.toLowerCase();
    } else {
      this.buildForm();
      this.usergroupType = Type.CLIENT.toLowerCase();
    }
    if (this.userGroupService.isAdmin()) {
      this.shouldShowActionButtons = true;
    }
  }

  private buildEmptyUserGroupDetails(): void {
    this.userGroupDetails = new UserGroupDetails();
    this.userGroupDetails.name = '';
    this.userGroupDetails.description = '';
    this.userGroupDetails.roles = [];
    this.userGroupDetails.active = true;
  }

  private buildForm(): void {
    this.userGroupDetails.description = this.userGroupDetails.description === null ? '' : this.userGroupDetails.description;
    if (this.operation === 'editUserGroup') {
      this.configuredEntityForm = this.fb.group({
        id: new UntypedFormControl(this.userGroupDetails.id),
        name: new UntypedFormControl(this.userGroupDetails.name, { validators: [Validators.required] }),
        description: new UntypedFormControl(this.userGroupDetails.description),
        roles: new UntypedFormControl(this.userGroupDetails.roles),
        isActive: new UntypedFormControl(this.userGroupDetails.active)
      });
      this.configuredEntityForm.controls.name.disable();
    } else {
      this.configuredEntityForm = this.fb.group({
        id: new UntypedFormControl(this.userGroupDetails.id),
        name: new UntypedFormControl(this.userGroupDetails.name, { validators: [Validators.required] }),
        description: new UntypedFormControl(this.userGroupDetails.description),
        roles: new UntypedFormControl(this.userGroupDetails.roles),
        isActive: new UntypedFormControl(this.userGroupDetails.active)
      });
    }
    this.disableFormFields();
  }

  private disableFormFields(): void {
    if ((!this.authorizationService.isAllowed('CFG_USR_GRP', ['CREATE', 'UPDATE']))) {
      this.configuredEntityForm.disable();
    }
    if (!this.userGroupService.isAdmin() && this.usergroupType === Type.GLOBAL.toLowerCase()) {
      this.configuredEntityForm.disable();
    }
  }

  private initUserRolesDataTableConfig() {
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Name',
          key: 'roleName',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.LINK,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            action: 'edit'
          }
        },
        {
          headerText: 'Username',
          key: 'Username',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Status',
          key: 'roleStatus',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'User Groups',
          key: 'userGroups',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Create Date',
          key: 'roleCreateDate',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'roleModifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          type: TableColumnType.TEMPLATE
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: false,
      hasDisplayDensity: false
    };
  }

  private createUserGroup(userGroupDetails: UserGroupDetails): void {
    this.userGroupService.addUserGroup(this.parentId, userGroupDetails)
      .pipe(takeUntil(this.destroyed$)).subscribe((result) => {
        userGroupDetails = new UserGroupDetails(result.result);
        this.isSaveClicked = false;
        this.isDataSaved = true;
        this.configuredEntityForm.markAsPristine();
        this.popupService.showSuccessMessage('User Group "' + this.configuredEntityForm.controls.name.value + '" successfully added');
        this.isApiServiceCalled = false;
        void this.router.navigate([this.userGroupService.getNavPath()]);
      }, error => {
        this.isSaveClicked = false;
        this.isDataSaved = false;
        this.isApiServiceCalled = false;
        if (error.status === 403) {
          this.popupService.showErrorMessage(error.error.statusMessage);
        } else {
          this.popupService.showErrorMessage(error.error.result);
        }
      });
  }

  private updateUserGroup(userGroupDetails: UserGroupDetails): void {
    this.userGroupService.updateUserGroup(this.parentId, userGroupDetails)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.isApiServiceCalled = false;
        this.popupService.showSuccessMessage('User Group "' + this.configuredEntityForm.controls.name.value + '" successfully updated');
        this.configuredEntityForm.markAsPristine();
        this.isSaveClicked = false;
        this.isDataSaved = false;
        void this.router.navigate([this.userGroupService.getNavPath()]);
      }, error => {
        this.isApiServiceCalled = false;
        this.isSaveClicked = false;
        this.isDataSaved = false;
        if (error.status === 403) {
          this.popupService.showErrorMessage(error.error.statusMessage);
        } else {
          this.popupService.showErrorMessage(Messages.updateUserGroupErrorMessage);
        }
      });
  }

  private getEntities() {
    this.isApiServiceCalled = true;
    this.callAPIs().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      this.configuredRoles = result[0].result as ConfiguredRole[];
      this.roleInfo = this.configuredRoles.map(role =>
        ({ parentId: role.parentId ? role.parentId : role.partitionKey, name: role.name, id: role.id })
      );
      this.multiSelectItems.next(this.roleInfo);
      if (this.operation === 'editUserGroup') {
        const userGroupResponse = result[1].result;
        this.userGroupDetails = new UserGroupDetails(userGroupResponse);
        this.buildForm();
      }
      this.isApiServiceCalled = false;
    }, () => {
      this.isApiServiceCalled = false;
    });
  }

  private callAPIs(): Observable<any[]> {
    const roles = this.rolesService.getActiveAndLimitedRoles(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredUserGroup = this.configuredUserGroupService.getUserGroupById(this.parentId, this.userGroupId).pipe(catchError(() => of({ 'result': [] })));
    const globalUserGroup = this.packagedUserGroupService.getUserGroupById(this.parentId, this.userGroupId).pipe(catchError(() => of({ 'result': [] })));
    const apisToCall: any[] = [
      roles
    ];
    if (this.operation === 'editUserGroup') {
      if (this.usergroupType === Type.CLIENT.toLowerCase()) {
        apisToCall.push(configuredUserGroup);
      } else if (this.usergroupType === Type.GLOBAL.toLowerCase()) {
        apisToCall.push(globalUserGroup);
      }
    }
    return forkJoin(apisToCall);
  }

}