import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ae-button',
  templateUrl: './ae-button.component.html'
})
export class AEButtonComponent {

  @Input() public buttonText: string;
  @Input() public buttonType: string;
  @Input() public routerLink: string;
  @Input() public disabled: boolean;
  @Input() public iconPosition: string;
  @Input() public icon: string;
  @Input() public type: string;

  @Output() buttonClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router
  ) { }

  public performClickAction(): void {
    if (this.routerLink) {
      void this.router.navigate([this.routerLink]);
    } else {
      this.buttonClick.emit({});
    }
  }

  public getButtonType(): string {
    return this.buttonType ? this.buttonType : this.identifyButtonType();
  }

  private identifyButtonType(): string {
    if (this.buttonText.includes('Create') || this.buttonText.includes('Save') || (this.buttonText.includes('Add'))) {
      return 'primary';
    } else if (this.buttonText.includes('Validate') || this.buttonText.includes('View Associations')
     || this.buttonText.includes('Edit') || this.buttonText.includes('Cancel')) {
      return 'secondary';
    } else if (this.buttonText.includes('Back to') || this.buttonText.includes('View Schema')) {
      return 'text-primary';
    } else if (this.buttonText.includes('Delete') || this.buttonText.includes('Remove')) {
      return 'feedback-error-secondary';
    } else {
      return 'primary';
    }
  }

}