import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ClientModule } from 'src/app/modules/client/client.module';
import { ProgramsModule } from 'src/app/modules/programs/programs.module';
import { IQDecisionsModule } from 'src/app/modules/iqdecisions/iqdecisions.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { EventsModule } from 'src/app/modules/events/events.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RulesModule } from 'src/app/modules/rules/rules.module';
import { MomentModule } from 'ngx-moment';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { EntityStatesModule } from './modules/entitystates/entity-states.module';
import { CoreUIModule, FileUploadComponent } from '@epsilon/core-ui';
import { AuthorizationModule } from './shared/authorization/authorization-module';
import { ExclusionsModule } from './modules/exclusions/exclusions.module';
import { OktaLoginComponent } from './modules/okta-login/okta-login.component';
import {OKTA_CONFIG, OktaAuthModule} from '@okta/okta-angular';
import sampleConfig from './app.config';
import { Router } from '@angular/router';
import { ExternalSystemLoginComponent } from './modules/external-system-login/external-system-login.component';
import { ActionsModule } from './modules/actions/actions.module';
import { AdminModule } from './modules/admin/admin.module';


const oktaConfig = Object.assign({
  onAuthRequired: (oktaAuth, injector) => {
    const router = injector.get(Router);
    // Redirect the user to your custom login page
    router.navigate(['/login/okta']);
  }
}, sampleConfig.oidc);

@NgModule({
  declarations: [AppComponent, LoginComponent, OktaLoginComponent, ExternalSystemLoginComponent],

  imports: [
    AppRoutingModule,
    ProgramsModule,
    ActionsModule,
    AdminModule,
    IQDecisionsModule,
    EntityStatesModule,
    ClientModule,
    EventsModule,
    RulesModule,
    ExclusionsModule,
    SharedModule,
    BaseTemplateModule,
    BrowserModule,
    ReactiveFormsModule,
    MomentModule,
    FormsModule,
    NgSelectModule,
    CoreUIModule,
    AuthorizationModule.forRoot(),
    OktaAuthModule,
  ],
  providers: [{
    provide: OKTA_CONFIG, useValue: oktaConfig
  }],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}