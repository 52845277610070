import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramsRoutingModule } from './programs-routing.module';
import { ProgramsConfigurationComponent } from './programs-configuration/programs-configuration.component';
import { ProgramsHomeComponent } from './programs-home/programs-home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BusinessUnit } from 'src/app/shared/models/business-unit';
import { RequestInterceptor } from 'src/app/shared/http/request-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WarningState } from 'src/app/shared/warning-state';
import { ProgramWarningState } from './program-warning-state';
import { DelayPipe } from 'src/app/shared/pipes/delay.pipe';
import { FilterPipe } from 'src/app/shared/pipes/filter.pipe';
import { ProgramScheduleComponent } from './program-schedule/program-schedule.component';
import { ProgramRuleComponent } from './program-rule/program-rule.component';
import { CustomProgramComponent } from './custom-program/custom-program.component';
import { ProgramsHeaderComponent } from './programs-header/programs-header.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProgramStateIndicatorComponent } from './program-state-indicator/program-state-indicator.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProgramSimulationComponent } from './program-simulation/program-simulation.component';
import { CustomProgramBuilder } from './custom-program-builder/custom-program-builder.component';
import { ModalSearchComponent } from 'src/app/shared/component/modal/modal-search/modal-search.component';
import { ProgramSelectorComponent } from './program-selector/program-selector.component';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import {
  TableModule, PaginationModule, ToggleModule, SearchModule, RibbonModule, MultiselectModule,
  ActionModule, LoadingIndicatorModule, StatusIndicatorModule, CollapsiblePanelModule, NavSecondaryModule
} from 'epsilon-blueprint';
import { AllocationComponent } from '../actions/allocations/allocation.component';
import { BaseComponent } from '../client/base/base.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { TreeModule } from '@circlon/angular-tree-component';
import { CoreUIModule } from '@epsilon/core-ui';
import { CustomProgramExclusionBuilderComponent } from
  './program-exclusion/custom-program-exclusion-builder/custom-program-exclusion-builder.component';
import { CustomProgramExclusionConfiguationComponent }
  from './program-exclusion/custom-program-exclusion-configuation/custom-program-exclusion-configuation.component';
import { ScheduleConfigurationComponent } from './schedule-configuration/schedule-configuration.component';
import { AuditHistoryComponent } from '../change-log/audit-history.component';

@NgModule({
  declarations: [
    ProgramsConfigurationComponent,
    ProgramsHomeComponent,
    DelayPipe,
    FilterPipe,
    ProgramScheduleComponent,
    ProgramRuleComponent,
    CustomProgramComponent,
    CustomProgramBuilder,
    ProgramsHeaderComponent,
    ProgramStateIndicatorComponent,
    ProgramSimulationComponent,
    ModalSearchComponent,
    ProgramSelectorComponent,
    AllocationComponent,
    CustomProgramExclusionBuilderComponent,
    CustomProgramExclusionConfiguationComponent,
    ScheduleConfigurationComponent,
    AuditHistoryComponent
  ],
  imports: [
    CommonModule,
    ProgramsRoutingModule,
    BaseTemplateModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    StorageServiceModule,
    DragDropModule,
    SharedModule,
    TreeModule,
    TableModule,
    PaginationModule,
    ToggleModule,
    SearchModule,
    RibbonModule,
    MultiselectModule,
    LoadingIndicatorModule,
    ActionModule,
    StatusIndicatorModule,
    CollapsiblePanelModule,
    NavSecondaryModule,
    CoreUIModule
  ],
  providers: [
    BusinessUnit,
    BaseComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    WarningState,
    ProgramWarningState
  ]
})
export class ProgramsModule { }
