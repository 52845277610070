import { Rule } from './rule';

export class Program {

  programName: string;
  eventSource: string;
  eventType: string;
  displayName: string;
  description: string;
  rules: Rule[];
  inputSettings: Map<string, ProgramInputSetting>;
  relatedProgramSettings: RelatedProgramSettings[];
  packagedRuleSettings: PackagedRuleSettings[];
  status: string;
  eventSourceName: string;
  exclusions: string;
  verticalType: string;
  parentId: string;
  bundles : string[];

  constructor(response?: Object) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response['result'])));
    }
  }

  public getPackagedRuleSettingsByRelationType(rulesRelationType: RulesRelationType): PackagedRuleSettings[] {
    const packagedRuleSettingsWithSchedules: PackagedRuleSettings[] = [];
    this.packagedRuleSettings.forEach(nextPackagedRuleSettings => {
      nextPackagedRuleSettings = Object.assign(new PackagedRuleSettings(), nextPackagedRuleSettings);
      const relatedRulesSettingsForSchedules: RelatedRulesSettings[] = nextPackagedRuleSettings.getRelatedRulesSettingsByRelationType(rulesRelationType);
      if (relatedRulesSettingsForSchedules.length > 0) {
        packagedRuleSettingsWithSchedules.push(nextPackagedRuleSettings);
      }
    });
    return packagedRuleSettingsWithSchedules;
  }

  public getRuleEventType(ruleId: string) {
    const rule = this.rules.find(nextRule => nextRule.ruleId == ruleId);
    return rule.eventType;
  }

  public isPackagedTemplateSupported(): boolean {
    const programEventRuleSettings: PackagedRuleSettings
      = this.rules.find(rule => rule.eventType === this.eventType) as unknown as PackagedRuleSettings;
    return programEventRuleSettings && programEventRuleSettings.useCustomTemplate;
  }

}

export class ProgramInputSetting {

  defaultValue: string;
  minValue: string;
  maxValue: string;
  spamThreshold: string;
  valueSubtype: string;
  valueType: string;

}

export class RelatedProgramSettings {

  relationType: string;
  name: string;

}

export class PackagedRuleSettings {

  ruleId: string;
  eventType: string;
  relatedRulesSettings: RelatedRulesSettings[];
  useCustomTemplate: boolean;

  public getRelatedRulesSettingsByRelationType(rulesRelationType: RulesRelationType) {
    let relatedRulesSettings: RelatedRulesSettings[] = [];
    if (this.relatedRulesSettings && this.relatedRulesSettings != null && this.relatedRulesSettings.length > 0) {
      relatedRulesSettings = this.relatedRulesSettings.filter(nextRelatedRulesSetting => nextRelatedRulesSetting.relationType === rulesRelationType);
    }
    return relatedRulesSettings;
  }

}

export class RelatedRulesSettings {

  relationType: RulesRelationType;
  packagedRuleIds: string[] = [];

}

export enum RulesRelationType {
  SCHEDULES = 'SCHEDULES'
}

export enum UIControlType {
  TEXT = 'TEXT',
  TEXTBOX = 'TEXTBOX',
  SELECTBOX = 'SELECTBOX'
}

export enum PackagedProgramStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}