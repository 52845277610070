import { ArrayOperators, IntegerOperators, BooleanOperators, StringOperators, DateOperators } from '../operators/rule-operators';
import { DataTypes } from './data-types';
import { ConditionType } from './condition-type';
import { Condition } from './condition';
import { ValueType } from './value-type';
import { SourceType } from './source-type';

export class AttributeCondition implements Condition {

  operator: string;
  name: string;
  path: string;
  type: ConditionType;
  sourceType: SourceType;
  entityStateName: string;
  entityStateId: string;
  dataType: string;
  values: any[];
  valueType: ValueType = ValueType.CONSTANT;

  constructor(name: string, path: string, dataType: string, sourceType: SourceType) {
    this.name = name;
    this.path = path;
    this.type = ConditionType.ATTRIBUTE;
    this.dataType = dataType.toUpperCase();
    this.sourceType = sourceType;
    switch (this.dataType) {
      case DataTypes.array: {
        this.operator = ArrayOperators.CONTAINS_IGNORECASE.value;
        break;
      }
      case DataTypes.boolean: {
        this.operator = BooleanOperators.EQ.value;
        this.values = [];
        this.values.push(true);
        break;
      }
      case DataTypes.string: {
        this.operator = StringOperators.EQ_IGNORECASE.value;
        break;
      }
      case DataTypes.date: {
        this.operator = DateOperators.EQ.value;
        break;
      }
      case DataTypes.number: {
        this.operator = IntegerOperators.EQ.value;
      }
    }
    return this;
  }

  public static getEmptyAttributeCondition(): AttributeCondition {
    const attributeCondition = new AttributeCondition(undefined, undefined, "", undefined);
    attributeCondition.dataType = undefined;
    attributeCondition.valueType = undefined;
    return attributeCondition;
  }

}