export enum SupportedJSONSchemaVer {
  DRAFT_V7= 'draft-v7'
}

export class ConfiguredEventId {

  eventSourceName: string;
  eventName: string;

}

export class ConfiguredEvent {

  id: ConfiguredEventId;
  name: string;
  eventType: string;
  description: string;
  eventSchema: string;
  schema: string;
  public eventSourceProvider: string;
  schemaVersion: SupportedJSONSchemaVer;
  modifiedBy: string;
  modifiedDate: string;

  constructor(configureEvent?: Object) {
    if (configureEvent) {
      Object.assign(this, JSON.parse(JSON.stringify(configureEvent)));
    }
  }

  public getSchema(){
    return JSON.parse(this.schema);
  }

  public getSchemaForDisplay(){
    return JSON.stringify(this.getSchema(), undefined, 4);
  }

  public getSampleEventSchema(){
    return JSON.parse(this.getSampleEvent());
  }

  public getEventSourceProvider(): string {
    return JSON.parse(this.eventSourceProvider);
  }

  public getSampleEvent(): string {
    const sampleEventRecord = {};
    let sampleEventStr: string;
    if (this.eventSchema) {
      this.schema = this.eventSchema;
    }
    const eventSchema = JSON.parse(this.schema);
    const sampleEvent = this.computeSampleEventRecord(eventSchema, sampleEventRecord);
    sampleEventStr = JSON.stringify(sampleEvent, null, '\t');
    return sampleEventStr;
  }

  public computeSampleEventRecord(schema, sampleEvent): any {
    if (schema.properties) {
      Object.keys(schema.properties).forEach(value => {
        if (schema.properties[value].type === 'object' && schema.properties[value].properties) {
          sampleEvent[value] = {};
          this.computeSampleEventRecord(schema.properties[value], sampleEvent[value]);
        } else if (schema.properties[value].type === 'array' && schema.properties[value].items) {
          sampleEvent[value] = [];
          if (schema.properties[value].items.type === 'object') {
            sampleEvent[value].push({});
          } else {
            sampleEvent[value].push('');
          }
          this.computeSampleEventRecord(schema.properties[value].items, sampleEvent[value][0]);
        } else {
          sampleEvent[value] = schema.properties[value].default;
        }
      });
    }
    return sampleEvent;
  }

}