import { Feature } from './permission/feature/role-permission-constants';

export class EntityCopy {

  public id: string;
  public feature: Feature;

  constructor(id: string, feature: Feature) {
    this.id = id;
    this.feature = feature;
  }

  public static build(id: string, feature: Feature): EntityCopy {
    return new EntityCopy(id, feature);
  }

}