import { CustomRule } from './custom-rule/custom-rule';
import { EventKey } from './eventKey.model';
import { ConfiguredProgramTestData } from './configured-program-test-data/configured-program-test-data';
import { Constants, EntityTypes, RuleTypes } from 'src/app/shared/constants';
import { ConfiguredProgramTestDataMap } from './configured-program-test-data/configured-program-test-data-map';
import { TestDataSet } from './configured-progra-test-data/test-data-set.model';
import { RuleThenClauseOperation } from './custom-rule/then-clause/operation/rule-then-clause-operation';

export class ConfiguredRule {

  parentId: string;
  name: string;
  description: string;
  id: string;
  eventKey: EventKey;
  companyId: string;
  rule: string;
  descriptionAutoGenerated: boolean;
  ruleObject: CustomRule;
  modifiedDate: string;
  modifiedBy: string;

  constructor(result?: Object) {
    if (result) {
      Object.assign(this, JSON.parse(JSON.stringify(result)));
    }
  }

  setRule(rule:string) {
    this.rule = rule;
    this.setRuleObject();
  }

  setRuleObject() {
    this.ruleObject = JSON.parse(this.rule);
  }

  setRuleString(ruleDefination) {
    this.rule = JSON.stringify(ruleDefination);
  }

  hasAction(): boolean {
    if (this.ruleObject) {
      if (this.ruleObject.actions !== undefined && this.ruleObject.actions[0].actionSettingsInput !== undefined
              && JSON.stringify(this.ruleObject.actions[0].actionSettingsInput) !== '{}') {
        return true;
      }
    }
    return false;
  }

  getTestData(configuredProgramTestDataMap: ConfiguredProgramTestDataMap): TestDataSet[] {
    const testDataSetListForRule: TestDataSet[] = [];
    const eventSourceEventTypekeyForRule : string = this.eventKey.eventSourceName + Constants.DB_KEY_SEPARATOR + this.eventKey.eventName;
    configuredProgramTestDataMap.testDataMap.forEach((configuredProgramTestData: ConfiguredProgramTestData, eventSourceEventTypekeyInDataMap: string) => {
      if (eventSourceEventTypekeyForRule === eventSourceEventTypekeyInDataMap) {
        configuredProgramTestData.eventAttributeTestDataMap.forEach((testData: string[], eventAttributePath: string) => {
          testDataSetListForRule.push(new TestDataSet(eventSourceEventTypekeyInDataMap.split(Constants.DB_KEY_SEPARATOR)[0],
            eventSourceEventTypekeyInDataMap.split(Constants.DB_KEY_SEPARATOR)[1], eventAttributePath, testData.toString(), true));
        });
      }
    });
    return testDataSetListForRule;
  }

  getActions() {
    if (!this.ruleObject) {
      return null;
    }
    if (this.isRuleTypePackaged()) {
      return this.ruleObject.thenClause.actions;
    }
    return this.ruleObject.actions;
  }

  public getRuleThenClauseOperations(): RuleThenClauseOperation[] {
    if (this.ruleObject && this.ruleObject.thenClause && this.ruleObject.thenClause) {
      return this.ruleObject.thenClause.operations;
    }
    return [] as RuleThenClauseOperation[];
  }

  public getRuleType(): RuleTypes {
    return this.ruleObject.type ? this.ruleObject.type : RuleTypes.CUSTOM;
  }

  public isRuleTypePackaged(): boolean {
    return this.getRuleType() === RuleTypes.PACKAGED;
  }

}