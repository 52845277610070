import { Component, OnInit, TemplateRef, ViewChild, NgModule, Output } from '@angular/core';
import { BaseComponent } from '../../client/base/base.component';
import { UsersComponent } from 'src/app/modules/client/user-roles/users/users.component';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { SystemUsersService } from 'src/app/shared/services/system-users.service';
import { SystemUser } from 'src/app/shared/models/user-roles/system-user';
import { USERS_SERVICE_TOKEN, USER_CLASS_TOKEN } from 'src/app/shared/tokens';

@Component({
  selector: 'app-system-users-home',
  templateUrl: './system-users-home.component.html',
  providers: [{provide: USERS_SERVICE_TOKEN, useClass: SystemUsersService}]
})

export class SystemUsersHomeComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

}