<section id="iq-decisions-model-modal">
    <coreui-modal modalWidth="60" #basicModal>
        <ng-template #titleTemplate>
            Select Model
        </ng-template>
        <ng-template #titleCloseButtonTemplate>
            <button type="button" (click)="closeBasicModal()" coreUIButton="icon-large"><i
                    coreUIIcon="close"></i></button>
        </ng-template>
        <ng-template #contentTemplate>

            <app-ae-table [isDataLoading]="isDataLoading" [properties]="properties" [configuredTableData]="models"
                (radioButtonSelected)="rowSelected($event)">
            </app-ae-table>

        </ng-template>
        <ng-template #buttonsTemplate>
            <button coreUIButton="secondary" (click)="closeBasicModal()">Cancel</button>
            <button coreUIButton (click)="closeBasicModal()">Save</button>
        </ng-template>
    </coreui-modal>
    <div (click)="launchBasicModal()">
        <ng-content #showButton></ng-content>
    </div>

</section>