import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Messages } from 'src/app/shared/message';
import { Category, Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';

@Component({
  selector: 'app-client-header',
  templateUrl: './client-header.component.html',
  styleUrls: ['./client-header.component.scss']
})
export class ClientHeaderComponent implements OnInit {

  public leftNavigations = [];
  private CLIENT_DETAILS = { text: 'Client Details', route: './details/edit', url: '/client/details/edit' };
  private EVENT_SOURCE_PROVIDERS = { text: 'Event Source Providers', route: './eventSourceProvider', url: '/client/eventSourceProvider' };
  private ACTION_DESTINATIONS = { text: 'Action Destinations', route: './ActionDestinations', url: '/client/ActionDestinations' };
  private BATCH_EXTRACTS = { text: 'Batch Extracts', route: './BatchExtractHome', url: '/client/BatchExtractHome' };
  private PACKAGED_SETTINGS = { text: 'Packaged Settings', route: './packagedsettings', url: '/client/packagedsettings' };
  private ROLES_PERMISSIONS = { text: 'Roles & Permissions', route: './admin', url: 'client/admin/usergroups',
    subNavs: [
      { text: 'User Groups', url: '/client/admin/usergroups' },
      { text: 'Users', url: '/client/admin/users' },
      { text: 'Roles', url: '/client/admin/roles' }
    ]
  };
  private SUPPORT = { text: 'Support', route: './support', url: '/client/support/imslookup',
    subNavs: [
      { text: 'IMS Lookup', url: '/client/support/imslookup' },
      { text: 'Entity State Lookup', url: '/client/support/entitystatelookup' },
      { text: 'Activity Lookup', url: '/client/support/activitylookup' }
    ]
  };

  constructor(private router: Router,
    public authorizationService: AuthorizationService) { }

  ngOnInit(): void {
    if (!this.authorizationService.isAuthorizationEnabled()) {
      if (this.router.url === '/client') {
        void this.router.navigate(['client/eventSourceProvider']);
      }
      this.initNavTabs();
    } else {
      this.getAuthorizedTabs();
    }
  }

  private initNavTabs(): void {
    this.leftNavigations = [
      this.EVENT_SOURCE_PROVIDERS,
      this.CLIENT_DETAILS,
      this.ACTION_DESTINATIONS,
      this.ROLES_PERMISSIONS,
      this.BATCH_EXTRACTS,
      this.PACKAGED_SETTINGS,
      this.SUPPORT
    ];
  }

  private getAuthorizedTabs(): void {
    this.leftNavigations = [];
    const routes = [];
    const features = this.authorizationService.getAllowedFeatures(Category.CLIENT);
    if (this.authorizationService.isCategoryAccessible('PARENT', 'AND')) {
      this.leftNavigations.push(this.CLIENT_DETAILS);
      routes.push('details/edit');
    }
    if (features.includes(Feature.EVENT_SRC) || features.includes(Feature.CFG_EVENT_SRC)) {
      this.leftNavigations.push(this.EVENT_SOURCE_PROVIDERS);
      routes.push('eventSourceProvider');
    }
    if (features.includes(Feature.ACTION_DEST) || features.includes(Feature.CFG_ACTION_DEST)) {
      this.leftNavigations.push(this.ACTION_DESTINATIONS);
      routes.push('ActionDestinations');
    }
    if (features.includes(Feature.USR) || features.includes(Feature.PKG_USR_GRP) || features.includes(Feature.CFG_USR_GRP)
      || features.includes(Feature.PKG_ROLE) || features.includes(Feature.CFG_ROLE)) {
      this.leftNavigations.push(this.ROLES_PERMISSIONS);
      routes.push('admin/usergroups');
    }
    if (features.includes(Feature.CFG_BATCH_EXTRACT)) {
      this.leftNavigations.push(this.BATCH_EXTRACTS);
      routes.push('BatchExtractHome');
    }
    if (features.includes(Feature.SYS_SETTING) || features.includes(Feature.CFG_SETTING)) {
      this.leftNavigations.push(this.PACKAGED_SETTINGS);
      routes.push('packagedsettings');
    }
    if (features.includes(Feature.SUPPORT)) {
      if (this.authorizationService.isAllowed('SUPPORT', ['CREATE'])) {
        this.SUPPORT.subNavs.push({ text: 'IMS Insert', url: '/client/support/imsinsert' });
      }
      if (this.authorizationService.isAllowed('SUPPORT', ['READ'])) {
        this.SUPPORT.subNavs.push({ text: Messages.supportPageNavEntityContentLabel, url: '/client/support/rmncontents' });
      }
      this.leftNavigations.push(this.SUPPORT);
      routes.push('support');
    }
    if (this.router.url === '/client') {
      void this.router.navigate(['client/' + routes[0]]);
    }
  }


}