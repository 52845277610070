import { Component, OnInit, OnDestroy, Injector, ViewChild, TemplateRef, ComponentFactoryResolver } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProgramHomeFilterItems } from 'src/app/shared/models/program-home-filter-items';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { BluePrintTableColumnType } from 'src/app/shared/blueprint-table-column-type';
import { BaseComponent } from '../../client/base/base.component';
import { IQDecisionsService } from 'src/app/shared/services/iqdecisions.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { IQDecisionSource } from 'src/app/shared/models/iqdecisions-source-enum';
import { environment } from 'src/environments/environment';
import { ConfiguredRecommendationStatus } from 'src/app/shared/models/recommendations/configured-recommendation-status-enum';
import { ModelPackageSource } from 'src/app/shared/models/recommendations/model-package-enum';
import { IQDecisionsEntity } from 'src/app/shared/models/iqdecisions/iqdecisions-entity';
import { ShowComponentInTableEntity } from 'src/app/shared/models/iqdecisions/showComponentInTableEntity';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Constants } from 'src/app/shared/constants';
import { WarningType } from 'src/app/shared/warning-options';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
@Component({
  selector: 'app-iqdecisions-home',
  templateUrl: './iqdecisions-home.component.html'
  })
export class IQDecisionsHomeComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  protected warningModal: CoreuiModalWarningComponent;

  @ViewChild('expandableRowsTemplate', { static: true }) expandableRowsTemplate: TemplateRef<any>;
  public parentId: string;
  public configuredIQDecisionsList: IQDecisionsEntity[];
  public navigationSubscription;
  public filterItems = ProgramHomeFilterItems.FILTER_ITEMS;
  public isPageLoading = false;
  public properties = {};
  public configuredPackageName: string;
  public isApiServiceCalled: boolean;
  public configuredIQDecisionsTableList = [];
  public configuredIQDecisionsTableFilteredData = [];
  public readConfiguredIQDecisionsTableFilteredData = [];

  private searchByName: string;
  private configuredRecommendationId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public iqDecisionsService: IQDecisionsService,
    public parentContextService: ParentContextService,
    public router: Router,
    public injector: Injector,
    public authorizationService: AuthorizationService,
    private popupService: PopupMessageService) {
    super();
    this.parentId = this.parentContextService.getParentContext();
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = true;
        if (this.parentId !== '') {
          this.getAllConfiguredIQDecisions();
        }
      }
    });
  }

  ngOnInit() : void {
    if (!environment.showIQDecisions) {
      void this.router.navigate(['programs']);
    } else {
      this.initDataTableConfig();
    }
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  newModel(route: string): void {
    void this.router.navigate(['iqdecisions/' + route]);
  }

  public performMenuButtonClickActions(configuredIQDecision: any): void {
    this.configuredPackageName = configuredIQDecision.packageName;
    this.configuredRecommendationId = configuredIQDecision.id;
    switch (configuredIQDecision.label) {
      case Messages.publishBtnText:
        this.publishIQDecision();
        break;
      case Messages.unpublishBtnText:
        this.showUnpublishIQDecisionAlert();
        break;
      case Messages.deleteBtnTxt:
        this.showDeleteIQDecisionAlert();
        break;
    }
  }

  public handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteIQDecisions();
    } else if (decision && this.warningModal.warningType === WarningType.CHANGE_PROGRAM_MODE_WARNING) {
      this.unpublishIQDecision();
    }
  }

  private showDeleteIQDecisionAlert(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteConfiguredRecommendation,
      msg: `This action will permanently delete this package '${this.configuredPackageName}' and all of its data.`,
      msg2: [Messages.deleteConfiguredRecommedationModalConfirmationMessage]
    });
  }

  private showUnpublishIQDecisionAlert(): void {
    this.warningModal.launchModal(WarningType.CHANGE_PROGRAM_MODE_WARNING, {
      title: Messages.unpublishPackage,
      msg: Messages.confirmUnpublishPackage
    });
  }

  private publishIQDecision(): void {
    this.isApiServiceCalled = true;
    this.iqDecisionsService.publishIQDecisions(this.configuredRecommendationId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isApiServiceCalled = false;
      this.popupService.showSuccessMessage(this.configuredPackageName + ' ' + Messages.successfullyPublishedMessage, false);
      location.reload();
    }, () => {
      this.isApiServiceCalled = false;
      this.popupService.showErrorMessage(Messages.errorPublishingRecommendation);
    });
  }

  private unpublishIQDecision(): void {
    this.isApiServiceCalled = true;
    this.iqDecisionsService.unpublishIQDecisions(this.configuredRecommendationId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isApiServiceCalled = false;
      this.popupService.showSuccessMessage(this.configuredPackageName + Messages.successfullyUnPublishedMessage, false);
      location.reload();
    }, () => {
      this.isApiServiceCalled = false;
      this.popupService.showErrorMessage(Messages.errorUnpublishingRecommendation);
    });
  }

  private deleteIQDecisions(): void {
    this.isApiServiceCalled = true;
    this.iqDecisionsService.deleteIQDecisions(this.configuredRecommendationId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isApiServiceCalled = false;
      this.popupService.showDeleteMessage(`Package '${this.configuredPackageName}' successfully deleted`, false);
      location.reload();
    }, error => {
      this.isApiServiceCalled = false;
      if (error.status === 403) {
        this.popupService.showErrorMessage(error.error.statusMessage);
      } else {
        this.popupService.showErrorMessage(Messages.errorDeletingPackage);
      }
    });
  }

  public handleSearch(searchInput: string) : void {
    if (searchInput === null || searchInput === undefined) {
      searchInput = '';
    }
    if (this.searchByName !== searchInput) {
      this.searchByName = searchInput.trim();
      this.filterConfiguredPrograms();
    }
  }

  private filterConfiguredPrograms(): void {
    if (this.searchByName && this.searchByName.trim()) {
      this.searchByName = this.searchByName.trim();
      this.configuredIQDecisionsTableFilteredData = this.readConfiguredIQDecisionsTableFilteredData
        .filter(recommendation => {
          return recommendation.packageName.toLowerCase().includes(this.searchByName.toLowerCase());
        });
    } else {
      this.configuredIQDecisionsTableFilteredData = this.readConfiguredIQDecisionsTableFilteredData;
    }
  }

  private getAllConfiguredIQDecisions() : void {
    this.iqDecisionsService.getAllConfiguredIQDecisionsRequest(this.parentId).subscribe(
      (res: any) => {
        this.configuredIQDecisionsList = res.result;
        this.setTableListData();
        this.isPageLoading = false;
      }, () => {
        this.isPageLoading = false;
      });
  }

  private initDataTableConfig(): void {
    this.isPageLoading = true;
    this.properties = {
      rowId: 'id',
      showBadgesComponent: true,
      columns: [
        {
          headerText: 'Package Name',
          key: 'packageName',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.LINK,
          link: {
            element: 'a',
            ariaLabel: '#{packageName}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Type',
          key: 'type',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Status',
          key: 'status',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Model Output',
          key: 'modelOutput',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Company',
          key: 'businessUnit',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Program Usage',
          key: 'programUsage',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: '',
          key: 'actions',
          isSortable: false,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'packageName',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true,
      expandableRowsTemplate: this.expandableRowsTemplate
    };
  }

  private getProgramUsageColumns = (programUsage): any => {
    const component = 'ae-badge';
    const colors = { none: 'Plum', inactive: 'Plum', active: 'Lime' };
    const noneArray = [{ component, type: '', msg: 'None', color: colors.none }];
    if (!programUsage) {
      return noneArray;
    }
    const programUsageObj = [];
    const activeFoundPrograms = programUsage.filter(item => item.targetStatus.toLowerCase() === 'active');
    const activeFoundProgramsLength:number = activeFoundPrograms.length;
    if (activeFoundProgramsLength) {
      const newDataActives = { component, type: '', msg: `${activeFoundProgramsLength} active`, color: colors.active };
      programUsageObj.push(newDataActives);
    }
    const inactiveFoundPrograms = programUsage.filter(item => item.targetStatus.toLowerCase() === 'inactive');
    const inactiveFoundProgramsLength:number = inactiveFoundPrograms.length;
    if (inactiveFoundProgramsLength) {
      const newDataInactives = { component, type: '', msg: `${inactiveFoundProgramsLength} inactive`, color: colors.inactive };
      programUsageObj.push(newDataInactives);
    }
    if (!programUsageObj.length) {
      return noneArray;
    }
    return programUsageObj;
  };

  private buildColumnBadgeComponentStatus(status, type): ShowComponentInTableEntity[] {
    const possibleCases = { 'incomplete': 'Pineapple', 'published': 'Lime', 'not published': 'Plum' };
    const findStatus = Object.keys(possibleCases).indexOf(status.toLowerCase());
    const result = [status, findStatus >= 0 ? possibleCases[status.toLowerCase()] : possibleCases.incomplete];
    const objectData = [{ component: 'ae-badge', type: type.toLowerCase(), msg: result[0], color: result[1] }];
    return objectData;
  }

  private buildActionsPerState(status, associations): ShowComponentInTableEntity[] {
    const actionStr = 'actions';
    const finalComponentDataComponents = [];
    const currentStatus = status.toLowerCase();
    if (currentStatus === ConfiguredRecommendationStatus.incomplete) {
      finalComponentDataComponents.push({ component: actionStr, label: 'Delete', disabled: false,
        isAuthorized: this.authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['DELETE']) });
    } else if (currentStatus === ConfiguredRecommendationStatus.published) {
      this.hasAssociation(finalComponentDataComponents, actionStr, associations, Messages.unpublishBtnText,
        this.authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['UPDATE']));
    } else {
      finalComponentDataComponents.push({ component: actionStr, label: 'Publish', disabled: false,
        isAuthorized: this.authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['UPDATE']) });
      this.hasAssociation(finalComponentDataComponents, actionStr, associations, Messages.deleteBtnTxt,
        this.authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['DELETE']));
    }
    return finalComponentDataComponents;
  }

  private hasAssociation(finalComponentDataComponents: any[], actionStr: string, associations: any, labelTxt: string,
    actionAuthorized: boolean): void {
    let activeProgramFound;
    if (associations !== null) {
      activeProgramFound = associations.filter((item: { targetStatus: string }) => item.targetStatus.toLowerCase() === 'active');
    }
    if (labelTxt === Messages.unpublishBtnText) {
      finalComponentDataComponents.push({ component: actionStr, label: labelTxt, disabled: (associations !== null && activeProgramFound.length > 0),
        isAuthorized: actionAuthorized });
    } else if (labelTxt === Messages.deleteBtnTxt) {
      finalComponentDataComponents.push({ component: actionStr, label: labelTxt, disabled: (associations !== null), isAuthorized: actionAuthorized });
    }
  }

  private setTableListData(): void {
    if (!this.configuredIQDecisionsList || this.configuredIQDecisionsList === undefined) {
      return;
    }
    this.configuredIQDecisionsTableFilteredData = [];
    this.configuredIQDecisionsList.forEach(iqDecisionItem => {
      const configuredEntityStateTableRow = {};
      const isSingleCase = iqDecisionItem.type.toLowerCase() === IQDecisionSource.single;
      configuredEntityStateTableRow['id'] = iqDecisionItem.id;
      configuredEntityStateTableRow['packageName'] = iqDecisionItem.name;
      configuredEntityStateTableRow['status'] = this.buildColumnBadgeComponentStatus(iqDecisionItem.status, 'spot');
      configuredEntityStateTableRow['modelOutput'] = iqDecisionItem?.metadataConfiguration?.packageInfo?.recommendationType?.name || '';
      configuredEntityStateTableRow['businessUnit'] = iqDecisionItem?.company?.name || '';
      configuredEntityStateTableRow['programUsage'] = this.getProgramUsageColumns(iqDecisionItem.associations);
      configuredEntityStateTableRow['routerLink'] = `/iqdecisions/${iqDecisionItem.id}`;
      configuredEntityStateTableRow['type'] = isSingleCase ? ModelPackageSource.singleModelPackage : ModelPackageSource.multiModelPakage;
      configuredEntityStateTableRow['actions'] = this.buildActionsPerState(iqDecisionItem.status, iqDecisionItem.associations);
      this.configuredIQDecisionsTableFilteredData.push(configuredEntityStateTableRow);
      this.readConfiguredIQDecisionsTableFilteredData.push(configuredEntityStateTableRow);
    });
    this.configuredIQDecisionsTableList = this.configuredIQDecisionsTableFilteredData.slice(0, 10);
  }

  private sortTableData(sort): void {
    this.configuredIQDecisionsTableList = this.handleSort(sort, this.configuredIQDecisionsTableFilteredData);
  }

  private onPageChange(pageData): void {
    this.configuredIQDecisionsTableList = this.handlePageChange(pageData, this.configuredIQDecisionsTableFilteredData);
  }

}