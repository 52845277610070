<div class="row mb-4">
  <div class="col-6">
    <app-ae-section-header [headerText]="messages.packageInfoSubHeader"></app-ae-section-header>
    <div class="row mt-3">
      <div class="col">
        <span class="text-style-muted-1">{{messages.company}}</span>
        <p class="indent-text">{{configuredRecommendation.company?.name}}</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <span class="text-style-muted-1">{{messages.packageType}}</span>
        <p class="indent-text">
          {{configuredRecommendation.metadataConfiguration?.packageInfo?.recommendationType?.name}}</p>
      </div>
    </div>
    <div *ngIf="!isEditMode; else editForm">
      <div *ngIf="this.configuredRecommendation.metadataConfiguration?.packageInfo?.choiceType?.name || choiceTypeName">
        <div class="row mt-3">
          <div class="col">
            <span class="text-style-muted-1">{{messages.categoryLevel}}</span>
            <p class="indent-text">
              {{packageInfoFormControl.controls.choiceTypeName.value}}</p>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <span class="text-style-muted-1">{{messages.MaxItemsReturn}}</span>
          <p class="indent-text">{{configuredRecommendation.metadataConfiguration?.packageInfo.itemsToReturn}}</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <span class="text-style-muted-1">{{messages.description}}</span>
          <p class="indent-text">{{configuredRecommendation.description}}</p>
        </div>
      </div>
    </div>


    <!-- EDIT RECCOMMENDATION -->
    <ng-template #editForm>
      <form [formGroup]="packageInfoFormControl">

        <div *ngIf="showCategoryLevel()">
          <div class="tooltip-container">
            <ng-template #packageLevelTooltip>
              {{tooltipMessages.packageLevelTooltip}}
            </ng-template>
            <div class="tooltip-container">
              <div class="row mt-3">
                <div class="col">
                  <coreui-form-field label="{{messages.categoryLevel}}">
                    <coreui-select id="choiceTypeName" placeholder="{{messages.selectCategoryLevelOption}}"
                      formControlName="choiceTypeName" [coreUITooltip]="packageLevelTooltip" position="right" (valueChange)="onItemChange($event)">
                      <coreui-option [value]="" [disabled]="true">{{messages.selectCategoryLevelOption}}</coreui-option>
                      <coreui-option *ngFor="let item of categoryLevelsList;"
                        [value]="item.name">
                        {{item.name}}
                      </coreui-option>
                    </coreui-select>
                    <coreui-form-error>
                      <div>{{messages.packageLevelError}}</div>
                    </coreui-form-error>
                  </coreui-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <coreui-form-field label="Max Items to Return">
              <input coreUIInput [coreUIIntegerMask]="{disallowNegative: true}"
                (keydown)="preventZeroStartingNumber($event)" type="text" name="maxItems"
                formControlName="maxItemReturned" />
              <coreui-form-error>
                <div>{{messages.maxItemError}}</div>
              </coreui-form-error>
            </coreui-form-field>
          </div>
        </div>
        <div>
          <div class="tooltip-container">
            <ng-template #descriptionTooltip>
              {{tooltipMessages.descriptionTooltip}}
            </ng-template>
            <div class="row mt-3">
              <div class="col">
                <div class="tooltip-container">
                  <coreui-form-field label="Description" for="description">
                    <textarea maxlength="500" coreUIInput id="description" placeholder="Add description"
                      formControlName="description" rows="6" [coreUITooltip]="descriptionTooltip" position="right">
                            </textarea>
                  </coreui-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
</div>
