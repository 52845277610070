import { Component, OnInit, OnDestroy, ViewChild, QueryList, ComponentRef, ViewContainerRef, ComponentFactory, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ProgramSharedDataService } from '../program-shared-data.service';
import { ProgramData } from 'src/app/shared/models/program-data';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ProgramsHeaderComponent } from '../programs-header/programs-header.component';
import { LoginServiceLocator } from '../../../shared/services/login.service-locator';

@Component({
  selector: 'app-custom-program',
  templateUrl: './custom-program.component.html'
})
export class CustomProgramComponent implements OnInit, OnDestroy {

  @ViewChild(ProgramsHeaderComponent, { static: true }) header: ProgramsHeaderComponent;
  tenantProgram = new ConfiguredProgram();
  parentId : string;
  operation : string;
  public programData : ProgramData;
  eventSource : string;
  configuredProgramId: string;
  public customProgramsNavTabs = [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private loginServiceLocator: LoginServiceLocator,
    private parentContextService: ParentContextService,
    private route: ActivatedRoute,
    private programDataService: ProgramSharedDataService) {
    this.parentId = this.parentContextService.getParentContext();
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.operation = route.snapshot.paramMap.get('action');
      this.eventSource = route.snapshot.paramMap.get('eventsource');
      this.configuredProgramId = route.snapshot.paramMap.get('id');
    });
    this.initProgramNavTabs();
    this.programData = this.programDataService.getProgramData();
  }


  public ngOnInit(): void {
    this.programData.parentId = this.parentId;
    this.programData.tenantProgram = this.tenantProgram;
    this.programData.eventSource = this.eventSource;
    this.programData.operation = this.operation;
    this.programData.programId = this.configuredProgramId;
    this.programDataService.setProgramData(this.programData);
    if (this.operation === 'add') {
      this.programData.tenantProgram.programType = 'SELF_SERVICE_EXTERNAL';
    }
    this.programDataService.isDataSaved = false;
    this.programDataService.isDeleteOperation = false;
    this.programDataService.header = this.header;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initProgramNavTabs(): void {
    this.customProgramsNavTabs = [
      {
        text: 'Define',
        route: this.eventSource !== 'eventSource' ? './rule' : './program-rule'
      },
      {
        text: 'Schedule',
        route: './schedule'
      },
      {
        text: 'Simulate',
        route: './simulate'
      }
    ];
  }

}