
export class EventKey {

  eventSourceName: string;
  eventName: string;

  constructor(eventSource?: string, eventName?: string) {
    this.eventSourceName = eventSource;
    this.eventName = eventName;
  }

  getCompositeKey() {
    return this.eventSourceName + '_' + this.eventName;
  }

}