import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ae-toggle',
  templateUrl: './ae-toggle.component.html',
  styleUrls: ['./ae-toggle.component.scss']
})
export class AeToggleComponent {

  @Input()
  enableHammerIcon: boolean;

  @Input()
  label: string;

  @Input()
  type: string;

  @Input()
  name: string;

  @Input()
  uncheckedLabel: string;

  @Input()
  checkedLabel: string;

  @Input()
  callback: any;

  @Input()
  formGroup: UntypedFormGroup;

}
