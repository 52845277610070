<div class="row">
  <div class="col-6 pb-4">
    <app-ae-section-header [headerText]="messages.modelsSubHeader"></app-ae-section-header>
    <div *ngIf="configuredRecommendation.type === packageType.SINGLE">
      <div *ngIf="!isEditMode; else editForm">
        <p class="text-style-muted-1">{{messages.primaryModel}}</p>
        <p class="indent-text-with-margin">{{configuredRecommendation.metadataConfiguration.primaryModel?.name}}</p>

        <p class="text-style-muted-1">{{messages.fallbackModel}}</p>
        <p class="indent-text-with-margin">{{configuredRecommendation.metadataConfiguration.groupInfos[0].fallbackModel
          ?
          getFallBackModelName() : '--'}}</p>
      </div>
      <ng-template #editForm>
        <form [formGroup]="modelFormGroup">
          <div class="row">
            <div class="col-4">
              <label class="text-style-muted-1" for="">{{messages.primaryModel}}</label>
              <app-model-selection-modal (isSelectedModel)="getSelectedModel($event, modelType.PRIMARY_MODEL)"
                [models]="models" [buttonLabel]="'{{messages.selectModel}}'">
                <app-ae-button type="button"
                  *ngIf="!modelFormGroup.get(modelType.PRIMARY_MODEL).value; else primaryModelName"
                  buttonText="{{messages.selectModel}}" buttonType="secondary"></app-ae-button>
                <ng-template #primaryModelName>
                  <div>
                    <span>{{modelFormGroup.get(modelType.PRIMARY_MODEL).value?.name}}</span>
                    <app-ae-button type="button" buttonType="icon-large" icon="edit"></app-ae-button>
                  </div>
                </ng-template>
              </app-model-selection-modal>
            </div>
          </div>
          <div class="row pt-4" [hidden]="!modelFormGroup.get(modelType.PRIMARY_MODEL).value">
            <div class="col-4">
              <label class="text-style-muted-1" for="">{{messages.fallbackModel}}</label>
              <app-model-selection-modal (isSelectedModel)="getSelectedModel($event, modelType.FALLBACK_MODEL)"
                [models]="fallbackModelList" [buttonLabel]="messages.selectModel">
                <app-ae-button type="button" *ngIf="!modelFormGroup.get(modelType.FALLBACK_MODEL).value; else modelName"
                  buttonText="Select Model" buttonType="secondary"></app-ae-button>
                <ng-template #modelName>
                  <div>
                    <span>{{modelFormGroup.get(modelType.FALLBACK_MODEL).value.name}}</span>
                    <app-ae-button type="button" buttonType="icon-large" icon="edit"></app-ae-button>
                  </div>
                </ng-template>
              </app-model-selection-modal>
            </div>
          </div>
        </form>
      </ng-template>
    </div>
    <div *ngIf="configuredRecommendation.type === packageType.MULTI">
      <div *ngIf="!isEditMode; else editFormMulti">
        <app-ae-table [properties]="modelsProperties" [configuredTableData]="modelsTableData">
        </app-ae-table>
      </div>
      <ng-template #editFormMulti>
        <app-iqdecisions-multi-model-group-info [groupInfo]="modelFormGroup" [isEditMode]="true"
          [originalModel]="originalModels"></app-iqdecisions-multi-model-group-info>
      </ng-template>
    </div>
  </div>
</div>