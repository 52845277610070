import { Injectable } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ConfiguredEntityState, EntityStateKey, EventAttribute } from 'src/app/shared/models/entity-states/configured-entity-state';
import { DataTypes } from '../../shared/models/custom-rule/conditions/data-types';
import { ValueType } from '../../shared/models/custom-rule/then-clause/operation/value-type';

@Injectable({ providedIn: 'root' })
export class EntityStatesSharedDataService {

  public configuredEntityState : ConfiguredEntityState;
  public configuredEntityStateList : ConfiguredEntityState[] = [];
  public entityStateFormGroup: UntypedFormGroup;
  private entityStateForm = new Subject<any>();
  private hasAssociation = new Subject<any>();

  constructor(private fb: UntypedFormBuilder) { }

  getEntityStateList() : ConfiguredEntityState[] {
    return this.configuredEntityStateList;
  }

  setEntityStateList(data:ConfiguredEntityState[]) : void {
    this.configuredEntityStateList = data;
  }

  getEntityStateForm() : UntypedFormGroup {
    return this.entityStateFormGroup;
  }

  public initializeConfiguredEntity(): void {
    this.configuredEntityState = new ConfiguredEntityState();
    this.configuredEntityState.name = '';
    this.configuredEntityState.description = '';
    this.configuredEntityState.timeToLive = 30;
    this.configuredEntityState.schema = '{}';
    const eventAttribute = new EventAttribute();
    eventAttribute.dataType = DataTypes.string;
    eventAttribute.type = ValueType.EVENT_ATTRIBUTE;
    eventAttribute.name = 'epsilonCustomerId';
    eventAttribute.path = '$.epsilonCustomerId';
    const entityStateKey = new EntityStateKey();
    entityStateKey.name = '';
    entityStateKey.attributes = Array.from([eventAttribute]);
    this.configuredEntityState.entityStateKey = entityStateKey;
  }

  initializeEntityStateForm(configuredEntityState: ConfiguredEntityState): UntypedFormGroup {
    if (configuredEntityState) {
      this.configuredEntityState = this.getConfiguredEntityState(configuredEntityState);
      this.configuredEntityState.description = this.configuredEntityState.description === null ?
          '' : this.configuredEntityState.description;
      this.entityStateFormGroup = this.fb.group({
        parentId: configuredEntityState.parentId,
        id: configuredEntityState.id,
        description: configuredEntityState.description,
        name: configuredEntityState.name,
        schema: configuredEntityState.schema,
        type: configuredEntityState.type,
        modifiedDate: configuredEntityState.modifiedDate,
        entityStateKeys : [this.getEntityStateKeysFromAttributes(configuredEntityState.entityStateKey.attributes),[Validators.required, this.maxEntityKeys]],
        timeToLive: [configuredEntityState.timeToLive === undefined ? 30 : configuredEntityState.timeToLive,
          [Validators.required, Validators.max(365), Validators.min(1)]],
        entityStateKey: this.buildEntityStateKey(configuredEntityState.entityStateKey)
      });
    }
    this.setFormSubscriptions(this.entityStateFormGroup);
    return this.entityStateFormGroup;
  }

  public buildEntityStateKey(entityStateKey: EntityStateKey): UntypedFormGroup {
    return this.fb.group({
      name: entityStateKey.name,
      attributes: this.fb.array(this.buildEventAttribute(entityStateKey.attributes))
    });
  }

  public maxEntityKeys(control: FormControl) : any {
    const numberOfCommas = control.value.split(',').length - 1;
    if (numberOfCommas && numberOfCommas > 4) {
      return { maxEntityKeys: true };
    }
    return null;
  }

  public getEntityStateKeysFromAttributes(attributes: EventAttribute[]): string {
    const existingPath : string[] = [];
    attributes.forEach(attribute => {
      if (attribute.type === 'EVENT_ATTRIBUTE') {
       existingPath.push(attribute.path);
      }
    })
    return existingPath.join(',');
  }
  
  public buildEventAttribute(attributes: EventAttribute[]): UntypedFormGroup[] {
    const formGroup: Array<UntypedFormGroup> = [];
    attributes.forEach(attribute => {
      if (attribute.type === 'EVENT_ATTRIBUTE') {
        formGroup.push(this.fb.group({
          type: attribute.type,
          name: attribute.name,
          path: [attribute.path, Validators.required],
          dataType: 'STRING'
        }));
      } else if (attribute.type === 'CONSTANT') {
        formGroup.push(this.fb.group({
          type: attribute.type,
          name: attribute.name,
          dataType: attribute.dataType,
          path: attribute.path
        }));
      }
    });
    return formGroup;
  }

  buildEmptyEntityStateForm(parentId: string) : UntypedFormGroup {
    this.entityStateFormGroup = this.getEntityStateForm();
    if (this.entityStateFormGroup === null || this.entityStateFormGroup === undefined) {
      this.entityStateFormGroup = this.getEntityStateFormGroup(parentId);
    }
    this.setFormSubscriptions(this.entityStateFormGroup);
    return this.entityStateFormGroup;
  }

  get entityStateKeyAttributes(): UntypedFormArray {
    return <UntypedFormArray>(<UntypedFormGroup> this.entityStateFormGroup.get('entityStateKey')).get('attributes');
  }

  setEntityStateForm(entityStateForm: UntypedFormGroup) : void {
    this.entityStateFormGroup.patchValue(entityStateForm);
    this.setFormSubscriptions(this.entityStateFormGroup);
  }

  getFormSubscriptions(): Observable<any> {
    return this.entityStateForm.asObservable();
  }

  setFormSubscriptions(message: any) {
    this.entityStateForm.next(message);
  }

  getAssociationSubscriptions(): Observable<any> {
    return this.hasAssociation.asObservable();
  }

  setAssociationSubscriptions(message: boolean): void {
    this.hasAssociation.next(message);
  }

  getEventAttribute(): EventAttribute {
    const eventAttribute = new EventAttribute();
    eventAttribute.name = 'epsilonCustomerId';
    eventAttribute.path = '$.epsilonCustomerId';
    eventAttribute.dataType = 'STRING';
    return eventAttribute;
  }

  getConfiguredEntityState(configuredEntityState: ConfiguredEntityState): ConfiguredEntityState {
    if (configuredEntityState.entityStateKey === null || configuredEntityState.entityStateKey === undefined) {
      configuredEntityState.entityStateKey = new EntityStateKey();
      configuredEntityState.entityStateKey.attributes.push(this.getEventAttribute());
    }
    return configuredEntityState;
  }

  getEntityStateFormGroup(parentId: string) : UntypedFormGroup {
    const entityStateKey = this.getConfiguredEntityState(new ConfiguredEntityState()).entityStateKey;
    this.entityStateFormGroup = this.fb.group({
      parentId: parentId,
      id: '',
      description: '',
      name: '',
      timeToLive: [30, [Validators.required, Validators.max(365), Validators.min(1)]],
      schema: '{}',
      type: '',
      modifiedDate: '',
      entityStateKeys: [this.getEntityStateKeysFromAttributes(entityStateKey.attributes),[Validators.required, this.maxEntityKeys]],
      entityStateKey: this.buildEntityStateKey(entityStateKey)
    });
    this.setFormSubscriptions(this.entityStateFormGroup);
    return this.entityStateFormGroup;
  }

}