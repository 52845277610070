<app-ae-loading-indicator [isLoading]="programDataService.isApiServiceCalled"  [type]="'loading bar'"></app-ae-loading-indicator>
<app-ae-loading-indicator [isLoading]="isApiCallForNameUpdate"  [type]="'loading bar'"></app-ae-loading-indicator>
<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
<ng-container *ngIf="!programDataService.isApiServiceCalled">
  <div class="container-fluid">
    <div class="row mb-1">
      <ng-container *ngIf="isButtonEnabled()">
        <div class="col text-right">
          <div ngbTooltip="{{displayTexts.DISABLED_SAVE_BTN_TOOLTIP}}" [disableTooltip]="!programData.tenantProgram.active" placement="bottom-right auto">
            <app-ae-button 
              [buttonText]="'Save'"
              buttonType="text-primary"
              [disabled]="(programData.tenantProgram.id == undefined || programData.tenantProgram.active ||
              (programData.tenantProgram.name != undefined &&  programData.tenantProgram.name.trim() == '')
              || programData.tenantProgram.isCustomProgram()) || programData.isUnsupportedProgram"
              (click)="saveProgram()"
            ></app-ae-button>
          </div>
        </div>
        <div class="col-auto pl-0">
          <div ngbTooltip="{{displayTexts.DISABLED_ACTIVATE_BTN_TOOLTIP}}" [disableTooltip]="isActivateEnabled || programData.tenantProgram.active" placement="bottom-right auto">
            <app-ae-button 
              buttonText="{{buttonDisplayText}}"
              classes="btn btn-primary"
              [disabled]="!isActivateEnabled && !programData.tenantProgram.active || programDataService.isApiCallForNameUpdate.value"
              (click)="activate(programData.tenantProgram.name, programData.tenantProgram.id, programData.tenantProgram.active, programData.tenantProgram.programType)"
            ></app-ae-button>
          </div>
        </div>
      </ng-container>
    </div>
    <fieldset>
    <div class="row">
      <div class="col">
        <h2>Mode</h2>
        <div class="section-line"></div>
      </div>
    </div>
    <div class="row u-mt-20 u-pb-20">
      <div class="col-md-2">
        <div class="form-group">
          <label for="mode"></label>
          <coreui-select id="mode" (valueChange)="this.changeMode($event)" [(ngModel)]="selectedMode" [disabled]="isReadOnly">
            <coreui-option *ngFor="let item of this.modeList|keyvalue;" [value]="item.key">{{item.value}}</coreui-option>
          </coreui-select>
        </div>
      </div>
    </div>
    <div class="row u-pb-20">
      <div class="col">
        <h2>Schedule</h2>
        <div class="section-line"></div>
      </div>
    </div>
    <div *ngIf="!isProgramInLiveMode()">
      <coreui-notification notificationStyle="information" [isPersistent]="true" [autoShow]="true">
        You can't create a schedule while you're in test mode.
      </coreui-notification>
    </div>
    <div *ngIf="isProgramInLiveMode()">
      <div class="row u-pb-10" *ngIf="!isSchedulesAvailable()">
        <div class="col">
          <p class="text-style-muted-1">Schedule a status change for the program.</p>
        </div>  
      </div>
      <ng-container *ngIf="isSchedulesAvailable()">
        <ng-container *ngFor="let schedule of this.configuredSchedules">
          <div class="row">
            <div class="col-auto pr-1">
              <p>The program is scheduled to go <b>{{ getScheduleActionForDisplay(schedule.scheduleAction) }}</b> on
                <b>{{ getDisplayDate(schedule.scheduleTime)}}</b> at <b>{{ getDisplayTime(schedule.scheduleTime) }}
                  {{getScheduledZone()}}.</b>
              </p>
            </div>
            <div *ngIf="authorizationService.isAllowed('PKG_PRG', ['UPDATE']) || authorizationService.isAllowed('CUST_PRG', ['UPDATE'])">
              <button class="col-auto" coreUIButton="icon" (click)="onCancelSchedule(schedule)"><i
                coreUIIcon="trash"></i></button>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="row u-pb-20" *ngIf="authorizationService.isAllowed('PKG_PRG', ['UPDATE']) || authorizationService.isAllowed('CUST_PRG', ['UPDATE'])">
        <div class="col">
          <app-ae-button buttonText="New Schedule" buttonType="secondary" iconPosition="left" icon="add"
            (click)="onScheduleAction('create')" [disabled]="this.configuredSchedules == undefined || this.configuredSchedules.length > 0"></app-ae-button>
        </div>
      </div>
      <ng-container *ngIf="isConfiguredScheduleModalDisplayed()">
        <app-schedule-configuration [action]="getActionType()" [programName]="getConfiguredProgramName()"
          [programStatus]="getConfiguredProgramStatus()" [configuredSchedule]="getConfiguredSchedule()"
          [zone]="getScheduledZone()" (scheduledInfo)="captureConfiguredScheduleAction($event)"
          (isModalDisplayInfo)="setScheduleConfigurationModalStatus($event)"></app-schedule-configuration>
      </ng-container>
    </div>
    <div class="row u-pb-20">
      <div class="col">
        <h2>Test Recipient Data</h2>
        <div class="section-line"></div>
      </div>
    </div>
    <div class="row u-pb-20">
      <div class="col">
        <p class="text-style-muted-1">Your recipients will receive all actions in this program if their information entered below is present in
          incoming events.</p>
      </div>
    </div>
    <ng-container *ngFor="let dataSet of testDatasetList; let dataSetIndex = index">
      <div class="row u-pb-10">
        <div class="col">
          <h3>Data set</h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <ng-container *ngIf="testDatasetList[dataSetIndex].isPersisted" >
              <dl>
                <dt>Event Source Provider</dt>
                <dd>{{dataSet.eventSource === 'Conversant' ? 'Epsilon Tag' : dataSet.eventSource}}</dd>
              </dl>
            </ng-container>
            <ng-container *ngIf="!testDatasetList[dataSetIndex].isPersisted">
              <label for="eventSource-{{dataSetIndex}}">Event Source Provider</label>
              <coreui-select id="eventSource-{{dataSetIndex}}" #EventSourceProvider
                [(ngModel)]="testDatasetList[dataSetIndex].eventSource" placeholder="Select an event source provider"
                (valueChange)="onChangeEventSource($event, dataSetIndex)" [disabled]="testDatasetList[dataSetIndex].isPersisted">
                <coreui-option value="" [disabled]="true">Select an event source provider</coreui-option>
                <coreui-option *ngFor="let eventSource of this.eventSourceProviders| keyvalue;" [value]="eventSource.key">
                  {{eventSource.value}}
                </coreui-option>
              </coreui-select>
            </ng-container>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <ng-container *ngIf="testDatasetList[dataSetIndex].isPersisted">
              <dl>
                <dt>Event</dt>
                <dd>{{dataSet.event}}</dd>
              </dl>
            </ng-container>
            <ng-container *ngIf="!testDatasetList[dataSetIndex].isPersisted">
              <label for="event-{{dataSetIndex}}">Event</label>
              <coreui-select id="event-{{dataSetIndex}}" #Event [(ngModel)]="testDatasetList[dataSetIndex].event"
                placeholder="Event name" (valueChange)="onChangeEvent(Event, dataSetIndex)"
                [disabled]="testDatasetList[dataSetIndex].isPersisted || testDatasetList[dataSetIndex].eventSource === ''">
                <coreui-option value="" [disabled]="true">Select an event name</coreui-option>
                <coreui-option *ngFor="let eventOption of this.testDatasetList[dataSetIndex].eventOptions;" [value]="eventOption">
                  {{eventOption}}</coreui-option>
              </coreui-select>
            </ng-container>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <ng-container *ngIf="testDatasetList[dataSetIndex].isPersisted">
              <dl>
                <dt>Event Attribute</dt>
                <dd>{{dataSet.eventAttribute}}</dd>
              </dl>
            </ng-container>
            <ng-container *ngIf="!testDatasetList[dataSetIndex].isPersisted">
              <label for="eventAttribute-{{dataSetIndex}}">Event Attribute</label>
              <input id="eventAttribute-{{dataSetIndex}}" type="text" class="form-control"
                [(ngModel)]="testDatasetList[dataSetIndex].eventAttribute" placeholder="Enter an event attribute"
                [disabled]="testDatasetList[dataSetIndex].isPersisted || testDatasetList[dataSetIndex].eventSource ===''"
                (focusout)="validateEventAttribute(dataSetIndex)"/>
              <ng-container *ngIf="testDatasetList[dataSetIndex].isInvalidEventAttribute">
                <p class="text-error">Please select another attribute</p>
              </ng-container>
              <ng-container *ngIf="!isEventAttrPresent">
                <p class="text-error">This attribute does not exist in the event schema. Please check the schema and select an existing attribute.</p>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!testDatasetList[dataSetIndex].isPersisted" class="col-auto align-self-center">
          <app-ae-button
            [buttonText]="'View Event Schema'"
            buttonType="text-primary"
            [disabled]="testDatasetList[dataSetIndex].eventSource ==='' || testDatasetList[dataSetIndex].event === ''"
            (click)="openEventSchemaPopup(dataSetIndex)"
          ></app-ae-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="testRecipientData">Test Recipient Data (comma separate data)</label>
            <textarea id="testRecipientData" class="form-control" placeholder="Enter up to 40 data points" rows="5"
              [(ngModel)]="testDatasetList[dataSetIndex].testData" (focusout)="dataSet.validateTestDataCount(dataSetIndex); onChangeTestRecipientData();"></textarea>
            <ng-container *ngIf="testDatasetList[dataSetIndex].isTestDataCountExceed">
              <p class="text-error">Enter up to 40 data points</p>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row u-pb-25" *ngIf="authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE']) || authorizationService.isAllowed('CUST_PRG', ['CREATE','UPDATE'])">
        <div class="col-auto">
          <app-ae-button [buttonText]="'Delete Data Set'" (click)="this.deleteDataSet(dataSetIndex)"></app-ae-button>
        </div>
      </div>
    </ng-container>
    <div class="row u-pb-25" *ngIf="authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE']) || authorizationService.isAllowed('CUST_PRG', ['CREATE','UPDATE'])">
      <div class="col-auto">
        <app-ae-button
          buttonText="{{testDatasetList.length == 0 ? 'Add a data set' : 'Add another data set'}}"
          buttonType="text-primary"
          icon="add"
          iconPosition="left"
          [disabled]="testDatasetList.length >= maxDataSetAllowed"
          (click)="this.addNewDataSet()"
          [disabled]="isReadOnly"
        ></app-ae-button>
        <ng-container *ngIf="testDatasetList.length >= maxDataSetAllowed">
          <p>The maximum number of 10 data sets has been added</p>
        </ng-container>
      </div>
    </div>
    </fieldset>
  </div>
</ng-container>
<app-ae-modal-info></app-ae-modal-info>