import { Argument } from './argument';
import { OperandType } from './operand-type';
import { OperationName } from './operation-name';

export class RuleThenClauseOperation {

  public operandType: OperandType;
  public name: OperationName;
  public arguments: Argument[];

}