import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-states-connections',
  template: ``
})
export class EntityStatesConnectionsComponent implements OnInit, OnDestroy {

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

}