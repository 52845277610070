<coreui-notification notificationStyle="error" messageDuration="5000" [isPersistent]="false" #formError>{{errorMessage}}</coreui-notification>
<div class="container-fluid object-page-header">
	<div class="row align-items-center u-py-25 h-10">
		<div class="col">
			<h1 class="pr-4">Action Destinations</h1>
    </div>
	</div>
</div>
<form class="my-3" [formGroup]="actionDestinationsForm">
	<div class="container-fluid">
		<app-ae-loading-indicator [isLoading]="operationInProgress" [type]="'loading bar'"></app-ae-loading-indicator>
		<app-coreui-modal-warning (decision)="handleDeleteDecision($event)"></app-coreui-modal-warning>
		<ng-container formArrayName="actionDestination" *ngIf="!operationInProgress">
			<coreui-accordion>
				<ng-container *ngFor="let actionDestination of actionDestinationsForm.get('actionDestination')['controls']; let i=index">
					<coreui-accordion-pane label="{{actionDestination.value.displayName}}" [active]="actionDestinationResult">
						<div class="row my-3">
							<div class="col">
								<div [formGroupName]="i" class="card border-color-secondary rounded-0">
									<div id="card-header-{{i}}" class="card-header bg-transparent">
										<div class="row">
											<div class="col">
												<input formControlName="id" hidden="true" />
                      </div>
											<div class="col-sm-auto">
												<app-ae-button class="mx-3" *ngIf="isCustomActionDestinationFormEntry(actionDestination) && authorizationService.isAllowed('CFG_ACTION_DEST', ['UPDATE'])"
                          [buttonText]="'Edit'" [disabled]="isEditDisabled(actionDestination.value.id)" (click)="editActionDestination(actionDestination,i)"></app-ae-button>
												<app-ae-button *ngIf="authorizationService.isAllowed('ACTION_DEST', ['DELETE']) || authorizationService.isAllowed('CFG_ACTION_DEST', ['DELETE'])"
                          [buttonText]="'Remove'" [disabled]="isRemoveDisabled(actionDestination)" (click)="deleteActionDestinationFromADAlertModal(actionDestination.value.id, i, isCustomActionDestinationFormEntry(actionDestination))"></app-ae-button>
											</div>
										</div>
									</div>
									<div id="card-body-{{i}}" class="card-body collapse show p-3" [attr.aria-labelledby]="'#card-header-' + i">
										<div class="row">
											<div class="col">
												<app-ribbon *ngIf="hasOperationFailed(i, actionDestination.value.id)"
                          ribbonStyle="ribbon-alert"
                          [ribbonMessage]="getOperationFailureMessage()"
                          ribbonHasButton="true"
                          [buttonText]="getAssociationsBtnTxt()"
                          (buttonClick)="openAssociationsModal(actionDestination.value.id)">
                        </app-ribbon>
											</div>
										</div>
										<ng-container formArrayName="actionSettings" *ngIf="!isCustomActionDestinationFormEntry(actionDestination)">
											<ng-container *ngFor="let businessUnitDetails of actionDestination.get('actionSettings').controls; let j=index">
												<div [formGroupName]="j">
													<div class="row my-3">
														<div class="col-md-3">
															<label for="businessUnitName-{{i}}-{{j}}">Business Unit Name</label>
															<input id="businessUnitName-{{i}}-{{j}}" type="text" formControlName="businessUnitName" class="form-control" />
                            							</div>
														<div class="col-md-3">
															<label for="businessUnitId-{{i}}-{{j}}">Business Unit Id</label>
															<input id="businessUnitId-{{i}}-{{j}}" type="text" formControlName="businessUnitId" is-invalid="true" class="form-control" />
                            							</div>
														<div class="col-md-3">
															<label for="company">Company</label>
															<div class="mb-1"></div>
															<span class="text-style-muted-1">{{businessUnitDetails.controls.company.value}}</span>
                            							</div>
														<div class="col-sm-auto align-self-end" *ngIf="authorizationService.isAllowed('ACTION_DEST', ['DELETE']) || authorizationService.isAllowed('CFG_ACTION_DEST', ['DELETE'])">
															<app-ae-button buttonText="Delete" (click)="handleActionsFromDeleteBUAlertModal(actionDestination.value.id, actionDestination.controls.actionSettings, j)"></app-ae-button>
														</div>
													</div>
												</div>
											</ng-container>
											<div class="row" *ngIf="isBusinessUnitPresentForActionDestination(actionDestination.get('id').value)
                                    && (authorizationService.isAllowed('ACTION_DEST', ['CREATE']) || authorizationService.isAllowed('CFG_ACTION_DEST', ['CREATE']))">
												<div class="col">
													<app-ae-button *ngIf="actionDestination.get('actionSettings').length == 0" buttonType="text-primary" [buttonText]="'Add a Business Unit'" iconPosition="left" icon="add" (click)="addBusinessUnit(actionDestination.controls.actionSettings,actionDestination)"></app-ae-button>
													<app-ae-button *ngIf="actionDestination.get('actionSettings').length > 0" buttonType="text-primary" [buttonText]="'Add Another Business Unit'" iconPosition="left" icon="add" (click)="addBusinessUnit(actionDestination.controls.actionSettings,actionDestination)"></app-ae-button>
												</div>
											</div>
										</ng-container>
										<div class="row" *ngIf="isCustomActionDestinationFormEntry(actionDestination)">
											<div class="col">
                        {{ actionDestination.get('description').value }}
                      </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</coreui-accordion-pane>
				</ng-container>
			</coreui-accordion>
			<div class="row mt-3 mb-4" *ngIf="authorizationService.isAllowed('CFG_ACTION_DEST', ['CREATE'])">
				<div class="col">
					<app-ae-button *ngIf="actionDestinationsForm.get('actionDestination')['length'] == 0" [buttonText]="'Add an Action Destination'" buttonType="text-primary" iconPosition="left" icon="add" (click)="open()"></app-ae-button>
					<app-ae-button *ngIf="actionDestinationsForm.get('actionDestination')['length'] > 0" [buttonText]="'Add Another Action Destination'" buttonType="text-primary" iconPosition="left" icon="add" (click)="open()"></app-ae-button>
					<ng-container *ngIf="isCustomActionDestinationModalDisplayed()">
						<app-add-custom-action-destination-modal
              [customActionDestination]="getCustomActionDestination()"
              [oauthCredential]="getOAuthCredential()"
              [isInitializeEditMode]="getEditMode()"
              [existingCustomActionDestinationId]="getExistingCustomActionDestinationId()"
              (isModalDisplayed)="setCustomActionDestinationModalDisplayStatus($event)"
              (configuredActionDestination)="captureConfiguredActionDestination($event)"
            ></app-add-custom-action-destination-modal>
					</ng-container>
					<ng-container *ngIf="isAWSActionDestinationModalDisplayed()">
						<app-add-aws-action-destination-modal
              [awsActionDestination]="getAWSActionDestination()"
              [awsUserCredential]="getAWSUserCredential()"
              [existingCustomActionDestinationId]="getExistingCustomActionDestinationId()"
              [isInitializeEditMode]="getEditMode()"
              (isModalDisplayed)="setAWSActionDestinationModalDisplayStatus($event)"
              (configuredActionDestination)="captureConfiguredActionDestination($event)"
            ></app-add-aws-action-destination-modal>
					</ng-container>
					<ng-container *ngIf="isAddBUModalDisplayed()">
						<app-add-business-unit-modal
              [actionDestination]="getPopupActionDestination()"
              (isModalDisplayed)="setIsBUModalDisplayStatus($event)"
              (configuredBusinessUnit)="captureConfiguredBusinessUnit($event)"
            ></app-add-business-unit-modal>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</form>
<app-ae-modal-info (selectedItem)="captureSelectedItem($event)"></app-ae-modal-info>
