import { Parent } from '../../parent';
import { ParentPermission } from './parent-permission';

export class LoginResponse {

  public parents: Parent[];
  public parentPermission: ParentPermission;

  constructor(response?: string) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response)));
    }
  }

}