import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { AttributeMetadata } from 'src/app/shared/models/custom-rule/conditions/attribute-metadata';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TreeNode } from 'src/app/shared/models/custom-rule/tree-node';
import { EntityStateMetadata } from 'src/app/shared/models/custom-rule/conditions/entity-state-metadata';

@Component({
  selector: 'app-entity-state-attribute',
  templateUrl: './entity-state-attribute.component.html',
  styleUrls: ['./entity-state-attribute.component.scss']
})
export class EntityStateAttributeComponent implements OnInit {

  @Input()
  public systemEntityStateMetadatList: EntityStateMetadata[];
  @Input()
  public customEntityStateMetadataList: EntityStateMetadata[];
  @Input()
  public customEventMetadataList: TreeNode[];
  @Input()
  public attributeMetadataList: AttributeMetadata[];
  public filteredSystemEntityStateMetadataList: EntityStateMetadata[] = [];
  public filteredCustomEntityStateMetadataList: EntityStateMetadata[] = [];
  public filteredCustomEventMetadataList: TreeNode[] = [];
  public filteredAttributeDisplayNames: string[] = [];
  public allAttributeDisplayNames: string[] = [];
  public filteredResult = false;
  public searchFormGroup: UntypedFormGroup;
  public parentId: string;
  public attributeDragConnectedTo = ['entityStateAttributeDropArea'];
  public eventAttributeDragConnectedTo = ['latestNOccurrenceOccurenceDropArea', 'latestNOccurrenceDurationDropArea'];

  public constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private parentContextService: ParentContextService) {
    this.initInputParams();
  }

  public ngOnInit(): void {
    this.initFormGroup();
    this.initFilteredAttributes();
    this.clearFilter();
  }

  public getAttributeDisplayName(entityStateAttribute: TreeNode): string {
    return entityStateAttribute.title ? entityStateAttribute.title : entityStateAttribute.name;
  }

  public onSearchTermInput(searchTerm: string): void {
    this.filteredAttributeDisplayNames = searchTerm
      ? this.allAttributeDisplayNames.filter(displanName => displanName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
      : this.filteredAttributeDisplayNames;
    this.changeDetectorRef.detectChanges();
  }

  public onEntityStateAttributeSelected(): void {
    const searchTerm: string = this.searchFormGroup.get('searchTerm').value;
    if (searchTerm) {
      this.filterAttributes(searchTerm);
    } else {
      this.clearFilter();
    }
  }

  public isEntityStateAccordionActive(entityStateMetadatList: EntityStateMetadata[]): boolean {
    return this.filteredResult && entityStateMetadatList.length > 0;
  }

  private initInputParams(): void {
    this.parentId = this.parentContextService.getParentContext();
  }

  private initFilteredAttributes(): void {
    const entityStateAttribute: TreeNode[] = [];
    this.systemEntityStateMetadatList.forEach(systemEntityStateMetadat => entityStateAttribute.push(...systemEntityStateMetadat.attributes));
    this.customEntityStateMetadataList.forEach(systemEntityStateMetadat => entityStateAttribute.push(...systemEntityStateMetadat.attributes));
    entityStateAttribute.forEach((entityStateAttribute: TreeNode) => {
      this.allAttributeDisplayNames.push(this.getAttributeDisplayName(entityStateAttribute));
    });
    this.customEventMetadataList.map(eventMetadata => this.getLeafNodesOfDataAttributeFromEvent(eventMetadata))
      .forEach(dataAttributes => { dataAttributes.forEach(attribute => this.allAttributeDisplayNames.push(attribute.name)) });
    this.allAttributeDisplayNames = [...new Set(this.allAttributeDisplayNames)];
  }

  private clearFilter(): void {
    this.filteredResult = false;
    this.initFilteredEntityStateMetadatList(this.systemEntityStateMetadatList, this.customEntityStateMetadataList);
    this.initFilteredEventMetadataList(this.customEventMetadataList);
  }

  private initFilteredEntityStateMetadatList(systemEntityStateMetadatList: EntityStateMetadata[],
    customEntityStateMetadatList: EntityStateMetadata[]): void {
    this.filteredSystemEntityStateMetadataList = systemEntityStateMetadatList;
    this.filteredCustomEntityStateMetadataList = customEntityStateMetadatList;
  }

  private initFilteredEventMetadataList(customEventMetadataList: TreeNode[]): void {
    this.filteredCustomEventMetadataList = customEventMetadataList.filter(eventMetadata => this.getLeafNodesOfDataAttributeFromEvent(eventMetadata).length > 0);
  }

  private filterAttributes(searchTerm: string): void {
    this.filteredSystemEntityStateMetadataList = this.getFilteredEntityStateAttributes(searchTerm, this.systemEntityStateMetadatList);
    this.filteredCustomEntityStateMetadataList = this.getFilteredEntityStateAttributes(searchTerm, this.customEntityStateMetadataList);
    this.filteredCustomEventMetadataList = this.getFilteredEventAttributes(searchTerm, this.customEventMetadataList);
    this.filteredResult = true;
  }

  private getFilteredEntityStateAttributes(searchTerm: string,
    entityStateMetadatList: EntityStateMetadata[]): EntityStateMetadata[] {
    const filteredEntityStateMetadatList: EntityStateMetadata[] = [];
    entityStateMetadatList.forEach(entityState => {
      const entityStateAttributes: TreeNode[] = entityState.attributes;
      const filteredEntityStateAttributes: TreeNode[] = entityStateAttributes.filter(entityStateAttribute =>
        this.getAttributeDisplayName(entityStateAttribute) === searchTerm);
      if (filteredEntityStateAttributes.length > 0) {
        filteredEntityStateMetadatList.push(new EntityStateMetadata(entityState.name, filteredEntityStateAttributes));
      }
    });
    return filteredEntityStateMetadatList;
  }

  private getFilteredEventAttributes(searchTerm: string, eventMetadataList: TreeNode[]): TreeNode[] {
    const filteredEvents: TreeNode[] = [];
    eventMetadataList.forEach(event => {
      const filteredDataAttributes: TreeNode[] = this.getLeafNodesOfDataAttributeFromEvent(event).filter(attribute => attribute.name === searchTerm);
      if (filteredDataAttributes.length > 0) {
        const filteredAttributeEvent: TreeNode = new TreeNode();
        const filteredDataAttribute: TreeNode = new TreeNode();
        filteredDataAttribute.name = 'data';
        filteredDataAttribute.children = filteredDataAttributes;
        filteredAttributeEvent.name = event.name;
        filteredAttributeEvent.children = [filteredDataAttribute];
        filteredEvents.push(filteredAttributeEvent);
      }
    });
    return filteredEvents;
  }

  private initFormGroup(): void {
    this.searchFormGroup = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
  }

  protected getLeafNodesOfDataAttributeFromEvent(eventMetadata: TreeNode): TreeNode[] {
    const dataAttributeNode: TreeNode = eventMetadata.children.find(attribute => attribute.name === 'data');
    if (dataAttributeNode === undefined || dataAttributeNode.children === null || dataAttributeNode.children === undefined) {
      return [];
    }
    return dataAttributeNode.children.filter(node => node.children === undefined);
  }

}