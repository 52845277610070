import { AuthenticationCredential } from './AuthenticationCredential';

export class AwsUserCredential implements AuthenticationCredential {

  userName: string;
  accessKey: string;
  secretAccessKey: string;
  encoded = false;

  public toString(): string {
    this.encoded = true;
    this.secretAccessKey = btoa(this.secretAccessKey);
    return `{\"userName\":\"${this.userName}\",\"accessKey\":\"${this.accessKey}\",\"secretAccessKey\":\"${this.secretAccessKey}\",\"encoded\":${this.encoded}}`;
  }

  public toJSONString() {
    return {
      'userName': `${this.userName}`,
      'accessKey': `${this.accessKey}`,
      'secretAccessKey': `${this.secretAccessKey}`
    };
  }

}