import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormOnSaveAction } from 'src/app/shared/constants';
import { ExclusionOperator, ExclusionOperatorDisplayNameMap } from 'src/app/shared/models/exclusion/exclusion-operator';
import { Messages } from 'src/app/shared/message';
import { ExclusionDisplayMode } from 'src/app/shared/models/exclusion/exclusion-display-mode';
import { DataTypes } from 'src/app/shared/models/custom-rule/conditions/data-types';
import { BaseExclusionConfiguration } from '../../models/exclusion/base-exclusion-configuration';
import { AttributeMetadata } from '../../models/custom-rule/conditions/attribute-metadata';
import { ValueType } from '../../models/custom-rule/conditions/value-type';

@Injectable()
export abstract class ExclusionConfigurationBaseOperator implements BaseExclusionConfiguration {

  @Input()
  public action: FormOnSaveAction;

  @Input()
  public attributeName: string;

  @Input()
  public attributeDataType: DataTypes;

  @Input()
  public exclusionOperator: ExclusionOperator;

  @Input()
  public attributeConditionValues: unknown[];

  @Input()
  public readOnly: boolean;

  @Input()
  public allowDelete: boolean;

  @Input()
  public exclusionDisplayMode: ExclusionDisplayMode;

  @Input()
  public attributeMetadataList: AttributeMetadata[];

  @Output()
  public deleteExclusionConfiguration = new EventEmitter<void>();

  public messages = Messages;
  public exclusionConfigurationFormGroup: UntypedFormGroup;
  public exclusionOperatorDisplayNameMap = ExclusionOperatorDisplayNameMap;
  public displayExclusionOperator: string;

  constructor() { }

  public get exclusionDisplayModes(): typeof ExclusionDisplayMode {
    return ExclusionDisplayMode;
  }

  public onEntityStateAttributeDelete(): void {
    this.deleteExclusionConfiguration.emit();
  }

  public isPristine(): boolean {
    return this.exclusionConfigurationFormGroup.pristine;
  }

  public markAsPristine(): void {
    this.exclusionConfigurationFormGroup.markAsPristine();
  }

  public canDeleteExclusionConfiguration(): boolean {
    return !this.readOnly && this.allowDelete;
  }

  public validate(): boolean {
    return true;
  }

  public buildAttributeConditionValues(): unknown[] {
    return [];
  }

  public getAttributeName(): string {
    return this.attributeName
      .replace(/([A-Z])/g, ' $1');
  }

  public getConditionValueType(): ValueType {
    return ValueType.CONSTANT;
  }

}
