import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-search',
  templateUrl: './modal-search.component.html'
})
export class ModalSearchComponent {

  @Input() public width: string;
  @Input() public searchExist: boolean;
  @Output() searchText = new EventEmitter<string>();
  @Output() clearSearch = new EventEmitter<string>();
  public displayMinSearchError: boolean;
  public isSearch = true;

  constructor(
  ) { }

  performSearch(searchInput) {
    if (searchInput !== null && searchInput.trim().length < 3) {
      this.displayMinSearchError = true;
      this.isSearch = true;
      return;
    } else {
      this.searchText.emit(searchInput);
      this.isSearch = false;
      this.displayMinSearchError = false;
    }
  }

  resetSearch(searchInput) {
    this.clearSearch.emit();
    this.isSearch = true;
  }

}
