export class IconViewDisplayItem {

  private id: string;
  private name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  get Id() {
    return this.id || '';
  }

  get Name() {
    return this.name || '';
  }

  set Id(id: string) {
    this.id = id;
  }

  set Name(name: string) {
    this.name = name;
  }

}