import { Component, Input, OnInit } from '@angular/core';
import { placeHolderSyntaxValidator } from 'src/app/shared/form-validators/placeholder-validator';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Pattern } from 'src/app/shared/pattern';
import { EntityType } from 'src/app/shared/entity-type';

@Component({
  selector: 'app-actions-api-configuration',
  templateUrl: './actions-api-configuration.component.html'
})
export class ActionsApiConfigurationComponent implements OnInit {

  @Input() endPointValue: string;
  @Input() isSaveClicked: boolean ;
  @Input() entityType: string ;

  public configuredAPIActionForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.configuredAPIActionForm = this.fb.group({
      endPoint: [this.endPointValue, [Validators.required,
        Validators.pattern(Pattern.URL), placeHolderSyntaxValidator()]]
    });
    this.disableEndpoint();
  }

  public get endPoint(): AbstractControl {
    return this.configuredAPIActionForm.get('endPoint');
  }

  private disableEndpoint() : void {
    if (this.entityType === EntityType.PACKAGED.toLowerCase()) {
      this.configuredAPIActionForm.controls['endPoint'].disable();
    }
  }

}