import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { ActionsHomeComponent } from './actions-home/actions-home.component';
import { ActionsConfigurationComponent } from './actions-configuration/actions-configuration.component';
import { UnsavedChangesGuard } from 'src/app/shared/guard/unsaved-changes.guard';

const routes: Routes = [
  {
    path: 'actions',
    canActivate: [AuthGuard],
    component: ActionsHomeComponent
  },
  {
    path: 'actions/:action/:type/:id',
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: ActionsConfigurationComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActionsRoutingModule { }