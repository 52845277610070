import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Action } from 'src/app/shared/models/action';
import { EventSource } from 'src/app/shared/models/event-source';
import { ContainerConditionElement } from 'src/app/shared/models/custom-rule/conditions/container-condition-element';
import { ConditionType } from 'src/app/shared/models/custom-rule/conditions/condition-type';
import { AttributeCondition } from 'src/app/shared/models/custom-rule/conditions/attribute-condition';
import { AttributeConditionElement } from 'src/app/shared/models/custom-rule/conditions/attribute-condition-element';
import { CustomRule } from 'src/app/shared/models/custom-rule/custom-rule';
import { ActionElement } from 'src/app/shared/models/custom-rule/actions/action-element';
import { ConfiguredRule } from 'src/app/shared/models/configured-rule.model';
import { UntypedFormControl, UntypedFormGroup, AbstractControl, UntypedFormBuilder, FormGroupDirective, ControlContainer } from '@angular/forms';
import { Company } from 'src/app/shared/models/company';
import { RuleBuilderService } from 'src/app/shared/services/rule-builder.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { EventKey } from 'src/app/shared/models/eventKey.model';
import { ContainerCondition } from 'src/app/shared/models/custom-rule/conditions/container-condition';
import { RuleConditionBuilderComponent } from '../rule-condition-builder/rule-condition-builder.component';
import { RuleActionBuilderComponent } from '../rule-action-builder/rule-action-builder.component';
import { Messages } from 'src/app/shared/message';
import { ConfiguredEntityState } from '../../models/entity-states/configured-entity-state';
import { RuleThenClauseBuilderComponent } from '../rule-then-clause-builder/rule-then-clause-builder.component';
import { RuleThenClause } from '../../models/custom-rule/then-clause/rule-then-clause';
import { ConfiguredEventDetails } from 'src/app/shared/models/configured-event-details';
import { AuthorizationService } from '../../services/authorization-service';
import { RuleWhenClauseBuilderComponent } from '../rule-when-clause-builder/rule-when-clause-builder.component';
import { RuleWhenClause } from '../../models/custom-rule/when-clause';
import { RuleTypes } from '../../constants';

@Component({
  selector: 'app-rule-builder',
  templateUrl: './rule-builder.component.html'
})
export class RuleBuilderComponent implements OnInit {

  @Input() public eventSources: EventSource[];
  @Input() public configuredEventDetails: ConfiguredEventDetails[];
  @Input() public rule: ConfiguredRule;
  @Input() public attributeMap: object;
  @Input() public isReadOnlyRule = false;
  @Input() public selectedEventSchema: string;
  @Input() public filteredConfiguredActions: Action[] = [];
  @Input() public actionDestinations = [];
  @Input() public showDeleteButton = true;
  @Input() public ruleComponentIndex: string;
  @Input() public validationRequired = true;
  @Output() public validationStatus = new EventEmitter<boolean>();

  public thenClauseBuilderComponent: RuleThenClauseBuilderComponent;
  public ruleConditionBuilder:RuleConditionBuilderComponent;
  public actionBuilder: RuleActionBuilderComponent;
  public ruleWhenClauseBuilder: RuleWhenClauseBuilderComponent;
  public rightPaneAttributes: ContainerConditionElement;
  public whenCluaseRightPaneAttributes: ContainerConditionElement;
  public entityStateList: ConfiguredEntityState[];
  public configuredRuleForm: UntypedFormGroup;
  private companyList: Company[] = [];

  constructor(
    private ruleBuilder: RuleBuilderService,
    private popupService: PopupMessageService,
    private controlContainer: ControlContainer,
    private parentF: FormGroupDirective,
    private formBuilder: UntypedFormBuilder,
    private authorizationSerivce: AuthorizationService
  ) { }

  ngOnInit() {
    this.configuredRuleForm = <UntypedFormGroup> this.controlContainer.control;
    this.initializeComponent();
  }

  @ViewChild('ruleConditionBuilder', { static: false })
  public set ruleConditionBuilderComponent(ruleConditionBuilder:RuleConditionBuilderComponent) {
    if (ruleConditionBuilder) {
      this.ruleConditionBuilder = ruleConditionBuilder;
    }
  }

  @ViewChild('actionBuilder', { static: false })
  public set ruleActionBuilderComponent(actionBuilder: RuleActionBuilderComponent) {
    if (actionBuilder) {
      this.actionBuilder = actionBuilder;
    }
  }

  @ViewChild(RuleThenClauseBuilderComponent, { static: false })
  public set ruleThenClauseBuilderComponent(thenClauseBuilderComponent: RuleThenClauseBuilderComponent) {
    if (thenClauseBuilderComponent) {
      this.thenClauseBuilderComponent = thenClauseBuilderComponent;
    }
  }

  @ViewChild(RuleWhenClauseBuilderComponent, { static: false })
  public set ruleWhenClauseBuilderComponent(ruleWhenClauseBuilder: RuleWhenClauseBuilderComponent) {
    if (ruleWhenClauseBuilder) {
      this.ruleWhenClauseBuilder = ruleWhenClauseBuilder;
    }
  }

  private initializeComponent() {
    if (!this.rightPaneAttributes) {
      this.rightPaneAttributes = new ContainerConditionElement();
    }
    this.rightPaneAttributes.conditions = [];
    this.rightPaneAttributes.type = ConditionType.AND;

    if (!this.rule) {
      this.rule = this.getEmptyConfiguredRule();
    } else {
      if (this.rule.eventKey) {
        this.provisionCompanyIdControl();
        this.setCompanyList(this.rule.eventKey.eventSourceName);
      }
      if (this.rule.ruleObject) {
        this.setRightPanelAttributes(this.rule);
        this.addNewFormController('companyId', this.rule.companyId);
      }
    }
    this.showDeleteButton = !this.showDeleteButton ? this.showDeleteButton : this.authorizationSerivce.isAllowed('CFG_RULE', ['DELETE']);
  }

  private setRightPanelAttributes(configuredRule: ConfiguredRule) {
    const rule = JSON.parse(configuredRule.rule);
    if ( rule.condition !== undefined) {
      const containerConditionElement: ContainerConditionElement = this.buildContainerConditionElement(rule.condition, 0);
      this.rightPaneAttributes.type = containerConditionElement.type;
      this.rightPaneAttributes.conditions.push.apply(this.rightPaneAttributes.conditions, containerConditionElement.conditions);
      this.addNewFormController('type', rule.condition.type);
    }
    this.addNewFormController('conditions', this.rightPaneAttributes.conditions);
  }

  private buildContainerConditionElement(containerCondition: ContainerCondition, level: number): ContainerConditionElement {
    const containerConditionElement: ContainerConditionElement = new ContainerConditionElement();
    containerConditionElement.type = containerCondition.type;
    containerConditionElement.conditions = [];
    containerCondition.conditions.forEach(condition => {
      if (condition.hasOwnProperty('conditions')) {
        containerConditionElement.conditions.push(this.buildContainerConditionElement(condition as ContainerCondition, level + 1));
      } else {
        const attributeConditionElement: AttributeConditionElement = new AttributeConditionElement(true, true, condition as AttributeCondition, '');
        containerConditionElement.conditions.push(attributeConditionElement);
      }
    });
    return containerConditionElement;
  }

  private getEmptyConfiguredRule(): ConfiguredRule {
    const eventKey: EventKey = new EventKey();
    eventKey.eventName = '';
    eventKey.eventSourceName = '';
    const configuredRule: ConfiguredRule = new ConfiguredRule();
    configuredRule.name = '';
    configuredRule.description = '';
    configuredRule.eventKey = eventKey;
    const customRule: CustomRule = new CustomRule();
    customRule.companyId = '';
    customRule.eventKey = eventKey;
    customRule.actions = [];
    customRule.whenClause = new RuleWhenClause();
    customRule.type = RuleTypes.CUSTOM;
    configuredRule.setRule(JSON.stringify(customRule));
    return configuredRule;
  }

  public get companyId(): AbstractControl {
    return this.configuredRuleForm.get('companyId');
  }

  private initRightPaneAttributes() {
    if (!this.rightPaneAttributes) {
      this.rightPaneAttributes = new ContainerConditionElement();
    }
    this.rightPaneAttributes.conditions = [];
    this.rightPaneAttributes.type = ConditionType.AND;
  }

  private setCompanyList(eventSourceName: string) {
    this.companyList = [];
    const eventSource: any = this.eventSources.find(eventSource => eventSource.eventSource === eventSourceName);
    if (eventSource != undefined && eventSource.settings != undefined) {
      eventSource.settings.forEach(setting => {
        if (setting['companyId'] != undefined) {
          const company: Company = new Company();
          company.companyId = setting['companyId'];
          company.companyName = setting['companyName'] === undefined ? setting['companyId'] : setting['companyName'];
          this.companyList.push(company);
        }
      });
    }
  }

  resetRightPanel(isEventSourceChanged: boolean) {
    this.provisionCompanyIdControl();
    this.setCompanyList(this.rule.eventKey.eventSourceName);
    this.initRightPaneAttributes();
    this.actionBuilder.initRightPaneActions();
    this.configuredRuleForm.updateValueAndValidity();
  }

  private provisionCompanyIdControl() {
    if (this.isCompanyApplicable(this.rule.eventKey.eventSourceName)) {
      if (this.rule.companyId == null) {
        this.rule.companyId = '';
      }
      this.configuredRuleForm.addControl('companyId', new UntypedFormControl(this.rule.companyId));
    } else {
      this.configuredRuleForm.removeControl('companyId');
    }
  }

  public isCompanyApplicable(eventSourceName: string) {
    if (eventSourceName) {
      const eventSource = this.eventSources.find(eventSource => eventSource.eventSource === eventSourceName);
      if (eventSource && eventSource['settings'] && eventSource['settings'].length > 0 && eventSource['settings'][0] && eventSource['settings'][0]['companyId']) {
        return true;
      }
    }
    return false;
  }

  private addNewFormController(controllerName, controllerValue): UntypedFormGroup {
    this.configuredRuleForm.addControl(controllerName, this.formBuilder.control(''));
    this.configuredRuleForm.controls[controllerName].patchValue(controllerValue);
    this.configuredRuleForm.controls[controllerName].updateValueAndValidity();
    return this.configuredRuleForm;
  }

  private isPlaceHolderAvailable(configuredRuleForm): boolean {
    if (configuredRuleForm.controls.placeHoldList.value && configuredRuleForm.controls.placeHoldList.value.length > 0) {
      return true;
    }
    return false;
  }

  public validateCompanyId(configuredRuleForm) {
    const value = configuredRuleForm.controls.companyId.value;
    if (this.validationRequired && (value === null || value === undefined || value === '')
      && configuredRuleForm.controls.companyId.touched) {
      return false;
    }
    return true;
  }

  public validateConditionAttributes(conditions): boolean {
    if (!this.ruleConditionBuilder.validateContainerConditionElement(conditions)) {
      this.validationStatus.emit(false);
      this.popupService.showErrorMessage(this.ruleConditionBuilder.message);
      return false;
    }
    if (this.rightPaneAttributes.conditions.length == 0) {
      this.validationStatus.emit(false);
      this.popupService.showErrorMessage(Messages.incompleteRule);
      return false;
    }
    return true;
  }

  public validateActions() {
    this.validationStatus.emit(this.actionBuilder.validateActions());
  }

  public validateRuleThenClause(): void {
    this.validationStatus.emit(this.thenClauseBuilderComponent.validateThenClause());
  }

  public validateRuleWhenClause(): void {
    this.validationStatus.emit(this.ruleWhenClauseBuilder.validate());
  }

  getRule(companyId: string, eventSourceName: string, eventName: string, entityStateList: ConfiguredEntityState[] ) {
    if (this.rule.isRuleTypePackaged()) {
      return this.buildPackagedCustomRule(companyId, eventSourceName, eventName, entityStateList);
    }
    if (this.validateConditionAttributes(this.rightPaneAttributes.conditions)) {
      const ruleThenClause: RuleThenClause = this.thenClauseBuilderComponent.buildThenClause();
      const actions: ActionElement[] = this.actionBuilder.buildActions();
      if (actions.length == 0) {
        this.popupService.showErrorMessage(Messages.addActionsErrorMessage);
        this.validationStatus.emit(false);
        return;
      }
      const rule: CustomRule = this.ruleBuilder.buildConditionRule(eventName, eventSourceName, companyId,
        this.rightPaneAttributes, actions, ruleThenClause, entityStateList);
      return JSON.stringify(rule);
    }
  }

  private buildPackagedCustomRule(companyId: string, eventSourceName: string, eventName: string, entityStateList: ConfiguredEntityState[] ): string {
    if (!this.ruleWhenClauseBuilder.validate()) {
      return;
    }
    const ruleThenClause: RuleThenClause = this.thenClauseBuilderComponent.buildThenClause();
    const ruleWhenClause = this.ruleWhenClauseBuilder.build(entityStateList);
    const eventKey: EventKey = new EventKey();
    eventKey.eventName = eventName;
    eventKey.eventSourceName = eventSourceName;
    const customRule: CustomRule = new CustomRule();
    customRule.name = eventName;
    customRule.packageName = eventSourceName;
    customRule.eventKey = eventKey;
    customRule.companyId = companyId;
    customRule.thenClause = ruleThenClause;
    customRule.whenClause = ruleWhenClause;
    customRule.entitySchemas = ruleThenClause.getEntityStateSchemasUsed(entityStateList);
    customRule.type = RuleTypes.PACKAGED;
    customRule.templateName = this.rule.ruleObject.templateName;
    customRule.salience = this.rule.ruleObject.salience;
    customRule.salientRules = this.rule.ruleObject.salientRules;
    return JSON.stringify(customRule);
  }

}