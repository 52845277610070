import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItemActivatedEvent } from '@epsilon/core-ui';

@Component({
  selector: 'app-ae-left-nav',
  templateUrl: './ae-left-nav.component.html'
  })
export class AELeftNavComponent {

  @Input() public leftNavigations: [];

  constructor(public router: Router) {}

  public leftNavSecondaryCollapsed = false;

  public get tooltipMessageSecondary(): string {
    return this.leftNavSecondaryCollapsed ? 'Expand' : 'Collapse';
  }

  public isActive(url: string): boolean {
    return this.router.isActive(url, true);
  }

  public onActivated(event: NavigationItemActivatedEvent): void {
    event.event.preventDefault();
    void this.router.navigateByUrl(event.item.url);
  }

  public onToggleLeftNavSecondaryClick() {
    this.leftNavSecondaryCollapsed = !this.leftNavSecondaryCollapsed;
  }

}
