<form novalidate
      [formGroup]="awsUserCredentialForm">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="userName">Username</label>
        <input type="text"
               id="userName"
               class="form-control"
               placeholder="Enter your username"
               formControlName="userName"
               [ngClass]="{ 'is-invalid': validateForRequired(getUserNameControl()) }"/>
        <div *ngIf="validateForRequired(getUserNameControl())" class="invalid-feedback">
          {{getEmptyUserNameMessage()}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="accessKey">Access Key</label>
        <input type="text"
               id="accessKey"
               class="form-control"
               placeholder="Enter an access key"
               formControlName="accessKey"
               [ngClass]="{'is-invalid': validateForRequired(getAccessKeyControl()) }" />
        <div *ngIf="validateForRequired(getAccessKeyControl())" class="invalid-feedback">
          {{getEmptyAccessKeyMessage()}}
        </div>
      </div>
    </div>
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="secretAccessKey">Secret Access Key</label>
        <input type="password"
               id="secretAccessKey"
               class="form-control"
               placeholder="Enter a secret access key"
               formControlName="secretAccessKey"
               [ngClass]="{ 'is-invalid': validateForRequired(getSecretAccessKeyControl()) }" />
        <div *ngIf="validateForRequired(getSecretAccessKeyControl())" class="invalid-feedback">
          {{getEmptySecretAccessKeyMessage()}}
        </div>
      </div>
    </div>
  </div>
</form>
