import { ConfiguredEventId } from './configured-event.model';
import { ServiceSettings } from './service-settings';
import { ActionType } from './action-type';
import { ActionDelay } from './action-delay';
import { Pattern } from 'src/app/shared/pattern';
import { DelayType } from './delay-type.enum';
import { EntityTypes } from 'src/app/shared/constants';
import { Mode } from './mode-enum';
import { UtilService } from '../services/util-service';

export class Action {

  id: string;
  name: string;
  actionDestinationId: string;
  actionType: ActionType;
  actionProvider: string;
  eventId: ConfiguredEventId;
  description: string;
  data: string;
  status: string;
  programs: string[];
  modifiedBy: string;
  modifiedDate: string;
  settings: ServiceSettings;
  entityType: string;
  serviceId: string;
  private mode: string;

  constructor(response?: Object) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response)));
      if (this.entityType === EntityTypes.PACKAGED) {
        this.data = JSON.stringify(this.settings);
      }
      const parsedData = this.data === '' ? '' : JSON.parse(this.data);
      const serviceSettings: ServiceSettings = Object.create(ServiceSettings.prototype);
      this.settings = serviceSettings;
      Object.assign(serviceSettings, parsedData);
      this.settings.actionDelay = new ActionDelay(serviceSettings.actionDelay);
    }
    this.mode = this.getMode();
  }

  public getDynamicActionValueforRule() {
    const placeholderRegex = RegExp(Pattern.PLACEHOLDER, 'g');
    let regResult;
    const placeHoldList = [];
    while ((regResult = placeholderRegex.exec(this.data)) !== null) {
      regResult[1] !== undefined ? placeHoldList.push(regResult[1]) : placeHoldList.push(regResult[0]);
    }
    return placeHoldList;
  }

  public hasDurationDelay(): boolean {
    return this.settings && this.settings.getDelayType() === DelayType.DURATION;
  }

  public isModeSynchronous(): boolean {
    return this.mode && this.mode.toUpperCase() === Mode.SYNCHRONOUS;
  }

  public hasAttributeDelay(): boolean {
    return this.settings && this.settings.getDelayType() === DelayType.ATTRIBUTE;
  }

  public setActionDelay(actionDelay : ActionDelay) {
    this.settings.actionDelay = actionDelay;
  }

  public hasDelay(): boolean {
    return this.hasDurationDelay() || this.hasAttributeDelay();
  }

  public getDelayType() {
    if (this.settings) {
      return this.settings.getDelayType();
    }
    return null;
  }

  public getDelay() {
    if (this.settings) {
      return this.settings.getDelay();
    }
    return null;
  }

  public isValid(): boolean {
    if (this.entityType === EntityTypes.PACKAGED) {
      return this.isValidPackagedConfiguredAction();
    }
    return this.isValidCustomConfiguredAction();
  }

  public getMode(): string {
    if (this.mode) {
      return this.mode.toUpperCase();
    }
    return Mode.ASYNCHRONOUS;
  }

  public setMode(mode: string): void {
    this.mode = mode;
  }

  public getModeDisplayName(): string {
    return UtilService.getTitlecase(this.getMode());
  }

  private isValidPackagedConfiguredAction(): boolean {
    if (this.settings && this.actionProvider) {
      return true;
    }
    return false;
  }

  private isValidCustomConfiguredAction(): boolean {
    if (this.data && this.actionDestinationId) {
      return true;
    }
    return false;
  }

}
