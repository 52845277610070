import { Component, OnInit, TemplateRef, ViewChild, NgModule } from '@angular/core';
import { BaseComponent } from '../../client/base/base.component';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { USER_GROUP_SERVICE_TOKEN, USER_GROUP_CLASS_TOKEN } from 'src/app/shared/tokens';
import { UserGroupsComponent } from '../../client/user-roles/user-groups/user-groups.component';
import { PackagedUserGroupService } from 'src/app/shared/services/packaged-user-group.service';
import { Messages } from 'src/app/shared/message';

@Component({
  selector: 'app-system-user-groups-home',
  templateUrl: './system-user-groups-home.component.html',
  providers: [{provide: USER_GROUP_SERVICE_TOKEN, useClass: PackagedUserGroupService}]
  })

export class SystemUserGroupsHomeComponent extends BaseComponent implements OnInit {
  public messages = Messages;
  constructor() {
    super();
  }

}