export class DroppedItem {

  name: string;
  type: DroppedItemType;

}

export enum DroppedItemType {
  RULE = 'RULE',
  PROGRAM_TEMPLATE = 'PROGRAM_TEMPLATE',
}