<h2>Select Permissions</h2>
<div class="py-4">
  <form class="Core-Form" [formGroup]="allPermissionsFormGroup">
    <div class="row p-1 pr-4">
      <div class="col-auto ml-4 pt-1 pr-1">
        <p>Set all to</p>
      </div>
      <div class="col pt-2">
        <coreui-checkbox-group orientation="horizontal">
          <coreui-form-field label="Read">
            <input type="checkbox" coreUICheckbox formControlName="allReadFormControl" (change)="onAllPermissionChange('READ')" />
          </coreui-form-field>
          <coreui-form-field label="Write">
            <input type="checkbox" coreUICheckbox formControlName="allWriteFormControl" (change)="onAllPermissionChange('CREATE')" />
          </coreui-form-field>
          <coreui-form-field label="Delete">
            <input type="checkbox" coreUICheckbox formControlName="allDeleteFormControl" (change)="onAllPermissionChange('DELETE')" />
          </coreui-form-field>
        </coreui-checkbox-group>
      </div>
    </div>
  </form>
</div>