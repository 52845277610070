import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';
import { SystemUser } from '../models/user-roles/system-user';
import { UsersService } from './users-service-interface';

@Injectable({
  providedIn: 'root'
  })
export class SystemUsersService implements UsersService {

  private static readonly configuredUsersUrl: string = 'admin/user/';
  private static readonly parentID: string = 'System';
  private http: HttpClient;
  private host: string = environment.host;
  private base: string = environment.base;

  public constructor(http: HttpClient) {
    this.http = http;
  }

  public getUsersByParent(parentId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + SystemUsersService.configuredUsersUrl;
    return this.http.get<ServiceResponse>(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  public updateUser(parentId: string, systemUser: SystemUser): Observable<ServiceResponse> {
    const url = this.host + this.base + SystemUsersService.configuredUsersUrl + systemUser.id;
    return this.http.put<ServiceResponse>(url, systemUser, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  public addUIUser(parentId: string, systemUser: SystemUser): Observable<ServiceResponse> {
    const url = this.host + this.base + SystemUsersService.configuredUsersUrl;
    return this.http.post<ServiceResponse>(url, systemUser, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

}