import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { EventSourceProvidersComponent } from './event-source-providers.component';
import { Observable } from 'rxjs';
import { LoginServiceLocator } from 'src/app/shared/services/login.service-locator';

@Injectable({
  providedIn: 'root'
})
export class EventSettingsCanDeactivateGuardService implements CanDeactivate<EventSourceProvidersComponent> {

  constructor(private loginServiceLocator: LoginServiceLocator) {}

  canDeactivate(component: EventSourceProvidersComponent): boolean | Observable<boolean> | Promise<boolean> {

    if (!this.loginServiceLocator.getLoginService().isLoggedIn()) {
      return true;
    }

    if (component.companyAdded !== 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      return component.launchUnsavedChangesModal();
    }
    return true;
  }

}