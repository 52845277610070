import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren, Inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServiceResponse } from 'src/app/shared/models/service-response';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants, FormOnSaveAction } from 'src/app/shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Messages } from 'src/app/shared/message';
import { Permission } from 'src/app/shared/models/permission/role/permission';
import { Action } from 'src/app/shared/models/permission/feature/action';
import { Feature, CategoryDisplayName, ExcludedFeatures } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { AuthorizationService } from '../../../../shared/services/authorization-service';
import { Type } from '../../../../shared/models/roleType';
import { WarningType } from 'src/app/shared/warning-options';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { AllPermissionsComponent } from './all-permissions/all-permissions.component';
import { CategoryPermissionsComponent } from './category-permissions/category-permissions.component';
import { RolesService } from 'src/app/shared/services/roles-service-interface';
import { ROLES_SERVICE_TOKEN, ROLE_MODEL } from 'src/app/shared/tokens';
import { Role } from 'src/app/shared/models/permission/role/role';
import { ConfiguredRole } from 'src/app/shared/models/user-roles/configured-role';
import { ActionType } from 'src/app/shared/models/permission/feature/action-type';
import { Pattern } from 'src/app/shared/pattern';

@Component({
  selector: 'app-role-configuration',
  templateUrl: './role-configuration.component.html'
  })
export class RoleConfigurationComponent extends BaseFormDirective implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren('allPermissionComponent') public allPermissionComponentList: QueryList<AllPermissionsComponent>;
  @ViewChildren('categoryPermissionComponents') public categoryPermissionComponentsList: QueryList<CategoryPermissionsComponent>; 

  public formReady = false;
  public isCustomRole = false;
  public isAPIServiceCalled = false;
  public rolesLandingPageRouterLink: string;
  public messages = Messages;
  public feature = Feature;
  public parentContextService: ParentContextService;
  public route: ActivatedRoute;
  public formOnSaveAction = FormOnSaveAction.CREATE;
  public isFormReadOnly: boolean;
  public parentId: string;
  public roleCategoryFeaturesMap = new Map<string, Permission[]>();
  public roleName = 'New Role';
  public categoryDisplayName = CategoryDisplayName;
  public editRoleType: string;
  public isDeleteOperation: boolean;

  private allCategoryFeaturesMap: Record<string, unknown> = {};
  private allPermissionComponent: AllPermissionsComponent;
  private categoryPermissionComponents: CategoryPermissionsComponent[];
  private editRoleId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private roles: Role[] = [];

  public constructor(
    private popupService: PopupMessageService,
    public router: Router,
    public fb: UntypedFormBuilder,
    public authorizationService: AuthorizationService,
    @Inject(ROLES_SERVICE_TOKEN) private rolesService: RolesService,
    @Inject(ROLE_MODEL) public role: Role,
    route: ActivatedRoute,
    parentContextService: ParentContextService) {
    super();
    this.route = route;
    this.parentContextService = parentContextService;
    this.route.params.subscribe(params => {
      this.formOnSaveAction = params.action;
      this.editRoleId = params.id;
      this.editRoleType = params.type;
    });
    this.parentId = this.parentContextService.getParentContext();
  }

  public ngOnInit(): void {
    this.rolesLandingPageRouterLink = this.rolesService.getNavPath();
    this.isCustomRole = this.editRoleType === this.rolesService.getRoleType().toLowerCase();
    this.buildConfiguredRoleForm();
    this.initRoles();
    this.initFeatures();
  }

  public ngAfterViewInit(): void {
    this.initFeaturePermissionComponent();
    this.allPermissionComponentList.changes.subscribe((component: QueryList<AllPermissionsComponent>) => {
      this.allPermissionComponent = component.first;
      this.loadAllPermissionForm();
    });
    this.categoryPermissionComponentsList.changes.subscribe((component: QueryList<CategoryPermissionsComponent>) => {
      this.categoryPermissionComponents = component.toArray();
      this.loadAllPermissionForm();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public canNavigateAway(): boolean {
    return (super.canNavigateAway());
  }

  public onSave(): void {
    if (this.isFormValid()) {
      if (this.formOnSaveAction === FormOnSaveAction.EDIT) {
        this.editRole();
      } else if (this.formOnSaveAction === FormOnSaveAction.CREATE) {
        this.createRole();
      }
    }
  }

  public handleDecision(userConfirmation: boolean): void {
    if (userConfirmation && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteRole(this.editRoleId);
    }
  }

  public onDelete(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteRole,
      msg: Messages.deleteRoleWarningMessage,
      msg2: [Messages.deleteRoleWarningMessage2]
    });
  }

  public onAllPermissionChange($event: Action): void {
    this.categoryPermissionComponents.forEach(component => {
      component.updateAllFeaturePermission($event.allowed, $event.actionType);
      component.refreshCategoryPermissionForm();
    });
  }

  public loadAllPermissionForm(): void {
    if (this.categoryPermissionComponents.includes(undefined) || this.allPermissionComponent === undefined) {
      return;
    }
    const allReadSelected = this.categoryPermissionComponents.every(component =>
      (component.categoryPermissionsFormGroup.controls.categoryReadFormControl.value === true));
    const allWriteSelected = this.categoryPermissionComponents.every(component =>
      (component.categoryPermissionsFormGroup.controls.categoryWriteFormControl.value === true));
    const allDeleteSelected = this.categoryPermissionComponents.every(component =>
      (component.categoryPermissionsFormGroup.controls.categoryDeleteFormControl.value === true));

    this.allPermissionComponent.allPermissionsFormGroup.controls.allReadFormControl.setValue(allReadSelected);
    this.allPermissionComponent.allPermissionsFormGroup.controls.allWriteFormControl.setValue(allWriteSelected);
    this.allPermissionComponent.allPermissionsFormGroup.controls.allDeleteFormControl.setValue(allDeleteSelected);
  }

  public buildPermissions(): Permission[] {
    let permissions: Permission[] = [];
    this.categoryPermissionComponents.forEach(component => {
      permissions = permissions.concat(component.buildPermissions());
    });
    return permissions;
  }

  public buildConfiguredRole(): Role {
    this.role = new Role();
    this.role.parentId = this.parentId;
    this.role.name = this.configuredEntityForm.get('name').value;
    this.role.description = this.configuredEntityForm.get('description').value;
    this.role.active = true;
    this.role.permissions = this.buildPermissions();
    return this.role;
  }

  public getCategoryPermissionTitle(category: string): string {
    return 'All ' + this.categoryDisplayName[category] + ' Permissions';
  }

  public originalOrder(): number {
    return 0;
  }

  private initFeatures(): void {
    this.isAPIServiceCalled = true;
    this.rolesService.getAllFeatures(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
      this.allCategoryFeaturesMap = serviceResponse.result as Record<string, unknown>;
      this.isAPIServiceCalled = false;
      this.buildCategoryFeatureMapping();
    }, () => {
      this.isAPIServiceCalled = false;
      this.popupService.showErrorMessage(this.messages.rolesRequestErrorMessage);
    });
  }

  private buildCategoryFeatureMapping(): void {
    Object.keys(this.allCategoryFeaturesMap).forEach(category => {
      if (category !== "SYSTEM"){
        this.buildPermissionsForCategory(category, this.allCategoryFeaturesMap[category] as Record<string, unknown>);
      }
    });
    this.initPermissions();
  }

  private buildPermissionsForCategory(category: string, categoryFeaturesMap: Record<string, unknown>): void {
    let permissions: Permission[] = [];
    Object.keys(categoryFeaturesMap).forEach(feature => {
      const permission = new Permission();
      permission.feature = feature as Feature;
      permission.actions = categoryFeaturesMap[feature]['actions'] as Action[];
      permissions.push(permission);
    });
    permissions = this.filterExcludeFeatures(permissions);
    this.roleCategoryFeaturesMap.set(category, permissions);
  }

  private filterExcludeFeatures(permissions: Permission[]): Permission[] {
    return permissions.filter(permission => !ExcludedFeatures.includes(permission.feature));
  }

  private patchPermissions(savedPermissions: Permission[]): void {
    this.roleCategoryFeaturesMap.forEach((value: Permission[], key: string) => {
      this.roleCategoryFeaturesMap.set(key,
        value.map(element1 => savedPermissions.find(element2 => element2.feature === element1.feature) || element1));
    });
  }

  private markAsPristine(): void {
    this.configuredEntityForm.markAsPristine();
  }

  private initFeaturePermissionComponent(): void {
    this.categoryPermissionComponents = this.categoryPermissionComponentsList.toArray();
    this.allPermissionComponent = this.allPermissionComponentList.first;
  }

  private initRoles(): void {
    this.isAPIServiceCalled = true;
    this.rolesService.getAllRoles(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
      this.roles = serviceResponse.result as Role[];
      this.isAPIServiceCalled = false;
    }, error => {
      this.isAPIServiceCalled = false;
      if (error.error.statusMessage === 'NOT_FOUND') {
        this.roles = [];
      } else {
        this.popupService.showErrorMessage(this.messages.rolesRequestErrorMessage);
      }
    });
  }

  private initPermissions(): void {
    if (this.formOnSaveAction === FormOnSaveAction.CREATE) {
      this.formReady = true;
      return;
    } else if (this.formOnSaveAction === FormOnSaveAction.EDIT) {
      
      this.initPermissionsFromRole();
    }
  }

  private initPermissionsFromRole(): void {
    this.getRoleById();
  }

  private getRoleById(): void {
    this.isAPIServiceCalled = true;
    this.rolesService.getRoleById(this.editRoleType, this.editRoleId, this.parentId)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
        const role = serviceResponse.result as Role;
        role.description = role.description === null ? '' : role.description;
        this.formReady = true;
        this.isAPIServiceCalled = false;
        this.patchConfiguredRoleForm(role);
      }, error => {
        this.isAPIServiceCalled = false;
        this.popupService.showErrorMessage(this.messages.roleEditErrorMessage + (<string>error.error.statusMessage));
      }
      );
  }

  private patchConfiguredRoleForm(role: Role): void {
    this.configuredEntityForm.patchValue({
      id: role.id,
      name: role.name,
      description: role.description
    });
    this.role = new Role();
    this.role.id = role.id;
    this.role.name = role.name;
    this.role.description = role.description;
    this.role.permissions = role.permissions;
    this.roleName = role.name;
    this.patchPermissions(role.permissions);
    this.disableFormFields(role);
  }

  private disableFormFields(configuredRole): void {
    if (!this.isRoleUpdateAllowed(configuredRole.type.toLowerCase())){
      this.configuredEntityForm.disable();
    }
  }

  private deleteRole(id: string): void {
    this.isAPIServiceCalled = true;
    this.rolesService.deleteRoleById(this.parentId, id)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        if (this.parentId === Constants.SYSTEM_PARENT){
          this.popupService.showSuccessMessage(Messages.deletedRole, true);
        } else{
          this.popupService.showSuccessMessage(Messages.deletedRole);
        } 
        this.isDeleteOperation = true;
        this.isAPIServiceCalled = false;
        void this.router.navigate([this.rolesService.getNavPath()]);
      }, () => {
        this.isAPIServiceCalled = false;
        this.popupService.showErrorMessage(Messages.unableToDeleteRole);
      });
  }

  private buildConfiguredRoleForm(): void {
    this.configuredEntityForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('')
    });
    this.isFormReadOnly = this.editRoleType === Type.GLOBAL.toLowerCase();
    if (this.authorizationService.isAllowed(this.rolesService.getFeatureName(), [ActionType.CREATE, ActionType.UPDATE])) {
      this.isFormReadOnly = false;
    }
  }

  private isFormValid(): boolean {
    return this.isRoleNameValid();
  }

  private isRoleNameValid(): boolean {
    const roleNameFormControl = this.configuredEntityForm.get('name');
    if (roleNameFormControl.invalid) {
      return false;
    }
    if (roleNameFormControl.value.match(Pattern.ALPHA_NUMERIC_SPACE_HYPHEN) === null){
      this.configuredEntityForm.controls.name.setErrors({ pattern: true });
      return false;
    } else{
      return this.isRoleNameUnique(roleNameFormControl);
    }
  }

  private isRoleNameUnique(roleNameFormControl: AbstractControl): boolean {
    if (this.isRoleNameExist(roleNameFormControl.value)) {
      this.configuredEntityForm.controls.name.setErrors({ duplicateName: true });
      return false;
    }
    return true;
  }

  private isRoleNameExist(roleName: string): boolean {
    const roleNames: string[] = this.roles.map(configuredRole => configuredRole.name);
    
    if (this.formOnSaveAction === FormOnSaveAction.EDIT && this.role.name === roleName) {
      return false;
    }
    return roleNames.includes(roleName);
  }

  public isRoleUpdateAllowed(roleType: string): boolean{
    return (this.authorizationService.isAllowed(this.rolesService.getFeatureName(),  [ActionType.CREATE, ActionType.UPDATE])) && (roleType === this.rolesService.getRoleType().toLowerCase());
  }

  public isRoleDeleteAllowed(): boolean{
    return this.formOnSaveAction === FormOnSaveAction.EDIT && this.isCustomRole && this.authorizationService.isAllowed(this.rolesService.getFeatureName(), [ActionType.DELETE]);
  
  }

  private createRole(): void {
    this.isAPIServiceCalled = true;
    this.buildConfiguredRole();
    this.rolesService.createRole(this.parentId, this.role).pipe(takeUntil(this.destroyed$)).subscribe(
      (serviceResponse: ServiceResponse) => {
        const configuredRole = serviceResponse.result as ConfiguredRole;
        this.popupService.showSuccessMessage(this.messages.roleCreateSuccessMessage);
        this.isAPIServiceCalled = false;
        this.markAsPristine();
        this.formOnSaveAction = FormOnSaveAction.EDIT;
        void this.router.navigate([this.rolesService.getNavPath()]);
        this.roleName = this.role.name;
        this.disableFormFields(configuredRole);
      }, (error: any) => {
        let errorMessage = Messages.roleCreateErrorMessage;
        if (error.status === 400) {
          if (error.error.statusMessage === 'DUPLICATE_ITEM') {
            errorMessage = error.error.result;
          }
          if (error.error.statusMessage === 'REQUIRED') {
            errorMessage = Messages.requiredFields;
          }
        }
        this.popupService.showErrorMessage(errorMessage);
        this.isAPIServiceCalled = false;
      });
  }

  private editRole(): void {
    this.isAPIServiceCalled = true;
    this.buildConfiguredRole();
    this.rolesService.updateRole(this.parentId, this.role, this.editRoleId).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.popupService.showSuccessMessage('Role "' + this.role.name + '" is successfully updated');
        this.isAPIServiceCalled = false;
        this.markAsPristine();
        void this.router.navigate([this.rolesService.getNavPath()]);
      }, () => {
        this.popupService.showErrorMessage(this.messages.roleEditErrorMessage);
        this.isAPIServiceCalled = false;
      });
  } 
}