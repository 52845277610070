import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';
import { ConfiguredUser } from '../models/user-roles/configured-user';
import { UsersService } from './users-service-interface';
import { APIUser } from '../models/user-roles/api-user';

@Injectable({
  providedIn: 'root'
  })
export class ConfiguredUsersService implements UsersService {

  private static readonly configuredUsersUrl: string = 'admin/user/';

  private host: string = environment.host;
  private base: string = environment.base;

  constructor(public http?: HttpClient) { }

  getUsersByParent(parentId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredUsersService.configuredUsersUrl;
    return this.http.get<ServiceResponse>(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  updateUser(parentId: string, configuredUser: ConfiguredUser): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredUsersService.configuredUsersUrl + configuredUser.id;
    return this.http.put<ServiceResponse>(url, configuredUser, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  addUIUser(parentId: string, configuredUser: ConfiguredUser): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredUsersService.configuredUsersUrl;
    return this.http.post<ServiceResponse>(url, configuredUser, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  addAPIUser(parentId: string, apiUser: APIUser): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredUsersService.configuredUsersUrl;
    return this.http.post<ServiceResponse>(url, apiUser, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

}