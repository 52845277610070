import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Action } from '../../models/action';
import { ConfiguredEventDetails } from '../../models/configured-event-details';
import { ConfiguredRule } from '../../models/configured-rule.model';
import { AttributeMetadata } from '../../models/custom-rule/conditions/attribute-metadata';
import { RuleThenClause } from '../../models/custom-rule/then-clause/rule-then-clause';
import { RuleActionBuilderComponent } from '../rule-action-builder/rule-action-builder.component';
import { RuleThenClauseOperationBuilderComponent } from '../rule-then-clause-operation-builder/rule-then-clause-operation-builder.component';

@Component({
  selector: 'app-rule-then-clause-builder',
  templateUrl: './rule-then-clause-builder.component.html'
})
export class RuleThenClauseBuilderComponent implements OnInit {

  @Input()
  public formGroup: UntypedFormGroup;
  @Input()
  public isReadOnlyRule = false;
  @Input()
  public showDeleteButton: boolean;
  @Input()
  public validationRequired: boolean;
  @Input()
  public configuredRule: ConfiguredRule;
  @Input()
  public attributeMap: { [key: string]: AttributeMetadata };
  @Input() public eventSources: EventSource[];
  @Input() public configuredEventDetails: ConfiguredEventDetails[];
  @Input() public filteredConfiguredActions: Action[] = [];
  @Input() public actionDestinations = [];
  @Input() public selectedEventSchema: string;

  @ViewChild(RuleThenClauseOperationBuilderComponent, { static: false })
  public ruleThenClauseOperationBuilderComponent: RuleThenClauseOperationBuilderComponent;
  public actionBuilder: RuleActionBuilderComponent;

  @ViewChild('thenClauseActionBuilder', { static: false })
  public set ruleThenClauseActionBuilderComponent(actionBuilder: RuleActionBuilderComponent) {
    if (actionBuilder) {
      this.actionBuilder = actionBuilder;
    }
  }

  public constructor() {
  }

  public ngOnInit(): void {
  }

  public initRightPaneThenClause(): void {
    this.ruleThenClauseOperationBuilderComponent.initRightPaneOperations();
    if (this.configuredRule.isRuleTypePackaged()) {
      this.actionBuilder.initRightPaneActions();
    }
  }

  public validateThenClause(): boolean {
    if (!this.ruleThenClauseOperationBuilderComponent.validateOperations()) {
      return false;
    }
    if (this.configuredRule.isRuleTypePackaged()) {
      return this.actionBuilder.validateActions();
    }
    return true;
  }

  public buildThenClause(): RuleThenClause {
    const ruleThenClause: RuleThenClause = new RuleThenClause();
    ruleThenClause.operations = this.ruleThenClauseOperationBuilderComponent.buildOperations();
    if (this.configuredRule.isRuleTypePackaged()) {
      ruleThenClause.actions = this.actionBuilder.buildActions();
    }
    return ruleThenClause;
  }

}