import { Injectable } from '@angular/core';
import { CognitoUtil } from '../auth/cognito.service';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParentContextService } from './parent-context.service';
import { AuthorizationService } from './authorization-service';
import { LoginService } from './LoginService';
import { BaseLoginService } from './base-login.service';

export interface LoginCallback {
  loginCallback(message: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoLoginService extends BaseLoginService implements LoginService {

  returnTo = '/';
  isLoggedIn_: boolean;
  loggedInUser: string;
  lastLoggedInUser: string;
  expiresAt: number = 0;

  constructor(
    public cognitoUtil: CognitoUtil,
    private router: Router,
    private activeModal: NgbModal,
    private _location: Location,
    activeRoute: ActivatedRoute,
    public authorizationService: AuthorizationService,
    public parentContext?: ParentContextService
  ) {
    super(router);
    if (this.cognitoUtil.getCurrentUser()) {
      this.lastLoggedInUser = this.cognitoUtil.getCurrentUser().getUsername();
    }
    activeRoute.queryParams.subscribe(params => {
      if (params['returnTo']) {
        this.returnTo = params['returnTo'];
      } else {
        this.returnTo = '/';
      }
    });
  }

  public authorizationFailed(message: string, callback: LoginCallback) : void {
    callback.loginCallback(message);
    console.log('logging out user as authorization failed');
    setTimeout(() => this.logout(), 2000);
    console.log('result: ' + message);
  }

  public authenticate(username: string, password: string, callback: LoginCallback): void {
    console.log('UserLoginService: starting the authentication');

    const authenticationData = {
      Username: username,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    console.log('UserLoginService: Params set...Authenticating the user');
    const cognitoUser = new CognitoUser(userData);
    console.log('UserLoginService: config is ' + AWS.config);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes) => {
        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;

        cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
          onSuccess: result => this.onLoginSuccess(callback, result),
          onFailure: err => this.onLoginError(callback, err)
        });
      },
      onSuccess: result => {
        console.log(result);
        this.onLoginSuccess(callback, result);
      },
      onFailure: err => this.onLoginError(callback, err)
    });
  }

  public logout(): void {
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('token');
    localStorage.removeItem(ParentContextService.PARENT_LIST);
    localStorage.removeItem(AuthorizationService.PERMISSIONS);
    if (this.cognitoUtil.getCurrentUser()) {
      this.cognitoUtil.getCurrentUser().signOut();
    }
    this.isLoggedIn_ = false;
    this.loggedInUser = null;
    this.expiresAt = 0;
    this.activeModal.dismissAll();
    this.router.navigate(['login'], { queryParams: { returnTo: '' }, queryParamsHandling: 'merge' });
  }

  public isLoggedIn(): boolean {
    if (!this.loggedInUser) {
      this.isAuthenticated();
    }
    this.isLoggedIn_ = !!this.loggedInUser && this.expiresAt !== 0
        && moment().unix() < this.expiresAt;
    return this.isLoggedIn_;
  }

  public getUserName(): string {
    return this.loggedInUser;
  }

  private isAuthenticated(): void {
    // authenticating the user from local storage if session is unavailable
    const cognitoUser = this.cognitoUtil.getCurrentUser();
    const thisRef = this;
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log('UserLoginService: Couldn\'t get the session: ' + err, err.stack);
        } else {
          console.log('UserLoginService: Session is ' + session.isValid());
          if (session.isValid()) {
            thisRef.setSession(session);
          }
        }
      });
    } else {
      console.log('UserLoginService: can\'t retrieve the current user');
    }
  }

  private forgotPassword(username: string, callback: LoginCallback): void {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function() {

      },
      onFailure: function(err) {
        callback.loginCallback(err.message);
      },
      inputVerificationCode() {
        callback.loginCallback(null);
      }
    });
  }

  private confirmNewPassword(email: string, verificationCode: string, password: string, callback: LoginCallback): void {
    const userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function() {
        callback.loginCallback(null);
      },
      onFailure: function(err) {
        callback.loginCallback(err.message);
      }
    });
  }

  private onLoginSuccess = (callback: LoginCallback, session: CognitoUserSession) => {
    console.log('In authenticateUser onSuccess callback');
    this.setSession(session);
    if (this.authorizationService.isAuthorizationEnabled()) {
      this.authorize(callback);
    } else {
      this.router.navigateByUrl(this.returnTo);
    }
  };

  private onLoginError = (callback: LoginCallback, err) => {
    callback.loginCallback(err.message);
  };

  private authorize(callback: LoginCallback) : void {
    let parentId = '';
    if (this.parentContext.isParentContextSet() && this.lastLoggedInUser === this.loggedInUser) {
      parentId = this.parentContext.getParentContext();
    }
    this.authorizationService.initializePermissionsOnLogin(parentId, this.loggedInUser, this, callback);
  }

  private setSession(session): void {
    this.expiresAt = session.getIdToken().payload.exp;
    this.loggedInUser = this.cognitoUtil.getCurrentUser().getUsername();
    localStorage.setItem('token', session.getIdToken().getJwtToken());
  }

}
