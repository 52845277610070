import { DataTypes } from '../conditions/data-types';

export class Operator {

  value: string;
  display: string;
  csv: boolean;
  constructor(value: string, display: string, csv: boolean) {
    this.value = value;
    this.display = display;
    this.csv = csv;
  }

}

export class DateIntegerOperator extends Operator {

  relative: boolean;
  comparison: boolean;
  arithmetic: boolean;
  constructor(value: string, display: string, csv: boolean, relative: boolean, comparison: boolean, arithmetic: boolean) {
    super(value, display, csv);
    this.relative = relative;
    this.comparison = comparison;
    this.arithmetic = arithmetic;
  }

}

export const StringOperators = {
  EQ_IGNORECASE: new Operator('EQ_IGNORECASE', 'Equals', false),
  NEQ_IGNORECASE: new Operator('NEQ_IGNORECASE', 'Does not equal', false),
  ENDSWITH_IGNORECASE: new Operator('ENDSWITH_IGNORECASE', 'Ends with', false),
  STARTSWITH_IGNORECASE: new Operator('STARTSWITH_IGNORECASE', 'Starts with', false),
  LIKE: new Operator('LIKE', 'Matches', false),
  NOTLIKE: new Operator('NOTLIKE', 'Does not match', false),
  IN_IGNORECASE: new Operator('IN_IGNORECASE', 'In', true),
  NOTIN_IGNORECASE: new Operator('NOTIN_IGNORECASE', 'Is not in', true),
  ISNULL: new Operator('ISNULL', 'Is null', false),
  NOTISNULL: new Operator('NOTISNULL', 'Is not null', false)
};

export const IntegerOperators = {
  EQ: new DateIntegerOperator('EQ', 'Equals', false, true, false, false),
  NEQ: new DateIntegerOperator('NEQ', 'Does not equal', false, true, false, false),
  IN: new DateIntegerOperator('IN', 'In', true, true, false, false),
  NOTIN: new DateIntegerOperator('NOTIN', 'Is not in', true, true, false, false),
  GT: new DateIntegerOperator('GT', 'Greater than', false, true, false, false),
  GTEQ: new DateIntegerOperator('GTEQ', 'Greater than or equal to', false, true, false, false),
  LT: new DateIntegerOperator('LT', 'Less than', false, true, false, false),
  LTEQ: new DateIntegerOperator('LTEQ', 'Less than or equal to', false, true, false, false),
  ISNULL: new DateIntegerOperator('ISNULL', 'Is null', false, false, false, false),
  NOTISNULL: new DateIntegerOperator('NOTISNULL', 'Is not null', false, false, false, false),
  MINUS: new DateIntegerOperator('MINUS', 'subtract from', false, false, false, true),
  PLUS: new DateIntegerOperator('PLUS', 'add to', false, false, false, true),
  IS_COMPARE_NUMBER: new DateIntegerOperator('IS_COMPARE_NUMBER', 'Attribute Comparison', false, false, true, false)
};

export const ArrayOperators = {
  CONTAINS_IGNORECASE: new Operator('CONTAINS_IGNORECASE', 'Contains', false),
  NOTCONTAINS_IGNORECASE: new Operator('NOTCONTAINS_IGNORECASE', 'Does not contain', false),
  ISNULL: new Operator('ISNULL', 'Is null', false),
  NOTISNULL: new Operator('NOTISNULL', 'Is not null', false)
};

export const BooleanOperators = {
  EQ: new Operator('EQ', 'Equals', false),
  NEQ: new Operator('NEQ', 'Does not equal', false),
  ISNULL: new Operator('ISNULL', 'Is null', false),
  NOTISNULL: new Operator('NOTISNULL', 'Is not null', false)
};

export const DateOperators = {
  BEFORE: new DateIntegerOperator('BEFORE', 'Is before', false, true, false, false),
  AFTER: new DateIntegerOperator('AFTER', 'Is after', false, true, false, false),
  EQ: new DateIntegerOperator('EQ', 'Is', false, false, false, false),
  NEQ: new DateIntegerOperator('NEQ', 'Is not', false, false, false, false),
  LT: new DateIntegerOperator('LT', 'Is before', false, false, false, false),
  LTEQ: new DateIntegerOperator('LTEQ', 'Is on or before', false, false, false, false),
  GT: new DateIntegerOperator('GT', 'Is after', false, false, false, false),
  GTEQ: new DateIntegerOperator('GTEQ', 'Is on or after', false, false, false, false),
  ISNULL: new DateIntegerOperator('ISNULL', 'Is null', false, false, false, false),
  NOTISNULL: new DateIntegerOperator('NOTISNULL', 'Is not null', false, false, false, false),
  MINUS: new DateIntegerOperator('MINUS', 'subtract from', false, false, false, true),
  PLUS: new DateIntegerOperator('PLUS', 'add to', false, false, false, true),
  IS_COMPARE_TIME: new DateIntegerOperator('IS_COMPARE_TIME', 'Attribute Comparison', false, false, true, false),
  IS_BEFORE_OCCURANCE: new DateIntegerOperator('IS_BEFORE_OCCURANCE', 'Latest Occurrence', false, false, false, false)
};

export enum NullOperators {
  ISNULL = 'ISNULL',
  NOTISNULL = 'NOTISNULL'
}

export class RuleOperators {

  operators: Operator[];
  constructor(type: string) {
    switch (type.toUpperCase()) {
      case DataTypes.string: {
        this.operators = Object.values(StringOperators);
        break;
      }
      case DataTypes.number: {
        this.operators = Object.values(IntegerOperators);
        break;
      }
      case DataTypes.array: {
        this.operators = Object.values(ArrayOperators);
        break;
      }
      case DataTypes.boolean: {
        this.operators = Object.values(BooleanOperators);
        break;
      }
      case DataTypes.date: {
        this.operators = Object.values(DateOperators);
        break;
      }
    }
  }

  static getOperatorsForDatatype(datatype: string) {
    switch (datatype.toUpperCase()) {
      case DataTypes.string: {
        return StringOperators;
      }
      case DataTypes.number: {
        return IntegerOperators;
      }
      case DataTypes.array: {
        return ArrayOperators;
      }
      case DataTypes.boolean: {
        return BooleanOperators;
      }
      case DataTypes.date: {
        return DateOperators;
      }
    }
  }

}