import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { RequestInterceptor } from 'src/app/shared/http/request-interceptor';
import { WarningState } from 'src/app/shared/warning-state';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { TableModule, PaginationModule, ActionModule, LoadingIndicatorModule, CollapsiblePanelModule, NavSecondaryModule, ToggletipModule }
  from 'epsilon-blueprint';
import { BaseComponent } from '../client/base/base.component';
import { EntityStatesRoutingModule } from './entity-states-routing.module';
import { EntityStatesHomeComponent } from './entity-states-home/entity-states-home.component';
import { EntityStatesNavComponent } from './entitiystates-left-nav/entity-states-nav.component';
import { EntityStatesPropertiesComponent } from './entity-states-properties/entity-states-properties.component';
import { EntityStatesHeaderComponent } from './entity-states-header/entity-states-header.component';
import { EntityStatesConnectionsComponent } from './entity-states-connections/entity-states-connections.component';
import { EntityStatesSchemaComponent } from './entity-states-schema/entity-states-schema.component';
import { CoreUIModule } from '@epsilon/core-ui';

@NgModule({
    declarations: [
        EntityStatesHomeComponent,
        EntityStatesHeaderComponent,
        EntityStatesNavComponent,
        EntityStatesPropertiesComponent,
        EntityStatesSchemaComponent,
        EntityStatesConnectionsComponent
    ],
    imports: [
        EntityStatesRoutingModule,
        BaseTemplateModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        NgSelectModule,
        ReactiveFormsModule,
        DragDropModule,
        SharedModule,
        TableModule,
        PaginationModule,
        LoadingIndicatorModule,
        ActionModule,
        CollapsiblePanelModule,
        NavSecondaryModule,
        ToggletipModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        BaseTemplateModule,
        CoreUIModule
    ],
    providers: [
        BaseComponent,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        WarningState
    ]
})
export class EntityStatesModule { }
