export const ExclusionDetails
= {
  'PurchaseExclusion': [
    { 'value': 'Latest', 'type': 'TEXT' },
    { 'value': 'PURCHASES_IN_LAST_N_DAYS', 'type': 'TEXTBOX' },
    { 'value': 'purchase made less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_DAYS_FOR_PURCHASE_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_DAYS_FOR_PURCHASE_COUNT', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonCardExclusion': [
    { 'value': 'User already submitted Credit Card', 'type': 'TEXT' }
  ],
  'HourlyExclusion': [
    { 'value': 'Latest', 'type': 'TEXT' },
    { 'value': 'MSG_COUNT_IN_LAST_N_HOURS', 'type': 'TEXTBOX' },
    { 'value': 'messages sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_MSG_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_MSG_COUNT', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'WeeklyExclusion': [
    { 'value': 'More than', 'type': 'TEXT' },
    { 'value': 'MSG_COUNT_IN_LAST_N_DAYS', 'type': 'TEXTBOX' },
    { 'value': 'messages sent in last', 'type': 'TEXT' },
    { 'value': 'LAST_N_DAYS_FOR_MSG_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_DAYS_FOR_MSG_COUNT', 'type': 'SELECTBOX' }
  ],
  'ViewedExclusion': [
    { 'value': 'If no products have been viewed more than', 'type': 'TEXT' },
    { 'value': 'VIEWED_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedWishlistExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_WISHLIST_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_WISHLIST_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_WISHLIST_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedFavoritesExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_FAVORITE_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_FAVORITE_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_FAVORITE_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedSearchExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_SEARCH_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_SEARCH_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_SEARCH_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedCartExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_CART_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_CART_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_CART_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedCategoryExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_CATEGORY_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_CATEGORY_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_CATEGORY_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedProductExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_PRODUCT_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_PRODUCT_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_PRODUCT_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedHomePageExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_HOMEPAGE_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_HOMEPAGE_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_HOMEPAGE_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedCheckoutExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_CHECKOUT_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_CHECKOUT_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_CHECKOUT_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedTHHomePageExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_HOMEPAGE_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_HOMEPAGE_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_TH_HOMEPAGE_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedTHReservationDetailsExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_RESERVATION_DETAILS_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_RESERVATION_DETAILS_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_TH_RESERVATION_DETAILS_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedTHPropertyBrowseExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_PROPERTY_BROWSE_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_PROPERTY_BROWSE_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_TH_PROPERTY_BROWSE_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'ViewedTHSearchExclusion': [
    { 'value': 'Event source page visited less than', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_SEARCH_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'times in the last', 'type': 'TEXT' },
    { 'value': 'VIEWED_TH_SEARCH_IN_LAST_N_PERIOD', 'type': 'TEXTBOX' },
    { 'value': 'VIEWED_TH_SEARCH_IN_LAST_N_PERIOD', 'type': 'SELECTBOX' }
  ],
  'AbandonCheckoutExclusion': [
    { 'value': 'Latest Abandoned Checkout message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_CHECKOUT', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_CHECKOUT', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonCartExclusion': [
    { 'value': 'Latest Abandoned Cart message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_CART', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_CART', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonProductExclusion': [
    { 'value': 'Latest Abandoned Product message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_PRODUCT', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_PRODUCT', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonCategoryExclusion': [
    { 'value': 'Latest Abandoned Category message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_CATEGORY', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_CATEGORY', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonSearchExclusion': [
    { 'value': 'Latest Abandoned Search message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_SEARCH', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_SEARCH', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonPromotionExclusion': [
    { 'value': 'Latest Abandoned Promotion message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_PROMOTION', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_PROMOTION', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonWishlistExclusion': [
    { 'value': 'Latest Abandoned Wishlist message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_WISHLIST', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_WISHLIST', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonFavoritesExclusion': [
    { 'value': 'Latest Abandoned Favorites message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_FAVORITES', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_ABANDONED_FAVORITES', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonSignupExclusion': [
    { 'value': 'User already signed up', 'type': 'TEXT' }
  ],
  'ConfirmationExclusion': [
    { 'value': 'Latest', 'type': 'TEXT' },
    { 'value': 'CONFIRMATION_IN_LAST_N_DAYS', 'type': 'TEXTBOX' },
    { 'value': 'confirmation made less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_DAYS_FOR_CONFIRMATION_COUNT', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_DAYS_FOR_CONFIRMATION_COUNT', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'AbandonReservationDetailsExclusion': [
    { 'value': 'Latest Abandoned Reservation message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_TH_ABANDONED_RESERVATION_DETAILS', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_TH_ABANDONED_RESERVATION_DETAILS', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'THAbandonSearchExclusion': [
    { 'value': 'Latest Abandoned Search message sent less than', 'type': 'TEXT' },
    { 'value': 'LAST_N_HOURS_FOR_TH_ABANDONED_SEARCH', 'type': 'TEXTBOX' },
    { 'value': 'LAST_N_HOURS_FOR_TH_ABANDONED_SEARCH', 'type': 'SELECTBOX' },
    { 'value': 'before now', 'type': 'TEXT' }
  ],
  'VarianceExclusion': [
    { 'value': 'Product price is outside', 'type': 'TEXT' },
    { 'value': 'PERCENTAGE_VARIANCE', 'type': 'TEXTBOX' },
    { 'value': 'percentage range of the average department price', 'type': 'TEXT' }
  ],
  'BrandNameExclusion': [
    { 'value': 'Exclude product from model results if product brand is equal to', 'type': 'TEXT' },
    { 'value': 'DROP_BRAND_NAME', 'type': 'TEXTBOX' },
  ],
  'HighestPricedProductsFilter': [
    { 'value': 'Filter in highest 2 priced products if household income > $150,000', 'type': 'TEXT'}
  ],
  'LowestPricedProductsFilter': [
    { 'value': 'Filter in lowest 2 priced products if household income < $150,000', 'type': 'TEXT'}
  ]
};
