import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Program } from '../models/program';
import { environment } from '../../../environments/environment';
import { ProgramService } from './program.service';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';

@Injectable({
  providedIn: 'root'
})
export class PackagedProgramService extends ProgramService {

  host = environment.host;
  base = environment.base;
  programDetailsComponent = 'program-details/';
  programDetailsPath = 'configured/';

  constructor(public http?: HttpClient,
    public programService?: ProgramService) {
    super(http);
  }

  getProgramDetails(parentId: string, eventsource: string, programName: string) {
    const url = this.host + this.base + this.programPath + eventsource + '/' + programName;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get<Program>(url, options);
  }

  public getAllPackagedPrograms(parentId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + this.programPath ;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get<ServiceResponse>(url, options);
  }

}