import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { BaseComponent } from '../../client/base/base.component';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { USER_GROUP_SERVICE_TOKEN, USER_GROUP_CLASS_TOKEN } from 'src/app/shared/tokens';
import { UserGroupsComponent } from '../../client/user-roles/user-groups/user-groups.component';
import { PackagedUserGroupService } from 'src/app/shared/services/packaged-user-group.service';
import { UserGroupConfigurationComponent } from '../../client/user-roles/user-group-configuration/user-group-configuration.component';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { UserGroupDetails } from 'src/app/shared/models/user-groups/user-group-details';

@Component({
  selector: 'app-system-user-groups-configuration',
  templateUrl: './system-user-groups-configuration.component.html',
  providers: [{provide: USER_GROUP_SERVICE_TOKEN, useClass: PackagedUserGroupService},
              {provide: USER_GROUP_CLASS_TOKEN, useClass: UserGroupDetails}]
})
export class SystemUserGroupsConfigurationComponent extends BaseFormDirective {
  @ViewChildren(UserGroupConfigurationComponent) userGroupConfigurationComponent: QueryList<UserGroupConfigurationComponent>;
}