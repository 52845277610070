<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <p class="text-style-muted-1 m-0">Perform entity state lookup</p>
    </div>
  </div>
  <form [formGroup]="lookupForm">
    <div class="row mt-3">
      <div class="col-3">
        <coreui-form-field label="Company Id">
          <input coreUIInput formControlName="companyId" placeholder="Enter company id" />
        </coreui-form-field>
      </div>
      <div class="col-3">
        <coreui-form-field label="Key">
          <input coreUIInput formControlName="key" placeholder="Enter key" />
          <coreui-form-error>Please enter the key</coreui-form-error>
        </coreui-form-field>
      </div>
      <div class="col pt-4">
        <app-ae-button (click)="lookupEntityState()" [disabled]="lookupForm.invalid" buttonText="Lookup">
        </app-ae-button>
      </div>
    </div>
    <div class="row">
      <div class="col-6" *ngIf="isSubmitted">
        <coreui-form-field label="Entity State" *ngIf="entiyStateData && !isApiServiceCalled">
          <textarea id="entityStateRecord" rows="10" coreUIInput formControlName="entiyStateData"></textarea>
        </coreui-form-field>
        <coreui-load-mask class="py-4" [active]="true" *ngIf="isApiServiceCalled">
        </coreui-load-mask>
        <coreui-graphic-message class="py-2" label="No Results Found" graphic="noResults"
          *ngIf="!entiyStateData && !isApiServiceCalled">
          Sorry, we did not find any results.
        </coreui-graphic-message>
      </div>
    </div>
  </form>
</div>