import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as Ajv from 'ajv';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ASSOCIATION_API_FIELD_STATUS, ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER, ENTITY_TYPE, HAS_ASSOCIATIONS_IDENTIFIER, ID_IDENTIFIER }
  from 'src/app/shared/models/association/association-constants';
import { ConfiguredEntityAssociationParams }
  from 'src/app/shared/models/association/configured-entity-association-params';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { ConfiguredEvent, ConfiguredEventId, SupportedJSONSchemaVer }
  from 'src/app/shared/models/configured-event.model';
import { AssociationService } from 'src/app/shared/services/association.service';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { EventService } from 'src/app/shared/services/event.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { Pattern } from 'src/app/shared/pattern';
import { EntityType } from 'src/app/shared/entity-type';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { AssociationsTableBuilderService } from 'src/app/shared/services/associations-table-builder.service';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { Constants, OperationType } from '../../../shared/constants';
import { EntityCopyService } from 'src/app/shared/services/entity-copy.service';
import { EntityCopy } from 'src/app/shared/models/entity-copy.model';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';

@Component({
  selector: 'app-events-configuration',
  templateUrl: './events-configuration.component.html'
})
export class EventsConfigurationComponent extends BaseFormDirective implements OnInit, OnDestroy {

  public parentId: string;
  public isApiServiceCalled: boolean;
  public messages = Messages;
  public inputParams = {
    eventSourceProvider: '',
    eventName: '',
    action: ''
  };
  public operationFailure = false;
  public hasAssociation: boolean;
  public associationEntityId: string;
  public associationStatus: ConfiguredEntityAssociationParams;
  public isDeleteOperation: boolean;
  public configuredEvent: ConfiguredEvent;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private configuredEventSources: any;
  private configuredEvents: ConfiguredEvent[];
  private ajv = new Ajv({ allErrors: true });
  private configuredEventActiveInPrograms = '';
  private operationStatusMessage = '';


  constructor(private fb: UntypedFormBuilder,
    private eventSourceService: EventSourceService,
    public parentContextService: ParentContextService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private router: Router,
    private popupService: PopupMessageService,
    private associationService: AssociationService,
    private associationsTableBuilderService: AssociationsTableBuilderService,
    public authorizationService: AuthorizationService,
    private entityCopyService: EntityCopyService) {
    super();
    this.initInputParam(route);
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.getConfiguredEventSourceProviders();
    this.getConfiguredEvents();
    this.buildConfiguredEventForm();
  }

  canNavigateAway(): boolean {
    if (this.isDataSaved || this.isDeleteOperation || this.hasAssociation) {
      return true;
    }
    return !(this.inputParams.action === OperationType.ADD && this.isCreateFormUpdated() ||
        this.inputParams.action === OperationType.EDIT && this.isEditFormUpdated());
  }

  private isEditFormUpdated(): boolean {
    return this.configuredEvent.description !== this.configuredEntityForm.controls.eventDesc.value ||
        this.configuredEvent.eventSchema !== this.configuredEntityForm.controls.eventSchema.value;
  }

  private isCreateFormUpdated(): boolean {
    return this.configuredEntityForm.controls.eventName.value !== '' ||
        this.configuredEntityForm.controls.eventSourceProvider.value !== '' ||
        this.configuredEntityForm.controls.eventDesc.value !== '' ||
        this.configuredEntityForm.controls.eventSchema.value !== '';
  }

  initInputParam(route: ActivatedRoute): void {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.inputParams.action = route.snapshot.paramMap.get('action');
      this.inputParams.eventName = route.snapshot.paramMap.get('eventName');
      this.inputParams.eventSourceProvider = route.snapshot.paramMap.get('eventSourceProvider');
    });
  }

  buildConfiguredEventForm(): void {
    this.operationStatusMessage = 'Loading event';
    this.buildConfiguredEventFormToAdd();
    if (this.inputParams.action === OperationType.EDIT) {
      this.buildConfiguredEventFormToEdit();
    } else {
      this.inputParams.action = OperationType.ADD;
    }
  }

  buildConfiguredEventFormToAdd(): void {
    this.configuredEvent = this.buildEmptyConfiguredEvent();
    this.buildForm();
  }

  buildEmptyConfiguredEvent(): ConfiguredEvent {
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = '';
    configuredEventId.eventName = '';

    const configuredEvent: ConfiguredEvent = new ConfiguredEvent();
    configuredEvent.name = '';
    configuredEvent.id = configuredEventId;
    configuredEvent.description = '';
    configuredEvent.schema = '';

    return configuredEvent;
  }

  buildForm(): void {
    this.configuredEntityForm = this.fb.group({
      id: new UntypedFormControl(this.configuredEvent.id),
      eventName: new UntypedFormControl(this.configuredEvent.name,
        { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC), Validators.maxLength(100)] }),
      eventSourceProvider: new UntypedFormControl(this.configuredEvent.id.eventSourceName, { validators: Validators.required }),
      eventDesc: new UntypedFormControl(this.configuredEvent.description, { validators: Validators.maxLength(250) }),
      eventSchema: new UntypedFormControl(this.configuredEvent.schema, { validators: Validators.required, updateOn: 'blur' })
    });
  }

  patchForm(configuredEvent: ConfiguredEvent): void {
    this.configuredEntityForm.patchValue({
      id: configuredEvent.id,
      eventName: configuredEvent.name,
      eventSourceProvider: configuredEvent.id.eventSourceName,
      eventDesc: configuredEvent.description,
      eventSchema: configuredEvent.eventSchema
    });
  }

  buildConfiguredEventFormToEdit(): void {
    this.getConfiguredEvent();
    this.validateAssociationStatus();
  }

  getConfiguredEvent(): void {
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = this.inputParams.eventSourceProvider;
    configuredEventId.eventName = this.inputParams.eventName;
    this.isApiServiceCalled = true;
    this.eventService.getConfiguredEvent(this.parentId, configuredEventId)
      .pipe(takeUntil(this.destroyed$)).subscribe((res: ConfiguredEvent[]) => {
        this.isApiServiceCalled = false;
        const configuredEvent: ConfiguredEvent = res['result'];
        this.configuredEvent = new ConfiguredEvent();
        this.configuredEvent.id = configuredEvent.id;
        this.configuredEvent.name = configuredEvent.name;
        this.configuredEvent.description = configuredEvent.description === null ? '' : configuredEvent.description;
        this.configuredEvent.eventSchema = configuredEvent.schema;
        this.setConfiguredEvent(this.configuredEvent);
      }, error => {
        this.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.statusMessage);
      });
  }

  setConfiguredEvent(configuredEvent: ConfiguredEvent): void {
    this.patchForm(configuredEvent);
  }

  getConfiguredEventSourceProviders(): void {
    this.isApiServiceCalled = true;
    this.eventSourceService.getEventSources(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.configuredEventSources = (data['result'] as EventSource[]).filter(configuredEvent => configuredEvent['type'] === EntityType.SELF_SERVICE);
      this.isApiServiceCalled = false;
    }, () => {
      this.configuredEventSources = null;
      this.isApiServiceCalled = false;
    });
  }

  getConfiguredEvents(): void {
    this.eventService.getConfiguredEvents(this.parentId)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ConfiguredEvent[]) => {
        this.configuredEvents = serviceResponse['result'];
      }, () => {
      });
  }

  addConfiguredEvent(configuredEvent: ConfiguredEvent): void {
    this.isApiServiceCalled = true;
    this.eventService.addConfiguredEvent(this.parentId, configuredEvent)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.isApiServiceCalled = false;
        this.isDataSaved = true;
        this.isSaveClicked = false;
        this.popupService.showSuccessMessage('Event "' + this.configuredEntityForm.controls.eventName.value + '" successfully added', true);
        this.inputParams.action = OperationType.EDIT;
        this.router.navigateByUrl('events', {skipLocationChange: true}).then( () => {
          this.router.navigateByUrl('events/edit/' + this.configuredEvent.id.eventSourceName + '/' + this.configuredEvent.name);
        });
      }, error => {
        this.isDataSaved = false;
        this.isSaveClicked = false;
        this.isApiServiceCalled = false;
        if (error.status === 403) {
          this.popupService.showErrorMessage(error.error.statusMessage);
        } else {
          this.popupService.showErrorMessage(Messages.saveEventErrorMessage);
        }
      });
  }

  updateConfiguredEvent(configuredEventId: ConfiguredEventId, configuredEvent: ConfiguredEvent): void {
    this.isApiServiceCalled = true;
    this.eventService.updateConfiguredEvent(this.parentId, configuredEventId, configuredEvent)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.isApiServiceCalled = false;
        if(this.isEventSourceOrEventNameUpdated()) {
          this.router.navigateByUrl('events', { skipLocationChange: true }).then( () => {
            void this.router.navigate([`events/edit/${this.configuredEvent.eventSourceProvider}/${this.configuredEvent.name}`]);
          });
        }
        this.popupService.showSuccessMessage('Event "' + this.configuredEntityForm.controls.eventName.value + '" successfully updated', true);
      }, error => {
        this.isApiServiceCalled = false;
        this.isSaveClicked = false;
        this.isDataSaved = false;
        if (error.status === 403) {
          this.popupService.showErrorMessage(error.error.statusMessage);
        } else {
          this.popupService.showErrorMessage(Messages.updateEventErrorMessage);
        }
      });
  }

  saveConfiguredEvent(): void {
    this.isSaveClicked = true;
    if (!this.configuredEntityForm.valid) {
      this.popupService.showErrorMessage(Messages.requiredFields);
    } else {
      this.configuredEvent.name = this.configuredEntityForm.controls.eventName.value;
      this.configuredEvent.description = this.configuredEntityForm.controls.eventDesc.value;
      this.configuredEvent.schema = this.configuredEntityForm.controls.eventSchema.value;
      this.configuredEvent.schemaVersion = SupportedJSONSchemaVer.DRAFT_V7;
      this.configuredEvent.eventSourceProvider = this.configuredEntityForm.controls.eventSourceProvider.value;
      if (this.inputParams.action === OperationType.EDIT) {
        this.updateConfiguredEvent(this.configuredEvent.id, this.configuredEvent);
      } else {
        const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
        configuredEventId.eventSourceName = this.configuredEntityForm.controls.eventSourceProvider.value;
        configuredEventId.eventName = this.configuredEntityForm.controls.eventName.value;
        this.configuredEvent.id = configuredEventId;
        this.operationStatusMessage = 'Saving event';
        delete configuredEventId.eventName;
        this.addConfiguredEvent(this.configuredEvent);
      }
    }
  }

  public copyConfiguredEvent(): void {
    this.isApiServiceCalled = true;
    this.entityCopyService.copyEntity(this.parentId, EntityCopy.build(this.getCompositeKey(), Feature.CFG_EVENT))
      .pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.isApiServiceCalled = false;
        this.popupService.showSuccessMessage(`Copy Successful. New Event '${<string>res['result'].name}' successfully created.`);
      }, error => {
        this.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.result);
      });
  }

  private getCompositeKey(): string {
    return (this.configuredEntityForm.controls.eventSourceProvider.value + Constants.DB_KEY_SEPARATOR
      + this.configuredEntityForm.controls.eventName.value);
  }

  public isEventSourceOrEventNameUpdated(): boolean {
    return this.configuredEntityForm.controls.eventSourceProvider.dirty || this.configuredEntityForm.controls.eventName.dirty;
  }

  public showDeleteConfiguredEventAlert(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteConfiguredEvent,
      msg: Messages.deleteEventModalWarningMessage,
      msg2: [Messages.deleteEventModalConfirmationMessage]
    });
  }

  public handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteConfiguredEvent();
    }
  }

  deleteConfiguredEvent(): void {
    this.operationStatusMessage = 'Deleting event';
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = this.configuredEntityForm.controls.eventSourceProvider.value;
    configuredEventId.eventName = this.configuredEntityForm.controls.eventName.value;
    this.isApiServiceCalled = true;
    this.eventService.deleteConfiguredEvent(this.parentId, configuredEventId)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.isApiServiceCalled = false;
        this.isDeleteOperation = true;
        this.popupService.showDeleteMessage(`Event '${<string> this.configuredEntityForm.controls.eventName.value}' successfully deleted`);
        this.router.navigate(['events']);
      }, error => {
        this.isApiServiceCalled = false;
        if (error.status === 403) {
          this.popupService.setByResponse(error);
        } else {
          this.popupService.showErrorMessage(this.getOperationFailureMessage());
          this.operationFailure = true;
        }
      });
  }

  get eventName(): AbstractControl {
    return this.configuredEntityForm.get('eventName');
  }

  get eventSourceProvider(): AbstractControl {
    return this.configuredEntityForm.get('eventSourceProvider');
  }

  get eventSchema(): AbstractControl {
    return this.configuredEntityForm.get('eventSchema');
  }

  get eventDesc(): AbstractControl {
    return this.configuredEntityForm.get('eventDesc');
  }

  checkIfEventNameAlreadyExists(): void {
    const isAleradyTaken = this.configuredEvents.find(existingConfiguredEvent =>
      existingConfiguredEvent.name.toLowerCase() === (<string> this.configuredEntityForm.controls.eventName.value).toLowerCase());
    if (isAleradyTaken !== undefined) {
      this.configuredEntityForm.controls.eventName.setErrors({ 'alreadyExist': true });
    }
  }

  validateSchema(): void {
    try {
      if (!this.ajv.validateSchema(JSON.parse(this.configuredEntityForm.controls.eventSchema.value))) {
        this.configuredEntityForm.controls.eventSchema.setErrors({ 'invalidJsonSchema': true });
      }
    } catch (exception) {
      this.configuredEntityForm.controls.eventSchema.setErrors({ 'invalidJsonSchema': true });
    }
  }

  getOperationFailureMessage(): string {
    return Messages.eventOperationFailureMessage;
  }

  isEditMode(): boolean {
    return this.inputParams.action === OperationType.EDIT;
  }

  public setAssociationInProgress(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = true;
    this.operationStatusMessage = Messages.loadingAssociations;
  }

  public setAssociationDone(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = false;
    this.hasAssociation = event[HAS_ASSOCIATIONS_IDENTIFIER];
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  hasAssociations(): boolean {
    return this.hasAssociation;
  }

  hasOperationFailed(): boolean {
    return this.operationFailure || this.hasAssociations();
  }

  public getAssociationsBtnTxt(): string {
    return Messages.viewAssociationsBtnTxt;
  }

  public openAssociationsModal(): void {
    const entityType = 'CONFIGURED_EVENT';
    this.associationsTableBuilderService
      .withEntityType(entityType)
      .withConfiguredEntityAssociationParams(this.associationStatus)
      .buildAssociationTableDataAfterLookup()
      .subscribe(associationTableData => {
        if (associationTableData[ENTITY_TYPE] === entityType) {
          this.launchAssociationsModal(associationTableData.tableData);
        }
      });
    // Track association status - in progress
    this.associationsTableBuilderService.trackAssociationInProgress().subscribe($event => {
      this.setAssociationInProgress($event);
    });
    // Track association status - done
    this.associationsTableBuilderService.trackAssociationDone().subscribe($event => {
      if ($event[ENTITY_TYPE] !== entityType
        || !(ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER in $event)
        || !(HAS_ASSOCIATIONS_IDENTIFIER in $event)
        || !(ID_IDENTIFIER in $event)) {
        return;
      }
      this.setAssociationDone($event);
    });
  }

  private launchAssociationsModal(associationModalDetail: Record<string, any>): void {
    this.infoModal.launchModal({
      'title': associationModalDetail['title'],
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': associationModalDetail['data'],
        'tableConfig': {
          'properties': associationModalDetail['properties'],
          'isDataLoading': associationModalDetail['isDataLoading']
        }
      }
    });
  }

  private validateAssociationStatus(): void {
    this.hasAssociation = false;
    if (!this.isEditMode()) {
      this.hasAssociation = false;
    }
    this.isApiServiceCalled = true;
    this.associationService.getAssociations(this.parentId, ConfiguredEntityType.EVENT, this.inputParams.eventName)
      .subscribe(success => {
        if (ASSOCIATION_API_FIELD_STATUS in success['result']) {
          this.hasAssociation = success['result'][ASSOCIATION_API_FIELD_STATUS];
        }
        this.associationStatus = new ConfiguredEntityAssociationParams(null, this.parentId, this.inputParams.eventName,
          ConfiguredEntityType.EVENT, { EVENT_SOURCE: this.inputParams.eventSourceProvider });
        this.isApiServiceCalled = false;
      }, error => {
        this.isApiServiceCalled = false;
        this.hasAssociation = false;
        if ((!('result' in error.error)) || (error.error.statusMessage === 'FAILED')) {
          this.popupService.showErrorMessage(Messages.associationRequestErroredMessage);
        } else {
          this.popupService.showErrorMessage(error.error.statusMessage);
        }
      });
  }

}
