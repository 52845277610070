import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ae-badge',
  templateUrl: './ae-badge.component.html'
})
export class AEBadgeComponent {
  @Input() public elements: any [];

  constructor(
    private router: Router
  ) { }

}