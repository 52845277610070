import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from '../../client/base/base.component';
import { NgModule } from '@angular/core';
import { PackagedRolesService } from 'src/app/shared/services/packaged-roles.service';
import { UserRolesComponent } from '../../client/user-roles/roles/user-roles.component';
import { ROLES_SERVICE_TOKEN } from 'src/app/shared/tokens';
import { Type } from 'src/app/shared/models/roleType';
import { FormOnSaveAction } from 'src/app/shared/constants';

@Component({
    selector: 'app-roles-home',
    templateUrl: './packaged-roles-home.component.html',
    providers: [{provide: ROLES_SERVICE_TOKEN, useClass: PackagedRolesService}]
  })

  export class RolesHomeComponent extends BaseComponent implements OnInit{
    constructor(){
      super();
    }
  }