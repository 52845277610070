import { Injectable } from '@angular/core';
import { AttributeCondition } from '../models/custom-rule/conditions/attribute-condition';
import { AndCondition } from '../models/custom-rule/conditions/and-condition';
import { CustomRule } from '../models/custom-rule/custom-rule';
import { OrCondition } from '../models/custom-rule/conditions/or-condition';
import { ConditionType } from '../models/custom-rule/conditions/condition-type';
import { EventKey } from '../models/eventKey.model';
import { Condition } from '../models/custom-rule/conditions/condition';
import { ContainerConditionElement } from '../models/custom-rule/conditions/container-condition-element';
import { AttributeConditionElement } from '../models/custom-rule/conditions/attribute-condition-element';
import { DataTypes } from '../models/custom-rule/conditions/data-types';
import { Operator, RuleOperators, NullOperators } from '../models/custom-rule/operators/rule-operators';
import { ContainerCondition } from '../models/custom-rule/conditions/container-condition';
import { ValueType } from '../models/custom-rule/conditions/value-type';
import { ActionElement } from '../models/custom-rule/actions/action-element';
import { DropArea } from '../models/custom-rule/conditions/drop-area';
import { DisplayTextService } from './display-text.service';
import { ConfiguredEntityState } from '../models/entity-states/configured-entity-state';
import { SourceType } from '../models/custom-rule/conditions/source-type';
import { EntityStateSchema } from '../models/entity-states/entity-state-schema';
import { RuleThenClause } from '../models/custom-rule/then-clause/rule-then-clause';
import { RuleThenClauseOperation } from '../models/custom-rule/then-clause/operation/rule-then-clause-operation';
import { OperandType } from '../models/custom-rule/then-clause/operation/operand-type';
import { Argument } from '../models/custom-rule/then-clause/operation/argument';
import { ArgumentName } from '../models/custom-rule/then-clause/operation/argument-name';
import { EntityStateAttribute } from '../models/custom-rule/then-clause/operation/entity-state-attribute';
import { Duration } from 'luxon';
import { DurationUnits } from '../models/exclusion/duration-units';
import { EntityType } from '../models/entity-type';
import { Constants, RuleTypes } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class RuleBuilderService {

  public MAX_ALLOWED_ACTIONS = 5;
  public enityStateIdsUsedInRule: string[] = [];
  public entitystateSchemaList: any[] = [];

  constructor(private displyText: DisplayTextService) {}

  public buildConditionRule(ruleName: string, packageName: string, companyId: string,
    containerConditionElement: ContainerConditionElement, actions: ActionElement[],
    ruleThenClause: RuleThenClause, entityStateList: ConfiguredEntityState[]): CustomRule {
    const eventKey: EventKey = new EventKey();
    eventKey.eventName = ruleName;
    eventKey.eventSourceName = packageName;

    const customRule: CustomRule = new CustomRule();
    customRule.name = ruleName;
    customRule.packageName = packageName;
    customRule.eventKey = eventKey;
    customRule.companyId = companyId;
    customRule.actions = actions;
    customRule.thenClause = ruleThenClause;
    customRule.condition = this.buildCondition(containerConditionElement);
    this.enityStateIdsUsedInRule = [];
    this.getEntityStateIdFromContainer(containerConditionElement);
    const enityStateIdsUsedInRule: string[] = this.getEntityStateIdsUsedInThenClauseOperations(ruleThenClause.operations);
    this.enityStateIdsUsedInRule = this.enityStateIdsUsedInRule.concat(enityStateIdsUsedInRule);
    const enityStateIdsUsedInActions: string[] = this.getEntityStateIdsUsedInActions(actions, entityStateList);
    this.enityStateIdsUsedInRule = this.enityStateIdsUsedInRule.concat(enityStateIdsUsedInActions);
    this.getEntityStateSchemaList(entityStateList);
    customRule.entitySchemas = this.getEntityStateSchema();
    customRule.type = RuleTypes.CUSTOM;
    return customRule;
  }

  public initDurationDetails(condition: AttributeConditionElement): void {
    const jsonDuration = Duration.fromISO(condition.attributeCondition.values[3]).toObject();
    const jsonDurationUnit = Object.keys(jsonDuration).shift();
    condition.attributeCondition.values[4] = DurationUnits[jsonDurationUnit.toLocaleUpperCase()];
    condition.attributeCondition.values[3] = jsonDuration[jsonDurationUnit];
  }

  public treeDropDragOver(event, dropArea: DropArea, level : number): void {
    this.toggleDropArea(event, dropArea, level, true);
  }

  public treeDropDragLeave(event, dropArea: DropArea, level : number): void {
    this.toggleDropArea(event, dropArea, level, false);
  }

  public toggleDropArea(event, dropArea: DropArea, level: number, show: boolean): void {
    this.updateDropAreaStyles(event, this.getStylesForDropArea(dropArea, show, level), this.getStylesForDropArea(dropArea, !show, level));
    if (dropArea === DropArea.AFTER) {
      this.hideChildDropArea(event, show);
    }
    this.setDropAreaMessage(event, dropArea, level, show);
  }

  public getComparisonValue(condition: AttributeCondition): any[] {
    if (condition.dataType === DataTypes.date) {
      const durationJSON = Object.create({});
      durationJSON[condition.values[4]] = condition.values[3];
      const durationInISO = Duration.fromObject(durationJSON);
      condition.values[3] = durationInISO.toISO();
      condition.values.pop();
      return condition.values;
    } else {
      condition.values[3] = Number(condition.values[3]);
      return condition.values;
    }
  }

  public buildCondition(confCondition: ContainerConditionElement): Condition {
    const container: ContainerCondition = this.getContainerConditionOfType(confCondition.type);
    confCondition.conditions.forEach(nextConfCondition => {
      if (nextConfCondition.hasOwnProperty('conditions')) {
        const nestedCondition = this.buildCondition(nextConfCondition);
        container.add(nestedCondition);
      } else {
        const confAttributeCondition = nextConfCondition as AttributeConditionElement;
        container.add(this.transformAttributeConditionValue(confAttributeCondition));
      }
    });
    return container;
  }

  private getEntityStateIdFromContainer(containerConditionElement: ContainerConditionElement): void {
    containerConditionElement.conditions.forEach(nextConfCondition => {
      if (Object.prototype.hasOwnProperty.call(nextConfCondition, 'conditions')) {
        this.getEntityStateIdFromContainer(nextConfCondition);
      } else {
        const confAttributeCondition = nextConfCondition as AttributeConditionElement;
        if (confAttributeCondition.attributeCondition.sourceType === SourceType.ENTITY_STATE) {
          this.enityStateIdsUsedInRule.push(confAttributeCondition.attributeCondition.entityStateId);
        }
      }
    });
  }

  private getEntityStateSchemaList(entityStateList: ConfiguredEntityState[]) {
    this.entitystateSchemaList = entityStateList.map(item =>
      ({ id: item.id, name: item.name, key: item.entityStateKey, isSystem: (item.type === EntityType.PACKAGED ? true : false) }));
  }

  private getEntityStateSchema(): EntityStateSchema[] {
    const entityStateSchemaList = this.entitystateSchemaList.filter(
      entityStateSchema => this.enityStateIdsUsedInRule.includes(entityStateSchema.id));
    return entityStateSchemaList;
  }

  private getEntityStateIdsUsedInThenClauseOperations(thenClauseOperations: RuleThenClauseOperation[]): string[] {
    const entityStateIdsUsedInThenClauseOperations: string[] = [];
    const entityStateUpdateOperations: RuleThenClauseOperation[] = thenClauseOperations.filter(
      thenClauseOperation => thenClauseOperation.operandType === OperandType.ENTITY_STATE
    );
    entityStateUpdateOperations.forEach(entityStateUpdateOperation => {
      const entityStateAttributeArgument: Argument = entityStateUpdateOperation.arguments.find(argument => argument.name === ArgumentName.ATTRIBUTE);
      const entityStateAttribute: EntityStateAttribute = entityStateAttributeArgument.value as EntityStateAttribute;
      entityStateIdsUsedInThenClauseOperations.push(entityStateAttribute.entityStateId);
    });
    return entityStateIdsUsedInThenClauseOperations;
  }

  private getEntityStateIdsUsedInActions(actions: ActionElement[], entityStateList: ConfiguredEntityState[]): string[] {
    const entityStateIdsUsedInActions: string[] = [];
    actions.forEach((action: ActionElement) => {
      const entityStateIdsUsedInAction: string[] = this.getEntityStateIdsUsedInAction(action, entityStateList);
      if (entityStateIdsUsedInAction.length > 0) {
        entityStateIdsUsedInActions.push(...entityStateIdsUsedInAction);
      }
    });
    return entityStateIdsUsedInActions;
  }

  private getEntityStateIdsUsedInAction(action: ActionElement, entityStateList: ConfiguredEntityState[]): string[] {
    const entityStateIdsUsedInAction: string[] = [];
    entityStateList.forEach((entityState: ConfiguredEntityState) => {
      const serviceInput: string = action.actionSettingsInput['serviceInput'];
      const entityStateId = entityState.id;
      if (serviceInput.includes(entityStateId)) {
        entityStateIdsUsedInAction.push(entityStateId);
      }
    });
    return entityStateIdsUsedInAction;
  }

  private getContainerConditionOfType(conditionType: ConditionType): ContainerCondition {
    let containerCondition: ContainerCondition;
    const attributeConditions: AttributeCondition[] = [];
    switch (conditionType) {
      case ConditionType.AND: {
        containerCondition = new AndCondition(attributeConditions);
        break;
      }
      case ConditionType.OR: {
        containerCondition = new OrCondition(attributeConditions);
        break;
      }
    }
    return containerCondition;
  }

  private transformAttributeConditionValue(attributeConditionElement: AttributeConditionElement): AttributeCondition {
    const attributeCondition = attributeConditionElement.attributeCondition;
    const transformedAtrributeCondition : AttributeCondition = { ...attributeCondition };
    const inputValues: string = attributeCondition.values.toString();
    let values = [];
    if (attributeCondition.valueType === ValueType.ARRAY) {
      transformedAtrributeCondition.values = this.getComparisonValue(attributeCondition);
      return transformedAtrributeCondition;
    }
    if (attributeCondition.operator === NullOperators.ISNULL || attributeCondition.operator === NullOperators.NOTISNULL) {
      transformedAtrributeCondition.values = values;
      return transformedAtrributeCondition;
    }
    if (attributeConditionElement.isBeforeOccurrenceOperator()) {
      const inputDuration = attributeCondition.values[1];
      const inputDurationUnit = attributeCondition.values[2];
      let durationInISO = '';
      if (inputDuration && inputDurationUnit) {
        const durationJSON = Object.create({});
        durationJSON[inputDurationUnit] = inputDuration;
        durationInISO = Duration.fromObject(durationJSON).toString();
      }
      transformedAtrributeCondition.values = [];
      transformedAtrributeCondition.values.push(Constants.NOW);
      transformedAtrributeCondition.values.push(durationInISO);
      return transformedAtrributeCondition;
    }
    // If ValueType is ATTRIBUTE
    if (attributeCondition.valueType === ValueType.ATTRIBUTE) {
      transformedAtrributeCondition.values = attributeCondition.values;
      return transformedAtrributeCondition;
    }
    // If boolean
    if (attributeConditionElement.isBooleanDataType()) {
      transformedAtrributeCondition.values = this.parseBooleanValues(inputValues);
      return transformedAtrributeCondition;
    }
    // If relative date
    if (attributeConditionElement.isRelativeDateOperator()) {
      transformedAtrributeCondition.values = inputValues.split(',');
      return transformedAtrributeCondition;
    }
    // If absolute date
    if (attributeConditionElement.isAbsoluteDateOperator()) {
      transformedAtrributeCondition.values = this.parseDateValuesToMilliSecond(inputValues);
      return transformedAtrributeCondition;
    }
    // If csv
    const operatorsList = RuleOperators.getOperatorsForDatatype(attributeCondition.dataType);
    if ((<Operator>operatorsList[attributeCondition.operator]).csv) {
      values = inputValues.split(',');
    } else {
      values.push(inputValues);
    }
    // If number
    if (attributeCondition.dataType === DataTypes.number) {
      values = this.parseNumericValues(values);
    }
    // If array
    if (attributeCondition.dataType === DataTypes.array) {
      let transformedValues = [];
      if (attributeConditionElement.typeOfValue === DataTypes.boolean) {
        transformedValues = this.parseBooleanValues(values[0]);
      }
      if (attributeConditionElement.typeOfValue === DataTypes.number) {
        transformedValues = this.parseNumericValues(values);
      }
      if (attributeConditionElement.typeOfValue === DataTypes.string) {
        transformedValues = values;
      }
      values = transformedValues;
    }
    transformedAtrributeCondition.values = values;
    return transformedAtrributeCondition;
  }

  private parseBooleanValues(inputValue: string) {
    const values: boolean[] = [];
    const parsedInputValue: boolean = JSON.parse(inputValue.toLowerCase());
    values.push(parsedInputValue);
    return values;
  }

  private parseNumericValues(inputValues: string[]) {
    let values: number[] = [];
    values = inputValues.map(function(item) {
      return +item;
    });
    return values;
  }

  private parseDateValuesToMilliSecond(inputValues: string) {
    const values: string[] = [];
    const valueInMilliSeconds = Date.parse(inputValues).toString();
    values.push(valueInMilliSeconds);
    return values;
  }

  private setDropAreaMessage(event, dropArea: DropArea, level: number, showDropArea: boolean) {
    if (dropArea === DropArea.CHILD && level === 4) {
      event.event.target.lastElementChild.innerHTML = showDropArea ? this.displyText.RULE_MAX_NESTED_REACHED : '';
    } else if (dropArea === DropArea.ACTION && level >= this.MAX_ALLOWED_ACTIONS) {
      if (event.event.target.lastElementChild) {
        event.event.target.lastElementChild.innerHTML = showDropArea ? this.displyText.RULE_MAX_ACTION_REACHED : '';
      }
    }
  }

  private updateDropAreaStyles(event, stylesToApply: string [], stylesToRemove: string []) {
    stylesToRemove.forEach(cssClass => {
      event.event.target.classList.remove(cssClass);
    });

    stylesToApply.forEach(cssClass => {
      event.event.target.classList.add(cssClass);
    });
  }

  private getStylesForDropArea(dropArea: DropArea, showDropArea: boolean, level: number) {
    let stylesToApply: string [];
    if (showDropArea) {
      let paddingForChildDropArea = 'p-4';
      if (dropArea === DropArea.CHILD && level === 4) {
        paddingForChildDropArea = 'p-3';
      }
      stylesToApply = dropArea === DropArea.AFTER ? ['col-12', paddingForChildDropArea] : [paddingForChildDropArea];
    } else {
      stylesToApply = dropArea === DropArea.AFTER ? ['col-1', 'p-1'] : ['p-1'];
    }
    return stylesToApply;
  }

  private hideChildDropArea(event, hidden: boolean) {
    if (event.event.target.parentNode.children.length === 2 ) {
      event.event.target.parentNode.lastElementChild.hidden = hidden;
    }
  }

}
