<form novalidate [formGroup]="configuredAPIActionForm">
  <div class="form-group">
    <coreui-form-field label="API URL" for="endPoint">
      <input coreUIInput 
      type="text" 
      id="endPoint"
      formControlName="endPoint"
      placeholder="Enter the API URL for this action"/>
      <coreui-form-error  *ngIf="endPoint.invalid && isSaveClicked">
        <div *ngIf="endPoint.errors.required">
         Please enter the API URL for this action. URLs should start with ‘http://’ or ‘https://’ and contain at least one character.
        </div>
        <div *ngIf="endPoint.errors.pattern">
          Your API URL is invalid. Please check your URL for errors. URLs should start with ‘http://’ or ‘https://’ and contain atleast one character.
        </div>
        <div *ngIf="endPoint.errors.invalidPlaceholderSyntax">
          {{getinvalidPlaceHolderStatusMsg('endPoint')}}
       </div>
      </coreui-form-error>
    </coreui-form-field>
  </div>
</form>