import { Role } from '../permission/role/role';
import { DatePipe } from '@angular/common';
import { Type } from '../roleType';
import { RolesService } from '../../services/roles-service-interface';
import { ROLES_SERVICE_TOKEN } from '../../tokens';
import {Inject} from '@angular/core';


export class ConfiguredRoleTableRow {

  public id: string;
  public name: string;
  public description: string;
  public userGroups: string;
  public createDate: string;
  public modifiedDate: string;
  public modifiedBy: string;
  public routerLink: string;
  public type: Type;

  datePipe: DatePipe = new DatePipe('en-US');

  constructor(configuredRole: Role, @Inject(ROLES_SERVICE_TOKEN) private rolesService: RolesService) {
    if (configuredRole !== undefined) {
      this.description = this.description === null ? '' : this.description;
      this.id = configuredRole.id;
      this.name = configuredRole.name;
      this.description = configuredRole.description === null ? '' : configuredRole.description;
      this.type = configuredRole.parentId ? Type.CLIENT : Type.GLOBAL ;
      this.createDate = this.datePipe.transform(new Date(configuredRole.createdDate), 'MM/dd/yyyy hh:mm aaaa');
      this.modifiedDate = this.datePipe.transform(new Date(configuredRole.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
      this.modifiedBy = configuredRole.modifiedBy;
      this.routerLink = `${this.rolesService.getNavPath()}${this.type.toLowerCase()}/edit/${configuredRole.id}`;
    }
  }

}
