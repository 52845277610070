import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Action } from 'src/app/shared/constants';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { APIUser } from 'src/app/shared/models/user-roles/api-user';
import { Messages } from 'src/app/shared/message';
import { UserStatus } from 'src/app/shared/models/user-status';
import { ModalComponent } from '@epsilon/core-ui';
import { UserGroupInfo } from '../../../../shared/models/permission/user/user-group-info';
import { AuthorizationService } from '../../../../shared/services/authorization-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfiguredUserGroupService } from '../../../../shared/services/configured-user-group.service';
import { ParentContextService } from '../../../../shared/services/parent-context.service';
import { DatePipe } from '@angular/common';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { Pattern } from 'src/app/shared/pattern';

@Component({
  selector: 'app-api-user-configuration',
  templateUrl: './api-user-configuration.component.html',
  styleUrls: ['./api-user-configuration.component.scss']
  })

export class ApiUserConfigurationComponent implements OnInit {

  @Input()
  public action: string;
  @Input()
  public apiUsers: APIUser[] = [];
  @Input()
  public id: string;
  @Output()
  private isModalDisplayed = new EventEmitter<any>();
  @Output()
  private apiUser = new EventEmitter<any>();

  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  @ViewChild(CoreuiModalWarningComponent)
  public warningModal: CoreuiModalWarningComponent;
  public userApiFormGroup: UntypedFormGroup;
  public formBuilder: UntypedFormBuilder;
  public apiUserToEdit: APIUser;
  public messages = Messages;
  public isReadOnly = false;
  public isSaveClicked = false;
  public parentId: string;
  public isApiServiceCalled: boolean;
  public operationStatusMessage: string;
  private isModalShown: boolean;

  public constructor(
    formBuilder: UntypedFormBuilder,
    public parentContextService: ParentContextService,
    public changeDetector: ChangeDetectorRef,
    public authorizationService?: AuthorizationService) {
    this.formBuilder = formBuilder;
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.buildAPIUserForm();
    this.launchBasicModal();
  }

  public getModalTitle(): string {
    return this.action === Action.CREATE ? 'Add' : 'Edit';
  }

  public onSave(): void {
    this.isSaveClicked = true;
    this.saveApiUser();
  }

  public closeBasicModal(): void {
    if (this.action === Action.CREATE && this.isSaveClicked === true) {
      void this.basicModal.hide();
      this.isModalShown = false;
      this.isModalDisplayed.emit(this.isModalShown);
      this.isSaveClicked = false;
    } else if (this.action === Action.CREATE && this.isSaveClicked === false) {
      this.warningModal.launchUnsavedChangesWarningModal().subscribe( result => {
        if (result === true) {
          void this.basicModal.hide();
          this.isModalShown = false;
          this.isModalDisplayed.emit(this.isModalShown);
          this.isSaveClicked = false;
        }
      });
    } else {
      void this.basicModal.hide();
      this.isModalShown = false;
      this.isModalDisplayed.emit(this.isModalShown);
      this.isSaveClicked = false;
    }
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  private saveApiUser() {
    if ((this.validateBlankValue(this.userApiFormGroup.controls.email.value) && this.isMatchingPattern(this.userApiFormGroup.controls.email.value, Pattern.ALPHA_NUMERIC_EMAIL)) &&
         (this.validateBlankValue(this.userApiFormGroup.controls.id.value) && this.isMatchingPattern(this.userApiFormGroup.controls.id.value, Pattern.EMAIL)) &&
         this.isMatchingPattern(this.userApiFormGroup.controls.password.value, Pattern.PASSWORD_VALIDATION) && this.validateBlankArray(this.userApiFormGroup.controls.secretQuestion.value) && this.validateBlankArray(this.userApiFormGroup.controls.secretAnswer.value)) {
      this.apiUserToEdit = this.getApiUser(this.apiUserToEdit);
      this.apiUser.emit({ action: this.action, apiUser: this.apiUserToEdit });
      this.closeBasicModal();
    } else {
      switch (false) {
        case this.isMatchingPattern(this.userApiFormGroup.controls.id.value, Pattern.EMAIL):
          this.userApiFormGroup.controls.id.setErrors({ 'pattern': true });
          break;
        case this.isMatchingPattern(this.userApiFormGroup.controls.password.value, Pattern.PASSWORD_VALIDATION):
          this.userApiFormGroup.controls.password.setErrors({ 'pattern': true });
          break;
        case this.isMatchingPattern(this.userApiFormGroup.controls.email.value, Pattern.ALPHA_NUMERIC_EMAIL):
          this.userApiFormGroup.controls.email.setErrors({ 'pattern': true });
          break;
        case this.validateBlankValue(this.userApiFormGroup.controls.secretQuestion.value):
          this.userApiFormGroup.controls.secretQuestion.setErrors({ 'required': true });
          break;
        case this.validateBlankArray(this.userApiFormGroup.controls.secretAnswer.value):
          this.userApiFormGroup.controls.secretAnswer.setErrors({ 'required': true });
      }
      this.userApiFormGroup.markAllAsTouched();
    }
  }

  private getApiUser(apiUser: APIUser): APIUser {
    apiUser.id = this.userApiFormGroup.get('id').value;
    apiUser.email = this.userApiFormGroup.get('email').value;
    apiUser.password = this.userApiFormGroup.get('password').value;
    apiUser.secretQuestion = this.userApiFormGroup.get('secretQuestion').value;
    apiUser.secretAnswer = this.userApiFormGroup.get('secretAnswer').value;
    return apiUser;
  }

  private buildAPIUserForm(): void {
    this.buildAPIUserFormToAdd(this.buildEmptyApiUserDetails());
    if (this.action === Action.CREATE) {
      this.apiUserToEdit = new APIUser();
    }
    if (this.action === Action.EDIT) {
      this.apiUserToEdit = this.apiUsers.find(
        nextApiUser => nextApiUser.id === this.id);
      this.patchAPIUserConfigurationForm();
    }
  }

  private patchAPIUserConfigurationForm(): void {
    const patch = {
      id: this.apiUserToEdit.id
    };
    this.userApiFormGroup.patchValue(patch);
    this.userApiFormGroup.disable();
  }

  private validateBlankValue(controlValue: string): boolean {
    if (controlValue === null) {
      return false;
    }
    if (controlValue.length === 0) {
      return false;
    }
    if (controlValue.trim() === '') {
      return false;
    }
    return true;
  }

  private validateBlankArray(controlValue: Array<Object>) {
    if (controlValue === null) {
      return false;
    }
    if (controlValue.length === 0) {
      return false;
    }
    return true;
  }

  private isMatchingPattern(input: string, pattern: string) {
    if (input === null) {
      return true;
    }
    return input.match(pattern) !== null;
  }

  private buildAPIUserFormToAdd(apiUser: APIUser): void {
    this.userApiFormGroup = this.formBuilder.group({
      id: new UntypedFormControl(apiUser.id),
      email: new UntypedFormControl(apiUser.email, { validators: [Validators.required] }),
      secretQuestion: new UntypedFormControl(apiUser.secretQuestion, { validators: [Validators.required] }),
      secretAnswer: new UntypedFormControl(apiUser.secretAnswer, { validators: [Validators.required] }),
      password: new UntypedFormControl(apiUser.password, { validators: [Validators.required] })
    });
  }

  private buildEmptyApiUserDetails(): APIUser {
    const apiUser = new APIUser();
    apiUser.id = null;
    apiUser.userName = null;
    apiUser.email = null;
    apiUser.secretQuestion = null;
    apiUser.secretAnswer = null;
    apiUser.password = null;
    return apiUser;
  }

}