import { AbstractControl } from '@angular/forms';

export class AllocationValidator {

  static numeric(control: AbstractControl) : {[key: string]: any} | null {
    const val = control.value;
    if (val === null || val === '') {
      return null;
    }
    if (val !== 100) {
      return { 'invalidNumber': true };
    }
    return null;
  }

}