import { AllocationEntity } from './allocation-entity';

export class Allocation {

  public allocationEntities: AllocationEntity[];
  public percentage: number;

  constructor(allocationEntities: AllocationEntity[], percentage) {
    this.allocationEntities = allocationEntities;
    this.percentage = percentage;
  }

}