<div class="row horizontal-scrollable">
  <div class="col">
    <bp-landing-page-header headingText="Support"></bp-landing-page-header>
    <coreui-tab-group coreUITabRouterGroup>
      <coreui-tab *ngIf="authorizationService.isAllowed('SUPPORT', ['READ'])" routerLink="./imslookup"
        label="IMS Lookup" [active]="true"></coreui-tab>
      <coreui-tab *ngIf="authorizationService.isAllowed('SUPPORT', ['READ'])" routerLink="./entitystatelookup"
        label="Entity State Lookup" [active]="true"></coreui-tab>
      <coreui-tab *ngIf="authorizationService.isAllowed('SUPPORT', ['READ'])" routerLink="./activitylookup"
        label="Activity Lookup" [active]="true"></coreui-tab>
      <coreui-tab *ngIf="authorizationService.isAllowed('SUPPORT', ['CREATE'])" routerLink="./imsinsert"
        label="IMS Insert" [active]="true"></coreui-tab>
      <coreui-tab *ngIf="authorizationService.isAllowed('SUPPORT', ['READ'])" routerLink="./rmncontents"
        label="{{ entityContentTabLabel }}" [active]="true"></coreui-tab>
    </coreui-tab-group>
    <router-outlet></router-outlet>
  </div>
</div>
