<!-- TODO: come back to typography here -->
<div class="container-fluid">
  <div class="row u-pt-25 mb-3">
    <div class="col">
      <app-ae-button
        buttonText="Back to Programs"
        iconPosition="left" 
        icon="chevronLeft"
        [routerLink]="'/programs'"
      ></app-ae-button>
    </div>
    <div class="col">
      <app-ae-button class="close" icon="close" buttonType="icon-large" (click)="openProgramHome();">
      </app-ae-button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col text-center">
      <h1>What kind of program do you want to create?</h1>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col text-center">
      <h2>Select a program template from the list of eCommerce programs below</h2>
    </div>
  </div>
  <div class="row mb-3 tabs">
    <div class="col text-center">
      <span class="border-bottom border-dark pb-4" role="tablist" aria-label="Bundle Tabs">
        <button role="tab" type="button" class="tab"
        *ngFor="let bundle of availableBundles"
                (click)="onSelect(bundle.Id)"
                [class.active]="bundle.Id===currentSelection"
                [attr.aria-selected]="bundle.Id===currentSelection"
                [attr.aria-controls]="getHtmlTabPanelId(bundle.Id)"
                [id]="bundle.Id"
                [attr.tabindex]="bundle.Id===currentSelection?0:-1"
        >{{bundle.DisplayName}}</button>
      </span>
    </div>
  </div>
  <app-ae-loading-indicator [isLoading]="isApiCallInProgress" [type]="'loading bar'"></app-ae-loading-indicator>
  <div role="tabpanel" tabindex="0" [attr.aria-labelledby]="currentSelection" [id]="getHtmlTabPanelId(currentSelection)">
    <div class="row mt-5">
      <div class="col-12 col-md-6 mb-4" *ngFor="let program of programsByBundle">
        <div [class.card]="!program.selected && !program.disabled" [class.card-disabled]="program.disabled" [class.card-selected]="program.selected" (click) = "selectProgram(program);">
          <div class="card-body py-3">
            <p class="text-color-secondary font-weight-semi-bold mb-3">{{getEventSourceDisplayName(program.eventSourceName)}}</p>
            <p class="card-program-name mb-3">{{program.name}}</p>
            <p class="card-text text-color-secondary">{{program.description}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mb-5">
      <div class="col-4 justify-content-end d-flex">
        <app-ae-button [buttonText]="'Cancel'"  (click)="openProgramHome()"></app-ae-button>
        <app-ae-button [buttonText]="'Create'" class="ml-2" (click)="proceedToProgramConfiguration()" [disabled]="!selectedProgram"></app-ae-button>
      </div>
    </div>
  </div>
</div>
