import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'delay'
})
export class DelayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let delay = '';
    const hr = Math.floor(value / 60);
    const min = value % 60;
    if (hr > 0) {
      delay += hr + (hr === 1 ? ' hour' : ' hours');
      if (min > 0) {
        delay += ' and ';
      }
    }
    if (min > 0) {
      delay += min + (min === 1 ? ' minute' : ' minutes');
    }
    return delay;
  }

}