<app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
<div class="container-fluid my-3">
    <div class="row">
        <div class="col-10">
            <p class="text-style-muted-1 m-0 pb-3" *ngIf="operation==='CREATE'"> {{ messages.insertRMNDataLabel }} </p>
            <p class="text-style-muted-1 m-0 pb-3" *ngIf="operation==='EDIT'">{{ messages.updateRMNDataLabel }}</p>
        </div>
        <div class="col-2">
            <span
                class="text-style-action"
                buttonType="text-primary"
                icon="chevronLeft"
                iconPosition="left"
                routerLink="/client/support/rmncontents"
            >&lt; {{ messages.backRMNContentText }}</span>
        </div>
    </div>
    <form class="Core-Form" [formGroup]="entityContentForm" (ngSubmit)="entityContentInsertRecord(formGroupDirective)"
        #formGroupDirective="ngForm">
        <coreui-form-field *ngIf="operation==='CREATE'" label="{{messages.companyId}}">
            <input coreUIInput name="companyId" formControlName="companyId" placeholder="{{messages.companyIdPlaceHolder}}" />
        </coreui-form-field>
        <coreui-form-field  *ngIf="operation==='EDIT'" label="{{messages.companyId}}">
            <input coreUIInput name="companyId" formControlName="companyId" />
        </coreui-form-field>
        <coreui-form-field *ngIf="operation==='CREATE'" label="{{messages.contentId}}">
            <input coreUIInput name="contentId" formControlName="contentId" placeholder="{{messages.contentIdPlaceHolder}}" />
            <coreui-form-error>
                <div *ngIf="entityContentForm.get('contentId').hasError('contentIdExists')">
                    {{messages.contentIdExists}}
                </div>
                <div>{{messages.contentIDRequired}}</div>
            </coreui-form-error>
        </coreui-form-field>
        <coreui-form-field *ngIf="operation==='EDIT'" label="{{messages.contentId}}">
            <input coreUIInput name="contentId" formControlName="contentId" />
            <coreui-form-error>{{messages.contentIdRequired}}</coreui-form-error>
        </coreui-form-field>
        <coreui-form-field *ngIf="operation==='CREATE'" label="{{messages.content}}">
            <input type="file" name="image" formControlName="image" placeholder="{{messages.contentPlaceHolder}}"
            (change)="fileSelect($event)" />
            <coreui-form-error>
                <div *ngIf="submitted && !entityContentForm.get('image').hasError('invalidFileType')">
                    {{messages.imageRequired}}
                </div>
                <div *ngIf="entityContentForm.get('image').hasError('invalidFileType')">
                    {{messages.uploadValidImage}}
                </div>
            </coreui-form-error>
    </coreui-form-field>
        <coreui-form-field *ngIf="operation==='EDIT'" label="{{messages.contentUrl}}">
            <input coreUIInput name="contentUrl" formControlName="contentUrl" />
            <coreui-form-error *ngIf="contentUrl.invalid">
                <div *ngIf="contentUrl.errors.pattern">{{messages.contentUrlError}}</div>  
                <div *ngIf="contentUrl.errors.required">{{messages.contentUrlRequired}}</div>
            </coreui-form-error>
        </coreui-form-field>
        <coreui-form-field label="{{messages.clickThroughUrl}}">
            <input coreUIInput name="clickThroughUrl" formControlName="clickThroughUrl"
                placeholder="{{messages.clickThroughUrlPlaceHolder}}" />
            <coreui-form-error *ngIf="clickThroughUrl.invalid">
                <div *ngIf="clickThroughUrl.errors.pattern">{{messages.clickThroughUrlError}}</div>  
                <div *ngIf="clickThroughUrl.errors.required">{{messages.clickThroughUrlRequired}}</div>
            </coreui-form-error>
        </coreui-form-field>
        <coreui-form-field label="{{messages.budget}}">
            <input coreUIInput name="budget" formControlName="budget" placeholder="{{messages.budgetPlaceHolder}}" />
            <coreui-form-error>{{messages.budgetRequired}}</coreui-form-error>
        </coreui-form-field>
        <coreui-form-field label="{{messages.expiration}}">
            <coreui-date-picker [minDate]="minDate" formControlName="expirationTime"></coreui-date-picker>
        </coreui-form-field>
        <div *ngIf="authorizationService.isAllowed('SUPPORT', ['UPDATE'])">
            <app-ae-button *ngIf="operation==='CREATE'" buttonType="submit" buttonText="{{messages.insertButton}}"></app-ae-button>
            <app-ae-button *ngIf="operation==='EDIT'" buttonType="submit" buttonText="{{messages.updateButton}}"></app-ae-button>
        </div>
    </form>
</div>