<div class="row my-1" *ngIf="!readOnly">
  <div class="col">
    <h4 class="text-style-muted-1">Drag and drop Exclusion to start building your program.</h4>
    <div class="drop-list" id="'exclusionDropArea'" coreUIDropList [data]="[]" connectedTo="'exclusionDragArea'" (dropped)="onDropped($event)"></div>
  </div>
</div>
<ng-container *ngFor="let configuredExclusion of programExclusions; let configuredExclusionIndex = index; let lastConfiguredExclusion = last;">
  <div class="row my-2">
    <div class="col">
      <app-custom-program-exclusion-configuation [configuredExclusion]="configuredExclusion" [readOnly]="readOnly" (deleteExclusionConfiguration)="deleteExclusionConfiguration(configuredExclusionIndex)"></app-custom-program-exclusion-configuation>
    </div>
  </div>
  <div class="row" *ngIf="programExclusions.length > 1 && !lastConfiguredExclusion">
    <div class="col">
      <span class="bg-dark text-white px-1">OR</span> 
    </div>
  </div>
</ng-container>
