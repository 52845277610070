import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-selector-icon',
  templateUrl: './selector-icon.component.html',
  styleUrls: ['./selector-icon.component.scss']
})
export class SelectorIconComponent implements OnInit {

  @Input()
  public selected: string;

  @Input()
  public name: string;

  @Input()
  public id: string;

  @Input()
  public imageStyle: string;

  constructor() { }

  ngOnInit() {
  }

  getImage(id) {
    const imageName = this.getImageName(id.replace(/\s/g, ''));
    const imageLocation = '../../../../../assets/img/' + imageName + '.PNG';
    return imageLocation;
  }

  private getImageName(id: string) {
    const availableImages: string[] = ['Conversant', 'Custom', 'Harmony', 'Shopify'];
    let imageName: string = availableImages.find(availableImage => availableImage === id);
    if (imageName === undefined) {
      imageName = 'NoImage';
    }

    return imageName;
  }

}
