export class TestDataSet {

  eventSource: string;
  event: string;
  eventAttribute: string;
  sampleEvent: any;
  testData: string;
  eventOptions: string[];
  isPersisted: boolean;
  isInvalidEventAttribute: boolean;
  isTestDataCountExceed: boolean;
  eventSourceName: string;


  constructor(eventSource?: string, event?: string, eventAttribute?: string, testData?: string, isPersisted?: boolean, eventSourceName?: string, isInvalidEventAttribute?: boolean) {
    this.eventSource = eventSource ? eventSource : '';
    this.event = event ? event : '';
    this.eventAttribute = eventAttribute ? eventAttribute : '';
    this.testData = testData ? testData : '';
    this.isPersisted = isPersisted ? isPersisted : false;
    this.eventSourceName = eventSourceName ? eventSourceName : '';
    this.isInvalidEventAttribute = isInvalidEventAttribute ? isInvalidEventAttribute : false;
  }

  isEventAttributeExist(dataset): boolean {
    return (this.eventSource === dataset.eventSource
      && this.event === dataset.event && this.eventAttribute === dataset.eventAttribute);
  }

  public validateTestDataCount() {
    this.isTestDataCountExceed = false;
    if (this.testData.split(',').length > 40) {
      this.isTestDataCountExceed = true;
    }
  }

  public isValidTestDataset() : boolean {
    return this.eventSource !== '' && this.event !== ''
    && this.eventAttribute !== '' && this.testData !== ''
    && !this.isTestDataCountExceed && !this.isInvalidEventAttribute;
  }

}