import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';
import { EntityStateLookup } from '../models/entity-state-lookup.model';

@Injectable({
  providedIn: 'root'
})
export class EntityStateLookupService {

  host = environment.host;
  base = environment.base;
  lookUpUrl = 'entitystate/lookup';

  constructor(private http: HttpClient) { }

  public fetchEntityStateLookUpRecord(requestBody: EntityStateLookup): Observable<ServiceResponse> {
    const url = this.host + this.base + this.lookUpUrl;
    return this.http.post<ServiceResponse>(url, requestBody);
  }

}