import { Constants, RuleTypes } from '../../constants';

export class DroolsRule {

  ruleId: string;
  actions: string;
  templateName: string;
  type: RuleTypes;

  public build(ruleId: string, eventType: string, templateName: string): DroolsRule {
    this.ruleId = ruleId + Constants.DB_KEY_SEPARATOR + eventType;
    this.templateName = templateName;
    this.type = RuleTypes.DROOLS;
    this.actions = '[]';
    return this;
  }

}