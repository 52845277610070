<coreui-modal #basicModal>
  <ng-template #titleTemplate>
    <h2 class="text-style-1 mb-0">Add Templates</h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <form [formGroup]="templateForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <p class="mb-0 p-3 border border-color-secondary">
              Send <span class="font-weight-bold">{{serviceName}}</span> message for business unit <span class="font-weight-bold">{{businessUnitId}}</span> using
            </p>
          </div>
        </div>
        <ng-container formArrayName="allocations">
          <ng-container [formGroupName]="allocationIndex" *ngFor="let allocation of allocationsFormArray.controls; let allocationIndex=index">
            <div class="row mt-4">
              <div class="col-8">
                <ng-container formArrayName="allocationEntities">
                  <ng-container [formGroupName]="allocationEntityIndex" *ngFor="let allocationEntity of allocation.controls.allocationEntities.controls; let allocationEntityIndex=index">
                    <label for="templateId_{{allocationIndex}}">Template ID</label>
                    <input type="text" class="form-control" formControlName="value" id="templateId_{{allocationIndex}}" (change)="findDuplicateTemplateIds()"
                           placeholder="Enter a template ID" (keydown.space)="$event.preventDefault();"  required/>
                    <div *ngIf="allocationsFormArray.controls.length > 1 && allocationIndex == allocationsFormArray.controls.length - 1" class="form-check form-check-inline mt-2">
                      <input class="form-check-input" id="blanktemplate" type="checkbox" [checked]="isBlankTemplate"
                             (change)="toggleBlankTemplate($event, allocationIndex); findDuplicateTemplateIds()"/>
                      <label class="form-check-label" for="blanktemplate">Do not send</label>
                    </div>
                    <div class="text-danger" *ngIf="areTemplateIdUnique(allocationEntity.controls.value.value, allocationIndex)">
                      This ID matches with one or more added templates. Please enter a unique template ID.
                    </div>
                    <div class="text-danger" *ngIf="validateAllocationEntityType(allocationEntity, allocationIndex)">
                      Please enter a template ID
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="col-2 px-0">
                <label for="percentage_{{allocationIndex}}">Allocation</label>
                <div class="input-group">
                  <input type="text" class="form-control"  formControlName="percentage" aria-describedby="percent+allocationIndex" id="percentage_{{allocationIndex}}" (keyup)="isTotalPercentage()"
                         placeholder="Percent" maxlength="3" onkeypress="return event.charCode >= 48 && event.charCode <= 57" required/>
                  <div class="input-group-append">
                <span class="input-group-text border-left-0" id="percent+allocationIndex">
                  <i class="fa fa-percent" aria-hidden="true"></i>
                </span>
                  </div>
                </div>
                <div class="text-danger" *ngIf="allocation.controls.percentage.value > 100 || (allocation.controls.percentage.touched && allocation.controls.percentage.value == '')">
                  Please enter a percentage (1-100%)
                </div>
              </div>
              <div class="col-auto mt-4" *ngIf="allocationIndex != 0">
                <app-ae-button [buttonText]="'Remove'" buttonType="micro-feedback-error-secondary" (click)="removeTemplates(allocationIndex);isTotalPercentage();findDuplicateTemplateIds()"></app-ae-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="allocationsFormArray.controls.length < 5">
          <div class="row">
            <div class="col">
              <app-ae-button [buttonText]="'Add another template'" buttonType="text-primary" class="mt-3" icon="add" iconPosition="left" [ariaLabel]="'Percent'" (click)="addAnotherAllocation()"></app-ae-button>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col">
            <div class="border-top border-color-secondary pt-3 my-3">
              <div class="row">
                <div class="col-8">
                  <app-ae-button [buttonText]="'Split Equally'" buttonType="secondary" (click)="splitEqually();isTotalPercentage()"></app-ae-button>
                </div>
                <div class="col-auto px-0">
                  <p class="mb-0">Total</p>
                  <p class="text-style-2 mb-0">{{templateForm.controls.total.value}}%</p>
                  <div class="text-danger" *ngIf="templateForm.controls.total.value != 100">
                    Total percentage must equal 100%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
    <app-ae-button (click)="findDuplicateTemplateIds();onAdd()" buttonText="Add"></app-ae-button>
  </ng-template>
</coreui-modal >
