import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfiguredProgram } from '../models/configured-program';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  host = environment.host;
  base = environment.base;
  programPath = 'program/';
  modePath = 'updatemode/';
  updatetestdataURI = 'updatetestdata/';
  programComponent = 'program/';
  programDetailsComponent = 'program-details/';
  programDetailsPath = '';
  activate = 'activate/';
  deactivate = 'deactivate/';
  public programViewToggle;
  private _fetchedProgramNames: string[];
  constructor(public http?: HttpClient) { }

  get fetchedProgramNames(): string[] {
    return this._fetchedProgramNames;
  }

  set fetchedProgramNames(value: string[]) {
    this._fetchedProgramNames = value;
  }

  getProgramsByBundle(parentId:string, bundleName: string): Observable<any> {
      const url = this.host + this.base + this.programPath + 'bundle/' + bundleName;
      const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
      const options = { headers: headers };
      return this.http.get(url, options);
    }

  getAllConfiguredPrograms(parentId: string): Observable<any> {
    const url = this.host + this.base + this.programPath + 'configured';
    return this.http.get(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  public getAllPrograms(parentId: string): Observable<any> {
    const url = this.host + this.base + this.programPath;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  getConfiguredProgramDetails(parentId: string, id: string): Observable<any> {
    const url = this.host + this.base + this.programPath + 'configured/' + id;
    return this.http.get(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  addConfiguredProgram(confPgm: ConfiguredProgram, parentId: string): Observable<any> {
    const url = this.host + this.base + this.programPath + this.programDetailsPath;
    const payload = JSON.stringify(confPgm);
    return this.http.post(url, payload, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  updateConfiguredProgram(confPgm: ConfiguredProgram, parentId: string, id: string): Observable<any> {
    const url = this.host + this.base + this.programPath + this.programDetailsPath + id;
    const payload = JSON.stringify(confPgm);
    return this.http.put(url, payload, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  updateModeConfiguredProgram(parentId: string, programId: string, mode: string): Observable<any> {
    const url = this.host + this.base + this.programPath + this.programDetailsPath + this.modePath + programId;
    const payload = { 'mode': mode };
    return this.http.put(url, payload, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  updateTestRecipients(testDataPayload: Record<string, any>, parentId: string, id: string, updatetestdataURI: string): Observable<any> {
    const url = this.host + this.base + this.programPath + this.programDetailsPath + updatetestdataURI + id;
    const payload = JSON.stringify(testDataPayload);
    return this.http.put(url, payload, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  activateProgram(parentId: string, programId: string, payload: Record<string, any>): Observable<any> {
    const url = this.host + this.base + this.programPath + this.programDetailsPath + this.activate + programId;
    return this.http.put(url, JSON.stringify(payload), {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  deactivateProgram(parentId: string, programId: string): Observable<any> {
    const url = this.host + this.base + this.programPath + this.programDetailsPath + this.deactivate + programId;
    return this.http.put(url, null, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  deleteConfiguredProgram(parentId: string, id: string): Observable<any> {
    const url = this.host + this.base + this.programPath + 'configured/' + id;
    return this.http.delete(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  updateNameInConfiguredProgram(parentId: string, id: string, name: string): Observable<any> {
    const url = this.host + this.base + this.programPath + 'configured/' + id + '/name';
    const payload = { 'name': name };
    return this.http.put(url, payload, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  simulateEventsInConfiguredProgram(parentId: string, configuredProgramId: string, eventRecordsPayload: string): Observable<any> {
    const url = this.host + this.base + this.programPath + 'simulate/' + configuredProgramId;
    return this.http.post(url, eventRecordsPayload, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

}