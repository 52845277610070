<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
<div class="container-fluid object-page-header pt-4">
  <div class="row align-items-center py-sm-3">
    <div class="col">
      <h1 class="text-style-1 mb-0">{{ messages.entityContentHomePageHeader }}</h1>
    </div>
    <div class="col text-right" *ngIf="authorizationService.isAllowed('SUPPORT', ['UPDATE']) || authorizationService.isAllowed('CONTENT', ['UPDATE'])">
        <app-ae-button buttonType="primary" buttonText="{{ messages.createRMNContentButtonText }}" routerLink="/client/support/rmninsert" [disabled]="!parentContextService.isParentContextSet()"></app-ae-button>
    </div>
  </div>
</div>
<ng-container *ngIf="!parentContextService.isParentContextSet()">
  <div class="container-fluid">
    <div class="row">
      <div class="col text-center">
        <p class="text-style-muted-3 m-0">{{ messages.parentSettingMessageForEntityContent }}</p>
      </div>
    </div>
  </div>
</ng-container>
<div class="container-fluid pt-5">
  <ng-container *ngIf="entityContentTableList">
    <div class="row">
      <div class="col-12">
        <app-ae-table [isDataLoading]="isDataLoading" [configuredTableData]="entityContentTableFilteredData"
                    [properties]="properties" (menuButtonClicked)="performMenuButtonClickActions($event)">
        </app-ae-table>
      </div>
    </div>
  </ng-container>
</div>