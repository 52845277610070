<app-base-template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <app-ae-loading-indicator [isLoading]="!isFormReady()" [type]="'loading bar'"></app-ae-loading-indicator>
        <coreui-notification notificationStyle="error" [dismissible]="true" [messageDuration]="10000" [isPersistent]="false" #formError>{{errorMessage}}</coreui-notification>
        <coreui-notification notificationStyle="success" [isPersistent]="false" #formSuccess>{{successMessage}}</coreui-notification>
        <app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
        <app-ae-modal-info></app-ae-modal-info>
      </div>
    </div>
    <ng-container *ngIf="isFormReady()">
      <div class="row">
        <div class="col">
          <app-exclusion-builder-header [action]="action" [configuredExclusion]="configuredExclusion" [configuredExclusions]="configuredExclusions" [readOnly]="isReadOnly()" (saveClicked)="onSave()" (deleteClicked)="onDelete()"></app-exclusion-builder-header>
        </div>
      </div>
      <div class="row">
        <div class="container-fluid pt-4">
          <app-ribbon *ngIf="hasAssociations()" ribbonStyle="ribbon-alert" [ribbonMessage]="getOperationFailureMessage()"
            ribbonHasButton="true" [buttonText]="getAssociationsBtnTxt()" (buttonClick)="openAssociationsModal()">
          </app-ribbon>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-4">
              <app-entity-state-attribute [systemEntityStateMetadatList]="systemEntityStateMetadatList"
                [customEntityStateMetadataList]="customEntityStateMetadataList" [customEventMetadataList]="customEventMetadataList"
                [attributeMetadataList]="attributeMetadataList"></app-entity-state-attribute>
              </div>
            <div class="col-8">
              <app-exclusion-configuration-builder [action]="action" [configuredEntityStates]="configuredEntityStates" [attributeMetadataList]="attributeMetadataList" [exclusion]="getExclusionParameter()" [readOnly]="isReadOnly()"></app-exclusion-configuration-builder>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</app-base-template>
