export enum AwsRegions {
  'US East (Ohio)' = 'us-east-2',
  'US East (N. Virginia)' = 'us-east-1',
  'US West (N. California)' = 'us-west-1',
  'US West (Oregon)' = 'us-west-2',
  'Asia Pacific (Hong Kong)' = 'ap-east-1',
  'Asia Pacific (Mumbai)' = 'ap-south-1',
  'Asia Pacific (Osaka-Local)' = 'ap-northeast-3',
  'Asia Pacific (Seoul)' = 'ap-northeast-2',
  'Asia Pacific (Singapore)' = 'ap-southeast-1',
  'Asia Pacific (Sydney)' = 'ap-southeast-2',
  'Asia Pacific (Tokyo)' = 'ap-northeast-1',
  'Canada (Central)' = 'ca-central-1',
  'Europe (Frankfurt)' = 'eu-central-1',
  'Europe (Ireland)' = 'eu-west-1',
  'Europe (London)' = 'eu-west-2',
  'Europe (Paris)' = 'eu-west-3',
  'Europe (Stockholm)' = 'eu-north-1',
  'Middle East (Bahrain)' = 'me-south-1',
  'South America (Sao Paulo)' = 'sa-east-1',
}