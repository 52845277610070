import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PrimaryNavigationService {

  host = environment.host;
  base = environment.base;
  configuredEventUrl = 'event/configured';
  packagedEventUrl = 'packaged-event';

  constructor(private http: HttpClient) { }

  public getExternalTenantMappingByParentIdService(parentId: string, externalSystem: string): Observable<any> {
    const url = this.host + this.base + 'external-tenant' + '/' + externalSystem;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

}
