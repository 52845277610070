import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Action } from 'src/app/shared/models/action';
import { TimeDurationComponent } from '../time-duration/time-duration.component';
import { DelayByAttributeComponent } from '../delay-by-attribute/delay-by-attribute.component';
import { ActionElement } from 'src/app/shared/models/custom-rule/actions/action-element';
import { ActionDelay } from 'src/app/shared/models/action-delay';
import { ConfiguredEventDetails } from 'src/app/shared/models/configured-event-details';
import { ActionType } from '../../models/action-type';
import { Constants } from '../../constants';

@Component({
  selector: 'app-rule-action',
  templateUrl: './rule-action.component.html'
})
export class RuleActionComponent implements OnInit {

  @Input() public index: number;
  @Input() public placeholders: string[];
  @Input() public configuredAction: Action;
  @Input() public configuredActionDestination;
  @Input() public actionSettings;
  @Input() public isReadOnlyRule = false;
  @Input() public showDeleteButton: boolean;
  @Input() public validationRequired: boolean;
  @Input() public selectedEventSchema: string;
  @Input() public mode: string;
  @Input() public actionInputs: string[];
  @Input() public eventSources: EventSource[];
  @Input() public configuredEventDetails: ConfiguredEventDetails[];
  public filteredEvents: string[];

  @Output() public insertActionController = new EventEmitter<UntypedFormGroup>();
  @Output() public removeActionController = new EventEmitter<number>();
  @ViewChild(TimeDurationComponent) delayByTimeControls: TimeDurationComponent;
  @ViewChild(DelayByAttributeComponent) delayByAttributeControl: DelayByAttributeComponent;

  public ruleActionForm: UntypedFormGroup;
  public isValidDelay = true;

  constructor(public formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.configureFormController();
  }

  public onDeleteAction() {
    this.removeActionController.emit(this.index);
  }

  public onEventSourceProviderChange(eventSourceProvider: string): void {
    this.populateFilteredEvents(eventSourceProvider);
  }

  public populateFilteredEvents(eventSourceProvider: string) {
    this.filteredEvents = [];
    this.configuredEventDetails.forEach(event => {
      const id: Map<string, string> = event.id;
      if (id['eventSourceName'] === eventSourceProvider) {
        this.filteredEvents.push(id['eventName']);
      }
    });
  }

  public getInputTextFieldSize(fieldName : string) : number {
    let fieldValue = fieldName;
    const valueInControl = this.ruleActionForm.get(fieldName).value;
    if (fieldValue === undefined) {
      fieldValue = '';
    }
    if (valueInControl !== undefined) {
      fieldValue = valueInControl;
    }
    return (fieldValue.length > fieldName.length + 6 ? fieldValue.length : fieldName.length + 6); // 6 is length of ='Enter '
  }

  public buildActionDelay(): ActionDelay {
    let delay;
    if (this.configuredAction.hasDurationDelay()) {
      delay = this.delayByTimeControls.getDelay();
    } else if (this.configuredAction.hasAttributeDelay()) {
      delay = this.delayByAttributeControl.getDelay();
    }
    const delayType = this.configuredAction.getDelayType();
    return new ActionDelay({ delay, delayType } as ActionDelay);
  }

  public validate(): boolean {
    let validationStatus = true;
    if (this.configuredAction.hasAttributeDelay()) {
      this.delayByAttributeControl.validateDelay();
    }
    this.placeholders.forEach(placeholder => {
      validationStatus = validationStatus && this.validateActionAttributes(placeholder);
    });
    return validationStatus && this.isValidDelay;
  }

  public validateActionAttributes(placeHolder): boolean {
    const placeholder = this.ruleActionForm.get(placeHolder);
    const value = placeholder.value;
    return !(this.validationRequired && (value === '' || value === undefined) && (placeholder.touched || placeholder.dirty));
  }

  public validateDelayComponent(status) {
    if (this.validationRequired) {
      this.isValidDelay = !status;
    }
  }

  public buildAction(): ActionElement {
    const actionInput = {};
    this.placeholders.forEach(placeholder => {
      actionInput[placeholder] = this.ruleActionForm.get(placeholder).value;
    });
    let actionDelay;
    if (this.configuredAction.hasDelay()) {
      actionDelay = this.buildActionDelay();
    }
    if (this.configuredAction.actionType === ActionType.SCHEDULED_EVENT) {
      actionInput[Constants.TRIGGER_ACTION_NAME] = this.ruleActionForm.get(Constants.TRIGGER_ACTION_NAME).value;
    }
    if (this.configuredAction.isModeSynchronous()) {
      return new ActionElement(this.configuredActionDestination.actionDestination, this.configuredAction,
        actionDelay, actionInput, this.ruleActionForm.get('internalEventSourceProviderName_' + this.configuredAction.name).value,
        this.ruleActionForm.get('internalEventName_' + this.configuredAction.name).value);
    }
    return new ActionElement(this.configuredActionDestination.actionDestination, this.configuredAction, actionDelay, actionInput);
  }

  private configureFormController() {
    this.ruleActionForm = new UntypedFormGroup({ placeholders: this.formBuilder.control('') });
    this.ruleActionForm.addControl('internalEventName_' + this.configuredAction.name,
      new UntypedFormControl(null));
    this.ruleActionForm.addControl('internalEventSourceProviderName_' + this.configuredAction.name,
      new UntypedFormControl(null));
    this.ruleActionForm.get('placeholders').patchValue(this.placeholders);
    this.ruleActionForm.get('placeholders').updateValueAndValidity();
    if (this.actionSettings) {
      const actionInpSett = JSON.parse(this.actionSettings['serviceInput']);
      this.placeholders.forEach(placeholder => {
        this.ruleActionForm.addControl(placeholder, new UntypedFormControl({ value: actionInpSett[placeholder], disabled: this.isReadOnlyRule }));
      });
      if (this.actionSettings[Constants.TRIGGER_ACTION_NAME]) {
        this.ruleActionForm.addControl(Constants.TRIGGER_ACTION_NAME,
          new UntypedFormControl({ value: this.actionSettings[Constants.TRIGGER_ACTION_NAME], disabled: true }));
      }
    } else {
      this.placeholders.forEach(placeholder => {
        this.ruleActionForm.addControl(placeholder, new UntypedFormControl({ value: '', disabled: this.isReadOnlyRule }));
      });
    }
    if (this.configuredAction.isModeSynchronous()) {
      this.ruleActionForm.get('internalEventSourceProviderName_' + this.configuredAction.name).patchValue(this.actionInputs[5]);
      this.populateFilteredEvents(this.actionInputs[5]);
      this.ruleActionForm.get('internalEventName_' + this.configuredAction.name).patchValue(this.actionInputs[4]);
    }

    this.insertActionController.emit(this.ruleActionForm);
  }

}
