<coreui-modal #basicModal>
  <ng-template #titleTemplate>
    <h2 class="text-style-1 mb-0">Add a Custom Event Source Provider</h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
      <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <form [formGroup]="customEventSourceFormGroup">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="providerName">Event Source Provider Name</label>
            <input id="providerName" formControlName="providerName" type="text" class="form-control"
              placeholder="Enter name for your event source provider" [readonly]="editMode == true"
              (keyup)="validateEnteredEventSource(customEventSourceFormGroup)">
            <div *ngIf="providerName.invalid && providerName.errors.pattern" class="text-color-warning">
              Name must be alphanumeric, valid characters are A-Z a-z 0-9.
            </div>
            <div *ngIf="providerName.invalid && providerName.errors.alreadyExists" class="text-color-warning">
              "{{customEventSourceFormGroup.value.providerName}}" already exists.
            </div>
            <div *ngIf="providerName.invalid && providerName.errors.notAllowed" class="text-color-warning">
              "{{customEventSourceFormGroup.value.providerName}}" not allowed.
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="providerDescription">Event Source Provider Description</label>
            <textarea id="providerDescription" formControlName="providerDescription" rows="3"
              class="form-control" placeholder="Add a description for this event source provider"></textarea>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
    <app-ae-button (click)="addCustomEventSource(customEventSourceFormGroup.value)" [disabled]="!customEventSourceFormGroup.valid" buttonText="Save"></app-ae-button>
  </ng-template>
</coreui-modal>
