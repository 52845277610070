<bp-status-indicator *ngIf="!programStatus; else statusIndicatorTemplate" status="incomplete-primary" [statusText]="programState.INACTIVE">
</bp-status-indicator>
<ng-template #statusIndicatorTemplate>
  <div *ngIf="programState[programMode]; else activeStatusIndicatorTemplate">
    <bp-status-indicator [status]="programStateStyle(programMode)" [statusText]="programState[programMode]">
    </bp-status-indicator>
  </div>
</ng-template>  
<ng-template #activeStatusIndicatorTemplate>
  <bp-status-indicator status="complete" [statusText]="programState.LIVE">
  </bp-status-indicator>
</ng-template>
