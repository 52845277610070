import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UtilService} from 'src/app/shared/services/util-service';
import {AllocationEntity} from 'src/app/shared/models/allocations/allocation-entity';
import {Allocation} from 'src/app/shared/models/allocations/allocation';
import {AllocationValidator} from './allocation.validator';
import {ModalComponent} from '@epsilon/core-ui';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.scss']
})
export class AllocationComponent implements OnInit {

  @ViewChild('basicModal', { static: true }) private basicModal: ModalComponent;

  @Input() public serviceName;
  @Input() public businessUnitId;
  @Input() public allocations: Allocation[];
  @Output() public isModalDisplayed = new EventEmitter<any>();
  @Output() public allocationConfiguration = new EventEmitter<any>();
  
  private isModalShown: boolean;

  public templateForm: UntypedFormGroup;
  public total = 0;
  public duplicateTemplateIds: any[] = [];
  public istemplateIds = false;
  public isAllocation = false;
  public isBlankTemplate = false;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() : void {
    this.launchBasicModal();
    this.createForm();
    this.isTotalPercentage();
  }

  public createForm(): void {
    this.templateForm = this.fb.group({
      serviceName: [this.serviceName],
      businessUnitId: [this.businessUnitId],
      allocations: this.fb.array(this.allocations.map(allocation => this.buildAllocation(allocation))),
      total: [this.total, [AllocationValidator.numeric]]
    });
  }

  public buildAllocation(allocation: Allocation): UntypedFormGroup {
    this.total = this.total + allocation.percentage;
    return this.fb.group({
      allocationEntities: this.fb.array(
        allocation.allocationEntities.map(allocationEntity => this.buildAllocationEntity(allocationEntity, allocation.percentage))),
      percentage: [allocation.percentage, Validators.required]
    });
  }

  buildAllocationEntity(allocationEntity: AllocationEntity, percentage: number): UntypedFormGroup {
    let allocationEntityFormGroup = this.fb.group({});
    if (this.allocations.length >= 1 && allocationEntity.value === '' && percentage !== 100 && percentage.toString() !== '') {
      allocationEntityFormGroup.disable();
      this.isBlankTemplate = true;
    }
    allocationEntityFormGroup = this.fb.group({
      type: [allocationEntity.type, Validators.required],
      value: new UntypedFormControl({ value: allocationEntity.value, disabled: this.isBlankTemplate }, Validators.required)
    });
    return allocationEntityFormGroup;
  }

  get allocationsFormArray(): UntypedFormArray {
    return <UntypedFormArray> this.templateForm.get('allocations');
  }

  public addAnotherAllocation(): void {
    const allocationEntities: AllocationEntity[] = [];
    allocationEntities.push(new AllocationEntity('templateId', ''));
    this.allocationsFormArray.push(this.buildAllocation(new Allocation(allocationEntities, '')));
    this.isBlankTemplate = false;
    this.allocationsFormArray.enable();
  }

  public splitEqually(): void {
    const allocations = this.allocationsFormArray;
    const splitSize = Math.round(100 / allocations.length);
    allocations.controls.forEach((allocation, index) => {
      (<UntypedFormGroup> allocation).controls.percentage.setValue(splitSize);
      if (allocations.length === 3 && index === 0) {
        (<UntypedFormGroup> allocation).controls.percentage.setValue(splitSize + 1);
      }
    });
  }

  public removeTemplates(index : number): void {
    if (this.validateBlankTemplate(index)) {
      this.isBlankTemplate = false;
    }
    this.allocationsFormArray.removeAt(index);
  }

  public isTotalPercentage(): boolean {
    let total = 0;
    this.isAllocation = false;
    this.allocationsFormArray.controls.forEach(allocation => {
      if (parseInt((<UntypedFormGroup> allocation).controls.percentage.value) > 0) {
        total = total + parseInt((<UntypedFormGroup> allocation).controls.percentage.value);
      }
    });
    this.templateForm.controls.total.setValue(total);
    return total === 100;
  }

  public findDuplicateTemplateIds(): void {
    const dupTemplateIds = [];
    this.duplicateTemplateIds = [];
    this.allocationsFormArray.controls.forEach(allocation => {
      const allocationEntities = (<UntypedFormArray>((<UntypedFormGroup>allocation).controls.allocationEntities));
      allocationEntities.controls.forEach(allocationEntity => {
        const value = allocationEntity.value.value;
        if (value !== undefined && value !== '') {
          dupTemplateIds.push(value);
        }
      });
    });
    this.duplicateTemplateIds = UtilService.findDuplicatesInArray(dupTemplateIds);
  }

  public areTemplateIdUnique(templateId: string, allocationIndex: number): boolean {
    return UtilService.isDuplicateElementInArray(this.duplicateTemplateIds, templateId) && !this.validateBlankTemplate(allocationIndex);
  }

  public validateAllocationEntityType(allocationEntity: UntypedFormGroup, allocationIndex: number): boolean {
    return allocationEntity.controls.value.touched && allocationEntity.controls.value.value === '' && !this.validateBlankTemplate(allocationIndex);
  }

  public validateBlankTemplate(allocationIndex: number) : boolean {
    return this.isBlankTemplate && this.allocationsFormArray.length > 1 && allocationIndex === this.allocationsFormArray.length - 1;
  }

  public toggleBlankTemplate(event, allocationIndex : number) : void {
    this.isBlankTemplate = event.target.checked;
    const allocationEntities = (<UntypedFormArray>((<UntypedFormGroup> this.allocationsFormArray.at(allocationIndex)).controls.allocationEntities));
    if (this.isBlankTemplate) {
      const allocationEntity = allocationEntities.at(0).value;
      allocationEntity.value = '';
      allocationEntities.at(0).patchValue(allocationEntity);
      allocationEntities.at(0).disable();
    } else {
      allocationEntities.at(0).enable();
    }
  }

  public onAdd(): void {
    UtilService.markFormGroupTouched(this.templateForm);
    if (this.templateForm.valid && this.duplicateTemplateIds.length === 0) {
      this.allocationConfiguration.emit(JSON.parse(JSON.stringify(this.allocationsFormArray.getRawValue())));
      this.closeBasicModal();
    }
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayed.emit(this.isModalShown);
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

}