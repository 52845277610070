import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ExclusionConfigurationBaseOperator } from '../exclusion-configuration-base-operator';
import { DurationUnits, DurationUnitList } from 'src/app/shared/models/exclusion/duration-units';
import { FormOnSaveAction, Constants } from 'src/app/shared/constants';
import { Duration } from 'luxon';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-exclusion-configuration-last-occurrences',
  templateUrl: './exclusion-configuration-last-occurrences.component.html',
  styleUrls: ['./exclusion-configuration-last-occurrences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExclusionConfigurationLastOccurrencesComponent extends ExclusionConfigurationBaseOperator implements OnInit {

  public formControlNames = {
    'DURATION': 'duration',
    'DURATION_UNIT': 'durationUnit'
  };

  public decimalMaskConfig = Constants.decimalMaskConfig;
  public durationUnitList = DurationUnitList;
  private inputDuration: number;
  private inputDurationUnit: DurationUnits;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
    this.initFormGroup();
  }

  public buildAttributeConditionValues(): unknown[] {
    const duration = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).value;
    const durationUnit: string = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).value;
    const durationJSON = Object.create({});
    durationJSON[durationUnit] = duration;
    const durationInISO = Duration.fromObject(durationJSON);
    const attributeConditionValues = ['now', durationInISO.toISO()];
    return attributeConditionValues;
  }

  public validate(): boolean {
    const duration = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).value;
    const isValidduration = this.validateDuration(duration);
    return isValidduration;
  }

  private validateDuration(duration: number): boolean {
    const isValid = this.isValidduration(duration);
    if (!isValid) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).setErrors({ required: true });
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).markAsDirty();
    }
    return isValid;
  }

  private isValidduration(duration: number): boolean {
    return duration && !isNaN(duration);
  }

  private initInput(): void {
    this.inputDuration = undefined;
    this.inputDurationUnit = DurationUnits.MINUTES;
    if (this.action === FormOnSaveAction.EDIT) {
      this.initDurationDetails(this.attributeConditionValues[1] as string);
    }
  }

  private initDurationDetails(duration: string): void {
    const jsonDuration = Duration.fromISO(duration).toObject();
    const jsonDurationUnit = Object.keys(jsonDuration).shift();
    this.inputDurationUnit = DurationUnits[jsonDurationUnit.toLocaleUpperCase()];
    this.inputDuration = jsonDuration[jsonDurationUnit];
  }

  private initFormGroup(): void {
    this.exclusionConfigurationFormGroup = new UntypedFormGroup({
      duration: new UntypedFormControl(this.inputDuration),
      durationUnit: new UntypedFormControl(this.inputDurationUnit.toString())
    });
    if (this.readOnly) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).disable();
    }
  }

}