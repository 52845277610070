import { Component, Input, OnInit } from '@angular/core';
import { ContainerConditionElement } from 'src/app/shared/models/custom-rule/conditions/container-condition-element';
import { ConditionType } from 'src/app/shared/models/custom-rule/conditions/condition-type';
import {
  DateIntegerOperator,
  DateOperators,
  IntegerOperators,
  Operator,
  RuleOperators
} from 'src/app/shared/models/custom-rule/operators/rule-operators';
import { DataTypes } from 'src/app/shared/models/custom-rule/conditions/data-types';
import { AttributeCondition } from 'src/app/shared/models/custom-rule/conditions/attribute-condition';
import { AttributeConditionElement } from 'src/app/shared/models/custom-rule/conditions/attribute-condition-element';
import { DropArea } from 'src/app/shared/models/custom-rule/conditions/drop-area';
import { ValueType } from 'src/app/shared/models/custom-rule/conditions/value-type';
import { RuleBuilderService } from 'src/app/shared/services/rule-builder.service';
import { SourceType } from '../../models/custom-rule/conditions/source-type';
import { AttributeMetadata } from '../../models/custom-rule/conditions/attribute-metadata';
import { TreeNode } from '../../models/custom-rule/tree-node';
import { DurationUnitList, DurationUnits } from '../../models/exclusion/duration-units';
import { Messages } from '../../message';
import { Constants } from '../../constants';

@Component({
  selector: 'app-rule-condition-builder',
  templateUrl: './rule-condition-builder.component.html',
  styleUrls: ['./rule-condition-builder.component.scss']
})
export class RuleConditionBuilderComponent implements OnInit {

  @Input() public rightPaneAttributes: ContainerConditionElement;
  @Input() public isReadOnlyRule = false;
  @Input() public attributeMap: { [key: string]: AttributeMetadata };
  @Input() public showDeleteButton: boolean;
  @Input() private validationRequired: boolean;


  public conditionType = ConditionType;
  public valueType = ValueType;
  public isComparisonAttributeDropped = false;
  public durationUnitList = DurationUnitList;
  public message = Messages.incompleteRuleError;
  public constants = Constants;

  constructor(public rulebuilder: RuleBuilderService) { }

  ngOnInit() {
  }

  public getOperators(dateType: string) {
    const operators = new RuleOperators(dateType.toLowerCase());
    const relative: Operator[] = [];
    const comparison: Operator[] = [];
    const absolute: Operator[] = [];
    const arithmetic: Operator[] = [];
    for (const op of operators.operators) {
      const dateOp = op as DateIntegerOperator;
      if (dateOp.relative) {
        relative.push(dateOp);
      } else if (dateOp.comparison) {
        comparison.push(dateOp);
      } else if (dateOp.arithmetic) {
        arithmetic.push(dateOp);
      } else {
        absolute.push(dateOp);
      }
    }
    const result = {
      relative: relative,
      absolute: absolute,
      comparison: comparison,
      arithmetic: arithmetic
    };
    return result;
  }

  public isValueInputTextField(condition: AttributeConditionElement): boolean {
    return !condition.isBooleanDataType() && !condition.isCsvOperator() && !condition.isRelativeDateOperator()
    && !condition.isAbsoluteDateOperator() && !condition.isNullOrNotNullOperator();
  }

  public validateAttributeComparison(attributeCondition: AttributeCondition): boolean {
    if ((attributeCondition.values[1] === undefined || attributeCondition.values[1] === '')
      && attributeCondition.valueType === ValueType.ARRAY) {
      this.message = Messages.incompleteAttributeComparison;
      return false;
    }
    return true;
  }

  public canDropOnRule(attribute) {
    return this.isNodeOfTypeEventAttrbute(attribute.data);
  }

  public isAttributeDropped(condition: AttributeConditionElement): boolean {
    if (condition.attributeCondition.values[1] !== '' && condition.attributeCondition.values[1] !== undefined) {
      const value = condition.attributeCondition.values[3];
      if (value.toString().startsWith('P')) {
        this.rulebuilder.initDurationDetails(condition);
      }
      this.isComparisonAttributeDropped = true;
      return true;
    }
    this.isComparisonAttributeDropped = false;
    return false;
  }

  public getDisplayNameForAttribute(attributePath: string): string {
    return attributePath.substring(2);
  }

  public validateBooleanValue(values: []): boolean {
    let valueString = '';
    if (values !== undefined) {
      valueString = values.toString().toLowerCase();
    }
    return valueString === 'true' || valueString === 'false';
  }
  public validateValue(confAttrCond: AttributeConditionElement) {
    if (confAttrCond.attributeCondition.valueType === ValueType.ARRAY) {
      return this.validateAttributeComparison(confAttrCond.attributeCondition);
    }
    if (confAttrCond.attributeCondition.valueType === ValueType.ATTRIBUTE) {
      return this.validateAttributeToCompare(confAttrCond);
    }

    if (!this.validationRequired && confAttrCond.typeOfValue != DataTypes.boolean && (confAttrCond.attributeCondition.values === undefined
      || confAttrCond.attributeCondition.values.toString() === '')) {
      confAttrCond.errorOnValues = '';
      return true;
    }
    if (confAttrCond.typeOfValue === DataTypes.boolean && (confAttrCond.attributeCondition.values === undefined
      || confAttrCond.attributeCondition.values.toString() === '')) {
      confAttrCond.errorOnValues = 'Please select a value';
      return true;
    }
    if ((confAttrCond.attributeCondition.values === undefined || confAttrCond.attributeCondition.values.toString() === '') && !confAttrCond.isNullOrNotNullOperator()) {
      confAttrCond.errorOnValues = 'Please enter at least one value';
      return false;
    } else {
      confAttrCond.errorOnValues = '';
    }
    if (this.validationRequired && confAttrCond.isBeforeOccurrenceOperator()) {
      if (!confAttrCond.attributeCondition.values[1]) {
        confAttrCond.errorOnValues = 'Please enter a numeric value';
        return false;
      }
    }
    if (this.validationRequired && confAttrCond.isRelativeDateOperator()) {
      if (confAttrCond.attributeCondition.values[0] !== Constants.NOW ) {
        if (confAttrCond.attributeCondition.values[0] === undefined || confAttrCond.attributeCondition.values[0].toString() === '') {
          confAttrCond.errorOnValues = 'Please enter a value';
          return false;
        } else if (isNaN(Number(confAttrCond.attributeCondition.values[0].toString()))) {
          confAttrCond.errorOnValues = 'Please enter a numeric value';
          return false;
        } else if (Number(confAttrCond.attributeCondition.values[0].toString()) <= 0) {
          confAttrCond.errorOnValues = 'Please enter a numeric value greater than 0';
          return false;
        }
      }
    }
    const setValues: string = confAttrCond.attributeCondition.values.toString();
    if (confAttrCond.attributeCondition.dataType === DataTypes.number) {
      const operatorsList = RuleOperators.getOperatorsForDatatype(confAttrCond.attributeCondition.dataType);
      if ((operatorsList[confAttrCond.attributeCondition.operator] as Operator).csv) {
        let values = [];
        values = setValues.split(',');
        const hasNAN: boolean = values.find(value => isNaN(Number(value))) != undefined;
        if (hasNAN) {
          confAttrCond.errorOnValues = 'Please check if all values are numbers';
          return false;
        } else {
          confAttrCond.errorOnValues = '';
          return true;
        }
      } else {
        if (isNaN(Number(setValues))) {
          confAttrCond.errorOnValues = 'Please enter a numeric value';
          return false;
        } else {
          confAttrCond.errorOnValues = '';
          return true;
        }
      }
    } else if (confAttrCond.attributeCondition.dataType === DataTypes.array) {
      const valueString = confAttrCond.attributeCondition.values.toString().toLowerCase();
      if (confAttrCond.typeOfValue === DataTypes.boolean) {
        if (valueString === 'true' || valueString === 'false') {
          confAttrCond.errorOnValues = '';
          return true;
        } else {
          confAttrCond.errorOnValues = 'Please enter true/false';
          return false;
        }
      } else if (confAttrCond.typeOfValue === DataTypes.number) {
        if (isNaN(Number(valueString))) {
          confAttrCond.errorOnValues = 'Please enter a numeric value';
          return false;
        } else {
          confAttrCond.errorOnValues = '';
          return true;
        }
      }
    } else if (confAttrCond.attributeCondition.dataType === DataTypes.string && confAttrCond.attributeCondition.operator === 'IN_IGNORECASE' || confAttrCond.attributeCondition.operator === 'NOTIN_IGNORECASE') {
      if (setValues.trim().split(',').indexOf('') !== -1) {
        confAttrCond.errorOnValues = 'Please enter at least one value';
        return false;
      }
    }
    return true;
  }

  public validateAttributeToCompare(attributeConditionElement: AttributeConditionElement): boolean {
    if (attributeConditionElement.attributeCondition.dataType === DataTypes.array) {
      attributeConditionElement.errorOnValues = 'DataType ' + DataTypes.array + ' is not supported for attribute comparison';
      return false;
    }
    const attributeToCompare: AttributeCondition = attributeConditionElement.attributeCondition.values[0];
    if (!attributeToCompare.hasOwnProperty('dataType')) {
      attributeConditionElement.errorOnValues = 'DataType is required for attribute to be compared';
      return false;
    }
    if (attributeToCompare.dataType === DataTypes.array) {
      attributeConditionElement.errorOnValues = 'DataType ' + DataTypes.array + ' is not supported for attribute comparison';
      return false;
    }
    if (attributeConditionElement.attributeCondition.dataType !== attributeToCompare.dataType) {
      attributeConditionElement.errorOnValues = 'DataType ' + attributeConditionElement.attributeCondition.dataType + ' not matching with data type of attribute to compare ' + attributeToCompare.dataType;
      return false;
    }
    return true;
  }

  public validateContainerConditionElement(containerConditionElement: ContainerConditionElement[]): boolean {
    for (const nextConfCondition of containerConditionElement) {
      if (nextConfCondition.hasOwnProperty('conditions') && !this.validateContainerConditionElement(nextConfCondition.conditions)) {
        return false;
      } else if (!nextConfCondition.hasOwnProperty('conditions') && !this.validateValue(nextConfCondition as AttributeConditionElement)) {
        return false;
      }
    }
    return true;
  }

  public addCondition($event) {
    this.rightPaneAttributes.conditions.push(this.buildAttributeConditionElement($event.element.data.id));
  }

  public addConditionAfter($event, currentAttributeConditionIndex: number, conditions: ContainerConditionElement[], level: number) {
    conditions.splice(currentAttributeConditionIndex + 1, 0, this.buildAttributeConditionElement($event.element.data.id));
    this.rulebuilder.toggleDropArea($event, DropArea.AFTER, level, false);
  }

  public addConditionAsChild($event, currentAttributeConditionIndex: number, conditions: ContainerConditionElement[], level: number) {
    if (level < 4) {
      const attributeConditionElement: AttributeConditionElement = this.buildAttributeConditionElement($event.element.data.id);
      if (conditions.length > currentAttributeConditionIndex + 1 && conditions[currentAttributeConditionIndex + 1].hasOwnProperty('conditions')) {
        conditions[currentAttributeConditionIndex + 1].conditions.splice(0, 0, attributeConditionElement);
      } else {
        conditions.splice(currentAttributeConditionIndex + 1, 0, { type: ConditionType.AND, conditions: [attributeConditionElement] });
      }
    }
    this.rulebuilder.toggleDropArea($event, DropArea.CHILD, level, false);
  }

  public addConditionBefore($event, currentAttributeConditionIndex: number, conditions: ContainerConditionElement[], level: number) {
    conditions.splice(currentAttributeConditionIndex, 0, this.buildAttributeConditionElement($event.element.data.id));
    this.rulebuilder.toggleDropArea($event, DropArea.BEFORE, level, false);
  }

  public deleteAttributeCondition(attributeCondition: AttributeConditionElement, parentConditions: ContainerConditionElement[]) {
    let deletePosition = parentConditions.indexOf(attributeCondition);
    const containerConditionElements: ContainerConditionElement[] = this.getConditionWrapperForDeletion(this.rightPaneAttributes.conditions, parentConditions);
    parentConditions.splice(deletePosition, 1);
    // Promoting nested items
    if (deletePosition < parentConditions.length && parentConditions[deletePosition].hasOwnProperty('conditions')) {
      const nestedConditions = parentConditions[deletePosition].conditions;
      parentConditions.splice(deletePosition, 1);
      nestedConditions.forEach( (nestedCondition: ContainerConditionElement) => {
        parentConditions.splice(deletePosition++, 0, nestedCondition);
      });
    }
    // Cleaning up empty condition container
    for (let index = 0; index < containerConditionElements.length; index++) {
      if (containerConditionElements[index].hasOwnProperty('conditions') && containerConditionElements[index].conditions === parentConditions && containerConditionElements[index].conditions.length === 0) {
        containerConditionElements.splice(index, 1);
      }
    }
  }


  public onLogicalOperatorSelected(conditionType: ConditionType, attributeCondition: AttributeConditionElement, attributeNode): void {
    attributeNode.conditions.forEach(element => {
      if (element == attributeCondition) {
        attributeNode.type = conditionType;
        return;
      }
      if (element.hasOwnProperty('conditions')) {
        this.onLogicalOperatorSelected(conditionType, attributeCondition, element);
      }
    });
  }

  public getInputTextFieldSize(fieldName: string, fieldValue: any): number {
    let value;
    if (fieldValue instanceof Array) {
      if (fieldValue[0] === undefined) {
        value = '';
      } else if (fieldValue[0] instanceof String) {
        value = fieldValue[0].trim();
      } else {
        value = String(fieldValue[0]);
        value = value.trim();
      }
    } else {
      value = fieldValue.trim();
    }
    return (value.length > fieldName.length + 6 ? value.length : fieldName.length + 6); // 6 is length of ='Enter '
  }

  public changeOperator(condition): void {
    this.isComparisonAttributeDropped = false;
    condition.operator = DateOperators.EQ.value;
    condition.valueType = ValueType.CONSTANT;
    condition.values = [];
  }

  public canDropAttributeToCompare(attributConditionDataTye: DataTypes, attributeToCompare) {
    const attributeToCompareConditionElement: AttributeConditionElement = this.buildAttributeConditionElement(attributeToCompare.data.id);
    if (!this.isNodeOfTypeEventAttrbute(attributeToCompare.data)) {
      return false;
    }
    if (attributConditionDataTye === DataTypes.array || attributeToCompareConditionElement.attributeCondition.dataType === DataTypes.array) {
      return false;
    }
    if (attributConditionDataTye !== attributeToCompareConditionElement.attributeCondition.dataType) {
      return false;
    }
    return true;
  }

  public addAttributeToCompareValues(eventWithAttributeToCompare, condition: AttributeConditionElement): void {
    const attributeToCompareAttributeConditionElement = this.buildAttributeConditionElement(eventWithAttributeToCompare.element.data.id);
    const attributeConditionToCompare = attributeToCompareAttributeConditionElement.attributeCondition;
    attributeConditionToCompare.values = [];
    condition.attributeCondition.values[0] = DateOperators.PLUS.value;
    condition.attributeCondition.values[1] = attributeToCompareAttributeConditionElement.attributeCondition;
    condition.attributeCondition.values[2] = DateOperators.EQ.value;
    condition.attributeCondition.values[3] = 0;
    if (condition.attributeCondition.operator === DateOperators.IS_COMPARE_TIME.value) {
      condition.attributeCondition.values[4] = DurationUnits.MINUTES;
    }
    this.isComparisonAttributeDropped = true;
  }

  public addAttributeToCompare(eventWithAttributeToCompare, attributeCondition: AttributeCondition) {
    const attributeToCompareAttributeConditionElement = this.buildAttributeConditionElement(eventWithAttributeToCompare.element.data.id);
    attributeToCompareAttributeConditionElement.attributeCondition.values = [];
    attributeCondition.valueType = ValueType.ATTRIBUTE;
    attributeCondition.values = [];
    attributeCondition.values.push(attributeToCompareAttributeConditionElement.attributeCondition);
  }

  public setDefaultRelativeDateValues(condition: AttributeConditionElement) {
    if (condition.attributeCondition.valueType === ValueType.CONSTANT && condition.isRelativeDateOperator()) {
      condition.attributeCondition.values[0] = '';
      condition.attributeCondition.values[1] = 'm';
    }
    if (condition.attributeCondition.valueType === ValueType.CONSTANT && condition.isBeforeOccurrenceOperator()) {
      condition.attributeCondition.values[2] = DurationUnits.MINUTES.toString();
    }
    if (condition.attributeCondition.operator === (DateOperators.IS_COMPARE_TIME.value || IntegerOperators.IS_COMPARE_NUMBER.value)) {
      condition.attributeCondition.valueType = ValueType.ARRAY;
      condition.attributeCondition.values[0] = DateOperators.PLUS.value;
      condition.attributeCondition.values[1] = '';
      condition.attributeCondition.values[2] = DateOperators.GT.value;
      condition.attributeCondition.values[3] = '';
      condition.attributeCondition.values[4] = '';
    }
  }

  public setCurrentDateTime(event: any, condition: AttributeConditionElement): AttributeConditionElement {
    if (event.target.checked) {
      condition.attributeCondition.values[0] = Constants.NOW;
      condition.attributeCondition.values = condition.attributeCondition.values.splice(0, 1);
    } else {
      condition.attributeCondition.values[0] = '';
      condition.attributeCondition.values[1] = 'm';
      condition.attributeCondition.values = condition.attributeCondition.values.splice(0, 2);
    }
    return condition;
  }

  private getConditionWrapperForDeletion(allConditions: ContainerConditionElement[],
    parentConditions: ContainerConditionElement[]): ContainerConditionElement[] {
    if (allConditions == parentConditions) {
      return allConditions;
    }
    for (const element of allConditions) {
      if (element.hasOwnProperty('conditions')) {
        if (element.conditions == parentConditions) {
          return allConditions;
        } else {
          const conditions: ContainerConditionElement[] = this.getConditionWrapperForDeletion(element.conditions, parentConditions);
          if (conditions !== undefined) {
            return conditions;
          }
        }
      }
    }
  }

  private buildAttributeConditionElement(eventAttributeId: string): AttributeConditionElement {
    const attrMap = this.attributeMap[eventAttributeId];
    if (this.isDateAttribute(attrMap.name) && attrMap.type !== DataTypes.array) {
      attrMap.type = DataTypes.date;
    }
    const attrCondition = new AttributeCondition(attrMap.name, attrMap.path, attrMap.type, attrMap.sourceType);
    if (attrMap.sourceType === SourceType.ENTITY_STATE) {
      attrCondition.entityStateName = attrMap.entityStateName;
      attrCondition.entityStateId = attrMap.entityStateId;
    }
    attrCondition.values = [];
    const attributeConditionElement = new AttributeConditionElement(false, false, attrCondition, attrMap.typeOfValue);
    if (attributeConditionElement.attributeCondition.valueType === ValueType.CONSTANT && attributeConditionElement.isRelativeDateOperator()) {
      attributeConditionElement.attributeCondition.values[1] = 'm';
    }
    return attributeConditionElement;
  }

  private isDateAttribute(name: string) {
    const nameLowerCase = name.toLocaleLowerCase();
    if (nameLowerCase.includes('time') || nameLowerCase.includes('date') || nameLowerCase.includes('executionschedule')) {
      return true;
    }
    return false;
  }

  private isNodeOfTypeEventAttrbute(attribute: TreeNode) {
    return attribute.nodeType === SourceType.EVENT || attribute.nodeType === SourceType.ENTITY_STATE;
  }

}
