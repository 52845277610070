
<coreui-radio-group [label]="''" id="ae-table" orientation="horizontal">
    <coreui-table class="h-auto mb-3"*ngIf="properties">
        <thead coreUITableSort>
            <tr coreUITableHeaderRow>
                <th *ngFor="let header of properties.columns">
                    <coreui-table-header-column *ngIf="header.key != 'actions' && header.multiHeaderValues == undefined && header.key != 'radioButton'"
                        [active]="getDefaultSort(header.headerText)" (sort)="handleEventSort($event,header.headerText)">
                        {{header.headerText}}
                    </coreui-table-header-column>
                    <coreui-table-multiple-header-column label={{header.headerText}} *ngIf="header.multiHeaderValues != undefined && header.multiHeaderValues.length > 0">
                        <coreui-table-header-column *ngFor="let headerValue of header.multiHeaderValues; let i=index" (sort)="handleMultiHeaderColumnEventSort($event,header.multiHeaderKeys[i])">
                            {{headerValue}}
                        </coreui-table-header-column>
                    </coreui-table-multiple-header-column>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr coreUITableRow *ngFor="let item of configuredTableList">
                <td *ngFor="let attribute of properties.columns">
                    <span *ngIf="attribute.hasOwnProperty('radioButton')">
                        <coreui-form-field label="">
                            <input type="radio" coreUIRadio value="{{item.id}}" name="inlineRadio"
                                [formControl]="radioButtonSelection"/>
                        </coreui-form-field>
                    </span>
                    <span
                        *ngIf="attribute.hasOwnProperty('link') && item.hasOwnProperty('routerLink') && !isAllowNewTab(attribute)">
                        <a href="javascript:void(0)" (click)="getRouterLink(item,'button')">
                            {{item[attribute.key]}}
                        </a>
                    </span>
                    <span
                        *ngIf="attribute.hasOwnProperty('link') && item.hasOwnProperty('routerLink') && isAllowNewTab(attribute)">
                        <span *ngIf="item.hasOwnProperty('toolTip')" class="tooltip-a">
                            <ng-template #tooltip>
                                <span class="tooltipMessage">
                                    {{ item.toolTip.msg }}
                                </span>

                            </ng-template>
                            <a [coreUITooltip]="tooltip" position="right">
                                <i coreUIIcon="{{ item.toolTip.icon }}" class="u-colorBlue50 u-fontSizeInherit"
                                    scale="1.5"></i>
                            </a>
                        </span>
                        <a href="{{getRouterLink(item,'link')}}">
                            {{item[attribute.key]}}
                        </a>
                    </span>
                    <span *ngIf="attribute.hasOwnProperty('link') && !item.hasOwnProperty('routerLink')">
                        {{item[attribute.key]}}
                    </span>
                    <span *ngIf="attribute.hasOwnProperty('editAction')">
                        <a (click)="getActionClick(attribute,item)"
                            href="javascript:void(0)">{{item[attribute.key]}}</a>
                    </span>
                    <span *ngIf="_array.isArray(item[attribute.key])">
                        <span *ngIf="item[attribute.key][0].component === 'ae-badge'">
                            <app-ae-badge [elements]="item[attribute.key]">
                            </app-ae-badge>
                        </span>
                        <span *ngIf="item[attribute.key][0].component === 'actions'">
                            <coreui-menu icon="more" menuButtonStyle="link" *ngIf="isActionMenuButtonEnabled(item[attribute.key])">
                                <coreui-menu-section class="test-codf">
                                    <ng-container *ngFor="let _item of item[attribute.key]">
                                        <button type="button" class="coreui-spots" coreUIMenuItem
                                            *ngIf="_item.isAuthorized === undefined || _item.isAuthorized" [disabled]="_item.disabled"
                                            (click)="onMenuButtonClick({label: _item.label, id: item.id, packageName: item.packageName}); label=''; id=''; packageName=''; ">{{_item.label}}</button>
                                    </ng-container>
                                </coreui-menu-section>
                            </coreui-menu>
                            <!--  <button coreUIButton="icon"><i coreUIIcon="trash"></i></button>  -->
                        </span>
                    </span>
                    <span
                        *ngIf="!attribute.hasOwnProperty('link') && !attribute.hasOwnProperty('editAction') && !attribute.hasOwnProperty('dataIcon') && !_array.isArray(item[attribute.key])">
                        {{item[attribute.key]}}
                    </span>
                    <span *ngIf="attribute.hasOwnProperty('dataIcon')">
                        <span *ngIf="isIcon(item[attribute.key], attribute.dataIcon.phrase)">
                            {{item[attribute.key] |
                            slice:attribute.dataIcon.startPhrase:attribute.dataIcon.endPhrase}}...
                            <a href="javascript:void(0)"
                                (click)="showTableCellContentInModal(item[attribute.key], attribute.dataIcon.startPhrase)"
                                [coreUITooltip]="tooltip" position="bottom">
                                <i coreUIIcon="expandWindow" scale="1.5"></i>
                            </a>
                        </span>
                        <span *ngIf="!isIcon(item[attribute.key], attribute.dataIcon.phrase)" class="text-break">
                            {{item[attribute.key]}}
                        </span>
                        <ng-template #tooltip>
                            <span class="tooltipMessage">
                                {{ attribute.dataIcon.toolTip }}
                            </span>
                        </ng-template>
                    </span>
                    <span *ngIf="attribute.hasOwnProperty('multiHeaderValues')">
                        <td> {{item[attribute.multiHeaderKeys[0]]}}
                            <div class="text-style-muted-1">{{item[attribute.multiHeaderKeys[1]]}}</div>
                        </td>
                      </span>
                </td>
            </tr>
            <tr *ngIf=" !isDataLoading && !configuredTableData.length" coreUITableRow>
                <td colspan="8" class="pt-4">
                    <coreui-graphic-message label="No Results Found" graphic="noResults">
                        Sorry, we did not find any results.
                    </coreui-graphic-message>
                </td>
            </tr>
            <tr *ngIf="isDataLoading">
                <td colspan="8" class="py-4">
                    <coreui-load-mask [active]="isDataLoading">
                    </coreui-load-mask>
                </td>
            </tr>
        </tbody>
    </coreui-table>
</coreui-radio-group>
<div class="row" *ngIf="!properties['hidePagination']">
    <bp-pagination class="col-4 ml-auto mb-3 mr-3" [dataLength]="configuredTableData.length" [defaultNumberOfRows]="10"
        [pageBuffer]="1" (pageData)="handleEventPageChange($event)"></bp-pagination>
</div>
<app-ae-modal-info></app-ae-modal-info>