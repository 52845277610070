import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayTextService {

  DISABLED_DELETE_BTN_TOOLTIP = 'This Program is active. Please deactivate first in order to delete.';
  DISABLED_SAVE_BTN_TOOLTIP = 'This Program is active. Please deactivate first in order to save.';
  DISABLED_ACTIVATE_BTN_TOOLTIP = 'The program has been changed. Please save first in order to activate';
  DISABLED_ADD_TEST_RECIPIENTS_TOOLTIP = 'This Program is active. Please deactivate first in order to add test recipients.';
  PROGRAM_SIMULATE_DESCRIPTION = 'Simulating events allows you to test your program with real event records and is a crucial step in completing your program.';
  ALLOWED_EVENT_RECORDS_EXHAUSTED = 'You have reached the limit for the number of events that can be simulated.';
  RULE_MAX_NESTED_REACHED = 'Nesting is limited to five levels';
  RULE_MAX_ACTION_REACHED = 'Rules are limited to five actions';

  constructor() { }

}