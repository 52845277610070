import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { OauthCredential } from 'src/app/shared/models/oauth-credential';
import { OauthActionSettings } from 'src/app/shared/models/oauth-action-settings';
import { Messages } from 'src/app/shared/message';
import { AuthType } from 'src/app/shared/models/auth-type';

@Component({
  selector: 'app-oauth-credential',
  templateUrl: './oauth-credential.component.html'
})
export class OauthCredentialComponent implements OnInit {

  oauthCredentialForm: UntypedFormGroup;
  authUrlChanged: boolean;
  clientIdChanged: boolean;
  clientSecretChanged: boolean;
  userIdChanged: boolean;
  passwordChanged: boolean;
  grantChanged: boolean;
  scopeChanged: boolean;
  editMode = false;
  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.oauthCredentialForm = this.fb.group({
      authUrl: ['', [Validators.required, Validators.pattern('https://.+')]],
      userId: ['', [Validators.required]],
      password: ['', [Validators.required]],
      clientId: ['', [Validators.required]],
      clientSecret: ['', [Validators.required]],
      grantType: ['', [Validators.required]],
      scope: ['', [Validators.required]]
    });

    this.getAuthUrlControl().valueChanges.subscribe(changes => {
      this.authUrlChanged = true;
    });

    this.getClientIdControl().valueChanges.subscribe(changes => {
      this.clientIdChanged = true;
    });

    this.getClientSecretControl().valueChanges.subscribe(changes => {
      this.clientSecretChanged = true;
    });

    this.getUserIdControl().valueChanges.subscribe(changes => {
      this.userIdChanged = true;
    });

    this.getPasswordControl().valueChanges.subscribe(changes => {
      this.passwordChanged = true;
    });

    this.getGrantTypeControl().valueChanges.subscribe(changes => {
      this.grantChanged = true;
    });

    this.getScopeControl().valueChanges.subscribe(changes => {
      this.scopeChanged = true;
    });
  }

  hasFormUpdated() {
    return this.clientIdChanged
      || this.authUrlChanged
      || this.userIdChanged
      || this.passwordChanged
      || this.scopeChanged
      || this.grantChanged;
  }
  finalizeUpdates() {
    this.clientIdChanged = false;
    this.authUrlChanged = false;
    this.clientSecretChanged = false;
    this.userIdChanged = false;
    this.passwordChanged = false;
    this.grantChanged = false;
    this.scopeChanged = false;
  }
  isValid() {
    return this.oauthCredentialForm.valid;
  }
  getCredentials(): OauthActionSettings {
    const oauthCredential = new OauthCredential();
    oauthCredential.userId = this.oauthCredentialForm.get('userId').value;
    oauthCredential.password = this.oauthCredentialForm.get('password').value;
    oauthCredential.clientId = this.oauthCredentialForm.get('clientId').value;
    oauthCredential.clientSecret = this.oauthCredentialForm.get('clientSecret').value;
    const oauthActionSettings = new OauthActionSettings();
    oauthActionSettings.authenticationMethod = AuthType.OAUTH;
    oauthActionSettings.authUrl = this.oauthCredentialForm.get('authUrl').value;
    oauthActionSettings.grantType = this.oauthCredentialForm.get('grantType').value;
    oauthActionSettings.scope = this.oauthCredentialForm.get('scope').value;
    oauthActionSettings.oauthCredential = oauthCredential;
    return oauthActionSettings;
  }

  getAuthUrlControl() {
    return this.oauthCredentialForm.get('authUrl');
  }
  getUserIdControl() {
    return this.oauthCredentialForm.get('userId');
  }
  getPasswordControl() {
    return this.oauthCredentialForm.get('password');
  }
  getClientIdControl() {
    return this.oauthCredentialForm.get('clientId');
  }
  getClientSecretControl() {
    return this.oauthCredentialForm.get('clientSecret');
  }
  getScopeControl() {
    return this.oauthCredentialForm.get('scope');
  }
  getGrantTypeControl() {
    return this.oauthCredentialForm.get('grantType');
  }
  validateForRequired(c: AbstractControl) {
    return c.errors && c.touched && c.errors.required;
  }
  getEmptyUserIdMessage() {
    return Messages.emptyUserId;
  }
  getEmptyPasswordMessage() {
    return Messages.emptyPassword;
  }
  getEmptyAuthUrlErrorMessage() {
    return Messages.emptyAuthUrl.replace('AUTH_METHOD', AuthType.OAUTH);
  }
  getEmptyClientIdMessage() {
    return Messages.emptyClientId;
  }
  getEmptyClientSecretMessage() {
    return Messages.emptyClientSecret;
  }
  getEmptyGrantTypeMessage() {
    return Messages.emptyGrantType;
  }
  getEmptyScopeMessage() {
    return Messages.emptyScope;
  }

}
