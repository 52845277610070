import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TenantSetting } from 'src/app/shared/models/tenant-settings/tenant-settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  host = environment.host;
  base = environment.base;
  settingUrl = 'settings/';

  constructor(private http: HttpClient) { }

  getTenantSettings(parentId: string) : Observable<any> {
    const url = this.host + this.base + this.settingUrl + 'all';
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  updateConfiguredTenantSettings(parentId: string, tenantSettings: TenantSetting[]) : Observable<any> {
    const url = this.host + this.base + this.settingUrl + 'configured';
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.put(url, tenantSettings, options);
  }

}