<coreui-filter [autoFocus]="true" data-qa="dateAddedFilter-all" label="Sort By" [buttonLabel]="sortByDisplay">
    <coreui-menu-section>
        <ul role="radiogroup" class="toggle-list">
            <li role="radio" class="toggle-listItem" *ngFor="let item of sortByOptions"
                (click)="performClickAction(item.text)">
                {{ item.text }}
                <i role="image" class="toggle-listItem-icon" coreUIIcon="approve" *ngIf="item.text === sortByDisplay">
                </i>
            </li>
        </ul>
    </coreui-menu-section>
</coreui-filter>
