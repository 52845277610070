import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ASSOCIATION_API_FIELD_STATUS, ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER,
  ENTITY_TYPE, HAS_ASSOCIATIONS_IDENTIFIER, ID_IDENTIFIER } from 'src/app/shared/models/association/association-constants';
import { ConfiguredEntityAssociationParams } from 'src/app/shared/models/association/configured-entity-association-params';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { IconViewDisplayItem } from 'src/app/shared/models/icon-view-display-item';
import { AssociationService } from 'src/app/shared/services/association.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { Messages } from 'src/app/shared/message';
import { AuthType } from 'src/app/shared/models/auth-type';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { AssociationsTableBuilderService } from 'src/app/shared/services/associations-table-builder.service';
import { ActionDestinationType } from 'src/app/shared/models/action-destination-type';
import { BusinessUnit } from 'src/app/shared/models/business-unit';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { AEModalInfoComponent } from 'src/app/shared/component/modal/ae-modal-info/ae-modal-info.component';
import { NotificationComponent } from '@epsilon/core-ui';
import { Constants, NotificationStyle } from 'src/app/shared/constants';
@Component({
  selector: 'app-action-destinations',
  templateUrl: './action-destinations.component.html'
})
export class ActionDestinationsComponent implements OnInit, OnDestroy {

  @ViewChild('formError', {static: true})
  private formError: NotificationComponent;
  @ViewChild(CoreuiModalWarningComponent, { static: true })
  private warningModal: CoreuiModalWarningComponent;

  @ViewChild(AEModalInfoComponent, { static: true })
  private infoModal: AEModalInfoComponent;

  actionDestinationsForm: UntypedFormGroup;
  public actionDestinationResults: any;
  public isCollapsed: boolean[] = [];
  public addActionDestinationRespose: any;
  public addBusinessUnitResponse: any;
  public deleteBUModelDetail: any;
  public parentId: string;
  public deleteConfiguredActionDestinationDetail: any;
  public operationFailures: boolean[] = [];
  public hasAssociationMap = {};
  public configuredEntityAssociationMap = {};
  public errorMessage: string;
  operationInProgress = false;
  operationStatusMessage = '';
  isCustomActionDestinationDeleteOperation: boolean;

  readonly ADD_AN_ACTION_DESTINATION = 'Add an Action Destination';
  readonly CUSTOM_AD_ID = 'Custom';
  readonly AWS_LAMBDA_AD_ID = 'AWS';
  readonly HARMONY_ACTION_DESTINATION = 'HARMONY';

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private allActionDestinations: any[];
  private customActionDestination: Record<string, unknown>;
  private customADOAuthCredential: Record<string, unknown>;
  private awsActionDestination: Record<string, unknown>;
  private awsUserCredential: Record<string, unknown>;
  private isInitializeEditMode: boolean;
  private existingCustomActionDestinationId: string;
  private isCustomActionDestinationModalShown: boolean;
  private isAWSActionDestinationShown: boolean;
  private isAddBUModalShown: boolean;
  private popupActionDestination;
  private businessUnitControl;
  private associationModalDetail;

  constructor(
    private actionDestinationService: ActionDestinationService,
    public modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public service: ProgramService,
    private parentContext: ParentContextService,
    private route: ActivatedRoute,
    private popupService: PopupMessageService,
    private associationService: AssociationService,
    public authorizationService: AuthorizationService,
    private associationsTableBuilderService: AssociationsTableBuilderService
  ) {
    this.parentId = this.parentContext.getParentContext();
    this.actionDestinationsForm = this.fb.group({
      actionDestination: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.loadAllActionDestinations();
  }

  public getExistingActionDestinationsAsDisplayItems(): IconViewDisplayItem[] {
    const existingActionDestinations = this.actionDestinationsForm.controls.actionDestination.value.map(function(item) {
      return new IconViewDisplayItem(item['id'], item['displayName']);
    });
    return existingActionDestinations;
  }

  public open(): void {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Loading';
    const existingActionDestinations = this.getExistingActionDestinationsAsDisplayItems();
    this.actionDestinationService.getAllActionDestinations(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.operationInProgress = false;
        if (res !== null && res['result'] !== null) {
          res['result'] = this.addDisplayNameAttribute(res);
        }
        this.allActionDestinations = res['result'];
        const allDisplayItems = [];
        this.allActionDestinations.map(element => {
          allDisplayItems.push(new IconViewDisplayItem(element.id, element.displayName));
        });
        this.infoModal.launchModal({
          'title': this.ADD_AN_ACTION_DESTINATION,
          'description': '',
          'content': {
            'type': 'ICON_VIEW',
            'iconViewConfig': {
              'displayItems': allDisplayItems,
              'disabledItems': existingActionDestinations
            }
          }
        });
      },
      () => {
        this.operationInProgress = false;
        this.errorMessage = Messages.unauthorizedUser;
        this.formError.show();
      }
    );
  }

  public captureSelectedItem(selection: IconViewDisplayItem): void {
    switch (selection.Id) {
      case this.CUSTOM_AD_ID: {
        this.processAsAddCustomActionDestinationRequest();
        break;
      }
      case this.AWS_LAMBDA_AD_ID: {
        this.processAsAddAwsActionDestinationRequest();
        break;
      }
      default: {
        this.processAsAddPackagedActionDestinationRequest(selection);
        break;
      }
    }
  }

  public deleteActionDestinationFromADAlertModal(actionDestinationName, index, isCustomActionDestination: boolean) {
    const control = <UntypedFormArray> this.actionDestinationsForm.controls.actionDestination;
    this.isCustomActionDestinationDeleteOperation = isCustomActionDestination;
    this.deleteConfiguredActionDestinationDetail = { control: control, actionDestinationName: actionDestinationName, index: index };
    this.warningModal.forEntity('ACTION_DESTINATION').launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.removeActionDestination,
      msg: Messages.confirmRemoveActionDestination,
      msg2: this.isCustomActionDestinationDeleteOperation ? [] : [Messages.removeActionDestinationConsequence]
    });
  }

  public handleDeleteADDecision(decision: boolean): void {
    if (decision) {
      this.deleteConfiguredActionDestination();
      this.operationFailures = [];
    }
  }

  public deleteConfiguredActionDestination(): void {
    this.operationStatusMessage = 'Deleting action destination';
    this.operationInProgress = true;
    this.actionDestinationService.deleteConfiguredActionDestination(
      this.parentId, this.deleteConfiguredActionDestinationDetail.actionDestinationName).pipe(takeUntil(this.destroyed$)).subscribe(result => {
      const control = <UntypedFormArray> this.actionDestinationsForm.controls.actionDestination;
      this.popupService.showSuccessMessage(`ActionDestination "${this.actionDestinationsForm.controls.actionDestination.value[this.deleteConfiguredActionDestinationDetail.index].displayName}"
       ${Constants.SUCCESSFULLY_DELETED}`);
      control.removeAt(this.deleteConfiguredActionDestinationDetail.index);
      this.operationInProgress = false;
    }, error => {
      this.operationInProgress = false;
      if (error.status === 403) {
        this.popupService.showErrorMessage(error.error.statusMessage);
      } else {
        this.associationModalDetail = this.associationsTableBuilderService
          .buildAssociationTableDataFromConfig(new ConfiguredEntityAssociationParams(
            error.error.result,
            this.parentId,
            this.deleteConfiguredActionDestinationDetail.actionDestinationName,
            ConfiguredEntityType.PACKAGED_ACTION_DESTINATION));
        this.operationFailures[this.deleteConfiguredActionDestinationDetail.index] = true;
        this.launchAssociationsModal();
      }
    });
  }

  public addBusinessUnit(control, actionDestination) {
    this.isAddBUModalShown = true;
    this.popupActionDestination = { buControl: control, parentId: this.parentId, id: actionDestination.value.id };
    this.businessUnitControl = control;
  }

  public handleActionsFromDeleteBUAlertModal(actionDestinationName, control, index): void {
    this.deleteBUModelDetail = {
      control: control, index: index,
      actionDestinationName: actionDestinationName,
      businessUnitId: control.value[index].businessUnitId,
      businessUnitName: control.value[index].businessUnitName,
      clientUserName: control.value[index].clientUserName,
      clientPassword: control.value[index].clientPassword,
      apiClientId: control.value[index].apiClientId,
      apiClientPassword: control.value[index].apiClientSecret
    };
    this.warningModal.forEntity('BUSINESS_UNIT').launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteBusinessUnit,
      msg: [Messages.confirmDeleteBusinessUnit]
    });
  }

  public handleDeleteBUDecision(decision: boolean): void {
    if (decision) {
      this.deleteBusinessUnit(this.deleteBUModelDetail.control, this.deleteBUModelDetail.index, this.deleteBUModelDetail);
    }
  }

  public deleteBusinessUnit(control, index, deleteBUModelDetail): void {
    this.operationStatusMessage = 'Deleting business unit';
    this.operationInProgress = true;
    this.actionDestinationService.deleteBusinessUnit(this.parentId, this.buildBusinessUnitPayloadForDeleteOperation(deleteBUModelDetail)).pipe(takeUntil(this.destroyed$)).subscribe(
      serviceResponse => {
        control.removeAt(index);
        this.operationInProgress = false;
      }, failureReason => {
        this.operationInProgress = false;
        if (failureReason.status === 403) {
          this.popupService.showErrorMessage(failureReason.error.statusMessage);
        } else {
          this.associationModalDetail = this.associationsTableBuilderService
            .buildAssociationTableDataFromConfig(new ConfiguredEntityAssociationParams(
              failureReason.error.result,
              this.parentId,
              deleteBUModelDetail.businessUnitId,
              ConfiguredEntityType.PACKAGED_ACTION_DESTINATION));
          this.launchAssociationsModal();
        }
      });
  }

  private buildBusinessUnitPayloadForDeleteOperation(deleteBUModelDetail: any): string {
    return '{ "parentId": "' + this.parentId
      + '", "actionDestination": "' + deleteBUModelDetail.actionDestinationName
      + '", "actionSettings":' + this.buildActionSettingsPayloadForDeleteOperation(deleteBUModelDetail)
      + '}';
  }

  private buildActionSettingsPayloadForDeleteOperation(deleteBUModelDetail: any): string {
    if (deleteBUModelDetail.actionDestinationName === Constants.SALESFORCE_ACTION_DESTINATION) {
      return '[ { "buId": "' + deleteBUModelDetail.businessUnitId
        + '", "buName": "' + deleteBUModelDetail.businessUnitName
        + '", "grantType": "client_credentials'
        + '", "apiClientId": "' + deleteBUModelDetail.apiClientId
        + '", "apiClientPassword": "' + deleteBUModelDetail.apiClientPassword + '" } ]';
    }
    return '[ { "buId": "' + deleteBUModelDetail.businessUnitId
      + '", "buName": "' + deleteBUModelDetail.businessUnitName
      + '", "clientUserName": "' + deleteBUModelDetail.clientUserName
      + '", "clientPassword": "' + deleteBUModelDetail.clientPassword + '" } ]';
  }

  public getData(): unknown {
    return this.associationModalDetail['data'];
  }

  public getTableProperties(): Record<string, unknown> {
    return this.associationModalDetail['properties'];
  }

  public isTableDataLoading(): boolean {
    return this.associationModalDetail['isDataLoading'];
  }

  public getTableTitle(): string {
    return this.associationModalDetail['title'];
  }

  public setbusinessUnitDetails(x, isDisabled) {
    const arr = new UntypedFormArray([]);
    x.actionSettings.forEach(y => {
      arr.push(this.initializeBusinessUnitDetails(y, isDisabled));
    });
    return arr;
  }

  public initializeBusinessUnitDetails(y, isDisabled) {
    return this.fb.group({
      businessUnitName: new FormControl({ value: y.buName, disabled: isDisabled }, Validators.required),
      businessUnitId: new FormControl({ value: y.buId, disabled: isDisabled }, Validators.required),
      company: new FormControl({ value: this.getCompanyValueToDisplay(y.company), disabled: isDisabled })
    });
  }

  private getCompanyValueToDisplay(companies: string): string {
    if (companies && companies !== "[]" && companies !== "null") {
      let returedCompany: string[] = [];
      let parsedCompanies = JSON.parse(companies);
      parsedCompanies[0].companyName ? parsedCompanies.forEach(cmp => returedCompany.push(cmp.companyName))
        : parsedCompanies.forEach(cmp => returedCompany.push(cmp.name));
      return returedCompany.join(', ');
    }
    return 'None';
  }

  /**
   * For action desitnations returned by the API
   * @param actionDestination - API result
   */
  public isCustomActionDestination(actionDestination: any) {
    if ('type' in actionDestination) {
      return (actionDestination.type === ActionDestinationType.CUSTOM || actionDestination.type === ActionDestinationType.AWS) ? true : false;
    }
    return false;
  }

  /**
   * For UI template
   * @param actionDestination - form group
   */
  public isCustomActionDestinationFormEntry(actionDestination: any) {
    return Object.keys(actionDestination.controls).filter(key => key === 'description').length > 0;
  }

  public setPackagedActionDestinationFormEntry(x: any, isDisabled: boolean) {
    return this.fb.group({
      displayName: x.name,
      id: x.actionDestination,
      actionSettings: this.setbusinessUnitDetails(x, isDisabled)
    });
  }

  public setCustomActionDestinationFormEntry(x: any) {
    const description = 'description' in x ? x.description : '';
    const group = this.fb.group({
      displayName: x.name,
      id: x.actionDestination,
      description: description
    });
    return group;
  }

  public editActionDestination(control: any) {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Loading action destination';
    this.actionDestinationService.getActionDestination(control.controls.id.value, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(
      data => {
        this.operationInProgress = false;
        const result = data['result'];
        const actionDestination = this.parseAPIResponse(result);
        if (result.type === ActionDestinationType.AWS) {
          this.awsActionDestination = actionDestination;
          this.awsUserCredential = {};
          this.isInitializeEditMode = true;
          this.existingCustomActionDestinationId = actionDestination['id'];
          this.isAWSActionDestinationShown = true;
        } else if (result.type === ActionDestinationType.CUSTOM) {
          this.customActionDestination = actionDestination;
          this.customADOAuthCredential = {};
          this.isInitializeEditMode = true;
          this.existingCustomActionDestinationId = actionDestination['id'];
          this.isCustomActionDestinationModalShown = true;
        }
      },
      error => {
        this.operationInProgress = false;
        this.popupService.showErrorMessage(error);
      });
  }

  public isBusinessUnitPresentForActionDestination(actionDestinationId: string) {
    return actionDestinationId.includes(this.HARMONY_ACTION_DESTINATION) || actionDestinationId === Constants.SALESFORCE_ACTION_DESTINATION;
  }

  public getOperationFailureMessage() {
    return Messages.actionDestinationOperationFailureMessage;
  }

  public hasAssociation(actionDestination) {
    return this.hasAssociationMap[actionDestination];
  }

  public getConfiguredEntityAssociationParams(actionDestination) {
    return this.configuredEntityAssociationMap[actionDestination];
  }

  public setAssociationInProgress(event) {
    if (event === null || event === undefined) {
      return;
    }
    this.operationInProgress = true;
  }

  public setAssociationDone(event: Record<string, any>) {
    if (event === null || event === undefined) {
      return;
    }
    this.operationInProgress = false;
    this.hasAssociationMap[event.ID_IDENTIFIER] = event[HAS_ASSOCIATIONS_IDENTIFIER];
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public hasOperationFailed(i, actionDestination: string): boolean {
    return this.operationFailures[i] || this.hasAssociation(actionDestination);
  }

  public isRemoveDisabled(actionDestination): boolean {
    return (this.isCustomActionDestinationFormEntry(actionDestination) ? false :
      actionDestination.get('actionSettings').length !== 0) || this.hasAssociation(actionDestination.value.id);
  }

  public isEditDisabled(actionDestination): boolean {
    return this.hasAssociation(actionDestination);
  }

  public isCustomActionDestinationModalDisplayed(): boolean {
    return this.isCustomActionDestinationModalShown;
  }

  public getCustomActionDestination(): Record<string, unknown> {
    return this.customActionDestination;
  }

  public getOAuthCredential(): Record<string, unknown> {
    return this.customADOAuthCredential;
  }

  public getEditMode(): boolean {
    return this.isInitializeEditMode;
  }

  public getExistingCustomActionDestinationId(): string {
    return this.existingCustomActionDestinationId;
  }

  public setCustomActionDestinationModalDisplayStatus($event: boolean): void {
    this.isCustomActionDestinationModalShown = $event;
  }

  public captureConfiguredActionDestination(result: { refresh: boolean }): void {
    if (!result) {
      return;
    }
    if (result.refresh) {
      this.clearFormArray(<UntypedFormArray> this.actionDestinationsForm.controls.actionDestination);
      this.loadAllActionDestinations();
    }
  }

  public isAWSActionDestinationModalDisplayed(): boolean {
    return this.isAWSActionDestinationShown;
  }

  public getAWSActionDestination(): Record<string, unknown> {
    return this.awsActionDestination;
  }

  public getAWSUserCredential(): Record<string, unknown> {
    return this.awsUserCredential;
  }

  public setAWSActionDestinationModalDisplayStatus($event: boolean): void {
    this.isAWSActionDestinationShown = $event;
  }

  public isAddBUModalDisplayed(): boolean {
    return this.isAddBUModalShown;
  }

  public getPopupActionDestination(): any {
    return this.popupActionDestination;
  }

  public setIsBUModalDisplayStatus($event: boolean): void {
    this.isAddBUModalShown = $event;
  }

  public captureConfiguredBusinessUnit(businessUnit: BusinessUnit): void {
    this.addBusinessUnitResponse = businessUnit;
    const isDisabled = true;
    this.businessUnitControl.push(
      this.initializeBusinessUnitDetails({
        buName: this.addBusinessUnitResponse.businessUnitName,
        buId: this.addBusinessUnitResponse.businessUnitId,
        company : JSON.stringify(this.addBusinessUnitResponse.company)
      }, isDisabled));
  }

  public handleDeleteDecision(decision: boolean) {
    if (this.warningModal.getEntityType() === 'ACTION_DESTINATION') {
      this.handleDeleteADDecision(decision);
    } else if (this.warningModal.getEntityType() === 'BUSINESS_UNIT') {
      this.handleDeleteBUDecision(decision);
    }
  }

  public getAssociationsBtnTxt(): string {
    return Messages.viewAssociationsBtnTxt;
  }

  public openAssociationsModal(actionDestination: string): void {
    const entityType = 'ACTION_DESTINATION';
    this.associationsTableBuilderService
      .withEntityType(entityType)
      .withConfiguredEntityAssociationParams(this.getConfiguredEntityAssociationParams(actionDestination))
      .buildAssociationTableDataAfterLookup()
      .subscribe(associationTableData => {
        if (associationTableData[ENTITY_TYPE] === entityType) {
          this.launchAssociationsTableModal(associationTableData.tableData);
        }
      });
    // Track association status - in progress
    this.associationsTableBuilderService.trackAssociationInProgress().subscribe($event => {
      this.setAssociationInProgress($event);
    });
    // Track association status - done
    this.associationsTableBuilderService.trackAssociationDone().subscribe($event => {
      if ($event[ENTITY_TYPE] !== entityType
        || !(ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER in $event)
        || !(HAS_ASSOCIATIONS_IDENTIFIER in $event)
        || !(ID_IDENTIFIER in $event)) {
        return;
      }
      this.setAssociationDone($event);
    });
  }

  private launchAssociationsTableModal(associationModalDetail: Record<string, any>): void {
    this.infoModal.launchModal({
      'title': associationModalDetail['title'],
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': associationModalDetail['data'],
        'tableConfig': {
          'properties': associationModalDetail['properties'],
          'isDataLoading': associationModalDetail['isDataLoading']
        }
      }
    });
  }

  private launchAssociationsModal(): void {
    this.infoModal.launchModal({
      'title': this.getTableTitle(),
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': this.getData(),
        'tableConfig': {
          'properties': this.getTableProperties(),
          'isDataLoading': this.isTableDataLoading()
        }
      }
    });
  }

  private loadAllActionDestinations() {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Loading action destinations';
    this.actionDestinationService.getActionDestinations(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(data => {
      this.actionDestinationResults = data;
      this.setActionDestination();
      this.validateAssociationStatus();
      this.operationInProgress = false;
    }, error => {
      this.operationInProgress = false;
      this.actionDestinationResults = null;
      if (error.error.statusMessage === 'NOT_FOUND') {
        // Do nothing
      }
    });
  }

  private processAsAddPackagedActionDestinationRequest(selection: IconViewDisplayItem) {
    if (!selection) {
      return;
    }
    this.addConfiguredActionDestination({ providerName: selection.Name, providerId: selection.Id });
  }

  private processAsAddCustomActionDestinationRequest() {
    this.customActionDestination = {};
    this.customADOAuthCredential = {};
    this.isInitializeEditMode = false;
    this.existingCustomActionDestinationId = '';
    this.isCustomActionDestinationModalShown = true;
  }

  private processAsAddAwsActionDestinationRequest() {
    this.awsActionDestination = {};
    this.awsUserCredential = {};
    this.isInitializeEditMode = false;
    this.existingCustomActionDestinationId = '';
    this.isAWSActionDestinationShown = true;
  }

  private addDisplayNameAttribute(actionDestinationList: any) {
    for (const { item } of actionDestinationList['result'].map(item => ({ item }))) {
      item['displayName'] = item['name'];
      if (item['name'] === 'Custom') {
        item['displayName'] = 'Custom Destination';
      }
    }
    return actionDestinationList['result'];
  }

  private addConfiguredActionDestination(value) {
    this.operationInProgress = true;
    this.operationStatusMessage = 'Adding action destination';
    const configuredActionDestinationPayload: string = this.buildConfiguredActionDestinationPayload(value);
    this.actionDestinationService.configureActionDestination(this.parentId, configuredActionDestinationPayload).pipe(takeUntil(this.destroyed$)).subscribe(
      res => {
        this.operationInProgress = false;
        const control = <UntypedFormArray> this.actionDestinationsForm.controls.actionDestination;
        const isDisabled = false;
        control.push(
          this.fb.group({
            displayName: [value.providerName],
            id: [value.providerId],
            actionSettings: this.fb.array([])
          })
        );
      },
      (error: any) => {
        this.operationInProgress = false;
        this.popupService.showErrorMessage(error.error.statusMessage);
      });
  }

  private buildConfiguredActionDestinationPayload(value): string {
    const adPayload = {};
    adPayload['parentId'] = this.parentId;
    adPayload['actionDestination'] = value.providerId;
    adPayload['actionSettings'] = [];
    adPayload['name'] = value.providerName;
    return JSON.stringify(adPayload);
  }

  private setActionDestination(): void {
    const control = <UntypedFormArray> this.actionDestinationsForm.controls.actionDestination;
    const isDisabled = true;
    this.actionDestinationResults.result.forEach(x => {
      control.push(
        this.isCustomActionDestination(x) ? this.setCustomActionDestinationFormEntry(x) : this.setPackagedActionDestinationFormEntry(x, isDisabled));
    });
  }

  private parseAPIResponse(result: any) {
    const actionDestination = {};
    actionDestination['id'] = result['actionDestination'];
    actionDestination['name'] = result['name'];
    actionDestination['description'] = result['description'];
    const authConfig = result['actionSettings'][0];
    if (authConfig !== undefined) {
      actionDestination['authUrl'] = authConfig['url'];
      actionDestination['scope'] = authConfig['scope'];
      actionDestination['grantType'] = authConfig['grantType'];
      actionDestination['region'] = authConfig['region'];

      const credentials = JSON.parse(authConfig['credentials']);
      actionDestination['clientId'] = credentials['clientId'];
      actionDestination['clientSecret'] = credentials['clientSecret'];
      actionDestination['userId'] = credentials['userId'];
      actionDestination['password'] = credentials['password'];
      actionDestination['userName'] = credentials['userName'];
      actionDestination['accessKey'] = credentials['accessKey'];
      actionDestination['secretAccessKey'] = credentials['secretAccessKey'];
    } else {
      actionDestination['authMethod'] = AuthType.NONE;
    }
    return actionDestination;
  }

  private isCustomActionDestinationResponse(actionDestination: any) {
    return 'type' in actionDestination && actionDestination['type'] === ActionDestinationType.CUSTOM;
  }

  private clearFormArray(formArray: UntypedFormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  private validateAssociationStatus(): void {
    this.actionDestinationResults.result.forEach(actionDestination => {
      const actionDestinationProvider = actionDestination.actionDestination;
      const configuredEntityType = this.isCustomActionDestination(actionDestination) ?
        ConfiguredEntityType.CUSTOM_ACTION_DESTINATION : ConfiguredEntityType.PACKAGED_ACTION_DESTINATION;
      this.operationInProgress = true;
      this.associationService.getAssociations(this.parentId, configuredEntityType, actionDestinationProvider).subscribe(success => {
        this.hasAssociationMap[actionDestinationProvider] = success['result'][ASSOCIATION_API_FIELD_STATUS];
        this.configuredEntityAssociationMap[actionDestinationProvider] = new ConfiguredEntityAssociationParams(
          null, this.parentId, actionDestinationProvider, configuredEntityType);
        this.operationInProgress = false;
      }, () => {
        this.hasAssociationMap[actionDestinationProvider] = false;
      });
    });
  }
}