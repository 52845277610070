import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AttributeCondition } from '../../models/custom-rule/conditions/attribute-condition';
import { AttributeConditionElement } from '../../models/custom-rule/conditions/attribute-condition-element';
import { AttributeMetadata } from '../../models/custom-rule/conditions/attribute-metadata';
import { Condition } from '../../models/custom-rule/conditions/condition';
import { ConditionType } from '../../models/custom-rule/conditions/condition-type';
import { ContainerCondition } from '../../models/custom-rule/conditions/container-condition';
import { ContainerConditionElement } from '../../models/custom-rule/conditions/container-condition-element';
import { SourceType } from '../../models/custom-rule/conditions/source-type';
import { RuleWhenClause } from '../../models/custom-rule/when-clause';
import { ConfiguredEntityState } from '../../models/entity-states/configured-entity-state';
import { EntityStateSchema } from '../../models/entity-states/entity-state-schema';
import { EntityType } from '../../models/entity-type';
import { RuleBuilderService } from '../../services/rule-builder.service';
import { RuleConditionBuilderComponent } from '../rule-condition-builder/rule-condition-builder.component';

@Component({
  selector: 'app-rule-when-clause-builder',
  templateUrl: './rule-when-clause-builder.component.html',
  styleUrls: ['./rule-when-clause-builder.component.scss']
})
export class RuleWhenClauseBuilderComponent implements OnInit {

  @ViewChild('whenClauseConditionsBuilder', { static: true }) public ruleConditionBuilder:RuleConditionBuilderComponent;

  @Input() public ruleWhenClause: RuleWhenClause;
  @Input() public isReadOnlyRule = false;
  @Input() public attributeMap: { [key: string]: AttributeMetadata };
  @Input() public showDeleteButton: boolean;
  @Input() public validationRequired: boolean;
  public whenClauseConditions: ContainerConditionElement;

  public constructor(private ruleBuilderService: RuleBuilderService) {

  }

  public ngOnInit(): void {
    this.resetWhenClauseConditions();
    this.buildWhenClauseConditions(this.ruleWhenClause);
  }

  public resetWhenClauseConditions(): void {
    this.setWhenClauseConditions(this.getEmptyContainerConditionElement());
  }

  public setWhenClauseConditions(containerConditionElement: ContainerConditionElement): void {
    this.whenClauseConditions = containerConditionElement;
  }

  public validate(): boolean {
    if (!this.whenClauseConditions || !this.whenClauseConditions.conditions || this.whenClauseConditions.conditions.length === 0 ) {
      return false;
    }
    return this.ruleConditionBuilder.validateContainerConditionElement(this.whenClauseConditions.conditions);
  }

  public build(configuredEntityStates: ConfiguredEntityState[]): RuleWhenClause {
    const whenClause: RuleWhenClause = new RuleWhenClause();
    const condition: Condition = this.ruleBuilderService.buildCondition(this.whenClauseConditions);
    whenClause.condition = condition;
    const entityStateSchemas: EntityStateSchema[] = this.getEntityStateSchemasUsed(this.whenClauseConditions, configuredEntityStates);
    whenClause.entitySchemas = entityStateSchemas;
    return whenClause;
  }

  private getEmptyContainerConditionElement(): ContainerConditionElement {
    const containerConditionElement: ContainerConditionElement = new ContainerConditionElement();
    containerConditionElement.conditions = [];
    containerConditionElement.type = ConditionType.AND;
    return containerConditionElement;
  }

  private buildWhenClauseConditions(ruleWhenClause: RuleWhenClause) {
    if ( ruleWhenClause && ruleWhenClause.condition) {
      const containerConditionElement: ContainerConditionElement
        = this.buildContainerConditionElement(ruleWhenClause.condition as ContainerCondition, 0);
      this.whenClauseConditions.type = containerConditionElement.type;
      this.whenClauseConditions.conditions.push(...containerConditionElement.conditions);
    }
  }

  private buildContainerConditionElement(containerCondition: ContainerCondition, level: number): ContainerConditionElement {
    const containerConditionElement: ContainerConditionElement = new ContainerConditionElement();
    containerConditionElement.type = containerCondition.type;
    containerConditionElement.conditions = [];
    containerCondition.conditions.forEach(condition => {
      if (condition['conditions']) {
        containerConditionElement.conditions.push(this.buildContainerConditionElement(condition as ContainerCondition, level + 1));
      } else {
        const attributeConditionElement: AttributeConditionElement = new AttributeConditionElement(true, true, condition as AttributeCondition, '');
        containerConditionElement.conditions.push(attributeConditionElement);
      }
    });
    return containerConditionElement;
  }

  private getEntityStateSchemasUsed(whenClauseConditions: ContainerConditionElement,
    configuredEntityStates: ConfiguredEntityState[]): EntityStateSchema[] {
    const entityStateDetails = configuredEntityStates.map(item =>
      ({ id: item.id, name: item.name, key: item.entityStateKey, isSystem: (item.type === EntityType.PACKAGED ? true : false) }));
    const entityStateIdsUsedInCondition: string[] = this.getEntityStateIdFromCondition(whenClauseConditions);
    const entityStatesDetailsUsedInCondition = entityStateDetails.filter(
      entityStateSchema => entityStateIdsUsedInCondition.includes(entityStateSchema.id));
    const entityStateSchemasUsedInCondition: EntityStateSchema[] = [];
    entityStatesDetailsUsedInCondition.forEach(entityStateDetail => {
      const entityStateSchema: EntityStateSchema = new EntityStateSchema();
      entityStateSchema.id = entityStateDetail.id;
      entityStateSchema.name = entityStateDetail.name;
      entityStateSchema.key = entityStateDetail.key;
      entityStateSchema.isSystem = entityStateDetail.isSystem;
      entityStateSchemasUsedInCondition.push(entityStateSchema);
    });
    return entityStateSchemasUsedInCondition;
  }

  private getEntityStateIdFromCondition(containerConditionElement: ContainerConditionElement): string[] {
    const entityStateIdsUsedInCondition: string[] = [];
    this.initEntityStateIdFromCondition(containerConditionElement, entityStateIdsUsedInCondition);
    return entityStateIdsUsedInCondition;
  }

  private initEntityStateIdFromCondition(containerConditionElement: ContainerConditionElement, entityStateIdsUsedInCondition: string[]): void {
    containerConditionElement.conditions.forEach(nextConfCondition => {
      if (Object.prototype.hasOwnProperty.call(nextConfCondition, 'conditions')) {
        this.initEntityStateIdFromCondition(nextConfCondition, entityStateIdsUsedInCondition);
      } else {
        const confAttributeCondition = nextConfCondition as AttributeConditionElement;
        if (confAttributeCondition.attributeCondition.sourceType === SourceType.ENTITY_STATE) {
          entityStateIdsUsedInCondition.push(confAttributeCondition.attributeCondition.entityStateId);
        }
      }
    });
  }

}