<div class="row pt-1">
    <div class="col text-right">
        <app-ae-button type="button" [buttonText]="messages.newGroupButtonText" buttonType="tertiary"
            (click)="addNewGroup(groupInfo)"></app-ae-button>
    </div>
</div>
<div class="row pt-1">
    <div class="col">
        <coreui-table>
            <tr coreUITableHeaderRow>
                <th scope="col">
                    <coreui-table-header-column> Group Name </coreui-table-header-column>
                </th>
                <th scope="col">
                    <coreui-table-header-column> Share
                        <div class="tooltip-content" *ngIf="getShares() != 100">
                            <span> &nbsp; &nbsp;</span>
                            <a href="javascript: void(0)" [coreUITooltip]="shareValidationTooltip" position="bottom">
                                <i coreUIIcon="warning" scale="1.4" style="color: #eb770ce3"></i>
                            </a>
                        </div>
                    </coreui-table-header-column>
                </th>
                <th scope="col">
                    <coreui-table-header-column> {{messages.primaryModel}} </coreui-table-header-column>
                </th>
                <th scope="col">
                    <coreui-table-header-column> {{messages.fallbackModel}} </coreui-table-header-column>
                </th>
                <th scope="col" aria-label="actions" coreUITableActionColumn></th>
            </tr>
            <tbody [formGroup]="myGroup">
                <tr formArrayName="groupInfos" *ngFor="let group of myGroup.controls.groupInfos.controls; let groupIndex = index" coreUITableRow>
                    <ng-container [formGroupName]="groupIndex">
                        <td class="col-3">
                            <coreui-form-field>
                                <input coreUIInput name="name" value="{{group.value.name}}" formControlName="name"
                                    class="form-control" [coreUITooltip]="groupTooltip" />
                            </coreui-form-field>
                            <div *ngIf="group.controls.name?.invalid && (group.controls.name?.dirty || group.controls.name?.touched)"
                                class="text-color-warning">
                                <ng-container *ngIf="group.controls.name.errors?.required">
                                    {{messages.groupNameEmptyMessage}}
                                </ng-container>
                                <ng-container *ngIf="group.controls.name.errors?.maxlength">
                                    {{messages.groupNameMaxValueMessage}}
                                </ng-container>
                            </div>
                        </td>
                        <td class="col-3">
                            <coreui-form-field>
                                <input type="text" value="{{group.value.share}}" formControlName="share" coreUIInput
                                    coreUIPercentMask class="shareInputField" />
                            </coreui-form-field>
                            <div *ngIf="group.controls.share?.invalid && (group.controls.share?.dirty || group.controls.share?.touched)"
                                class="text-color-warning" formControlName='share'>
                                <ng-container *ngIf="group.controls.share.errors?.required">
                                    {{messages.shareValuesEmptyMessage}}
                                </ng-container>
                                <ng-container *ngIf="group.controls.share.errors?.max">
                                    {{messages.sharesMaxValueMessage}}
                                </ng-container>
                                <ng-container *ngIf="group.controls.share.errors?.min">
                                    {{messages.sharesMinValueMessage}}
                                </ng-container>
                            </div>
                        </td>
                        <td class="col-4">
                            <app-model-selection-modal
                                (isSelectedModel)="getSelectedModel($event, 'primaryModel', group)"
                                [models]="getPrimaryModels(group)" [buttonLabel]="'{{messages.selectModel}}'">
                                <app-ae-button type="button"
                                    *ngIf="group.controls.primaryModel.value == null; else primaryModelName"
                                    buttonText="{{messages.selectModel}}" buttonType="secondary"></app-ae-button>
                                <ng-template #primaryModelName>
                                    <div>
                                        <span>{{group.controls.primaryModel.value.name}}</span>
                                        <app-ae-button type="button" buttonType="icon-large" icon="edit">
                                        </app-ae-button>
                                    </div>
                                </ng-template>
                            </app-model-selection-modal>
                            <div class="row pt-4 pl-3 text-color-warning"
                                *ngIf="displayGroupValidationMessage && validatePrimaryModel(group.controls?.primaryModel.value)">
                                {{messages.primaryModelValidationperGroup}}
                            </div>
                        </td>
                        <td class="col-4">
                            <app-model-selection-modal
                                (isSelectedModel)="getSelectedModel($event, 'fallbackModel', group)"
                                [models]="fallbackModelList(group)" [buttonLabel]="'{{messages.selectModel}}'">
                                <app-ae-button type="button"
                                    *ngIf="group.controls.fallbackModel.value == null; else fallbackModelName"
                                    buttonText="{{messages.selectModel}}" buttonType="secondary"></app-ae-button>
                                <ng-template #fallbackModelName>
                                    <div>
                                        <span>{{group.controls.fallbackModel.value.name}}</span>
                                        <app-ae-button type="button" buttonType="icon-large" icon="edit">
                                        </app-ae-button>
                                    </div>
                                </ng-template>
                            </app-model-selection-modal>
                        </td>
                        <td aria-label="actions" coreUITableActionColumn>
                            <button coreUIButton="icon" (click)="deleteGroup(groupInfo, groupIndex)"
                                [disabled]="disableDeleteButton()"><i coreUIIcon="trash"></i></button>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </coreui-table>
        <ng-template #shareValidationTooltip>
            <span class="tooltipMessage">
                Share percentages <br> must sum to 100.
            </span>
        </ng-template>
        <div class="tooltip-container">
            <ng-template #groupTooltip>
                Provide a unique name that <br> convey the purpose or intent of the group, <br> and allow for clear
                communication when <br> viewing performance reports
            </ng-template>
        </div>