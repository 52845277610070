import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ConfiguredRule } from '../../models/configured-rule.model';
import { AttributeMetadata } from '../../models/custom-rule/conditions/attribute-metadata';
import { DataTypes } from '../../models/custom-rule/conditions/data-types';
import { DropArea } from '../../models/custom-rule/conditions/drop-area';
import { SourceType } from '../../models/custom-rule/conditions/source-type';
import { ValueType } from '../../models/custom-rule/conditions/value-type';
import { Argument } from '../../models/custom-rule/then-clause/operation/argument';
import { ArgumentName } from '../../models/custom-rule/then-clause/operation/argument-name';
import { EntityStateAttribute } from '../../models/custom-rule/then-clause/operation/entity-state-attribute';
import { OperandType } from '../../models/custom-rule/then-clause/operation/operand-type';
import { OperationName } from '../../models/custom-rule/then-clause/operation/operation-name';
import { RuleThenClauseOperation } from '../../models/custom-rule/then-clause/operation/rule-then-clause-operation';
import { TreeNode } from '../../models/custom-rule/tree-node';
import { RuleBuilderService } from '../../services/rule-builder.service';
import { RuleThenClauseOperationComponent } from '../rule-then-clause-operation/rule-then-clause-operation.component';

@Component({
  selector: 'app-rule-then-clause-operation-builder',
  templateUrl: './rule-then-clause-operation-builder.component.html',
  styleUrls: ['./rule-then-clause-operation-builder.component.scss']
})
export class RuleThenClauseOperationBuilderComponent implements OnInit {

  @Input()
  public formGroup: UntypedFormGroup;

  @Input()
  public isReadOnlyRule = false;

  @Input()
  public showDeleteButton: boolean;

  @Input()
  public validationRequired: boolean;

  @Input()
  public configuredRule: ConfiguredRule;

  @Input()
  public attributeMap: { [key: string]: AttributeMetadata };

  @ViewChildren(RuleThenClauseOperationComponent)
  public ruleThenClauseOperationComponent: QueryList<RuleThenClauseOperationComponent>;

  public droparea = DropArea;
  public rightPaneOperations: RuleThenClauseOperation[] = [];

  public ruleBuilderService: RuleBuilderService;

  public constructor(ruleBuilderService: RuleBuilderService) {
    this.ruleBuilderService = ruleBuilderService;
  }

  public ngOnInit(): void {
    this.rightPaneOperations = [];
    this.formGroup.addControl('operations', new UntypedFormArray([]));
    this.setRightPanelOperations(this.configuredRule);
  }

  public initRightPaneOperations(): void {
    this.rightPaneOperations = [];
  }

  public onRemoveOperationController(operationRemoved: number): void {
    this.rightPaneOperations.splice(operationRemoved, 1);
  }

  public canDropEntityStateOperation(attribute: unknown): boolean {
    const treeNode: TreeNode = attribute['data'];
    if (!this.isNodeOfTypeEntityState(treeNode)) {
      return false;
    }
    if (!this.isSupportedDataType(treeNode)) {
      return false;
    }
    return true;
  }

  public onEntityStateOperationDropped(event: unknown): void {
    const attributeMetadataId = event['element']['data']['id'];
    const entityStateAttributeMetaData: AttributeMetadata = Object.assign(new AttributeMetadata(), this.attributeMap[attributeMetadataId]);
    const ruleThenClauseOperation: RuleThenClauseOperation = this.buildEntityStateOperation(entityStateAttributeMetaData);
    this.rightPaneOperations.push(ruleThenClauseOperation);
  }

  public buildOperations(): RuleThenClauseOperation[] {
    const operations: RuleThenClauseOperation[] = [];
    const ruleThenClauseOperationComponents: RuleThenClauseOperationComponent[] = this.ruleThenClauseOperationComponent.toArray();
    ruleThenClauseOperationComponents.forEach(ruleThenClauseOperationComponent => operations.push(ruleThenClauseOperationComponent.builOperation()));
    return operations;
  }

  public validateOperations(): boolean {
    let validationStatus = true;
    const ruleThenClauseOperationComponents: RuleThenClauseOperationComponent[] = this.ruleThenClauseOperationComponent.toArray();
    ruleThenClauseOperationComponents.forEach(ruleThenClauseOperationComponent => {
      validationStatus = validationStatus && ruleThenClauseOperationComponent.validate();
    });
    return validationStatus;
  }

  public showDropArea(): boolean {
    return this.showDeleteButton;
  }

  private isNodeOfTypeEntityState(attribute: TreeNode): boolean {
    return attribute.nodeType === SourceType.ENTITY_STATE;
  }

  private isSupportedDataType(attribute: TreeNode): boolean {
    const entityStateAttributeMetaData: AttributeMetadata = this.attributeMap[attribute.id];
    return entityStateAttributeMetaData.type === DataTypes.string
      || entityStateAttributeMetaData.type === DataTypes.number
      || entityStateAttributeMetaData.type === DataTypes.array;
  }

  private setRightPanelOperations(configuredRule: ConfiguredRule): void {
    this.rightPaneOperations = configuredRule.getRuleThenClauseOperations();
  }

  private buildEntityStateOperation(entityStateAttributeMetaData: AttributeMetadata): RuleThenClauseOperation {
    const ruleThenClauseOperation: RuleThenClauseOperation = new RuleThenClauseOperation();
    ruleThenClauseOperation.operandType = OperandType.ENTITY_STATE;
    ruleThenClauseOperation.name = OperationName.SET;
    ruleThenClauseOperation.arguments = this.buildEntityStateOperationArguments(entityStateAttributeMetaData);
    return ruleThenClauseOperation;
  }

  private buildEntityStateOperationArguments(entityStateAttributeMetaData: AttributeMetadata): Argument[] {
    const operationArguments: Argument[] = [];

    // Build VALUE_TYPE
    const argumentValueType: Argument = new Argument();
    argumentValueType.name = ArgumentName.VALUE_TYPE;
    argumentValueType.value = ValueType.CONSTANT;

    // Build ATTRIBUTE
    const argumentAttribute: Argument = new Argument();
    argumentAttribute.name = ArgumentName.ATTRIBUTE;
    const attribute: EntityStateAttribute = new EntityStateAttribute();
    attribute.name = entityStateAttributeMetaData.name;
    attribute.path = entityStateAttributeMetaData.path;
    attribute.dataType = entityStateAttributeMetaData.getAttributeDataType();
    attribute.entityStateName = entityStateAttributeMetaData.entityStateName;
    attribute.entityStateId = entityStateAttributeMetaData.entityStateId;
    argumentAttribute.value = attribute;

    // Build VALUE
    const argumentValue: Argument = new Argument();
    argumentValue.name = ArgumentName.VALUE;
    argumentValue.value = null;

    operationArguments.push(argumentValueType);
    operationArguments.push(argumentValue);
    operationArguments.push(argumentAttribute);
    return operationArguments;
  }

}