<app-rule-then-clause-operation *ngFor="let rightPaneOperation of rightPaneOperations; let index=index"
  [index]="index"
  [ruleThenClauseOperation]="rightPaneOperation"
  [attributeMap]="attributeMap"
  [isReadOnlyRule]="isReadOnlyRule"
  [showDeleteButton]="showDeleteButton"
  [validationRequired]="validationRequired"
  (removeOperationController)="onRemoveOperationController($event)"
></app-rule-then-clause-operation>
<div class="row m-1" *ngIf="showDropArea() && rightPaneOperations.length > 0">
  <div class="col drop-attributes py-1"
    (treeDrop)="onEntityStateOperationDropped($event)"
    [treeAllowDrop]="canDropEntityStateOperation.bind(this)"
    (treeDropDragOver)="ruleBuilderService.treeDropDragOver($event, droparea.OPERATION, rightPaneOperations.length)"
    (treeDropDragLeave)="ruleBuilderService.treeDropDragLeave($event, droparea.OPERATION, rightPaneOperations.length)"
  >
  </div>
</div>
<div class="row mx-1 my-2 rule-build-help drop-attributes" *ngIf="showDropArea() && rightPaneOperations.length === 0"
  (treeDrop)="onEntityStateOperationDropped($event)"
  [treeAllowDrop]="canDropEntityStateOperation.bind(this)">
  <div class="col p-0 text-center">
    <p class="text-style-muted-2 py-4 mb-0">
      Drag and drop entity state attribute to build an operation
    </p>
  </div>
</div>
  
