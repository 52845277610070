import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Pattern } from 'src/app/shared/pattern';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { Parent } from 'src/app/shared/models/parent';
import { VerticalType } from 'src/app/shared/models/vertical-type.enum';
import { ParentService } from 'src/app/shared/services/parent.service';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { Constants, OperationType } from '../../../shared/constants';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html'
})
export class ClientFormComponent extends BaseFormDirective implements OnInit, OnDestroy {

  public parentid: string;
  public isApiServiceCallInProgress: boolean;
  public messages = Messages;
  public verticalTypeList = [];
  public inputParams = {
    id: '',
    action: ''
  };

  private parent: Parent;
  private parents: Parent[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private fb: UntypedFormBuilder,
    private parentContextService: ParentContextService,
    private parentService: ParentService,
    private route: ActivatedRoute,
    private router: Router,
    private popupService: PopupMessageService) {
    super();
    this.initInputParam(route);
  }

  ngOnInit(): void {
    this.parentid = this.parentContextService.getParentContext();
    this.getParents();
    this.buildParentForm();
    this.populateVerticalTypeList();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public canNavigateAway(): boolean {
    if (this.isDataSaved) {
      return true;
    }
    return !(this.inputParams.action === OperationType.ADD && this.isCreateFormUpdated() ||
        this.inputParams.action === OperationType.EDIT && this.isEditFormUpdated());
  }

  private isEditFormUpdated(): boolean {
    return this.parent.name !== this.configuredEntityForm.controls.parentName.value ||
        this.parent.verticalType !== this.configuredEntityForm.controls.verticalType.value ||
        this.parent.reportingEnabled !== this.configuredEntityForm.controls.reportingEnabled.value;
  }

  private isCreateFormUpdated(): boolean {
    return this.configuredEntityForm.controls.parentId.value !== '' ||
        this.configuredEntityForm.controls.parentName.value !== '' ||
        this.configuredEntityForm.controls.verticalType.value !== '' ||
        this.configuredEntityForm.controls.reportingEnabled.value !== false;
  }

  private initInputParam(route: ActivatedRoute): void {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.inputParams.action = route.snapshot.paramMap.get('action');
      this.inputParams.id = route.snapshot.paramMap.get('id');
    });
  }

  private buildParentForm(): void {
    this.parent = new Parent();
    if (this.router.url === '/client/details/edit') {
      this.inputParams.action = OperationType.EDIT;
      this.inputParams.id = this.parentid;
      this.getPersistedParent(this.parentid);
    } else if (this.inputParams.action === OperationType.EDIT) {
      this.getPersistedParent(this.inputParams.id);
    } else {
      this.buildForm();
    }
  }

  private getPersistedParent(id: string): void {
    this.isApiServiceCallInProgress = true;
    this.parentService.getParent(id)
      .pipe(takeUntil(this.destroyed$)).subscribe((res: Parent[]) => {
        this.isApiServiceCallInProgress = false;
        this.parent = res['result'];
        this.setParent();
      }, error => {
        this.isApiServiceCallInProgress = false;
        this.popupService.showErrorMessage(error.error.statusMessage);
      });
  }

  private setParent(): void {
    this.buildForm();
    this.disableUneditableFields();
  }

  private buildForm(): void {
    this.configuredEntityForm = this.fb.group({
      parentName: new UntypedFormControl(this.parent.name,
        { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC_WITH_SPACE), Validators.maxLength(150)] }),
      parentId: new UntypedFormControl(this.parent.id,
        { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC), Validators.maxLength(150)] }),
      verticalType: new UntypedFormControl(this.parent.verticalType, { validators: Validators.required }),
      reportingEnabled: new UntypedFormControl(this.parent.reportingEnabled, { validators: Validators.required })
    });
  }

  public saveParent(): void {
    this.isSaveClicked = true;
    if (!this.configuredEntityForm.valid) {
      this.popupService.showErrorMessage(Messages.requiredFields);
    } else {
      this.parent.name = this.configuredEntityForm.controls.parentName.value;
      this.parent.id = this.configuredEntityForm.controls.parentId.value;
      this.parent.verticalType = this.configuredEntityForm.controls.verticalType.value;
      this.parent.reportingEnabled = this.configuredEntityForm.controls.reportingEnabled.value;

      if (this.inputParams.action === OperationType.EDIT) {
        this.updateParent(this.inputParams.id, this.parent);
      } else {
        this.addParent(this.parent);
      }
    }
  }

  private addParent(parent: Parent): void {
    this.isApiServiceCallInProgress = true;
    this.parentService.addParent(parent)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.isApiServiceCallInProgress = false;
        this.isDataSaved = true;
        this.isSaveClicked = false;
        this.popupService.showSuccessMessage(Messages.parent + " " + this.configuredEntityForm.controls.parentName.value + ' successfully added', true);
        this.inputParams.action = OperationType.EDIT;
        this.router.navigateByUrl('admin/' + Messages.parents, {skipLocationChange: true}).then( () => {
          this.router.navigateByUrl('admin/' + Messages.parents + '/edit/' + this.parent.id);
        });
        this.disableUneditableFields();
      }, error => {
        this.isDataSaved = false;
        this.isSaveClicked = false;
        this.isApiServiceCallInProgress = false;
        this.popupService.showErrorMessage(error.error.statusMessage);
      });
  }

  private updateParent(id: string, parent: Parent): void {
    this.isApiServiceCallInProgress = true;
    this.parentService.updateParent(id, parent)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.isApiServiceCallInProgress = false;
        this.popupService.showSuccessMessage(Messages.parent + " " + this.configuredEntityForm.controls.parentName.value + ' successfully updated');
        this.isDataSaved = false;
        this.isSaveClicked = false;
      }, error => {
        this.isApiServiceCallInProgress = false;
        this.isSaveClicked = false;
        this.isDataSaved = false;
        this.popupService.showErrorMessage(error.error.statusMessage);
      });
  }

  private getParents(): void {
    this.parentService.getParents()
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: Parent[]) => {
        this.parents = serviceResponse['result'];
      }, () => {
        this.popupService.showErrorMessage(Messages.parentsRequestError);
      });
  }

  private populateVerticalTypeList(): void {
    for(const key of Object.keys(VerticalType)) {
      this.verticalTypeList.push({
          id: key,
          name: 'verticalType',
          value: key,
          label: VerticalType[key],
          formControlName: 'verticalType'
      });
    }
  }

  public checkIfParentNameAlreadyExists(): void {
    if (this.parents !== undefined) {
      const isAlreadyTaken = this.parents.find(existingParent =>
      existingParent.name.toLowerCase() === (<string> this.configuredEntityForm.controls.parentName.value).toLowerCase());
      if (isAlreadyTaken !== undefined) {
        this.configuredEntityForm.controls.parentName.setErrors({ 'alreadyExist': true });
      }
    }
  }

  public checkIfParentIdAlreadyExists(): void {
    if (this.parents !== undefined) {
      const isAlreadyTaken = this.parents.find(existingParent =>
      existingParent.id.toLowerCase() === (<string> this.configuredEntityForm.controls.parentId.value).toLowerCase());
      if (isAlreadyTaken !== undefined) {
        this.configuredEntityForm.controls.parentId.setErrors({ 'alreadyExist': true });
      }
    }
  }

  public get parentName(): AbstractControl {
    return this.configuredEntityForm.get('parentName');
  }

  public get parentId(): AbstractControl {
    return this.configuredEntityForm.get('parentId');
  }

  public disableUneditableFields(): void {
    this.configuredEntityForm.controls['parentId'].disable();
  }

  public onReportingEnabledChange(): void {
  }

  public onVerticalTypeChange(): void {
  }

  public isClientDetails(): boolean {
    return !this.router.url.includes('/client/details') ? true : false;
  }

}
