import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ae-section-header',
  templateUrl: './ae-section-header.component.html'
})
export class AESectionHeaderComponent {

  @Input() headerText: string;

}