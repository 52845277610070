import { Constants, RuleTypes } from '../constants';
import { ProgramType } from '../program-type';
import { CustomRule } from './custom-rule/custom-rule';
import { DroolsRule } from 'src/app/shared/models/rule/drools-rule';

export class Configuration {

  companyId: string;
  eventSource: string;
  eventType: string;
  configuredActionSettingList: string;
  programInput: string;
  rule: string;
  rules: string;
  exclusions: string;
  entityKey: string;
  eventSourceName: string;

  public getSourceAndEventsMap(programType: ProgramType): Map<string, string[]> {
    let sourceAndExternalEventsMap: Map<string, string[]> = new Map<string, string[]>();
    switch (programType) {
      case ProgramType.PACKAGED:
        sourceAndExternalEventsMap = this.getSourceAndEventsMapOfPackagedProgram();
        break;
      case ProgramType.SELF_SERVICE:
        sourceAndExternalEventsMap = this.getSourceAndEventsMapOfCustomProgram();
        break;
      case ProgramType.SELF_SERVICE_EXTERNAL:
        sourceAndExternalEventsMap = this.getSourceAndEventsMapOfCustomExternalProgram();
        break;
      case ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED:
        sourceAndExternalEventsMap = this.getSourceAndEventsMapOfCustomExternalPackagedProgram();
        break;
    }
    return sourceAndExternalEventsMap;
  }

  private getSourceAndEventsMapOfPackagedProgram(): Map<string, string[]> {
    const rules: Record<string, unknown>[] = JSON.parse(this.rules) as Record<string, unknown>[];
    const sourceAndExternalEventsMap : Map<string, string[]> = new Map<string, string[]>();
    const eventNames: string[] = [];
    rules.forEach((rule: Record<string, unknown>) => {
      const ruleId = String(rule.ruleId);
      const ruleNameEventType: string[] = ruleId.split(Constants.DB_KEY_SEPARATOR);
      const eventType: string = ruleNameEventType[1];
      eventNames.push(eventType);
    });
    sourceAndExternalEventsMap.set(this.eventSource, eventNames);
    return sourceAndExternalEventsMap;
  }

  private getSourceAndEventsMapOfCustomProgram(): Map<string, string[]> {
    const sourceAndExternalEventsMap : Map<string, string[]> = new Map<string, string[]>();
    sourceAndExternalEventsMap.set(this.eventSource, new Array(this.eventType));
    return sourceAndExternalEventsMap;
  }

  private getSourceAndEventsMapOfCustomExternalProgram(): Map<string, string[]> {
    let rules: Record<string, unknown>[] = JSON.parse(this.rules) as Record<string, unknown>[];
    rules = rules.filter(rule => rule.type !== RuleTypes.DROOLS);
    const sourceAndExternalEventsMap : Map<string, string[]> = new Map<string, string[]>();
    rules.forEach((rule: Record<string, unknown>) => {
      const eventKey: Record<string, string> = rule.eventKey as Record<string, string>;
      if (sourceAndExternalEventsMap.has(eventKey.eventSourceName)) {
        const eventNames: Set<string> = new Set(sourceAndExternalEventsMap.get(eventKey.eventSourceName));
        eventNames.add(eventKey.eventName);
        sourceAndExternalEventsMap.set(eventKey.eventSourceName, Array.from(eventNames));
      } else {
        sourceAndExternalEventsMap.set(eventKey.eventSourceName, new Array(eventKey.eventName));
      }
    });
    return sourceAndExternalEventsMap;
  }

  private getSourceAndEventsMapOfCustomExternalPackagedProgram(): Map<string, string[]> {
    const rules: CustomRule[] = JSON.parse(this.rules);
    const eventSource = rules.find(rule => rule.type === RuleTypes.PACKAGED).eventKey.eventSourceName;
    const sourceAndExternalEventsMap : Map<string, string[]> = new Map<string, string[]>();
    rules.forEach(rule => {
      let eventName = '';
      if (rule.type === RuleTypes.DROOLS) {
        const drrolsRule: DroolsRule = rule as unknown as DroolsRule;
        eventName = drrolsRule.ruleId.split(Constants.DB_KEY_SEPARATOR)[1];
      } else {
          eventName = rule.eventKey.eventName;
      }
      if (eventName) {
        if (sourceAndExternalEventsMap.has(eventSource)) {
          const eventNames: Set<string> = new Set(sourceAndExternalEventsMap.get(eventSource));
          eventNames.add(eventName);
          sourceAndExternalEventsMap.set(eventSource, Array.from(eventNames));
        } else {
          sourceAndExternalEventsMap.set(eventSource, new Array(eventName));
        }
      }
    });
    return sourceAndExternalEventsMap;
  }

  public getCompanyId(programType: string) {
    if(programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED || programType === ProgramType.SELF_SERVICE_EXTERNAL) {
      if (this.rules) {
        const rule = JSON.parse(this.rules);
          const companyIds: string[] = [];
          rule.forEach(rule => {
            if(rule.companyId) {
                companyIds.push(rule.companyId);
          }
        });
        const companyId = companyIds[0];
        return companyId;      
      }
    } else {
      return this.companyId;
    }
  }
}

export enum EXCLUSIONS {
  MSG_COUNT_IN_LAST_N_HOURS = 'MSG_COUNT_IN_LAST_N_HOURS',
  MSG_COUNT_IN_LAST_N_DAYS = 'MSG_COUNT_IN_LAST_N_DAYS',
  PURCHASES_IN_LAST_N_DAYS = 'PURCHASES_IN_LAST_N_DAYS',
  LAST_N_HOURS_FOR_MSG_COUNT = 'LAST_N_HOURS_FOR_MSG_COUNT',
  LAST_N_DAYS_FOR_MSG_COUNT = 'LAST_N_DAYS_FOR_MSG_COUNT',
  LAST_N_DAYS_FOR_PURCHASE_COUNT = 'LAST_N_DAYS_FOR_PURCHASE_COUNT',
  LAST_N_HOURS_FOR_ABANDONED_CART = 'LAST_N_HOURS_FOR_ABANDONED_CART',
  LAST_N_HOURS_FOR_ABANDONED_CHECKOUT = 'LAST_N_HOURS_FOR_ABANDONED_CHECKOUT',
  LAST_N_HOURS_FOR_ABANDONED_PRODUCT = 'LAST_N_HOURS_FOR_ABANDONED_PRODUCT',
  LAST_N_HOURS_FOR_ABANDONED_CATEGORY = 'LAST_N_HOURS_FOR_ABANDONED_CATEGORY',
  LAST_N_HOURS_FOR_ABANDONED_SEARCH = 'LAST_N_HOURS_FOR_ABANDONED_SEARCH',
  LAST_N_HOURS_FOR_ABANDONED_PROMOTION = 'LAST_N_HOURS_FOR_ABANDONED_PROMOTION',
  LAST_N_HOURS_FOR_ABANDONED_WISHLIST = 'LAST_N_HOURS_FOR_ABANDONED_WISHLIST',
  LAST_N_HOURS_FOR_ABANDONED_FAVORITES = 'LAST_N_HOURS_FOR_ABANDONED_FAVORITES',
  LAST_N_DAYS_FOR_SUBMIT_COUNT = 'LAST_N_DAYS_FOR_SUBMIT_COUNT',
  SUBMITS_IN_LAST_N_DAYS = 'SUBMITS_IN_LAST_N_DAYS',
  SIGNUP_IN_LAST_N_DAYS = 'SIGNUP_IN_LAST_N_DAYS',
  LAST_N_DAYS_FOR_CONFIRMATION_COUNT = 'LAST_N_DAYS_FOR_CONFIRMATION_COUNT',
  CONFIRMATION_IN_LAST_N_DAYS = 'CONFIRMATION_IN_LAST_N_DAYS',
  LAST_N_HOURS_FOR_TH_ABANDONED_RESERVATION_DETAILS = 'LAST_N_HOURS_FOR_TH_ABANDONED_RESERVATION_DETAILS',
  LAST_N_HOURS_FOR_TH_ABANDONED_SEARCH = 'LAST_N_HOURS_FOR_TH_ABANDONED_SEARCH',
  VIEWED_COUNT = 'VIEWED_COUNT',
  VIEWED_IN_LAST_N_PERIOD = 'VIEWED_IN_LAST_N_PERIOD',
  VIEWED_WISHLIST_COUNT = 'VIEWED_WISHLIST_COUNT',
  VIEWED_WISHLIST_IN_LAST_N_PERIOD = 'VIEWED_WISHLIST_IN_LAST_N_PERIOD',
  VIEWED_FAVORITE_COUNT = 'VIEWED_FAVORITE_COUNT',
  VIEWED_FAVORITE_IN_LAST_N_PERIOD = 'VIEWED_FAVORITE_IN_LAST_N_PERIOD',
  VIEWED_HOMEPAGE_COUNT = 'VIEWED_HOMEPAGE_COUNT',
  VIEWED_HOMEPAGE_IN_LAST_N_PERIOD = 'VIEWED_HOMEPAGE_IN_LAST_N_PERIOD',
  VIEWED_SEARCH_COUNT = 'VIEWED_SEARCH_COUNT',
  VIEWED_SEARCH_IN_LAST_N_PERIOD = 'VIEWED_SEARCH_IN_LAST_N_PERIOD',
  VIEWED_CART_COUNT = 'VIEWED_CART_COUNT',
  VIEWED_CART_IN_LAST_N_PERIOD = 'VIEWED_CART_IN_LAST_N_PERIOD',
  VIEWED_CATEGORY_COUNT = 'VIEWED_CATEGORY_COUNT',
  VIEWED_CATEGORY_IN_LAST_N_PERIOD = 'VIEWED_CATEGORY_IN_LAST_N_PERIOD',
  VIEWED_PRODUCT_COUNT = 'VIEWED_PRODUCT_COUNT',
  VIEWED_PRODUCT_IN_LAST_N_PERIOD = 'VIEWED_PRODUCT_IN_LAST_N_PERIOD',
  VIEWED_CHECKOUT_COUNT = 'VIEWED_CHECKOUT_COUNT',
  VIEWED_CHECKOUT_IN_LAST_N_PERIOD = 'VIEWED_CHECKOUT_IN_LAST_N_PERIOD',
  VIEWED_TH_HOMEPAGE_COUNT = 'VIEWED_TH_HOMEPAGE_COUNT',
  VIEWED_TH_HOMEPAGE_IN_LAST_N_PERIOD = 'VIEWED_TH_HOMEPAGE_IN_LAST_N_PERIOD',
  VIEWED_TH_RESERVATION_DETAILS_COUNT = 'VIEWED_TH_RESERVATION_DETAILS_COUNT',
  VIEWED_TH_RESERVATION_DETAILS_IN_LAST_N_PERIOD = 'VIEWED_TH_RESERVATION_DETAILS_IN_LAST_N_PERIOD',
  VIEWED_TH_PROPERTY_BROWSE_COUNT = 'VIEWED_TH_PROPERTY_BROWSE_COUNT',
  VIEWED_TH_PROPERTY_BROWSE_IN_LAST_N_PERIOD = 'VIEWED_TH_PROPERTY_BROWSE_IN_LAST_N_PERIOD',
  VIEWED_TH_SEARCH_COUNT = 'VIEWED_TH_SEARCH_COUNT',
  VIEWED_TH_SEARCH_IN_LAST_N_PERIOD = 'VIEWED_TH_SEARCH_IN_LAST_N_PERIOD',
  PERCENTAGE_VARIANCE = 'PERCENTAGE_VARIANCE',
  DROP_BRAND_NAME = 'DROP_BRAND_NAME',
  HIGHEST_PRICED_FILTER = 'HIGHEST_PRICED_FILTER',
  LOWEST_PRICED_FILTER = 'LOWEST_PRICED_FILTER'
}
