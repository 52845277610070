<div *ngIf="type === 'With Label'">
  <coreui-form-field label="{{label}}">
      <div class="row" [formGroup]=formGroup>
        <coreui-toggle enableHammerIcon={{enableHammerIcon}}
                       uncheckedLabel={{uncheckedLabel}}
                       checkedLabel={{checkedLabel}}
                       formControlName="{{name}}"
                       (change)="callback(e)">
        </coreui-toggle>
    </div>
  </coreui-form-field>
</div>
