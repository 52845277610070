import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CognitoUtil } from '../auth/cognito.service';
import { Action } from '../models/permission/feature/action';
import { LoginResponse } from '../models/permission/login-response/login-response';
import { Permission } from '../models/permission/role/permission';
import { ParentContextService } from './parent-context.service';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { LoginCallback } from './cognito.login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalSystemLoginComponent } from 'src/app/modules/external-system-login/external-system-login.component';

export interface AuthorizationCallback {
  authorizationSuccessful(message: string, loginCallback?: LoginCallback): void;
  authorizationFailed(message: string, loginCallback?: LoginCallback): void;
}

@Injectable({ providedIn: 'root' })
export class AuthorizationService {

  public static PERMISSIONS = 'permissions';
  host = environment.host;
  base = environment.base;
  authPath = 'login/';
  private featurePermissions: Map<string, boolean> = new Map<string, boolean>();
  private returnTo: string;
  private isAuthorized: boolean;

  private categoryFeatureMap = new Map<string, string[]>([
    ['PROGRAMS', ['PKG_PRG', 'CUST_PRG']],
    ['EVENTS', ['PKG_EVENT', 'CFG_EVENT']],
    ['ACTIONS', ['CFG_ACTION']],
    ['ENTITY_STATES', ['ENT_STATE', 'CFG_ENT_STATE']],
    ['CONFIGURED_RECOMMENDATION', ['CONFIGURED_RECOMMENDATION']],
    ['PARENT', ['PARENT']],
    ['RULES', ['CFG_RULE']],
    ['EXCLUSIONS', ['CFG_EXCLUSION']],
    ['PRODUCT_SWITCHER', ['PERFORMANCE_INSIGHTS']],
    ['CLIENT', ['EVENT_SRC', 'CFG_EVENT_SRC', 'ACTION_DEST', 'CFG_ACTION_DEST', 'CFG_BATCH_EXTRACT',
      'SYS_SETTING', 'CFG_SETTING', 'USR', 'PKG_ROLE', 'CFG_ROLE', 'PKG_USR_GRP', 'CFG_USR_GRP', 'SUPPORT', 'CONTENT']],
    ['USER_GROUP_ROLES', ['USR', 'PKG_ROLE', 'CFG_ROLE', 'PKG_USR_GRP', 'CFG_USR_GRP']],
    ['SUPPORT', ['SUPPORT']],
    ['CONTENT', ['CONTENT']]]);

  constructor(@Inject(LOCAL_STORAGE) private storage?: WebStorageService,
    activeRoute?: ActivatedRoute,
    public http?: HttpClient,
    public parentContext?: ParentContextService,
    public cognitoUtil?: CognitoUtil,
    private router?: Router) {
    this.buildFeaturePermissions(this.getPermissions());
    activeRoute.queryParams.subscribe(params => {
      if (params['returnTo']) {
        this.returnTo = params['returnTo'];
      } else {
        this.returnTo = '/';
      }
    });
  }

  public initializePermissionsOnLogin(parentId: string, userId: string, callback: AuthorizationCallback, loginCallback?: LoginCallback): void {
    if (this.isAuthorizationEnabled()) {
      this.getAuthorizationDetails(parentId, userId, callback, loginCallback);
    }
  }

  public initializePermissionsOnParentSwitch(callback : AuthorizationCallback, username: string): void {
    if (this.isAuthorizationEnabled()) {
      this.getAuthorizationDetails(this.parentContext.getParentContext(), username, callback, null);
    }
  }

  public isCategoryAccessible(category: string, operator: string): boolean {
    if (!this.isAuthorizationEnabled()) {
      return true;
    }

    const features = this.getPermissions()
      .filter(permission => this.categoryFeatureMap.get(category).includes(permission.feature)
      && (permission.actions.filter(a => a.allowed)).length > 0);
    if ((operator === 'OR' && features.length > 0)
            || (operator === 'AND' && this.categoryFeatureMap.get(category).length === features.length)) {
      return true;
    }
    return false;
  }

  public isAuthorizationEnabled(): boolean {
    return environment.enableAuthorization;
  }

  public isUserAuthorized(): boolean {
    if (!this.isAuthorizationEnabled()) {
      return true;
    } else {
      this.isAuthorized && this.getPermissions() && this.getPermissions().length > 0;
    }
  }

  public getAllowedFeatures(category: string): string[] {
    const features = [];
    const permissions: Permission[] = this.getPermissions()
      .filter(permission => this.categoryFeatureMap.get(category).includes(permission.feature)
      && (permission.actions.filter(a => a.allowed)).length > 0);
    permissions.filter(feature => features.push(feature.feature));
    return features;
  }

  public isAllowed(feature: string, actionTypes: any[]): boolean {
    if (!this.isAuthorizationEnabled()) {
      return true;
    }
    for (const actionType of actionTypes) {
      if (this.featurePermissions.get(feature + '_' + actionType)) {
        return true;
      }
    }
    return false;
  }

  public getUserAuthorizationDetailsLoggedInFromExternalSystem(url: string, externalSystemLogin: ExternalSystemLoginComponent,
    callback : AuthorizationCallback, loginCallback?: LoginCallback): void {
    this.http.get(url)
      .subscribe(
        res => {
          const response = new LoginResponse(res['result']);
          const parent = response.parents.filter(p => p.id === response.parentPermission.parentId)[0];
          this.parentContext.setParentContext(response.parentPermission.parentId);
          this.parentContext.setParentVerticalType(parent.verticalType);
          this.parentContext.setParentListContext(response.parents);
          this.persistPermissions(response.parentPermission.permissions);
          this.isAuthorized = true;
          externalSystemLogin.isPageLoading = false;
          externalSystemLogin.apiResponse = res['statusMessage'];
          const routePath = this.getRoutePath(response.parentPermission.permissions);
          callback.authorizationSuccessful(routePath, null);
        }, error => {
          this.isAuthorized = false;
          externalSystemLogin.isPageLoading = false;
          if (error.error.statusMessage === 'NOT_FOUND') {
            externalSystemLogin.isUrlError = true;
          } else {
            externalSystemLogin.apiResponse = 'FAILURE';
          }
          callback.authorizationFailed('Failed to authorize user', loginCallback);
        });
  }

  public getRoutePath(permissions: Permission[]): string {
    let defaultRoute = '';
    for (const [page, features] of this.categoryFeatureMap) {
      for (const feature of features) {
        const permission = permissions.find(p => p.feature === feature);
        if (permission && permission.actions && permission.actions.filter(a => a.allowed).length > 0) {
          if (defaultRoute.length === 0) {
            defaultRoute = page.toLowerCase();
          }
          if (defaultRoute.length !== 0 && this.returnTo === '/') {
            return defaultRoute;
          }
          if (this.returnTo.includes(page.toLowerCase())) {
            return this.returnTo;
          }
        }
      }
    }
    return 'programs';
  }

  private persistPermissions(permissions: Permission[]): void {
    this.storage.set(AuthorizationService.PERMISSIONS, permissions);
    this.buildFeaturePermissions(permissions);
  }

  private getPermissions() : Permission[] {
    const permissions: Permission[] = this.storage.get(AuthorizationService.PERMISSIONS);
    return permissions ? permissions : [];
  }

  private getAuthorizationDetails(parentId: string, userId: string, callback : AuthorizationCallback, loginCallback: LoginCallback): void {
    const url = this.host + this.base + this.authPath + userId;
    this.http.get(url, { headers: new HttpHeaders({ 'parent-id': parentId }) })
      .subscribe(
        res => {
          const response = new LoginResponse(res['result']);
          const parent = response.parents.filter(p => p.id === response.parentPermission.parentId)[0];
          this.parentContext.setParentContext(response.parentPermission.parentId);
          this.parentContext.setParentVerticalType(parent.verticalType);
          this.parentContext.setParentListContext(response.parents);
          this.persistPermissions(response.parentPermission.permissions);
          const routePath = this.getRoutePath(response.parentPermission.permissions);
          callback.authorizationSuccessful(routePath, null);
          this.isAuthorized = true;
        }, error => {
          this.isAuthorized = false;
          if (error.error.statusMessage === 'UNAUTHORIZED' || error.error.statusMessage === 'INVALID_DATA') {
            callback.authorizationFailed('User is not authorized to login', loginCallback);
          } else {
            callback.authorizationFailed('Failed to authorize user', loginCallback);
          }
        });
  }

  private buildFeaturePermissions(permissions: Permission[]): void {
    this.featurePermissions = new Map<string, boolean>();
    if (permissions && permissions.length > 0) {
      for (const permission of permissions) {
        const actions: Action[] = permission.actions;
        for (const action of actions) {
          const permType = permission.feature + '_' + action.actionType;
          this.featurePermissions.set(permType, action.allowed);
        }
      }
    }
  }


}
