import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { ReplaySubject, Subscription } from 'rxjs';
import { EntityStatesSharedDataService } from '../entity-states-shared-data.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayTextService } from 'src/app/shared/services/display-text.service';
import { JSONFileService } from 'src/app/shared/services/json-file-service';
import { ConfiguredEntityState, EventAttribute } from 'src/app/shared/models/entity-states/configured-entity-state';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { EntityAssociations } from 'src/app/shared/models/entity-associations';
import { AssociationService } from 'src/app/shared/services/association.service';
import { takeUntil } from 'rxjs/operators';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { ASSOCIATION_API_FIELD_STATUS, HAS_ASSOCIATIONS_IDENTIFIER } from 'src/app/shared/models/association/association-constants';
import { ConfiguredEntityAssociationParams } from 'src/app/shared/models/association/configured-entity-association-params';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { ValueType } from 'src/app/shared/models/custom-rule/conditions/value-type';

@Component({
  selector: 'app-entity-states-schema',
  templateUrl: './entity-states-schema.component.html'
})
export class EntityStatesSchemaComponent extends BaseFormDirective implements OnInit {

  apiCallCount = 0;
  isEdit = false;
  configuredEntityState: ConfiguredEntityState;
  configuredEntityStateId: string;
  configuredEntityStateName: string;
  operation: string;
  parentId : string;
  subscription: Subscription;
  public operationFailure = false;
  public operationFailureAssociations: EntityAssociations;
  public hasAssociation: boolean;
  public inputParams = {
    id: '',
    action: '',
    name: ''
  };
  public associationStatus: ConfiguredEntityAssociationParams;
  public isApiServiceCalled: boolean;
  public operationStatusMessage = '';
  private popupService: PopupMessageService;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private associationService: AssociationService,private parentContextService?: ParentContextService,
    public modal?: NgbModal,public displayTexts?: DisplayTextService,
    public entityStatesSharedService?: EntityStatesSharedDataService,
    public injector?: Injector,
    public route?: ActivatedRoute,public jsonFileService?: JSONFileService,public fb?: UntypedFormBuilder,
    private ref?: ChangeDetectorRef) {
      super();
      this.parentId = this.parentContextService.getParentContext();
      this.initInputParam(route);
      this.route.parent.params.subscribe(params => {
        this.operation = params['action'];
        this.configuredEntityStateName = params['name'];
        this.configuredEntityStateId = params['id'];
      });
    this.subscription = this.entityStatesSharedService.getFormSubscriptions().subscribe(configuredEntityForm => {
      if (configuredEntityForm) {
        this.configuredEntityForm = configuredEntityForm;
      }
    });
  }


  ngOnInit(): void {
    this.configuredEntityForm = this.entityStatesSharedService.getEntityStateForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  setFormValueChanges() : void {
    this.entityStatesSharedService.setEntityStateForm(this.configuredEntityForm);
  }

  get entityStateKeyAttributes(): UntypedFormArray {
    if (this.configuredEntityForm !== undefined) {
      const entityStateFormGroup = (<UntypedFormGroup> this.configuredEntityForm.get('entityStateKey'));
      return (<UntypedFormArray>entityStateFormGroup.get('attributes'));
    }
  }

  setEventAttributeName(somethjing: any): void {
    const formArray: Array<UntypedFormGroup> = [];
    const attributes = somethjing.replace(/\s/g, '').split(',');
    attributes.forEach(attribute => {
      if (attribute !== '') {
        formArray.push(this.fb.group({
          type: 'EVENT_ATTRIBUTE',
          name: attribute.replace(/\./g, '').replace(/\$/g, ''),
          path: attribute,
          dataType: 'STRING'
        }));
      }
    })
    this.appendConstantEntityKey(formArray);
    this.entityStateKeyAttributes.controls = formArray;
  }

  public appendConstantEntityKey(formArray: Array<UntypedFormGroup>) {
    if (this.operation === "edit") {
      formArray.push(this.fb.group({
        type: ValueType.CONSTANT,
        name: this.configuredEntityStateId,
        dataType: null,
        path: ''
      }));
    }
  }

  public trackAssociationProgress(event): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = true;
  }

  public trackAssociationProgressDone(event): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = false;
    this.hasAssociation = event[HAS_ASSOCIATIONS_IDENTIFIER];
  }

  public hasAssociations(): boolean {
    return this.hasAssociation;
  }
  public hasOperationFailed(): boolean {
    return this.operationFailure || this.hasAssociations();
  }

  public getOperationFailureMessage(): string {
    return Messages.entityOperationFailureMessage;
  }

  private isEditMode() {
    return this.inputParams.action === 'edit';
  }

  private validateAssociationStatus(): void {
    if (!this.isEditMode()) {
      this.hasAssociation = false;
    }
    this.isApiServiceCalled = true;
    this.associationService.getAssociations(this.parentId, ConfiguredEntityType.ENTITYSTATES, this.inputParams.id).subscribe(success => {
      this.hasAssociation = ASSOCIATION_API_FIELD_STATUS in success['result'] ? success['result'][ASSOCIATION_API_FIELD_STATUS] : false;
      this.associationStatus = new ConfiguredEntityAssociationParams(null, this.parentId, this.inputParams.id, ConfiguredEntityType.ENTITYSTATES);
      this.associationStatus = new ConfiguredEntityAssociationParams(null, this.parentId, this.inputParams.id,
        ConfiguredEntityType.ENTITYSTATES, { EntityState: this.inputParams.id });
      this.isApiServiceCalled = false;
    },
    error => {
      this.isApiServiceCalled = false;
      this.hasAssociation = false;
      if ((!('result' in error.error)) || (error.error.statusMessage === 'FAILED')) {
        this.popupService.showErrorMessage(Messages.associationRequestErroredMessage);
      } else {
        this.popupService.showErrorMessage(error.error.statusMessage);
      }
    });
  }

  private initInputParam(route: ActivatedRoute) {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.inputParams.action = route.parent.snapshot.params.action;
      this.inputParams.id = route.parent.snapshot.params.id;
      this.inputParams.name = route.parent.snapshot.params.name;
    });
  }

}