import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Router } from '@angular/router';
import { AuthorizationService } from './shared/services/authorization-service';
import { UserSource } from './shared/models/user-source-enum';

@Injectable({
  providedIn: 'root'
})
export class RouteResolveGuard implements CanActivate {

  constructor(
    private authorizationServce: AuthorizationService,
    public router: Router) { }
  /**
   * @method canActivate Angular router guard
   * @param next  Router snapshot
   * @param state router state snapshot
   * @return {boolean}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.router.url === '/') {
      if ((localStorage.getItem('user-source') === UserSource.OKTA || localStorage.getItem('user-source') === UserSource.SSO)
        && localStorage.getItem('permissions') !== undefined) {
        const route = this.authorizationServce.getRoutePath(JSON.parse(localStorage.getItem('permissions')));
        this.router.navigate([route]);
      } else if (localStorage.getItem('user-source') === null) {
        void this.router.navigate(['login/okta']);
      } else {
        this.router.navigate(['programs']);
      }
    }
    return true;
  }

}