import { ServiceResponse } from '../../service-response';
import { Permission } from './permission';

export class Role {
  id: string;
  name: string;
  parentId: string;
  description: string;
  createdDate: number;
  modifiedDate: number;
  modifiedBy : string;
  permissions: Permission[];
  active: boolean;

  public constructor(response?: ServiceResponse) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response.result)));
    }
  }
}