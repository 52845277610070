<form [formGroup]="featurePermissionFormGroup">
  <div formArrayName="permissions">
    <div *ngFor="let permission of featurePermissionFormGroup.get('permissions')['controls']; let i=index"
      formGroupName="{{i}}">
      <div class="row pl-4">
        <div class="col-3 pt-3 permission-row-height">
          {{getDisplayNameforFeature(permission.controls.featureController.value)}}</div>
        <div class="col-2 pt-3 permission-row-height">
          <coreui-checkbox-group orientation="horizontal">
            <coreui-form-field label="Read">
              <input type="checkbox" coreUICheckbox formControlName="readFormControl"
                (change)="onFeaturePermissionChange($event,'READ')" />
            </coreui-form-field>
            <coreui-form-field label="Write">
              <input type="checkbox" coreUICheckbox formControlName="writeFormControl"
                (change)="onFeaturePermissionChange($event,'CREATE')" />
            </coreui-form-field>
            <coreui-form-field label="Delete">
              <input type="checkbox" coreUICheckbox formControlName="deleteFormControl"
                (change)="onFeaturePermissionChange($event,'DELETE')" />
            </coreui-form-field>
          </coreui-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</form>