export enum Feature {
  PKG_PRG = 'PKG_PRG',
  CUST_PRG = 'CUST_PRG',
  WORKFLOW_PROGRAM = 'WORKFLOW_PROGRAM',
  PKG_EVENT = 'PKG_EVENT',
  CFG_EVENT = 'CFG_EVENT',
  CFG_ACTION = 'CFG_ACTION',
  CFG_RULE = 'CFG_RULE',
  ENT_STATE = 'ENT_STATE',
  CFG_ENT_STATE = 'CFG_ENT_STATE',
  PERFORMANCE_INSIGHTS = 'PERFORMANCE_INSIGHTS',
  CFG_EXCLUSION = 'CFG_EXCLUSION',
  SYS_SETTING = 'SYS_SETTING',
  CFG_SETTING = 'CFG_SETTING',
  CFG_BATCH_EXTRACT = 'CFG_BATCH_EXTRACT',
  PKG_USR_GRP = 'PKG_USR_GRP',
  CFG_USR_GRP = 'CFG_USR_GRP',
  EVENT_SRC = 'EVENT_SRC',
  CFG_EVENT_SRC = 'CFG_EVENT_SRC',
  USR = 'USR',
  ACTION_DEST = 'ACTION_DEST',
  CFG_ACTION_DEST = 'CFG_ACTION_DEST',
  PKG_ROLE = 'PKG_ROLE',
  CFG_ROLE = 'CFG_ROLE',
  SUPPORT = 'SUPPORT',
  CONTENT = 'CONTENT',
  PARENT = 'PARENT',
  CFG_REPORTING = 'CFG_REPORTING',
  CONFIGURED_RECOMMENDATION = 'CONFIGURED_RECOMMENDATION'
}

export enum Category {
  PROGRAM = 'PROGRAM',
  EVENTS = 'EVENTS',
  ACTIONS = 'ACTIONS',
  RULES = 'RULES',
  ENTITY_STATES = 'ENTITY_STATES',
  EXCLUSIONS = 'EXCLUSIONS',
  PRODUCT_SWITCHER = 'PRODUCT_SWITCHER',
  PARENT = 'PARENT',
  CLIENT = 'CLIENT',
  RECOMMENDATION = 'RECOMMENDATION'
}

export const FeatureDisplayName = {
  PKG_PRG: 'Packaged Program',
  CUST_PRG: 'Custom Program',
  WORKFLOW_PROGRAM: 'Workflow Program',
  CFG_EVENT: 'Event',
  CFG_ACTION: 'Action',
  CFG_RULE: 'Rule',
  CFG_ENT_STATE: 'Entity State',
  CFG_EXCLUSION: 'Exclusion',
  PERFORMANCE_INSIGHTS: 'Performance Insights',
  CFG_SETTING: 'Custom Settings',
  CFG_BATCH_EXTRACT: 'Batch Extract',
  CFG_USR_GRP: 'User Group',
  CFG_EVENT_SRC: 'Event Source',
  USR: 'User',
  CFG_ACTION_DEST: 'Action Destination',
  CFG_ROLE: 'Role',
  SUPPORT: 'Support',
  CONTENT: 'Content',
  CFG_REPORTING: 'Reporting',
  PARENT: 'Parent',
  CONFIGURED_RECOMMENDATION: 'IQ Decisions',
  EXT_TNT: 'External Tenant',
  ACTION_DEST: 'Action Destination',
  PKG_ACTION: 'Packaged Action',
  PKG_EXCLUSION: 'Packaged Exclusion',
  PKG_REPORTING: 'Packaged Reporting',
  EVENT_SRC: 'Event Source',
  ENT_STATE: 'Entity State',
  PKG_EVENT: 'Packaged Event',
  PRG: 'Program',
  SYS_SETTING: 'System Settings',
  PKG_ROLE: 'Packaged Role',
  PKG_USR_GRP: 'Packaged User Group',
  REPORTING: 'Reporting', 
  CFG_SCHEMA: 'Configured Schema',
  OKTA_API: 'Okta API',
  PACKAGED_RECOMMENDATION: 'Packaged Recommendation'
};

export const CategoryDisplayName = {
  PROGRAM: 'Programs',
  PROGRAM_FEATURE: 'Program Features',
  EVENTS: 'Events',
  ACTIONS: 'Actions',
  RULES: 'Rules',
  ENTITY_STATES: 'Entity States',
  EXCLUSIONS: 'Exclusions',
  PRODUCT_SWITCHER: 'Product Switcher',
  CLIENT: 'Client',
  SYSTEM: 'System'
  // RECOMMENDATION: 'IQ Decisions'
};

export const ExcludedFeatures: Feature[] = [Feature.CONFIGURED_RECOMMENDATION];