import { DataTypes } from './data-types';
import { SourceType } from './source-type';

export class AttributeMetadata {

  id: string;
  name: string;
  title: string;
  type: DataTypes;
  typeOfValue: DataTypes;
  path: string;
  sourceType: SourceType;
  entityStateName: string;
  entityStateId: string;
  format: string;

  public getAttributeDataType(): DataTypes {
    if (this.isAttributeOfDateType()) {
      return DataTypes.date;
    }
    return this.type;
  }

  public isAttributeOfDateType(): boolean {
    const nameLowerCase = this.name.toLocaleLowerCase();
    if (this.format && this.format === 'date-time') {
      return true;
    } else if (this.type === DataTypes.number && (nameLowerCase.includes('time') || nameLowerCase.includes('date'))) {
      return true;
    }
    return false;
  }

  public getAttributeDisplayName(): string {
    return this.title ? this.title : this.name;
  }

}
