/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent, TableHeaderColumnComponent } from '@epsilon/core-ui';

@Component({
  selector: 'app-model-selection-modal',
  templateUrl: './model-selection-modal.component.html',
  styleUrls: ['./model-selection-modal.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush
})
export class ModelSelectionModalComponent {

  @Input() buttonLabel = '';
  @Input() models = [];
  @Output() isSelectedModel = new EventEmitter();
  @ViewChild('basicModal', { static: true }) private basicModal: ModalComponent;
  public properties = {};
  public selectedModel = {};
  public expandableRowsTemplate: any;
  public configuredIQDecisionsTableFilteredData = {};
  public defaultSortOption = 'Product';
  public defaultSortDirection = 'ASC';

  constructor() {
    this.initDataTableConfig();
  }

  public closeBasicModal() {
    void this.basicModal.hide().then(() => {
      if (this.selectedModel['id']) {
        this.isSelectedModel.emit(this.selectedModel);
      }
    });
  }

  public launchBasicModal() {
    this.initDataTableConfig();
    void this.basicModal.show().then(() => {});
  }

  public defaultSort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.defaultSortOption = sortOption;
    this.defaultSortDirection = sortComponent.sortDirection;
  }

  public sortAscDesc(sortType) {
    return sortType === 'ASC' ? 'ascending' : 'descending';
  }

  public rowSelected(value) {
    const model = this.models.find((m) => m.id === value.rowId);
    this.selectedModel = { id: model.id, name: model.name };
  }

  private initDataTableConfig(): void {
    this.properties = {
      hidePagination: true,
      rowId: 'id',
      showBadgesComponent: true,
      columns: [
        {
          headerText: '',
          key: 'radioButton',
          isSortable: false,
          isColumnDisplayed: false,
          radioButton: true
        },
        {
          headerText: 'Model Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Model Type',
          key: 'type',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Range',
          key: 'range',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true,
      expandableRowsTemplate: this.expandableRowsTemplate
    };
  }


}