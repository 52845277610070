import { Component, OnInit, OnDestroy, Injector, ViewChild, TemplateRef } from '@angular/core';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ProgramHomeFilterItems } from 'src/app/shared/models/program-home-filter-items';
import { ProgramType } from 'src/app/shared/program-type';
import { ConfiguredProgramDetails } from 'src/app/shared/models/configured-program-details';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ProgramHomeStateService } from 'src/app/shared/state/program-home-state.service';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { BluePrintTableColumnType } from 'src/app/shared/blueprint-table-column-type';
import { ProgramStatus } from 'src/app/shared/program-status';
import { Messages } from 'src/app/shared/message';
import { BaseComponent } from '../../client/base/base.component';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { DatePipe } from '@angular/common';
import { ExternalSystemLoginService } from 'src/app/shared/services/external-system-login-service';
import { ExternalSystemSource } from 'src/app/shared/models/external-system-source-enum';
import { forkJoin, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { ViewType } from 'src/app/shared/models/view-type';
import { ActionType } from 'src/app/shared/models/action/action-type';
import { PackagedProgramAction } from 'src/app/shared/models/action/packaged-program-action';
import { PackagedProgramRule } from 'src/app/shared/models/packaged-program-rule';
import { catchError, takeUntil } from 'rxjs/operators';
import { FilterModel } from 'src/app/shared/models/filter-model';
import { Configuration } from 'src/app/shared/models/configuration';

@Component({
  selector: 'app-programs-home',
  templateUrl: './programs-home.component.html',
  styleUrls: ['./programs-home.component.scss']
})

export class ProgramsHomeComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('expandableRowsTemplate', { static: true }) expandableRowsTemplate: TemplateRef<any>;
  @ViewChild('dateTemplateColumn', { static: true }) dateTemplateColumn: TemplateRef<any>;
  public isCollapseProgram = {};
  actionDestinationsForParent = [];
  eventSourceProvidersForParent = [];
  companiesForParent = [];
  bUnitsForParent = [];
  parentId: string;
  configuredProgramList: ConfiguredProgramDetails[];
  readConfiguredProgramList: ConfiguredProgramDetails[];
  isConfiguredProgramsLoading: boolean;
  fetchingAllCompanies: boolean;
  fetchingAllBUs: boolean;
  navigationSubscription: Subscription;
  sortBy: string;
  sortOrder: string;
  searchByName: string;
  displayMinSearchError: boolean;
  collapseFilters = true;
  filterItems = ProgramHomeFilterItems.FILTER_ITEMS;
  selectedFilterItem: string;
  selectedSubFilterItem: any;
  userSavedFiltersForCurrentParent = new Map<string, Set<string>>();
  userSavedFiltersForAllParents = new Map<string, Map<string, Set<string>>>();
  companyMap = new Map<string, string>();
  programBusinessUnitMap = new Map<string, string>();
  businessUnitsForParent = new Map<string, string>();
  public minSearchErrMsg: string;
  listView = false;
  isSearching = false;
  filterByActionDestinationValue = [];
  filterByBusinessUnitValue = [];
  filterByCompanyValue = [];
  filterByEventSourceValue = [];
  programDetails: ConfiguredProgram = new ConfiguredProgram();
  public programViewToggle: any;
  public selectedSortOption: any;
  // Table configuration
  public showFilters: boolean = false;
  public isDataLoading = false;
  public properties = {};
  configuredProgramTableList = [];
  configuredProgramTableFilteredData = [];
  pageIndices;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  sortByOptions = [
    {
      text: 'Create Date'
    },
    {
      text: 'Modified Date'
    },
    {
      text: 'Program Name'
    },
    {
      text: 'Status'
    }
  ];

  viewToggles = [
    {
      id: ViewType.TABLE,
      text: 'Table',
      value: ViewType.TABLE,
      isChecked: true
    },
    {
      id: ViewType.LIST,
      text: 'List',
      value: ViewType.LIST
    }
  ];

  constructor(
    config: NgbModalConfig,
    public service: ProgramService,
    public parentContext: ParentContextService,
    public router: Router, private eventSourceService: EventSourceService,
    private actionDestinationService: ActionDestinationService,
    private programHomeState: ProgramHomeStateService,
    public injector: Injector,
    public authorizationService: AuthorizationService,
    public externalSystemService: ExternalSystemLoginService,
    private datePipe: DatePipe) {
    super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.parentId = this.parentContext.getParentContext();
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = true;
        const value = this.parentContext.getParentContext();
        if (value !== '') {
          this.parentId = value;
          if (value !== 'NO_PARENT_CONFIGURED') {
            this.selectedSortOption = this.sortByOptions[1];
            this.initDataTableConfig();
            this.getEntities();
          }
        }
      }
    });
    if (this.service.programViewToggle === ViewType.LIST) {
      this.listView = true;
      this.programViewToggle = this.service.programViewToggle;
    }
    programHomeState.fetchCachedFilterState();
    this.sortBy = programHomeState.sortBy;
    this.sortOrder = programHomeState.sortOrder;
    this.userSavedFiltersForAllParents = programHomeState.filterItems;
    if (this.userSavedFiltersForAllParents.has(this.parentId)) {
      this.userSavedFiltersForCurrentParent = this.userSavedFiltersForAllParents.get(this.parentId);
    } else {
      this.userSavedFiltersForAllParents.set(this.parentId, new Map<string, Set<string>>());
    }
    this.showFilters = this.userSavedFiltersForCurrentParent !== null && this.userSavedFiltersForCurrentParent.size > 0;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  public routeProgram(program): void {
    this.router.navigate([this.getRouterLink(program)]);
  }

  openNewProgramSelector(): void {
    void this.router.navigate(['programs/program-selector']);
  }

  handleSearch(searchInput: string) : void {
    if (searchInput === null || searchInput === undefined) {
      searchInput = '';
    }
    if (this.searchByName !== searchInput) {
      this.searchByName = searchInput.trim();
      this.filterConfiguredPrograms();
    }
  }

  filterConfiguredPrograms(): void {
    if (this.searchByName && this.searchByName.trim()) {
      this.searchByName = this.searchByName.trim();
      this.configuredProgramList = this.readConfiguredProgramList
        .filter((program: ConfiguredProgramDetails) => {
          return program.name.toLowerCase().includes(this.searchByName.toLowerCase());
        });
    } else {
      this.configuredProgramList = this.readConfiguredProgramList;
    }
    if (this.userSavedFiltersForCurrentParent !== null && this.userSavedFiltersForCurrentParent.size > 0) {
      this.configuredProgramList = this.configuredProgramList
        .filter((program: ConfiguredProgramDetails) => {
          let displayConfigureProgram = true;
          this.userSavedFiltersForCurrentParent.forEach((values, key) => {
            let found = false;
            switch (key) {
              case 'event_source_provider':
                if (program.programType === ProgramType.PACKAGED) {
                  if (!values.has(program.configuration.eventSource)) {
                    displayConfigureProgram = false;
                    return;
                  }
                } else if (program.configuration.rules) {
                  values.forEach(value => {
                    if (program.hasEventSource(value)) found = true;
                  });
                  if (!found) {
                    displayConfigureProgram = false;
                  }
                }
                break;
              case 'action_destination':
                values.forEach(value => {
                  if (program.hasActionDestination(value)) found = true;
                });
                if (!found) {
                  displayConfigureProgram = false;
                }
                break;
              case 'business_unit':
                values.forEach(value => {
                  const filteredBUs: string[] = [];
                    if (program.configuration.rules) {
                      const programBUs = this.programDetails.getBusinessUnits(this.programDetails.getRules(program), program.programType);
                      programBUs.forEach(bu => {
                        if (this.businessUnitsForParent.has(bu)) {
                          filteredBUs.push(this.businessUnitsForParent.get(bu));
                        }
                      });
                    }
                    if (filteredBUs.length > 0 && filteredBUs.includes(value)) {
                      found = true;
                      return;
                    }
                });
                if (!found) {
                  displayConfigureProgram = false;
                }
                break;
              case 'company':
                if (program.programType === ProgramType.PACKAGED) {
                  if (!values.has(this.companyMap.get(program.configuration.companyId))) {
                    displayConfigureProgram = false;
                    return;
                  }
                } else if (program.configuration.rules) {
                  values.forEach(value => {
                    if (program.hasCompany(value, this.companyMap)) {
                      found = true;
                    }
                  });
                  if (!found) {
                    displayConfigureProgram = false;
                  }
                }
                break;
            }
          });
          return displayConfigureProgram;
        });
    }
    this.sortConfiguredPrograms(this.sortOrder);
    this.initConfiguredProgramTableList();
  }

  performFilterOperation(): void {
    if (this.selectedFilterItem && this.selectedSubFilterItem.length > 0) {
      this.userSavedFiltersForCurrentParent.set(this.selectedFilterItem, new Set<string>());
      this.selectedSubFilterItem.forEach(element => {
        this.userSavedFiltersForCurrentParent.get(this.selectedFilterItem).add(element === 'Harmony' ? 'HARMONY' : element);
      });
    } else {
      this.userSavedFiltersForCurrentParent.delete(this.selectedFilterItem);
    }
    this.userSavedFiltersForAllParents.set(this.parentId, this.userSavedFiltersForCurrentParent);
    this.loadInitFilter();
    this.selectedFilterItem = undefined;
    this.selectedSubFilterItem = undefined;
    this.filterConfiguredPrograms();
    this.programHomeState.saveState(this.sortBy, this.sortOrder, this.userSavedFiltersForAllParents);
  }

  loadInitFilter(): void {
    if (this.userSavedFiltersForCurrentParent !== null && this.userSavedFiltersForCurrentParent.has('action_destination')) {
      this.filterByActionDestinationValue = [];
      this.userSavedFiltersForCurrentParent.get('action_destination').forEach(adValue => {
        if (this.actionDestinationsForParent.filter(val => val.value === adValue)) {
          this.filterByActionDestinationValue.push(adValue);
        }
      });
    }
    if (this.userSavedFiltersForCurrentParent !== null && this.userSavedFiltersForCurrentParent.has('event_source_provider')) {
      this.filterByEventSourceValue = [];
      this.userSavedFiltersForCurrentParent.get('event_source_provider').forEach(esValue => {
        if (this.eventSourceProvidersForParent.filter(val => val.value === esValue)) {
          this.filterByEventSourceValue.push(esValue === 'HARMONY' ? 'Harmony' : esValue);
        }
      });
    }
    if (this.userSavedFiltersForCurrentParent !== null && this.userSavedFiltersForCurrentParent.has('company')) {
      this.filterByCompanyValue = [];
      this.userSavedFiltersForCurrentParent.get('company').forEach(comValue => {
        if (this.companiesForParent.filter(val => val.value === comValue).length > 0) {
          this.filterByCompanyValue.push(comValue);
        }
      });
    }
    if (this.userSavedFiltersForCurrentParent !== null && this.userSavedFiltersForCurrentParent.has('business_unit')) {
      this.filterByBusinessUnitValue = [];
      this.userSavedFiltersForCurrentParent.get('business_unit').forEach(buValue => {
        if (this.bUnitsForParent.filter(val => val.value === buValue).length > 0) {
          this.filterByBusinessUnitValue.push(buValue);
        }
      });
    }
  }

  filterByOptions(event: FilterModel): void {
    if (event.clear) {
      this.onFilterClearClick(event.formControlName);
      return;
    }
    this.selectedSubFilterItem = event.entityArray.filter((_: string, index: number) => {
      return event.formArray[index];
    }).map(_ => _.value);
    this.selectedFilterItem = event.formControlName;
    this.performFilterOperation();
  }

  onFilterClearClick(key: string): void {
    this.userSavedFiltersForCurrentParent.delete(key);
    this.userSavedFiltersForAllParents.set(this.parentId, this.userSavedFiltersForCurrentParent);
    this.filterConfiguredPrograms();
    this.programHomeState.saveState(this.sortBy, this.sortOrder, this.userSavedFiltersForAllParents);
  }

  private sortConfiguredPrograms($event): void {
    if (!this.listView) {
      return;
    }
    switch ($event) {
      case 'Program Name':
        this.configuredProgramList.sort((program1: ConfiguredProgramDetails, program2: ConfiguredProgramDetails) => {
          const sort = program1.name.toLowerCase() < program2.name.toLowerCase();
          return sort ? -1 : 1;
        });
        break;
      case 'Modified Date':
        this.configuredProgramList.sort((program1: ConfiguredProgramDetails, program2: ConfiguredProgramDetails) => {
          const sort = program1.modifiedDate < program2.modifiedDate;
          return sort ? 1 : -1;
        });
        break;
      case 'Status':
          this.configuredProgramList.sort((program1: ConfiguredProgramDetails, program2: ConfiguredProgramDetails) => {
            const sort = program1.active < program2.active;
            return sort ? 1 : -1;
        });
        break;
        case 'Create Date':
          this.configuredProgramList.sort((program1: ConfiguredProgramDetails, program2: ConfiguredProgramDetails) => {
            const sort = program1.createdDate < program2.createdDate;
            return sort ? 1 : -1;
        });
        break;
    }
    this.programHomeState.saveState(this.sortBy, this.sortOrder, this.userSavedFiltersForCurrentParent);
  }

  getRouterLink(program: ConfiguredProgramDetails): string {
    let routerLink = '';
    if (program.programType === ProgramType.PACKAGED) {
      routerLink = 'programs/configure/' + program.id + '/' + program.configuration.eventSource + '/edit/rule';
    }
    if (program.programType === ProgramType.SELF_SERVICE) {
      routerLink = 'programs/custom/configure/' + program.id + '/' + program.configuration.eventSource + '/edit';
    }
    if (program.programType === ProgramType.SELF_SERVICE_EXTERNAL || program.programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
      routerLink = 'programs/custom/configure/external/' + program.id + '/' + 'eventSource' + '/edit/program-rule';
    }
    return routerLink;
  }

  public toggleView(event: { target: { value: ViewType } }): void {
    this.listView = (event.target.value === ViewType.LIST) ? true : false;
    this.service.programViewToggle = event.target.value;
  }

  private initDataTableConfig() {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Program Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.LINK,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}',
            isAllowNewTab: !this.externalSystemService.isExternalSystem()
          }
        },
        {
          key: 'status',
          headerText: 'Status',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.STATUS,
          statusIndicatorMapping: {
            'incomplete-primary': ProgramStatus.INACTIVE,
            'incomplete-secondary': ProgramStatus.TEST,
            'complete': ProgramStatus.ACTIVE
          }
        },
        {
          headerText: 'Company',
          key: 'companyName',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Business Unit',
          key: 'businessUnit',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.TEMPLATE,
          template: this.dateTemplateColumn
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true,
      expandableRowsTemplate: this.expandableRowsTemplate
    };
  }

  private initConfiguredProgramTableList() {
    if (!this.configuredProgramList || this.configuredProgramList === undefined) {
      return;
    }
    this.configuredProgramTableFilteredData = [];
    this.configuredProgramList.forEach(program => {
      const configuredProgramTableRow = {};
      const configuration: Configuration = Object.assign(new Configuration(), program.configuration);
      configuredProgramTableRow['id'] = program.id;
      configuredProgramTableRow['name'] = program.name;
      configuredProgramTableRow['status'] = this.getProgramStatus(program.active, program.mode);
      configuredProgramTableRow['companyName'] = this.companyMap.get(configuration.getCompanyId(program.programType));
      configuredProgramTableRow['businessUnit'] = this.businessUnitsForParent.get(this.programBusinessUnitMap.get(program.id));
      configuredProgramTableRow['modifiedDate'] = this.datePipe.transform(new Date(program.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
      configuredProgramTableRow['modifiedBy'] = program.modifiedBy;
      configuredProgramTableRow['routerLink'] = this.getRouterLink(program);
      let programDescription = '';
      if (program.description || program.programType === 'SELF_SERVICE_EXTERNAL' || program.programType === 'SELF_SERVICE_EXTERNAL_PACKAGED') {
        programDescription = program.description;
      } else {
        programDescription = 'If ' + program.configuration.eventType
          + ' is received from ' + program.configuration.eventSource
          + ' ' + program.configuration.companyId
          + ' Then send to ' + program.configuration.eventType
          + ' Customer Keys with ' + program.configuration.eventType
          + ' Product IDs using Action Destination from Business Unit With Action Name';
      }
      configuredProgramTableRow['programDescription'] = programDescription;
      this.configuredProgramTableFilteredData.push(configuredProgramTableRow);
    });
    this.configuredProgramTableList = this.configuredProgramTableFilteredData.slice(0, 10);
  }


  private getProgramStatus(active: boolean, mode: string) {
    if (active) {
      if (mode === 'LIVE') {
        return ProgramStatus.ACTIVE;
      } else {
        return ProgramStatus.TEST;
      }
    } else {
      return ProgramStatus.INACTIVE;
    }
  }

  private getEntities(): void {
    this.isConfiguredProgramsLoading = true;
    this.callAPIs().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      this.getAllBusinessUnitsForParent(result[0].result);
      this.getEventSourceProviders(result[1].result);
      this.getActionDestinations(result[2].result);
      this.getAllCompaniesForParent(result[3].result);
      this.getConfiguredPrograms(result[4].result);
    }, error => {
    });
  }

  private callAPIs(): Observable<any[]> {
    const businessUnits = this.actionDestinationService.getBUs(this.parentId, '').pipe(catchError(() => of({ 'result': [] })));
    const configuredEventSources = this.eventSourceService.getEventSources(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredActionDestinations = this.actionDestinationService.getActionDestinations(this.parentId)
      .pipe(catchError(() => of({ 'result': [] })));
    const configuredCompanies = this.eventSourceService.getCompanies(this.parentId, '').pipe(catchError(() => of({ 'result': [] })));
    const configuredPrograms = this.service.getAllConfiguredPrograms(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const apisToCall: any[] = [
      businessUnits,
      configuredEventSources,
      configuredActionDestinations,
      configuredCompanies,
      configuredPrograms
    ];
    return forkJoin(apisToCall);
  }

  private getConfiguredPrograms(result): void {
    this.isConfiguredProgramsLoading = true;
    if (result) {
      this.readConfiguredProgramList = result.flat().map((program: ConfiguredProgramDetails) => new ConfiguredProgramDetails(program));
      this.readConfiguredProgramList.forEach(program => {
        this.getConfiguredProgramBusinessUnit(program);
      });
      this.service.fetchedProgramNames = this.readConfiguredProgramList.map((value: ConfiguredProgram) => value.name);
      this.getConfiguredProgramForExternalSystem();
      this.initConfiguredProgramTableList();
      this.filterConfiguredPrograms();
      this.isConfiguredProgramsLoading = false;
      this.isDataLoading = false;
    } else {
      this.isConfiguredProgramsLoading = false;
      this.isDataLoading = false;
    }
  }

  private getActionDestinations(result): void {
    if (result) {
      this.actionDestinationsForParent = [];
      this.filterItems.action_destination.values = result;
      this.filterItems.action_destination.values.forEach(actionDestination => {
        this.filterItems.action_destination.valueMap.set(actionDestination.actionDestination, actionDestination.name);
        const value = { 'label': actionDestination.name, 'value': actionDestination.actionDestination };
        this.actionDestinationsForParent.push(value);
      });
      this.loadInitFilter();
    } else {
      this.filterItems.action_destination.values = [];
    }
  }

  private getEventSourceProviders(result): void {
    if (result) {
      this.eventSourceProvidersForParent = [];
      this.filterItems.event_source_provider.values = result;
      this.filterItems.event_source_provider.values.forEach(eventSource => {
        this.filterItems.event_source_provider.valueMap.set(eventSource.eventSource, eventSource.name);
        const value = { 'label': eventSource.name, 'value': eventSource.eventSource };
        this.eventSourceProvidersForParent.push(value);
      });
      this.loadInitFilter();
    } else {
      this.filterItems.event_source_provider.values = [];
    }
  }

  private getAllCompaniesForParent(result): void {
    this.fetchingAllCompanies = true;
    if (result) {
      this.companiesForParent = [];
      const settings = result;
      const allCompanies = [];
      settings.forEach(setting => {
        if (setting.settings) {
          setting.settings.forEach(company => {
            this.companyMap.set(company.companyId, company.companyName);
            if (!allCompanies.includes(company.companyName) && company.companyName !== null) {
              const value = { 'label': company.companyName, 'value': company.companyName };
              this.companiesForParent.push(value);
            }
            allCompanies.push(company.companyName);
          });
        }
      });
      this.fetchingAllCompanies = false;
      this.loadInitFilter();
    }
  }

  private getAllBusinessUnitsForParent(result): void {
    this.fetchingAllBUs = true;
    this.bUnitsForParent = [];
    const settings = result;
    const allBUs = [];
    settings.forEach(setting => {
      if (setting.actionSettings) {
        setting.actionSettings.forEach(bu => {
          this.businessUnitsForParent.set(bu.buId, bu.buName);
          if (!allBUs.includes(bu.buName) && bu.buName !== null && bu.buName !== undefined) {
            const value = { 'label': bu.buName, 'value': bu.buName };
            this.bUnitsForParent.push(value);
          }
          allBUs.push(bu.buName);
        });
      }
    });
    this.fetchingAllBUs = false;
    this.loadInitFilter();
  }

  private getConfiguredProgramBusinessUnit(program: ConfiguredProgramDetails): void {
    let bu = '';
    if (program.programType === ProgramType.PACKAGED) {
      const businessUnitIds: string[] = program.getBusinessIdsOfPackagedProgram(program.configuration.rules);
      bu = businessUnitIds[0];
    }
    if (program.configuration.rules) {
      const rule = JSON.parse(program.configuration.rules);
      if(program.programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
        const businessUnitIds: string[] = [];
        rule.forEach(rule => {
          if(rule.thenClause && rule.thenClause.actions) {
          rule.thenClause.actions.forEach(action => {
            if (action.actionSettingsInput && action.actionSettingsInput.buId) {
              businessUnitIds.push(action.actionSettingsInput.buId);
            }
          });
        }
      });
        bu = businessUnitIds[0];
     }
      if (rule.actions) {
        rule.actions.forEach(action => {
          if (action.actionSettingsInput && action.actionSettingsInput.businessUnitId) {
            bu = action.actionSettingsInput.businessUnitId;
          }
        });
      }
    }
    if (this.businessUnitsForParent.has(bu)) {
      this.programBusinessUnitMap.set(program.id, bu);
    }
  }

  private getConfiguredProgramForExternalSystem(): void {
    if (this.externalSystemService.isExternalSystem() && this.externalSystemService.getExternalSource === ExternalSystemSource.PCM) {
      this.configuredProgramList = [];
      this.readConfiguredProgramList.forEach(program => {
        if (program.hasExternalTenant(this.externalSystemService.getExternalTenantId)) {
          this.configuredProgramList.push(program);
        }
      });
      this.filterConfiguredProgramForExternalSystem();
    }
  }

  private filterConfiguredProgramForExternalSystem(): void {

    const actionDestinations = new Set();
    const businessUnitIds = new Set();
    const companies = new Set();
    const eventSourceProviders = new Set();

    const actionDestinationsForParentES = [];
    const eventSourceProvidersForParentES = [];
    const companiesForParentES = [];
    const bUnitsForParentES = [];

    this.readConfiguredProgramList = JSON.parse(JSON.stringify(this.configuredProgramList));
    this.configuredProgramList.forEach(program => {
      if (program.programType === ProgramType.PACKAGED) {
        const rules = JSON.parse(program.configuration.rules);
        rules.forEach((rule: PackagedProgramRule) => {
          rule = Object.assign(new PackagedProgramRule(), rule);
          if (rule.actions) {
            const apiActions: PackagedProgramAction[] = rule.getActions(ActionType.API_ACTION);
            apiActions.forEach(action => {
              actionDestinations.add(action.actionInputs[0]);
              businessUnitIds.add(action.actionSettingsInput['serviceInput'].businessUnitId);
              eventSourceProviders.add(program.configuration.eventSource);
              companies.add(program.configuration.companyId);
            });
          }
        });
      }
    });

    this.actionDestinationsForParent.forEach(actDest => {
      if (actionDestinations.has(actDest.value)) {
        actionDestinationsForParentES.push(actDest);
      }
    });
    for (const [buId, buName] of this.businessUnitsForParent) {
      if (businessUnitIds.has(buId)) {
        const value = { 'label': buName, 'value': buName };
        bUnitsForParentES.push(value);
      }
    }
    this.companiesForParent.forEach(company => {
      if (companies.has(company.value)) {
        companiesForParentES.push(company);
      }
    });
    this.eventSourceProvidersForParent.forEach(eventSource => {
      if (eventSourceProviders.has(eventSource.value)) {
        eventSourceProvidersForParentES.push(eventSource);
      }
    });
    this.setFilteredDataForES(actionDestinationsForParentES, bUnitsForParentES, companiesForParentES,
      eventSourceProvidersForParentES);
  }

  private setFilteredDataForES(actionDestinationsForParentES: any[], bUnitsForParentES: any[],
    companiesForParentES: any[], eventSourceProvidersForParentES: any[]) : void {
    this.actionDestinationsForParent = [];
    this.bUnitsForParent = [];
    this.companiesForParent = [];
    this.eventSourceProvidersForParent = [];
    if (actionDestinationsForParentES.length) {
      this.actionDestinationsForParent = actionDestinationsForParentES;
    }
    if (bUnitsForParentES.length) {
      this.bUnitsForParent = bUnitsForParentES;
    }
    if (companiesForParentES.length) {
      this.companiesForParent = companiesForParentES;
    }
    if (eventSourceProvidersForParentES.length) {
      this.eventSourceProvidersForParent = eventSourceProvidersForParentES;
    }
  }

}
