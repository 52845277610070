import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IconType, FilterComponent } from '@epsilon/core-ui';
import { FilterModel } from '../../models/filter-model';

@Component({
  selector: 'app-ae-filter',
  templateUrl: './ae-filter.component.html',
  styleUrls: ['./ae-filter.component.scss']
})
export class AeFilterComponent implements OnInit {

  @ViewChild('filter')
  filter: FilterComponent;

  @Input()
  public autoFocus = false;

  @Input()
  public autoHide = false;

  @Input()
  buttonLabel = '';

  @Input()
  public filterStyle = 'primary';

  @Input()
  icon: IconType;

  @Input()
  public label = '';

  @Input()
  menuAlignment = 'left';

  @Input()
  public panelCssClass = '';

  @Input()
  showArrow = true;

  @Input()
  public entityArray: Array<any>;

  @Input()
  entityArrayName: string;

  @Input()
  public formGroup: UntypedFormGroup;

  @Input()
  cachedValue: Map<string, Set<string>>;

  @Output() filterEmitter: EventEmitter<FilterModel> = new EventEmitter<FilterModel>();

  public displayButtonLabel = '';

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    const formArray = this.buildFormArray(this.entityArrayName, this.entityArray);
    this.formGroup = this.formBuilder.group({
      formName: formArray
    });
    this.setButtonLabel(this.formGroup.get('formName').value);
  }

  public applyFilter(): void {
    this.emit({ 'formControlName': this.entityArrayName, 'formArray': this.formGroup.get('formName').value, 'entityArray': this.entityArray });
    this.setButtonLabel(this.formGroup.get('formName').value);
    this.hideFilter();
  }

  public clearFilter(): void {
    this.emit({ 'formControlName': this.entityArrayName, 'clear': true });
    this.clear();
    this.setButtonLabel(this.formGroup.get('formName').value);
    this.hideFilter();
  }

  private emit(filterModel: FilterModel): void {
    this.filterEmitter.emit(filterModel);
  }

  private hideFilter(): void {
    void this.filter.hideFilter();
  }

  private clear(): void {
    const value = this.entityArray.map(_ => false);
    this.formGroup.get('formName').setValue(value);
  }

  private buildFormArray(formControlName: string, entityArray: Array<any>) {
    const formArray = this.entityArray.map(_ => false);
    if (this.cachedValue && this.cachedValue.size > 0 && this.cachedValue.get(formControlName)) {
      const values = [];
      this.cachedValue.get(formControlName).forEach(filter => values.push(filter));
      for (let i = 0; i < entityArray.length; i++) {
        if (values.indexOf(entityArray[i].value) !== -1) {
          formArray[i] = true;
        }
      }
    }
    return this.formBuilder.array(formArray);
  }

  private setButtonLabel(formArrayValues: Array<boolean>): void {
    let count = 0;
    let name: string = this.buttonLabel;
    formArrayValues.forEach((val: boolean, i: number) => {
      if (val === true) {
        name = this.entityArray[i].label;
        count++;
      }
    });
    this.displayButtonLabel = (count > 1) ? count + ' options selected' : name;
  }

}