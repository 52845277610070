import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { CognitoLoginService } from '../services/cognito.login.service';
import { Router } from '@angular/router';
import { LoginServiceLocator } from '../services/login.service-locator';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public loginServiceLocator: LoginServiceLocator, public router: Router) {}
  /**
   * @method canActivate Angular router guard
   * @param next  Router snapshot
   * @param state router state snapshot
   * @return {boolean}
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    /* get the value from auth service whether user is logged or not,
       If not logged in then route him towards sign in
       If logged pass the value so that i execute the further functionality */
    if (!this.loginServiceLocator.getLoginService().isLoggedIn()) {
      console.log('logging out via auth guard');
      this.loginServiceLocator.getLoginService().logout();
      return false;
    }
    return true;
  }

}
