import { Injectable } from '@angular/core';
import { ActionDestinationService } from './action-destination.service';
import { AuthenticationMethod } from 'src/app/shared/models/authentication-method';
import { AwsActionDestination } from 'src/app/shared/models/aws-action-destination';
import { ActionDestinationServiceInterface } from './action-destination-service-interface';

@Injectable({
  providedIn: 'root'
})
export class IamActionDestinationService extends ActionDestinationService implements ActionDestinationServiceInterface {

  buildCredentials(formValue: any): AwsActionDestination {
    const awsAD = new AwsActionDestination();
    awsAD.parentId = formValue.context.getParentContext();
    awsAD.name = formValue.getAwsActionDestinationNameControl().value;
    awsAD.description = formValue.getAwsActionDestinationDescriptionControl().value;
    awsAD.authenticationMethod = AuthenticationMethod.IAM.value;
    const awsActionSettings = formValue.awsUserCredentialComponent.getCredentials();
    awsAD.awsActionSettings = awsActionSettings;
    return awsAD;
  }
  doPostConfiguredActionDestination(formValue: any) {
    return this.configureActionDestination(formValue.context.parentId, JSON.stringify(this.buildCredentials(formValue).buildPostPayload()));
  }
  doPutConfiguredActionDestination(formValue: any) {
    return this.saveActionSettingConfiguration(JSON.stringify(this.buildCredentials(formValue).buildPostPayload()), formValue.context.parentId, formValue.existingCustomActionDestinationId);
  }

}