import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ConfiguredBatchExtract } from 'src/app/shared/models/configured-batch-extract/configured-batch-extract';
import { Schedule } from 'src/app/shared/models/configured-batch-extract/schedule';
import { Recurrence } from 'src/app/shared/models/configured-batch-extract/recurrence';
import { BatchExtractFrequency } from 'src/app/shared/models/configured-batch-extract/batch-extract-frequency';
import { ExportDestination } from 'src/app/shared/models/configured-batch-extract/export-destination';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ConfiuredEventSource, PackagedEventSourceName } from 'src/app/shared/models/event-source';
import { Company } from 'src/app/shared/models/company';
import { Messages } from 'src/app/shared/message';
import { Pattern } from 'src/app/shared/pattern';
import { Action } from 'src/app/shared/constants';
import * as moment from 'moment';
import { ModalComponent } from '@epsilon/core-ui';
import { BehaviorSubject } from 'rxjs';
import { Selectable } from 'src/app/shared/models/selectable';
import { AuthorizationService } from '../../../../shared/services/authorization-service';

@Component({
  selector: 'app-configure-batch-extract',
  templateUrl: './configure-batch-extract.component.html'
})
export class ConfigureBatchExtractComponent implements OnInit {

  @Input()
  public action: string;
  @Input()
  public parentId: string;
  @Input()
  public id: string;
  @Input()
  public eventSources: ConfiuredEventSource[] = [];
  @Input()
  public packagedConfiguredPrograms: ConfiguredProgram[] = [];
  @Input()
  public configuredBatchExtracts: ConfiguredBatchExtract[] = [];
  @Output()
  public isModalDisplayed = new EventEmitter<any>();
  @Output()
  public batchExtractAction = new EventEmitter<any>();

  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;

  public eventSourceSelectOptions: { label: string; value: string }[] = [];
  public eventNameSelectOptions: Selectable[] = [];
  public companySelectOptions: { label: string; value: string }[] = [];
  public frequencySelectOptions: { label: string; value: string }[] = [];
  public configuredBatchExtractToEdit: ConfiguredBatchExtract;
  public configureBatchExtractFormGroup: UntypedFormGroup;
  public formBuilder: UntypedFormBuilder;
  public messages = Messages;
  public isSaveClicked = false;
  private isModalShown: boolean;
  private multiSelectItems: BehaviorSubject<Selectable[]> = new BehaviorSubject<Selectable[]>([]);

  public constructor(
    public authorizationService: AuthorizationService,
    formBuilder: UntypedFormBuilder) {
    this.formBuilder = formBuilder;
  }

  public ngOnInit(): void {
    this.initBatchFrequencySelectOptions();
    this.initEventSourceSelectOptions();
    this.buildConfiguredBatchExtractForm();
    this.launchBasicModal();
  }

  public onEventSourceChange(selectedEventSource: string): void {
    this.updateCompanyList(selectedEventSource);
  }

  public onCompanyIdChange(selectedCompany: string): void {
    this.updateEventList(this.configureBatchExtractFormGroup.get('eventSource').value, selectedCompany);
  }

  public getModalTitle(): string {
    return this.action === Action.CREATE ? 'New' : 'Edit';
  }

  public getMinStartTime(): Date {
    const date = new Date();
    date.setSeconds(0);
    return date;
  }

  public getMaxStartTime(): Date {
    return this.getTimeOneYearAhead();
  }

  public getMinimumEndTime(): Date {
    /*
      end-time should be in future
      end-time should be at least 1 hour ahead of start-date-time
    */
    const now = new Date();
    if (!this.configureBatchExtractFormGroup.get('startTime').value) {
      // start-date-time not selected by user, concider minimum-end-time as now + 1hr
      return moment(now).add(1, 'hour').toDate();
    }

    // Start date-time selected by user
    const startDateTime = new Date(this.configureBatchExtractFormGroup.get('startTime').value);
    const dateTime1HrAheadOfStartDateTime = moment(startDateTime).add(1, 'hour').toDate();
    if (dateTime1HrAheadOfStartDateTime < now) {
      // start-date-time + 1hr is not in future, concider minimum-end-time as now + 1hr
      return moment(now).add(1, 'hour').toDate();
    }

    // start-date-time + 1hr is in future, concider it as minimum-end-time
    return dateTime1HrAheadOfStartDateTime;
  }

  public getMaximumEndTime(): Date {
    return this.getTimeOneYearAhead();
  }

  public onSave(): void {
    this.isSaveClicked = true;
    if (this.action === Action.CREATE) {
      this.addConfiguredBatchExtract();
    }
    if (this.action === Action.EDIT) {
      this.updateConfiguredBatchExtract();
    }
  }

  public onDelete(): void {
    this.deleteConfiguredBatchExtract();
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayed.emit(this.isModalShown);
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  public isDisabled(): boolean {
    return this.configureBatchExtractFormGroup.get('eventSource').value === null
    || this.configureBatchExtractFormGroup.get('companyId').value === null;
  }

  public get EventsLabel(): string {
    return Messages.eventsLabel;
  }

  public get PlaceholderTxt(): string {
    return Messages.selectAnEventPlaceholderTxt;
  }

  public get MultiSelectDataStream(): BehaviorSubject<Selectable[]> {
    return this.multiSelectItems;
  }

  public get EventNamesFormControlName(): string {
    return 'eventNames';
  }

  private getTimeOneYearAhead(): Date {
    const maxAllowedDateTime = moment().add(1, 'year');
    return maxAllowedDateTime.toDate();
  }

  private buildConfiguredBatchExtractForm(): void {
    this.buildConfiguredBatchExtractFormToAdd();
    if (this.action === Action.EDIT) {
      this.configuredBatchExtractToEdit = this.configuredBatchExtracts.find(
        nextConfiguredBatchExtracts => nextConfiguredBatchExtracts.id === this.id);
      this.patchConfiguredBatchExtractForm();
      this.makeNonEditableFieldReadonly();
    }
  }

  private initBatchFrequencySelectOptions(): void {
    const batchExtractFrequencies: string[] = Object.values(BatchExtractFrequency);
    batchExtractFrequencies.forEach(nextFrequency => this.frequencySelectOptions
      = [...this.frequencySelectOptions, {
        label: nextFrequency.charAt(0).toUpperCase()
          + nextFrequency.slice(1).toLowerCase(), value: nextFrequency
      }]);
  }

  private initEventSourceSelectOptions(): void {
    this.eventSources.forEach(nextEventSource => this.eventSourceSelectOptions
      = [...this.eventSourceSelectOptions, { label: nextEventSource.name, value: nextEventSource.eventSource }]);
  }

  private buildConfiguredBatchExtractFormToAdd(): void {
    this.configureBatchExtractFormGroup = this.formBuilder.group({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, { validators: [Validators.required] }),
      eventSource: new UntypedFormControl(null, { validators: Validators.required }),
      companyId: new UntypedFormControl(null, { validators: Validators.required }),
      eventNames: new UntypedFormControl(null, { validators: Validators.required }),
      exportDestinationUrl: new UntypedFormControl(null, { validators: [Validators.required, Validators.pattern(Pattern.S3_URL)] }),
      startTime: new UntypedFormControl(null, { validators: Validators.required }),
      endTime: new UntypedFormControl(null, { validators: Validators.required }),
      interval: new UntypedFormControl(null, { validators: [Validators.required, Validators.min(1), Validators.pattern(Pattern.INTEGER)] }),
      frequency: new UntypedFormControl(null, { validators: Validators.required })
    });
    this.configureBatchExtractFormGroup.get('eventSource').valueChanges.subscribe(selectedEventSource => {
      this.onEventSourceChange(selectedEventSource);
    });
    this.configureBatchExtractFormGroup.get('companyId').valueChanges.subscribe(selectedCompanyId => {
      this.onCompanyIdChange(selectedCompanyId);
    });
    this.configureBatchExtractFormGroup.get('frequency').valueChanges.subscribe(frequency => {
      this.onFrequencyChange(frequency);
    });
  }

  private onFrequencyChange(freqValue: string): void {
    if (freqValue === 'HOURS') {
      this.configureBatchExtractFormGroup.get('interval').clearValidators();
      this.configureBatchExtractFormGroup.get('interval')
        .setValidators([Validators.required, Validators.min(1), Validators.max(23), Validators.pattern(Pattern.INTEGER)]);
    }

    if (freqValue === 'DAYS') {
      this.configureBatchExtractFormGroup.get('interval').clearValidators();
      this.configureBatchExtractFormGroup.get('interval')
        .setValidators([Validators.required, Validators.min(1), Validators.max(181), Validators.pattern(Pattern.INTEGER)]);
    }

    if (freqValue === 'WEEKS') {
      this.configureBatchExtractFormGroup.get('interval').clearValidators();
      this.configureBatchExtractFormGroup.get('interval')
        .setValidators([Validators.required, Validators.min(1), Validators.max(26), Validators.pattern(Pattern.INTEGER)]);
    }
    this.configureBatchExtractFormGroup.get('interval').updateValueAndValidity();
  }

  private patchConfiguredBatchExtractForm(): void {
    this.configureBatchExtractFormGroup.patchValue({ eventSource: this.configuredBatchExtractToEdit.eventSource });
    this.configureBatchExtractFormGroup.patchValue({ companyId: this.configuredBatchExtractToEdit.companyId });
    const patch = {
      id: this.configuredBatchExtractToEdit.id,
      name: this.configuredBatchExtractToEdit.name,
      eventNames: this.configuredBatchExtractToEdit.eventNames,
      exportDestinationUrl: this.configuredBatchExtractToEdit.exportDestination.url,
      startTime: new Date(this.configuredBatchExtractToEdit.schedule.startTime),
      endTime: new Date(this.configuredBatchExtractToEdit.schedule.endTime),
      interval: this.configuredBatchExtractToEdit.recurrence.interval,
      frequency: this.configuredBatchExtractToEdit.recurrence.frequency
    };
    this.configureBatchExtractFormGroup.patchValue(patch);
  }

  private makeNonEditableFieldReadonly() {
    this.configureBatchExtractFormGroup.get('name').disable({ onlySelf: true, emitEvent: false });
    this.configureBatchExtractFormGroup.get('eventSource').disable({ onlySelf: true, emitEvent: false });
    this.configureBatchExtractFormGroup.get('companyId').disable({ onlySelf: true, emitEvent: false });
    this.configureBatchExtractFormGroup.get('eventNames').disable({ onlySelf: true, emitEvent: false });
    this.configureBatchExtractFormGroup.get('exportDestinationUrl').disable({ onlySelf: true, emitEvent: false });
    this.configureBatchExtractFormGroup.get('startTime').disable({ onlySelf: true, emitEvent: false });
    this.configureBatchExtractFormGroup.get('interval').disable({ onlySelf: true, emitEvent: false });
    this.configureBatchExtractFormGroup.get('frequency').disable({ onlySelf: true, emitEvent: false });
    const endTime: Date = new Date(this.configuredBatchExtractToEdit.schedule.endTime);
    const now = new Date();
    if (endTime < now) {
      // End date is not in future. i.e. job is COMPLETED. Do not allow user to change end-date
      this.configureBatchExtractFormGroup.get('endTime').disable({ onlySelf: true, emitEvent: false });
    }
  }

  private addConfiguredBatchExtract(): void {
    this.validateForm();
    if (!this.configureBatchExtractFormGroup.invalid) {
      const configuredBatchExtract: ConfiguredBatchExtract = this.buildConfiguredBatchExtractFromForm();
      this.batchExtractAction.emit({ action: Action.CREATE, configuredBatchExtract: configuredBatchExtract });
      this.closeBasicModal();
    }
  }

  private updateConfiguredBatchExtract(): void {
    this.validateForm();
    if (!this.configureBatchExtractFormGroup.invalid) {
      const configuredBatchExtract: ConfiguredBatchExtract = this.buildConfiguredBatchExtractFromForm();
      this.batchExtractAction.emit({ action: Action.EDIT, configuredBatchExtract: configuredBatchExtract });
      this.closeBasicModal();
    }
  }

  private deleteConfiguredBatchExtract(): void {
    this.batchExtractAction.emit({ action: Action.DELETE, configuredBatchExtract: this.configuredBatchExtractToEdit });
    this.closeBasicModal();
  }

  private buildConfiguredBatchExtractFromForm(): ConfiguredBatchExtract {
    const configuredBatchExtract: ConfiguredBatchExtract = new ConfiguredBatchExtract();
    configuredBatchExtract.id = this.configureBatchExtractFormGroup.get('id').value;
    const name: string = this.configureBatchExtractFormGroup.get('name').value;
    configuredBatchExtract.name = name.trim();
    configuredBatchExtract.eventSource = this.configureBatchExtractFormGroup.get('eventSource').value;
    configuredBatchExtract.eventSourceName = this.configureBatchExtractFormGroup.get('eventSource').value
      === PackagedEventSourceName.CONVERSANT ? PackagedEventSourceName.EPSILON_TAG : this.configureBatchExtractFormGroup.get('eventSource').value;
    configuredBatchExtract.companyId = this.configureBatchExtractFormGroup.get('companyId').value;
    configuredBatchExtract.eventNames = this.extractEventNames();
    const exportDestination: ExportDestination = new ExportDestination();
    exportDestination.url = this.configureBatchExtractFormGroup.get('exportDestinationUrl').value;
    configuredBatchExtract.exportDestination = exportDestination;
    const schedule: Schedule = new Schedule();
    const startTime: Date = this.configureBatchExtractFormGroup.get('startTime').value;
    schedule.startTime = startTime.getTime();
    const endTime: Date = this.configureBatchExtractFormGroup.get('endTime').value;
    schedule.endTime = endTime.getTime();
    configuredBatchExtract.schedule = schedule;
    const recurrence: Recurrence = new Recurrence();
    recurrence.frequency = this.configureBatchExtractFormGroup.get('frequency').value;
    recurrence.interval = this.configureBatchExtractFormGroup.get('interval').value;
    configuredBatchExtract.recurrence = recurrence;
    return configuredBatchExtract;
  }

  private updateCompanyList(selectedEventSource: string): void {
    const confiuredEventSourceCompanies: Set<Company> = new Set<Company>();
    const eventSource: ConfiuredEventSource = this.eventSources.filter(nextEventSource => nextEventSource.eventSource === selectedEventSource)[0];
    eventSource.settings.forEach(nextSetting => {
      const company: Company = new Company();
      company.companyId = nextSetting.companyId;
      company.companyName = nextSetting.companyName;
      confiuredEventSourceCompanies.add(company);
    });
    this.configureBatchExtractFormGroup.get('companyId').reset(null, { onlySelf: true, emitEvent: false });
    this.companySelectOptions = [];
    confiuredEventSourceCompanies.forEach(nextCompany => {
      this.companySelectOptions = [...this.companySelectOptions, { label: nextCompany.companyName, value: nextCompany.companyId }];
    });
  }

  private updateEventList(selectedEventSource: string, selectedCompanyId: string): void {
    const configuredProgramEvents: Set<string> = new Set<string>();
    const configuredProgram: ConfiguredProgram[] = this.packagedConfiguredPrograms.filter(
      nextConfiguredProgram => nextConfiguredProgram.configuration.eventSource === selectedEventSource
        && nextConfiguredProgram.configuration.companyId === selectedCompanyId);
    configuredProgram.forEach(nextConfiguredProgram => {
      configuredProgramEvents.add(nextConfiguredProgram.configuration.eventType);
    });
    this.configureBatchExtractFormGroup.get('eventNames').reset([], { onlySelf: true, emitEvent: false });
    this.eventNameSelectOptions = [];
    configuredProgramEvents.forEach(nextEvent => this.eventNameSelectOptions
      = [...this.eventNameSelectOptions, { id: nextEvent, name: nextEvent }]);
    this.multiSelectItems.next(this.eventNameSelectOptions);
  }

  private validateMaxIntervalFrequency(interval: string): boolean {
    if (this.configureBatchExtractFormGroup.controls.interval.errors.max
      && this.configureBatchExtractFormGroup.controls.frequency.value === interval) {
      return true;
    } else {
      return false;
    }
  }

  private validateForm(): void {
    this.validateName();
    this.validateEventNames();
  }

  private validateName(): void {
    this.validateBlankValue(this.configureBatchExtractFormGroup.get('name'));
    if (this.configureBatchExtractFormGroup.get('name').hasError('required')) {
      return;
    }
    this.isNameAlreadyExist();
  }

  private validateEventNames(): void {
    this.validateBatchExtractConfiguration();
  }

  private validateBlankValue(control: AbstractControl): boolean {
    let isValid = true;
    const value: string = control.value;
    if (value && value.trim() === '') {
      control.setErrors({ 'required': true });
      isValid = false;
    }
    return isValid;
  }

  private isNameAlreadyExist(): boolean {
    let isValid = true;
    let configuredBatchExtracts: ConfiguredBatchExtract[] = this.configuredBatchExtracts;
    if (this.action === Action.EDIT) {
      configuredBatchExtracts = configuredBatchExtracts.filter((nextConfiguredBatchExtract: ConfiguredBatchExtract) =>
        nextConfiguredBatchExtract.id !== this.configuredBatchExtractToEdit.id);
    }
    const isAleradyExist = configuredBatchExtracts.find((nextConfiguredBatchExtract: ConfiguredBatchExtract) => {
      const existingEventName: string = nextConfiguredBatchExtract.name;
      const eventName: string = this.configureBatchExtractFormGroup.get('name').value;
      return existingEventName.toLowerCase() === eventName.toLowerCase();
    });
    if (isAleradyExist !== undefined) {
      this.configureBatchExtractFormGroup.get('name').setErrors({ 'alreadyExists': true });
      isValid = false;
    }
    return isValid;
  }

  private validateBatchExtractConfiguration(): boolean {
    let isAlreadyConfiured = false;

    if (this.configureBatchExtractFormGroup.get('eventSource').invalid
      || this.configureBatchExtractFormGroup.get('companyId').invalid
      || this.configureBatchExtractFormGroup.get('endTime').invalid
      || (this.configureBatchExtractFormGroup.get('eventNames').errors)
      && this.configureBatchExtractFormGroup.get('eventNames').errors.required) {
      return false;
    }

    const eventSource: string = this.configureBatchExtractFormGroup.get('eventSource').value;
    const companyId: string = this.configureBatchExtractFormGroup.get('companyId').value;
    const startTime: Date = this.configureBatchExtractFormGroup.get('startTime').value;
    const eventNames: string[] = this.configureBatchExtractFormGroup.get('eventNames').value;

    this.configureBatchExtractFormGroup.get('eventNames').clearValidators();
    this.configureBatchExtractFormGroup.get('eventNames').updateValueAndValidity();

    // Filter out ConfiguredBatchExtracts with other event source and companyId
    let configuredBatchExtracts: ConfiguredBatchExtract[] = this.configuredBatchExtracts.filter(
      nextConfiguredBatchExtract => nextConfiguredBatchExtract.eventSource === eventSource
        && nextConfiguredBatchExtract.companyId === companyId);

    // Filter out ConfiguredBatchExtracts which expires before the start of this batch extract
    configuredBatchExtracts = configuredBatchExtracts.filter(
      nextConfiguredBatchExtract => nextConfiguredBatchExtract.schedule.endTime >= startTime.getTime());

    // Filter out this configuredBatchExtractToEdit
    if (this.configuredBatchExtractToEdit) {
      configuredBatchExtracts = configuredBatchExtracts.filter(
        nextConfiguredBatchExtract => nextConfiguredBatchExtract.id !== this.configuredBatchExtractToEdit.id);
    }

    let confiuredEventName: string;
    let configuredBatchExtract: ConfiguredBatchExtract;
    for (const nextConfiguredBatchExtract of configuredBatchExtracts) {
      for (const eventName of eventNames) {
        if (nextConfiguredBatchExtract.eventNames.includes(eventName)) {
          confiuredEventName = eventName;
          configuredBatchExtract = nextConfiguredBatchExtract;
          this.configureBatchExtractFormGroup.get('eventNames').setErrors(
            {
              'alreadyConfigured': 'Batch Extract '
                + configuredBatchExtract.name + ' is already configured for event ' + confiuredEventName
            });
          isAlreadyConfiured = true;
          break;
        }
      }
      if (isAlreadyConfiured) {
        break;
      }
    }
    return isAlreadyConfiured;
  }

  private extractEventNames(): string[] {
    let eventNamesList:string[] = [];
    if(this.action === Action.EDIT){
      eventNamesList = this.configureBatchExtractFormGroup.get('eventNames').value as string[];
    }else if (this.action === Action.CREATE){
      let selectedItems = this.configureBatchExtractFormGroup.get('eventNames').value as Selectable[];
      selectedItems.map(event => eventNamesList.push(event.id));
    }
    return eventNamesList;
  }

}