import { UntypedFormGroup } from '@angular/forms';
import { Routable } from '../../guard/Routable';
import { CoreuiModalWarningComponent } from '../../component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { Directive, ViewChild } from '@angular/core';
import { AEModalInfoComponent } from '../../component/modal/ae-modal-info/ae-modal-info.component';
import { Observable } from 'rxjs';
import { WarningType } from '../../warning-options';
import { Messages } from '../../message';
@Directive()
export abstract class BaseFormDirective implements Routable {

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  protected warningModal: CoreuiModalWarningComponent;

  @ViewChild(AEModalInfoComponent, { static: true })
  protected infoModal: AEModalInfoComponent;

  public configuredEntityForm: UntypedFormGroup;
  public isDataSaved = false;
  public isSaveClicked = false;

  constructor() { }

  public canNavigateAway(): boolean {
    this.configuredEntityForm.updateValueAndValidity();
    return this.configuredEntityForm.pristine || this.isDataSaved;
  }

  public getWarningModal(): CoreuiModalWarningComponent {
    return this.warningModal;
  }

  public launchUnsavedChangesModal(): Observable<any> {
    this.warningModal.launchModal(WarningType.UNSAVED_CHANGES_WARNING, {
      msg2: [Messages.loseUnsavedChanges]
    });
    return this.warningModal.decision.asObservable();
  }

}
