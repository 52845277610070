import { EventType } from '../event-type';
import { SupportedEventSources } from './supported-event-sources';

export class EventSource {

  private name: SupportedEventSources;
  private eventTypes: EventType[];

  constructor(name: SupportedEventSources, eventTypes: EventType[]) {
    this.name = name;
    this.eventTypes = eventTypes;
  }

  public getName(): SupportedEventSources {
    return this.name;
  }

  public getEventTypes(): EventType[] {
    return this.eventTypes;
  }

  public getEventType(eventName: string): EventType {
    return this.eventTypes.find((eventType: EventType) => eventType.getName() === eventName);
  }

}