import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Group, Setting, TenantSetting } from 'src/app/shared/models/tenant-settings/tenant-settings';
import { SettingsService } from '../settings.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { Messages } from 'src/app/shared/message';
import { WarningType } from 'src/app/shared/warning-options';
import { ParentService } from 'src/app/shared/services/parent.service';

@Component({
  selector: 'app-custom-settings',
  templateUrl: './custom-settings.component.html'
})
export class CustomSettingsComponent implements OnInit, OnDestroy {

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  private warningModal: CoreuiModalWarningComponent;

  public tenantSettingsForm: UntypedFormGroup;
  public isCollapsed: boolean[] = [];
  public entityKey: string;
  public isPageLoading: boolean;
  public pageLoadMessage = '';
  public parentId: string;
  public configuredSettings: TenantSetting[];

  private tenantSettings: TenantSetting[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private featureIndex: number;
  private groupIndex: number;
  private settingIndex: number;
  private parentVerticalType: string;
  private filteredTenantSettings: TenantSetting[] = [];

  constructor(private fb: UntypedFormBuilder,
    private settingsService?: SettingsService,
    private parentContextService?: ParentContextService,
    private popupService?: PopupMessageService,
    public authorizationService?: AuthorizationService) {
    this.parentId = this.parentContextService.getParentContext();
    this.parentVerticalType = this.parentContextService.getParentVerticalType();
  }

  ngOnInit(): void {
    this.tenantSettingsForm = this.fb.group({});
    this.getTenantSettings();
  }

  saveTenantSettings(): void {
    if (this.tenantSettingsForm.touched && this.tenantSettingsForm.dirty) {
      this.configuredSettings = [];
      this.findModifiedTenantSettings(this.configuredSettings);
      this.tenantSettings = [];
      this.settingsService.updateConfiguredTenantSettings(this.parentId, this.configuredSettings)
        .subscribe(() => {
          this.popupService.showSuccessMessage(Messages.saveCustomSettingsSuccessMessage);
          const customSettings: TenantSetting[] = JSON.parse(JSON.stringify(this.tenantSettingsFormArray.getRawValue()));
          this.setTenantSettingForm(customSettings);
        }, () => {
          this.popupService.showErrorMessage(Messages.saveCustomSettingsErrorMessage);
        });
    }
  }

  public doTenantSettingsExists(): boolean {
    if (this.tenantSettingsForm !== undefined && this.tenantSettingsForm.get('tenantSettings')
      && (<UntypedFormArray> this.tenantSettingsForm.get('tenantSettings')).length > 0) {
      return true;
    }
    return false;
  }

  public setEditButtonValue(featureIndex: number, groupIndex: number, settingIndex: number): void {
    (<UntypedFormGroup>(<UntypedFormArray>(<UntypedFormGroup>(<UntypedFormArray> (<UntypedFormGroup> this.tenantSettingsFormArray.at(featureIndex))
      .get('groups')).at(groupIndex)).get('settings')).at(settingIndex)).patchValue({ isEdit: false });
  }

  public getSentenceCase(word: string) : string {
    const capitalLetter = word[0];
    const smallLetters = word.substring(1, word.length).toLowerCase();
    return capitalLetter + smallLetters;
  }

  public resetToDefault(featureIndex: number, groupIndex: number, settingIndex: number): void {
    this.featureIndex = featureIndex;
    this.groupIndex = groupIndex;
    this.settingIndex = settingIndex;
    this.warningModal.launchModal(WarningType.CHANGE_PROGRAM_MODE_WARNING, {
      title: Messages.resetToDefaultSettings,
      msg: Messages.resetToDefaultSettingsConsequence,
      msg2: [Messages.confirmResetToDefaultSettings]
    });
  }

  public handleDecision(decision: boolean): void {
    if (decision) {
      const defaultValue = this.tenantSettings[this.featureIndex].groups[this.groupIndex].settings[this.settingIndex].defaultValue;
      const setting: UntypedFormGroup = (<UntypedFormGroup>(<UntypedFormArray>(<UntypedFormGroup>(<UntypedFormArray> (<UntypedFormGroup> this.tenantSettingsFormArray.at(this.featureIndex))
        .get('groups')).at(this.groupIndex)).get('settings')).at(this.settingIndex));
      setting.patchValue({
        defaultValue: defaultValue,
        updatedValue: defaultValue,
        isEdit: true });
      setting.markAsDirty();
      setting.markAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private findModifiedTenantSettings(configuredSettings: TenantSetting[]): void {
    this.tenantSettingsFormArray.controls.forEach((feature, findex) => {
      if (feature.touched && feature.dirty) {
        (<UntypedFormArray>(<UntypedFormGroup>feature).controls.groups).controls.forEach((group, gindex) => {
          if (group.touched && group.dirty) {
            this.findModifiedSetting(<UntypedFormGroup>feature, <UntypedFormGroup>group, findex, gindex, configuredSettings);
          }
        });
      }
    });
  }

  private findModifiedSetting(feature: UntypedFormGroup, group: UntypedFormGroup, findex: number, gindex: number, configuredSettings: TenantSetting[]): void {
    (<UntypedFormArray>(group).controls.settings).controls.forEach((settingFG, sindex) => {
      if (settingFG.touched && settingFG.dirty) {
        const setting = (<UntypedFormGroup>settingFG).controls;
        const apiData = this.tenantSettings[findex].groups[gindex].settings[sindex];
        if ((setting.updatedValue.value !== setting.defaultValue.value)
          || (apiData.updatedValue !== undefined && apiData.updatedValue !== setting.defaultValue.value)) {
          this.buildModifiedTenantSettings(feature, gindex, sindex, configuredSettings);
        }
      }
    });
  }

  private buildModifiedTenantSettings(featureFG: UntypedFormGroup, gindex: number, sindex: number, configuredSettings: TenantSetting[]): void {
    const tenantFeature: TenantSetting = JSON.parse(JSON.stringify((featureFG).getRawValue()));
    const tenantGroup: Group = tenantFeature.groups[gindex];
    const tenantSetting: Setting = tenantFeature.groups[gindex].settings[sindex];
    delete tenantSetting.isEdit;
    let isFeaturePresent: boolean;
    let isGroupPresent: boolean;
    let isSettingPresent: boolean;

    configuredSettings.forEach((feature, findex) => {
      if (feature.name === tenantFeature.name) {
        isFeaturePresent = true;
        configuredSettings[findex].groups.forEach((group, gindex) => {
          if (group.name === tenantGroup.name) {
            isGroupPresent = true;
            configuredSettings[findex].groups[gindex].settings.forEach((setting, sindex) => {
              if (setting.name === tenantSetting.name) {
                isSettingPresent = true;
                configuredSettings[findex].groups[gindex].settings[sindex].updatedValue = tenantSetting.updatedValue;
              }
            });
            if (!isSettingPresent) {
              const settingslength = configuredSettings[findex].groups[gindex].settings.length;
              configuredSettings[findex].groups[gindex].settings[settingslength] = tenantSetting;
            }
          }
        });
        if (!isGroupPresent) {
          this.buildModifiedGroup(findex, configuredSettings, tenantGroup, tenantSetting);
        }
      }
    });
    if (!isFeaturePresent) {
      this.buildModifiedFeature(featureFG, tenantGroup, tenantSetting, configuredSettings);
    }
  }

  private buildModifiedFeature(featureFG: UntypedFormGroup, group: Group, setting: Setting, configuredSettings: TenantSetting[]): void {
    const length = configuredSettings.length;
    const feature: TenantSetting = JSON.parse(JSON.stringify((featureFG).getRawValue()));
    feature.groups = [];
    feature.groups[0] = group;
    feature.groups[0].settings = [];
    feature.groups[0].settings[0] = setting;
    configuredSettings[length] = feature;
  }

  private buildModifiedGroup(findex: number, configuredSettings: TenantSetting[], tenantGroup: Group, tenantSetting: Setting): void {
    const groupslength = configuredSettings[findex].groups.length;
    configuredSettings[findex].groups[groupslength] = tenantGroup;
    configuredSettings[findex].groups[groupslength].settings = [];
    configuredSettings[findex].groups[groupslength].settings[0] = tenantSetting;
  }

  private createTenantSettingsForm(): UntypedFormGroup {
    if (this.tenantSettings !== null && this.tenantSettings !== undefined && this.tenantSettings.length > 0) {
      this.tenantSettingsForm = this.fb.group({
        tenantSettings: this.fb.array(this.buildFeatureForm())
      });
    }
    return this.tenantSettingsForm;
  }

  private buildFeatureForm(): UntypedFormGroup[] {
    return this.tenantSettings.map(feature => this.fb.group({
      id: feature.id,
      name: feature.name,
      groups: this.fb.array(this.buildGroupsForm(feature.groups))
    }));
  }

  private buildGroupsForm(groups: Group[]): UntypedFormGroup[] {
    return groups.map(group => this.fb.group({
      name: group.name,
      title: group.title,
      settings: this.fb.array(this.buildSettingsForm(group.settings))
    }));
  }

  private buildSettingsForm(settings: Setting[]): UntypedFormGroup[] {
    return settings.map(setting => this.fb.group({
      description: setting.description,
      name: setting.name,
      minValue: setting.minValue,
      defaultValue: setting.defaultValue,
      updatedValue: setting.updatedValue === undefined ? setting.defaultValue : setting.updatedValue,
      maxValue: setting.maxValue,
      replacementKey: setting.replacementKey,
      subType: setting.subType,
      type: setting.type,
      isEdit: (setting.updatedValue === undefined || setting.updatedValue === setting.defaultValue) ? true : false
    }));
  }

  get tenantSettingsFormArray(): UntypedFormArray {
    if (this.tenantSettingsForm !== undefined) {
      return <UntypedFormArray> this.tenantSettingsForm.get('tenantSettings');
    }
  }

  private getTenantSettings(): void {
    this.isPageLoading = true;
    this.pageLoadMessage = 'loading custom settings';
    this.settingsService.getTenantSettings(this.parentId)
      .pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          const tenantSettingsResult: TenantSetting[] = res['result'];
          this.setTenantSettingForm(this.filterTenantSettingsByParentIdAndVerticalType(tenantSettingsResult, this.parentVerticalType));
          this.isPageLoading = false;
        }, () => {
          this.isPageLoading = false;
        });
  }

  private setTenantSettingForm(result: TenantSetting[]): void {
    this.tenantSettingsForm = this.fb.group({});
    this.tenantSettings = [];
    result.forEach(tenantSetting => {
      this.tenantSettings.push(new TenantSetting(tenantSetting));
    });
    this.createTenantSettingsForm();
  }

  private filterTenantSettingsByParentIdAndVerticalType(tenantSettingsResult: TenantSetting[], parentVerticalType: string): TenantSetting[] {
    tenantSettingsResult.forEach((setting: TenantSetting) => {
      const settingGroup: Group[] = [];
      setting.groups.forEach((group: Group) => {
        if (this.isValidTenantSettingGroup(group, parentVerticalType)) {
          settingGroup.push(group);
        }
      });
      const tenantSetting: TenantSetting = { id: setting.id, name: setting.name, visible: setting.visible, groups: settingGroup };
      this.filteredTenantSettings.push(tenantSetting);
    });
    return this.filteredTenantSettings;
  }

  private isValidTenantSettingGroup(group: Group, parentVerticalType: string): boolean {
    if (parentVerticalType !== null && group.verticalType !== null) {
      if (group.verticalType !== parentVerticalType) {
        return false;
      } else {
        if (group.parentId !== null && group.parentId !== this.parentId) {
          return false;
        }
      }
    } else {
      if (group.parentId !== null && group.parentId !== this.parentId) {
        return false;
      }
    }
    return true;
  }

}