<div class="container-fluid my-3">
  <div class="row">
    <div class="col col-sm-6 col-xl-3">
        <app-ae-table-search
        label="Search"
        placeholder="Search"
        (searchEmitter)="handleSearch($event)"
        #searchComponent>
        </app-ae-table-search>
    </div>
    <div class="col-sm-6 col-xl-3">
      <div class="form-group" hidden>
        <bp-multi-select label="Filter by Groups" [optionItems]="userGroups" placeholder="All"
          ngDefaultControl (change)="filterByUserGroups($event)" [(ngModel)]="userGroupsForParent">
        </bp-multi-select>
      </div>
    </div>
    <div class="col-auto ml-lg-auto mb-4 mt-lg-4" *ngIf="isRoleCreateAllowed()">
      <app-ae-button buttonText="Create Role" buttonType="primary" [routerLink]="createRoleRouterLink"></app-ae-button>
    </div>
  </div>
</div>
<div class="container-fluid my-3">
  <div class="row">
    <div class="col-12">
      <app-ae-table [isDataLoading]="isDataLoading" [properties]="properties" [configuredTableData]="configuredRolesTableFilteredData"  (actionClick)="actionClick($event)">
      </app-ae-table>
    </div>
  </div>
</div>
<ng-template #createDate let-rowData>
  {{ rowData.createDate | date:'MM/dd/yyyy h:mmaaaaa\'m\'' }}
</ng-template>
<ng-template #modifiedDate let-rowData>
  {{ rowData.modifiedDate | date:'MM/dd/yyyy h:mmaaaaa\'m\'' }}
</ng-template>
