import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'time-duration',
  templateUrl: './time-duration.component.html'
})
export class TimeDurationComponent implements OnInit {

  @Input() public parentForm;
  @Input() public durationTitle: string;
  @Input() public duration: string;
  @Input() public index: number;
  @Output() public validationStatus = new EventEmitter<boolean>();

  public dayList: number[] = [0, 1, 2, 3, 4, 5, 6, 7];
  public hourList: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  public minuteList: number[] = [0, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    const durationDetails = moment.duration(this.duration);
    this.parentForm.addControl('durationControls', this.formBuilder.group({
      days: durationDetails.days(),
      hours: durationDetails.hours(),
      minutes: durationDetails.minutes()
    }));
  }

  getDelay() {
    return moment.duration({
      minutes: this.parentForm.controls.durationControls.value.minutes,
      hours: this.parentForm.controls.durationControls.value.hours,
      days: this.parentForm.controls.durationControls.value.days }).toISOString();
  }

  validateDuration() {
    this.parentForm.controls.durationControls.markAsTouched();
    this.validationStatus.emit(this.parentForm.controls.durationControls.invalid);
  }

}