import { ProgramStatus } from '../../program-status';
import { Type } from '../roleType';

export class UserGroupDetailsTableRow {

  id: string;
  name: string;
  type: Type;
  description: string;
  createdDate: string;
  modifiedDate: string;
  modifiedBy: string;
  status: ProgramStatus;
  routerLink: string;

}