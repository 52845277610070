import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Parent } from '../models/parent';
import { Router } from '@angular/router';
import { Constants } from '../constants';


@Injectable({
  providedIn: 'root'
})

export class ParentContextService {

  public static PARENT_LIST = 'parentList';
  host = environment.host;
  base = environment.base;
  parentContext: BehaviorSubject<string>;
  parentId = '';
  static PARENT_ID = 'parentId';
  static VERTICAL_TYPE = 'verticalType';
  static SYSTEMURLLINKS = ['/admin/users', '/admin/roles']
  url: string;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private http: HttpClient, private router: Router) { }

  getParentContext() {
    this.url = this.router.url;
    if (this.url.includes('/admin/') && !this.url.includes('/client/')){
      return Constants.SYSTEM_PARENT
    } else {
      return this.storage.get(ParentContextService.PARENT_ID);
    }
  }

  setParentContext(parentId: string) {
    this.storage.set(ParentContextService.PARENT_ID, parentId);
  }

  getParentsList() {
    return this.http.get(this.host + this.base + 'parent');
  }

  observeParentContext() {
    return this.parentContext.asObservable();
  }

  setParentId(parentId: string) {
    this.parentId = parentId;
  }

  public getParentVerticalType(): string {
    return this.storage.get(ParentContextService.VERTICAL_TYPE);
  }

  public setParentVerticalType(verticalType: string): void {
    this.storage.set(ParentContextService.VERTICAL_TYPE, verticalType);
  }

  isParentContextSet() {
    const value = this.storage.get(ParentContextService.PARENT_ID);
    if (!value || value === undefined || value === '' || value === 'NO_PARENT_CONFIGURED') {
      return false;
    }
    return true;
  }

  public getParentListContext(): Parent[] {
    return this.storage.get(ParentContextService.PARENT_LIST);
  }

  public setParentListContext(parentList: Parent[]): void {
    this.storage.set(ParentContextService.PARENT_LIST, parentList);
  }

  public getParentName(parentId: string): string {
    const parent : Parent = this.storage.get(ParentContextService.PARENT_LIST).find(p => p.id === this.parentId);
    return parent.name;
  }

}