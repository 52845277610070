<app-base-template>
	<app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
	<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
	<app-ae-modal-info></app-ae-modal-info>
	<div class="container-fluid pt-4">
		<coreui-breadcrumbs>
			<a routerLink="/iqdecisions" coreUIBreadcrumbLink>{{messages.iqDecisions}}</a>
			<span coreUIBreadcrumbSlash></span>
			<a routerLink="/iqdecisions" coreUIBreadcrumbLink>{{messages.packages}}</a>
			<span coreUIBreadcrumbSlash></span>
			<span coreUIBreadcrumbEnd> {{messages.multiModelPackage}}</span>
		</coreui-breadcrumbs>
		<div class="row align-items-center py-sm-3">
			<div class="col">
				<div class="d-flex icon-header">
					<i coreUIIcon="surveySettings" class="d-flex align-items-center mr-3 mt-1" style="color: #FF4600"
						scale="2.2"></i>
					<h1 class="text-style-0 header-multi font-weight-bold">New Multi-Model Package</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="container-fluid mt-5">
		<div class="row">
			<div class="col-4">
				<coreui-step-wizard wizardStyle="ordered" #orderedWizard (change)="onStepChange($event)"
					(next)="onNextStepChange($event)" (previous)="onPreviousStepChange($event)">
					<coreui-step status="verified" label="Package Info" #orderedFirstStep [active]=true></coreui-step>
					<coreui-step status="disabled" label="Models"></coreui-step>
					<coreui-step status="disabled" label="Review &amp; Publish" #orderedLastStep></coreui-step>
				</coreui-step-wizard>
			</div>
		</div>
		<form *ngIf="configuredEntityForm" [formGroup]="configuredEntityForm">
			<div *ngIf="getCurrentStep() == 0">
				<div class="row pt-5">
					<div class="col-4">
						<coreui-form-field label="Company" for="eventSourceProvider">
							<coreui-select id="eventSourceProvider" formControlName="company" placeholder="Select Company"
								(valueChange)="doGetPackagesTypes($event)">
								<coreui-option [value]="" [disabled]="true">Select Company</coreui-option>
								<coreui-option *ngFor="let item of this.companyList | keyvalue"
									value="{{selectOptionValue(item.value.companyId, item.value.companyName) | json}}">
									{{item.value.companyName}}
								</coreui-option>
							</coreui-select>
							<coreui-form-error>
								<div>{{messages.companyError}}</div>
							</coreui-form-error>
						</coreui-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-4">
						<div class="tooltip-container">
							<ng-template #nameTooltip>
								{{tooltipMessages.nameTooltip}}
							</ng-template>
						</div>
						<coreui-form-field label="Package Name">
							<input coreUIInput name="packageName" formControlName="packageName" [coreUITooltip]="nameTooltip"
								(keyup)="packageNameValidations()" position="right" />
							<coreui-form-error *ngIf="packageName.errors?.required">
								<div> {{messages.packageNameRequiredValidationMessage}}</div>
							</coreui-form-error>
							<coreui-form-error *ngIf="packageName.errors?.maxlength">
								<div>{{messages.packageNameMaxLengthValidationMessage}}</div>
							</coreui-form-error>
							<coreui-form-error *ngIf="packageName.errors?.alreadyExist ">
								<div>{{messages.packageNameUniqueValidationMessage}} </div>
							</coreui-form-error>
						</coreui-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-4">
						<div class="tooltip-container">
							<ng-template #packageTypeTooltip>
								{{tooltipMessages.packageTypeTooltip}}
							</ng-template>
						</div>
						<coreui-form-field label="Package Type" for="packageType">
							<coreui-select id="packageType" placeholder="Select Package Type" formControlName="recommendationType"
								[coreUITooltip]="packageTypeTooltip" position="right" (valueChange)="addPackageLevelInput($event)">
								<coreui-option [value]="" [disabled]="true">Select Package Type</coreui-option>
								<coreui-option *ngFor="let item of packageTypesList;"
									[value]="selectOptionValue(item.id, item.name) | json">
									{{item.name}}
								</coreui-option>
							</coreui-select>
							<coreui-form-error>
								<div>{{messages.packageTypeError}}</div>
							</coreui-form-error>
						</coreui-form-field>
					</div>
				</div>
				<div class="row" *ngIf="showCategoryLevel()">
					<div class="col-4">
						<div class="tooltip-container">
							<ng-template #categoryLevelTooltip>
								{{tooltipMessages.categoryLevelTooltip}}
							</ng-template>
						</div>
						<coreui-form-field label="Category Level">
							<coreui-select id="choiceTypeName" placeholder="Select Category Level" formControlName="choiceTypeName"
								[coreUITooltip]="categoryLevelTooltip" position="right" (valueChange)="onItemChange($event)">
								<coreui-option [value]="" [disabled]="true">Select Category Level</coreui-option>
								<coreui-option *ngFor="let item of choiceTypeList;"
									[value]="item.name">
									{{item.name}}
								</coreui-option>
							</coreui-select>
							<coreui-form-error>
								<div>{{messages.packageLevelError}}</div>
							</coreui-form-error>
						</coreui-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-4">
						<coreui-form-field label="Max Items to Return">
							<input coreUIInput type="number" name="maxItemReturned" formControlName="maxItemReturned" />
							<coreui-form-error *ngIf="maxItemReturned.errors?.required">
								<div>{{messages.maxItemReturnedRequiredMessage}}</div>
							</coreui-form-error>
							<coreui-form-error *ngIf="maxItemReturned.errors?.min">
								<div>{{messages.maxItemReturnedMinValueMessage}}</div>
							</coreui-form-error>
							<coreui-form-error *ngIf="maxItemReturned.errors?.max">
								<div>{{messages.maxItemReturnedMaxValueMessage}}</div>
							</coreui-form-error>
						</coreui-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-4">
						<div class="tooltip-container">
							<ng-template #descriptionTooltip>
								{{tooltipMessages.descriptionTooltip}}
							</ng-template>
						</div>
						<coreui-form-field label="Description" for="description">
							<textarea coreUIInput id="description" placeholder="Add description" formControlName="description"
								rows="6" [coreUITooltip]="descriptionTooltip" position="right">
                    </textarea>
					<coreui-form-error>
						<div>{{messages.descriptionValidation}}</div>
					</coreui-form-error>
						</coreui-form-field>
					</div>
				</div>
			</div>
			<div *ngIf="getCurrentStep() == 1">
				<div class="row pt-5">
					<div class="col-6">
						{{messages.selectModelHeaderMessage}}
					</div>
				</div>
				<div class="row pb-2">
					<div class="col-6">
						<!-- MODELS SECTION -->
						<app-iqdecisions-multi-model-group-info [groupInfo]="configuredEntityForm.get('groupInfos')" [level]="level"
							[originalModel]="originalModels" [isEditMode]="false"
							(displayModelParameterEvent)="displayModelParametersMethod()"></app-iqdecisions-multi-model-group-info>
					</div>
				</div>
				<div class="row pt-4">
					<div *ngIf="displayModelParameters" class="col-6">
						<!-- MODEL PARAMETERS -->
						<app-iqdecisions-view-model-parameters [isEditMode]="displayEditForm"
							[configuredRecommendation]="configuredRecommendation" [modelParametersFormControl]="modelParametersForm" [saveForLaterClicked]="saveForLater">
						</app-iqdecisions-view-model-parameters>
					</div>
				</div>
			</div>
				<div *ngIf="getCurrentStep() == 2">
					<div class="row pt-5">
						<div class="col-12">
							<!-- PACKAGE INFO -->
							<app-iqdecisions-view-model-package-info [configuredRecommendation]="configuredRecommendation" [choiceTypeName]="choiceTypeName"
								[isEditMode]="false" [packageInfoFormControl]="configuredEntityForm" [categoryLevelsList]="choiceTypeList">
							</app-iqdecisions-view-model-package-info>
							<!-- MODELS SECTION -->
							<app-iqdecisions-view-models [configuredRecommendation]="configuredRecommendation"
								[modelsProperties]="modelsProperties" [modelsTableData]="modelsTableData" [isEditMode]="false">
							</app-iqdecisions-view-models>
							<!-- MODEL PARAMETERS-->
							<app-iqdecisions-view-model-parameters [configuredRecommendation]="configuredRecommendation"
              [modelParametersFormControl]="modelParametersForm" [isEditMode]="false">
							</app-iqdecisions-view-model-parameters>
						</div>
					</div>
				</div>
		</form>

		<!-- ACTION BUTTONS SECTION -->
		<div class="row pb-4">
			<div class="col-5 pt-5">
				<app-ae-button [buttonText]="orderedLastStep.active ? 'Publish' : 'Save &amp; Continue'" class="mr-2"
					buttonType="primary" type="button" [disabled]="configuredEntityForm.invalid"
					(click)="orderedLastStep.active ?  publishRecommendation() : (currentPageValidations() ? orderedWizard.nextStep() : disablePageShift())"
					*ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['CREATE','UPDATE'])"></app-ae-button>
				<app-ae-button [buttonText]="messages.saveForLaterBtnText" class="mr-2" buttonType="secondary" type="button"
					(click)="saveChangesForLater()"
					[disabled]="!configuredEntityForm.get('company').value || !configuredEntityForm.get('packageName').value || !configuredEntityForm.get('recommendationType').value"
					*ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['CREATE'])">
				</app-ae-button>
				<app-ae-button type="button" [buttonText]="messages.cancelBtnTxt" buttonType="secondary"
					(click)="cancelButtonClicked()"></app-ae-button>
			</div>
		</div>
		<a *ngIf="!orderedFirstStep.active" href="javascript:void(0);" (click)="orderedWizard.previousStep()">
			<i coreUIIcon="doubleChevronLeft" class="previous-cta"></i>Previous</a>
	</div>
	<ng-template #shareValidationTooltip>
		<span class="tooltipMessage">
			Share percentages <br> must sum to 100.
		</span>
	</ng-template>
	<div class="tooltip-container">
		<ng-template #groupTooltip>
			Provide a unique name that <br> convey the purpose or intent of the group, <br> and allow for clear communication when <br> viewing performance reports
		</ng-template>
	</div>
</app-base-template>
