import { ActionSettings } from './ActionSettings';
import { AuthenticationCredential } from './AuthenticationCredential';
import { OauthCredential } from './oauth-credential';

export class OauthActionSettings implements ActionSettings {

  authenticationMethod: string;
  authUrl: string;
  grantType: string;
  scope: string;
  oauthCredential: AuthenticationCredential;

  public buildActionSettings() {
    const oauthCred = this.oauthCredential as OauthCredential;
    return {
      'type': `${this.authenticationMethod}`,
      'credentials': `${oauthCred.toString()}`,
      'scope': `${this.scope}`,
      'grantType': `${this.grantType}`,
      'url': `${this.authUrl}`
    };
  }

  public buildJSONActionSettings() {
    const oauthCred = this.oauthCredential as OauthCredential;
    return {
      'type': `${this.authenticationMethod}`,
      'credentials': oauthCred.toJSONString(),
      'scope': `${this.scope}`,
      'grantType': `${this.grantType}`,
      'url': `${this.authUrl}`
    };

  }

}