<app-base-template>
  <div class="container-fluid pt-4 px-5">
      <div class="row align-items-center py-sm-3">
        <div class="col">
          <h1 class="mb-0">Clients Overview
          </h1>
        </div>
        <div class="col text-right">
          <app-ae-button iconPosition="left" icon="add" buttonText="Onboard Client" routerLink="/admin/clients/add/id" [disabled]="!parentContextService.isParentContextSet()"></app-ae-button>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-4 px-5">
      <ng-container *ngIf="configuredParentTableList">
        <div class="row">
          <div class="col-12">
            <app-ae-table [isDataLoading]="isDataLoading" [configuredTableData]="configuredParentTableFilteredData"
              [properties]="properties">
            </app-ae-table>
          </div>
        </div>
      </ng-container>
    </div>
</app-base-template>
