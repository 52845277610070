import { Injectable } from '@angular/core';
import { ParentContextService } from './parent-context.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { BaseLoginService } from './base-login.service';
import { LoginService } from './LoginService';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from './authorization-service';
import { ExternalSystemLoginComponent } from 'src/app/modules/external-system-login/external-system-login.component';

@Injectable({ providedIn: 'root' })
export class ExternalSystemLoginService extends BaseLoginService implements LoginService {

  public isOktaSession: boolean;
  public isLogedIn: boolean;
  private externalSource: string;
  private externalTenantId: string;
  private expiresAt: number;

  private host = environment.host;
  private base = environment.base;
  private authPath = 'login/external-system/';

  constructor(public http: HttpClient,
    private router: Router,
    private authorizationService: AuthorizationService) {
    super(router);
  }

  public isExternalSystem(): boolean {
    return this.externalSource && this.externalTenantId ? true : false;
  }

  public isLoggedIn(): boolean {
    if (this.isOktaSession) {
      this.isLogedIn = true;
    }
    this.getSessionExpiryTime();
    if (this.getUserName() && this.expiresAt && moment().unix() < this.expiresAt) {
      this.isLogedIn = true;
    }
    return this.isLogedIn;
  }

  public logout(): void {
    console.log('User logged out of External System: Sign out');
    localStorage.removeItem(ParentContextService.PARENT_LIST);
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('okta-token-storage');
  }

  public authorizationFailed(message: string): void {
    setTimeout(() => this.logout(), 2000);
    console.log('result: ' + message);
  }

  public intializePermissionOnLoginFromExternalSystem(externalSystemLoginComponent: ExternalSystemLoginComponent): void {
    const url = this.host + this.base + this.authPath + this.getUserName() + '/' + this.getExternalSource
      + '/' + this.getExternalTenantId;
    this.authorizationService.getUserAuthorizationDetailsLoggedInFromExternalSystem(url, externalSystemLoginComponent, this);
  }

  public getOktaSession(sessionUri: string): Observable<any> {
    this.isOktaSession = true;
    const options = { withCredentials: true };
    return this.http.get(sessionUri, options);
  }

  public get getExternalSource(): string {
    return this.externalSource;
  }

  public set setExternalSource(value: string) {
    this.externalSource = value;
  }

  public get getExternalTenantId(): string {
    return this.externalTenantId;
  }

  public set setExternalTenantId(value: string) {
    this.externalTenantId = value;
  }

  public getUserName(): string {
    return localStorage.getItem('username');
  }

  private getSessionExpiryTime(): void {
    if (localStorage.getItem('okta-token-storage') && !this.expiresAt) {
      const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
      if (oktaTokenStorage && oktaTokenStorage.idToken && oktaTokenStorage.idToken.expiresAt) {
        this.expiresAt = oktaTokenStorage.idToken.expiresAt;
      } else if (!oktaTokenStorage.idToken) {
        this.logout();
      }
    } else if (this.expiresAt && moment().unix() > this.expiresAt) {
      this.logout();
    }
  }

}
