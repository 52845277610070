import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionService } from 'src/app/shared/services/action.service';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { Action } from 'src/app/shared/models/action';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionStatus } from 'src/app/shared/action-status';
import { BaseComponent } from '../../client/base/base.component';
import { Mode } from 'src/app/shared/models/mode-enum';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { EntityType } from 'src/app/shared/entity-type';
import { OperationType } from '../../../shared/constants';
import { UtilService } from 'src/app/shared/services/util-service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-actions-home',
  templateUrl: './actions-home.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ActionsHomeComponent extends BaseComponent implements OnInit {

  @ViewChild('nameColumnTemplate', { static: true })
  nameColumnTemplate: TemplateRef<any>;
  @ViewChild('actionColumnTemplate', { static: true })
  actionColumnTemplate: TemplateRef<any>;
  @ViewChild('statusColumnTemplate', { static: true })
  statusColumnTemplate: TemplateRef<any>;
  @ViewChild('dateTemplateColumn', { static: true }) dateTemplateColumn: TemplateRef<any>;
  parentId: string;
  configuredActions: Action[];
  public loadingIndicator = false;
  public columns = [];
  public sorts = [];
  public isDataLoading = false;
  searchByName: string;
  readConfiguredActionList: Action[];
  public properties = {};
  configuredActionTableList: any = [];
  configuredActionTableFilteredData: any = [];
  pageIndices;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public parentContextService: ParentContextService,
    private actionService: ActionService,
    private actionDestinationService: ActionDestinationService,
    private route: ActivatedRoute,
    private router: Router,
    public authorizationService: AuthorizationService,
    private datePipe: DatePipe) {
    super();
    this.initDataTableConfig();
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.getConfiguredActions();
  }

  handleSearch(searchInput: string) : void {
    if (searchInput === null || searchInput === undefined) {
      searchInput = '';
    }
    if (this.searchByName !== searchInput) {
      this.searchByName = searchInput.trim();
      this.filterConfiguredEvents();
    }
  }

  filterConfiguredEvents(): void {
    if (this.searchByName && this.searchByName.trim()) {
      this.searchByName = this.searchByName.trim();
      this.configuredActions = this.readConfiguredActionList
        .filter((action: Action) => {
          return action.name.toLowerCase().includes(this.searchByName.toLowerCase());
        });
    } else {
      this.configuredActions = this.readConfiguredActionList;
    }
    this.initConfiguredActionTableList();
  }
  initDataTableConfig(): void {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Action Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Action Destination',
          key: 'actionDestinationId',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Source',
          key: 'source',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Mode',
          key: 'mode',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Action Description',
          key: 'description',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          key: 'status',
          headerText: 'Status',
          isSortable: true,
          isColumnDisplayed: true,
          statusIndicatorMapping: {
            'incomplete-primary': ActionStatus.INACTIVE,
            'complete': ActionStatus.ACTIVE
          }
        },
        {
          headerText: 'Programs',
          key: 'programs',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.dateTemplateColumn
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private initConfiguredActionTableList() {
    if (!this.configuredActions || this.configuredActions === undefined) {
      return;
    }
    this.configuredActionTableFilteredData = [];
    this.configuredActions.forEach(action => {
      const configuredActionTableRow = {};
      configuredActionTableRow['id'] = action.id;
      configuredActionTableRow['name'] = action.name;
      configuredActionTableRow['status'] = action.status === 'ACTIVE' ? ActionStatus.ACTIVE : ActionStatus.INACTIVE;
      configuredActionTableRow['actionDestinationId'] = action.actionDestinationId;
      configuredActionTableRow['source'] = action.entityType;
      configuredActionTableRow['mode'] = action.getModeDisplayName();
      configuredActionTableRow['description'] = action.description;
      configuredActionTableRow['programs'] = action.programs;
      configuredActionTableRow['routerLink'] = this.getRouterLink(action);
      configuredActionTableRow['modifiedBy'] = action.modifiedBy;
      configuredActionTableRow['modifiedDate'] = this.datePipe.transform(new Date(action.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
      this.configuredActionTableFilteredData.push(configuredActionTableRow);
    });
    this.configuredActionTableList = this.configuredActionTableFilteredData.slice(0, 10);
  }


  private getRouterLink(action: Action): string {
    if (action.entityType.toLowerCase() === EntityType.PACKAGED.toLowerCase()) {
      return `/actions/${OperationType.EDIT}/${action.entityType.toLowerCase()}/${action.serviceId}`;
    } else {
      return `/actions/${OperationType.EDIT}/${action.entityType.toLowerCase()}/${action.id}`;
    }
  }

  private actionClick(action: any) {
    this.router.navigate(['/actions/editAction/' + action.rowId]);
  }

  private getConfiguredActions(): void {
    this.loadingIndicator = true;
    this.actionService.getConfiguredActions(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((res: Action[]) => {
      this.readConfiguredActionList = res['result'].flat().map((action: Action) => new Action(action));
      this.configuredActions = res['result'].flat().map((action: Action) => new Action(action));
      this.getActionDestinations(this.configuredActions);
    }, error => {
      this.loadingIndicator = false;
      this.isDataLoading = false;
    });
  }

  private getActionDestinations(configuredActions: Action[]): void {
    this.actionDestinationService.getActionDestinations(this.parentId).subscribe(data => {
      const actionDestinations: any[] = data['result'];
      const customAD = [];
      if (actionDestinations !== null && actionDestinations.length > 0) {
        actionDestinations.forEach(actionDestination => {
          if (actionDestination.type === 'CUSTOM' || actionDestination.type === 'AWS') {
            customAD.push(actionDestination);
          }
        });
      }
      this.configuredActions.forEach(configuredAction => {
        const filteredAD = customAD.filter(actionDestination => actionDestination.actionDestination === configuredAction.actionDestinationId);
        if (filteredAD !== null && filteredAD.length > 0) {
          configuredAction.actionDestinationId = filteredAD[0].name;
        }
      });
      this.initConfiguredActionTableList();
      this.isDataLoading = false;
      this.loadingIndicator = false;
    }, error => {
      this.loadingIndicator = false;
      this.isDataLoading = false;
    });
  }

  private handleActionSort(sort) {
    this.configuredActionTableList = this.handleSort(sort, this.configuredActionTableFilteredData);
  }

  private handleActionPageChange(pageData) {
    this.configuredActionTableList = this.handlePageChange(pageData, this.configuredActionTableFilteredData);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
