export class Constants {

  public static CONFIGURATION = 'configuration';
  public static SUCCESSFULLY_DELETED = 'successfully deleted';
  public static SUCCESSFULLY_UPDATED = 'successfully updated';
  public static SUCCESSFULLY_CREATED = 'successfully created';
  public static CUSTOM = 'CUSTOM';
  public static AWS = 'AWS';
  public static RESULT = 'result';
  public static SETTINGS = 'settings';
  public static ACTIONS = 'actions';
  public static NAME = 'name';
  public static RULES = 'rules';
  public static FILTERED_CONFIGURED_ACTIONS = 'filteredConfiguredActions';
  public static CONFIGURED_RULE = 'configuredRule';
  public static RULE_COMPONENT_INDEX = 'ruleComponentIndex';
  public static ID = 'id';
  public static PROGRAM_TYPE = 'programType';
  public static NODE_TYPE = 'nodeType';
  public static CHILDREN = 'children';
  public static EVENT_SOURCE = 'eventSource';
  public static INDEX = 'index';
  public static CONVERSANT = 'Conversant';
  public static WEB = 'Web';

  public static DUPLICATE_ITEM = 'DUPLICATE_ITEM';
  public static NOT_FOUND = 'NOT_FOUND';
  public static DUPLICATE_PROGRAM = 'DUPLICATE_PROGRAM';

  public static DB_KEY_SEPARATOR = '_x_';
  public static DB_KEY_SUB_SEPARATOR = '_xx_';

  public static readonly SUFFIX_DELAY = '_DELAY';

  public static SPACE = ' ';
  public static TABLE_SORT_ASCENDING = 'ascending';
  public static DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm aaaa';
  public static DATE_FORMAT = 'MM/dd/yyyy';
  public static NA = 'N/A';
  public static maxValue = 100;
  public static minValue = 1;

  public static EXCLUSIONS_HOME_ROUTER_LINK = '/exclusions';
  public static CREATE_EXCLUSION_ROUTER_LINK = '/exclusions/Custom/create/id';
  public static EDIT_EXCLUSION_ROUTER_LINK = '/exclusions/edit/';
  public static RMNCONTENT_HOME_ROUTER_LINK = 'client/support/rmncontents';

  public static SYSTEM_PARENT = 'System';
  public static SYSTEM = 'SYSTEM';

  public static API = 'API';
  public static UI = 'UI';

  public static REFERENCE_TABLE_SEARCH = 'REFERENCE_TABLE_SEARCH';
  public static LIST_SEARCH = 'LIST_SEARCH';
  public static PROFILE_SEARCH = 'PROFILE_SEARCH';
  public static PROFILE_UPDATE = 'PROFILE_UPDATE';
  public static PROFILE_GET = 'PROFILE_GET';

  public static SALESFORCE_ACTION_DESTINATION = 'SFMC';

  public static TRIGGER_ACTION_ID = 'triggerActionId';
  public static TRIGGER_ACTION_NAME = 'triggerEventName';
  public static NOW = 'now';
  public static BY_CONST = 'by ';
  public static EST_CONST = ' EST';

  public static groupInfos = 'groupInfos';
  public static modelsForm = 'modelsForm';
  public static modelParametersForm = 'modelParametersForm';
  public static packageInfo = 'packageInfo';
  public static packageName = 'packageName';
  public static iqdecisions = 'iqdecisions';

  public static decimalMaskConfig = {
    disallowNegative: true,
    thousandsSeparator: ',',
    includeThousandsSeparator: false,
    decimalPrecision: 0,
    decimalPoint: '.'
  };

}

export enum Action {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  GET = 'GET'
}

export enum UserType {
  API = 'API',
  UI = 'UI'
}

export enum FormOnSaveAction {
  CREATE = 'create',
  EDIT = 'edit'
}

export enum OperationType {
  ADD = 'add',
  EDIT = 'edit'
}

export enum EntityTypes {
  PACKAGED = 'Packaged',
  CUSTOM = 'Custom'
}

export enum NotificationStyle {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  REQUIRED = 'required',
  INFO = 'information'
}

export enum ActionTypes {
  STANDARD_ACTIONS = 'Standard Actions',
  CUSTOM_ACTIONS = 'Custom Actions'
}

export enum RuleTypes {
  PACKAGED = 'Packaged',
  CUSTOM = 'Custom',
  DROOLS = 'Drools'
}
