import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ExclusionConfigurationBaseOperator } from 'src/app/shared/exclusion/exclusion-configuration/exclusion-configuration-base-operator';
import { DurationUnitList, DurationUnits } from 'src/app/shared/models/exclusion/duration-units';
import { FormOnSaveAction, Constants } from 'src/app/shared/constants';
import { Duration } from 'luxon';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ExclusionOperator } from 'src/app/shared/models/exclusion/exclusion-operator';

@Component({
  selector: 'app-exclusion-configuration-frequency-occurrences',
  templateUrl: './exclusion-configuration-frequency-occurrences.component.html',
  styleUrls: ['./exclusion-configuration-frequency-occurrences.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExclusionConfigurationFrequencyOccurrencesComponent extends ExclusionConfigurationBaseOperator implements OnInit {

  public readonly QUANTITY = 'quantity';
  public formControlNames = {
    'OCCURRENCES': 'occurrences',
    'OCCURRENCES_UNIT': 'occurrencesUnit',
    'DURATION': 'duration',
    'DURATION_UNIT': 'durationUnit',
    'OPERATOR_COMPARE': 'compareOperator'
  };

  public decimalMaskConfig = Constants.decimalMaskConfig;
  public durationUnitList = DurationUnitList;
  public compareOpMap = new Map<ExclusionOperator, string>();

  private inputOccurences: number;
  private inputDuration: number;
  private inputDurationUnit: DurationUnits;
  private inputCompareOperator: ExclusionOperator;

  constructor() {
    super();
    this.initCompareOpMap();
  }

  ngOnInit(): void {
    this.initInput();
    this.initFormGroup();
  }

  public validate(): boolean {
    const occurrences = this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).value;
    const duration = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).value;
    const isValidOccurences = this.validateOccurences(occurrences);
    const isValidduration = this.validateDuration(duration);
    return isValidOccurences && isValidduration;
  }

  public buildAttributeConditionValues(): unknown[] {
    const compareOp = this.exclusionConfigurationFormGroup.get(this.formControlNames.OPERATOR_COMPARE).value;
    const occurrences = this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).value;
    const duration = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).value;
    const durationUnit: string = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).value;
    const durationJSON = Object.create({});
    durationJSON[durationUnit] = duration;
    const durationInISO = Duration.fromObject(durationJSON);
    const attributeConditionValues = [occurrences, durationInISO.toISO(), compareOp];
    return attributeConditionValues;
  }

  private initCompareOpMap(): void {
    this.compareOpMap.set(ExclusionOperator.GTEQ, 'More Than');
    this.compareOpMap.set(ExclusionOperator.LTEQ, 'Less Than');
  }

  private initFormGroup(): void {
    this.exclusionConfigurationFormGroup = new UntypedFormGroup({
      compareOperator: new UntypedFormControl(this.inputCompareOperator.toString()),
      occurrences: new UntypedFormControl(this.inputOccurences),
      occurrencesUnit: new UntypedFormControl(this.QUANTITY),
      duration: new UntypedFormControl(this.inputDuration),
      durationUnit: new UntypedFormControl(this.inputDurationUnit.toString())
    });
    if (this.readOnly) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OPERATOR_COMPARE).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES_UNIT).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).disable();
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).disable();
    }
  }

  private initInput(): void {
    this.inputCompareOperator = this.compareOpMap.keys().next().value;
    this.inputOccurences = undefined;
    this.inputDuration = undefined;
    this.inputDurationUnit = DurationUnits.MINUTES;
    if (this.action === FormOnSaveAction.EDIT) {
      this.inputOccurences = this.attributeConditionValues[0] as number;
      this.initDurationDetails(this.attributeConditionValues[1] as string);
      this.inputCompareOperator = ExclusionOperator[this.attributeConditionValues[2] as string];
    }
  }

  private initDurationDetails(duration: string): void {
    const jsonDuration = Duration.fromISO(duration).toObject();
    const jsonDurationUnit = Object.keys(jsonDuration).shift();
    this.inputDurationUnit = DurationUnits[jsonDurationUnit.toLocaleUpperCase()];
    this.inputDuration = jsonDuration[jsonDurationUnit];
  }

  private validateOccurences(occurences: number): boolean {
    const isValid = this.isValidOccurences(occurences);
    if (!isValid) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).setErrors({ required: true });
      this.exclusionConfigurationFormGroup.get(this.formControlNames.OCCURRENCES).markAsDirty();
    }
    return isValid;
  }

  private validateDuration(duration: number): boolean {
    const isValid = this.isValidduration(duration);
    if (!isValid) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).setErrors({ required: true });
      this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION).markAsDirty();
    }
    return isValid;
  }

  private isValidOccurences(occurences: number): boolean {
    return occurences && !isNaN(occurences);
  }

  private isValidduration(duration: number): boolean {
    return duration && !isNaN(duration);
  }

}