export class Parent {

  id: string;
  name: string;
  verticalType: string;
  reportingEnabled: boolean;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;

  constructor(id?: string, name?: string, verticalType?: string, reportingEnabled?: boolean, parent?: Object) {
   if (parent) {
     Object.assign(this, JSON.parse(JSON.stringify(parent)));
   } else {
     this.id = id ? id : '';
     this.name = name ? name : '';
     this.verticalType = verticalType ? verticalType : '';
     this.reportingEnabled = reportingEnabled ? reportingEnabled : false;
   }
  }

}
