import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { ExclusionBuilderComponent } from './exclusion-builder/exclusion-builder.component';
import { ExclusionsHomeComponent } from './exclusions-home/exclusions-home.component';
import { ExclusionBuilderCanDeactivateGuard } from './exclusion-builder/exclusion-builder-can-deactivate.guard';

const routes: Routes = [
  {
    path: 'exclusions',
    canActivate: [AuthGuard],
    component: ExclusionsHomeComponent
  },
  {
    path: 'exclusions/:type/:action/:id',
    canActivate: [AuthGuard],
    canDeactivate: [ExclusionBuilderCanDeactivateGuard],
    component: ExclusionBuilderComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExclusionsRoutingModule { }