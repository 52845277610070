import { Component, OnDestroy, Injector } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { takeUntil } from 'rxjs/operators';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { EntityStatesSharedDataService } from '../entity-states-shared-data.service';
import { EntityStatesService } from 'src/app/shared/services/entitystates.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { Messages } from 'src/app/shared/message';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { OperationType } from '../../../shared/constants';
import { EntityType } from 'src/app/shared/models/entity-type';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { EntityCopy } from 'src/app/shared/models/entity-copy.model';
import { EntityCopyService } from 'src/app/shared/services/entity-copy.service';

@Component({
  selector: 'app-entity-states-header',
  templateUrl: './entity-states-header.component.html'
})
export class EntityStatesHeaderComponent extends BaseFormDirective implements OnDestroy {

  subscription: Subscription;
  associationSubscription: Subscription;
  configuredEntityState: ConfiguredEntityState = new ConfiguredEntityState();
  apiCallCount = 0;
  isEdit = false;
  isDeleteOperation = false;
  parentId: string;
  operation: string;
  configuredEntityStateType: string;
  hasAssociation: boolean;
  public isApiServiceCalled: boolean;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private entityStateService: EntityStatesService,
    private router: Router,
    private popupService: PopupMessageService,
    private parentContextService: ParentContextService,
    private route: ActivatedRoute,
    public injector: Injector,
    private entityCopyService: EntityCopyService,
    private entityStatesSharedService?: EntityStatesSharedDataService,
    public authorizationService?: AuthorizationService) {
    super();
    this.parentId = this.parentContextService.getParentContext();
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.operation = route.snapshot.paramMap.get('action');
      this.configuredEntityStateType = route.snapshot.paramMap.get('type');
    });
    this.subscription = this.entityStatesSharedService.getFormSubscriptions().subscribe(configuredEntityForm => {
      if (configuredEntityForm) {
        this.configuredEntityForm = configuredEntityForm;
        this.buildConfiguredEntityState();
      }
    });
    this.associationSubscription = this.entityStatesSharedService.getAssociationSubscriptions().subscribe(association => {
      if (association) {
        this.hasAssociation = association;
      }
    });
  }

  saveConfiguredEntityStates(): void {
    if (this.configuredEntityForm.valid) {
      this.configuredEntityState.description = this.configuredEntityState.description === null ?
        '' : this.configuredEntityState.description;
      if (this.operation === OperationType.EDIT) {
        this.entityStateService.updateConfiguredCustomEntityState(this.parentId, this.configuredEntityState)
          .subscribe( res => {
            this.entityStatesSharedService.initializeEntityStateForm(new ConfiguredEntityState(res['result']));
            this.isDataSaved = false;
            this.popupService.showSuccessMessage(Messages.saveEntityStateSuccessMessage);
          }, () => {
            this.popupService.showErrorMessage(Messages.saveEntityStateErrorMessage);
          });
      } else {
        this.entityStateService.addConfiguredCustomEntityState(this.parentId, this.configuredEntityState).subscribe( res => {
          this.configuredEntityState = new ConfiguredEntityState(res['result']);
          this.entityStatesSharedService.initializeEntityStateForm(this.configuredEntityState);
          this.operation = OperationType.EDIT;
          this.isDataSaved = true;
          // eslint-disable-next-line max-len
          void this.router.navigateByUrl('entitystates', {skipLocationChange: true}).then(() => {
            void this.router.navigateByUrl('/entitystates/custom/configure/' +
                this.configuredEntityState.id + '/' + this.configuredEntityState.type + '/edit');
          });
          this.popupService.showSuccessMessage(Messages.createEntityStateSuccessMessage, true);
        }, (error: any) => {
          this.isDataSaved = false;
          let errorMessage = Messages.createEntityStateErrorMessage;
          if (error.status === 400) {
            if (error.error.statusMessage === 'CONFIGURED_ENTITY_STATE_NOT_FOUND_RESULT') {
              errorMessage = error.error.result;
            }
            if (error.error.statusMessage === 'DUPLICATE_CONFIGURED_ENTITY_STATE') {
              errorMessage = `Another entity state with the name '${this.configuredEntityState.name}' already exists.`;
            }
          }
          this.popupService.showErrorMessage(errorMessage);
        });
      }
    } else {
      this.popupService.showErrorMessage(Messages.requiredFields);
    }
  }

  deleteConfiguredEntityStates() : void {
    this.warningModal.forEntity('ENTITY_STATE').launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteEntityState,
      msg: `Are you sure you want to delete entity state ${this.configuredEntityState.name} ?`,
      msg2: ['This action cannot be undone.']
    });
  }

  public copyConfiguredEntityStates(): void {
    this.isApiServiceCalled = true;
    this.entityCopyService.copyEntity(this.parentId, EntityCopy.build(this.configuredEntityState.id, Feature.CFG_ENT_STATE))
      .pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.isApiServiceCalled = false;
        this.popupService.showSuccessMessage(`Copy Successful. New Entity State '${<string>res['result'].name}' successfully created.`);
      }, error => {
        this.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.result);
      });
  }

  public isStandardEntityState() {
    return this.configuredEntityForm.controls.type.value === EntityType.PACKAGED;
  }

  public handleDeleteESDecision(decision: boolean): void {
    if (!decision) {
      return;
    }
    if (this.warningModal.getEntityType() === 'ENTITY_STATE' && decision) {
      this.entityStateService.deleteConfiguredCustomEntityState(this.parentId, this.configuredEntityState.id)
        .pipe(takeUntil(this.destroyed$)).subscribe(() => {
          this.isDeleteOperation = true;
          this.popupService.showDeleteMessage(`Entity state ${this.configuredEntityState.name} deleted successfully` );
          void this.router.navigate(['entitystates']);
        }, (error: any) => {
          if (error.status === 403) {
            this.popupService.setByResponse(error);
          } else {
            this.popupService.showErrorMessage(`Error deleting entity state ${this.configuredEntityState.name}`);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.subscription.unsubscribe();
    this.associationSubscription.unsubscribe();
  }

  public toggleHeaderButtons(): boolean {
    if (this.configuredEntityForm) {
      return (this.configuredEntityForm.controls.type.value === EntityType.CUSTOM && this.hasAssociation)
             || (this.configuredEntityForm.controls.type.value === EntityType.PACKAGED
             && (this.operation === 'add' || this.operation === 'edit'));
    }
    return false;
  }

  private buildConfiguredEntityState(): void {
    this.configuredEntityState = JSON.parse(JSON.stringify(this.configuredEntityForm.getRawValue()));
  }

}