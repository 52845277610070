import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, ChangeDetectorRef } 
from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } 
from "@angular/forms";
import { ModalComponent, DatePickerComponent, InfiniteScrollDirective } from "@epsilon/core-ui";
import { AuditHistoryService } from "src/app/shared/services/audit-history.service";
import { ParentContextService } from "src/app/shared/services/parent-context.service";
import { ReplaySubject, takeUntil, BehaviorSubject, Subject } from "rxjs";
import { UtilService } from 'src/app/shared/services/util-service';
import { AuditLog } from 'src/app/shared/models/change-log/audit-log';
import { AuditSearchConfiguration } from 'src/app/shared/models/change-log/audit-search-configuration';
import { AuditType } from 'src/app/shared/models/change-log/audit-type';
import { AuditActivity } from 'src/app/shared/models/change-log/audit-activity';
import { AuditHistory } from 'src/app/shared/models/change-log/audit-history';
import { DeltaChange } from 'src/app/shared/models/change-log/delta-change';
import { Delta } from 'src/app/shared/models/change-log/delta';

@Component({
	selector: "app-audit-history",
	templateUrl: "./audit-history.component.html",
	styleUrls: ["./audit-history.component.scss"]
})
export class AuditHistoryComponent implements OnInit, OnDestroy {
	
	@Input()
	public programId: string;
	@Input()
	public programName: string;
	@Output()
	public isModalDisplayInfo = new EventEmitter<boolean>();

	@ViewChild('startDate')
	public startDate: DatePickerComponent;

	@ViewChild('endDate')
	public endDate: DatePickerComponent;
		
	@ViewChild("basicModal", { static: true })
	private basicModal: ModalComponent;

	@ViewChild(InfiniteScrollDirective)
	public infiniteScrollDirective: InfiniteScrollDirective;
	
	public changeLogForm: UntypedFormGroup;
	public isSaveClicked: boolean;
	public isDataLoading = false;
	public properties: {};
	public auditHistoryTableData: AuditLog[] = [];
	public auditHistoryData: AuditLog[] = [];
	public API_RECORD_LIMIT = 500;
	public endDateMaxDate: Date;
	public startDatePickerMaxDate$: BehaviorSubject<Date>;
	public endDatePickerMinDate$: BehaviorSubject<Date>;
	public recordLimit = 10;
    public recordOffset = 0;

	private auditHistoryResult: AuditSearchConfiguration;
	private parentId: string;
	private parentName: string;
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	private ngUnsubscribe$ = new Subject<void>();
	private isModalShown: boolean;
	
	public constructor(
		private fb: UntypedFormBuilder,
		private auditHistoryService: AuditHistoryService,
		private parentContextService: ParentContextService,
		private changeDetectorRef: ChangeDetectorRef) {
		this.parentId = this.parentContextService.getParentContext();
		this.parentName = this.parentContextService.getParentName(this.parentId);
	}

	ngOnInit(): void {
		this.initialize();
	}

	private initialize(): void {
		this.buildChangeLogForm();
		this.getAuditHistory();
		this.initDataTableConfig();
		this.initDatePicker();
		this.launchBasicModal();
	}

	private buildChangeLogForm(): void {
		this.changeLogForm = this.fb.group({
			startDate: new UntypedFormControl(),
			endDate: new UntypedFormControl(),
			search: new UntypedFormControl('')
		});
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}

	public closeBasicModal(): void {
		void this.basicModal.hide();
		this.isModalShown = false;
		this.isModalDisplayInfo.emit(this.isModalShown);
		this.isSaveClicked = false;
	}

	public launchBasicModal(): void {
		void this.basicModal.show();
		this.isModalShown = true;
	}

	private initDataTableConfig(): void {
		this.isDataLoading = true;
		this.properties = {
			rowId: "id",
			columns: [
				{
					headerText: "Date Completed",
					key: "dateCompleted",
					isSortable: true,
					isColumnDisplayed: true
				},
				{
					headerText: "Activity",
					key: "activities",
					isSortable: true,
					isColumnDisplayed: true
				}
			],
			sort: {
				defaultSortedColumn: "name",
				defaultSortOrder: "ascending"
			},
			hasColumnSelector: true,
			hasDisplayDensity: true
		};
	}

	public initDatePicker(): void {
		this.endDateMaxDate = new Date();
		this.startDatePickerMaxDate$ = new BehaviorSubject<Date>(this.endDateMaxDate);
		this.endDatePickerMinDate$ = new BehaviorSubject<Date>(null);

		this.changeLogForm.get('startDate')
				.valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
				.subscribe((val) => {
						if (val) {
								this.endDatePickerMinDate$.next(val);
						}
				});

		this.changeLogForm.get('endDate')
				.valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
				.subscribe((val) => {
						if (val) {
								this.startDatePickerMaxDate$.next(val);
						}
				});
	}
	
	public onAuditSearchByDate(): void {
		this.auditHistoryTableData = [];
		this.auditHistoryResult = undefined;
		this.isSaveClicked = true;
		this.isDataLoading = true;
		this.initDataTableConfig();
		this.getAuditHistory();
	}
	
	private getPayload(): AuditSearchConfiguration {
		if (this.changeLogForm.controls.startDate.value && this.changeLogForm.controls.endDate.value) {
			return AuditSearchConfiguration.getAuditSearchConfigByDate(this.programId, this.API_RECORD_LIMIT,
				UtilService.getDateInEpochMillis(this.changeLogForm.get('startDate').value, 0, 0, 0), 
				UtilService.getDateInEpochMillis(this.changeLogForm.get('endDate').value, 23, 59, 59));
		}
		return AuditSearchConfiguration.getAuditSearchConfig(this.programId, this.API_RECORD_LIMIT);
	}
	
	private getAuditHistory(): void {
		this.isDataLoading = true;
		const payload = this.getPayload();
		this.auditHistoryService
			.getAuditHistory(this.parentId, payload)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(
				(res: any) => {
					this.recordOffset = -1;
					this.auditHistoryResult = res.result;
					console.log(this.auditHistoryResult);
					this.onScrollLimitReached();
					this.isDataLoading = false;
				}, error => {
					this.getApiErrorMessage(error);
				}
			);
	}

	private getApiErrorMessage(error: any) {
		this.isDataLoading = false;
		let errorMsg;
		if (error.error instanceof ErrorEvent) {
			errorMsg = `Error: ${error.error.message}`;
		} else {
			errorMsg = `Error: ${error.message}`;
		}
		console.log(errorMsg);
	}

	private constructAuditForDisplay(auditHistories: AuditHistory[]): AuditLog[] {
		for (var auditHistory of auditHistories) {
			const auditLog = new AuditLog();
			auditLog.getDateCompletedField(auditHistory);
			this.getActivityField(auditHistory.auditType, auditHistory.deltaChange, auditLog.getActivities);
			if (auditLog.getActivities.length > 0) {
				this.auditHistoryTableData.push(auditLog);
			}
		}
		console.log(this.auditHistoryTableData);
		this.auditHistoryData = JSON.parse(JSON.stringify(this.auditHistoryTableData));
		return this.auditHistoryTableData;
	}

	private getActivityField(auditType: AuditType, auditDeltaChange: DeltaChange, activities: AuditActivity[]): void {
		switch (auditType) {
			case AuditType.INSERT:
				const title = "Created Program: " + auditDeltaChange['newValue']['name'];
				const activity = new AuditActivity().build("insert", "/create", title, "checkmarkCircle");
				activity.getDeltas.push(this.buildNaturalLanguageForInsert(auditDeltaChange['newValue']));
				activities.push(activity);
				break;

			case AuditType.MODIFY:
				if (auditDeltaChange && auditDeltaChange.deltas) {
					for (var delta of auditDeltaChange.deltas) {
						this.buildActivitiesForModifyOperation(delta, activities);
					}
				}
				break;

			case AuditType.REMOVE:
				const activityDel = new AuditActivity();
				activityDel.deltaOperation = "remove";
				activityDel.title = "Deleted Program: " + this.programName;
				activities.push(activityDel);
				break;
		}
	}

	private buildActivitiesForModifyOperation(delta: Delta, activities: AuditActivity[]): void {
		let activity: AuditActivity = new AuditActivity();
		switch (delta.operation.toUpperCase()) {
			case AuditType.ADD:
				activity.buildAddActivitiesForModifyOperation(delta, activities);
				break;

			case AuditType.REPLACE:
				activity.buildReplaceActivitiesForModifyOperation(delta, activities);
				break;

			case AuditType.REMOVE:
				activity.buildRemoveActivitiesForModifyOperation(delta, activities);
				break;
		}
	}

	private buildNaturalLanguageForInsert(deltaNewValue: unknown): string {
		return 'From ' + deltaNewValue['packagedProgramName'] + ' Template for ' + this.parentName;
	}

	public isDeltaType(delta: unknown, type: string): boolean {
		return (delta != undefined && typeof delta == type) ? true : false;
	}

	public onScrollLimitReached(): void {
		if (this.recordOffset == -1) {
			this.recordOffset = 0;
		} else {
			this.recordOffset += this.recordLimit;
		}
		this.loadLazy();
	}
	
	private loadLazy() {
		const nextResults = this.auditHistoryResult.auditHistory.slice(
			this.recordOffset,
			this.recordOffset + this.recordLimit
		);
		this.constructAuditForDisplay(nextResults);			
	}

	public onSearchChange(searchText: string): void {
		this.auditHistoryTableData = [];
		this.auditHistoryTableData = JSON.parse(JSON.stringify(this.auditHistoryData));
		searchText = searchText.trim().toLowerCase();
		if (!searchText) {
			return;
		}
		let i = this.auditHistoryTableData.length;
		while (i--) { 
			if (!this.isPresent(this.auditHistoryTableData[i], searchText)) { 
				this.auditHistoryTableData.splice(i, 1);
			} 
		}
		this.changeDetectorRef.detectChanges();
	}
	
	private isPresent(auditData: AuditLog, search: string) : boolean {
		const activitySize = auditData.activities.length;
		let isPresent: boolean = false;
		let isTextinActivity: boolean = false; 
		if (auditData.userName.toLowerCase() === search) {
			isPresent = true;
		}

		if (auditData.date.toString().toLowerCase() === search) {
			isPresent = true;
		}

		for (let i = activitySize - 1; i >= 0; i--) {
			isTextinActivity = this.isSearchTextPresentinActivity(auditData.activities[i], search);
			if (!isTextinActivity) {
				auditData.activities.splice(i, 1);
			} else {
				isPresent = isTextinActivity;
			}
		}
		return isPresent;
	}

	private isSearchTextPresentinActivity(activity: AuditActivity, search: string): boolean {
		let isPresent = false;
		let i = activity.deltas.length;
		
		if (activity.title.toLowerCase().includes(search)) {
			return true;
		}

		while (i--) {
			if (this.isDeltaType(activity.deltas[i],'object')) {
				for (let [key, value] of Object.entries(activity.deltas[i])) {
					if (value.toString().toLowerCase() === search) {
						isPresent = true;
					}
				}
			} else {
				if (activity.deltas[i].toLowerCase().includes(search)) {
					isPresent = true;
				}
			}
			if (!isPresent) {
				activity.deltas.splice(i, 1);
			}
		}
		return isPresent;
	}

}