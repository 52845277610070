<nav coreUISecondaryNavigation
     id="leftNav2"
     aria-label="Primary navigation"
     [collapsed]="leftNavSecondaryCollapsed"
     [collapsedNavHidden]="true"
     data-qa="left-nav-secondary-menu">
  <button coreUICollapseToggle aria-label="Toggle left navigation"
          aria-controls="leftNav2"
          [coreUITooltip]="tooltipMessageSecondary"
          position="right"
          type="button"
          (click)="onToggleLeftNavSecondaryClick()">
    <i coreUIIcon="doubleChevronLeft"></i>
  </button>
  <ng-container *ngFor="let nav of leftNavigations">
    <coreui-secondary-navigation-item [label]="nav.text"
                                      [url]="nav.url"
                                      (activated)="onActivated($event)"
                                      [active]="isActive(nav.url)">
      <div *ngIf="nav.subNavs">
        <ng-container *ngFor="let subNav of nav.subNavs">
          <coreui-secondary-navigation-item [label]="subNav.text"
                                            [url]="subNav.url"
                                            (activated)="onActivated($event)"
                                            [active]="isActive(subNav.url)">
          </coreui-secondary-navigation-item>
        </ng-container>
      </div>

    </coreui-secondary-navigation-item>
  </ng-container>
</nav>
