<div class="row mb-2">
  <div class="col-6">
    <div>
      <app-ae-section-header [headerText]="messages.historySubHeader"></app-ae-section-header>
      <div class="row mt-3">
        <div class="col">
          <span class="text-style-muted-1 pt-2">{{messages.publication}}</span>
          <p class="indent-text">{{configuredRecommendation.createdDate | coreUILocaleDate}} by
            {{configuredRecommendation.createdBy}}</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <span class="text-style-muted-1 pt-2">{{messages.lastModification}}</span>
          <p class="indent-text">{{configuredRecommendation.modifiedDate | coreUILocaleDate}} by
            {{configuredRecommendation.modifiedBy}}</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <span class="text-style-muted-1 pt-2">{{messages.currentVersion}}</span>
          <p class="indent-text">{{configuredRecommendation.version}}</p>
        </div>
      </div>
    </div>
  </div>
</div>