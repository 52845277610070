import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';
import { RolesService } from './roles-service-interface';
import { PackagedRole } from '../models/permission/role/packaged-role';
import { AuthorizationService } from './authorization-service';
import { Type } from '../models/roleType';
import { Feature } from '../models/permission/feature/role-permission-constants';

@Injectable({
    providedIn: 'root'
  })
  export class PackagedRolesService implements RolesService {
  
    public static readonly partitionKey: string = 'System';
    public static readonly packagedRolesUrl: string = 'admin/packaged-role';
    private static readonly featuresUrl = 'admin/role/features';
    private static readonly navPath = '/admin/roles/';
    private static readonly createRoleRouterLink = '/admin/roles/' + Type.GLOBAL.toLowerCase();
    private static readonly roleType = Type.GLOBAL;
    private http: HttpClient;
    private host: string = environment.host;
    private base: string = environment.base;
    public rolesLandingRouter: string;
  
    public constructor(http: HttpClient, public authorizationService: AuthorizationService) {
      this.http = http;
      this.rolesLandingRouter = "/admin/roles";
    }

    public getAllRoles(parentId: string): Observable<ServiceResponse>{
      const url = this.host + this.base + PackagedRolesService.packagedRolesUrl;
      const headers: HttpHeaders = new HttpHeaders({'parent-id': PackagedRolesService.partitionKey});
      const options = { headers: headers };
      return this.http.get<ServiceResponse>(url, options);
    }

    public createRole(parentId: string, packagedRole: PackagedRole) {
      const url = this.host + this.base + PackagedRolesService.packagedRolesUrl;
      packagedRole.partitionKey = parentId
      return this.http.post<ServiceResponse>(url, packagedRole);
    }

    public updateRole(parentId: string, packagedRole: PackagedRole, roleId: string){
      const url = this.host + this.base + PackagedRolesService.packagedRolesUrl +'/' + PackagedRolesService.partitionKey + '/' + roleId;
      packagedRole.partitionKey = "System";
      const headers: HttpHeaders = new HttpHeaders({'parent-id': parentId});
      const options = { headers: headers };
      return this.http.put<ServiceResponse>(url, packagedRole);
    }

    public getFeatureName(): string {
      return Feature.PKG_ROLE;
    }

    public getNavPath(): string {
      return PackagedRolesService.navPath;
    }

    public getRoleType(): string{
      return PackagedRolesService.roleType;
    }

    public getCreateRouterLink(): string{
      return PackagedRolesService.createRoleRouterLink;
    }

    public deleteRoleById(parentId: string, id: string){
      const url = this.host + this.base + PackagedRolesService.packagedRolesUrl + '/' + parentId + '/' + id;
      return this.http.delete<ServiceResponse>(url);
    }

    public getRoleById(roleType: string, roleId: string, parentId: string): Observable<ServiceResponse> {
      if (roleType == Type.GLOBAL.toLowerCase()){
        const url = this.host + this.base + PackagedRolesService.packagedRolesUrl + '/' + PackagedRolesService.partitionKey + '/' + roleId;
        return this.http.get<ServiceResponse>(url);
      }
      return null;
    }

    public getAllFeatures(parentId: string): Observable<ServiceResponse> {
      const url = this.host + this.base + PackagedRolesService.featuresUrl + '/all';
      const headers: HttpHeaders = new HttpHeaders()
        .append('parent-id', parentId);
      const options = {
        headers
      };
      return this.http.get<ServiceResponse>(url, options);
    }
  }
  