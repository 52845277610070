import { ConfiguredEntityState } from '../../entity-states/configured-entity-state';
import { EntityStateSchema } from '../../entity-states/entity-state-schema';
import { EntityType } from '../../entity-type';
import { ActionElement } from '../actions/action-element';
import { Argument } from './operation/argument';
import { ArgumentName } from './operation/argument-name';
import { EntityStateAttribute } from './operation/entity-state-attribute';
import { OperandType } from './operation/operand-type';
import { RuleThenClauseOperation } from './operation/rule-then-clause-operation';

export class RuleThenClause {

  public actions: ActionElement[];
  public operations: RuleThenClauseOperation[];

  public getEntityStateSchemasUsed(entityStateList: ConfiguredEntityState[]): EntityStateSchema[] {
    const entityStateSchemasUsedInThenClause: EntityStateSchema[] = [];
    entityStateSchemasUsedInThenClause.push(...this.getEntityStateSchemasUsedInActions(entityStateList));
    entityStateSchemasUsedInThenClause.push(...this.getEntityStateSchemasUsedInOperations(entityStateList));
    return entityStateSchemasUsedInThenClause;
  }

  private getEntityStateSchemasUsedInOperations(entityStates: ConfiguredEntityState[]): EntityStateSchema[] {
    const entityStateIdsUsed: Set<string> = new Set();
    const entityStateUpdateOperations: RuleThenClauseOperation[] = this.operations.filter(
      thenClauseOperation => thenClauseOperation.operandType === OperandType.ENTITY_STATE
    );
    entityStateUpdateOperations.forEach(entityStateUpdateOperation => {
      const entityStateAttributeArgument: Argument = entityStateUpdateOperation.arguments.find(argument => argument.name === ArgumentName.ATTRIBUTE);
      const entityStateAttribute: EntityStateAttribute = entityStateAttributeArgument.value as EntityStateAttribute;
      entityStateIdsUsed.add(entityStateAttribute.entityStateId);
    });
    const entityStateIdsUsedArr = Array.from(entityStateIdsUsed);
    const entityStatesUsed: ConfiguredEntityState[] = entityStateIdsUsedArr.map(entityStateId =>
      entityStates.find(entityState => entityState.id === entityStateId));
    const entityStateSchemasUsed: EntityStateSchema[] = [];
    entityStatesUsed.forEach(entityState => {
      const entityStateSchema: EntityStateSchema = new EntityStateSchema();
      entityStateSchema.id = entityState.id;
      entityStateSchema.name = entityState.name;
      entityStateSchema.key = entityState.entityStateKey;
      entityStateSchema.isSystem = entityState.type === EntityType.PACKAGED;
      entityStateSchemasUsed.push(entityStateSchema);
    });
    return entityStateSchemasUsed;
  }

  private getEntityStateSchemasUsedInActions(entityStateList: ConfiguredEntityState[]): EntityStateSchema[] {
    const entityStateSchemasUsedInActions: EntityStateSchema[] = [];
    this.actions.forEach(action => entityStateSchemasUsedInActions.push(...this.getEntityStateSchemasUsedInAction(action, entityStateList)));
    const uniqueEntityStateSchemasUsedInActions = this.getUniqueEntityStates(entityStateSchemasUsedInActions);
    return uniqueEntityStateSchemasUsedInActions;
  }

  private getEntityStateSchemasUsedInAction(action: ActionElement, entityStateList: ConfiguredEntityState[]): EntityStateSchema[] {
    const entityStateSchemasUsedInAction: EntityStateSchema[] = [];
    entityStateList.forEach((entityState: ConfiguredEntityState) => {
      const serviceInput: string = action.actionSettingsInput['serviceInput'];
      if (serviceInput.includes(entityState.id)) {
        const entityStateSchema: EntityStateSchema = new EntityStateSchema();
        entityStateSchema.id = entityState.id;
        entityStateSchema.name = entityState.name;
        entityStateSchema.key = entityState.entityStateKey;
        entityStateSchema.isSystem = entityState.type === EntityType.PACKAGED;
        entityStateSchemasUsedInAction.push(entityStateSchema);
      }
    });
    return entityStateSchemasUsedInAction;
  }

  private getUniqueEntityStates(entityStateSchemaList: EntityStateSchema[]): EntityStateSchema[] {
    const entityStateIdAndEntityStateSchemaMap: Map<string, EntityStateSchema> = new Map();
    entityStateSchemaList.forEach(entityState => entityStateIdAndEntityStateSchemaMap.set(entityState.id, entityState));
    const uniqueEntityStateSchemaList: EntityStateSchema[] = Array.from(entityStateIdAndEntityStateSchemaMap.values());
    return uniqueEntityStateSchemaList;
  }

}