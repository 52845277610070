import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { RoleConfigurationComponent } from "../../client/user-roles/role-configuration/role-configuration.component";
import { BaseFormDirective } from "src/app/shared/models/base-form-configuration/base-form.directive";
import { Type } from "src/app/shared/models/roleType";
import { FormOnSaveAction } from "src/app/shared/constants";
import { ROLES_SERVICE_TOKEN, ROLE_MODEL } from "src/app/shared/tokens";
import { PackagedRolesService } from "src/app/shared/services/packaged-roles.service";
import { PackagedRole } from "src/app/shared/models/permission/role/packaged-role";
import { Role } from "src/app/shared/models/permission/role/role";
import { UntypedFormGroup } from "@angular/forms";

@Component({
    selector: 'app-roles-configuration',
    templateUrl: './packaged-roles-configuration.component.html',
    providers: [{provide: ROLES_SERVICE_TOKEN, useClass: PackagedRolesService},
                {provide: ROLE_MODEL, useClass: PackagedRole}]
  })

  export class PackagedRolesConfigurationComponent extends BaseFormDirective{

    @ViewChildren(RoleConfigurationComponent) roleConfigurationComponent: QueryList<RoleConfigurationComponent>;
  }