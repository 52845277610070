import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfiguredRule } from '../models/configured-rule.model';

@Injectable({
  providedIn: 'root'
})
export class RuleService {

  host = environment.host;
  base = environment.base;
  configuredRuleUrl = 'rule/configured';

  constructor(private http: HttpClient) { }

  getConfiguredRules(parentId: string) {
    const url = this.host + this.base + this.configuredRuleUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }

  getConfiguredRulebyId(parentId: string, configuredRuleId: string) {
    const url = this.host + this.base + this.configuredRuleUrl + '/' + configuredRuleId;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }

  deleteConfiguredRule(parentId: string, configuredRuleId: string) {
    const url = this.host + this.base + this.configuredRuleUrl + '/' + configuredRuleId;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.delete(url, options);
  }

  addConfiguredRule(parentId: string, configuredRule: ConfiguredRule) {
    const url = this.host + this.base + this.configuredRuleUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post(url, configuredRule, options);
  }

  updateConfiguredRule(parentId: string, configuredRuleId: string, configuredRule: ConfiguredRule) {
    const url = this.host + this.base + this.configuredRuleUrl + '/' + configuredRuleId;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.put(url, configuredRule, options);
  }

}