import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgramHomeStateService {

  searchByName: string;
  sortBy: string;
  sortOrder: string;
  filterItems: Map<string, Map<string, Set<string>>>;
  HOME_FILTER_KEY = 'agilityevents-home-filter';

  constructor() { }

  saveState(sortBy, sortOrder, filterItems) {
    const filterItemsObj = new Map<string, string>();
    filterItems.forEach((v, k) => {
      const filterMap = new Map<string, string[]>();
      v.forEach((val, key) => {
        filterMap.set(key, Array.from(val));
      });
      const jsonMapStr = JSON.stringify(Array.from(filterMap.entries()));
      filterItemsObj.set(k, jsonMapStr);
    });
    const filterItemsStr = JSON.stringify(Array.from(filterItemsObj.entries()));
    const filterState = {
      sortBy,
      sortOrder,
      filterItemsStr
    };
    localStorage.setItem(this.HOME_FILTER_KEY, JSON.stringify(filterState));
  }

  fetchCachedFilterState() {
    const cachedFilterState = localStorage.getItem(this.HOME_FILTER_KEY);
    if (cachedFilterState) {
      const filterState: {
        sortBy;
        sortOrder;
        filterItemsStr;
      } = JSON.parse(cachedFilterState);
      if (filterState.sortBy && ['program_name', 'modified_date'].indexOf(filterState.sortBy) > -1) {
        this.sortBy = filterState.sortBy;
      } else {
        this.sortBy = 'program_name';
      }
      if (filterState.sortOrder && ['asc', 'desc'].indexOf(filterState.sortOrder) > -1) {
        this.sortOrder = filterState.sortOrder;
      } else {
        switch (this.sortBy) {
          case 'program_name': this.sortOrder = 'asc'; break;
          case 'modified_date': this.sortOrder = 'desc'; break;
        }
      }
      if (filterState.filterItemsStr) {
        const filterItemsObj = new Map<string, string>(JSON.parse(filterState.filterItemsStr));
        this.filterItems = new Map<string, Map<string, Set<string>>>();
        filterItemsObj.forEach((v, k) => {
          const subFilterItem = new Map<string, Set<string>>();
          const filterValMap = new Map<string, Set<string>>(JSON.parse(v));
          filterValMap.forEach((val, key) => {
            subFilterItem.set(key, new Set<string>(val));
          });

          this.filterItems.set(k, subFilterItem);
        });
      } else {
        this.filterItems = new Map<string, Map<string, Set<string>>>();
      }
    } else {
      this.sortBy = 'program_name';
      this.sortOrder = 'asc';
      this.filterItems = new Map<string, Map<string, Set<string>>>();
    }
  }

  removeLocalStorageItems() {
    localStorage.removeItem(this.HOME_FILTER_KEY);
  }

  resetSearchItems() {
    this.searchByName = null;
  }

}