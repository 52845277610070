import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Messages } from 'src/app/shared/message';

@Component({
  selector: 'app-iqdecisions-view-history',
  templateUrl: './iqdecisions-view-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IqdecisionsViewHistoryComponent {

  @Input() configuredRecommendation;

  public messages = Messages;

}
