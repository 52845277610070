import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { ParentsConfigurationComponent } from './parents-configuration/parents-configuration.component';
import { SystemUsersHomeComponent } from './system-users-home/system-users-home.component';
import { UnsavedChangesGuard } from '../../shared/guard/unsaved-changes.guard';
import { AdminGuard } from 'src/app/modules/admin/admin-guard'
import { RolesHomeComponent } from './roles-home/packaged-roles-home.component';
import { PackagedRolesConfigurationComponent } from './roles-configuration/packaged-roles-configuration.component';
import { ParentsHomeComponent } from './parents-home/parents-home.component';
import { RoleConfigurationCanDeactivateGuard } from '../client/user-roles/role-configuration/role-configuration-can-deactivate.guard';
import { SystemUserGroupsHomeComponent } from './system-user-groups-home/system-user-groups-home.component';
import { SystemUserGroupsConfigurationComponent } from './system-user-groups-configuration/system-user-groups-configuration.component';
import { UserGroupConfigurationCanDeactivateGuard } from '../client/user-roles/user-group-configuration/user-group-configuration-can-deactivate.guard';
const routes: Routes = [
  {
    path: 'admin/clients/:action/:id',
    canActivate: [AdminGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: ParentsConfigurationComponent
  },
  {
    path: 'admin/users',
    canActivate: [AdminGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: SystemUsersHomeComponent
  },
  {
    path: 'admin/usergroups',
    canActivate: [AdminGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: SystemUserGroupsHomeComponent
  },
  {
    path: 'admin/usergroups/:action',
    canActivate: [AdminGuard],
    canDeactivate: [UserGroupConfigurationCanDeactivateGuard],
    component: SystemUserGroupsConfigurationComponent
  },
  {
    path: 'admin/usergroups/:type/:action/:id',
    canActivate: [AuthGuard],
    component: SystemUserGroupsConfigurationComponent,
    canDeactivate: [UserGroupConfigurationCanDeactivateGuard]
  },
  {
    path: 'admin/clients',
    canActivate: [AdminGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: ParentsHomeComponent
  },
  {
    path: 'admin/roles/:type/:action/:id',
    component: PackagedRolesConfigurationComponent,
    canActivate: [AdminGuard],
    canDeactivate: [RoleConfigurationCanDeactivateGuard]
  },
  {
    path: 'admin/roles',
    canActivate: [AdminGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: RolesHomeComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
