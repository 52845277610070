import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivityKeyDisplayName, ActivityKey, ActivityType } from './activity-lookup-enum';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ActivityLookupService } from 'src/app/shared/services/activity-lookup.service';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { Company } from 'src/app/shared/models/company';
import { ActivityLookupTableRow } from './activity-lookup-table-row';
import { DatePipe } from '@angular/common';
import { Constants } from 'src/app/shared/constants';

@Component({
  selector: 'app-activity-lookup',
  templateUrl: './activity-lookup.component.html'
})
export class ActivityLookupComponent implements OnInit {

  public isApiServiceCalled: boolean;
  public activityLookupForm: UntypedFormGroup;
  public activityKeyDisplayName = ActivityKeyDisplayName;
  public activityKeys = Object.values(ActivityKey);
  public activityTypes = Object.values(ActivityType);
  public selectedPeriod = 7;
  public isPageLoading = false;
  public properties = {};
  public activityLookupRecords = [];
  public activityLookupTable = [];
  public filteredRecords: ActivityLookupTableRow[] = [];
  public companyList: Company[] = [];
  public noResult: boolean;
  private parentId: string;

  constructor(private formBuilder: UntypedFormBuilder,
    public parentContextService: ParentContextService,
    public activityLookupService: ActivityLookupService,
    private eventSourceService: EventSourceService,
    private datePipe: DatePipe
  ) {
    this.parentId = parentContextService.getParentContext();
  }

  ngOnInit(): void {
    this.initActivityLookupForm();
    this.doGetCompanyDetails();
  }

  public handleSearch(): void {
    if (this.activityLookupForm.invalid) {
      return;
    }
    this.fetchEventsActivity();
  }

  public counter(i: number): number[] {
    return new Array(i);
  }

  private columnReset() {
    const actionTypeColumn = {
      headerText: 'Action Types',
      key: 'actionType',
      isSortable: true,
      isColumnDisplayed: true
    };
    this.initDataTableConfig();
    if (this.activityLookupForm.get('activityType').value === ActivityType.Outbound) {
      this.properties['columns'].splice(1, 0, actionTypeColumn);
    }
  }

  private doGetCompanyDetails(): void {
    this.isApiServiceCalled = true;
    this.eventSourceService.getCompanies(this.parentId, '').subscribe(
      res => {
        this.filterCompany(res);
        this.isApiServiceCalled = false;
      },
      (error: any) => {
        console.log(error);
      });
  }

  private filterCompany(res: Object) {
    const result = res['result'];
    if (result !== null && result.length > 0) {
      result.forEach(es => {
        if (es.settings !== undefined && es.settings !== null) {
          es.settings.forEach(setting => {
            const company: Company = new Company();
            company.companyId = setting['companyId'];
            company.companyName = setting['companyName'];
            this.companyList.push(company);
          });
        }
      });
    }
  }

  private fetchEventsActivity(): void {
    this.isPageLoading = true;
    this.activityLookupService.getEventActivityLookUp(this.buildPayload()).subscribe(
      (res: any) => {
        this.activityLookupRecords = res.result as ActivityLookupTableRow[];
        this.columnReset();
        this.initActivityLookupTableList();
        this.isPageLoading = false;
      }, (error: any) => {
        this.columnReset();
        this.filteredRecords = [];
        this.noResult = true;
        console.log(error);
        this.isPageLoading = false;
      });
  }

  private initActivityLookupTableList(): void {
    if (!this.activityLookupRecords || this.activityLookupRecords === undefined) {
      return;
    }
    this.activityLookupTable = [];
    this.activityLookupRecords.forEach((nextRecord) => {
      this.activityLookupTable.push(this.buildActivityLookupTableRow(nextRecord));
    });
    this.filteredRecords = [];
    this.filteredRecords = this.activityLookupTable;
  }

  private buildActivityLookupTableRow(nextActivityLookupRecord: any ): ActivityLookupTableRow {
    const activityLookupTableRow: ActivityLookupTableRow = new ActivityLookupTableRow();
    activityLookupTableRow.activityTimeStamp = this.datePipe.transform(new Date(nextActivityLookupRecord.activityTimeStamp),
      Constants.DATE_TIME_FORMAT);
    activityLookupTableRow.actionType = nextActivityLookupRecord.actionType;
    activityLookupTableRow.eventType = nextActivityLookupRecord.eventType;
    activityLookupTableRow.eventSource = nextActivityLookupRecord.eventSource;
    activityLookupTableRow.companyId = nextActivityLookupRecord.companyId;
    activityLookupTableRow.message = nextActivityLookupRecord.message;
    return activityLookupTableRow;
  }

  private buildPayload(): string {
    const requestBody = {
      'parentId': this.parentId,
      'keyName': this.activityLookupForm.get('lookUpKey').value,
      'keyValue': this.activityLookupForm.get('lookUpValue').value,
      'period': this.activityLookupForm.get('lookBackPeriod').value,
      'companyId': this.activityLookupForm.get('companyId').value,
      'activityType': this.activityLookupForm.get('activityType').value
    };
    return JSON.stringify(requestBody);
  }

  private initActivityLookupForm(): void {
    this.activityLookupForm = this.formBuilder.group({
      lookUpKey: ['', Validators.required],
      lookUpValue: ['', Validators.required],
      companyId: [''],
      activityType: ['', Validators.required],
      lookBackPeriod: [this.selectedPeriod]
    });
  }

  private initDataTableConfig(): void {
    this.isPageLoading = false;
    this.properties = {
      columns: [
        {
          headerText: 'Timestamp',
          key: 'activityTimeStamp',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Event Type',
          key: 'eventType',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Event Source',
          key: 'eventSource',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Company ID',
          key: 'companyId',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Payload',
          key: 'message',
          isSortable: true,
          isColumnDisplayed: true,
          dataIcon: {
            phrase: 'Event Received:',
            startPhrase: 16,
            endPhrase: 76,
            toolTip: 'Click to expand'
          }
        }
      ],
      sort: {
        defaultSortedColumn: 'activityTimeStamp',
        defaultSortOrder: 'descending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

}