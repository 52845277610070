import { ServiceSettings } from './service-settings';

export class ApiServiceSettings extends ServiceSettings {

  endPoint : string;

  constructor() {
    super();
    this.endPoint = '';
  }

}