import { Injectable } from '@angular/core';
import { UserSource } from '../models/user-source-enum';
import { CognitoLoginService } from './cognito.login.service';
import { ExternalSystemLoginService } from './external-system-login-service';
import { LoginService } from './LoginService';
import { OktaLoginService } from './okta.login.service';

@Injectable({
  providedIn: 'root'
})

export class LoginServiceLocator {

  constructor(
    private loginService: CognitoLoginService,
    private oktaLoginService: OktaLoginService,
    private externalSystemLoginService: ExternalSystemLoginService
  ) {}

  public getLoginService(): LoginService {
    if (localStorage.getItem('user-source') === UserSource.SSO) {
      return this.externalSystemLoginService;
    } else if (localStorage.getItem('user-source') === UserSource.OKTA) {
      return this.oktaLoginService;
    } else {
      return this.loginService;
    }
  }

}