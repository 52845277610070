import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER, ID_IDENTIFIER, HAS_ASSOCIATIONS_IDENTIFIER,
  ASSOCIATION_API_FIELD_ASSOCIATIONS, ENTITY_TYPE, ASSOCIATION_API_FIELD_STATUS } from '../models/association/association-constants';
import { AssociationTemplate } from '../models/association/association-template';
import { ConfiguredEntityType } from '../models/association/configured-entity-type.enum';
import { Messages } from 'src/app/shared/message';
import { ConfiguredEntityAssociationParams } from '../models/association/configured-entity-association-params';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssociationService {

  host = environment.host;
  base = environment.base;
  associationUrl = 'associations';

  public INFO = 'INFO';
  public WARNING_ALERT = 'WARNING';

  private configuredEntityAssociationParams: ConfiguredEntityAssociationParams;
  private associationInProgress = new EventEmitter<any>();
  private associationDone = new EventEmitter<any>();
  private entityType: string;
  private associationsAPIResponse = new EventEmitter<any>();

  constructor(
    private http: HttpClient
  ) { }

  public withEntityType(entityType: string): AssociationService {
    this.entityType = entityType;
    return this;
  }

  public withConfiguredEntityAssociationParams(configuredEntityAssociationParams: Record<string, any>): AssociationService {
    this.configuredEntityAssociationParams = configuredEntityAssociationParams as ConfiguredEntityAssociationParams;
    return this;
  }

  public getAssociations(parentId: string, associationType: ConfiguredEntityType, id: string): Observable<any> {
    const url = `${this.host}${this.base}${this.associationUrl}/${AssociationTemplate[associationType].apiPathPart}/${id}`;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }

  public getEmptyAssociationsMessage(type: ConfiguredEntityType): string {
    return Messages.emptyAssociationsMessage.replace('{object type}', AssociationTemplate[type].displayName.toLowerCase());
  }

  public loadAssociations(): Observable<any> {
    this.notifyInProgress();
    this.getAssociations(
      this.configuredEntityAssociationParams.ParentId,
      this.configuredEntityAssociationParams.EntityType,
      this.configuredEntityAssociationParams.EntityId).subscribe(
      success => {
        if (success['statusMessage'] !== 'SUCCESS') {
          return;
        }
        this.configuredEntityAssociationParams.ApiResponse = Object.keys(success['result'][ASSOCIATION_API_FIELD_ASSOCIATIONS]).length > 0
          ? success['result'] : {};
        this.associationsAPIResponse.emit(this.configuredEntityAssociationParams);
        this.notifyDone(success['result'][ASSOCIATION_API_FIELD_STATUS]);
      }, () => {
        this.notifyDone(false);
      });
    return this.associationsAPIResponse;
  }

  public trackAssociationInProgress(): Observable<any> {
    return this.associationInProgress.asObservable();
  }

  public trackAssociationDone(): Observable<any> {
    return this.associationDone.asObservable();
  }

  private notifyInProgress(): void {
    const status = {};
    status[ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER] = true;
    this.associationInProgress.emit(status);
  }

  private notifyDone(hasAssociations: boolean): void {
    const status = {};
    status[ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER] = false;
    status[HAS_ASSOCIATIONS_IDENTIFIER] = hasAssociations;
    status[ID_IDENTIFIER] = this.configuredEntityAssociationParams.EntityId;
    status[ENTITY_TYPE] = this.entityType;
    this.associationDone.emit(status);
  }

}