import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserSource } from 'src/app/shared/models/user-source-enum';
import { CognitoLoginService } from 'src/app/shared/services/cognito.login.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: UntypedFormGroup;
  public validUsername : boolean;
  public errorMessage: string;
  public version: string;
  public loggingIn: boolean;

  constructor(private fb: UntypedFormBuilder,
    public loginService: CognitoLoginService,
    public router: Router) {

  }

  ngOnInit() {
    this.createLoginForm();
    this.validUsername = true;
    this.errorMessage = null;
    this.version = environment.version;
    localStorage.setItem('user-source', UserSource.COGNITO);
    console.log('Checking if the user is already authenticated. If so, then redirect to the secure site');
    this.isLoggedIn();
  }

  public OnSubmit(): void {
    if (this.loginForm.value.username === null || this.loginForm.value.password === null) {
      this.errorMessage = 'All fields are required';
      return;
    }
    this.errorMessage = null;
    this.loggingIn = true;
    this.loginService.authenticate(this.loginForm.value.username, this.loginForm.value.password, this);
  }

  public loginCallback(message: string): void {
    this.loggingIn = false;
    if (message !== null) { // error
      this.errorMessage = message;
      console.log('loginCallback result: ' + this.errorMessage);
    } else { // success
      location.reload();
    }
  }

  public isLoggedIn(): void {
    if (this.loginService.isLoggedIn()) {
      this.router.navigate(['/']);
    }
  }

  private createLoginForm(): void {
    this.loginForm = this.fb.group({
      username: [
        null,
        Validators.compose([Validators.required, Validators.minLength(2)])
      ],
      password: [null, Validators.required]
    });
  }

}