import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
  })

export class EntityContentService {

  host = environment.host;
  base = environment.base;
  entityContentUpsert = 'entity-content/configured';
  entityContentGetAll = 'entity-content/configured/all';
  entityContent = 'entity-content';
  budgetPath = 'budget/configured';
  allBudgetPath = 'budget/configured/all';
  upsertPath = 'content';

  constructor(private http: HttpClient) { }

  public insertEntityContent(parentId: string, payload: string): Observable<any> {
    const url = this.host + this.base + this.entityContentUpsert;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post(url, payload, options);
  }

  public updateEntityContent(parentId: string, payload: string): Observable<any> {
    const url = this.host + this.base + this.entityContentUpsert;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.put(url, payload, options);
  }

  public addContent(parentId: string, payload: any): Observable<any> {
    const url = this.host + this.base + this.upsertPath;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post(url, payload, options);
  }

  public getConfiguredEntityContents(parentId: string): Observable<any> {
    const url = this.host + this.base + this.entityContentGetAll;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }

  public deleteConfiguredEntityContent(companyId: string, contentId: string, parentId: string): Observable<any> {
    const url = this.host + this.base + this.entityContentUpsert;
    return this.http.delete(url, this.buildOptions(parentId, contentId, companyId));
  }

  public getEntityContent(companyId: string, contentId: string, parentId: string) {
    const url = this.host + this.base + this.entityContentUpsert;
    return this.http.get(url, this.buildOptions(parentId, contentId, companyId));
  }

  public addBudgetContent(parentId: string, payload: string) {
    const url = this.host + this.base + this.budgetPath;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post(url, payload, options);
  }

  public updateBudgetContent(parentId: string, payload: string) {
    const url = this.host + this.base + this.budgetPath;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.put(url, payload, options);
  }

  public getBudgetContent(companyId: string, contentId: string, parentId: string) {
    const url = this.host + this.base + this.budgetPath;
    const params = { 'id': contentId };
    if (companyId !== null) {
      params['companyId'] = companyId;
    }
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = { headers: headers, params: params };
    return this.http.get(url, options);
  }

  public getAllBudgetContent(parentId: string) {
    const url = this.host + this.base + this.allBudgetPath;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }

  public fetchBudgetEntityContent(parentId: string) {
    let budgetTable: any;
    const budgetUrl = this.host + this.base + this.allBudgetPath;
    const budgetHeaders: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const budgetOptions = {
      headers: budgetHeaders
    };
    return this.http.get(budgetUrl, budgetOptions).pipe(
      tap(result => {
        budgetTable = result;
      }),
      concatMap(() => {
        const entityContentUrl = this.host + this.base + this.entityContentGetAll;
        const entityContentHeaders: HttpHeaders = new HttpHeaders()
          .append('parent-id', parentId);
        const entityContentOptions = {
          headers: entityContentHeaders
        };
        return this.http.get(entityContentUrl, entityContentOptions);
      }),
      map(entityContentTable => {
        return { budgetTable, entityContentTable };
      })
    );
  }

  private buildOptions(parentId: string, contentId: string, companyId: string) {
    var params = { 'contentId': contentId };
    if (companyId !== null) {
      params['companyId'] = companyId
    }
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers, params: params };
    return options;
  }

}