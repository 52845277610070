import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsHomeComponent } from './events-home/events-home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EventsRoutingModule } from './events-routing.module';
import { EventsConfigurationComponent } from './events-configuration/events-configuration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { TableModule, PaginationModule, ActionModule, LoadingIndicatorModule, RadioButtonModule } from 'epsilon-blueprint';
import { CoreUIModule } from '@epsilon/core-ui';

@NgModule({
  declarations: [EventsHomeComponent, EventsConfigurationComponent],
  imports: [
    CommonModule,
    NgbModule,
    EventsRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    BaseTemplateModule,
    TableModule,
    PaginationModule,
    ActionModule,
    LoadingIndicatorModule,
    RadioButtonModule,
    CoreUIModule
  ]
})
export class EventsModule { }