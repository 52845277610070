<coreui-modal #basicModal>
  <app-coreui-modal-warning></app-coreui-modal-warning>
  <bp-loading-indicator *ngIf="isApiServiceCalled" [text]="operationStatusMessage" [kind]="'inPage'">
  </bp-loading-indicator>
  <ng-template #titleTemplate>
    <h2 class="text-style-1 mb-0">{{getModalTitle()}} User</h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
      <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <form name="usersConfigurationForm" [formGroup]="usersConfigurationFormGroup" class="horizontal-scroll">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <coreui-form-field for="name" label="Name">
              <input coreUIInput type="text" id="name" name="name" formControlName="name" class="form-control" placeholder="Enter a first and last name">
              <coreui-form-error *ngIf="usersConfigurationFormGroup.controls.name.errors?.required">
								<div> {{messages.nameRequired}}</div>
							</coreui-form-error>
              <coreui-form-error *ngIf="usersConfigurationFormGroup.controls.name.errors?.pattern">
								<div> {{messages.invalidPlaceholderSyntaxErrorMsg}}</div>
							</coreui-form-error>
            </coreui-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <coreui-form-field for="username" label="Username">
              <input coreUIInput type="text" id="username" name="username" formControlName="username" class="form-control" placeholder="Enter a username">
              <coreui-form-error *ngIf="usersConfigurationFormGroup.controls.username.errors?.required">
								<div> {{messages.userNameRequired}}</div>
							</coreui-form-error>
              <coreui-form-error *ngIf="usersConfigurationFormGroup.controls.username.errors?.pattern">
								<div> {{messages.invalidEmailPlaceholderSyntaxErrorMsg}}</div>
							</coreui-form-error>
            </coreui-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <label for="startDate">User Start Date</label>
              <input type="text" id="startDate" formControlName="startDate" class="form-control" placeholder="{{getDefaultStartDate()}}" [min]="getMinimumDate()" [owlDateTime]="startDatePicker" [owlDateTimeTrigger]="startDatePicker">
              <div class="input-group-append">
                <app-ae-button
                  buttonType="icon-large"
                  icon="calendar"
                  [owlDateTimeTrigger]="startDatePicker">
                </app-ae-button>
              </div>
              <owl-date-time #startDatePicker></owl-date-time>
            </div>
            <ng-container *ngIf="isSaveClicked && usersConfigurationFormGroup.controls.startDate.invalid">
              <div *ngIf="usersConfigurationFormGroup.controls.startDate.errors.required" class="text-color-warning">
                {{messages.startDateRequired}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <label for="endDate">User End Date</label>
              <input type="text" id="endDate" formControlName="endDate" class="form-control" placeholder="MM/DD/YYYY, 12:00 AM" [min]="getMinimumDate()"  [owlDateTime]="endDatePicker">
              <div class="input-group-append">
                <app-ae-button
                  buttonType="icon-large"
                  icon="calendar"
                  [owlDateTimeTrigger]="endDatePicker">
                </app-ae-button>
              </div>
              <owl-date-time #endDatePicker></owl-date-time>
            </div>
            <div class="form-check form-check-inline mt-2">
              <input class="form-check-input"  id="noEndDate" type="checkbox" [checked]="isEndDateNull" [disabled]="isReadOnly"
                (change)="noEndDateSelected($event)"/>
                        <label class="form-check-label" for="noEndDate">No End Date</label>
            </div>
            <ng-container *ngIf="isSaveClicked && usersConfigurationFormGroup.controls.endDate.invalid">
              <coreui-form-error> 
                <div *ngIf="usersConfigurationFormGroup.controls.endDate.errors.required" class="text-color-warning">
                  {{messages.endDateRequired}}
                </div>
              </coreui-form-error> 
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <app-ae-multi-select
              [formGroup]="usersConfigurationFormGroup"
              [multiSelectFormControlName]="UserGroupsFormControlName"
              [label]="UserGroupsLabel"
              [placeholder]="SelectUserGroupsPlaceholderTxt"
              [multiSelectDataStream]="MultiSelectDataStream">
            </app-ae-multi-select>
            <ng-container *ngIf="isSaveClicked && usersConfigurationFormGroup.controls.userGroups.invalid">
              <div *ngIf="usersConfigurationFormGroup.controls.userGroups.errors.required" class="text-color-warning">
                {{messages.emptyUserGroup}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="row pl-4 pt-2">
              <div class="col-auto custom-control custom-radio">
                <input type="radio" id="Active"  class="custom-control-input" name="status"
                    value="Active" formControlName="status" >
                <label for="Active" class="custom-control-label">Active</label>
              </div>
              <div class="col-auto custom-control custom-radio">
                <input type="radio" id="Inactive"  class="custom-control-input" name="status"
                      value="Inactive" formControlName="status" >
                <label for="Inactive" class="custom-control-label">Inactive</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="action === 'EDIT'" class="row">
        <div class="col">
          <div class="form-group">
            <div class="col-xs py-2 px-0">
              <label>{{messages.lastLogin}}</label>
              <p class="font-weight-bold"> {{ configuredUserToEdit.lastLoginDate  |  date: 'MM/dd/yyyy h:mm a' }} </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
    <div class="px-sm" *ngIf="authorizationService.isAllowed('USR', ['CREATE','UPDATE'])">
      <app-ae-button (click)="onSave()" buttonText="Save"></app-ae-button>
    </div>
  </ng-template>
</coreui-modal>
