<app-base-template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-auto p-0">
        <app-ae-left-nav [leftNavigations]="leftNavigations"></app-ae-left-nav>
      </div>
      <div class="col px-0">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</app-base-template>
