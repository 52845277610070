import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AwsUserCredential } from 'src/app/shared/models/aws-user-credential';
import { AwsActionSettings } from 'src/app/shared/models/aws-action-settings';
import { Messages } from 'src/app/shared/message';


@Component({
  selector: 'app-aws-user-credential',
  templateUrl: './aws-user-credential.component.html'
})
export class AwsUserCredentialComponent implements OnInit {

  awsUserCredentialForm: UntypedFormGroup;
  userNameChanged: boolean;
  accessKeyChanged: boolean;
  secretAccessKeyChanged: boolean;

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.awsUserCredentialForm = this.fb.group({
      userName: ['', [Validators.required]],
      accessKey: ['', [Validators.required]],
      secretAccessKey: ['', [Validators.required]]
    });

    this.getUserNameControl().valueChanges.subscribe(changes => {
      this.userNameChanged = true;
    });

    this.getAccessKeyControl().valueChanges.subscribe(changes => {
      this.accessKeyChanged = true;
    });

    this.getSecretAccessKeyControl().valueChanges.subscribe(changes => {
      this.secretAccessKeyChanged = true;
    });
  }
  hasFormUpdated() {
    return this.userNameChanged
      || this.accessKeyChanged
      || this.secretAccessKeyChanged;
  }
  finalizeUpdates() {
    this.userNameChanged = false;
    this.accessKeyChanged = false;
    this.secretAccessKeyChanged = false;
  }
  getCredentials(): AwsActionSettings {
    const awsUserCredential = new AwsUserCredential();
    awsUserCredential.userName = this.awsUserCredentialForm.get('userName').value;
    awsUserCredential.accessKey = this.awsUserCredentialForm.get('accessKey').value;
    awsUserCredential.secretAccessKey = this.awsUserCredentialForm.get('secretAccessKey').value;
    const awsActionSettings = new AwsActionSettings();
    awsActionSettings.awsUserCredential = awsUserCredential;
    return awsActionSettings;
  }
  isValid() {
    return this.awsUserCredentialForm.valid;
  }
  getUserNameControl() {
    return this.awsUserCredentialForm.get('userName');
  }
  getAccessKeyControl() {
    return this.awsUserCredentialForm.get('accessKey');
  }
  getSecretAccessKeyControl() {
    return this.awsUserCredentialForm.get('secretAccessKey');
  }
  validateForRequired(c: AbstractControl) {
    return c.errors && c.touched && c.errors.required;
  }
  validateForPattern(c: AbstractControl) {
    return c.errors && c.errors.pattern;
  }
  getEmptyUserNameMessage() {
    return Messages.emptyUserName;
  }
  getEmptyAccessKeyMessage() {
    return Messages.emptyAccessKey;
  }
  getEmptySecretAccessKeyMessage() {
    return Messages.emptySecretAccessKey;
  }

}