export class ProgramHomeFilterItems {

  static FILTER_ITEMS = {
    event_source_provider: {
      label: 'Event Source Provider',
      valueField: 'eventSource',
      values: [],
      valueMap: new Map<string, string>()
    },
    action_destination: {
      label: 'Action Destination',
      valueField: 'actionDestination',
      values: [],
      valueMap: new Map<string, string>()
    }
  };

}