import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IMSService } from 'src/app/shared/services/ims.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';

@Component({
  selector: 'app-ims-insert',
  templateUrl: './ims-insert.component.html'
})
export class ImsInsertComponent implements OnInit {

  public insertForm: UntypedFormGroup;
  public isApiServiceCalled: boolean = false;
  private IMS_CONTEXT_ID = 'ae';
  private parentId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public parentContextService: ParentContextService,
    private imsService: IMSService,
    private popupService: PopupMessageService
  ) {
    this.parentId = parentContextService.getParentContext();
  }

  ngOnInit(): void {
    this.initInsertForm();
  }

  private initInsertForm(): void {
    this.insertForm = this.formBuilder.group({
      customerKey: ['', Validators.required],
      customerId: ['', Validators.required],
      emailAddress: ['', Validators.required],
      smsNumber: [''],
      deviceId: ['']
    })
  };

  public insertRecord(formGroupDirective): void {
    if (this.insertForm.invalid) {
      return;
    }
    this.isApiServiceCalled = true;
    this.imsService.insertRecord(this.parentId, this.buildPayload()).subscribe(
      (result: any) => {
        this.popupService.showSuccessMessage(result.result.httpResponse);
        formGroupDirective.resetForm();
        this.insertForm.reset();
        this.isApiServiceCalled = false;
      }, (error: any) => {
        this.popupService.showErrorMessage(error.error.result.httpResponse);
        this.isApiServiceCalled = false;
      });
  }

  private buildPayload(): string {
    const requestBody = {
      'parentId': this.parentId,
      'contextId': this.IMS_CONTEXT_ID,
      'customerKey': this.insertForm.get('customerKey').value,
      'customerId': this.insertForm.get('customerId').value,
      'emailAddress': this.insertForm.get('emailAddress').value,
      'smsNumber': this.insertForm.get('smsNumber').value,
      'deviceId': this.insertForm.get('deviceId').value
    };
    return JSON.stringify(requestBody);
  }

}
