<!-- TODO: replace native selects with ng-select, replace radio buttons with bp-radio-button -->
<app-base-template>
  <app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
  <app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
  <app-ae-modal-info></app-ae-modal-info>
  <form *ngIf="!isApiServiceCalled" [formGroup]="configuredEntityForm">
    <div class="container-fluid object-page-header pt-4">
      <div class="row mb-2">
        <div class="col">
            <app-ae-button
              buttonText="Back to Actions"
              buttonType="text-primary"
              iconPosition="left"
              icon="chevronLeft"
              routerLink="/actions"
            ></app-ae-button>
        </div>
      </div>
      <div class="row align-items-top u-pb-25">
        <div class="col">
          <div class="form-group mb-0">
            <input
              type="text"
              id="actionNameControl"
              class="form-control object-page-header-input"
              placeholder="Action Name"
              formControlName="actionName"
              (keyup)="checkIfActionNameAlreadyExists()"
            />
            <div *ngIf="actionName.invalid && isSaveClicked" class="text-color-warning">
              <ng-container *ngIf="actionName.errors.required || actionName.errors.pattern">
                Please enter a unique action name. The name may contain alphanumeric and special characters.
              </ng-container>
              <ng-container *ngIf="actionName.errors.alreadyExist">
                This action name already exists. Please enter a unique action name. The name may contain alphanumeric and special characters.
              </ng-container>
            </div>
            <div *ngIf="inputParams.action ==='add'" class="text-color-warning pt-2">
              <span class="font-weight-semi-bold">Warning:</span> You must enter a unique action name. You will not able to edit this name after the creation of this action.
            </div>
          </div>
        </div>
        <div class="row" *ngIf="inputParams.action === 'edit' && !isStandardAction()">
          <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_ACTION', ['DELETE'])">
            <app-ae-button class="ml-4" buttonText="Delete" (click)="showDeleteConfiguredActionAlert()" [disabled]="hasAssociation">
            </app-ae-button>
          </div>
          <div class="col-auto pl-0"
            *ngIf="authorizationService.isAllowed('CFG_ACTION', ['CREATE','UPDATE'])">
            <app-ae-button class="mr-3" buttonText="Copy" (click)="copyConfiguredAction()"></app-ae-button>
          </div>
        </div>
        <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_ACTION', ['CREATE','UPDATE']) && !isStandardAction()">
          <app-ae-button buttonText="Save" (click)="saveConfiguredAction()" [disabled]="isSaveDisabled()"></app-ae-button>
        </div>
      </div>
    </div>
    <div class="container-fluid pt-4">
      <app-ribbon *ngIf="hasOperationFailed()"
        ribbonStyle="ribbon-alert"
        [ribbonMessage]="getOperationFailureMessage()"
        ribbonHasButton="true"
        [buttonText]="getAssociationsBtnTxt()"
        (buttonClick)="openAssociationsModal()">
      </app-ribbon>
      <div *ngIf="!isConfiguredAD" class="row mb-3">
        <div class="col">
          <bp-ribbon classes="ribbon ribbon-alert" text="Action Destination isn't configured for this action. Please configure it so that this action will appear while rule setup."></bp-ribbon>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <p class="text-style-muted-1">
            <span class="font-weight-semi-bold">Note:</span> Use the term <span class="font-weight-semi-bold">[@userDefinedId@]</span> to specify where a user should be able to fill in information within a program or rule, such as a template ID or business unit ID.
          </p>
          <div class="form-group">
            <coreui-form-field label="Action Destination" for="actionDestination">
              <coreui-select id="actionDestination" formControlName="actionDestination" placeholder="Select an action destination"
                (valueChange)="onItemChange($event)">
                <coreui-option value="null" [disabled]="true">Select an action destination</coreui-option>
                <coreui-option *ngFor="let item of actionDestinations;" [value]="item.actionDestination">{{item.name}}
                </coreui-option>
              </coreui-select>
              <coreui-form-error *ngIf="actionDestination.invalid && isSaveClicked">
                <div *ngIf="actionDestination.errors.required">
                  Please select an action destination
                </div>
              </coreui-form-error>
            </coreui-form-field>
            <coreui-form-field label="Action Type" for="actionType">
              <coreui-select id="actionType" formControlName="actionType" placeholder="Select an action type">
                <coreui-option [value]="null" [disabled]="true">Select an action type</coreui-option>
                <coreui-option *ngFor="let item of actionTypes;" [value]="item.id">{{item.name}}</coreui-option>
              </coreui-select>
              <coreui-form-error *ngIf="actionType.invalid && isSaveClicked && actionType.errors.required">
                Please select an action type
              </coreui-form-error>
            </coreui-form-field>
          <!-- TODO: nesting <form> in <form> is invalid html, refactor app-actions-api-configuration and app-actions-aws-configuration -->
          <app-actions-api-configuration
            *ngIf="actionPreference === 'API'"
            [endPointValue]="endPoint"
            [entityType]="inputParams.entityType"
            [isSaveClicked]="isSaveClicked"
          ></app-actions-api-configuration>
          <app-actions-aws-configuration
            *ngIf="actionPreference === 'LAMBDA'"
            [awsRegionValue]="awsRegion"
            [resourceNameValue]="resourceName"
            [isSaveClicked]="isSaveClicked"
          ></app-actions-aws-configuration>
          <coreui-form-field label="Action Description (Optional)" for="actionDescription">
            <textarea coreUIInput id="actionDescription" placeholder="Add a description for this action" rows="5"
              formControlName="actionDescription">
                    </textarea>
          </coreui-form-field>
          <app-ae-radio-group
            label = 'Mode'
            orientation = 'vertical'
            [radioItems] = modesList
            [formGroup] = configuredEntityForm
            [callback] = onModeChange
            >
          </app-ae-radio-group>
          </div>
        </div>
      </div>
      <div class="form-group">
        <h2>Associated Event</h2>
        <div class="row mt-3">
          <div class="col">
            <p>This action can only be used in programs that contain its associated event.</p>
          </div>
        </div>
        <div class="row mt-2" *ngIf="!isStandardAction()">
          <div class="col-4">
            <coreui-form-field label="Event Source Provider" for="eventSourceProvider">
              <coreui-select id="eventSourceProvider" formControlName="eventSourceProvider"
                placeholder="Select an event source provider" (valueChange)="onEventSourceProviderChange($event)">
                <coreui-option [value]="" [disabled]="true">Select an event source provider</coreui-option>
                <coreui-option *ngFor="let item of eventSourceProviders;" [value]="item.eventSource">{{item.name}}
                </coreui-option>
              </coreui-select>
              <coreui-form-error *ngIf="eventSourceProvider.invalid && isSaveClicked">
                <div *ngIf="eventSourceProvider.errors.required">
                  Please select an event source provider
                </div>
              </coreui-form-error>
            </coreui-form-field>
          </div>
          <div class="col-4">
            <coreui-form-field label="Event" for="eventType">
              <coreui-select id="eventType" formControlName="eventType" placeholder="Select an event"
                (blur)="getEventSchemaForEventType()">
                <coreui-option [value]="" [disabled]="true">Select an event</coreui-option>
                <coreui-option *ngFor="let item of this.filteredEvents;" [value]="item.id.eventName">{{item.id.eventName}}
                </coreui-option>
              </coreui-select>
              <coreui-form-error *ngIf="eventType.invalid && isSaveClicked">
                <div *ngIf="eventType.errors.required">Please select an event
                </div>
              </coreui-form-error>
            </coreui-form-field>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-5">
          <fieldset class="form-group">
            <app-ae-radio-group
              label = 'Do you want to add a delay to this action?'
              orientation = 'vertical'
              [radioItems] = delayList
              [callback] = onDelayOptionChange
              [formGroup] = configuredEntityForm
              >
            </app-ae-radio-group>
            <div *ngIf="delayType.value === 'DURATION'">
              <time-duration
                [durationTitle]="'Delay by time:'"
                [duration]="delay"
                [parentForm]="configuredEntityForm"
              ></time-duration>
            </div>
            <div *ngIf="delayType.value === 'ATTRIBUTE'">
              <app-delay
                [delayTitle]="'Delay by attribute:'"
                [delay]="delay"
                [parentForm]="configuredEntityForm"
                [selectedEventSchema]="selectedEventSchema"
                [validationRequired]="true"
                (validationStatus)="invalidateForm($event)"
              ></app-delay>
            </div>
          </fieldset>
        </div>
      </div>
      <ng-container *ngIf="actionPreference === 'API'">
        <div class="row mt-5">
          <div class="col-lg-5">
            <h2>Headers (Optional)</h2>
          </div>
        </div>
        <ng-container formArrayName="headers">
          <ng-container *ngFor="let header of configuredEntityForm.get('headers').controls; let headerIndex = index">
            <ng-container [formGroupName]="headerIndex">
              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <coreui-form-field label="Name">
                      <input
                        type="text"
                        id="headerName"
                        class="form-control"
                        placeholder="Enter a name"
                        formControlName="headerName"
                      />
                    </coreui-form-field>
                    <div *ngIf="header.get('headerName').invalid && (header.get('headerName').dirty || header.get('headerName').touched)" class="text-color-warning">
                      <ng-container *ngIf="header.get('headerName').errors.required">
                        Please enter a name
                      </ng-container>
                      <ng-container *ngIf="header.get('headerName').errors.pattern || header.get('headerName').errors.invalidPlaceholderSyntax">
                        {{getinvalidPlaceHolderStatusMsg('headerName')}}
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <coreui-form-field label="Value">
                      <input
                        type="text"
                        id="headerValue"
                        class="form-control"
                        placeholder="Enter a value"
                        formControlName="headerValue"
                      />
                    </coreui-form-field>
                    <div *ngIf="header.get('headerValue').invalid && (header.get('headerValue').dirty || header.get('headerValue').touched)" class="text-color-warning">
                      <ng-container *ngIf="header.get('headerValue').errors.required">
                        Please enter a value
                      </ng-container>
                      <ng-container *ngIf="header.get('headerValue').errors.invalidPlaceholderSyntax">
                        {{getinvalidPlaceHolderStatusMsg('headerValue')}}
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div *ngIf="this.inputParams.entityType !== entityTypes.PACKAGED.toLowerCase()" class="col pt-4">
                <app-ae-button id="deleteButton" (click)="deleteHeader(headers, headerIndex)" buttonText="Delete Header"></app-ae-button>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <div *ngIf="this.inputParams.entityType !== entityTypes.PACKAGED.toLowerCase()" class="row">
          <div class="col-auto">
            <app-ae-button
              buttonText="Add a Header"
              icon="add"
              iconPosition="left"
              buttonType="text-primary"
              (click)="addNewHeader(configuredEntityForm.get('headers'))"
            ></app-ae-button>
          </div>
          <div class="col-12">
            <h2 class="mt-5">Content</h2>
          </div>
          <div class="col-lg-5">
            <fieldset class="form-group">
              <legend>Request Type</legend>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="httpMethodPost"
                  class="custom-control-input"
                  name="httpMethod"
                  value="POST"
                  formControlName="httpMethod"
                >
                <label for="httpMethodPost" class="custom-control-label">POST</label>
              </div>
              <div class="custom-control custom-radio mt-2">
                <input
                  type="radio"
                  id="httpMethodPut"
                  class="custom-control-input"
                  name="httpMethod"
                  value="PUT"
                  formControlName="httpMethod"
                >
                <label for="httpMethodPut" class="custom-control-label">PUT</label>
              </div>
              <div class="custom-control custom-radio mt-2">
                <input
                  type="radio"
                  id="httpMethodGet"
                  class="custom-control-input"
                  name="httpMethod"
                  value="GET"
                  formControlName="httpMethod"
                >
                <label for="httpMethodGet" class="custom-control-label">GET</label>
              </div>
              <div *ngIf="httpMethod.invalid && isSaveClicked" class="text-color-warning">
                <ng-container *ngIf="httpMethod.errors.required">Please select request type</ng-container>
              </div>
            </fieldset>
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="col-lg-5" *ngIf ='!isStandardAction()'>
          <div class="form-group">
            <coreui-form-field label="Content Type" for="contentType">
              <coreui-select id="contentType" formControlName="contentType">
                <coreui-option value="application/json" [selected]="true">JSON</coreui-option>
                <coreui-option value="none">NONE</coreui-option>
              </coreui-select>
            </coreui-form-field>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <coreui-form-field label="Content" for="content">
              <textarea
                id="content"
                class="form-control"
                placeholder="Enter the content for this action"
                rows="7"
                formControlName="content"
                (blur)="validateActionContent()"
              ></textarea>
              <coreui-form-error>
              <div *ngIf="content.invalid && isSaveClicked" class="text-color-warning">
                <ng-container *ngIf="content.errors.invalidVelocitySyntax">
                  Your velocity template in action content is invalid. Please check for syntax errors.
                </ng-container>
                <ng-container *ngIf="content.errors.invalidJSONInVelocityTemplate">
                  Your json in velocity template is invalid. Please check for syntax errors.
                </ng-container>
                <ng-container *ngIf="content.errors.invalidJson">
                  Your action content is invalid. Please check for syntax errors.
                </ng-container>
                <ng-container *ngIf="content.errors.required">
                  Please enter the content for this action
                </ng-container>
                <ng-container *ngIf="content.errors.invalidPlaceholderSyntax">
                  {{getinvalidPlaceHolderStatusMsg('content')}}
                </ng-container>
              </div>
            </coreui-form-error>
          </coreui-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-base-template>
