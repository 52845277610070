import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ProgramService } from './program.service';

@Injectable({
  providedIn: 'root'
})
export class CustomProgramService extends ProgramService {

  host = environment.host;
  base = environment.base;
  programDetailsPath = 'custom/';

  constructor(public http?: HttpClient,
    public programService?: ProgramService) {
    super(http);
  }

}