<ng-container [formGroup]="formGroup">
  <div formArrayName="actions" *ngIf="rightPaneActions && rightPaneActions.length > 0">
    <app-rule-action *ngFor="let action of rightPaneActions; let index=index"
                     [index]="index"
                     [placeholders]="action.placeholders"
                     [configuredAction]="action.configuredAction"
                     [configuredActionDestination]="action.configuredActionDestination"
                     [actionSettings]="action.actionSettingsInput"
                     [isReadOnlyRule]="isReadOnlyRule"
                     [showDeleteButton]="showDeleteButton"
                     [validationRequired]="validationRequired"
                     [eventSources]="eventSources"
                     [configuredEventDetails]="configuredEventDetails"
                     [actionInputs]="action.actionInputs"
                     [mode]="action.mode"
                     (insertActionController)="pushActionToController($event)"
                     (removeActionController)="onDeleteAction($event)"
    ></app-rule-action>
    <div class="row m-1" *ngIf="!isReadOnlyRule && showDeleteButton && rightPaneActions.length < ruleBuilder.MAX_ALLOWED_ACTIONS">
      <div class="col drop-attributes py-1"
           [treeAllowDrop]="canDropOnAction.bind(this)"
           (treeDropDragOver)="ruleBuilder.treeDropDragOver($event, droparea.ACTION, rightPaneActions.length)"
           (treeDropDragLeave)="ruleBuilder.treeDropDragLeave($event, droparea.ACTION, rightPaneActions.length)"
           (treeDrop)="onActionDropped($event)">
      </div>
    </div>
    <div class="row m-1" *ngIf="!isReadOnlyRule && showDeleteButton && rightPaneActions.length >= ruleBuilder.MAX_ALLOWED_ACTIONS">
      <div class="col drop-attributes py-1 text-center"
           [treeAllowDrop]="canDropOnAction.bind(this)"
           (treeDropDragOver)="ruleBuilder.treeDropDragOver($event, droparea.ACTION, rightPaneActions.length)"
           (treeDropDragLeave)="ruleBuilder.treeDropDragLeave($event, droparea.ACTION, rightPaneActions.length)"
           (treeDrop)="onActionDropped($event)">
        <span class="disable-pointer-events py-3"></span>
      </div>
    </div>
  </div>
  <div class="row mx-1 u-mb-10 rule-build-help drop-attributes"
       (treeDrop)="onActionDropped($event)"
       [treeAllowDrop]="canDropOnAction.bind(this)"
       *ngIf="!isReadOnlyRule && showDeleteButton && rightPaneActions.length === 0">
    <div class="col p-0 text-center">
      <p class="text-style-muted-2 py-4 mb-0">
        Drag and drop an action to finish building your rule
      </p>
    </div>
  </div>
</ng-container>
