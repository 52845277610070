import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { IQDecisionsRoutingModule } from './iqdecisions-routing.module';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import {
  TableModule,
  PaginationModule,
  ActionModule,
  RibbonModule,
  LoadingIndicatorModule,
  RadioButtonModule,
  CollapsiblePanelModule,
  NavSecondaryModule,
  ToggletipModule } from 'epsilon-blueprint';
import { CoreUIModule } from '@epsilon/core-ui';
import { IQDecisionsHomeComponent } from './iqdecisions-home/iqdecisions-home.component';
import { WarningState } from 'src/app/shared/warning-state';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { RequestInterceptor } from 'src/app/shared/http/request-interceptor';
import { BaseComponent } from '../client/base/base.component';
import { IQDecisionsNewMultiModelComponent } from './iqdecisions-multi-model/iqdecisions-multi-model.component';
import { IQdecisionsConfigurationSingleModelPackageComponent }
  from './iqdecisions-configuration-single-model-package/iqdecisions-configuration-single-model-package.component';
import { ModelSelectionModalComponent } from './model-selection-modal/model-selection-modal.component';
import { IQDecisionsViewSingleModelComponent } from './iqdecisions-view-single-model/iqdecisions-view-single-model.component';
import { IqdecisionsViewModelPackageInfoComponent } from './iqdecisions-view-model-package-info/iqdecisions-view-model-package-info.component';
import { IqdecisionsViewModelsComponent } from './iqdecisions-view-models/iqdecisions-view-models.component';
import { IqdecisionsViewHistoryComponent } from './iqdecisions-view-history/iqdecisions-view-history.component';
import { IqdecisionsViewProgramUsageComponent } from './iqdecisions-view-program-usage/iqdecisions-view-program-usage.component';
import { IqdecisionsViewModelParametersComponent } from './iqdecisions-view-model-parameters/iqdecisions-view-model-parameters.component';
import { IqdecisionsMultiModelGroupInfoComponent } from './iqdecisions-multi-model-group-info/iqdecisions-multi-model-group-info.component';

@NgModule({
  declarations: [
    IQDecisionsHomeComponent,
    IQDecisionsNewMultiModelComponent,
    IQdecisionsConfigurationSingleModelPackageComponent,
    ModelSelectionModalComponent,
    IQDecisionsViewSingleModelComponent,
    IqdecisionsViewModelPackageInfoComponent,
    IqdecisionsViewModelsComponent,
    IqdecisionsViewHistoryComponent,
    IqdecisionsViewProgramUsageComponent,
    IqdecisionsViewModelParametersComponent,
    IqdecisionsMultiModelGroupInfoComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    IQDecisionsRoutingModule,
    SharedModule,
    FormsModule,
    BaseTemplateModule,
    TableModule,
    PaginationModule,
    ActionModule,
    RibbonModule,
    LoadingIndicatorModule,
    RadioButtonModule,
    CoreUIModule,
    HttpClientModule,
    NgSelectModule,
    DragDropModule,
    CollapsiblePanelModule,
    NavSecondaryModule,
    ToggletipModule
  ],
  providers: [
    BaseComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    WarningState
  ]
})


export class IQDecisionsModule { }
