import { Component, OnInit, ViewChild } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { EntityContentService } from 'src/app/shared/services/entity-content.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { ReplaySubject, takeUntil } from 'rxjs';
import { WarningType } from 'src/app/shared/warning-options';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { BaseComponent } from '../../../base/base.component';
import { EntityContent } from 'src/app/shared/models/entity-content.model';
import { BudgetContent } from 'src/app/shared/models/budget-content.model';
import { Constants } from 'src/app/shared/constants';
import { BluePrintTableColumnType } from 'src/app/shared/blueprint-table-column-type';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-entity-content-home',
  templateUrl: './entity-content-home.component.html'
  })
export class EntityContentHomeComponent extends BaseComponent implements OnInit {

  public isDataLoading = false;
  public columns = [];
  public sorts = [];
  public properties = {};
  public entityContentTableList = [];
  public pageIndices;
  public entityContentTableFilteredData = [];
  public readEntityContentTableFilteredData = [];
  public messages = Messages;
  @ViewChild(CoreuiModalWarningComponent, { static: true })
  protected warningModal: CoreuiModalWarningComponent;
  private parentId: string;
  private ContentRowIdToBeDeleted: string;
  private entityContents: EntityContent[] = [];
  private budgetIds: { [key: string]: any } = {};
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public parentContextService: ParentContextService,
    private entityContentService: EntityContentService,
    public authorizationService: AuthorizationService,
    private datePipe: DatePipe,
    private popupService: PopupMessageService) {
    super();
    this.initDataTableConfig();
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.fetchEntityContentData();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteEntityContent();
    }
  }
  public performMenuButtonClickActions(event: any): void {
    this.ContentRowIdToBeDeleted = event.id;
    this.showDeleteEntityContentDecisionAlert();
  }

  private parseContentKey(partitionKey: string) : any[] {
    const parts = partitionKey.split(Constants.DB_KEY_SEPARATOR);
    return parts;
  }

  private fetchEntityContentData(): any {
    this.entityContentService.fetchBudgetEntityContent(this.parentId).subscribe(({ budgetTable, entityContentTable }) => {
      const budgetContentRes = budgetTable['result'];
      budgetContentRes.forEach(bc => {
        const budgetContent = new BudgetContent();
        budgetContent.companyId = bc.partitionKey.companyId;
        budgetContent.id = bc.id;
        budgetContent.clicksRemaining = bc.clicksRemaining;
        budgetContent.expirationTime = bc.expirationTime;
        this.budgetIds[bc.id] = bc.budget;
      });
      const entityContentRes = entityContentTable['result'];
      entityContentRes.forEach(ec => {
        const entityContent = new EntityContent(this.parentId, ec.fieldName, ec.contentType, ec.contentKey.companyId, ec.contentId, ec.clickThroughUrl, ec.contentUrl, ec.expirationTime);
        this.entityContents.push(entityContent);
      });
      this.initEntityContentTableList();
      this.isDataLoading = false;
    }, error => {
      this.isDataLoading = false;
    });
  }

  private mergeTables(): EntityContent[] {
    const mergedTable = [];
    for (const row of this.entityContents) {
      const result = new EntityContent(this.parentId, row.fieldName, row.contentType, row.companyId, row.contentId, row.clickThroughUrl, row.contentUrl, row.expirationTime);
      if (row.contentId in this.budgetIds) {
        result.budget = this.budgetIds[row.contentId];
      } else {
        result.budget = null;
      }
      mergedTable.push(result);
    }
    return mergedTable;
  }

  private initEntityContentTableList() {
    const mergedTable = this.mergeTables();
    if (!mergedTable || mergedTable === undefined) {
      return;
    }
    this.entityContentTableFilteredData = [];
    mergedTable.forEach(ec => {
      const entityContentTableRow = {};
      entityContentTableRow['parentId'] = this.parentId;
      entityContentTableRow['id'] = ec.getContentKey();
      entityContentTableRow['companyId'] = ec.companyId;
      entityContentTableRow['contentType'] = ec.contentType;
      entityContentTableRow['fieldName'] = ec.fieldName;
      entityContentTableRow['contentId'] = ec.contentId;
      entityContentTableRow['contentUrl'] = ec.contentUrl;
      entityContentTableRow['clickThroughUrl'] = ec.clickThroughUrl;
      entityContentTableRow['budget'] = ec.budget;
      entityContentTableRow['expirationTime'] = ec.expirationTime ? this.datePipe.transform(new Date(ec.expirationTime * 1000), Constants.DATE_FORMAT) : null;
      entityContentTableRow['actions'] = this.buildActionsPerState();
      entityContentTableRow['routerLink'] = this.getRouterLink(ec);
      this.entityContentTableFilteredData.push(entityContentTableRow);
      this.readEntityContentTableFilteredData.push(entityContentTableRow);
    });
    this.entityContentTableList = this.entityContentTableFilteredData.slice(0, 10);
  }

  private getRouterLink(entityContent: EntityContent): string {
    return `/client/support/rmnupdate/` + entityContent.getEntityContentPath();
  }

  private buildActionsPerState(): any[] {
    const actionStr = 'actions';
    const finalComponentDataComponents = [];
    finalComponentDataComponents.push({ component: actionStr, label: Messages.deleteBtnTxt, disabled: false,
      isAuthorized: (this.authorizationService.isAllowed('SUPPORT', ['DELETE']) || this.authorizationService.isAllowed('CONTENT', ['DELETE']))
    });
    return finalComponentDataComponents;
  }

  private initDataTableConfig(): void {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      showBadgesComponent: true,
      columns: [
        {
          headerText: this.messages.contentId,
          key: 'contentId',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.LINK,
          link: {
            element: 'a',
            ariaLabel: '#{contentId}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: this.messages.contentUrl,
          key: 'contentUrl',
          isSortable: true,
          isColumnDisplayed: true,
          dataIcon: {
            phrase: '',
            startPhrase: 0,
            endPhrase: 35,
            toolTip: this.messages.clickToExpand
          }
        },
        {
          headerText: this.messages.clickThroughUrl,
          key: 'clickThroughUrl',
          isSortable: true,
          isColumnDisplayed: true,
          dataIcon: {
            phrase: '',
            startPhrase: 0,
            endPhrase: 35,
            toolTip: this.messages.clickToExpand
          }
        },
        {
          headerText: this.messages.budget,
          key: 'budget',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: this.messages.expiration,
          key: 'expirationTime',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: '',
          key: 'actions',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'contentId',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }


  private showDeleteEntityContentDecisionAlert(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteEntityContent,
      msg: Messages.deleteEntityContentActionMessage,
      msg2: [Messages.deleteEntityContentModalConfirmationMessage]
    });
  }

  private deleteEntityContent(): void {
    const parsedKey = this.parseContentKey(this.ContentRowIdToBeDeleted);
    const contentId = parsedKey[1];
    let companyId: string;
    if (parsedKey.length === 2) {
      companyId = null;
    } else {
      companyId = parsedKey[2];
    }
    this.entityContentService.deleteConfiguredEntityContent(companyId, contentId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.popupService.showDeleteMessage(Messages.deleteEntityContentSuccessMessage, false);
      location.reload();
    }, error => {
      if (error.status === 403) {
        this.popupService.showErrorMessage(error.error.statusMessage);
      } else {
        this.popupService.showErrorMessage(Messages.errorDeletingEntityContent);
      }
    });
  }

}