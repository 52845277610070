<app-ae-loading-indicator [isLoading]="isAPIServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
<div class="p-4">
  <app-ae-button iconPosition="left" icon="chevronLeft" [routerLink]="rolesLandingPageRouterLink"
    buttonText="Back to Roles">
  </app-ae-button>
  <div class="py-4" *ngIf="formReady">
    <h1>{{roleName}}</h1>
  </div>
  <div>
    <form *ngIf="formReady" class="core-form" [formGroup]="configuredEntityForm">
      <coreui-form-field class="col-6 p-0" label="Name" for="name">
        <input coreUIInput name="name" formControlName="name" placeholder={{messages.enterName}} id="name"/>
        <coreui-form-error *ngIf="configuredEntityForm.controls.name.errors?.duplicateName" class="text-color-warning">
          <div>{{messages.enterUniqueRoleName}}</div>
        </coreui-form-error>
        <coreui-form-error *ngIf="configuredEntityForm.controls.name.errors?.pattern" class="text-color-warning">
          <div>{{messages.invalidPlaceholderSyntaxErrorMsg}}</div>
        </coreui-form-error>
      </coreui-form-field>

      <coreui-form-field class="col-6 p-0" [label]="optionalLabel">
        <ng-template #optionalLabel>{{messages.description}}<span class="text-style-muted-4">{{messages.optionalLabel}}</span></ng-template>
        <textarea rows="5" coreUIInput formControlName="description"></textarea>
      </coreui-form-field>

      <app-all-permissions (allPermissionOutput)="onAllPermissionChange($event)" [isFormReadOnly]="!this.isCustomRole"
        #allPermissionComponent>
      </app-all-permissions>

      <coreui-tab-group aria-label="horizontal-tab-group">
        <coreui-tab *ngFor="let category of this.roleCategoryFeaturesMap | keyvalue:originalOrder;"
          label="{{this.categoryDisplayName[category.key]}}">
          <app-category-permissions categoryPermissionTitle="{{this.getCategoryPermissionTitle(category.key)}}"
            [inputPermissionList]="category.value" [isFormReadOnly]="!this.isCustomRole"
            (categoryPermissionsOutput)="loadAllPermissionForm()"
            #categoryPermissionComponents></app-category-permissions>
        </coreui-tab>
      </coreui-tab-group>

      <div class="row p-4">
        <app-ae-button class="mx-1"
          *ngIf="isRoleUpdateAllowed(this.editRoleType)" buttonText="Submit"
          (click)="onSave()"></app-ae-button>
        <app-ae-button class="mx-2" *ngIf="formOnSaveAction === 'create'" buttonText="Cancel"
          [routerLink]="rolesLandingPageRouterLink"></app-ae-button>
        <app-ae-button class="mx-2"
          *ngIf="isRoleDeleteAllowed()"
          buttonText="Delete" (click)="onDelete()"></app-ae-button>
      </div>
    </form>
  </div>
</div>