import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationComponent } from '@epsilon/core-ui';
import { NotificationStyle } from '../../constants';
import { PopupMessage } from '../../models/popup-message';
import { PopupMessageService } from '../../services/popup-message.service';

@Component({
  selector: 'app-ae-notification',
  templateUrl: './ae-notification.component.html'
})
export class AENotificationComponent implements OnInit {

  @ViewChild('toast', { static: true })
  public toast: NotificationComponent;

  public style: NotificationStyle;
  public message: string;
  public messageDuration = 4000;
  public nextPage = false;

  constructor(private popupService: PopupMessageService) { }

  ngOnInit(): void {
    this.popupService.popup.subscribe((popup : PopupMessage) => this.show(popup));
  }

  show(popup: PopupMessage): void {
    if (popup.nextPage) {
      this.handleNextPagePopup(popup);
      return;
    }
    this.set(popup);
    void this.toast.show();
  }

  set(popup: PopupMessage): void {
    this.style = popup.type;
    this.message = popup.message;
    if (popup.timeout) {
      this.messageDuration = popup.timeout;
    } else {
      this.messageDuration = 4000;
    }
  }

  handleNextPagePopup(popup: PopupMessage): void {
    this.popupService.setShowNotification(true);
    popup.nextPage = false;
    this.popupService.setNextPagePopup(popup);
  }

}