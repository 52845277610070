import { Injectable } from '@angular/core';
import { parse } from 'velocityjs';

@Injectable({ providedIn: 'root' })
export class VelocityValidatorService {

  static removeVelocitySyntaxFromActionContent(actionContent: string): string {
    return actionContent.split('\n').filter(delimitedContent => delimitedContent.search('#') === -1).join('\n');
  }

  static isVelocitySyntaxPresent(actionContent: string): boolean {
    const patterns = ['#set', '#if', '#foreach', '#else', '#elseif', '#end'];
    return patterns.some(pattern => actionContent.search(pattern) > -1);
  }

  static isValidVelocityTemplate(actionContent: string): boolean {
    if (actionContent === null || actionContent.trim().length === 0) {
      return false;
    }
    try {
      parse(actionContent);
    } catch (e) {
      return false;
    }
    return true;

  }

  static isValidJSON(actionContent: string): boolean {
    if (actionContent === null || actionContent.trim().length === 0) {
      return false;
    }
    try {
      JSON.parse(actionContent);
    } catch (e) {
      return false;
    }
    return true;
  }

}
