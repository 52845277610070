import { EventEmitter, Injectable } from '@angular/core';
import { NotificationStyle } from '../constants';
import { PopupMessage } from '../models/popup-message';

@Injectable({
  providedIn: 'root'
})
export class PopupMessageService {

  public popup: EventEmitter<PopupMessage> = new EventEmitter<PopupMessage>();

  private nextPagePopup: PopupMessage;

  private showNotification = false;

  constructor() { }

  public showSuccessMessage(message: string, nextPage = false, timeout = 5000): void {
    this.set({
      type: NotificationStyle.SUCCESS, timeout: timeout, message: message, nextPage: nextPage
    });
  }

  public showErrorMessage(message: string, nextPage = false, timeout = 5000): void {
    this.set({
      type: NotificationStyle.ERROR, timeout: timeout, message: message, nextPage: nextPage
    });
  }

  public showDeleteMessage(message: string, nextPage = true, timeout = 5000): void {
    this.set({
      type: NotificationStyle.SUCCESS, timeout: timeout, message: message, nextPage: nextPage
    });
  }

  public showRequiredMessage(message: string, nextPage = true, timeout = 5000): void {
    this.set({
      type: NotificationStyle.REQUIRED, timeout: timeout, message: message, nextPage: nextPage
    });
  }

  public set(popup: PopupMessage): void {
    this.popup.emit(popup);
  }

  public emitNextPage(): void {
    this.set(this.nextPagePopup);
  }

  public getNextPagePopup(): PopupMessage {
    return this.nextPagePopup;
  }

  public setNextPagePopup(value: PopupMessage): void {
    this.nextPagePopup = value;
  }

  public getShowNotification(): boolean {
    return this.showNotification;
  }

  public setShowNotification(value: boolean): void {
    this.showNotification = value;
  }

  setByResponse(response: any): void {
    const popup = new PopupMessage();
    if (response.status === 403) {
      popup.type = NotificationStyle.ERROR;
      popup.message = response.error.statusMessage;
      popup.timeout = 10000;
    }
    if (popup) {
      this.set(popup);
    }
  }

  setAsError(message: string): void {
    const popup = new PopupMessage();
    popup.message = message;
    popup.timeout = 4000;
    popup.type = NotificationStyle.ERROR;
    this.set(popup);
  }

  dismiss(): void {
    this.popup = null;
  }

}