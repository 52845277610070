import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuditSearchConfiguration } from '../models/change-log/audit-search-configuration';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuditHistoryService {

	public readonly auditHistoryUrl: string = 'audit-history/search';
	
	private host: string = environment.host;
	private base: string = environment.base;

	constructor(public http?: HttpClient) { }

	public getAuditHistory(parentId: string, auditSearchConfiguration: AuditSearchConfiguration): Observable<any> {
		const url = this.host + this.base + this.auditHistoryUrl;
		return this.http.post(url, auditSearchConfiguration, this.getHeaders(parentId));
	}

	private getHeaders(parentId: string): any {
		const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    	const options = { headers: headers};
		return options;
	}

}
  