<form name="exclusionConfigurationForm" [formGroup]="exclusionConfigurationFormGroup">
  <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMFORTABLE" class="container-fluid">
    <div class="row my-2">
      <div class="col-auto border border-color-secondary rounded align-self-center py-2">
        <div class="row">
          <div class="col-auto py-2 pr-1">
            Latest
          </div>
          <div class="col-auto px-1 inline-form-field" *ngIf="!isInputOccurencesAttributeConfigured()">
            <coreui-form-field>
              <input type="text" coreUIInput [coreUIDecimalMask]="decimalMaskConfig" name="occurrences" formControlName="occurrences" />
              <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
            </coreui-form-field>
          </div>
          <div class="col-auto pl-1" *ngIf="!isInputOccurencesAttributeConfigured()">
            <coreui-select formControlName="occurrencesUnit">
              <coreui-option [value]="QUANTITY"
                [selected]="exclusionConfigurationFormGroup.controls[formControlNames.OCCURRENCES_UNIT].value === QUANTITY">{{
                QUANTITY | titlecase }}</coreui-option>
            </coreui-select>
          </div>
          <div class="col-auto" *ngIf="isInputOccurencesAttributeConfigured()">
            <div class="col-auto py-2 px-0 text-style-2">
              {{inputOccurencesAttribute.name}}
            </div>
          </div>
          <div class="col px-0 py-0 ml-0">
            <div class="drop-list" id="latestNOccurrenceDurationDropArea" coreUIDropList [data]="[]"
              [connectedTo]="[]" (dropped)="inputOccurencesAttributeDropped($event)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row border border-color-secondary rounded mb-2">
      <div class="col align-self-center py-3">
        {{ getAttributeName() | lowercase }}
      </div>
      <div class="col-auto py-2 ml-auto">
        <button coreUIButton="icon-large" withBorder [disabled]="!canDeleteExclusionConfiguration()" (click)="onEntityStateAttributeDelete()"><i coreUIIcon="trash"></i></button>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-auto border border-color-secondary rounded align-self-center py-2">
        <div class="row">
          <div class="col-auto py-2 pr-1">
            Less than
          </div>
          <div class="col-auto" *ngIf="!isInputDurationAttributeConfigured()">
            <div class="row">
              <div class="col-auto px-1 inline-form-field">
                <coreui-form-field>
                  <input type="text" coreUIInput [coreUIDecimalMask]="decimalMaskConfig" name="duration"
                    formControlName="duration" />
                  <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
                </coreui-form-field>
              </div>
              <div class="col-auto px-1">
                <coreui-select formControlName="durationUnit">
                  <coreui-option *ngFor="let durationUnitName of durationUnitList" [value]="durationUnitName"
                    [selected]="exclusionConfigurationFormGroup.controls[formControlNames.DURATION_UNIT].value === durationUnitName">{{durationUnitName
                    | titlecase}}</coreui-option>
                </coreui-select>
              </div>
            </div>
          </div>
          <div class="col-auto" *ngIf="isInputDurationAttributeConfigured()">
            <div class="col-auto py-2 px-0 text-style-2">
              {{inputDurationAttribute.name}}
            </div>
          </div>
          <div class="col-auto py-2 pl-1">
            Before Now
          </div>
          <div class="col px-0 py-0 ml-0">
            <div class="drop-list" id="latestNOccurrenceOccurenceDropArea" coreUIDropList [data]="[]"
              [connectedTo]="[]" (dropped)="inputDurationAttributeDropped($event)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMPACT" class="col-auto border border-color-secondary rounded align-self-center py-2">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-auto py-2 pl-2 pr-0">
            Latest
          </div>
          <div class="col-auto pl-1 pr-0 inline-form-field" *ngIf="!isInputOccurencesAttributeConfigured()">
            <coreui-form-field>
              <input type="text" coreUIInput [coreUIDecimalMask]="decimalMaskConfig" name="occurrences" formControlName="occurrences" />
              <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
            </coreui-form-field>
          </div>
          <div class="col-auto py-2 pl-1 pr-0 text-style-2" *ngIf="isInputOccurencesAttributeConfigured()">
            {{inputOccurencesAttribute.name}}
          </div>
          <div class="col-auto py-2 pl-1 pr-0">
            {{ getAttributeName() | lowercase }}
          </div>
          <div class="col-auto py-2 pl-1 pr-0">
            less than
          </div>
          <div class="col-auto pl-1 pr-0 inline-form-field" *ngIf=" !isInputDurationAttributeConfigured()">
            <coreui-form-field>
              <input type="text" coreUIInput [coreUIDecimalMask]="decimalMaskConfig" name="duration" formControlName="duration" />
              <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
            </coreui-form-field>
          </div>
          <div class="col-auto pl-1 pr-0 inline-form-field" *ngIf="!isInputDurationAttributeConfigured()">
            <coreui-select formControlName="durationUnit">
              <coreui-option *ngFor="let durationUnitName of durationUnitList" [value]="durationUnitName" [selected]="exclusionConfigurationFormGroup.controls[formControlNames.DURATION_UNIT].value === durationUnitName">{{durationUnitName | titlecase}}</coreui-option>
            </coreui-select>
          </div>
          <div class="col-auto py-2 pl-1 pr-0 text-style-2" *ngIf=" isInputDurationAttributeConfigured()">
            {{inputDurationAttribute.name}}
          </div>
          <div class="col-auto py-2 pl-1 pr-0">
            before now
          </div>
        </div>
      </div>
      <div class="col-auto align-self-center">
        <div class="row">
          <div class="col-auto ml-auto px-2">
            <button coreUIButton="feedback-error-secondary" [disabled]="!canDeleteExclusionConfiguration()" (click)="onEntityStateAttributeDelete()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>