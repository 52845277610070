import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';
import { UserGroupDetails } from '../models/user-groups/user-group-details';
import { UserGroupService } from './user-group-service-interface';
import { Router } from '@angular/router';
import { PackagedUserGroupService } from './packaged-user-group.service';
import { Feature } from '../models/permission/feature/role-permission-constants';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ConfiguredUserGroupService implements UserGroupService {

  host = environment.host;
  base = environment.base;
  userGroupNavPath = 'client/admin/usergroups';
  configuredUserGroupUrl = 'usergroup/configured/';
  globalUserGroupUrl = 'usergroup/packaged/';
  partitionKey = 'System';
  queryUserGroupUrl = 'usergroup/configured?';


  constructor(private http: HttpClient, private router: Router) { }

  public getAllUserGroups(parentId: string) : Observable<any> {
    const url = this.host + this.base + this.configuredUserGroupUrl;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  public getActiveUserGroups(parentId: string, queryString: string): Observable<any> {
    const url = this.host + this.base + this.queryUserGroupUrl + queryString;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  public addUserGroup(parentId: string, userGroupDetails: UserGroupDetails): Observable<ServiceResponse> {
    const url = this.host + this.base + this.configuredUserGroupUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post<ServiceResponse>(url, userGroupDetails, options);
  }

  public updateUserGroup(parentId: string, userGroupDetails: UserGroupDetails): Observable<ServiceResponse> {
    const url = this.host + this.base + this.configuredUserGroupUrl + userGroupDetails.id;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.put<ServiceResponse>(url, userGroupDetails, options);
  }

  public getUserGroupById(parentId: string, userGroupId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + this.configuredUserGroupUrl + userGroupId;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get<ServiceResponse>(url, options);
  }

  public deleteUserGroupById(parentId: string, userGroupId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + this.configuredUserGroupUrl + userGroupId;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.delete<ServiceResponse>(url, options);
  }

  public getRouterLink(userGroup: UserGroupDetails): string {
    return `client/admin/usergroups/${userGroup.type.toLowerCase()}/editUserGroup/${userGroup.id}`;
  }

  public postDeleteRouterLink(): string {
    return 'client/admin';
  }

  public getNavPath(): string {
    return this.userGroupNavPath;
  }

  public getFeatureName(): string {
    return Feature.CFG_USR_GRP;
  }

  public isAdmin(): boolean {
    return false;
  }

}