import { Pipe, PipeTransform } from '@angular/core';
import { bool } from 'aws-sdk/clients/signer';

@Pipe({
  name: 'filter'
})

/**
 * To be used with *ngFor to filter list based on the provided callback function.
 */
export class FilterPipe implements PipeTransform {

  transform(items: any, callback: (item: any) => bool): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }

}