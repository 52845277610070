export enum ActivityKey {
  EPSILON_CUSTOMER_ID= 'epsilonCustomerId',
  EMAIL_ADDRESS= 'emailAddress',
  SEARCH_STRING= 'stringSearch'
}

export const ActivityKeyDisplayName: Record<ActivityKey, string> = {
  [ActivityKey.EPSILON_CUSTOMER_ID]: 'Epsilon Customer ID',
  [ActivityKey.EMAIL_ADDRESS]: 'Email Address',
  [ActivityKey.SEARCH_STRING]: 'String Search'
};

export enum ActivityType {
  Inbound= 'Inbound',
  Outbound= 'Outbound'
}