<div *ngIf="!isEditMode; else editForm">
  <div class="row">
    <div class="col-6">
      <app-ae-section-header [headerText]="messages.modelParametersMessage"></app-ae-section-header>
      <div class="row mt-3">
        <div class="col">
          <span class="text-style-muted-1">{{messages.inputMessage}}</span>
          <p class="indent-text">
            {{configuredRecommendation.metadataConfiguration?.packageInfo?.itemsChoiceType?.name}}
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <span class="text-style-muted-1 pt-2">{{messages.itemsPerInputLevelMessage}}</span>
          <p class="indent-text">
            {{configuredRecommendation.metadataConfiguration?.packageInfo?.itemsPerInputLevel}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #editForm>
  <div class="row">
    <div class="col-8">
      <form [formGroup]="modelParametersFormControl">
        <app-ae-section-header [headerText]="messages.modelParametersMessage"></app-ae-section-header>
        <div class="row">
          <div class="col-8">
            <div class="tooltip-container">
              <div class="tooltip-container">
                <coreui-form-field label="Input">
                  <coreui-select id="itemsChoiceTypeName" placeholder="{{messages.selectInputText}}" formControlName="itemsChoiceTypeName"
                    position="right" (valueChange)="onItemChange($event)">
                    <coreui-option [value]="" [disabled]="true">{{messages.selectInputText}}</coreui-option>
                    <coreui-option *ngFor="let item of choiceTypeList;"
                      [value]="item">
                      {{item}}
                    </coreui-option>
                  </coreui-select>
                  <coreui-form-error>
                    <ng-container *ngIf="itemsChoiceTypeName.errors?.required && saveForLater">
                      {{messages.inputRequiredMessage}}</ng-container>
                  </coreui-form-error>
                </coreui-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <coreui-form-field class="text-style-muted-1" label="{{messages.itemsPerInputLevelMessage}}">
              <input coreUIInput coreUIIntegerMask type="text" name="itemsPerInputLevel"
                formControlName="itemsPerInputLevel" />
              <coreui-form-error *ngIf="itemsPerInputLevel.invalid && saveForLater">
                <ng-container *ngIf="itemsPerInputLevel.errors?.required">
                {{messages.itemsPerInputLevelRequiredMessage}}</ng-container>
                <ng-container *ngIf="itemsPerInputLevel.errors?.min">
                {{messages.itemsPerInputLevelMinValueMessage}}</ng-container>
              </coreui-form-error>
            </coreui-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>