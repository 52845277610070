import { Injectable } from '@angular/core';
import { JsonSchemaAttribute } from '../models/json-schema-attribute';
import { v4 as uuid } from 'uuid';
import { ReservedAttributeLocatorService } from './reserved-attribute-locator.service';
import { DataTypes } from '../models/custom-rule/conditions/data-types';
import { SourceType } from '../models/custom-rule/conditions/source-type';
import { AttributeMetadata } from '../models/custom-rule/conditions/attribute-metadata';
import { TreeNode } from '../models/custom-rule/tree-node';

@Injectable({
  providedIn: 'root'
})
export class EventSchemaParserService {

  constructor(private reservedAttributeLocatorService: ReservedAttributeLocatorService) { }

  public extractAttributes(schema: Record<string|number|symbol, unknown>, rootPath: string,
    attributeMap: {[key: string]: AttributeMetadata}, sourceType: SourceType): TreeNode[] {
    const tree: TreeNode[] = [];
    try {
      const entryPoint: string = this.getEntryPoint(schema);
      // The right-hand side of a 'for...in' statement must be of type 'any', an object type or a type parameter
      let schemaProperties: any = schema[entryPoint];
      if (this.isOfTypeObject(schemaProperties)) {
        schemaProperties = schemaProperties['properties'];
      }
      for (const item in schemaProperties) {
        if (Object.prototype.hasOwnProperty.call(schemaProperties, item)) {
          const value = schemaProperties[item];
          const path: string = this.setPath(rootPath, item, value);
          if (this.isOfTypeAttribute(value)) {
            if (this.isTypeOfNull(value)) {
              continue;
            }
            if (this.reservedAttributeLocatorService.doesExist(item)) {
              continue;
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const id = uuid();
            const newAttributeMetadata = this.buildAttributeMetadata(id, item, (value as JsonSchemaAttribute).title,
              (value as JsonSchemaAttribute).type, path, sourceType, (value as JsonSchemaAttribute).format);
            attributeMap[newAttributeMetadata.id] = newAttributeMetadata;
            tree.push(this.buildNode(sourceType, newAttributeMetadata.id, newAttributeMetadata.name, newAttributeMetadata.title));
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const id = uuid();
            tree.push(this.buildNode(sourceType, id, item,
              (value as JsonSchemaAttribute).title, this.extractAttributes(value, path, attributeMap, sourceType)));
          }
        }
      }
      return tree;
    } catch (e) {
      return <TreeNode[]>[];
    }
  }

  public buildNode(sourceType: SourceType, id: string, name: string, title: string, children?: TreeNode[]): TreeNode {
    const treeNode: TreeNode = new TreeNode();
    treeNode.nodeType = sourceType;
    treeNode.id = id;
    treeNode.name = name;
    treeNode.title = title;
    if (children) {
      treeNode.children = children;
    }
    return treeNode;
  }

  private buildAttributeMetadata(id: string, name: string, title: string, type: string,
    path: string, sourceType: SourceType, format: string): AttributeMetadata {
    const metadata: AttributeMetadata = new AttributeMetadata();
    metadata.id = id;
    metadata.name = name;
    metadata.title = title;
    metadata.type = DataTypes[type];
    metadata.format = format;
    metadata.typeOfValue = DataTypes[type];
    if (path.includes('[*]')) {
      metadata.type = DataTypes.array;
    }
    metadata.path = path;
    metadata.sourceType = sourceType;
    return metadata;
  }

  private setPath(rootPath: string, attribute: string, value: any): string {
    if (this.isOfTypeArray(value)) {
      return this.appendPath(rootPath, attribute + '[*]');
    } else {
      return this.appendPath(rootPath, attribute);
    }
  }

  private getEntryPoint(schema: any): string {
    const schemaType: string = schema.type ? schema.type : 'object';
    switch (schemaType.toLowerCase()) {
      case 'object':
        return 'properties';
      case 'array':
        return 'items';
      default:
        return 'properties';
    }
  }

  private appendPath(rootPath: string, valueToAppend: string): string {
    return `${rootPath}.${valueToAppend}`;
  }

  private isOfTypeAttribute(obj: any): boolean {
    return 'type' in obj && !('properties' in obj) && !('items' in obj);
  }

  private isOfTypeObject(obj: any): boolean {
    return obj['type'] === 'object' && obj['properties'] !== null;
  }

  private isOfTypeArray(obj: any): boolean {
    return obj['type'] === 'array' && 'items' in obj;
  }

  private isTypeOfNull(obj: any): boolean {
    return obj['type'] === 'null';
  }

  private isArrayNotEmpty(arrayNames: any[]): boolean {
    return typeof arrayNames !== 'undefined' && arrayNames.length > 0;
  }

}
