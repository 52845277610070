import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { LoginServiceLocator } from 'src/app/shared/services/login.service-locator';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { ExternalSystemLoginService } from 'src/app/shared/services/external-system-login-service';
import oktaConfig from '../../../../app.config';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PrimaryNavigationService } from 'src/app/shared/services/primary-navigation.service';
import { UtilService } from 'src/app/shared/services/util-service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { UserSource } from 'src/app/shared/models/user-source-enum';


@Component({
  selector: 'app-primary-navigation',
  templateUrl: './primary-navigation.component.html',
  providers: [NgbTabsetConfig],
  styleUrls: ['./primary-navigation.component.scss']
})
export class PrimaryNavigationComponent implements OnInit {

  @ViewChild('authorize') authorizeRef: ElementRef;

  buildNumber = environment.version;
  documentationLink = environment.documentationLink;
  programsActiveIndicator: boolean;
  eventsActiveIndicator: boolean;
  actionsActiveIndicator: boolean;
  entityStatesActiveIndicator: boolean;
  iqDecisionsActiveIndicator: boolean;
  rulesActiveIndicator: boolean;
  exclusionsActiveIndicator: boolean;
  clientActiveIndicator: boolean;
  public parentId: string;
  public authorizeUrl: SafeResourceUrl;
  public isProductSwitched: boolean;
  public showNavItemsTest: boolean;

  constructor(config: NgbTabsetConfig, public loginServiceLocator: LoginServiceLocator, private router: Router,
    public authorizationService: AuthorizationService, private popupService: PopupMessageService,
    public externalSystemService: ExternalSystemLoginService, public http?: HttpClient,
    public domsanitizer?: DomSanitizer, private parentContextService?: ParentContextService,
    public primaryNavigationService?: PrimaryNavigationService) {
    config.justify = 'center';
    config.type = 'pills';
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.manageActiveIndicator(this.router.url);
  }

  performLogout(): void {
    this.loginServiceLocator.getLoginService().logout();
  }

  pageIQDecisionsValidation = () => environment?.showIQDecisions && true;

  manageActiveIndicator(menuItem: string): void {
    if (menuItem.includes('/programs')) {
      this.programsActiveIndicator = true;
    } else if (menuItem.includes('/events')) {
      this.eventsActiveIndicator = true;
    } else if (menuItem.includes('/actions')) {
      this.actionsActiveIndicator = true;
    } else if (menuItem.includes('/entitystates')) {
      this.entityStatesActiveIndicator = true;
    } else if (menuItem.includes('/iqdecisions')) {
      this.iqDecisionsActiveIndicator = true;
    } else if (menuItem.includes('/rules')) {
      this.rulesActiveIndicator = true;
    } else if (menuItem.includes('/exclusions')) {
      this.exclusionsActiveIndicator = true;
    } else if (menuItem.includes('/client')) {
      this.clientActiveIndicator = true;
    }
  }

  public showNav(): boolean {
    return !this.externalSystemService.isExternalSystem() ? true : false;
  }

  public isOktaUser(): boolean {
    return localStorage.getItem('user-source') === UserSource.OKTA;
  }

  public authorizeProduct(productSwitcher: string): void {
    let requestURL: string;
    switch (productSwitcher) {
      case 'performanceInsights': {
        requestURL = oktaConfig.oidc.sessionCookieRedirectUri + `${environment.tableauRedirectPath}`;
        requestURL = requestURL.replace('TOKEN', localStorage.getItem('token'));
        this.getExternalTenantMappingByParentId('Tableau', requestURL);
        break;
      }
      case 'pcm': {
        break;
      }
    }
  }

  private getExternalTenantMappingByParentId(externalSystem: string, authorizeUrl: string): void {
    this.primaryNavigationService.getExternalTenantMappingByParentIdService(this.parentId, externalSystem)
      .subscribe(
        response => {
          const externalUrl = response['result'].externalUrl;
          const windowRef = window.open(authorizeUrl, 'productSwitcher');
          windowRef.onload = void this.loadExternalUrl(externalUrl, windowRef);
        }, error => {
          console.log(JSON.stringify(error));
          this.popupService.showErrorMessage(Messages.externalUrlNotFound);
        });
  }

  private loadExternalUrl(url: string, windowRef: Window): void {
    UtilService.delay(9000);
    windowRef.location.href = url;
  }

  private showNavItems(): boolean {
    this.showNavItemsTest = true;
    if (this.router.url.includes('/admin/')){
      this.showNavItemsTest = false;
    };
    if(this.router.url.includes('client/admin')){
      this.showNavItemsTest = true;
    }
    return this.showNavItemsTest;
  }

}
