import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { BatchExtractRoutingModule } from './batch-extract-routing.module';
import { BatchExtractHomeComponent } from './batch-extract-home/batch-extract-home.component';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { TableModule, PaginationModule, LoadingIndicatorModule, MultiselectModule } from 'epsilon-blueprint';
import { ConfigureBatchExtractComponent } from './configure-batch-extract/configure-batch-extract.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '../base/base.component';
import { CoreUIModule } from '@epsilon/core-ui';

@NgModule({
  declarations: [BatchExtractHomeComponent, ConfigureBatchExtractComponent],
  imports: [
    CommonModule,
    BatchExtractRoutingModule,
    BaseTemplateModule,
    TableModule,
    PaginationModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MultiselectModule,
    LoadingIndicatorModule,
    NgSelectModule,
    CoreUIModule
  ],
  providers: [
    BaseComponent
  ]
})
export class BatchExtractModule {

}
