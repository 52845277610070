import { ActionDelay } from '../action-delay';
import { ActionType } from './action-type';
import { v4 as uuid } from 'uuid';
import { DelayType } from '../delay-type.enum';
import { DurationInputSubTypes } from '../duration-input-sub-types';
import { Mode } from '../mode-enum';

export class PackagedProgramAction {

  public id: string;
  public actionType: ActionType;
  public actionDelay: ActionDelay;
  public actionInputs: string[];
  public actionSettingsInput: {};

  constructor(action?: PackagedProgramAction) {
    Object.assign(this, action);
  }

  public buildApiAction(actionDestination: string, service: string, businessUnitId: string, templateId: string ): PackagedProgramAction {
    this.id = uuid();
    this.actionType = ActionType.API_ACTION;
    this.actionInputs = [actionDestination, service];
    this.actionSettingsInput = { serviceInput: { businessUnitId: businessUnitId, templateId: templateId } };
    return this;
  }

  public buildScheduledAction(trigerEventName: string, durationHours: number, durationType?: string,
    durationSubtype?: DurationInputSubTypes): PackagedProgramAction {
    this.id = uuid();
    this.actionType = ActionType.SCHEDULED_EVENT;
    const acionSettingsInput = {
      'triggerEventName': trigerEventName
    };
    this.actionSettingsInput = acionSettingsInput;
    const actionDelay: ActionDelay = new ActionDelay();
    actionDelay.delay = durationHours.toFixed(2);
    actionDelay.delayType = DelayType.DURATION;
    actionDelay.delaySubtype = durationSubtype;
    this.actionDelay = actionDelay;
    return this;
  }

  public isAsynchronous(): boolean {
    return this.actionInputs && this.actionInputs.indexOf(Mode.SYNCHRONOUS.toLowerCase()) === -1;
  }

}