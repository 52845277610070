import { AuditActivity } from './audit-activity';

export class AuditLog {
	date: Date;
	userName: string;
	activities: AuditActivity[] = [];
	
	public get getActivities(): AuditActivity[] {
		return this.activities;
	}

	public getDateCompletedField(auditHistory: any): void {
		this.date = new Date(auditHistory["auditTime"]);;
		this.userName = auditHistory["userId"];
	}
}