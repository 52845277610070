import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Action } from 'src/app/shared/models/permission/feature/action';
import { ActionType } from 'src/app/shared/models/permission/feature/action-type';

@Component({
  selector: 'app-all-permissions',
  templateUrl: './all-permissions.component.html',
  styleUrls: ['./all-permissions.component.scss']
  })

export class AllPermissionsComponent implements OnInit {

  @Input() public isFormReadOnly: boolean;
  @Output() public allPermissionOutput = new EventEmitter<Action>();

  public allPermissionsFormGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
    this.loadAllPermissionForm();
  }

  public loadAllPermissionForm(): void {
    this.allPermissionsFormGroup = new UntypedFormGroup({
      allReadFormControl: new UntypedFormControl({ value: false, disabled: this.isFormReadOnly }),
      allWriteFormControl: new UntypedFormControl({ value: false, disabled: this.isFormReadOnly }),
      allDeleteFormControl: new UntypedFormControl({ value: false, disabled: this.isFormReadOnly })
    });
  }

  public onAllPermissionChange(action: string): void {
    let updatedAction: Action;
    switch (action) {
      case ActionType.READ:
        updatedAction = this.buildAction(ActionType.READ, this.allPermissionsFormGroup.controls.allReadFormControl.value);
        break;
      case ActionType.CREATE:
        updatedAction = this.buildAction(ActionType.CREATE, this.allPermissionsFormGroup.controls.allWriteFormControl.value);
        break;
      case ActionType.DELETE:
        updatedAction = this.buildAction(ActionType.DELETE, this.allPermissionsFormGroup.controls.allDeleteFormControl.value);
        break;
    }
    this.allPermissionOutput.emit(updatedAction);
  }

  private buildAction(actionType: ActionType, allowed: boolean): Action {
    const action = new Action();
    action.actionType = actionType;
    action.allowed = allowed;
    return action;
  }

}