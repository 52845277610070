import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Action } from 'src/app/shared/constants';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ConfiguredUser } from 'src/app/shared/models/user-roles/configured-user';
import { Messages } from 'src/app/shared/message';
import { UserStatus } from 'src/app/shared/models/user-status';
import { ModalComponent } from '@epsilon/core-ui';
import { UserGroupInfo } from '../../../../shared/models/permission/user/user-group-info';
import { AuthorizationService } from '../../../../shared/services/authorization-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfiguredUserGroupService } from '../../../../shared/services/configured-user-group.service';
import { ParentContextService } from '../../../../shared/services/parent-context.service';
import {DatePipe} from '@angular/common';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { Pattern } from 'src/app/shared/pattern';

@Component({
  selector: 'app-users-configuration',
  templateUrl: './users-configuration.component.html',
  styleUrls: ['./users-configuration.component.scss']
})
export class UsersConfigurationComponent implements OnInit {

  @Input()
  public action: string;
  @Input()
  public configuredUsers: ConfiguredUser[] = [];
  @Input()
  public id: string;
  @Input()
  public userGroupInfo: UserGroupInfo[];
  @Output()
  private isModalDisplayed = new EventEmitter<any>();
  @Output()
  private configuredUser = new EventEmitter<any>();

  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  @ViewChild(CoreuiModalWarningComponent)
  public warningModal: CoreuiModalWarningComponent;
  public usersConfigurationFormGroup: UntypedFormGroup;
  public formBuilder: UntypedFormBuilder;
  public configuredUserToEdit: ConfiguredUser;
  public messages = Messages;
  public isReadOnly = false;
  public isSaveClicked = false;
  public endDate: number;
  public isEndDateNull = false;
  public parentId: string;
  public userGroupDetails: UserGroupInfo[];
  public isApiServiceCalled: boolean;
  public operationStatusMessage: string;
  private isModalShown: boolean;
  private multiSelectItems: BehaviorSubject<UserGroupInfo[]> = new BehaviorSubject<UserGroupInfo[]>([]);
  private datePipe: DatePipe = new DatePipe('en-US');

  public constructor(
    formBuilder: UntypedFormBuilder,
    public parentContextService: ParentContextService,
    public configuredUserGroupService: ConfiguredUserGroupService,
    public changeDetector: ChangeDetectorRef,
    public authorizationService?: AuthorizationService) {
    this.formBuilder = formBuilder;
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.buildUserConfigurationForm();
    this.getAllUserGroups();
    this.launchBasicModal();
  }

  public getModalTitle(): string {
    return this.action === Action.CREATE ? 'Add' : 'Edit';
  }

  public getMinimumDate(): Date {
    const date = new Date();
    date.setSeconds(0);
    return date;
  }

  public getDefaultStartDate(): string {
    return this.datePipe.transform(new Date(), 'MM/dd/yyyy, hh.mma');
  }

  public onSave(): void {
    this.isSaveClicked = true;
    this.saveConfiguredUser();
  }

  public noEndDateSelected(event): void {
    if (event.target.checked) {
      this.usersConfigurationFormGroup.get('endDate').disable({ onlySelf: true, emitEvent: false });
      this.usersConfigurationFormGroup.get('endDate').setValue('');
    } else {
      this.usersConfigurationFormGroup.get('endDate').enable({ onlySelf: true, emitEvent: false });
    }
  }

  public closeBasicModal(): void {
    if (this.action === Action.EDIT && (this.configuredUserToEdit.name !== this.usersConfigurationFormGroup.get('name').value ||
      this.configuredUserToEdit.userGroups !== this.usersConfigurationFormGroup.get('userGroups').value ||
        this.configuredUserToEdit.startDate !== this.usersConfigurationFormGroup.get('startDate').value ||
        this.configuredUserToEdit.endDate !== this.usersConfigurationFormGroup.get('endDate').value) && this.isSaveClicked === false) {
        this.warningModal.launchUnsavedChangesWarningModal().subscribe( result => {
          if (result === true){
            void this.basicModal.hide();
            this.isModalShown = false;
            this.isModalDisplayed.emit(this.isModalShown);
            this.isSaveClicked = false;
          }
        });
    } else if (this.action === Action.CREATE && this.isSaveClicked === true){
      void this.basicModal.hide();
      this.isModalShown = false;
      this.isModalDisplayed.emit(this.isModalShown);
      this.isSaveClicked = false;
    }else if (this.action === Action.CREATE && this.isSaveClicked === false){
      this.warningModal.launchUnsavedChangesWarningModal().subscribe( result => {
        if (result === true){
          void this.basicModal.hide();
          this.isModalShown = false;
          this.isModalDisplayed.emit(this.isModalShown);
          this.isSaveClicked = false;
        }
      });
    } else {
      void this.basicModal.hide();
      this.isModalShown = false;
      this.isModalDisplayed.emit(this.isModalShown);
      this.isSaveClicked = false;
    }
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  public get UserGroupsFormControlName(): string {
    return 'userGroups';
  }

  public get UserGroupsLabel(): string {
    return Messages.userGroupsLabel;
  }

  public get SelectUserGroupsPlaceholderTxt(): string {
    return Messages.selectUserGroupsPlaceholderTxt;
  }

  public get MultiSelectDataStream(): BehaviorSubject<UserGroupInfo[]> {
    return this.multiSelectItems;
  }

  private saveConfiguredUser() {
    if ((this.validateBlankValue(this.usersConfigurationFormGroup.controls.username.value) && this.isMatchingPattern(this.usersConfigurationFormGroup.controls.username.value, Pattern.ALPHA_NUMERIC_EMAIL)) &&
         (this.validateBlankValue(this.usersConfigurationFormGroup.controls.name.value) && this.isMatchingPattern(this.usersConfigurationFormGroup.controls.name.value, Pattern.ALPHA_NUMERIC_SPACE_HYPHEN)) &&
         this.validateBlankArray(this.usersConfigurationFormGroup.controls.userGroups.value)) {
      this.configuredUserToEdit = this.getConfiguredUser(this.configuredUserToEdit);
      this.configuredUser.emit({ action: this.action, user: this.configuredUserToEdit });
      this.closeBasicModal();
    } else {
      switch(false){
        case this.isMatchingPattern(this.usersConfigurationFormGroup.controls.username.value, Pattern.ALPHA_NUMERIC_EMAIL):
          this.usersConfigurationFormGroup.controls.username.setErrors({ 'pattern': true});
          break;
        case this.validateBlankValue(this.usersConfigurationFormGroup.controls.username.value):
          this.usersConfigurationFormGroup.controls.username.setErrors({ 'required': true });
          break;
        case this.isMatchingPattern(this.usersConfigurationFormGroup.controls.name.value, Pattern.ALPHA_NUMERIC_SPACE_HYPHEN):
          this.usersConfigurationFormGroup.controls.name.setErrors({ 'pattern': true});
          break;
        case this.validateBlankValue(this.usersConfigurationFormGroup.controls.name.value):
          this.usersConfigurationFormGroup.controls.name.setErrors({ 'required': true });
          break;
        case this.validateBlankArray(this.usersConfigurationFormGroup.controls.userGroups.value):
          this.usersConfigurationFormGroup.controls.userGroups.setErrors({ 'required': true });
      }
      this.usersConfigurationFormGroup.markAllAsTouched();
    }
  }

  private getConfiguredUser(configuredUser: ConfiguredUser): ConfiguredUser {
    configuredUser.id = this.usersConfigurationFormGroup.get('username').value;
    configuredUser.name = this.usersConfigurationFormGroup.get('name').value;
    configuredUser.active = this.usersConfigurationFormGroup.get('status').value === UserStatus.ACTIVE;
    configuredUser.userGroups = this.usersConfigurationFormGroup.get('userGroups').value;
    configuredUser.startDate = new Date(this.usersConfigurationFormGroup.get('startDate').value).valueOf();
    this.endDate = this.usersConfigurationFormGroup.get('endDate').value;
    configuredUser.endDate = this.endDate === null ? null : new Date(this.endDate).valueOf();
    return configuredUser;
  }

  private buildUserConfigurationForm(): void {
    this.buildUserConfigurationFormToAdd(this.buildEmptyUserDetails());
    if (this.action === Action.EDIT) {
      this.configuredUserToEdit = this.configuredUsers.find(
        nextConfiguredUser => nextConfiguredUser.id === this.id);
      this.patchUserConfigurationForm();
    }
    if (this.action === Action.CREATE) {
      this.configuredUserToEdit = new ConfiguredUser();
      this.isEndDateNull = true;
      if (this.isEndDateNull) {
        this.usersConfigurationFormGroup.get('endDate').disable({ onlySelf: true, emitEvent: false });
      }
    }
  }

  private validateBlankValue(controlValue: string): boolean {
    if (controlValue === null){return false;}
    if(controlValue.length === 0){return false;}
    if (controlValue.trim() === ''){return false;}
    return true;
  }

  private validateBlankArray(controlValue: Array<Object>){
    if (controlValue === null){return false;}
    if (controlValue.length === 0){ return false;}
    return true;
  }

  private isMatchingPattern(input: string, pattern: string){
    if (input === null) {return true;}
    return input.match(pattern) !== null;
  }

  private patchUserConfigurationForm(): void {
    this.isEndDateNull = this.configuredUserToEdit.endDate === null;
    if (this.isEndDateNull) {
      this.usersConfigurationFormGroup.get('endDate').disable({ onlySelf: true, emitEvent: false });
    }
    const patch = {
      id: this.configuredUserToEdit.id,
      name: this.configuredUserToEdit.name,
      username: this.configuredUserToEdit.id,
      status: this.configuredUserToEdit.active ? UserStatus.ACTIVE : UserStatus.INACTIVE,
      userGroups: this.configuredUserToEdit.userGroups,
      startDate: new Date(this.configuredUserToEdit.startDate),
      endDate: this.configuredUserToEdit.endDate === null ? null : new Date(this.configuredUserToEdit.endDate)
    };
    this.usersConfigurationFormGroup.get('username').disable({ onlySelf: true, emitEvent: false });
    this.usersConfigurationFormGroup.patchValue(patch);
    if (!this.authorizationService.isAllowed('USR', ['CREATE', 'UPDATE'])) {
      this.usersConfigurationFormGroup.disable();
      this.isReadOnly = true;
    }
  }

  private buildUserConfigurationFormToAdd(configuredUser: ConfiguredUser): void {
    this.usersConfigurationFormGroup = this.formBuilder.group({
      id: new UntypedFormControl(configuredUser.id),
      name: new UntypedFormControl(configuredUser.name, { validators: [Validators.required] }),
      username: new UntypedFormControl(configuredUser.userName, { validators: [Validators.required] }),
      userGroups: new UntypedFormControl(configuredUser.userGroups, { validators: [Validators.required] }),
      status: new UntypedFormControl(UserStatus.ACTIVE, { validators: [Validators.required] }),
      startDate: new UntypedFormControl(configuredUser.startDate, { validators: Validators.required }),
      endDate: new UntypedFormControl(configuredUser.endDate, { validators: Validators.required })
    });
  }

  private buildEmptyUserDetails(): ConfiguredUser {
    const configuredUser = new ConfiguredUser();
    configuredUser.id = null;
    configuredUser.name = null;
    configuredUser.userName = null;
    configuredUser.userGroups = [];
    configuredUser.active = true;
    configuredUser.startDate = new Date().valueOf();
    configuredUser.endDate = null;
    return configuredUser;
  }

  private getAllUserGroups() {
    this.multiSelectItems.next(this.userGroupInfo);
    this.isApiServiceCalled = true;
    this.operationStatusMessage = 'Loading all user groups';
    this.configuredUserGroupService.getActiveUserGroups(this.parentId, 'active=true&limited=true').subscribe(
      (res: any) => {
        this.userGroupDetails = res.result.map( obj => ({
          parentId: obj.parentId ? obj.parentId : obj.partitionKey,
          name: obj.name,
          id: obj.id }));
        this.multiSelectItems.next(this.userGroupDetails);
        this.isApiServiceCalled = false;
      }
    );
  }

}
