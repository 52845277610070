import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ae-radio-group',
  templateUrl: './ae-radio-group.component.html',
  styleUrls: ['./ae-radio-group.component.scss']
})
export class AeRadioGroupComponent {

  @Input()
  radioItems: any[];

  @Input()
  orientation: string;

  @Input()
  label: string;

  @Input()
  name: string;

  @Input()
  formControlName: string;

  @Input()
  callback: any;

  @Input()
  formGroup: UntypedFormGroup;

}
