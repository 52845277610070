
<form class="Core-Form" [formGroup]="formGroup">
    <coreui-form-field [label]="label">
        <coreui-select [formControlName]="multiSelectFormControlName" [placeholder]="placeholder" [multiple]="true" (blur)="onSelectBlur()" [disabled]="isDisabled">
            <ng-template #multipleSelectedTpl let-selectedOptions="selectedOptionsCount">
                {{selectedOptions}} options selected
            </ng-template>
            <coreui-option *ngFor="let itm of multiSelectedItems | async" [value]="itm">{{itm.name}}</coreui-option>
            <coreui-menu-item-divider *ngIf="multiSelectedItems.getValue().length > 0"></coreui-menu-item-divider>
            <coreui-option *ngFor="let item of multiFilteredItems | async" [value]="item">{{item.name}}</coreui-option>
        </coreui-select>
    </coreui-form-field>
</form>