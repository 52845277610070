import { Component, EventEmitter, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ProgramData } from 'src/app/shared/models/program-data';
import { EventRecord, ProgramSharedDataService } from '../program-shared-data.service';
import { Router } from '@angular/router';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { DisplayTextService } from 'src/app/shared/services/display-text.service';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { WarningType } from 'src/app/shared/warning-options';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { CustomProgramBuilderService } from 'src/app/shared/services/custom-program-builder.service';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { Operation } from 'src/app/shared/operation';
import { ProgramMode } from 'src/app/shared/program-mode';
import { ServiceLocator } from 'src/app/shared/services/service-locator';
import { PackagedProgramService } from 'src/app/shared/services/packaged-program.service';
import { Program } from 'src/app/shared/models/program';
import { ConfiguredProgramTestDataMap } from 'src/app/shared/models/configured-program-test-data/configured-program-test-data-map';
import { TestDataSet } from 'src/app/shared/models/configured-progra-test-data/test-data-set.model';
import { Constants, RuleTypes, Action } from 'src/app/shared/constants';
import { EventTypeSchemaMapper } from 'src/app/shared/event-type-schema-mapper';
import { EventService } from 'src/app/shared/services/event.service';
import { ConfiguredEvent } from 'src/app/shared/models/configured-event.model';
import { Messages } from 'src/app/shared/message';
import { JSONFileService } from 'src/app/shared/services/json-file-service';
import { EventSchemaService } from 'src/app/shared/services/event-schema.service';
import { PackagedEventSourceName } from 'src/app/shared/models/event-source';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { AEModalInfoComponent } from 'src/app/shared/component/modal/ae-modal-info/ae-modal-info.component';
import { ProgramType } from 'src/app/shared/program-type';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { ActionType } from '../../../shared/models/permission/feature/action-type';
import { BaseFormDirective } from '../../../shared/models/base-form-configuration/base-form.directive';
import { CustomRule } from 'src/app/shared/models/custom-rule/custom-rule';
import { ConfiguredSchedule } from 'src/app/shared/models/configured-schedule/configured-schedule.model';
import { ConfiguredScheduleService } from 'src/app/shared/services/configured-schedule.service';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { ProgramStatus } from 'src/app/shared/program-status';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceResponse } from 'src/app/shared/models/service-response';
import { ScheduleAction } from 'src/app/shared/models/configured-schedule/schedule-action';
import { ScheduleStatus } from 'src/app/shared/models/configured-schedule/schedule-status';
import * as moment from 'moment';

export let InjectorInstance: Injector;
@Component({
  selector: 'app-program-schedule',
  templateUrl: './program-schedule.component.html'
})
export class ProgramScheduleComponent extends BaseFormDirective implements OnInit, OnDestroy {

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  public warningModal: CoreuiModalWarningComponent;

  @ViewChild(AEModalInfoComponent, { static: true })
  public infoModal: AEModalInfoComponent;

  public selectedMode: string = ProgramMode.TEST;
  public isEventAttrPresent = true;
  public isApiCallForNameUpdate: boolean;
  isEdit = false;
  modeList = new Map<string, string>();
  programData: ProgramData;
  programInstance: any;
  actionDestinations: any = [];
  public testDatasetList: TestDataSet[] = [];
  public DB_KEY_SEPARATOR = Constants.DB_KEY_SEPARATOR;
  public isReadOnly = false;
  public maxDataSetAllowed = 10;
  public mode = '';
  public eventSourceProviders = new Map<string, string>();
  public eventSourceEventMap: Map<string, string[]>;
  public eventTypeSchemaMapper: EventTypeSchemaMapper;
  public eventMap: Map<string, string>;
  public configuredEvents: ConfiguredEvent[] = [];
  public eventChildEventMap: Map<string, string[]>;
  public eventSourceRuleMap: Map<string, string[]>;
  public programTestDatasetPayload = { mode: null, testData: {}, configuredProgramTestDataMap: null };

  public isActivateInProgress = new BehaviorSubject<boolean>(false);
  public isDeactivateInProgress = new BehaviorSubject<boolean>(false);
  public buttonDisplayText = '';
  public isActivateEnabled: boolean;
  public isUpdateSaved: boolean;
  public eventAttrVal;
  public eventSchemaService: EventSchemaService;
  public eventSchemaMap = new Map<string, EventRecord>();
  public configuredSchedules: ConfiguredSchedule[];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private eventSchemaDataIndex: string;
  public configuredSchedule: ConfiguredSchedule;
  private isConfiguredScheduleModalShown: boolean;
  private configureScheduleAction: string;


  constructor(
    private fb: UntypedFormBuilder,
    public programDataService: ProgramSharedDataService,
    private router: Router,
    private programService: ProgramService,
    private popupService: PopupMessageService,
    public displayTexts: DisplayTextService,
    private customProgramBuilderService: CustomProgramBuilderService,
    private actionDestinationService: ActionDestinationService,
    private packagedProgramService: PackagedProgramService,
    private eventService: EventService,
    public injector: Injector,
    public jsonFileService: JSONFileService,
    public modalService: NgbModal,
    public authorizationService: AuthorizationService,
    private configuredScheduleService: ConfiguredScheduleService) {
    super();
    InjectorInstance = this.injector;
    this.eventTypeSchemaMapper = new EventTypeSchemaMapper(jsonFileService);
    this.eventSchemaService = new EventSchemaService(jsonFileService);
  }

  public ngOnInit(): void {
    this.programDataService.canNavigateAway = false;
    this.programDataService.currentApiFlag.subscribe(isApiCallForNameUpdate => this.isApiCallForNameUpdate = isApiCallForNameUpdate);
    this.testDatasetList = [];
    this.eventMap = new Map<string, string>();
    this.eventChildEventMap = new Map<string, string[]>();
    this.eventSourceRuleMap = new Map<string, string[]>();
    this.programData = this.programDataService.getProgramData();
    this.eventSchemaMap = this.programDataService.getEventSchemaMap();
    this.programInstance = ServiceLocator.getProgramService(this.programData.tenantProgram.programType, this.injector);
    this.initializeMode();
    this.setButtonDisplayText();
    if (this.programData.operation === Operation.ADD) {
      this.programDataService.programData.tenantProgram.mode = this.selectedMode;
    } else if (this.programData.operation === Operation.EDIT || this.programData.operation === Operation.VALIDATE) {
      this.isActivateEnabled = true;
      this.isUpdateSaved = true;
      if (this.programData.tenantProgram.programType === undefined
        || this.programDataService.programData.packagedRuleSettings === undefined) {
        this.fetchConfiguredProgram();
      } else {
        this.isReadOnly = this.programDataService.isReadOnly();
        if (this.programData.tenantProgram.isPackagedProgram() || this.programData.tenantProgram.isCustomWithExternalPackagedRules()) {
          this.getPackagedProgramInternalEvents();
        }
        this.initializeTestDataSet();
        this.selectedMode = this.programDataService.programData.tenantProgram.mode;
        this.getActiveSchedulesForProgram();
      }
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  }

  private getActiveSchedulesForProgram(): void {
    const configuredSchedule: ConfiguredSchedule = new ConfiguredSchedule();
    configuredSchedule.featureId = this.programData.programId;
    configuredSchedule.feature = this.programData.tenantProgram.getFeature();
    this.getActiveConfiguredSchedulesByFeature(configuredSchedule);
  }

  public canNavigateAway(): boolean {
    if (this.isEdit && !this.programData.tenantProgram.active && !this.programDataService.isApiServiceCalled && this.isEditFormUpdated() &&
      !this.programDataService.isDeleteOperation) {
      this.programDataService.canNavigateAway = false;
      return false;
    } else {
      this.programDataService.canNavigateAway = true;
      return true;
    }
  }

  public isEditFormUpdated(): boolean {
    const configuredProgramTestDataMap = new ConfiguredProgramTestDataMap();
    configuredProgramTestDataMap.getTestDataMapFromDatasetList(this.testDatasetList);
    return JSON.stringify(configuredProgramTestDataMap.toJson())
      !== JSON.stringify(this.programData.tenantProgram.configuredProgramTestDataMap.toJson())
      || this.programData.tenantProgram.mode !== this.mode || !this.programDataService.header.canDeactivate();
  }

  public openEventSchemaPopup(dataSetIndex: string): void {
    const eventName = this.testDatasetList[dataSetIndex].event;
    this.eventSchemaMap = this.programDataService.getEventSchemaMap();
    this.testDatasetList[dataSetIndex].sampleEvent = this.eventSchemaMap.get(eventName).sample;
    this.eventSchemaDataIndex = dataSetIndex;
    this.launchModal();
  }

  public onChangeEvent(eventType, dataSetIndex): void {
    this.isActivateEnabled = false;
    this.testDatasetList[dataSetIndex].eventAttribute = '';
    this.testDatasetList[dataSetIndex].isInvalidEventAttribute = false;
  }

  public onChangeEventSource(eventSourceProvider, dataSetIndex): void {
    this.isActivateEnabled = false;
    this.testDatasetList[dataSetIndex].event = '';
    this.testDatasetList[dataSetIndex].eventAttribute = '';
    this.testDatasetList[dataSetIndex].eventOptions = this.eventSourceRuleMap.get(eventSourceProvider);
    this.testDatasetList[dataSetIndex].isInvalidEventAttribute = false;
  }

  public initializeTestDataSet() {
    this.programDataService.programData.tenantProgram = Object.assign(new ConfiguredProgram(), this.programData.tenantProgram);
    this.eventSourceRuleMap = this.programDataService.programData.tenantProgram.getEventSourceEventTypesMap();
    Array.from(this.eventSourceRuleMap.keys()).forEach(key => {
      this.eventSourceProviders.set(key, key === PackagedEventSourceName.CONVERSANT ? PackagedEventSourceName.EPSILON_TAG : key);
    });
    this.testDatasetList = this.programDataService.programData.tenantProgram.getTestDataset();
    if (this.programDataService.programData.tenantProgram.isCustomWithExternalRules()
      || this.programDataService.programData.tenantProgram.isCustomProgram()
      || this.programDataService.programData.tenantProgram.isCustomWithExternalPackagedRules()) {
      this.programDataService.isApiServiceCalled = true;
      this.eventService.getConfiguredEvents(this.programData.parentId).subscribe(
        (response: any) => {
          this.configuredEvents = response['result'];
          this.configuredEvents.forEach(configEvent => {
            const cofiguredEvent = new ConfiguredEvent(configEvent);
            const sampleEvent = cofiguredEvent.getSampleEvent();
            if (sampleEvent) {
              const eventKey = configEvent.id.eventSourceName + this.DB_KEY_SEPARATOR + configEvent.id.eventName;
              this.eventMap.set(eventKey, sampleEvent);
            }
          });
          this.programDataService.isApiServiceCalled = false;
        },
        (error: any) => {
          this.programDataService.isApiServiceCalled = false;
        });
    }
  }

  public addNewDataSet() {
    if (!this.isActivateEnabled) {
      this.isUpdateSaved = false;
    } else {
      this.isActivateEnabled = false;
    }
    this.testDatasetList.push(new TestDataSet());
  }

  public deleteDataSet(dataSetIndex) {
    if (this.testDatasetList[dataSetIndex].isPersisted) {
      this.isActivateEnabled = false;
    } else if (this.isUpdateSaved) {
      this.isActivateEnabled = true;
    }
    this.testDatasetList.splice(dataSetIndex, 1);
  }

  public isButtonEnabled(): boolean {
    if (this.programData.tenantProgram.isPackagedProgram()) {
      return this.authorizationService.isAllowed('PKG_PRG', [ActionType.CREATE, ActionType.UPDATE]);
    } else if (this.programData.tenantProgram.isCustomWithExternalRules() || this.programData.tenantProgram.isCustomWithExternalPackagedRules()) {
      return this.authorizationService.isAllowed('CUST_PRG', [ActionType.CREATE, ActionType.UPDATE]);
    }
    return false;
  }

  public initializeMode() {
    this.modeList.set(ProgramMode.TEST, 'Test');
    this.modeList.set(ProgramMode.LIVE, 'Live');
  }

  public fetchConfiguredProgram() {
    this.programDataService.isApiServiceCalled = true;
    this.programService.getConfiguredProgramDetails(this.programData.parentId, this.programData.programId).subscribe(
      (res: any) => {
        this.programDataService.programData.tenantProgram = new ConfiguredProgram(res);
        this.mode = this.programDataService.programData.tenantProgram.mode;
        this.programInstance = ServiceLocator.getProgramService(
          this.programDataService.programData.tenantProgram.programType, this.injector);
        this.isReadOnly = this.programDataService.isReadOnly();
        if (this.programDataService.programData.tenantProgram.isCustomProgram()) {
          const rule = JSON.parse(res.result.configuration.rule);
          this.programDataService.programData.rules = rule['actions'];
        } else if (this.programDataService.programData.tenantProgram.isCustomWithExternalRules()
          || this.programDataService.programData.tenantProgram.isCustomWithExternalPackagedRules()) {
          if (this.programDataService.programData.tenantProgram.mode === null) {
            this.programDataService.programData.tenantProgram.mode = ProgramMode.TEST;
          }
          this.setCustomActionDestinationsForProgram();
        }
        this.initializeTestDataSet();
        if ((this.programData.tenantProgram.isPackagedProgram() || this.programData.tenantProgram.isCustomWithExternalPackagedRules())
          && !this.programDataService.programData.packagedRuleSettings) {
          let eventSource = '';
          if (this.programData.tenantProgram.isPackagedProgram()) {
            eventSource = this.programData.eventSource;
          } else {
            const rules: CustomRule[] = JSON.parse(this.programData.tenantProgram.configuration.rules);
            const packagedRule = rules.find(rule => rule.type === RuleTypes.PACKAGED);
            eventSource = packagedRule.eventKey.eventSourceName;
          }
          this.doGetPackagedProgram(this.programData.parentId, eventSource, this.programData.tenantProgram.packagedProgramName);
        }
        this.setButtonDisplayText();
        this.selectedMode = this.programDataService.programData.tenantProgram.mode;
        this.programDataService.isApiServiceCalled = false;
        this.getActiveSchedulesForProgram();
      },
      (error: any) => {
        const errorMessage: string = error.error.statusMessage;
        if (errorMessage === 'NOT_FOUND') {
          this.programData.isUnsupportedProgram = true;
        }
        this.programDataService.isApiServiceCalled = false;
      });
  }

  setCustomActionDestinationsForProgram() {
    this.programDataService.isApiServiceCalled = true;
    this.actionDestinationService.getActionDestinations(
      this.programDataService.programData.parentId).pipe(takeUntil(this.destroyed$)).subscribe(data => {
        const allActionDestinationsForParent: any[] = data['result'];
        if (allActionDestinationsForParent !== null && allActionDestinationsForParent.length > 0) {
          this.programDataService.setProgramActionDestinationsFrom(allActionDestinationsForParent);
        }
        this.programDataService.isApiServiceCalled = false;
      }, () => {
        this.programDataService.isApiServiceCalled = false;
      });
  }

  public doGetPackagedProgram(parentId: string, eventSourve: string, packagedProgramName: string): void {
    this.programDataService.isApiServiceCalled = true;
    this.packagedProgramService.getProgramDetails(parentId, eventSourve, packagedProgramName).subscribe(
      (res: any) => {
        const programDetails: Program = new Program(res);
        this.programDataService.programData.packagedRuleSettings = programDetails.packagedRuleSettings;
        this.getPackagedProgramInternalEvents();
        this.initializeTestDataSet();
        this.programDataService.isApiServiceCalled = false;
      },
      (error: any) => {
        const errorMessage: string = error.error.statusMessage as string;
        if (errorMessage === 'NOT_FOUND') {
          this.programData.isUnsupportedProgram = true;
        }
        this.programDataService.isApiServiceCalled = false;
      });
  }

  public getPackagedProgramInternalEvents() {
    const eventChildRuleIdsMap: Map<string, string[]> = new Map<string, string[]>();
    this.programDataService.programData.packagedRuleSettings.forEach(ruleSettings => {
      if (ruleSettings.relatedRulesSettings) {
        ruleSettings.relatedRulesSettings.forEach(relatedRules => {
          eventChildRuleIdsMap.set(ruleSettings.eventType, relatedRules.packagedRuleIds);
        });
      }
    });
    eventChildRuleIdsMap.forEach((childRuleIds, parentEvent) => {
      childRuleIds.forEach(childRuleId => {
        const eventValues = [];
        this.programDataService.programData.packagedRuleSettings.forEach(ruleSettings => {
          if (ruleSettings.ruleId === childRuleId) {
            if (this.eventChildEventMap.has(parentEvent)) {
              const childEvents = this.eventChildEventMap.get(parentEvent);
              childEvents.push(ruleSettings.eventType);
              this.eventChildEventMap.set(parentEvent, childEvents);
            } else {
              this.eventChildEventMap.set(parentEvent, new Array(ruleSettings.eventType));
            }
          }
          eventValues.push(ruleSettings.eventType);
        });
        this.eventSourceRuleMap.set(this.programData.tenantProgram.configuration.eventSource, eventValues);
      });
    });
    this.mode = this.programDataService.programData.tenantProgram.mode;
  }

  public saveProgram() {
    if (this.areAllTestDataSetValid()) {
      const configuredProgramTestDataMap: ConfiguredProgramTestDataMap = new ConfiguredProgramTestDataMap();
      configuredProgramTestDataMap.getTestDataMapFromDatasetList(this.testDatasetList);
      this.programTestDatasetPayload.mode = this.programDataService.programData.tenantProgram.mode;
      this.mode = this.selectedMode;
      this.programTestDatasetPayload.configuredProgramTestDataMap = configuredProgramTestDataMap.toJson();
      const programInstance = ServiceLocator.getProgramService(this.programData.tenantProgram.programType, this.injector);
      programInstance.updateTestRecipients(this.programTestDatasetPayload, this.programData.parentId,
        this.programData.programId, programInstance.updatetestdataURI).subscribe(
          serviceResponse => {
            this.programDataService.programData.tenantProgram.mode = this.selectedMode;
            const tenantProgram = new ConfiguredProgram(serviceResponse);
            this.programData.tenantProgram.configuredProgramTestDataMap = tenantProgram.configuredProgramTestDataMap;
            this.initializeTestDataSet();
            this.popupService.showSuccessMessage(Messages.saveModeAndDatasetSuccessMessage);
            this.isUpdateSaved = true;
            this.isActivateEnabled = true;
          }, failureReason => {
            this.popupService.showErrorMessage(Messages.saveModeAndDatasetFailureMessage);
          });
    } else {
      this.popupService.showErrorMessage(Messages.datasetValidationErrorMessage);
    }
  }

  public changeMode(selectedMode: string): void {
    if (this.programDataService.programData.tenantProgram.mode === selectedMode || selectedMode === null) {
      return;
    }
    this.selectedMode = selectedMode;
    let title; let message; let message2;
    if (this.selectedMode === 'TEST') {
      title = Messages.testStatus;
      message = Messages.testModeMsg;
      message2 = [Messages.confirmTestMode];
    }
    if (this.selectedMode === 'LIVE') {
      title = Messages.liveStatus;
      message = Messages.liveModeMsg;
      message2 = [Messages.confirmLiveMode];
    }
    this.warningModal.forEntity('CHANGE_MODE').launchModal(WarningType.CHANGE_PROGRAM_MODE_WARNING, {
      title: title,
      msg: message,
      msg2: message2
    });
  }

  public handleChangeModeDecision(decision: boolean): void {
    if (decision) {
      this.programDataService.programData.tenantProgram.mode = this.selectedMode;
      this.isActivateEnabled = false;
    } else {
      this.selectedMode = this.programDataService.programData.tenantProgram.mode;
    }
  }

  public onChangeTestRecipientData() {
    this.isActivateEnabled = false;
  }

  public activate(programName, id, isActive, programType) {
    this.programDataService.isApiServiceCalled = true;
    if (!isActive) {
      this.isActivateInProgress.next(true);
      this.activateProgram(programName, id, programType);
    } else {
      this.isDeactivateInProgress.next(true);
      this.deActivateProgram(programName, id, programType);
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public handleDecision(decision: boolean): void {
    if (this.warningModal.warningType === WarningType.UNSAVED_CHANGES_WARNING && decision) {
      this.programDataService.header.discardProgramNameChanges();
    }
    switch (this.warningModal.getEntityType()) {
      case 'CHANGE_MODE':
        this.handleChangeModeDecision(decision);
        break;
      case 'ACTIVATE_PROGRAM':
      case 'DEACTIVATE_PROGRAM':
        location.reload();
        break;
    }
  }

  public validateEventAttribute(dataSetIndex: number): void {
    this.testDatasetList[dataSetIndex].isInvalidEventAttribute = false;
    const datasetToValidate = this.testDatasetList[dataSetIndex];
    this.testDatasetList.forEach((dataset, index) => {
      if (dataSetIndex !== index && datasetToValidate.isEventAttributeExist(dataset)) {
        this.testDatasetList[dataSetIndex].isInvalidEventAttribute = true;
      } else if (dataSetIndex === index && datasetToValidate.eventAttribute !== '') {
        const eventName = this.testDatasetList[dataSetIndex].event;
        this.eventSchemaMap = this.programDataService.getEventSchemaMap();
        this.isEventAttrPresent = this.eventSchemaService.validateEventAttributeInSchema(
          (this.eventSchemaMap.get(eventName).schema), (datasetToValidate.eventAttribute).substring(2));
      }
    });
  }

  private launchModal(): void {
    this.infoModal.launchModal({
      'title': 'Event Schema',
      'description': '',
      'content': {
        'type': 'PLAIN_TEXT',
        'data': this.testDatasetList[this.eventSchemaDataIndex].sampleEvent
      }
    });
  }

  private areAllTestDataSetValid(): boolean {
    let areAllTestDataValidFlag = true;
    for (const dataSet of this.testDatasetList) {
      if (!dataSet.isValidTestDataset() || !this.isEventAttrPresent) {
        areAllTestDataValidFlag = false;
      }
    }
    return areAllTestDataValidFlag;
  }

  private activateProgram(programName, id, programType) {
    const programInstance = ServiceLocator.getProgramService(programType, this.injector);
    this.findNewlyAddedActionlessRulesInTemplate().subscribe((missingRules: Array<Record<string, any>>) => {
      programInstance.activateProgram(this.programData.parentId, id, missingRules).subscribe(
        () => {
          this.activateProgramSuccess(programName);
        }, (error: any) => {
          this.programDataService.isApiServiceCalled = false;
          this.activateProgramFailure(error);
        }
      );
    });
  }

  private deActivateProgram(programName, programId, programType) {
    const programInstance = ServiceLocator.getProgramService(programType, this.injector);
    programInstance.deactivateProgram(this.programData.parentId, programId).subscribe(
      () => {
        this.deactivateProgramSuccess(programName);
      }, (error: any) => {
        this.programDataService.isApiServiceCalled = false;
        this.deactivateProgramFailure(error);
      }
    );
  }

  private activateProgramSuccess(programName: string) {
    this.isActivateInProgress.next(false);
    this.warningModal.forEntity('ACTIVATE_PROGRAM').launchModal(WarningType.ALERT, {
      title: Messages.activateProgram,
      msg: `${programName} has been activated successfully.`
    });
    this.programDataService.isApiServiceCalled = false;
  }

  private activateProgramFailure(error) {
    this.isActivateInProgress.next(false);
    if (error.status === 403) {
      this.popupService.setByResponse(error);
    } else if (error.status === 404) {
      const errorMessage = error.error.result as string;
      this.showErrorPopup(errorMessage);
    } else {
      let errorMessage = 'Program activation not successful';
      if (error.error.result !== undefined && error.error.statusMessage === 'FAILED') {
        errorMessage = error.error.result;
      }
      this.showErrorPopup(errorMessage);
    }
  }

  private deactivateProgramSuccess(programName: string) {
    const activateMsg: string[] = [];
    activateMsg.push('Deactivation of Configured Program ' + programName + ' completed successfully.');
    this.isDeactivateInProgress.next(false);
    this.warningModal.forEntity('DEACTIVATE_PROGRAM').launchModal(WarningType.ALERT, {
      title: Messages.deactivateProgram,
      msg: `${programName} has been de-activated successfully.`
    });
    this.programDataService.isApiServiceCalled = false;
  }

  private deactivateProgramFailure(error) {
    this.isDeactivateInProgress.next(false);
    const activateMsg: string[] = [];
    if (error.status === 403) {
      this.popupService.setByResponse(error);
    } else {
      this.showErrorPopup(Messages.deactivateProgramFailure, activateMsg);
    }
  }

  private showErrorPopup(errorMessage: string, msg2: Array<any> = []) {
    this.warningModal.launchModal(WarningType.ALERT, {
      title: Messages.errorTxt,
      msg: errorMessage,
      msg2: msg2
    });
  }

  private setButtonDisplayText() {
    if (this.programData.tenantProgram.active) {
      this.buttonDisplayText = 'Deactivate Program';
    } else {
      this.buttonDisplayText = 'Activate Program';
    }
  }

  private findNewlyAddedActionlessRulesInTemplate(): Observable<any> {
    if (this.programData.tenantProgram.programType !== ProgramType.PACKAGED) {
      return of([]);
    }
    this.programDataService.isApiServiceCalled = true;
    const missingPackagedRules = new EventEmitter<any>();
    this.packagedProgramService.getProgramDetails(this.programData.parentId, this.programData.eventSource, this.programData.tenantProgram.packagedProgramName)
      .subscribe(result => {
        const newRules = this.programData.tenantProgram.findNewlyAddedEmptyActionRulesFromTemplate(new Program(result));
        missingPackagedRules.next(newRules);
        this.programDataService.isApiServiceCalled = false;
      });
    return missingPackagedRules;

  }

  private buildConfiguredSchedule(): ConfiguredSchedule {
    this.configuredSchedule = new ConfiguredSchedule();
    this.configuredSchedule.featureId = this.programData.tenantProgram.id;
    this.configuredSchedule.feature = this.getProgramType();
    return this.configuredSchedule;
  }

  private getProgramType(): Feature {
    if (this.programData.tenantProgram.programType === ProgramType.PACKAGED) {
      return Feature.PKG_PRG;
    }
    return Feature.CUST_PRG;
  }

  public getConfiguredProgramName(): string {
    return this.programData.tenantProgram.name;
  }

  public getConfiguredProgramStatus(): string {
    if (this.programData.tenantProgram.active) {
      return ProgramStatus.ACTIVE;
    }
    return ProgramStatus.INACTIVE;
  }

  public getConfiguredSchedule(): ConfiguredSchedule {
    return this.configuredSchedule;
  }

  public onScheduleAction(action: string): void {
    if (action === Action.CREATE.toLowerCase()) {
      this.handleCreateScheduleAction();
    }
  }

  public handleCreateScheduleAction(): void {
    this.configureScheduleAction = Action.CREATE;
    this.buildConfiguredSchedule();
    this.isConfiguredScheduleModalShown = true;
  }

  public isConfiguredScheduleModalDisplayed(): boolean {
    return this.isConfiguredScheduleModalShown;
  }

  public getActionType(): string {
    return this.configureScheduleAction;
  }

  public isReadWriteAccessAllowed(): boolean {
    if (this.authorizationService.isAllowed('PKG_PRG', ['CREATE', 'UPDATE'])
      || this.authorizationService.isAllowed('CUST_PRG', ['CREATE', 'UPDATE'])) {
      return true;
    }
    return false;
  }

  public getScheduledZone(): string {
    return "UTC";
  }

  public setScheduleConfigurationModalStatus($event: boolean): void {
    this.isConfiguredScheduleModalShown = $event;
  }

  public captureConfiguredScheduleAction(result: { action: string; configuredSchedule: ConfiguredSchedule }): void {
    if (!result) {
      return;
    }
    switch (result.action) {
      case Action.CREATE:
        this.createConfiguredSchedule(result.configuredSchedule);
        break;
    }
  }

  private createConfiguredSchedule(configuredSchedule: ConfiguredSchedule): void {
    this.programDataService.isApiServiceCalled = true;
    this.configuredSchedules = [];
    this.configuredScheduleService.createConfiguredSchedule(this.programData.parentId, configuredSchedule)
      .pipe(takeUntil(this.destroyed$)).subscribe(result => {
        this.configuredSchedules.push(new ConfiguredSchedule(result.result));
        this.popupService.showSuccessMessage(`Schedule ${Constants.SUCCESSFULLY_CREATED}`);
        this.programDataService.isApiServiceCalled = false;
      }, error => {
        const errorMessage: string = error.error.result.replace('Invalid request payload - [','');
        this.popupService.showErrorMessage(errorMessage);
        this.programDataService.isApiServiceCalled = false;
      });
  }

  private getActiveConfiguredSchedulesByFeature(configuredSchedule: ConfiguredSchedule): void {
    this.configuredScheduleService.getAllConfiguredSchedulesByFeature(this.programData.parentId, configuredSchedule)
      .pipe(takeUntil(this.destroyed$)).subscribe((res: ServiceResponse) => {
        this.configuredSchedules = res.result as ConfiguredSchedule[];
        this.configuredSchedules = this.configuredSchedules.filter(configuredSchedule => configuredSchedule.scheduleStatus === ScheduleStatus.SCHEDULED);
      }, () => {
        this.configuredSchedules = [];
      });
  }

  public onCancelSchedule(configuredSchedule: ConfiguredSchedule): void {
    this.configuredScheduleService.cancelConfiguredSchedule(this.programData.parentId, configuredSchedule)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.popupService.showSuccessMessage(`Schedule ${Constants.SUCCESSFULLY_DELETED}`);
        this.configuredSchedules = this.configuredSchedules.filter(schedule => schedule.scheduleId != configuredSchedule.scheduleId);
      }, error => {
        this.popupService.showErrorMessage("Failed to delete the schedule");
      });
  }
                                          
  public isSchedulesAvailable(): boolean {
    return this.configuredSchedules != undefined && this.configuredSchedules.length > 0 ? true : false; 
  }

  public getScheduleActionForDisplay(scheduleAction: ScheduleAction): string {
    if (scheduleAction == ScheduleAction.ACTIVATION) {
      return "live";
    }
    return "inactive";
  }

  public getDisplayDate(scheduleTime: number): string {
    return moment(this.epochTimeToDate(scheduleTime)).format('L');
  }

  public getDisplayTime(scheduleTime: number): string {
    return moment.utc(scheduleTime).format('LT');
  }

  private epochTimeToDate(scheduleTime: number): number {
    const date = new Date(scheduleTime);
    const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    return utc;
  }

  public isProgramInLiveMode(): boolean {
    if (this.programData.tenantProgram.mode === 'LIVE') {
      return true;
    }
    return false;
  }
}