import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';

@Component({
  selector: 'app-user-roles-header',
  templateUrl: './user-roles-header.component.html',
  styleUrls: ['./user-roles-header.component.scss']
})
export class UserRolesHeaderComponent implements OnInit {

  public headerNavTabs = [];

  constructor(private router : Router,
    public authorizationService: AuthorizationService) { }

  ngOnInit(): void {
    if (!this.authorizationService.isAuthorizationEnabled()) {
      if (this.router.url === './admin') {
        void this.router.navigate(['client/admin/usergroups']);
      }
    } else {
      const features = this.authorizationService.getAllowedFeatures('USER_GROUP_ROLES');
      if (this.router.url === './admin') {
        if (features.includes(Feature.USR)) {
          void this.router.navigate(['client/admin/users']);
        } else if (features.includes(Feature.PKG_USR_GRP) || features.includes(Feature.CFG_USR_GRP)) {
          void this.router.navigate(['client/admin/usergroups']);
        } else if (features.includes(Feature.PKG_ROLE) || features.includes(Feature.CFG_ROLE)) {
          void this.router.navigate(['client/admin/roles']);
        }
      }
    }
  }

}
