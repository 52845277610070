import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Messages } from 'src/app/shared/message';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';

@Component({
  selector: 'app-support-header',
  templateUrl: './support-header.component.html',
  styleUrls: ['./support-header.component.scss']
})
export class SupportHeaderComponent implements OnInit {

  public headerNavTabs = [];
  public entityContentTabLabel= Messages.supportPageNavEntityContentLabel;

  constructor(private router : Router,
    public authorizationService: AuthorizationService) { }

  ngOnInit(): void {
    if (!this.authorizationService.isAuthorizationEnabled()) {
      if (this.router.url === './support') {
        void this.router.navigate(['client/support/imslookup']);
      }
    } else {
      const features = this.authorizationService.getAllowedFeatures('SUPPORT');
      if (this.router.url === './support') {
        if (features.includes(Feature.SUPPORT)) {
          void this.router.navigate(['client/support/imslookup']);
        }
      }
    }
  }

}
