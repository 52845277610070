import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Action } from 'src/app/shared/models/action';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { EventTypeSchemaMapper } from '../../event-type-schema-mapper';
import { EventSchemaService } from '../../services/event-schema.service';
import { AEModalInfoComponent } from '../modal/ae-modal-info/ae-modal-info.component';

@Component({
  selector: 'app-delay',
  templateUrl: './delay-by-attribute.component.html'
})
export class DelayByAttributeComponent implements OnInit {

  @Input() public parentForm;
  @Input() public delayTitle: string;
  @Input() public validationRequired: boolean;
  @Input() public delay: string;
  @Input() public selectedEventSchema: string;
  @Input() public selectedConfiguredAction: Action;
  @Input() public index: number;
  @Output() validationStatus = new EventEmitter<boolean>();
  @ViewChild(AEModalInfoComponent, { static: true }) private infoModal: AEModalInfoComponent;

  public parentId: string;
  public eventTypeSchemaMapper: EventTypeSchemaMapper;
  public isEventAttrPresent = true;
  public eventAttrVal: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private parentContext: ParentContextService,
    private eventService: EventSourceService,
    private eventSchemaService: EventSchemaService) { }

  public ngOnInit(): void {
    this.parentForm.addControl(
      'delayByAttribute', new UntypedFormControl(!this.delay ? '' : this.delay.startsWith('$') ? this.delay : '',
        { validators: this.validationRequired ? [Validators.required] : [] }));
    this.parentId = this.parentContext.getParentContext();
    if (!this.selectedEventSchema && this.selectedConfiguredAction) {
      this.eventService.getConfiguredEvents(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(res => {
        const configuredEvents = res['result'];
        const selectedConfiguredEvent = configuredEvents.filter(event => event.name === this.selectedConfiguredAction.eventId.eventName);
        this.selectedEventSchema = selectedConfiguredEvent[0].schema;
      }, () => {
      });
    }
  }

  public openEventSchemaPopup(): void {
    this.infoModal.launchModal({
      'title': 'Event Schema',
      'description': '',
      'content': {
        'type': 'PLAIN_TEXT',
        'data': this.selectedEventSchema
      }
    });
  }

  public getDelay() {
    return this.parentForm.controls.delayByAttribute.value;
  }

  public validateDelayAttribute(attrVal): boolean {
    this.isEventAttrPresent = this.eventSchemaService.validateEventAttributeInSchema(this.selectedEventSchema, (attrVal.target.value).substring(2));
    if (!this.isEventAttrPresent) {
      this.parentForm.controls.delayByAttribute.setErrors({ 'incorrect': true });
    }
    this.validationStatus.emit(this.parentForm.controls.delayByAttribute.invalid);
    return this.isEventAttrPresent;
  }


  public validateDelay(): void {
    this.parentForm.controls.delayByAttribute.markAsTouched();
    this.validationStatus.emit(this.parentForm.controls.delayByAttribute.invalid);
  }

}