import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { BusinessUnit } from 'src/app/shared/models/business-unit';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { ModalComponent } from '@epsilon/core-ui';
import { Constants } from 'src/app/shared/constants';
import { BehaviorSubject } from 'rxjs';
import { Selectable } from 'src/app/shared/models/selectable';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { Messages } from 'src/app/shared/message';

@Component({
  selector: 'app-add-business-unit-modal',
  templateUrl: './add-business-unit-modal.component.html',
  styleUrls: ['./add-business-unit-modal.component.scss']
})
export class AddBusinessUnitPopupComponent implements OnInit {

  @Input()
  public actionDestination: any;
  @ViewChild('documentEditForm', { static: true })
  public documentEditForm: FormGroupDirective;
  @Output()
  private isModalDisplayed = new EventEmitter<any>();
  @Output()
  private configuredBusinessUnit = new EventEmitter<any>();
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;

  public isDuplicateId: boolean;
  public isDuplicateName: boolean;
  public validationStatus: string;
  public loadingMessage: string;
  private multiSelectItems: BehaviorSubject<Selectable[]> = new BehaviorSubject<Selectable[]>([]);
  public company: string = 'company';
  public selectCompanyPlaceHolder: string = 'Select Companies';
  public companyForm: FormGroup = this.fb.group({
    company:  new UntypedFormControl()
  });
  public messages = Messages;

  readonly SALESFORCE_ACTION_DESTINATION = Constants.SALESFORCE_ACTION_DESTINATION;

  private isModalShown: boolean;

  constructor(
    public businessUnit:BusinessUnit,
    private actionDestinationService: ActionDestinationService,
    private popupService: PopupMessageService,
    private fb: UntypedFormBuilder,
    private eventSourceService: EventSourceService
  ) {}

  public ngOnInit(): void {
    this.launchBasicModal();
    this.getCompanies();
    this.loadingMessage = 'Validating credentials';
    this.businessUnit = {
      apiClientId: '',
      apiClientSecret: '',
      businessUnitId: '',
      businessUnitName: '',
      clientPassword: '',
      clientUsername: '',
      company:''
    };
  }

  checkDuplicateBU() {
    this.isDuplicateId = false;
    this.isDuplicateName = false;
    this.actionDestination.buControl.value.forEach((key: any) => {
      if (key.businessUnitId == this.businessUnit.businessUnitId) {
        this.isDuplicateId = true;
      }
      if (key.businessUnitName == this.businessUnit.businessUnitName) {
        this.isDuplicateName = true;
      }
    });
  }

  private getCompanies(): void {
    this.eventSourceService.getCompanies(this.actionDestination.parentId, '').subscribe(
      res => {
        let webCompany = res[Constants.RESULT].find(e => e.eventSource === Constants.WEB);
        let conversantCompany = res[Constants.RESULT].find(e => e.eventSource === Constants.CONVERSANT);
        if(webCompany && conversantCompany) {
          this.multiSelectItems.next(webCompany[Constants.SETTINGS].concat(conversantCompany[Constants.SETTINGS]
            .filter((obj2) => !webCompany[Constants.SETTINGS].some((obj1) => obj1.companyId === obj2.companyId)))
            .map((obj) => ({id: obj.companyId, name: obj.companyName})));
        } else if(conversantCompany) {
          this.multiSelectItems.next(conversantCompany[Constants.SETTINGS].map((obj) => ({id: obj.companyId, name: obj.companyName})));
        } else {
          this.multiSelectItems.next(webCompany[Constants.SETTINGS].map((obj) => ({id: obj.companyId, name: obj.companyName})))
        }
      },
      (error: any) => {
        console.log(error);
      });
  }

  public submitForm(): void {
    this.validationStatus = '';
    if (!this.isDuplicateId && !this.isDuplicateName) {
      this.saveBusinessUnit(this.businessUnit);
    }
  }

  public saveBusinessUnit(businessUnit: BusinessUnit): void {
    this.validationStatus = 'LOADING';
    const buDetailPayload: string = '{ "parentId": "' + this.actionDestination.parentId
      + '", "actionDestination": "' + this.actionDestination.id
      + '", "actionSettings":' + this.getActionSettingsPayload(businessUnit)
      + ' }';
    this.actionDestinationService.saveActionSettingConfiguration(buDetailPayload, this.actionDestination.parentId, this.actionDestination.id)
      .subscribe(
        () => {
          this.validationStatus = '';
          this.businessUnit.company = this.companyForm.controls.company.value;
          this.configuredBusinessUnit.emit(this.businessUnit);
          this.closeBasicModal();
        }, failureReason => {
          if (failureReason.status === 403) {
            this.popupService.setByResponse(failureReason);
            this.closeBasicModal();
          }
          if (failureReason.error.statusMessage == 'FAILED' && failureReason.error.result[0] === 'Authentication error') {
            this.validationStatus = 'INVALID_DATA';
          } else if (failureReason.error.statusMessage == 'FAILED' && failureReason.error.result[0] === 'Invalid BU') {
            this.validationStatus = 'FAILED';
          } else if (failureReason.error.statusMessage == 'FAILED' && failureReason.error.result === Messages.actionDestinationBusinessUnitCompanyAlreadyAssociated) {
            this.validationStatus = 'COMPANY_MAPPED_ERROR';
          } else {
            this.validationStatus = 'OTHER_ERROR';
          }
        });
  }

  private getActionSettingsPayload(businessUnit: BusinessUnit): string {
    let actionSettingsPayload = this.getPayload(businessUnit);
    if (this.actionDestination.id === this.SALESFORCE_ACTION_DESTINATION) {
      actionSettingsPayload[0]["grantType"] = 'client_credentials';
    } else {
      actionSettingsPayload[0]["clientUserName"] = businessUnit.clientUsername;
      actionSettingsPayload[0]["clientPassword"] = businessUnit.clientPassword;
    }
    return JSON.stringify(actionSettingsPayload, null, 0);
  }

  private getPayload(businessUnit: BusinessUnit) {
    let actionSettingsPayload = [{
      buId: businessUnit.businessUnitId,
      buName: businessUnit.businessUnitName,
      apiClientId: businessUnit.apiClientId,
      apiClientPassword: businessUnit.apiClientSecret
    }];
    if (this.companyMapped()) {
      actionSettingsPayload[0]["company"] = this.getCompanyValue(this.companyForm.controls.company.value)
    }
    return actionSettingsPayload;
  }

  private companyMapped() : boolean {
    return this.companyForm.controls.company?.value && this.companyForm.controls.company?.value.length !== 0
  }

  private getCompanyValue(companies : any[]): string {
    return JSON.stringify(companies.map(company => ({
      companyId : company.id,
      companyName : company.name
    })));
  }

  public isDisabled(): boolean {
    return this.businessUnit.apiClientId === ''
    || this.businessUnit.apiClientSecret === ''
    || this.businessUnit.businessUnitId === ''
    || this.businessUnit.businessUnitName === ''
    || (this.actionDestination.id !== this.SALESFORCE_ACTION_DESTINATION && (this.businessUnit.clientPassword === '' || this.businessUnit.clientUsername === ''))
    || this.isDuplicateId || this.isDuplicateName;
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayed.emit(this.isModalShown);
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }
}