<form [formGroup]="tenantSettingsForm">
	<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
	<div class="container-fluid object-page-header">
		<div class="row align-items-center u-py-25 h-10">
			<div class="col">
				<h1 class="pr-4">Packaged Settings</h1>
      </div>
			<div class="col-auto mr-4" *ngIf="doTenantSettingsExists() && authorizationService.isAllowed('CFG_SETTING', ['CREATE','UPDATE'])">
				<app-ae-button buttonText="Save" (click)="saveTenantSettings()" [disabled]="!tenantSettingsForm.dirty"></app-ae-button>
			</div>
		</div>
	</div>
	<app-ae-loading-indicator [isLoading]="isPageLoading" [type]="'loading bar'"></app-ae-loading-indicator>
	<div class="container-fluid mt-3" *ngIf="doTenantSettingsExists()">
		<ng-container formArrayName="tenantSettings">
			<coreui-accordion>
				<ng-container [formGroupName]="featureIndex"
        *ngFor="let feature of tenantSettingsFormArray.controls; let featureIndex=index">
					<ng-container formArrayName="groups">
						<ng-container [formGroupName]="groupIndex" *ngFor="let group of feature.controls.groups.controls; let groupIndex=index">
							<coreui-accordion-pane label="{{ group.controls.title.value }}" [active]="customSettingsResult">
								<div class="card border-color-secondary border-left-0 border-right-0">
									<div class="row">
										<div class="col">
											<div id="card-body-{{featureIndex +'_'+ groupIndex}}" class="card-body collapse show ml-3" *ngIf="!isCollapsed[featureIndex +'_'+ groupIndex]">
												<ng-container formArrayName="settings">
													<ng-container [formGroupName]="settingIndex"
                            *ngFor="let setting of group.controls.settings.controls; let settingIndex=index">
														<div class="border-top" *ngIf="settingIndex > 0"></div>
														<div *ngIf="setting.controls.type.value === 'DURATION'" [ngClass]="[setting.controls.isEdit.value ? 'text-style-muted-1' : '']">
															<div class="row py-1 mx-0 my-1">
																<div class="col-auto px-md-3"> {{ setting.controls.description.value }} </div>
															</div>
															<div class="row py-1 ml-0 my-1 align-items-center">
																<div class="col-auto mt-1 pl-3 px-1">
																	<label for="setting_{{settingIndex}}"></label>
																	<input type="text" class="form-control" formControlName="updatedValue"
                                    id="{{featureIndex +'_'+ groupIndex +'_'+ settingIndex}}" placeholder="Enter delay Ex: PT95M" [readonly]="setting.controls.isEdit.value" required/>
                                </div>
																<div class="col-auto mt-1 pl-2 mr-5">
                                  {{ getSentenceCase(setting.controls.subType.value) }}
                                </div>
																<div class="col-auto ml-auto" *ngIf="authorizationService.isAllowed('CFG_SETTING', ['UPDATE'])">
																	<div *ngIf="setting.controls.isEdit.value">
																		<app-ae-button buttonText="Edit" (click)="setEditButtonValue(featureIndex, groupIndex, settingIndex)"></app-ae-button>
																	</div>
																	<div *ngIf="!setting.controls.isEdit.value && setting.controls.updatedValue.value !== setting.controls.defaultValue.value">
																		<app-ae-button buttonText="Reset" buttonType="secondary" (click)="resetToDefault(featureIndex, groupIndex, settingIndex)"></app-ae-button>
																	</div>
																</div>
															</div>
														</div>
														<div *ngIf="setting.controls.type.value !== 'DURATION'" [ngClass]="[setting.controls.isEdit.value ? 'text-style-muted-1' : '']">
															<div class="row my-3 align-items-center">
																<div class="col-auto ml-3">
																	<label for="description"> {{ setting.controls.description.value }} </label>
																</div>
																<div class="col-auto px-0">
																	<input type="text" class="form-control" formControlName="updatedValue"
                                  id="{{featureIndex +'_'+ groupIndex +'_'+ settingIndex}}" placeholder="Enter value" [readonly]="setting.controls.isEdit.value" required/>
                                </div>
																<div class="col-auto ml-auto" *ngIf="authorizationService.isAllowed('CFG_SETTING', ['UPDATE'])">
																	<div *ngIf="setting.controls.isEdit.value">
																		<app-ae-button buttonText="Edit" (click)="setEditButtonValue(featureIndex, groupIndex, settingIndex)"></app-ae-button>
																	</div>
																	<div *ngIf="!setting.controls.isEdit.value && setting.controls.updatedValue.value !== setting.controls.defaultValue.value">
																		<app-ae-button buttonText="Reset" buttonType="secondary" (click)="resetToDefault(featureIndex, groupIndex, settingIndex)"></app-ae-button>
																	</div>
																</div>
															</div>
														</div>
													</ng-container>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</coreui-accordion-pane>
						</ng-container>
					</ng-container>
				</ng-container>
			</coreui-accordion>
		</ng-container>
	</div>
</form>
