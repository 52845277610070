<ng-container [formGroup]="parentForm">
  <fieldset>
    <h2>{{delayTitle}}</h2>
    <div>
      <label [for]="index + '_delayByAttribute'">Event Attribute</label>
    </div>
    <div class="row mt-2">
      <div class="col-md-7">
        <input
          type="text"
          [id]="index + '_delayByAttribute'"
          formControlName="delayByAttribute"
          class="form-control"
          placeholder="Enter an event attribute"
          (focusout)="validateDelayAttribute($event)"
        />
        <div *ngIf="!parentForm.controls.delayByAttribute.value && (parentForm.controls.delayByAttribute.dirty || parentForm.controls.delayByAttribute.touched)" class="text-color-warning">
          Please enter an event attribute
        </div>
        <ng-container *ngIf="parentForm.controls.delayByAttribute.value && !isEventAttrPresent">
          <p class="text-color-warning">This attribute does not exist in the event schema. Please check the schema and select an existing attribute.</p>
        </ng-container>
      </div>
      <div class="col-auto">
        <app-ae-button [buttonText]="'View Event Schema'" buttonType="text-primary" (click)="openEventSchemaPopup()"></app-ae-button>
      </div>
    </div>
  </fieldset>
  <app-ae-modal-info></app-ae-modal-info>
</ng-container>
