import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { LoginServiceLocator } from 'src/app/shared/services/login.service-locator';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
@Injectable({
  providedIn: 'root'
})
export class AdminGuard extends AuthGuard {
     constructor(public router: Router,
       public loginServiceLocator: LoginServiceLocator,
       public authorizationService: AuthorizationService) { super(loginServiceLocator, router); }
     /**
      * @method canActivate Angular router guard
      * @param next  Router snapshot
      * @param state router state snapshot
      * @return {boolean}
      */
     canActivate(
       next: ActivatedRouteSnapshot,
       state: RouterStateSnapshot
     ): boolean {
        if (!super.canActivate(next, state)) {
          return false;
        }
        if ((!this.authorizationService.isAllowed('PARENT', ['READ']) && next.routeConfig.path.includes('admin/client')) ||
        (!this.authorizationService.isAllowed('PKG_ROLE', ['READ']) && next.routeConfig.path.includes('admin/roles')) ||
        (!this.authorizationService.isAllowed('USR', ['READ']) && next.routeConfig.path.includes('admin/users')) ||
        (!this.authorizationService.isAllowed('PKG_USR_GRP', ['READ']) && next.routeConfig.path.includes('admin/usergroups'))
        ) {
          this.router.navigateByUrl('programs');
          return false;
        }
        return true;
     }
}
