import { ServiceResponse } from '../service-response';
import { UserGroupInfo } from '../permission/user/user-group-info';
import { UserType } from '../../constants';

export class User {

  public id: string;
  public name: string;
  public parentId: string;
  public parentName: string;
  public userName: string;
  public active: boolean;
  public lastLoginDate: number;
  public startDate: number;
  public endDate: number;
  public createdDate: number;
  public modifiedDate: number;
  public modifiedBy: string;
  public userGroups: UserGroupInfo[];
  public userType: UserType;

  public constructor(response?: ServiceResponse) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response.result)));
    }
  }
}