import { JSONFileService } from 'src/app/shared/services/json-file-service';
import { SupportedEventSources } from 'src/app/shared/models/program-simulation/event-source/supported-event-sources';
import { EventSourceBuilder } from 'src/app/shared/models/program-simulation/event-source/event-source-builder';
import { EventType } from 'src/app/shared/models/program-simulation/event-type';

export class EventTypeSchemaMapper {

  private eventTypeSchemaMap : Map<EventType, any> = new Map<EventType, any>();

  constructor(private eventSchemaService: JSONFileService) {
    this.initEventSchema();
  }

  private initEventSchema() {
    Object.values(SupportedEventSources).forEach(supportedEventSource => {
      const eventSource = EventSourceBuilder.build(supportedEventSource);
      eventSource.getEventTypes().forEach(eventType => {
        const eventSchemaLocation = '../../assets/' + eventType.getSchemaFileName();
        this.eventSchemaService.getJSON(eventSchemaLocation).subscribe((data: any): void => {
          this.eventTypeSchemaMap[eventType.getName()] = data;
        });
      });
    });
  }

  public getSchema(eventType: string): any {
    return this.eventTypeSchemaMap[eventType];
  }

}