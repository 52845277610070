<app-base-template>
  <app-ae-loading-indicator [isLoading]="isPageLoading" [type]="'loading bar'"></app-ae-loading-indicator>
  <ng-container class="container-fluid">
    <div class="row">
      <div class="col text-center text-style-action" *ngIf="apiResponse && apiResponse === 'FAILURE'">
        <h2>{{ loginError }}</h2>
      </div>
      <div class="col text-center text-style-action" *ngIf="!apiResponse && (isUrlError || onSessionExpiry())">
        <h2>{{ urlError }}</h2>
      </div>
      <ng-container *ngIf="!isTokenAvailable">
        <iframe #authorize [src]="this.authorizeUrl" (load)="handleAuthorizeResponse()"
          width="0" height="0" frameborder="0">
        </iframe>
      </ng-container>
    </div>
  </ng-container>
</app-base-template>
