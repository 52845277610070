import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleConfigurationComponent } from './role-configuration.component';
import { FormOnSaveAction } from '../../../../shared/constants';
import { Type } from '../../../../shared/models/roleType';
import { Permission } from 'src/app/shared/models/permission/role/permission';
import { PackagedRolesConfigurationComponent } from 'src/app/modules/admin/roles-configuration/packaged-roles-configuration.component';

@Injectable({ providedIn: 'root' })

export class RoleConfigurationCanDeactivateGuard implements CanDeactivate<RoleConfigurationComponent> {

  constructor() {
  }

  canDeactivate(component: RoleConfigurationComponent | PackagedRolesConfigurationComponent): boolean | Promise<boolean> | Observable<boolean> {
    if (component instanceof PackagedRolesConfigurationComponent){
      component = component.roleConfigurationComponent.first;
    }
    if (component.isDeleteOperation) {
      return true;
    }
    if ((component.formOnSaveAction === FormOnSaveAction.CREATE && this.isCreateFormUpdated(component)
        || component.formOnSaveAction === FormOnSaveAction.EDIT && this.isEditFormUpdated(component))) {
      return component.launchUnsavedChangesModal();
    }
    return true;
  }

  private isCreateFormUpdated(component: RoleConfigurationComponent): boolean {
    component.buildConfiguredRole();
    return component.configuredEntityForm.controls.name.value !== '' || component.configuredEntityForm.controls.description.value !== ''
      || !this.arePermissionsDefault(component.role.permissions);
  }

  private isEditFormUpdated(component: RoleConfigurationComponent): boolean {
    return (component.role.description !== component.configuredEntityForm.controls.description.value)
      || this.arePermissionsChanged(component.role.permissions, component.buildPermissions());
  }

  private arePermissionsChanged(savedPermssions: Permission[], latestPermissions: Permission[]): boolean {
    const permissionEqual = (permission1, permission2) => (typeof permission1 === 'object' && Object.keys(permission1).length > 0
      ? Object.keys(permission1).length === Object.keys(permission2).length
      && Object.keys(permission1).every(element => permissionEqual(permission1[element], permission2[element]))
      : permission1 === permission2);
    const permissionsEqual = (permissions1, permissions2) =>
      permissions1.length === permissions2.length && permissions1.every((element, idx) => permissionEqual(element, permissions2[idx]));
    return !permissionsEqual(savedPermssions, latestPermissions);
  }

  private arePermissionsDefault(permssions: Permission[]): boolean {
    const nonDefaultPermission = Array.prototype.find.call(permssions, function(permission) {
      return Array.prototype.find.call(permission.actions, function(action) {
        return action.allowed === true;
      });
    });
    return nonDefaultPermission === undefined;
  }

}