import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, Inject, Input } from '@angular/core';
import { takeUntil, catchError } from 'rxjs/operators';
import { ReplaySubject, of } from 'rxjs';
import { UsersService } from 'src/app/shared/services/users-service-interface';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ServiceResponse } from 'src/app/shared/models/service-response';
import { ConfiguredUserTableRow } from 'src/app/shared/models/user-roles/configured-user-table-row';
import { BaseComponent } from '../../base/base.component';
import { Action, Constants, UserType } from 'src/app/shared/constants';
import { UserStatus } from 'src/app/shared/models/user-status';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { ConfiguredUserGroupService } from '../../../../shared/services/configured-user-group.service';
import { UserGroupInfo } from '../../../../shared/models/permission/user/user-group-info';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { NotificationComponent } from '@epsilon/core-ui';
import { USERS_SERVICE_TOKEN, USER_CLASS_TOKEN } from 'src/app/shared/tokens';
import { User } from 'src/app/shared/models/user-roles/user';
import { APIUser } from 'src/app/shared/models/user-roles/api-user';
import { AeTableSearchComponent } from 'src/app/shared/component/ae-table-search/ae-table-search.component';
import { Messages } from 'src/app/shared/message';
import { ActionStatus } from 'src/app/shared/action-status';
import { ConfiguredUsersService } from 'src/app/shared/services/configured-users.service';
import { AEModalInfoComponent } from 'src/app/shared/component/modal/ae-modal-info/ae-modal-info.component';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent extends BaseComponent implements OnInit {
  @Input() showDropDownOption: boolean = true;

  @ViewChild('statusColumnTemplate', { static: true })
  statusColumnTemplate: TemplateRef<any>;
  @ViewChild('lastLoginDate', { static: true })
  public lastLoginDateColumn: TemplateRef<any>;
  @ViewChild('startDate', { static: true })
  public startDateColumn: TemplateRef<any>;
  @ViewChild('createdDate', { static: true })
  public createDateColumn: TemplateRef<any>;
  @ViewChild('modifiedDate', { static: true })
  public modifiedDateColumn: TemplateRef<any>;
  @ViewChild('formSuccess', { static: true })
  public formSuccess: NotificationComponent;
  @ViewChild('formError', { static: true })
  public formError: NotificationComponent;
  @ViewChild('searchComponent', {static:true})
  public searchComponent: AeTableSearchComponent;
  @ViewChild(AEModalInfoComponent, { static: true })
  public infoModal: AEModalInfoComponent;

  public properties = {};
  public messages = Messages;
  public constants = Constants;
  public paginatedUsers: ConfiguredUserTableRow[] = [];
  public filteredUsers: ConfiguredUserTableRow[] = [];
  public allUsersForParent: ConfiguredUserTableRow[] = [];
  public configuredUsersTableFilteredData = [];
  public configuredUsersTableList = [];
  public loadingIndicator = false;
  public isDataLoading = false;
  public searchByAttribute: string;
  public isSearching = false;
  public endDate: string;
  public successMessage = '';
  public errorMessage = '';
  public userGroupInfo: UserGroupInfo[] = [];
  public userType: string;
  public configuredUsers: User[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private parentId: string;

  private isConfigureUserModalShown: boolean;
  private configureUserAction: string;
  private configureUserId: string;

  public constructor(
    public parentContextService: ParentContextService,
    @Inject(USERS_SERVICE_TOKEN) private usersService: UsersService,
    public configuredUserService: ConfiguredUsersService,
    public configuredUserGroupService: ConfiguredUserGroupService,
    private popupService: PopupMessageService,
    public modalService: NgbModal,
    private datePipe: DatePipe,
    public authorizationService: AuthorizationService) {
    super();
  }

  ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.getAllUserGroups();
    this.initDataTableConfig();
    this.getAllUsers();
  }

  public actionClick(event: {action: string; rowId: string}): void {
    if (event.action === Action.EDIT.toLowerCase()) {
      this.handleEditUserAction(event.rowId);
    } else if (event.action === Action.CREATE.toLowerCase()) {
      this.userType === Constants.UI ? this.handleAddUIUserAction() : this.handleAddAPIUserAction();
    }
  }

  public handleUsersPageChange(pageData: {currentPage: number; rowsPerPage: number; indices: {start: number; end: number}}): void {
    this.pageIndices = { ...pageData.indices };
    this.paginatedUsers = this.filteredUsers.slice(
      pageData.indices.start,
      pageData.indices.end
    );
  }

  public handleAddUIUserAction(): void {
    this.userType = Constants.UI;
    this.handleAddUserAction();
  }

  public handleAddAPIUserAction(): void {
    this.userType = Constants.API;
    this.handleAddUserAction();
  }

  public handleEditUserAction(id: string): void {
    this.userType = this.configuredUsers.find(user => user.id === id).userType;
    this.configureUserAction = Action.EDIT;
    this.configureUserId = id;
    this.isConfigureUserModalShown = true;
  }

  public handleSearch(searchInput: string): void {
    if (this.searchByAttribute !== searchInput) {
      this.searchByAttribute = searchInput.toString();
    }
    this.filterConfiguredUsers();
  }

  public handleClearSearch(): void {
    this.searchByAttribute = '';
    this.initConfiguredUsersTableList();
  }


  public handleUserSort(sort): void {
    this.paginatedUsers = this.handleSort(sort, this.filteredUsers);
  }

  public isConfigureUserModalDisplayed(): boolean {
    return this.isConfigureUserModalShown;
  }

  public getAction(): string {
    return this.configureUserAction;
  }

  public getConfiguredUsers(): User[] {
    return this.configuredUsers;
  }

  public getId(): string {
    return this.configureUserId;
  }

  public setConfigureUserModalDisplayStatus($event: boolean): void {
    this.isConfigureUserModalShown = $event;
  }

  public captureConfiguredUserFromModal(result: { action: string; user: User }): void {
    if (result.action === Action.CREATE) {
      this.createUIUser(result.user);
    }
    if (result.action === Action.EDIT) {
      this.editUser(result.user);
    }
  }

  public captureAPIUserFromModal(result: { action: string; apiUser: APIUser }): void {
    if (result.action === Action.CREATE) {
      this.createAPIUser(result.apiUser);
    }
  }

  private initDataTableConfig() {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          editAction: {
            action: 'edit',
            element: 'a',
            text: '#{name}',
            ariaLabel: 'Edit #{id}',
            class: 'link-primary',
            modal: true
          }
        },
        {
          headerText: 'Username',
          key: 'id',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Status',
          key: 'status',
          isSortable: true,
          isColumnDisplayed: true,
          statusIndicatorMapping: {
            'incomplete-primary': ActionStatus.INACTIVE,
            'complete': ActionStatus.ACTIVE
          }
        },
        {
          headerText: 'User Type',
          key: 'userType',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Last Login Date',
          key: 'lastLoginDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.lastLoginDateColumn
        },
        {
          headerText: 'Start Date',
          key: 'startDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.startDateColumn
        },
        {
          headerText: 'End Date',
          key: 'endDate',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.modifiedDateColumn
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }


  private handleAddUserAction(): void {
    this.configureUserAction = Action.CREATE;
    this.configureUserId = '';
    this.isConfigureUserModalShown = true;
  }

  private createUIUser(user: User): void {
    this.isDataLoading = true;
    this.loadingIndicator = true;
    user.parentId = this.parentId;
    user.userType = UserType.UI;
    user.parentName = this.parentContextService.getParentContext();
    this.usersService.addUIUser(
      user.parentId, user).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.showSuccessMessage('User "' + user.name + '" successfully Added');
        this.getAllUsers();
      }, error => {
        this.isDataLoading = false;
        this.loadingIndicator = false;
        if (error.error.statusMessage === 'DUPLICATE_ITEM') {
          this.showErrorMessage('Another user with the username "' + error.error.result.name + '" already exists.');
        } else {
          this.showErrorMessage(error.error.statusMessage);
        }
      }
    );
  }

  private createAPIUser(apiUser: APIUser): void {
    this.isDataLoading = true;
    this.loadingIndicator = true;
    apiUser.parentId = this.parentId;
    apiUser.userType = UserType.API;
    apiUser.userId = apiUser.id;
    this.configuredUserService.addAPIUser(
      apiUser.parentId, apiUser).pipe(takeUntil(this.destroyed$)).subscribe(
      (response) => {
        let record:Record<string, any > = response.customStatusMessages;
        this.apiUserPopUp(record);
        this.getAllUsers();
      }, error => {
        this.isDataLoading = false;
        this.loadingIndicator = false;
        if (error.error.statusMessage === 'DUPLICATE_ITEM') {
          this.showErrorMessage('Another user with the id "' + apiUser.id + '" already exists.');
        } else {
          this.showErrorMessage(error.error.statusMessage);
        }
      }
    );
  }

  private editUser(user: User): void {
    this.isDataLoading = true;
    this.loadingIndicator = true;
    this.usersService.updateUser(
      this.parentId, user).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.showSuccessMessage('User "' + user.name + '" successfully updated');
        this.getAllUsers();
      }, error => {
        if (error.error.statusMessage === 'NOT_FOUND') {
          this.showErrorMessage('User with username "' + user.name + '" does not exist');
        } else {
          this.showErrorMessage(error.error.statusMessage);
        }
        this.getAllUsers();
      }
    );
  }


  private getAllUsers(): void {
    this.filteredUsers = [];
    this.loadingIndicator = true;
    this.isDataLoading = true;
    this.usersService.getUsersByParent(
      this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
      this.configuredUsers = serviceResponse.result as User[];
      this.initConfiguredUsersTableList();
      this.getAllUserGroups();
      this.loadingIndicator = false;
      this.isDataLoading = false;
    },
    error => {
      this.loadingIndicator = false;
      this.isDataLoading = false;
    });
  }

  private initConfiguredUsersTableList() {
    if (!this.configuredUsers || this.configuredUsers === undefined) {
      return;
    }
    this.allUsersForParent = [];
    this.configuredUsers.forEach((nextUser: User) => {
      this.allUsersForParent.push(this.buildConfiguredUsersTableRow(nextUser));
    });
    this.filterConfiguredUsers();
  }

  private buildConfiguredUsersTableRow(inputUser: User): ConfiguredUserTableRow {
    const configuredUser: User = Object.assign(new User(), inputUser);
    const configuredUserTableRow: ConfiguredUserTableRow = new ConfiguredUserTableRow();
    configuredUserTableRow.id = configuredUser.id;
    configuredUserTableRow.name = configuredUser.name;
    configuredUserTableRow.userType = configuredUser.userType;
    configuredUserTableRow.status = configuredUser.active ? UserStatus.ACTIVE : UserStatus.INACTIVE;
    if (configuredUser.lastLoginDate) {
      configuredUserTableRow.lastLoginDate = this.datePipe.transform(new Date(configuredUser.lastLoginDate), 'MM/dd/yyyy hh:mm aaaa');
    } else {
      configuredUserTableRow.lastLoginDate = 'N/A';
    }
    configuredUserTableRow.startDate = this.datePipe.transform(new Date(configuredUser.startDate), 'MM/dd/yyyy hh:mm aaaa');
    this.endDate = this.datePipe.transform(new Date(configuredUser.endDate), 'MM/dd/yyyy hh:mm aaaa');
    configuredUserTableRow.endDate = configuredUser.endDate === null ? 'No end Date' : this.endDate;
    configuredUserTableRow.createdDate = this.datePipe.transform(new Date(configuredUser.createdDate), 'MM/dd/yyyy hh:mm aaaa');
    configuredUserTableRow.modifiedDate = this.datePipe.transform(new Date(configuredUser.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
    configuredUserTableRow.modifiedBy = configuredUser.modifiedBy;
    return configuredUserTableRow;
  }

  private apiUserPopUp(customStatusMessage: Object){
    let message = `
    Username: ` + customStatusMessage['ApiUser']['id'] + ` \n
    Password: ` + customStatusMessage['ApiUser']['password'] + ` \n
    ClientId: ` + customStatusMessage['ApiUser']['clientId'] + ` \n
    Client Secret: ` + customStatusMessage['ApiUser']['clientSecret'] + ` \n
    Okta Authorization URL: ` + customStatusMessage['OktaAuthUrl'] + ` \n`;
    let title = "API User " + customStatusMessage['ApiUser']['id'] + " successfully Added";
    this.infoModal.launchModal({
      'title': title,
      'description': '',
      'content': {
        'type': 'PLAIN_TEXT',
        'data': message
      }
    });
  }

  private filterConfiguredUsers() {
    this.filteredUsers = this.allUsersForParent
      .filter((configuredUser: ConfiguredUserTableRow) => {
        return configuredUser.name.toLowerCase().includes(this.searchByAttribute.toLowerCase())
        || configuredUser.id.toLowerCase().includes(this.searchByAttribute.toLowerCase());
      });
    this.paginatedUsers = this.filteredUsers.slice(0, 10);
  }

  private showSuccessMessage(message: string): void {
    this.successMessage = message;
    this.formSuccess.show().then(() => {}).catch(() => {});
  }

  private showErrorMessage(message: string): void {
    this.errorMessage = message;
    this.formError.show().then(() => {}).catch(() => {});
  }

  private getAllUserGroups() {
    this.configuredUserGroupService.getActiveUserGroups(this.parentId, 'active=true&limited=true').subscribe(
      (res: any) => {
        this.userGroupInfo = res.result.map( obj => ({
          parentId: obj.parentId ? obj.parentId : obj.partitionKey,
          name: obj.name,
          id: obj.id }));
      }
    );
  }
}