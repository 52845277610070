<div class="row m-0 rule-build-help drop-attributes" (treeDrop)="addCondition($event)"
  [treeAllowDrop]="canDropOnRule.bind(this)" *ngIf="!isReadOnlyRule && this.rightPaneAttributes.conditions.length==0">
  <div class="col p-0 text-center">
    <p class="text-style-muted-2 py-4 mb-0">Drag and drop event attributes here
    </p>
  </div>
</div>

<div class="row m-0">
  <div class="col p-0">
    <ng-template [ngTemplateOutlet]="conditionBuilderTemplate"
      [ngTemplateOutletContext]="{ conditions: this.rightPaneAttributes.conditions, type: this.rightPaneAttributes.type, level: 0 }">
    </ng-template>
  </div>
</div>

<ng-template #attributeCompareTemplate let-condition="condition" let-level="level"
             let-conditionIndex="conditionIndex">
  <fieldset [disabled]="isReadOnlyRule">
  <div class="row">
    <div class="col-xs-6 pt-3 p-3 font-weight-bold">
      {{getDisplayNameForAttribute(condition.attributeCondition.path)}}
    </div>
    <div class="col-auto p-2" *ngIf="isAttributeDropped(condition)">
      <coreui-select id="valuesDrpDown_{{level}}{{conditionIndex}}"
                     [(ngModel)]="condition.attributeCondition.values[0]">
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).arithmetic" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
      </coreui-select>
    </div>
    <div class="col p-3 text-right">
      <app-ae-button buttonType="micro-feedback-error-secondary" (click)="changeOperator(condition.attributeCondition)"
                     [disabled]="isReadOnlyRule" buttonText="Delete"></app-ae-button>
    </div>
  </div>
  <div class="row" *ngIf="isAttributeDropped(condition)">
    <div class="col-xs-6 pt-2 font-weight-bold">
      {{getDisplayNameForAttribute(condition.attributeCondition.values[1].path)}}
    </div>
    <div class="col-auto pt-0 pb-2">
      <coreui-select id="valuesDrpDown_{{level}}{{conditionIndex}}"
                     [(ngModel)]="condition.attributeCondition.values[2]">
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).relative" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).absolute" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
      </coreui-select>
    </div>
    <div class="col-auto pt-0 pb-2">
      <input type="text" [coreUIDecimalMask]="decimalMaskConfig" coreUIInput
             [(ngModel)]="condition.attributeCondition.values[3]" id="values_{{level}}{{conditionIndex}}"
             (focusout)="validateValue(condition)" [disabled]="isReadOnlyRule"
             placeholder="Enter the value" />
      <div class="text-color-warning" *ngIf="condition.errorOnValues!=''">
        {{condition.errorOnValues}}
      </div>
    </div>
    <div class="col-auto pt-0 pb-2" *ngIf="condition.attributeCondition.operator == 'IS_COMPARE_TIME'">
      <coreui-select placeholder="Select a value" [(ngModel)]="condition.attributeCondition.values[4]">
        <coreui-option *ngFor="let durationUnitName of durationUnitList"
                       id="valuesDrpDown_{{level}}{{conditionIndex}}" [value]="durationUnitName">
          {{durationUnitName | lowercase}}
        </coreui-option>
      </coreui-select>
    </div>
  </div>
  <div class="row rule-build-help drop-attributes m-0 mb-1"
       (treeDrop)="addAttributeToCompareValues($event,  condition)"
       [treeAllowDrop]="canDropAttributeToCompare.bind(this, condition.attributeCondition.dataType)"
       *ngIf="!isComparisonAttributeDropped">
    <div class="col text-center">
      <p class="text-style-muted-2 py-1">Drag and drop an attribute to compare values of</p>
    </div>
  </div>
  </fieldset>
</ng-template>

<ng-template #attributeConditionTemplate let-parent="parent" let-condition="condition"
  let-conditionsCount="conditionsCount" let-conditionIndex="conditionIndex" let-level="level" let-type="type"
  let-isReadOnlyRule="isReadOnlyRule">
  <div class="m-1 drop-attributes py-1" [treeAllowDrop]="canDropOnRule.bind(this)"
    (treeDrop)="addConditionBefore($event, conditionIndex, parent, level)"
    (treeDropDragOver)="rulebuilder.treeDropDragOver($event, 'BEFORE', null)"
    (treeDropDragLeave)="rulebuilder.treeDropDragLeave($event, 'BEFORE', null)" *ngIf="!isReadOnlyRule && conditionsCount > 0">
  </div>
  <div class="row border m-1" *ngIf="condition.isAttributeCompare()">
    <div class="col">
      <ng-template [ngTemplateOutlet]="attributeCompareTemplate"
                   [ngTemplateOutletContext]="{condition: condition, level: level, conditionIndex: conditionIndex}">
      </ng-template>
    </div>
  </div>
  <fieldset class="p-0" [disabled]="isReadOnlyRule">
  <div class="row border m-1" *ngIf="!condition.isAttributeCompare()">
    <div class="col-xs-6 pt-4 p-3 font-weight-bold">
      {{getDisplayNameForAttribute(condition.attributeCondition.path)}}
    </div>
    <div class="col-xs-6 p-3" *ngIf="!condition.isDateDataType()">
      <label for="operator" class="sr-only">Operator</label>
      <coreui-select #selectedOperator id="operator_{{level}}{{conditionIndex}}"
        [(ngModel)]="condition.attributeCondition.operator" [ngModelOptions]="{standalone: true}"
        (blur)="condition.isOperatorSelected=true;" [disabled]="isReadOnlyRule">
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).relative" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
          <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).absolute" value="{{op.value}}">
            {{op.display}}
          </coreui-option>
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).comparison" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
      </coreui-select>
    </div>
    <div class="col-xs-6 p-3" *ngIf="condition.isDateDataType()">
      <label for="operator_{{level}}{{conditionIndex}}" class="sr-only">Operator</label>
      <coreui-select #selectedOperator id="operator_{{level}}{{conditionIndex}}"
        [(ngModel)]="condition.attributeCondition.operator" [ngModelOptions]="{standalone: true}"
        (blur)="condition.isOperatorSelected=true;setDefaultRelativeDateValues(condition);" [disabled]="isReadOnlyRule">
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).relative" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).absolute" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
        <coreui-option *ngFor="let op of this.getOperators(condition.attributeCondition.dataType).comparison" value="{{op.value}}">
          {{op.display}}
        </coreui-option>
      </coreui-select>
    </div>
    <span *ngIf="condition.attributeCondition.valueType === valueType.ATTRIBUTE && condition.attributeCondition.operator !== 'ISNULL'
        && condition.attributeCondition.operator !== 'NOTISNULL'" class="col-xs-6 pt-4 p-3 mr-auto">
      {{condition.attributeCondition.values[0].name}}
    </span>
    <ng-container *ngIf="condition.attributeCondition.valueType === valueType.CONSTANT">
      <div class="col-xs-6 p-3" *ngIf="isValueInputTextField(condition)">
        <label for="values_{{level}}{{conditionIndex}}" class="sr-only">Values</label>
        <input coreUIInput type="text" class="form-control" id="values_{{level}}{{conditionIndex}}"
          [(ngModel)]="condition.attributeCondition.values" [ngModelOptions]="{standalone: true}"
          (focusout)="validateValue(condition)" [disabled]="isReadOnlyRule"
          [placeholder]="'Enter ' + condition.attributeCondition.name" [size]="getInputTextFieldSize(condition.attributeCondition.name, condition.attributeCondition.values)">
        <div class="text-color-warning" *ngIf="condition.errorOnValues!=''">
          {{condition.errorOnValues}}
        </div>
      </div>
      <div class="col-xs-auto p-3" *ngIf="condition.isCsvOperator()">
        <label for="values_{{level}}{{conditionIndex}}" class="sr-only">Values</label>
        <textarea rows="1" cols="50" class="form-control" id="values_{{level}}{{conditionIndex}}"
          [(ngModel)]="condition.attributeCondition.values" [ngModelOptions]="{standalone: true}"
          (focusout)="validateValue(condition)" [disabled]="isReadOnlyRule"
          [placeholder]="'Enter ' + condition.attributeCondition.name"></textarea>
        <div class="text-color-warning" *ngIf="condition.errorOnValues!=''">
          {{condition.errorOnValues}}
        </div>
      </div>
      <div class="col-xs-auto p-3"  *ngIf="condition.isRelativeDateOperator()">
        <label for="values_{{level}}{{conditionIndex}}" class="sr-only">Values</label>
        <div class="row">
            <input *ngIf="condition.attributeCondition.values[0] === constants.NOW"
             type="text" class="form-control col-2 mx-2" id="values_{{level}}{{conditionIndex}}"
            [placeholder]="'#'" (focusout)="validateValue(condition)" readonly>
            <input *ngIf="condition.attributeCondition.values[0] !== constants.NOW"
            type="text" class="form-control col-2 mx-2" id="values_{{level}}{{conditionIndex}}" [(ngModel)]="condition.attributeCondition.values[0]"
            [placeholder]="'#'" (focusout)="validateValue(condition)">
          <coreui-select class="col-3" id="valuesDrpDown_{{level}}{{conditionIndex}}" [(ngModel)]="condition.attributeCondition.values[1]"
            [disabled]="condition.attributeCondition.values[0] === constants.NOW">
            <coreui-option value="m">Minutes</coreui-option>
            <coreui-option value="h">Hours</coreui-option>
            <coreui-option value="d">Days</coreui-option>
          </coreui-select>
          <label class="col-1 pt-2 pl-2" *ngIf="condition.attributeCondition.operator === 'BEFORE'">ago</label>
          <label class="col-2 pt-2 px-1" *ngIf="condition.attributeCondition.operator === 'AFTER'">from now</label>
          <coreui-checkbox-group orientation="vertical">
            <coreui-form-field class="pt-2 px-2" label="Set to current date & time">
              <input type="checkbox" coreUICheckbox id="setCurrentDateTime_{{level}}{{conditionIndex}}"
                [disabled]="isReadOnlyRule" [checked]="condition.attributeCondition.values[0] === constants.NOW"
                (change)="setCurrentDateTime($event, condition)" value="{{condition.attributeCondition.values}}" />
            </coreui-form-field>
          </coreui-checkbox-group>
        </div>
        <div class="text-color-warning" *ngIf="condition.errorOnValues!=''">
          {{condition.errorOnValues}}
        </div>
      </div>
      <div class="col-xs-auto p-3"  *ngIf="condition.isBeforeOccurrenceOperator()">
        <label for="values_{{level}}{{conditionIndex}}" class="sr-only">Values</label>
        <div class="row">
          <span class="col-auto pt-2 px-1">less than</span>
          <div class="col-2 px-2">
            <input type="text" [coreUIDecimalMask]="decimalMaskConfig" coreUIInput
                   [(ngModel)]="condition.attributeCondition.values[1]" id="values_{{level}}{{conditionIndex}}"
                   (focusout)="validateValue(condition)" [disabled]="isReadOnlyRule"
                   placeholder="#" />
            <div class="text-color-warning" *ngIf="condition.errorOnValues!=''">
              {{condition.errorOnValues}}
            </div>
          </div>
          <div class="col-auto px-1">
            <coreui-select id="valuesDrpDown_{{level}}{{conditionIndex}}" placeholder="Select a value" [(ngModel)]="condition.attributeCondition.values[2]">
              <coreui-option *ngFor="let durationUnitName of durationUnitList" [value]="durationUnitName">
                {{durationUnitName | titlecase}}
              </coreui-option>
            </coreui-select>
          </div>
          <span class="col-auto pt-2 px-2">before now</span>
        </div>
      </div>
      <div class="col-xs-6 p-3" *ngIf="condition.isAbsoluteDateOperator() && !condition.isNullOrNotNullOperator() && !condition.isBeforeOccurrenceOperator()">
        <div class="row">
          <div class="col">
            <div class="input-group">
              <label for="values_{{level}}{{conditionIndex}}" class="sr-only">Values</label>
              <input coreUIInput type="text" class="form-control" id="values_{{level}}{{conditionIndex}}"
                     [(ngModel)]="condition.attributeCondition.values[0]" [disabled]="isReadOnlyRule"
                     [placeholder]="'Select ' + condition.attributeCondition.name"
                     [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1">
              <div class="input-group-append">
                <app-ae-button
                  buttonType="icon-large"
                  icon="calendar"
                  [owlDateTimeTrigger]="dt1">
                </app-ae-button>
              </div>
              <owl-date-time #dt1 (afterPickerClosed)="validateValue(condition)"></owl-date-time>
            </div>
          </div>
        </div>
        <div class="text-color-warning" *ngIf="condition.errorOnValues!=''">
          {{condition.errorOnValues}}
        </div>
      </div>
      <div class="col-xs-6 p-3" *ngIf="condition.isBooleanDataType()">
        <label for="valuesDrpDown_{{level}}{{conditionIndex}}" class="sr-only">Values</label>
        <coreui-select id="valuesDrpDown_{{level}}{{conditionIndex}}" [(ngModel)]="condition.attributeCondition.values"
          [ngModelOptions]="{standalone: true}" [disabled]="isReadOnlyRule" placeholder="Select value">
          <coreui-option [value]="" [disabled]="true">Select value</coreui-option>
          <coreui-option [value]="true">True</coreui-option>
          <coreui-option [value]="false">False</coreui-option>
        </coreui-select>
      <div class="text-color-warning"
        *ngIf=" !validateBooleanValue(condition.attributeCondition.values) && condition.errorOnValues!=''">
        {{condition.errorOnValues}}
      </div>
      </div>
      <div class="col-xs-6 p-3 pl-1 pt-4 font-italic" *ngIf="condition.attributeCondition.operator && condition.isCsvOperator()">
          (Comma separated values)
      </div>
      <div *ngIf="condition.isSameAttributeCompare()"  class="col p-1 mr-auto drop-attributes" [treeAllowDrop]="canDropAttributeToCompare.bind(this, condition.attributeCondition.dataType)"
        (treeDrop)="addAttributeToCompare($event, condition.attributeCondition)">
      </div>
      <div *ngIf="!condition.isSameAttributeCompare()" class="col p-1 mr-auto drop-attributes"></div>
    </ng-container>
    <div class="col-xs-6 py-3 ml-auto text-right" *ngIf="!condition.outerCondition && showDeleteButton">
      <app-ae-button buttonType="micro-feedback-error-secondary" (click)="this.deleteAttributeCondition(condition, parent)" [disabled]="isReadOnlyRule" buttonText="Delete"></app-ae-button>
    </div>
  </div>
  <div class="row m-0 mx-1" *ngIf="!isReadOnlyRule && conditionsCount > 0">
    <div class="col-1 drop-attributes py-1" [treeAllowDrop]="canDropOnRule.bind(this)"
      (treeDrop)="addConditionAfter($event, conditionIndex, parent, level)"
      (treeDropDragOver)="rulebuilder.treeDropDragOver($event, 'AFTER', level)"
      (treeDropDragLeave)="rulebuilder.treeDropDragLeave($event, 'AFTER', level)">
    </div>
    <ng-container *ngIf="level < 4">
      <div class="col-11 drop-attributes py-1 text-center"
        [treeAllowDrop]="canDropOnRule.bind(this)"
        (treeDrop)="addConditionAsChild($event, conditionIndex, parent, level)"
        (treeDropDragOver)="rulebuilder.treeDropDragOver($event, 'CHILD', level)"
        (treeDropDragLeave)="rulebuilder.treeDropDragLeave($event, 'CHILD', level)">
      </div>
    </ng-container>
    <ng-container *ngIf="level === 4">
      <div class="col-11 drop-attributes py-1 text-center"
        [treeAllowDrop]="canDropOnRule.bind(this)"
        (treeDrop)="addConditionAsChild($event, conditionIndex, parent, level)"
        (treeDropDragOver)="rulebuilder.treeDropDragOver($event, 'CHILD', level)"
        (treeDropDragLeave)="rulebuilder.treeDropDragLeave($event, 'CHILD', level)">
        <span class="disable-pointer-events py-3"></span>
      </div>
    </ng-container>
  </div>
  <div class="row mr-1 mt-1 ml-4" *ngIf="conditionsCount - 1  > conditionIndex">
    <div class="col p-0">
      <div ngbDropdown class="dropdown">
        <button ngbDropdownToggle type="button" id="selectLogicalCondition_{{conditionIndex}}"
          class="btn btn-sm btn-drop-down dropdown-toggle font-weight-bold" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false" [disabled]="conditionIndex > 0 || isReadOnlyRule">{{type}}</button>
        <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="selectLogicalCondition">
          <button ngbDropdownItem class="dropdown-item"
            (click)="onLogicalOperatorSelected(conditionType.AND, condition, this.rightPaneAttributes)">{{conditionType.AND | titlecase}}</button>
          <button ngbDropdownItem class="dropdown-item"
            (click)="onLogicalOperatorSelected(conditionType.OR, condition, this.rightPaneAttributes)">{{conditionType.OR | titlecase}}</button>
        </div>
      </div>
    </div>
  </div>
  </fieldset>
</ng-template>

<ng-template #conditionBuilderTemplate let-conditions="conditions" let-type="type" let-level="level">
  <ng-container *ngFor="let item of conditions; let conditionIndex = index">
    <ng-container *ngIf="!item.hasOwnProperty('conditions')">
      <ng-template [ngTemplateOutlet]="attributeConditionTemplate"
        [ngTemplateOutletContext]="{parent:conditions, condition: item, conditionsCount: conditions.length, conditionIndex: conditionIndex, level: level,  type:type,isReadOnlyRule: isReadOnlyRule }">
      </ng-template>
    </ng-container>
    <div *ngIf="item.hasOwnProperty('conditions')">
      <div class="row m-0 ml-5">
        <div class="col p-0">
          <ng-template [ngTemplateOutlet]="conditionBuilderTemplate"
            [ngTemplateOutletContext]="{ conditions: item.conditions, type: item.type, level:level+1 }">
          </ng-template>
        </div>
      </div>
      <div class="row m-0 ml-4" *ngIf="conditionIndex != conditions.length - 1">
        <div class="col p-0">
          <div ngbDropdown class="dropdown">
            <button ngbDropdownToggle type="button"
              class="btn btn-sm btn-drop-down dropdown-toggle font-weight-bold" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [disabled]="true">{{type}}</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
