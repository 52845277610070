export class BudgetContent {

  public parentId: string;
  public id: string;
  public budget: number;
  public clicksRemaining: string;
  public companyId: string;
  public contentId: string;
  public expirationTime: number;

  constructor() {
  }

}