import { Injectable, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pattern } from 'src/app/shared/pattern';

@Injectable()
export class BaseComponent implements OnInit {

  public pageIndices;

  constructor() { }

  ngOnInit() {
  }

  public handleSort(sort, tableData): any[] {
    const tableOrdered = sort.order === 'ascending' ? this.sortByKeyAsc(tableData, sort.column) : this.sortByKeyDesc(tableData, sort.column);
    return tableOrdered.slice(
      this.pageIndices.start,
      this.pageIndices.end
    );
  }

  public handlePageChange(pageData, tableData): any[] {
    this.pageIndices = { ...pageData.indices };
    return tableData.slice(
      pageData.indices.start,
      pageData.indices.end
    );
  }

  public closeModal(modal: NgbActiveModal): void {
    modal.close();
  }

  public isElementAnArrayObject(element: (string | any[])): number {
    if (Array.isArray(element) && element.length && element[0]?.component) {
      return 1;
    }
    return 0;
  }

  public reverseArrayElements(array: any[]): any[] {
    const reversedArray = array.reduce((acc, item) => {
      acc.unshift(item);
      return acc;
    }, []);
    return [...reversedArray];
  }

  sortByKeyAsc(array, key): any {
    return array.sort((a, b) => {
      const arrayComponentFlag = this.isElementAnArrayObject(a[key]);
      let x = arrayComponentFlag ? a[key][0].msg : a[key];
      let y = arrayComponentFlag ? b[key][0].msg : b[key];
      const re = new RegExp(Pattern.DATE_FORMAT_REGEX);
      if (re.test(x) && re.test(y)) {
        x = this.parseDate(x);
        y = this.parseDate(y);
      }
      if (!x || x === undefined) {
        return -1;
      }
      if (!y || y === undefined) {
        return 1;
      }
      if (x < y) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  sortByKeyDesc(array, key): any {
    return array.sort((a, b) => {
      const arrayComponentFlag = this.isElementAnArrayObject(a[key]);
      let x = arrayComponentFlag ? a[key][0].msg : a[key];
      let y = arrayComponentFlag ? b[key][0].msg : b[key];
      const re = new RegExp(Pattern.DATE_FORMAT_REGEX);
      if (re.test(x) && re.test(y)) {
        x = this.parseDate(x);
        y = this.parseDate(y);
      }
      if (!x || x === undefined) {
        return 1;
      }
      if (!y || y === undefined) {
        return -1;
      }
      if (x > y) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  private parseDate(date: string): number {
    return Date.parse(date);
  }
}