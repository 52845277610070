import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ae-sort-by',
  templateUrl: './ae-sort.component.html',
  styleUrls: ['./ae-sort.component.scss']
})
export class AESortComponent {

  @Input() sortByOptions: any[];
  @Input() sortByDisplay: string = "Sort By";
  @Output() sortByValue = new EventEmitter<string>();

  constructor() { }

  performClickAction(text: string): void {
    this.sortByDisplay = text;
    this.sortByValue.emit(text);
  }

}
