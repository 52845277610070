import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@epsilon/core-ui';
import { Observable } from 'rxjs';
import { Messages } from 'src/app/shared/message';
import { WarningOptions, WarningType } from 'src/app/shared/warning-options';

@Component({
  selector: 'app-coreui-modal-warning',
  templateUrl: './coreui-modal-warning.component.html'
})
export class CoreuiModalWarningComponent implements OnInit {

  @Output()
  public decision = new EventEmitter<any>();

  @ViewChild('alertModal', { static: true })
  private alertModal: ModalComponent;

  public options: WarningOptions;
  public warningType: WarningType;

  private entityType: string;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public yes(): void {
    this.closeAlertModal(true);
  }

  public no(): void {
    this.closeAlertModal(false);
  }

  public launchAlertModal(): void {
    void this.alertModal.show();
  }

  public launchModal(type: WarningType, displayMsg: Record<string, any>): void {
    this.warningType = type;
    switch (type) {
      case WarningType.DELETE_ENTITY_WARNING:
        this.options = WarningOptions.deleteEntity(displayMsg.title, displayMsg.msg, 'msg2' in displayMsg ? displayMsg.msg2 : []);
        break;
      case WarningType.UNSAVED_CHANGES_WARNING:
        this.options = WarningOptions.unsavedChanges('msg' in displayMsg ? displayMsg.msg : []);
        break;
      case WarningType.CHANGE_PROGRAM_MODE_WARNING:
        this.options = WarningOptions.changeMode(displayMsg.title, displayMsg.msg, 'msg2' in displayMsg ? displayMsg.msg2 : []);
        break;
      case WarningType.ALERT:
        this.options = WarningOptions.alert(displayMsg.title, displayMsg.msg, 'msg2' in displayMsg ? displayMsg.msg2 : []);
        break;
    }
    this.launchAlertModal();
  }

  public getEntityType(): string {
    return this.entityType;
  }

  public forEntity(entityName: string): CoreuiModalWarningComponent {
    this.entityType = entityName;
    return this;
  }

  public launchUnsavedChangesWarningModal(): Observable<any> {
    this.launchModal(WarningType.UNSAVED_CHANGES_WARNING, {
      msg2: [Messages.loseUnsavedChanges]
    });
    return this.decision.asObservable();
  }

  private closeAlertModal(choice: boolean): void {
    void this.alertModal.hide();
    this.decision.emit(choice);
  }

}