import { ProgramType } from '../program-type';
import { PackagedProgramService } from 'src/app/shared/services/packaged-program.service';
import { CustomProgramService } from 'src/app/shared/services/custom-program.service';
import { CustomProgramBuilderService } from 'src/app/shared/services/custom-program-builder.service';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';


export abstract class ServiceLocator {

  public static getProgramService(programType: string, injector?: Injector) {
    const httpClient = injector.get<HttpClient>(HttpClient);
    if (programType === ProgramType.PACKAGED) {
      return new PackagedProgramService(httpClient);
    } else if (programType === ProgramType.SELF_SERVICE) {
      return new CustomProgramService(httpClient);
    } else if (programType === ProgramType.SELF_SERVICE_EXTERNAL || programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
      return new CustomProgramBuilderService(httpClient);
    }
  }

}