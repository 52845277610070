import { EventAttributes } from './event-attributes';

export class Testdata {

  actionDestinationsTestData: Map<string, EventAttributes>;

  constructor(response?: Object) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response)));
    }
  }

  getTestData(actionDestinationId: string) {
    if (this.actionDestinationsTestData && (this.actionDestinationsTestData)[actionDestinationId]) {
      return ((this.actionDestinationsTestData)[actionDestinationId]).data.toString();
    }
  }

  getTestDataAttributePath(actionDestinationId: string) {
    if (this.actionDestinationsTestData && (this.actionDestinationsTestData)[actionDestinationId]) {
      return ((this.actionDestinationsTestData)[actionDestinationId]).eventAttributePath;
    }
  }

}