
export class ConfiguredEntityState {

  public parentId: string;
  public id: string;
  public description: string;
  public name: string;
  public schema: any;
  public type: string;
  public modifiedDate: string;
  public timeToLive: number;
  public entityStateKey: EntityStateKey;
  public modifiedBy: String;

  constructor(ConfiguredEntityState?: Object) {
    if (ConfiguredEntityState) {
      Object.assign(this, JSON.parse(JSON.stringify(ConfiguredEntityState)));
    }
  }

}

export class EntityStateKey {

  public name = '';
  public attributes: EventAttribute[] = [];

}


export class EventAttribute {

  public name: string;
  public path: string;
  public dataType = 'STRING';
  public type = 'EVENT_ATTRIBUTE';

}