import { Injectable, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormOnSaveAction } from 'src/app/shared/constants';
import { ExclusionOperatorDisplayNameMap } from 'src/app/shared/models/exclusion/exclusion-operator';
import { DataTypes } from 'src/app/shared/models/custom-rule/conditions/data-types';
import { ExclusionConfigurationBaseOperator } from './exclusion-configuration-base-operator';

@Injectable()
export abstract class ExclusionConfigurationPrimitiveOperator extends ExclusionConfigurationBaseOperator implements OnInit {

  public formControlNames = {
    'CONDITION_VALUE': 'conditionValue'
  };
  public inputConditionValue: DataTypes;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
    this.initFormGroup();
  }

  public initInput(): void {
    this.displayExclusionOperator = ExclusionOperatorDisplayNameMap[this.exclusionOperator];
    this.inputConditionValue = undefined;
    if (this.action === FormOnSaveAction.EDIT) {
      this.inputConditionValue = this.attributeConditionValues[0] as DataTypes;
    }
  }

  public initFormGroup(): void {
    this.exclusionConfigurationFormGroup = new UntypedFormGroup({
      conditionValue: new UntypedFormControl(this.inputConditionValue)
    });
    if (this.readOnly) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).disable();
    }
  }

  public buildAttributeConditionValues(): unknown[] {
    const conditionValue = this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).value;
    const attributeConditionValues = [conditionValue];
    return attributeConditionValues;
  }

  public validate(): boolean {
    const conditionValue = this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).value;
    return this.validateInputValue(conditionValue);
  }

  private validateInputValue(conditionValue: DataTypes): boolean {
    const isValid = this.isValidDataType(conditionValue);
    if (!isValid) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).setErrors({ required: true });
      this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).markAsDirty();
    }
    return isValid;
  }

  private isValidDataType(conditionValue: DataTypes): boolean {
    switch (this.attributeDataType) {
      case DataTypes.string:
        return conditionValue && (typeof conditionValue === 'string');
      case DataTypes.boolean:
        return (typeof conditionValue === 'boolean');
      case DataTypes.number || DataTypes.integer:
        return conditionValue && (typeof conditionValue === 'number');
      default:
        return false;
    }
  }

}
