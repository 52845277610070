/* eslint-disable max-len */
import { environment } from '../environments/environment';

export default {
  oidc: {
    clientId: `${environment.oktaClientId}`,
    issuer: `${environment.oktaIssuerUrl}`,
    redirectUri: `${window.location.origin}/login/okta/callback`,
    scopes: ['openid', 'profile', 'email'],
    authenticationUri: `${environment.oktaVanityUrl}/v1/authorize?response_type=id_token&client_id=${environment.oktaClientId}&redirect_uri=${window.location.origin}/login/okta/callback&state=authn&scope=openid+profile+email&nonce=abcd&prompt=none`,
    sessionUri: `${environment.oktaVanityUrl}`.split('oauth2/')[0] + 'api/v1/sessions/me',
    sessionCookieRedirectUri: `${environment.oktaIssuerUrl}`.split('oauth2/')[0] + 'login/sessionCookieRedirect?token=TOKEN&redirectUrl='
  }
};
