 <app-coreui-modal-warning (decision)="handleDeleteConfiguredProgramDecision($event)"></app-coreui-modal-warning>
<div class="container-fluid object-page-header pt-4 mb-3">
  <div class="row mb-2">
     <div class="col">
        <app-ae-button
        buttonText="Back to Programs"
        iconPosition="left" 
        icon="chevronLeft"
        [routerLink]="'/programs'"
      ></app-ae-button>
     </div>
  </div>
  <div class="row mb-4 mb-lg-0">
     <div class="col-12 col-lg">
        <div class="form-group mb-0">
           <div class="input-group mt-2">
              <label id="programName" class="sr-only">Program Name</label>
              <input type="text" id="programName" class="form-control object-page-header-input" required placeholder="Enter program name"
              #programName="ngModel" [(ngModel)]="this.persistedConfiguredProgram.name"
              [class.is-invalid]="(programName.touched && (programName.invalid || programName.value.trim() === '' || isDuplicateProgramName))"
              [disabled]="persistedConfiguredProgram.active"
              (focus)="isProgramNameInputFocused = true;"
              />
              <div class="input-group-append" *ngIf="isProgramNameInputFocused && isEdit && authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE'])">
                 <app-ae-button [buttonText]="'Cancel'" (click)="discardProgramNameChanges()"></app-ae-button>
                 <app-ae-button class="ml-2" [buttonText]="'Save'" (click)="updateProgramName()"></app-ae-button>
              </div>
           </div>
           <div class="text-color-warning"
              *ngIf="((programName.invalid || programName.value.trim() === '') && programName.touched)">
              Program name is required
           </div>
           <div class="text-color-warning" *ngIf="isDuplicateProgramName">
             Another program with the name already exists.
           </div>
        </div>
     </div>
     <div class="col-12 col-md col-lg-auto mt-2 mt-md-3 pl-md-0">
        <app-program-state-indicator *ngIf="isEdit"
        [programStatus]="persistedConfiguredProgram.active" [programMode]="persistedConfiguredProgram.mode"
        ></app-program-state-indicator>
     </div>
   <div class="col-12 col-md col-lg-auto mt-2 mt-md-2"
      *ngIf="isEdit && ((this.persistedConfiguredProgram.programType === 'PACKAGED' && authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE'])) 
         || (this.persistedConfiguredProgram.programType !== 'PACKAGED' && authorizationService.isAllowed('CUST_PRG', ['CREATE','UPDATE'])))">
      <app-ae-button class="mr-2" [buttonText]="'Copy'" (click)="copyConfiguredProgram()"></app-ae-button>
   </div>
     <div class="col-12 col-md col-lg-auto mt-2 mt-md-2 pl-md-0" *ngIf="authfeatureName && 
         authorizationService.isAllowed(authfeatureName, ['DELETE'])">
        <div ngbTooltip="{{displayTexts.DISABLED_DELETE_BTN_TOOLTIP}}" [disableTooltip]="!persistedConfiguredProgram.active" 
           placement="bottom-right">
           <div *ngIf="isEdit">              
              <app-ae-button
              [buttonText]="'Delete'"
              (click)="removeConfiguredProgram()"
              [disabled]="persistedConfiguredProgram.active || apiCallCount !== 0"
              ></app-ae-button>
           </div>
        </div>
     </div>
  </div>
  <div class="row">
      <div class="col-1 mt-1 mb-3">
         <label for="programName">Program</label>
      </div>
      <div class="col-2 mt-1 mb-3" *ngIf="isEdit && authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE','READ'])">
         <a href="javascript:void(0)" (click)="onChangeLogModalAction()">View Change Log</a>
      </div>
   </div>
</div>

<ng-container *ngIf="isChangeLogModalShown">
   <app-audit-history (isModalDisplayInfo)="setChangeLogModalStatus($event)"
      [programId]="this.programData.programId" [programName]="this.programData.tenantProgram.name"></app-audit-history>
</ng-container>