<div class="container-fluid">
  <div class="row">
      <div class="col col-sm-6 col-xl-3">
        <app-ae-table-search
        label="Search"
        placeholder="Search"
        (searchEmitter)="handleSearch($event)"
        (clearSearch)="handleClearSearch()">
        </app-ae-table-search>
    </div>
    <div class="col text-right pt-4" *ngIf="isUserGroupCreateAllowed()">
      <app-ae-button buttonType="primary" buttonText="Create User Group" [routerLink]="createUserGroupRouterLink"></app-ae-button>
    </div>
  </div>
</div>
<div class="container-fluid my-3">
  <ng-container *ngIf="!parentContextService.isParentContextSet()">
    <div class="row">
      <div class="col text-center">
        <p class="text-style-muted-3 m-0">Please set up a Parent in Settings to create an User Groups</p>
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col">
      <app-ae-table [isDataLoading]="isPageLoading" [properties]="properties"
      [configuredTableData]="userGroupTableFilteredData" (actionClick)="actionClick($event)">
      </app-ae-table>
    </div>
  </div>
</div>

<ng-template #createdDateColumn let-userGroup>
  {{userGroup.createdDate | date: 'MM/dd/yyyy h:mmaaaaa\'m\''}}
</ng-template>

<ng-template #modifiedDateColumn let-userGroup>
  {{userGroup.modifiedDate | date: 'MM/dd/yyyy h:mmaaaaa\'m\''}}
</ng-template>
