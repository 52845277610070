import { Component, ViewEncapsulation } from '@angular/core';
import { ExclusionConfigurationPrimitiveOperator } from '../exclusion-configuration-primitive-operator';

@Component({
  selector: 'app-exclusion-configuration-string-operation',
  templateUrl: './exclusion-configuration-string-operation.component.html',
  styleUrls: ['./exclusion-configuration-string-operation.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ExclusionConfigurationStringOperationComponent extends ExclusionConfigurationPrimitiveOperator {

  public constructor() {
    super();
  }

}