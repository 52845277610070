import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Argument } from '../../models/custom-rule/then-clause/operation/argument';
import { ArgumentName } from '../../models/custom-rule/then-clause/operation/argument-name';
import { Attribute } from '../../models/custom-rule/then-clause/operation/attribute';
import { RuleThenClauseOperation } from '../../models/custom-rule/then-clause/operation/rule-then-clause-operation';
import { EntityStateAttribute } from '../../models/custom-rule/then-clause/operation/entity-state-attribute';
import { Messages } from '../../../shared/message';
import { TreeNode } from '../../models/custom-rule/tree-node';
import { SourceType } from '../../models/custom-rule/conditions/source-type';
import { AttributeMetadata } from '../../models/custom-rule/conditions/attribute-metadata';
import { ValueType } from '../../models/custom-rule/then-clause/operation/value-type';
import { OperationName } from '../../models/custom-rule/then-clause/operation/operation-name';
import { Pattern } from '../../pattern';
import { DataTypes } from '../../models/custom-rule/conditions/data-types';
import { TrimForm } from '../../models/custom-rule/then-clause/operation/trim-form';
import { Constants } from 'src/app/shared/constants';

@Component({
  selector: 'app-rule-then-clause-operation',
  templateUrl: './rule-then-clause-operation.component.html',
  styleUrls: ['./rule-then-clause-operation.component.scss']
})
export class RuleThenClauseOperationComponent implements OnInit {

  @Input()
  public index: number;

  @Input()
  public ruleThenClauseOperation: RuleThenClauseOperation;

  @Input()
  public isReadOnlyRule = false;

  @Input()
  public showDeleteButton: boolean;

  @Input()
  public validationRequired: boolean;

  @Input()
  public attributeMap: { [key: string]: AttributeMetadata };

  @Output()
  public removeOperationController = new EventEmitter<number>();

  public argumentNames = ArgumentName;
  public formBuilder: UntypedFormBuilder;
  public operationFormGroup: UntypedFormGroup;
  public messages = Messages;
  public dataTypes = DataTypes;
  public operationNames = OperationName;
  public isListDataSettingsExpanded = false;
  public operatioNameToggleConfig: {
    'checkedLabel': OperationName;
    'unCheckedLabel': OperationName;
    'checked': boolean;
  } = Object.create({});
  public decimalMaskConfig = {
    disallowNegative: true,
    thousandsSeparator: ',',
    includeThousandsSeparator: false,
    decimalPrecision: 2,
    decimalPoint: '.'
  };
  public constructor(formBuilder: UntypedFormBuilder) {
    this.formBuilder = formBuilder;
  }

  public ngOnInit(): void {
    this.configureForm();
    this.initOperationNameToggleConfig(this.ruleThenClauseOperation.name, this.getEntityStateAttributeDataType());
  }

  public isValueTypeConstant(): boolean {
    return this.getFormControlValueType().value === ValueType.CONSTANT;
  }

  public isValueTypeEventAttribute(): boolean {
    return this.getFormControlValueType().value === ValueType.EVENT_ATTRIBUTE;
  }

  public getEntityStateAttributePath(): string {
    const entityStateAttribute: EntityStateAttribute = this.getInputArgument(ArgumentName.ATTRIBUTE).value as EntityStateAttribute;
    return entityStateAttribute.path.replace(new RegExp(Pattern.JSON_ATTRIBUTE_PATH), '');
  }

  public getEntityStateAttributeDataType(): DataTypes {
    const entityStateAttribute: EntityStateAttribute = this.getInputArgument(ArgumentName.ATTRIBUTE).value as EntityStateAttribute;
    return entityStateAttribute.dataType;
  }

  public getEventAttributePath(): string {
    const eventAttribute: Attribute = this.getFormControlValue().value as Attribute;
    return eventAttribute.path.replace(new RegExp(Pattern.JSON_ATTRIBUTE_PATH), '');
  }

  public getInputArgument(argumentName: ArgumentName): Argument {
    const argument: Argument = this.ruleThenClauseOperation.arguments.find(nextArgument => nextArgument.name === argumentName);
    return argument;
  }

  public isEntityStateAttributeOfTypeDateTime(): boolean {
    const entityStateAttribute: EntityStateAttribute
       = Object.assign(new EntityStateAttribute(), this.getInputArgument(ArgumentName.ATTRIBUTE).value as EntityStateAttribute);
    return entityStateAttribute.isAttributeOfDateType();
  }

  public onDeleteOperation(): void {
    this.removeOperationController.emit(this.index);
  }

  public canDropEventAttributeToSet(event: unknown): boolean {
    const attribute: TreeNode = event['data'];
    if (!this.isNodeOfTypeEventAttribute(attribute)) {
      return false;
    }
    if (!this.isCompatibleDataType(attribute.id)) {
      return false;
    }
    return true;
  }

  public onEventAttributeDrop(event: unknown): void {
    const attributeMetadataId = event['element']['data']['id'];
    const eventAttributeMetaData: AttributeMetadata = this.attributeMap[attributeMetadataId];
    this.setValueToEventAttribute(eventAttributeMetaData);
    this.setFormControlValueType(ValueType.EVENT_ATTRIBUTE);
  }

  public validate(): boolean {
    const formControlValue = this.getFormControlValue();
    const value: string = formControlValue.value;
    if (this.getFormControlOperationName().value === OperationName.SET_CURRENT_DATE_TIME) {
      return true;
    }
    if (this.getEntityStateAttributeDataType() === DataTypes.array) {
      return this.validateListArguments();
    }
    if (value) {
      return true;
    }
    formControlValue.setErrors({ 'required': true });
    return false;
  }

  public builOperation(): RuleThenClauseOperation {
    const ruleThenClauseOperation: RuleThenClauseOperation = this.ruleThenClauseOperation;
    ruleThenClauseOperation.name = this.getFormControlOperationName().value;
    const operationArguments: Argument[] = [];
    operationArguments.push(this.getInputArgument(ArgumentName.ATTRIBUTE));
    operationArguments.push(this.getOperationArgumentValueType());
    operationArguments.push(this.getOperationArgumentValue());
    if (this.getFormControlOperationName().value === OperationName.ADD
    || this.getFormControlOperationName().value === OperationName.APPEND_CURRENT_DATE_TIME) {
      operationArguments.push(this.getOperationArgumentDeDupe());
      operationArguments.push(this.getOperationArgumentTrimFrom());
      operationArguments.push(this.getOperationArgumentMaxLength());
    }
    ruleThenClauseOperation.arguments = operationArguments;
    return ruleThenClauseOperation;
  }

  public getFormControlOperationName(): AbstractControl {
    return this.operationFormGroup.get('operationName');
  }

  public getFormControlValueType(): AbstractControl {
    return this.operationFormGroup.get('valueType');
  }

  public getFormControlValue(): AbstractControl {
    return this.operationFormGroup.get('value');
  }

  public getFormControlSetCurrentDateTime(): AbstractControl {
    return this.operationFormGroup.get('setCurrentDateTime');
  }

  public getFormControlAddCurrentDateTime(): AbstractControl {
    return this.operationFormGroup.get('addCurrentDateTime');
  }

  public getFormControlDeDupe(): AbstractControl {
    return this.operationFormGroup.get('deDupe');
  }

  public getFormControlTrimFrom(): AbstractControl {
    return this.operationFormGroup.get('trimFrom');
  }

  public getFormControlMaxLength(): AbstractControl {
    return this.operationFormGroup.get('maxLength');
  }

  public showHideListDataSettings() : void {
    this.isListDataSettingsExpanded = this.isListDataSettingsExpanded ? false : true;
  }

  public onOperationNameToggleValueChange(operationNameToggleChecked: boolean): void {
    let operationNameToSet = this.operatioNameToggleConfig.unCheckedLabel;
    if (operationNameToggleChecked) {
      operationNameToSet = this.operatioNameToggleConfig.checkedLabel;
    }
    if (operationNameToggleChecked && this.isEntityStateAttributeOfTypeDateTime()
      && this.getEntityStateAttributeDataType() === DataTypes.array && this.getFormControlAddCurrentDateTime().value) {
      operationNameToSet = OperationName.APPEND_CURRENT_DATE_TIME;
    }
    if (!operationNameToggleChecked && this.isEntityStateAttributeOfTypeDateTime() && this.getEntityStateAttributeDataType() === DataTypes.array) {
      this.setFormControlAddCurrentDateTime(false);
    }
    this.setFormControlOperationName(operationNameToSet);
  }

  public onSetCurrentDateTimeCheckboxChange(currentDateTimeCheckboxChecked: boolean): void {
    if (currentDateTimeCheckboxChecked) {
      this.setFormControlValue('');
      this.getFormControlValue().disable();
      this.setFormControlOperationName(OperationName.SET_CURRENT_DATE_TIME);
      this.setFormControlValueType(ValueType.CONSTANT);
    } else {
      this.getFormControlValue().enable();
      this.setFormControlOperationName(OperationName.SET);
    }
  }

  public onAddCurrentDateTimeCheckboxChange(currentDateTimeCheckboxChecked: boolean): void {
    if (currentDateTimeCheckboxChecked) {
      this.setFormControlValue('');
      this.getFormControlValue().disable();
      this.setFormControlOperationName(OperationName.APPEND_CURRENT_DATE_TIME);
      this.setFormControlValueType(ValueType.CONSTANT);
    } else {
      this.getFormControlValue().enable();
      this.setFormControlOperationName(OperationName.ADD);
    }
  }

  public getOperationNameToDisplay(): string {
    let operationName = this.getFormControlOperationName().value;
    if (operationName === OperationName.SET_CURRENT_DATE_TIME) {
      operationName = OperationName.SET;
    }
    return operationName;
  }

  public showOperationNameToggleButton(): boolean {
    return this.getEntityStateAttributeDataType() === DataTypes.number || this.getEntityStateAttributeDataType() === DataTypes.array;
  }

  public isAddOperation(): boolean {
    return this.getFormControlOperationName().value === this.operationNames.ADD
    || this.getFormControlOperationName().value === this.operationNames.APPEND_CURRENT_DATE_TIME;
  }

  private initOperationNameToggleConfig(operationName: OperationName, dataType: DataTypes): void {
    if (dataType === DataTypes.number && !this.isEntityStateAttributeOfTypeDateTime()) {
      this.operatioNameToggleConfig.checkedLabel = OperationName.INCREMENT;
      this.operatioNameToggleConfig.unCheckedLabel = OperationName.SET;
      if (operationName === OperationName.INCREMENT) {
        this.operatioNameToggleConfig.checked = true;
      }
    }
    if (dataType === DataTypes.array) {
      this.operatioNameToggleConfig.checkedLabel = OperationName.ADD;
      this.operatioNameToggleConfig.unCheckedLabel = OperationName.SET;
      if (operationName === OperationName.ADD) {
        this.operatioNameToggleConfig.checked = true;
      }
      if (operationName === OperationName.APPEND_CURRENT_DATE_TIME) {
        this.operatioNameToggleConfig.checkedLabel = OperationName.ADD;
        this.operatioNameToggleConfig.checked = true;
      }
    }
  }

  private configureForm() {
    this.setUpEmptyForm();
    this.setFormControlOperationName(this.ruleThenClauseOperation.name);
    const value = this.getInputArgument(ArgumentName.VALUE).value;
    const valueType: ValueType = this.getInputArgument(ArgumentName.VALUE_TYPE).value as ValueType;
    this.setFormControlValueType(valueType);
    this.setFormControlValue(value);
    if (this.ruleThenClauseOperation.name === OperationName.SET_CURRENT_DATE_TIME) {
      this.setFormControlSetCurrentDateTime(true);
    }
    if (this.ruleThenClauseOperation.name === OperationName.APPEND_CURRENT_DATE_TIME) {
      this.setFormControlAddCurrentDateTime(true);
    }
    const isDataTypeArray: boolean = this.getEntityStateAttributeDataType() === DataTypes.array;
    const isOperationToSetConstantDateTime: boolean
    = this.isOperationToSetConstantDateTime(this.ruleThenClauseOperation.name, valueType, this.isEntityStateAttributeOfTypeDateTime());
    if (value && this.isValueTypeConstant() && isOperationToSetConstantDateTime && !isDataTypeArray) {
      const epochMillis: number = value as number;
      this.setFormControlValue(new Date(epochMillis));
    }
    const isOperationToAddConstantDateTime: boolean
    = this.isOperationToAddConstantDateTime(this.ruleThenClauseOperation.name, valueType, this.isEntityStateAttributeOfTypeDateTime());
    if (value && this.isValueTypeConstant() && (isOperationToSetConstantDateTime || isOperationToAddConstantDateTime)
      && isDataTypeArray) {
      const dateTimes: number[] = value as number[];
      if (dateTimes && dateTimes.length > 0) {
        const epochMillis: number = value[0] as number;
        this.setFormControlValue(new Date(epochMillis));
      }
    }
    if (value && this.isValueTypeConstant() && isDataTypeArray
      && !isOperationToSetConstantDateTime && !isOperationToAddConstantDateTime
      && this.ruleThenClauseOperation.name !== OperationName.APPEND_CURRENT_DATE_TIME) {
      this.setFormControlValue((value as string[]).join());
    }
    if (this.ruleThenClauseOperation.name === OperationName.ADD || this.ruleThenClauseOperation.name === OperationName.APPEND_CURRENT_DATE_TIME) {
      this.isListDataSettingsExpanded = true;
      this.setFormControlDeDupe(this.getInputArgument(ArgumentName.DE_DUPE).value as boolean);
      this.setFormControlTrimForm(this.getInputArgument(ArgumentName.TRIM_FROM).value as TrimForm);
      this.setFormControlMaxLength(this.getInputArgument(ArgumentName.MAX_LENGTH).value as number);
    }
    if (this.isReadOnlyRule) {
      this.makeFormReadOnly();
    }
  }

  private makeFormReadOnly(): void {
    this.getFormControlSetCurrentDateTime().disable();
    this.getFormControlValue().disable();
    this.getFormControlDeDupe().disable();
    this.getFormControlTrimFrom().disable();
    this.getFormControlMaxLength().disable();
  }

  private setUpEmptyForm(): void {
    this.operationFormGroup = this.formBuilder.group({
      operationName: new UntypedFormControl(null),
      valueType: new UntypedFormControl(null),
      value: new UntypedFormControl(null),
      setCurrentDateTime: new UntypedFormControl(false),
      addCurrentDateTime: new UntypedFormControl(false),
      deDupe: new UntypedFormControl(true),
      trimFrom: new UntypedFormControl(TrimForm.TOP),
      maxLength: new UntypedFormControl(['', [Validators.required, Validators.max(100), Validators.min(1)]]
      )
    });
    this.getFormControlSetCurrentDateTime().valueChanges.subscribe((value: boolean) => {
      this.onSetCurrentDateTimeCheckboxChange(value);
    });
    this.getFormControlAddCurrentDateTime().valueChanges.subscribe((value: boolean) => {
      this.onAddCurrentDateTimeCheckboxChange(value);
    });
  }

  private setFormControlOperationName(operationName: OperationName): AbstractControl {
    const attributeFormControl: AbstractControl = this.getFormControlOperationName();
    attributeFormControl.patchValue(operationName);
    return attributeFormControl;
  }

  private setFormControlValueType(valueType: ValueType): AbstractControl {
    const valueTypeFormControl: AbstractControl = this.getFormControlValueType();
    valueTypeFormControl.patchValue(valueType);
    return valueTypeFormControl;
  }

  private setFormControlValue(value: unknown): AbstractControl {
    const valueFormControl: AbstractControl = this.getFormControlValue();
    valueFormControl.patchValue(value);
    return valueFormControl;
  }

  private setFormControlSetCurrentDateTime(value: boolean): AbstractControl {
    const setCurrentDateTimeFormControl: AbstractControl = this.getFormControlSetCurrentDateTime();
    setCurrentDateTimeFormControl.patchValue(value);
    return setCurrentDateTimeFormControl;
  }

  private setFormControlAddCurrentDateTime(value: boolean): AbstractControl {
    const addCurrentDateTimeFormControl: AbstractControl = this.getFormControlAddCurrentDateTime();
    addCurrentDateTimeFormControl.patchValue(value);
    return addCurrentDateTimeFormControl;
  }

  private setFormControlDeDupe(value: boolean): AbstractControl {
    const valueFormControl: AbstractControl = this.getFormControlDeDupe();
    valueFormControl.patchValue(value);
    return valueFormControl;
  }

  private setFormControlTrimForm(value: TrimForm): AbstractControl {
    const valueFormControl: AbstractControl = this.getFormControlTrimFrom();
    valueFormControl.patchValue(value);
    return valueFormControl;
  }

  private setFormControlMaxLength(value: number): AbstractControl {
    const valueFormControl: AbstractControl = this.getFormControlMaxLength();
    valueFormControl.patchValue(value);
    return valueFormControl;
  }

  private setValueToEventAttribute(eventAttributeMetaData: AttributeMetadata): void {
    const eventAttribute: Attribute = new Attribute();
    eventAttribute.name = eventAttributeMetaData.name;
    eventAttribute.path = eventAttributeMetaData.path;
    eventAttribute.dataType = eventAttributeMetaData.type;
    this.setFormControlValue(eventAttribute);
  }

  private getOperationArgumentValueType(): Argument {
    const argumentValueType: Argument = new Argument();
    argumentValueType.name = ArgumentName.VALUE_TYPE;
    argumentValueType.value = this.getFormControlValueType().value;
    return argumentValueType;
  }

  private getOperationArgumentValue(): Argument {
    const value = this.getFormControlValue().value;
    const argumentValue: Argument = new Argument();
    argumentValue.name = ArgumentName.VALUE;
    argumentValue.value = value;
    if (this.getFormControlOperationName().value === OperationName.SET_CURRENT_DATE_TIME
      || this.getFormControlOperationName().value === OperationName.APPEND_CURRENT_DATE_TIME) {
      argumentValue.value = [];
      return argumentValue;
    }
    const isOperationToSetConstantDateTime: boolean = this.isOperationToSetConstantDateTime(
      this.getFormControlOperationName().value, this.getFormControlValueType().value, this.isEntityStateAttributeOfTypeDateTime());
    if (value && isOperationToSetConstantDateTime && this.getEntityStateAttributeDataType() !== DataTypes.array) {
      argumentValue.value = new Date(value as string).getTime();
      if (this.getFormControlValue().dirty) {
        argumentValue.value = (value as Date).setSeconds(0, 0);
      }
    }
    const isOperationToAddConstantDateTime: boolean = this.isOperationToAddConstantDateTime(
      this.getFormControlOperationName().value, this.getFormControlValueType().value, this.isEntityStateAttributeOfTypeDateTime());
    if (value && (isOperationToSetConstantDateTime || isOperationToAddConstantDateTime)
      && this.getEntityStateAttributeDataType() === DataTypes.array) {
      argumentValue.value = [new Date(value as string).getTime()];
      if (this.getFormControlValue().dirty) {
        argumentValue.value = [(value as Date).setSeconds(0, 0)];
      }
    }
    if (value && !isOperationToSetConstantDateTime && !isOperationToAddConstantDateTime && this.getEntityStateAttributeDataType() === DataTypes.array
    && this.isValueTypeConstant()) {
      argumentValue.value = (value as string).split(',');
    }
    return argumentValue;
  }

  private getOperationArgumentDeDupe(): Argument {
    const argumentValue: Argument = new Argument();
    argumentValue.name = ArgumentName.DE_DUPE;
    argumentValue.value = this.getFormControlDeDupe().value;
    return argumentValue;
  }

  private getOperationArgumentTrimFrom(): Argument {
    const argumentValue: Argument = new Argument();
    argumentValue.name = ArgumentName.TRIM_FROM;
    argumentValue.value = this.getFormControlTrimFrom().value;
    return argumentValue;
  }

  private getOperationArgumentMaxLength(): Argument {
    const argumentValue: Argument = new Argument();
    argumentValue.name = ArgumentName.MAX_LENGTH;
    argumentValue.value = this.getFormControlMaxLength().value;
    return argumentValue;
  }

  private isNodeOfTypeEventAttribute(attribute: TreeNode): boolean {
    return attribute.nodeType === SourceType.EVENT;
  }

  private isCompatibleDataType(attributeMetadataId: string) {
    const eventAttributeMetaData: AttributeMetadata = Object.assign(new AttributeMetadata(), this.attributeMap[attributeMetadataId]);
    const entityStateAttribute: EntityStateAttribute = this.getInputArgument(ArgumentName.ATTRIBUTE).value as EntityStateAttribute;
    if (eventAttributeMetaData.getAttributeDataType() === entityStateAttribute.dataType) {
      return true;
    }
    return false;
  }

  private isOperationToSetConstantDateTime(operationName: OperationName, valueType: ValueType, entityStateAttributeOfTypeDateTime: boolean): boolean {
    if (operationName === OperationName.SET
      && valueType === ValueType.CONSTANT
      && entityStateAttributeOfTypeDateTime) {
      return true;
    }
    return false;
  }

  private isOperationToAddConstantDateTime(operationName: OperationName, valueType: ValueType, entityStateAttributeOfTypeDateTime: boolean): boolean {
    if (operationName === OperationName.ADD
      && valueType === ValueType.CONSTANT
      && entityStateAttributeOfTypeDateTime) {
      return true;
    }
    return false;
  }

  private validateListArguments() : boolean {
    const formControlMaxLength = this.getFormControlMaxLength();
    const maxLength: number = this.getFormControlMaxLength().value;
    if (maxLength > Constants.maxValue || maxLength < Constants.minValue) {
      formControlMaxLength.setErrors({ 'required': true });
      return false;
    }
    return true;
  }

}