import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Messages } from 'src/app/shared/message';
import { PackageType } from 'src/app/shared/models/package-builder/package-type-enum';
import { IQDecisionsService } from 'src/app/shared/services/iqdecisions.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { UtilService } from 'src/app/shared/services/util-service';

@Component({
  selector: 'app-iqdecisions-view-model-parameters',
  templateUrl: './iqdecisions-view-model-parameters.component.html',
  styleUrls: ['./iqdecisions-view-model-parameters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IqdecisionsViewModelParametersComponent implements OnInit {

  @Input() configuredRecommendation;
  @Input() isEditMode = false;
  @Input() modelParametersFormControl: UntypedFormGroup;
  @Input() saveForLater: boolean = false;

  public messages = Messages;
  public categoryLevelsList = [];
  public choiceTypeList = [];
  parentId: any;

  constructor(
    private parentContext: ParentContextService,
    private iqDecisionsService: IQDecisionsService
  ) {}

  ngOnInit(): void {
    this.parentId = this.parentContext.getParentContext();
    this.doGetPackagesTypes(this.configuredRecommendation.company.id);
  }

  public get itemsPerInputLevel(): AbstractControl {
    return this.modelParametersFormControl.get('itemsPerInputLevel');
  }
    public get itemsChoiceTypeName(): AbstractControl {
    return this.modelParametersFormControl.controls.itemsChoiceTypeName;
  }

  public onItemChange(event: string): void {
    this.categoryLevelsList.forEach(val => {
      if (event === val.name) {
        this.modelParametersFormControl.patchValue({
          itemsChoiceTypeId: val.id
        });
      }
    });
  }

  public doGetPackagesTypes(companyId: string): void {
    this.iqDecisionsService
      .getPackagedTypes(companyId, this.parentId).subscribe((res) => {
        this.categoryLevelsList = JSON.parse(UtilService.unescapedJsonString(res["result"].template.metadataJson)).choiceTypes;
        this.categoryLevelsList.forEach(val => {
          if (this.configuredRecommendation.metadataConfiguration?.packageInfo.itemsChoiceType && this.configuredRecommendation.metadataConfiguration?.packageInfo.itemsChoiceType.name === val.name) {
            this.modelParametersFormControl.patchValue({
              itemsChoiceTypeId: val.id,
              itemsPerInputLevel: this.configuredRecommendation.metadataConfiguration.packageInfo.itemsPerInputLevel
            });
          }
          this.choiceTypeList.push(val.name);
        });
      });
  }
}
