import { AuditHistory } from './audit-history';

export class AuditSearchConfiguration {

	entityId: string;
	dateRange: DateRange;
	pageSize: number;
	lastEvaluatedKey: {};
	auditHistory: AuditHistory[]; 
	parentId: string;

	public static getAuditSearchConfig(entityId: string, pageSize: number): AuditSearchConfiguration {
		const audit: AuditSearchConfiguration = new AuditSearchConfiguration();
		audit.entityId = entityId;
		audit.pageSize = pageSize;
		return audit;
	}

	public static getAuditSearchConfigByDate(entityId: string, pageSize: number,
			 startDate: number, endDate: number): AuditSearchConfiguration {
		let audit: AuditSearchConfiguration = this.getAuditSearchConfig(entityId, pageSize);
		audit.dateRange = new DateRange(startDate, endDate);
		return audit;
	}

}

class DateRange {
	startDate: number;
	endDate: number;

	constructor(startDate: number, endDate: number) {
		this.startDate = startDate;
		this.endDate = endDate;
	}
}