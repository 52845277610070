import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { UnsavedChangesGuard } from 'src/app/shared/guard/unsaved-changes.guard';
import { IQdecisionsConfigurationSingleModelPackageComponent } from
  './iqdecisions-configuration-single-model-package/iqdecisions-configuration-single-model-package.component';
import { IQDecisionsHomeComponent } from './iqdecisions-home/iqdecisions-home.component';
import { IQDecisionsNewMultiModelComponent } from './iqdecisions-multi-model/iqdecisions-multi-model.component';
import { IQDecisionsViewSingleModelComponent } from './iqdecisions-view-single-model/iqdecisions-view-single-model.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: IQDecisionsHomeComponent
  },
  {
    path: 'add/singleModelPackage',
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: IQdecisionsConfigurationSingleModelPackageComponent
  },
  {
    path: 'add/multiModelPackage',
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: IQDecisionsNewMultiModelComponent
  },
  {
    path: ':id',
    canActivate: [AuthGuard],
    component: IQDecisionsViewSingleModelComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IQDecisionsRoutingModule { }