import { ExportDestination } from './export-destination';
import { BatchExtractStatus } from './batch-extract-status';
import { Schedule } from './schedule';
import { Recurrence } from './recurrence';
import { ServiceResponse } from '../service-response';

export class ConfiguredBatchExtract {

  public id: string;
  public name: string;
  public eventSource: string;
  public companyId: string;
  public eventNames: string[];
  public exportDestination: ExportDestination;
  public status: BatchExtractStatus;
  public schedule: Schedule;
  public recurrence: Recurrence;
  public modifiedDate: number;
  public modifiedBy: string;
  public eventSourceName: string;

  public constructor(response?: ServiceResponse) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response.result)));
      const exportDestination: ExportDestination = new ExportDestination();
      exportDestination.url = this.exportDestination.url;
      this.exportDestination = exportDestination;

      const schedule: Schedule = new Schedule();
      schedule.startTime = this.schedule.startTime;
      schedule.endTime = this.schedule.endTime;
      this.schedule = schedule;

      const recurrence: Recurrence = new Recurrence();
      recurrence.frequency = this.recurrence.frequency;
      recurrence.interval = this.recurrence.interval;
      this.recurrence = recurrence;
    }
  }

}