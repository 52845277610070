import { ServiceResponse } from '../service-response';
import { Exclusion } from './exclusion';

export class ConfiguredExclusion {

  public id: string = '';
  public name: string = '';
  public exclusion: string = '';
  public type: string = '';
  public typeNameForDisplay: string = '';
  public modifiedDate: string = '';
  public parentId: string = '';
  public routerLink: string = '';
  public description: string = '';
  public active = false;
  public modifiedBy: string;
  constructor(result?: ServiceResponse) {
    if (result) {
      Object.assign(this, JSON.parse(JSON.stringify(result.result)));
    }
  }

  public getExclusion(): Exclusion {
    return Object.assign(new Exclusion(), JSON.parse(this.exclusion));
  }

}