import { Component, OnInit } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BluePrintTableColumnType } from 'src/app/shared/blueprint-table-column-type';
import { BaseComponent } from '../../client/base/base.component';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { Parent } from 'src/app/shared/models/parent';
import { ParentService } from 'src/app/shared/services/parent.service';
import { DatePipe } from '@angular/common';
import { Constants } from 'src/app/shared/constants';
import { VerticalType } from 'src/app/shared/models/vertical-type.enum';

@Component({
  selector: 'app-parents-home',
  templateUrl: './parents-home.component.html'
})
export class ParentsHomeComponent extends BaseComponent implements OnInit {

  // Table configuration
  public isDataLoading = false;
  public columns = [];
  public sorts = [];
  public properties = {};
  public configuredParentTableList = [];
  public configuredParents: Parent[];
  public configuredParentTableFilteredData = [];
  private parentId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public parentContextService: ParentContextService,
    private parentService: ParentService,
    private route: ActivatedRoute,
    private router: Router,
    public authorizationService: AuthorizationService,
    private datePipe: DatePipe) {
    super();
    this.parentId = this.parentContextService.getParentContext();
  }

  ngOnInit(): void {
    this.initDataTableConfig();
    this.getConfiguredParents();
  }

  private initDataTableConfig(): void {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Client Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          type: BluePrintTableColumnType.LINK,
          link: {
            element: 'a',
            ariaLabel: '#{name}',
            path: '#{routerLink}'
          }
        },
        {
          headerText: 'Client ID',
          key: 'parentId',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Vertical Type',
          key: 'verticalType',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Reporting Enabled',
          key: 'reportingEnabled',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Updated',
          key: 'updated',
          multiHeaderKeys: ['updatedDate', 'updatedBy'],
          multiHeaderValues: ['Updated Date', 'Updated By'],
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Created',
          key: 'created',
          multiHeaderKeys: ['createdDate', 'createdBy'],
          multiHeaderValues: ['Created Date', 'Created By'],
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private initconfiguredParentTableList(): void {
    if (!this.configuredParents || this.configuredParents === undefined) {
      return;
    }
    this.configuredParentTableFilteredData = [];
    this.configuredParents.forEach(parent => {
      const configuredParentTableRow = {};
      configuredParentTableRow['id'] = parent.id;
      configuredParentTableRow['name'] = parent.name;
      configuredParentTableRow['routerLink'] = '/admin/clients/edit/' + parent.id;
      configuredParentTableRow['parentId'] = parent.id;
      configuredParentTableRow['verticalType'] = VerticalType[parent.verticalType];
      configuredParentTableRow['reportingEnabled'] = parent.reportingEnabled ? 'Yes' : 'No';
      configuredParentTableRow['updatedDate'] = this.datePipe.transform(new Date(parent.modifiedDate), 'MM/dd/yy hh:mm aaaa') + Constants.EST_CONST;
      configuredParentTableRow['updatedBy'] = Constants.BY_CONST + parent.modifiedBy;
      configuredParentTableRow['createdDate'] = this.datePipe.transform(new Date(parent.createdDate), 'MM/dd/yy hh:mm aaaa') + Constants.EST_CONST;
      configuredParentTableRow['createdBy'] = Constants.BY_CONST + parent.createdBy;
      this.configuredParentTableFilteredData.push(configuredParentTableRow);
    });
    this.configuredParentTableList = this.configuredParentTableFilteredData.slice(0, 10);
  }

  private getConfiguredParents(): void {
    this.parentService.getParents().pipe(takeUntil(this.destroyed$)).subscribe((res: Parent[]) => {
      this.configuredParents = res['result'];
      this.initconfiguredParentTableList();
      this.isDataLoading = false;
    }, error => {
      console.log('error: ', error);
      this.isDataLoading = false;
    });
  }

   ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
