<app-rule-action-builder *ngIf="configuredRule.isRuleTypePackaged()"
  #thenClauseActionBuilder
  [formGroup]="formGroup"
  [filteredConfiguredActions]="filteredConfiguredActions"
  [actionDestinations]="actionDestinations"
  [selectedEventSchema]="selectedEventSchema"
  [eventSources]="eventSources"
  [configuredEventDetails]="configuredEventDetails"
  [configuredRule]="configuredRule"
  [isReadOnlyRule]="isReadOnlyRule"
  [showDeleteButton]="showDeleteButton"
  [validationRequired]="validationRequired"
></app-rule-action-builder>
<app-rule-then-clause-operation-builder
  [formGroup]="formGroup"
  [configuredRule]="configuredRule"
  [attributeMap]="attributeMap"
  [isReadOnlyRule]="isReadOnlyRule"
  [showDeleteButton]="showDeleteButton"
  [validationRequired]="validationRequired"
></app-rule-then-clause-operation-builder>
