<div class="row horizontal-scrollable">
  <div class="col-12">
    <bp-landing-page-header headingText="Users and Roles"></bp-landing-page-header>
      <coreui-tab-group coreUITabRouterGroup>
        <coreui-tab *ngIf="authorizationService.isAllowed('PKG_USR_GRP', ['READ']) || authorizationService.isAllowed('CFG_USR_GRP', ['CREATE', 'UPDATE', 'READ'])"
          routerLink="./usergroups" label="User Groups" [active]="true"></coreui-tab>
        <coreui-tab *ngIf="authorizationService.isAllowed('PKG_ROLE', ['READ']) || authorizationService.isAllowed('CFG_ROLE', ['CREATE', 'UPDATE', 'READ'])"
          routerLink="./users" label="Users"></coreui-tab>
        <coreui-tab *ngIf="authorizationService.isAllowed('USR', ['CREATE', 'UPDATE', 'READ'])" routerLink="./roles" label="Roles"></coreui-tab>
      </coreui-tab-group>
    <router-outlet></router-outlet>
  </div>
</div>
