import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfiguredBatchExtract } from '../models/configured-batch-extract/configured-batch-extract';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';

@Injectable({
  providedIn: 'root'
})
export class ConfiguredBatchExtractService {

  private static readonly configuredBatchExtractUrl: string = 'batch-extract/configured';
  private host: string = environment.host;
  private base: string = environment.base;
  private http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  public addConfiguredBatchExtract(parentId: string, configuredBatchExtract: ConfiguredBatchExtract): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredBatchExtractService.configuredBatchExtractUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post<ServiceResponse>(url, configuredBatchExtract, options);
  }

  public editConfiguredBatchExtract(parentId: string, configuredBatchExtract: ConfiguredBatchExtract): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredBatchExtractService.configuredBatchExtractUrl + '/' + configuredBatchExtract.id;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.put<ServiceResponse>(url, configuredBatchExtract, options);
  }

  public getConfiguredBatchExtractByParent(parentId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredBatchExtractService.configuredBatchExtractUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get<ServiceResponse>(url, options);
  }

  public getConfiguredBatchExtractById(parentId: string, id: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredBatchExtractService.configuredBatchExtractUrl + '/' + id;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get<ServiceResponse>(url, options);
  }

  public deleteConfiguredBatchExtractById(parentId: string, id: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredBatchExtractService.configuredBatchExtractUrl + '/' + id;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.delete<ServiceResponse>(url, options);
  }

}