import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ConfiguredBatchExtract } from 'src/app/shared/models/configured-batch-extract/configured-batch-extract';
import { ReplaySubject } from 'rxjs';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ConfiguredBatchExtractService } from 'src/app/shared/services/configured-batch-extract.service';
import { takeUntil } from 'rxjs/operators';
import { ConfiguredBatchExtractTableRow } from 'src/app/shared/models/configured-batch-extract/configured-batch-extract-table-row';
import { Schedule } from 'src/app/shared/models/configured-batch-extract/schedule';
import { Recurrence } from 'src/app/shared/models/configured-batch-extract/recurrence';
import * as moment from 'moment';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { EventSourceProviderType, ConfiuredEventSource } from 'src/app/shared/models/event-source';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ProgramType } from 'src/app/shared/program-type';
import { Action, Constants } from 'src/app/shared/constants';
import { Messages } from 'src/app/shared/message';
import { ServiceResponse } from 'src/app/shared/models/service-response';
import { DatePipe } from '@angular/common';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { WarningType } from 'src/app/shared/warning-options';

@Component({
  selector: 'app-batch-extract-home',
  templateUrl: './batch-extract-home.component.html',
  styleUrls: ['./batch-extract-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BatchExtractHomeComponent implements OnInit, OnDestroy {

  @ViewChild('modifiedDate', { static: true })
  public modifiedDateColumn: TemplateRef<any>;

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  private warningModal: CoreuiModalWarningComponent;

  public isDataLoading = false;
  public properties = {};

  public parentContextService: ParentContextService;
  public configuredBatchExtractTableList: ConfiguredBatchExtractTableRow[] = [];
  public configuredBatchExtractsTableFilteredData: ConfiguredBatchExtractTableRow[] = [];
  public eventSources: ConfiuredEventSource[] = [];
  public packagedConfiguredPrograms: ConfiguredProgram[] = [];
  public operationStatusMessage = '';

  private featchEventSourceInProgress = false;
  private featchCompaniesInProgress = false;
  private featchProgramsInProgress = false;
  private featchBatchExtractInProgress = false;
  private createBatchExtractInProgress = false;
  private saveBatchExtractInProgress = false;
  private deleteBatchExtractInProgress = false;

  private parentId: string;
  private pageIndices: { start: number; end: number };
  private configuredBatchExtracts: ConfiguredBatchExtract[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  private configuredBatchExtractService: ConfiguredBatchExtractService;
  private eventSourceService: EventSourceService;
  private programService: ProgramService;
  private popupService: PopupMessageService;
  private datePipe: DatePipe;
  private scheduleDateTimeDisplayFormat = 'MM/DD/YYYY';
  private isBatchExtractConfigurationModalShown: boolean;
  private configuredBatchExtractId: string;
  private batchExtractActionType: string;
  private toBeDeletedId: string;

  public constructor(parentContextService: ParentContextService,
    configuredBatchExtractService: ConfiguredBatchExtractService,
    eventSourceService: EventSourceService,
    programService: ProgramService,
    popupService: PopupMessageService,
    datePipe: DatePipe,
    public authorizationService: AuthorizationService) {
    this.parentContextService = parentContextService;
    this.configuredBatchExtractService = configuredBatchExtractService;
    this.eventSourceService = eventSourceService;
    this.programService = programService;
    this.popupService = popupService;
    this.datePipe = datePipe;
  }

  public ngOnInit(): void {
    this.parentId = this.parentContextService.getParentContext();
    this.initDataTableConfig();
    this.initConfiguredEventSourceProviders();
    this.initPackagedConfiuredPrograms();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public isOperationInProcess(): boolean {
    return this.featchEventSourceInProgress || this.featchCompaniesInProgress
    || this.featchProgramsInProgress || this.deleteBatchExtractInProgress
    || this.featchBatchExtractInProgress || this.createBatchExtractInProgress
    || this.saveBatchExtractInProgress;
  }

  public handlePageChange(pageData: {currentPage: number; rowsPerPage: number; indices: {start: number; end: number}}): void {
    this.pageIndices = { ...pageData.indices };
    this.configuredBatchExtractTableList = this.configuredBatchExtractsTableFilteredData.slice(
      pageData.indices.start,
      pageData.indices.end
    );
  }

  public handleSort(sort: {column: string; order: string}): void {
    if (sort.order === Constants.TABLE_SORT_ASCENDING) {
      this.sortByKeyAsc(this.configuredBatchExtractsTableFilteredData, sort.column);
    } else {
      this.sortByKeyDesc(this.configuredBatchExtractsTableFilteredData, sort.column);
    }
    this.configuredBatchExtractTableList = this.configuredBatchExtractsTableFilteredData.slice(
      this.pageIndices.start,
      this.pageIndices.end
    );
  }

  public actionClick(event: {action: string; rowId: string}): void {
    if (event.action === Action.EDIT.toLowerCase()) {
      this.handleEditBatchExtractAction(event.rowId);
    }
    if (event.action === Action.DELETE.toLowerCase()) {
      this.handleDeleteBatchExtractAction(event.rowId);
    }
  }

  public handleAddBatchExtractAction(): void {
    this.isBatchExtractConfigurationModalShown = true;
    this.batchExtractActionType = Action.CREATE;
    this.configuredBatchExtractId = '';

  }

  public handleEditBatchExtractAction(id: string): void {
    this.isBatchExtractConfigurationModalShown = true;
    this.batchExtractActionType = Action.EDIT;
    this.configuredBatchExtractId = id;
  }

  public getActionType(): string {
    return this.batchExtractActionType;
  }

  public getId(): string {
    return this.configuredBatchExtractId;
  }

  public getParentId(): string {
    return this.parentId;
  }

  public getEventSources(): ConfiuredEventSource[] {
    return this.eventSources;
  }

  public getPackagedConfiguredPrograms(): ConfiguredProgram[] {
    return this.packagedConfiguredPrograms;
  }

  public getConfiguredBatchExtracts(): ConfiguredBatchExtract[] {
    return this.configuredBatchExtracts;
  }

  public isBatchExtractConfigurationModalDisplayed(): boolean {
    return this.isBatchExtractConfigurationModalShown;
  }

  public setBatchExtractConfigurationModalStatus($event: boolean): void {
    this.isBatchExtractConfigurationModalShown = $event;
  }

  public captureBatchExtractAction(result: { action: string; configuredBatchExtract: ConfiguredBatchExtract }): void {
    if (!result) {
      return;
    }
    switch (result.action) {
      case Action.CREATE:
        this.createBatchExtract(result.configuredBatchExtract);
        break;
      case Action.DELETE:
        this.handleDeleteBatchExtractAction(result.configuredBatchExtract.id);
        break;
      case Action.EDIT:
        this.editBatchExtract(result.configuredBatchExtract);
        break;
    }
  }

  public handleDecision(userConfirmation: boolean): void {
    if (userConfirmation) {
      this.deleteBatchExtract(this.toBeDeletedId);
    }
  }

  private initDataTableConfig() {
    this.isDataLoading = true;
    this.properties = {
      rowId: 'id',
      columns: [
        {
          headerText: 'Batch Extract Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true,
          editAction: {
            action: 'edit',
            element: 'a',
            text: '#{name}',
            ariaLabel: 'Edit #{id}',
            class: 'link-primary',
            modal: true
          }
        },
        {
          headerText: 'Event Source Provider',
          key: 'eventSource',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Event Names',
          key: 'eventNames',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Company Name',
          key: 'companyName',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Schedule',
          key: 'schedule',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Send Frequency',
          key: 'recurrence',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Modified Date',
          key: 'modifiedDate',
          isSortable: true,
          isColumnDisplayed: true,
          template: this.modifiedDateColumn
        },
        {
          headerText: 'Modified By',
          key: 'modifiedBy',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private sortByKeyAsc(array: ConfiguredBatchExtractTableRow[], key: string) {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      if (!x || x === undefined) {
        return -1;
      }
      if (!y || y === undefined) {
        return 1;
      }
      return x < y ? -1 : 1;
    });
  }

  private sortByKeyDesc(array: ConfiguredBatchExtractTableRow[], key: string) {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      if (!x || x === undefined) {
        return 1;
      }
      if (!y || y === undefined) {
        return -1;
      }
      return x > y ? -1 : 1;
    });
  }

  private handleDeleteBatchExtractAction(id: string): void {
    this.toBeDeletedId = id;
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteBatchExtract,
      msg: Messages.deleteBatchExtractConsquence,
      msg2: [Messages.confirmDeleteBatchExtract]
    });
  }

  private createBatchExtract(configuredBatchExtract: ConfiguredBatchExtract): void {
    this.createBatchExtractInProgress = true;
    this.operationStatusMessage = Messages.savingConfiguredBatchExtract;
    this.configuredBatchExtractService.addConfiguredBatchExtract(this.parentId, configuredBatchExtract)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
        this.createBatchExtractInProgress = false;
        const configuredBatchExtract: ConfiguredBatchExtract = new ConfiguredBatchExtract(serviceResponse);
        this.addInConfiguredBatchExtractsList(configuredBatchExtract);
        this.popupService.showSuccessMessage(Messages.savedConfiguredBatchExtract);
      }, err => {
        this.createBatchExtractInProgress = false;
        this.popupService.showErrorMessage(Messages.unableToSaveConfiguredBatchExtract + ' ' + err.error.statusMessage);
      });
  }

  private editBatchExtract(configuredBatchExtract: ConfiguredBatchExtract): void {
    this.saveBatchExtractInProgress = true;
    this.operationStatusMessage = Messages.savingConfiguredBatchExtract;
    this.configuredBatchExtractService.editConfiguredBatchExtract(this.parentId, configuredBatchExtract)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
        this.saveBatchExtractInProgress = false;
        const configuredBatchExtract: ConfiguredBatchExtract = new ConfiguredBatchExtract(serviceResponse);
        this.deleteFromConfiguredBatchExtractsList(configuredBatchExtract.id);
        this.addInConfiguredBatchExtractsList(configuredBatchExtract);
        this.popupService.showSuccessMessage(Messages.savedConfiguredBatchExtract);
      }, err => {
        this.saveBatchExtractInProgress = false;
        this.popupService.showErrorMessage(Messages.unableToSaveConfiguredBatchExtract + ' ' + err.error.result);
      });
  }

  private addInConfiguredBatchExtractsList(configuredBatchExtract: ConfiguredBatchExtract): void {
    this.configuredBatchExtracts.push(configuredBatchExtract);
    this.initConfiguredBatchExtractTableList();
  }

  private deleteBatchExtract(id: string): void {
    this.deleteBatchExtractInProgress = true;
    this.operationStatusMessage = Messages.deletingConfiguredBatchExtract;
    this.configuredBatchExtractService.deleteConfiguredBatchExtractById(this.parentId, id)
      .pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.deleteBatchExtractInProgress = false;
        this.deleteFromConfiguredBatchExtractsList(id);
        this.popupService.showDeleteMessage(Messages.deletedConfiguredBatchExtract);
      }, err => {
        this.deleteBatchExtractInProgress = false;
        this.popupService.showErrorMessage(Messages.unableToDeleteConfiguredBatchExtract + ' ' + err.error.statusMessage);
      });
  }

  private deleteFromConfiguredBatchExtractsList(id: string): void {
    const location: number = this.configuredBatchExtracts.findIndex(
      configuredBatchExtract => configuredBatchExtract.id === id);
    this.configuredBatchExtracts.splice(location, 1);
    this.initConfiguredBatchExtractTableList();
  }

  private initConfiguredBatchExtracts(): void {
    this.featchBatchExtractInProgress = true;
    this.operationStatusMessage = Messages.loadingConfiguredBatchExtract;
    this.configuredBatchExtractService.getConfiguredBatchExtractByParent(
      this.parentId).pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ServiceResponse) => {
      this.configuredBatchExtracts = serviceResponse.result as ConfiguredBatchExtract[];
      this.initDataTableConfig();
      this.initConfiguredBatchExtractTableList();
      this.isDataLoading = false;
      this.featchBatchExtractInProgress = false;
    },
    () => {
      this.isDataLoading = false;
      this.featchBatchExtractInProgress = false;
    });
  }

  private initConfiguredEventSourceProviders(): void {
    this.featchEventSourceInProgress = true;
    this.operationStatusMessage = Messages.loadingConfiguredBatchExtract;
    this.eventSourceService.getEventSources(this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(data => {
      const allConfiguredEventSources: ConfiuredEventSource[] = data['result'];
      this.eventSources = allConfiguredEventSources.filter(
        nextConfiguredEventSources => nextConfiguredEventSources.type === EventSourceProviderType.PACKAGED);
      this.featchEventSourceInProgress = false;
      this.initConfiguredBatchExtracts();
    }, () => {
      this.featchEventSourceInProgress = false;
      this.isDataLoading = false;
    });
  }

  private initPackagedConfiuredPrograms(): void {
    this.featchProgramsInProgress = true;
    this.operationStatusMessage = Messages.loadingConfiguredBatchExtract;
    this.programService.getAllConfiguredPrograms(this.parentId)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ConfiguredProgram[]) => {
        const allConfiguredProgram: ConfiguredProgram[] = serviceResponse['result'];
        this.packagedConfiguredPrograms = allConfiguredProgram.filter(
          nextConfiguredProgram => nextConfiguredProgram.programType === ProgramType.PACKAGED);
        this.featchProgramsInProgress = false;
      }, () => {
        this.featchProgramsInProgress = false;
      });
  }

  private initConfiguredBatchExtractTableList() {
    if (!this.configuredBatchExtracts || this.configuredBatchExtracts === undefined) {
      return;
    }
    this.configuredBatchExtractsTableFilteredData = [];
    this.configuredBatchExtracts.forEach((nextConfiguredBatchExtract: ConfiguredBatchExtract) => {
      this.configuredBatchExtractsTableFilteredData.push(this.buildConfiguredBatchExtractTableRow(nextConfiguredBatchExtract));
    });
    this.configuredBatchExtractTableList = this.configuredBatchExtractsTableFilteredData.slice(0, 10);
  }

  private buildConfiguredBatchExtractTableRow(inputConfiguredBatchExtract: ConfiguredBatchExtract): ConfiguredBatchExtractTableRow {
    const configuredBatchExtract: ConfiguredBatchExtract = Object.assign(new ConfiguredBatchExtract(), inputConfiguredBatchExtract);
    configuredBatchExtract.schedule = Object.assign(new Schedule(), inputConfiguredBatchExtract.schedule);
    configuredBatchExtract.recurrence = Object.assign(new Recurrence(), inputConfiguredBatchExtract.recurrence);
    const configuredBatchExtractTableRow: ConfiguredBatchExtractTableRow = new ConfiguredBatchExtractTableRow();
    configuredBatchExtractTableRow.id = configuredBatchExtract.id;
    configuredBatchExtractTableRow.name = configuredBatchExtract.name;
    configuredBatchExtractTableRow.eventSource = configuredBatchExtract.eventSourceName;
    configuredBatchExtractTableRow.companyName = this.getCompanyName(configuredBatchExtract);
    configuredBatchExtractTableRow.eventNames = configuredBatchExtract.eventNames.join(', ');
    const startTime: number = configuredBatchExtract.schedule.startTime;
    const endTime: number = configuredBatchExtract.schedule.endTime;
    configuredBatchExtractTableRow.schedule = moment(startTime).format(this.scheduleDateTimeDisplayFormat)
      + ' to ' + moment(endTime).format(this.scheduleDateTimeDisplayFormat);
    configuredBatchExtractTableRow.recurrence = 'Every ' + configuredBatchExtract.recurrence.interval
      + ' ' + configuredBatchExtract.recurrence.frequency.toString().toLowerCase();
    configuredBatchExtractTableRow.modifiedDate = this.datePipe.transform(new Date(configuredBatchExtract.modifiedDate), 'MM/dd/yyyy hh:mm aaaa');
    configuredBatchExtractTableRow.modifiedBy = inputConfiguredBatchExtract.modifiedBy;
    configuredBatchExtractTableRow.routerLink = '/batch-extract/edit/' + configuredBatchExtract.id;
    return configuredBatchExtractTableRow;
  }

  private getCompanyName(configuredBatchExtract: ConfiguredBatchExtract): string {
    if (!configuredBatchExtract.companyId) {
      return Constants.NA;
    }
    const confiuredEventSource: ConfiuredEventSource = this.eventSources.find(
      (nextConfiuredEventSource: ConfiuredEventSource) => nextConfiuredEventSource.eventSource === configuredBatchExtract.eventSource);
    const companySetting = confiuredEventSource.settings.find(nextSetting => nextSetting.companyId === configuredBatchExtract.companyId);
    return companySetting.companyName;
  }

}
