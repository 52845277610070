<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="searchRule">Search</label>
            <div class="input-group">
                <input #searchInput type="text" class="form-control" placeholder="Search by name" id="searchRule"
                (keyup.enter)=performSearch(searchInput.value) [ngStyle]="{'width.%': width}"
                (keyup.escape)="performSearch(null); searchInput.value = ''">
                <div class="input-group-append">
                    <button class="btn btn-outline-primary" *ngIf="isSearch" (click)="performSearch(searchInput.value)">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <span class="sr-only">search rule</span>
                    </button>
                    <button class="btn btn-outline-primary" *ngIf="!isSearch" (click)="resetSearch(null); searchInput.value = ''">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        <span class="sr-only">clear rule search filter</span>
                    </button>
                </div>
                <div class="text-color-warning" *ngIf="displayMinSearchError">Minimum 3 characters required to search.</div>
                <div class="text-color-warning" *ngIf="searchExist">No search results to display.</div>
            </div>
        </div>
    </div>
</div>