<app-base-template>
  <app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
  <app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
    <div class="container-fluid object-page-header pt-4">
      <div class="row align-items-center py-sm-3">
        <div class="col">
          <h1 class="text-style-1 mb-0">IQ Decisions</h1>
        </div>
        <div class="col text-right" *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['CREATE'])">
          <coreui-combo-button buttonLabel="+ New Package" buttonType="primary" (buttonClick)="newModel('add/singleModelPackage')">
            <button type="button" routerLink="add/singleModelPackage" coreUIMenuItem>Single-Model</button>
            <button type="button" routerLink="add/multiModelPackage" coreUIMenuItem>Multi-Model</button>
          </coreui-combo-button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!parentContextService.isParentContextSet()">
      <div class="container-fluid">
        <div class="row">
          <div class="col text-center">
            <p class="text-style-muted-3 m-0">Please set up a Parent in Settings to create a program</p>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="container-fluid pt-5">
      <div class="row">
        <div class="col col-sm-6 col-xl-3">
          <app-ae-table-search
            label="Search"
            placeholder="Search"
            (searchEmitter)="handleSearch($event)">
          </app-ae-table-search>
        </div>
      </div>
      <coreui-tab-group aria-label="horizontal-tab-group">
          <coreui-tab label="Packages" [active]="true">
            <ng-container *ngIf="configuredIQDecisionsTableList">
              <div class="row">
                <div class="col-12">
                  <app-ae-table [isDataLoading]="isPageLoading" [configuredTableData]="configuredIQDecisionsTableFilteredData"
                    [properties]="properties" (menuButtonClicked)="performMenuButtonClickActions($event)">
                  </app-ae-table>
                </div>
              </div>
            </ng-container>
          </coreui-tab>
      </coreui-tab-group>
      <ng-template #errorLabel>
          <i coreUIIcon="radioFill" scale="0.5" class="tab-icon-error-label"></i>
          <span>Tab with Error</span>
      </ng-template>
      <ng-template #iconLabel>
          <i coreUIIcon="warning" scale="1.4" class="tab-icon-warning-label"></i>
          <span>Tab with Icon</span>
      </ng-template>
    </div>
  </app-base-template>
