import { SourceType } from './conditions/source-type';

export class TreeNode {

  id: string;
  name: string;
  title: string;
  children: TreeNode[];
  path: string;
  nodeType: SourceType;

}