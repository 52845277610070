import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { LoadingIndicatorModule } from 'epsilon-blueprint';
import { CoreUIModule } from '@epsilon/core-ui';
import { ParentsConfigurationComponent } from './parents-configuration/parents-configuration.component';
import { ParentsHomeComponent } from './parents-home/parents-home.component';
import { SystemUsersHomeComponent } from './system-users-home/system-users-home.component';
import { ClientModule } from '../client/client.module';
import { RolesHomeComponent } from './roles-home/packaged-roles-home.component';
import { PackagedRolesConfigurationComponent } from './roles-configuration/packaged-roles-configuration.component';
import { SystemUserGroupsHomeComponent } from './system-user-groups-home/system-user-groups-home.component';
import { SystemUserGroupsConfigurationComponent } from './system-user-groups-configuration/system-user-groups-configuration.component';

@NgModule({
  declarations: [
    ParentsConfigurationComponent,
    ParentsHomeComponent,
    RolesHomeComponent,
    PackagedRolesConfigurationComponent,
    SystemUsersHomeComponent,
    RolesHomeComponent,
    SystemUserGroupsHomeComponent,
    SystemUserGroupsConfigurationComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    SharedModule,
    FormsModule,
    BaseTemplateModule,
    LoadingIndicatorModule,
    CoreUIModule,
    ClientModule
  ]
})
export class AdminModule { }