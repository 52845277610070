import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { IamActionDestinationService } from 'src/app/shared/services/iam-action-destination.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Messages } from 'src/app/shared/message';
import { AwsUserCredentialComponent } from '../aws-user-credential/aws-user-credential.component';
import { ModalComponent } from '@epsilon/core-ui';

interface Alert {
  type: string;
  message: string;
}

enum NotificationType {
  SUCCESS = 'SUCCESS',
  INVALID_DATA = 'INVALID_DATA',
  NETWORK_ERROR = 'NETWORK_ERROR',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM'
}

@Component({
  selector: 'app-add-aws-action-destination-modal',
  templateUrl: './add-aws-action-destination-modal.component.html',
  styleUrls: ['./add-aws-action-destination-modal.component.scss']
})
export class AddAwsActionDestinationModalComponent implements OnInit {

  @Input() awsActionDestination = {};
  @Input() awsUserCredential = {};
  @Input() existingCustomActionDestinationId: string;
  @Input() isInitializeEditMode = false;
  @ViewChild(AwsUserCredentialComponent)
  awsUserCredentialComponent: AwsUserCredentialComponent;
  @Output()
  private isModalDisplayed = new EventEmitter<any>();
  @Output()
  private configuredActionDestination = new EventEmitter<any>();
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;

  awsActionDestinationForm: UntypedFormGroup;
  notification: Alert;
  isValidCredentials: boolean;
  operationSuccess = false;
  testButtonClicked = false;
  saveButtonClicked = false;
  isLoading = false;
  editMode = false;
  private _notification = new Subject<Alert>();

  private isModalShown: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private context: ParentContextService,
    private actionDestinationService: IamActionDestinationService,
    private popupService: PopupMessageService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.launchBasicModal();
    this.awsActionDestinationForm = this.fb.group({
      awsActionDestinationName: ['', [Validators.required]],
      awsActionDestinationDescription: ['', [Validators.required]]
    });
    this._notification.subscribe(alert => {
      this.notification = alert;
    });
    // Remove duplicate name notification when user begins changing the name
    this.getAwsActionDestinationNameControl().valueChanges.subscribe(changes => {
      this.notification = null;
    });

    // Reload on edit mode
    if (this.isInitializeEditMode) {
      this.onEditModeLoad();
    }
  }
  public getAwsActionDestinationNameControl(): any {
    return this.awsActionDestinationForm.get('awsActionDestinationName');
  }
  public getAwsActionDestinationDescriptionControl(): any {
    return this.awsActionDestinationForm.get('awsActionDestinationDescription');
  }
  public getAwsActionDestinationARNControl(): any {
    if (this.awsUserCredentialComponent) {
      return this.awsUserCredentialComponent.userNameChanged;
    } else {
      return false;
    }
  }

  public validateForRequired(c: AbstractControl): boolean {
    return c.errors && c.touched && c.errors.required;
  }
  public isSuccessOrNetworkErrorNotification(): boolean {
    return this.notification.type === NotificationType.NETWORK_ERROR || this.notification.type === NotificationType.SUCCESS;
  }
  public test(): void {
    this.testButtonClicked = true;
    this.saveButtonClicked = false;
    this.isLoading = true;
    this.actionDestinationService.testIamActionDestinationConnection(this.context.getParentContext(),
      this.actionDestinationService.buildCredentials(this).buildTestConnectionPayload()).subscribe(
      success => {
        this.operationSuccess = true;
        this.isLoading = false;
        this.isValidCredentials = true;
        this.pushSuccessNotification(this.sendNotification(Messages.successfulConnection, NotificationType.SUCCESS));
        this.finalizeAuthenticationUpdates();
      },
      failed => {
        this.operationSuccess = true;
        this.isLoading = false;
        this.isValidCredentials = false;
        if (failed.status === 400) {
          if (failed.error.statusMessage === 'FAILED') {
            this.pushErrorNotification(this.sendNotification(Messages.credentialsAreInvalid, NotificationType.INVALID_DATA));
            return;
          }
          if (failed.error.statusMessage === 'NETWORK_ERROR') {
            this.pushErrorNotification(this.sendNotification(Messages.invalidDataInputOrNetworkError, NotificationType.NETWORK_ERROR));
            return;
          }
        } else {
          this.pushErrorNotification(this.sendNotification(Messages.invalidDataInputOrNetworkError, NotificationType.NETWORK_ERROR));
        }
      }
    );
  }
  public saveChanges(): void {
    this.saveButtonClicked = true;
    this.testButtonClicked = false;
    let operationResult: Observable<Object>;
    if (this.existingCustomActionDestinationId) {
      this.isLoading = true;
      operationResult = this.actionDestinationService.doPutConfiguredActionDestination(this);
    } else {
      this.isLoading = true;
      operationResult = this.actionDestinationService.doPostConfiguredActionDestination(this);
    }
    operationResult.subscribe(
      success => {
        this.isLoading = false;
        this.operationSuccess = true;
        this.editMode = false; // applies to update operation
        this.closeBasicModal();
        this.configuredActionDestination.emit({ refresh: true });
      },
      failureMessage => {
        this.isLoading = false;
        this.operationSuccess = true;
        const errorDetail = failureMessage['error'];
        if (errorDetail.statusMessage === 'DUPLICATE_ITEM') {
          this.pushErrorNotification(this.sendNotification(Messages.duplicateName, NotificationType.DUPLICATE_ITEM));
          return;
        } else {
          this.pushErrorNotification(this.sendNotification(errorDetail['result'], NotificationType.INVALID_DATA));
        }
      }
    );
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayed.emit(this.isModalShown);
  }

  public isValidForTest(): boolean {
    return this.isInitializeEditMode ? false : this.getAwsActionDestinationARNControl();
  }
  public isValidForSave(): boolean {
    return this.awsActionDestinationForm.valid
      && this.operationSuccess
      && this.awsUserCredentialComponent.isValid()
      && this.isValidCredentials
      && !this.hasFormUpdated();
  }
  public getEmptyActionDestinationNameErrorMessage(): any {
    return Messages.emptyActionDestinationName;
  }
  public getEmptyActionDestinationDescriptionErrorMessage(): any {
    return Messages.emptyActionDestinationDescription;
  }

  private finalizeAuthenticationUpdates(): void {
    this.awsUserCredentialComponent.finalizeUpdates();
  }

  private pushSuccessNotification(alert: Alert) {
    this._notification.next(alert);
  }
  private pushErrorNotification(alert: Alert) {
    this._notification.next(alert);
  }
  private sendNotification(msg: string, type: string): Alert {
    return {
      type: type,
      message: `${msg}`
    };
  }
  private hasFormUpdated() {
    return this.awsUserCredentialComponent.hasFormUpdated();
  }

  private onEditModeLoad() {
    this.getAwsActionDestinationNameControl().setValue(this.awsActionDestination['name']);
    this.getAwsActionDestinationDescriptionControl().setValue(this.awsActionDestination['description']);
    // Set Credentials
    this.ref.detectChanges(); // To track Oauth credential component load
    this.awsUserCredentialComponent.getUserNameControl().setValue(this.awsActionDestination['userName']);
    this.awsUserCredentialComponent.getAccessKeyControl().setValue(this.awsActionDestination['accessKey']);
    this.awsUserCredentialComponent.getSecretAccessKeyControl().setValue(this.awsActionDestination['secretAccessKey']);
    this.isInitializeEditMode = false;
  }

}