<app-coreui-modal-warning (decision)="handleDeleteESDecision($event)"></app-coreui-modal-warning>
<app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-ae-button buttonText="Back to Entity States" iconPosition="left" icon="chevronLeft" routerLink="/entitystates"></app-ae-button>
    </div>
  </div>
  <div class="row">
    <div class="col ml-3 mb-3">
      <div class="h-50 d-inline-block u-min-h-2-5">
        <div *ngIf="configuredEntityForm !== undefined">
          <span class="text-style-1 mb-0">{{ configuredEntityForm.controls.name.value }}</span>
        </div>
      </div>
      <p class="mt-2">Entity State</p>
    </div>
    <ng-container *ngIf="this.operation === 'edit'">
      <div class="col-auto text-right pl-0" *ngIf="authorizationService.isAllowed('CFG_ENT_STATE', ['DELETE'])">
        <app-ae-button buttonText="Delete" (click)="deleteConfiguredEntityStates()" [disabled]="toggleHeaderButtons()">
        </app-ae-button>
      </div>
      <div class="col-auto text-right pl-0"
        *ngIf="authorizationService.isAllowed('CFG_ENT_STATE', ['CREATE','UPDATE']) && !isStandardEntityState()">
        <app-ae-button buttonText="Copy" (click)="copyConfiguredEntityStates()"></app-ae-button>
      </div>
    </ng-container>
    <div class="col-auto text-right pl-0" *ngIf="authorizationService.isAllowed('CFG_ENT_STATE', ['CREATE','UPDATE'])">
      <app-ae-button buttonText="Save" (click)="saveConfiguredEntityStates()" [disabled]="toggleHeaderButtons()"></app-ae-button>
    </div>
  </div>
</div>
