<div class="pt-4">
  <app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
</div>
<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
<form [formGroup]="configuredEntityForm"  *ngIf="!isApiServiceCalled">
    <div class="container-fluid object-page-header pt-4">
        <div class="row mb-2">
            <div class="col">
                <app-ae-button buttonText="Back to User Groups" buttonType="text-primary" iconPosition="left" icon="chevronLeft" [routerLink]="userGroupsLandingPageRouterLink"></app-ae-button>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col">
                <h1 class="mb-0">{{configuredEntityForm.controls.name.value}}</h1>
            </div>
            <div class="col-auto pl-0" *ngIf="operation === 'editUserGroup' && authorizationService.isAllowed('CFG_USR_GRP', ['DELETE']) && shouldShowActionButtons">
                <app-ae-button buttonText="Delete" (click)="showDeleteUserGroupAlert()"></app-ae-button>
            </div>
            <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_USR_GRP', ['CREATE', 'UPDATE']) && shouldShowActionButtons">
                <app-ae-button buttonText="Save" (click)="saveUserGoupeDetails()"></app-ae-button>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="my-2 mb-lg-4">User Group</p>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row pt-4">
            <div class="col-4">
                <div class="form-group">
                    <label for="userGroupNameControl">Name</label>
                        <input type="text" id="userGroupNameControl" class="form-control object-page-header-input" placeholder="Enter a name"
                        formControlName="name"/>
                    <ng-container *ngIf="isSaveClicked && configuredEntityForm.controls.name.invalid">
                        <div *ngIf="configuredEntityForm.controls.name.errors.required" class="text-color-warning">
                            Please enter a user group name
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label for="userGroupDescriptionControl">Description (optional)</label>
                    <textarea id="userGroupDescriptionControl" class="form-control" placeholder="Enter a description" rows="5" formControlName="description" ></textarea>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <app-ae-multi-select
                        [formGroup]="configuredEntityForm"
                        [multiSelectFormControlName]="RolesFormControlName"
                        [label]="RolesLabel"
                        [placeholder]="SelectRolesPlaceholderTxt"
                        [multiSelectDataStream]="MultiSelectDataStream">
                    </app-ae-multi-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label for="userRolesControl">Status</label>
                    <div class="row pl-4 pt-2">
                        <div class="col-auto custom-control custom-radio">
                            <input type="radio" id="Active"  class="custom-control-input" name="isActive"
                                [value]="true" formControlName="isActive" >
                            <label for="Active" class="custom-control-label">Active</label>
                        </div>
                        <div class="col-auto custom-control custom-radio">
                            <input type="radio" id="Inactive"  class="custom-control-input" name="isActive"
                                [value]="false" formControlName="isActive" >
                            <label for="Inactive" class="custom-control-label">Inactive</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" hidden>
            <div class="col-4">
                <h2>Users</h2>
            </div>
        </div>
        <div class="row" hidden>
            <div class="col-3">
                <bp-search label="Search" placeholder="Search"></bp-search>
            </div>
            <div class="col text-right pt-4" *ngIf="authorizationService.isAllowed('CFG_USR_GRP', ['CREATE'])">
                <button type="button" class="btn btn-primary btn-md">Add Users</button>
            </div>
        </div>
        <div class="row" hidden>
            <div class="col">
                <app-ae-table [isDataLoading]="isDataLoading" [properties]="properties" [configuredTableData]="configuredProgramTableFilteredData">
                </app-ae-table>
            </div>
        </div>
    </div>
</form>
