import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProgramsConfigurationComponent } from './programs-configuration/programs-configuration.component';
import { ProgramsHomeComponent } from './programs-home/programs-home.component';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { ProgramRuleComponent } from './program-rule/program-rule.component';
import { ProgramScheduleComponent } from './program-schedule/program-schedule.component';
import { CustomProgramComponent } from './custom-program/custom-program.component';
import { ProgramSimulationComponent } from './program-simulation/program-simulation.component';
import { CustomProgramBuilder } from './custom-program-builder/custom-program-builder.component';
import { ProgramSelectorComponent } from './program-selector/program-selector.component';
import { UnsavedChangesGuard } from '../../shared/guard/unsaved-changes.guard';


const routes: Routes = [
  {
    path: 'programs',
    canActivate: [AuthGuard],
    component: ProgramsHomeComponent
  },
  {
    path: 'programs/custom/configure/external/:id/:eventsource/:action',
    canActivate: [AuthGuard],
    component: CustomProgramComponent,
    children: [
      { path: 'program-rule', component: CustomProgramBuilder, canDeactivate: [UnsavedChangesGuard] },
      { path: 'schedule', component: ProgramScheduleComponent, canDeactivate: [UnsavedChangesGuard] },
      { path: 'simulate', component: ProgramSimulationComponent },
      { path: '', redirectTo: 'program-rule', pathMatch: 'prefix' }
    ]
  },
  {
    path: 'programs/configure/:id/:eventsource/:action',
    component: ProgramsConfigurationComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'rule', component: ProgramRuleComponent, canDeactivate: [UnsavedChangesGuard] },
      { path: 'schedule', component: ProgramScheduleComponent, canDeactivate: [UnsavedChangesGuard] },
      { path: 'simulate', component: ProgramSimulationComponent },
      { path: '', redirectTo: 'rule', pathMatch: 'prefix' }
    ]
  },
  {
    path: 'programs/program-selector',
    canActivate: [AuthGuard],
    component: ProgramSelectorComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProgramsRoutingModule { }