
import { Router } from '@angular/router';


export abstract class BaseLoginService {

  private navRouter: Router;
  constructor(router: Router
  ) {
    this.navRouter = router;
  }

  public authorizationSuccessful(returnTo: string) : void {
    console.log('result: ' + returnTo);
    void this.navRouter.navigateByUrl(returnTo);
  }

}