import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Selectable } from '../../models/selectable';

@Component({
  selector: 'app-ae-multi-select',
  templateUrl: './ae-multi-select.component.html'
})
export class AEMultiSelectComponent implements AfterViewInit {

  @Input()
  public formGroup: UntypedFormGroup;

  @Input()
  public multiSelectFormControlName: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public multiSelectDataStream: BehaviorSubject<Selectable[]>;

  @Input()
  public isDisabled: boolean;

  public multiFilteredItems: BehaviorSubject<Selectable[]> = new BehaviorSubject<any>([]);
  public multiSelectedItems: BehaviorSubject<Selectable[]> = new BehaviorSubject<any>([]);

  private multiSelectData: Selectable[];

  constructor(private changeDetector: ChangeDetectorRef) { }

  public ngAfterViewInit(): void {
    this.multiSelectDataStream.subscribe(md => {
      this.multiSelectData = md;
      // re-load on new data
      this.onSelectBlur();
    });
    // If there is any pre-selection
    this.onSelectBlur();
  }

  public onSelectBlur(): void {
    this.multiSelectedItems.next(this.SelectedItems.sort());
    this.multiFilteredItems.next(this.FilteredItems.sort());
    this.changeDetector.detectChanges();
  }

  private get FilteredItems(): any[] {
    const selectedIds = [];
    this.SelectedItems.map(item => selectedIds.push(item.id));

    const filtered = this.multiSelectData.filter(item => selectedIds.indexOf(item.id) < 0);
    return filtered;
  }

  private get SelectedItems(): any[] {
    const items = this.formGroup.controls[this.multiSelectFormControlName].value as any[];
    return items === null ? [] : items;
  }

}