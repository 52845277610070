import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators, FormArray, FormControl, FormGroup } from '@angular/forms';
import { GroupInfos } from 'src/app/shared/models/package-builder/groupInfos';
import { Messages } from 'src/app/shared/message';
import { PackageLevel } from 'src/app/shared/models/package-builder/package-level-enum';
import { Constants } from 'src/app/shared/constants';
import { ModelType } from 'src/app/shared/models/package-builder/modelType';

@Component({
  selector: 'app-iqdecisions-multi-model-group-info',
  templateUrl: './iqdecisions-multi-model-group-info.component.html',
  styleUrls: ['./iqdecisions-multi-model-group-info.component.scss']
})

export class IqdecisionsMultiModelGroupInfoComponent implements OnInit {

  public messages = Messages;
  public primaryModels = [];
  public fallbackModels = [];
  public originalModels = [];
  private displayModelParameters: boolean = false;
  private groupNameIncrement: number = 1;
  public groupInfos: UntypedFormArray;
  public myGroup: any;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) { }

  @Input() groupInfo;
  @Input() level;
  @Input() originalModel;
  @Input() isEditMode = false;
  @Output() displayModelParameterEvent = new EventEmitter();

  ngOnInit(): void {
    this.myGroup = this.formBuilder.group({
      groupInfos: <UntypedFormArray>this.groupInfo
    });
    this.primaryModels = this.fallbackModels = this.originalModels = this.originalModel;
    if (this.isEditMode) {
      this.groupNameIncrement = Object.keys(this.groupInfo.value).length+1;
      this.setInitialPrimaryModel();
    } else {
      this.addDefaultGroups();
    }
  }

  private initializeGroupInfo(group: GroupInfos): FormGroup {
    return this.formBuilder.group({
      name: new UntypedFormControl(group.name, { validators: [Validators.required, Validators.maxLength(75)] }),
      share: new UntypedFormControl(group.share, { validators: [Validators.required, , Validators.min(1), Validators.max(100)] }),
      primaryModel: new UntypedFormControl(),
      fallbackModel: new UntypedFormControl()
    });
  }

  private addDefaultGroups(): void {
    if (Object.keys(this.groupInfo.value).length === 0) {
      for (let i = 0; i < 2; i++) {
        this.addNewGroup((<UntypedFormArray>this.groupInfo), 50);
      }
    }
  }

  private setInitialPrimaryModel(): void {
    let groupInfoFormArray = this.groupInfo as FormArray;
    let temp = [];
    for (let i = 0; i < groupInfoFormArray.controls.length; i++) {
      temp.push(groupInfoFormArray.controls[i].value['primaryModel'].id);
    }
    temp.forEach(id => {
      this.primaryModels = [...this.primaryModels].filter(m => m.id !== id);
    })
  }

  public addNewGroup(controls: UntypedFormArray, share?: number): void {
    controls.push(this.initializeGroupInfo(new GroupInfos('Group ' + this.groupNameIncrement++, share === undefined ? 1 : share)));
  }

  public getShares(): number {
    let totalShare: number = 0;
    let b = this.groupInfo as FormArray;
    for (let i = 0; i < b.controls.length; i++) {
      var y: number = +b.controls[i].value['share'];
      totalShare = totalShare + y;
    }
    return totalShare;
  }

  public getSelectedModel(value, type, group): void {
    if (type == ModelType.PRIMARY_MODEL) {
      if (group.value.primaryModel != null && !([...this.primaryModels].find(a => a.id === group.value.primaryModel.id))) {
        this.primaryModels = [
          ...this.primaryModels,
          [...this.originalModels].filter(a => a.id === group.value.primaryModel.id)[0]];
      }
      this.primaryModels = [...this.primaryModels].filter(m => m.id !== value.id);
    }
    group.setControl(type, new FormControl(value));
    this.displayModelParameterEvent.emit();
  }

  public getPrimaryModels(group): Array<any>  {
    return [...this.primaryModels].filter(m => m.id !== group.value.fallbackModel?.id);
  }

  public fallbackModelList(group): Array<any> {
    return [...this.fallbackModels].filter(m => m.id !== group.value.primaryModel?.id);
  }

  public validatePrimaryModel(group: any): boolean {
    if ((this.level === PackageLevel.MODELS && group === null)
      || (this.isEditMode && group === null)) {
      return true;
    }
    return false;
  }

  public deleteGroup(group: UntypedFormArray, index: number): void {
    let primaryModelForGroup = group.controls[index].value.primaryModel;
    if (primaryModelForGroup !== null && !([...this.primaryModels].find(a => a.id === primaryModelForGroup.id))) {
      this.primaryModels = [
        ...this.primaryModels,
        [...this.originalModels].filter(a => a.id === primaryModelForGroup.id)[0]
      ];
    }
    group.removeAt(index);
  }

  public disableDeleteButton(): boolean {
    return (<UntypedFormArray>this.groupInfo).length === 1;
  }
}
