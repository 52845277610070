import { Component, OnInit, OnDestroy, ViewChildren, QueryList, Injector, ViewChild } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { takeUntil, catchError } from 'rxjs/operators';
import { ReplaySubject, Observable, forkJoin, of } from 'rxjs';
import { RuleService } from 'src/app/shared/services/rule.service';
import { ConfiguredRule } from 'src/app/shared/models/configured-rule.model';
import { v4 as uuid } from 'uuid';
import { ProgramSharedDataService } from '../program-shared-data.service';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { ActionService } from 'src/app/shared/services/action.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomProgramBuilderService } from 'src/app/shared/services/custom-program-builder.service';
import { ServiceLocator } from 'src/app/shared/services/service-locator';
import { ProgramData } from 'src/app/shared/models/program-data';
import { Action } from 'src/app/shared/models/action';
import { RuleBuilderComponent } from 'src/app/shared/component/rule-builder/rule-builder.component';
import { ProgramType } from 'src/app/shared/program-type';
import { Constants, EntityTypes, RuleTypes } from 'src/app/shared/constants';
import { Pattern } from 'src/app/shared/pattern';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { Operation } from 'src/app/shared/operation';
import { Messages } from 'src/app/shared/message';
import { ProgramMode } from 'src/app/shared/program-mode';
import { DisplayTextService } from 'src/app/shared/services/display-text.service';
import { TestDataSet } from 'src/app/shared/models/configured-progra-test-data/test-data-set.model';
import { ConfiguredProgramTestDataMap } from 'src/app/shared/models/configured-program-test-data/configured-program-test-data-map';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { EntityStatesService } from 'src/app/shared/services/entitystates.service';
import { ConfiguredEventDetails } from 'src/app/shared/models/configured-event-details';
import { ConfiguredEvent } from 'src/app/shared/models/configured-event.model';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { WarningType } from 'src/app/shared/warning-options';
import { EventService } from '../../../shared/services/event.service';
import { DragDrop } from '@epsilon/core-ui';
import { ConfiguredExclusion } from 'src/app/shared/models/exclusion/configured-exclusion';
import { ExclusionService } from 'src/app/shared/services/exclusion.service';
import { CustomProgramExclusionBuilderComponent } from
  '../program-exclusion/custom-program-exclusion-builder/custom-program-exclusion-builder.component';
import { Program } from 'src/app/shared/models/program';
import { PackagedProgramService } from 'src/app/shared/services/packaged-program.service';
import { Rule } from 'src/app/shared/models/rule';
import { PackagedEventSourceName } from 'src/app/shared/models/event-source';
import { CustomRule } from 'src/app/shared/models/custom-rule/custom-rule';
import { BundleService } from 'src/app/shared/services/bundle.service';
import { Bundle } from 'src/app/shared/models/bundle/Bundle';
import { EventKey } from 'src/app/shared/models/eventKey.model';
import { ActionElement } from 'src/app/shared/models/custom-rule/actions/action-element';
import { ActionType } from 'src/app/shared/models/action-type';
import { Configuration } from 'src/app/shared/models/configuration';
import { DroppedItem, DroppedItemType } from '../../drag-drop/dropped-item';
import { DroolsRule } from 'src/app/shared/models/rule/drools-rule';
import { BaseFormDirective } from '../../../shared/models/base-form-configuration/base-form.directive';
import { LoginServiceLocator } from '../../../shared/services/login.service-locator';
import { isEqual as lodashIsEqual } from 'lodash';

class EventType {

  name: string;
  isCollapse = false;
  treeNodeForRules: any[] = [];

  getTreeNodefromArray() {
    if (this.treeNodeForRules !== undefined && this.treeNodeForRules.length > 0) {
      return Array.from(this.treeNodeForRules);
    }
  }

}

class EventSource {

  name: string;
  isCollapse = false;
  eventType: Map<string, EventType> = new Map<string, EventType>();

  getEventTypeList() {
    if (this.eventType !== undefined && this.eventType.size > 0) {
      return Array.from(this.eventType.values());
    }
  }

}

export let InjectorInstance: Injector;
@Component({
  selector: 'app-custom-program-builder',
  templateUrl: './custom-program-builder.component.html',
  styleUrls: ['./custom-program-builder.component.scss']
})
export class CustomProgramBuilder extends BaseFormDirective implements OnInit, OnDestroy {

  @ViewChildren(RuleBuilderComponent)
  public childForms: QueryList<RuleBuilderComponent>;

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  public warningModal: CoreuiModalWarningComponent;

  @ViewChildren(CustomProgramExclusionBuilderComponent)
  private customProgramExclusionBuilderComponent: QueryList<CustomProgramExclusionBuilderComponent>;

  public programDetailsPath: string;
  public configuredRules: ConfiguredRule[] = [];
  public programData: ProgramData;
  public actionDestinations : any[] = [];
  public configuredEntityStates: ConfiguredEntityState[];
  public isCollapseDescription = false;
  public operationStatusMessage = '';
  public eventSourceMap : Map<string, EventSource> = new Map<string, EventSource>();
  public programForm: UntypedFormGroup;
  public eventSources: EventSource[];
  public isCollapseRule = {};
  public childValidationStatus = true;
  public isReadOnlyRule = false;
  public showDeleteButton = false;
  public searchFormGroup: UntypedFormGroup;
  public filteredResult = false;
  public filteredRuleResult = false;
  public filteredConfiguredExclusionResult = false;
  public filteredPackagedExclusionResult = false;
  public filteredPackagedProgramTemplates = [];
  public filteredPackagedProgramTemplateResult = false;
  public configuredRuleNames: string[] = [];
  public filteredRuleNames = [];
  public filteredConfiguredExclusions = [];
  public filteredPackagedExclusions = [];
  public programExclusions: ConfiguredExclusion[];
  public packagedPrograms: Program[];
  public packagedProgramTemplates: Program[] = [];
  public availableBundles: Bundle[];

  // Drag and Drop Wiring
  public ruleDropAreaId = 'ruleDropArea';
  public ruleDragAreaId = 'ruleDragArea';
  public programTemplateDragAreaId = 'programTemplateDragArea';
  public dropAreaForProgramTemplateAndRuleId = 'dropAreaForProgramTemplateAndRule';
  public ruleDragAreaConnectedTo = ['ruleDropArea', 'dropAreaForProgramTemplateAndRule'];
  public ruleDropAreaConnectedTo = ['ruleDragArea'];
  public programTemplateDragAreaConnectedTo = ['dropAreaForProgramTemplateAndRule'];
  public dropAreaForProgramTemplateAndRuleConnectedTo = ['ruleDragArea', 'programTemplateDragArea'];

  private parentId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private searchEventSourceMap : Map<string, EventSource> = new Map<string, EventSource>();
  private configuredActions: Action[] = [];
  private configuredEvents: ConfiguredEvent[];
  private configuredEventDetails: ConfiguredEventDetails[];
  private configuredExclusionDetails: ConfiguredExclusion[];
  private packagedExclusionDetails: ConfiguredExclusion[];
  private packagedAndConfiguredExclusions: ConfiguredExclusion[];
  private filteredpackagedExclusionDetails: ConfiguredExclusion[];
  private filteredConfiguredExclusionDetails: ConfiguredExclusion[];
  private inEditMode: boolean;
  private dragNewRule = false;
  private deleteRule: {};
  private showMessage = true;
  private isAnyRuleDeleted = false;
  private selectedProgramTemplate: Program;
  private selectedTemplateProgramDetails: Program;
  private filteredPackagedProgramTemplate: Map<string, Program[]> = new Map<string, Program[]>();

  constructor(
    public bundleService: BundleService,
    public injector: Injector,
    private eventService: EventService,
    private ruleService?: RuleService,
    private parentContextService?: ParentContextService,
    private packagedProgramService?: PackagedProgramService,
    public programDataService?: ProgramSharedDataService,
    private loginServiceLocator?: LoginServiceLocator,
    private actionDestinationService?: ActionDestinationService,
    private actionService?: ActionService,
    private popupService?: PopupMessageService,
    private formBuilder?: UntypedFormBuilder,
    private customProgramBuilderService?: CustomProgramBuilderService,
    private eventSourceService?: EventSourceService,
    private exclusionService?: ExclusionService,
    private router?: Router,
    private route?: ActivatedRoute,
    public displayTexts?: DisplayTextService,
    public entityStateService?: EntityStatesService,
    public authorizationService?: AuthorizationService
  ) {
    super();
    this.parentId = this.parentContextService.getParentContext();
    InjectorInstance = this.injector;
  }

  private static createTreeNodeForRule(name: string, nodeType: string, children?: object): object {
    const id: string = uuid();
    const treeNode: object = Object.create(null);
    treeNode[Constants.NODE_TYPE] = nodeType;
    treeNode[Constants.ID] = id;
    treeNode[Constants.NAME] = name;
    if (children) {
      treeNode[Constants.CHILDREN] = children;
    }
    return treeNode;
  }

  private static getCompositeKey(rule) {
    return `${rule.eventKey.eventSourceName}_${rule.eventKey.eventName}_${rule.name}`;
  }

  ngOnInit(): void {
    this.availableBundles = this.bundleService.getBundles();
    this.programDetailsPath = this.customProgramBuilderService.programDetailsPath;
    this.programData = this.programDataService.getProgramData();
    this.programData.operation === 'edit' ? this.inEditMode = true : this.inEditMode = false;
    this.getEntities();
    this.initFormGroup();
  }

  public canNavigateAway(): boolean {
    if (this.programDataService.isDataSaved || this.programDataService.isDeleteOperation || this.programDataService.isApiServiceCalled
        || this.programDataService.isReadOnly() || !this.loginServiceLocator.getLoginService().isLoggedIn()) {
      this.programDataService.canNavigateAway = true;
      return true;
    }
    this.programDataService.canNavigateAway = !(this.programData.operation === Operation.ADD && this.isCreateFormUpdated() ||
        this.programData.operation === Operation.EDIT && this.isEditFormUpdated()) && this.programDataService.header.canDeactivate();
    return this.programDataService.canNavigateAway;
  }

  private isEditFormUpdated(): boolean {
    if (this.customProgramExclusionBuilderComponent.first !== undefined) {
      const rulesEqual: boolean = lodashIsEqual(this.programData.tenantProgram.configuration.rules, this.sendPayload().configuration.rules);
      const exclusions: ConfiguredExclusion[] = this.customProgramExclusionBuilderComponent.first.buildExclusions();
      const exclusionsEqual: boolean = lodashIsEqual(JSON.parse(this.programData.tenantProgram.configuration.exclusions), exclusions);
      return !rulesEqual || !exclusionsEqual;
    }
    return false;
  }

  private isCreateFormUpdated(): boolean {
    const exclusions: ConfiguredExclusion[] = this.customProgramExclusionBuilderComponent.first
      ? this.customProgramExclusionBuilderComponent.first.buildExclusions() : [];
    return this.programForm.controls.configuration.value.rules.length !== 0
      || exclusions.length !== 0;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getConfiguredProgramByID(): void {
    this.customProgramBuilderService.getConfiguredProgramDetails(this.programData.parentId, this.programData.programId).subscribe(
      res => {
        const configuredProgram: ConfiguredProgram = new ConfiguredProgram(res);
        this.programData.tenantProgram = configuredProgram;
        let rule = JSON.parse(configuredProgram.configuration.rules);
        if (configuredProgram.programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
          this.initSelectedProgramTemplate(configuredProgram.packagedProgramName);
          rule = this.removeRawRule(rule);
          this.removeActionsFromReminderRule(rule);
        }
        this.programForm.get(Constants.NAME).setValue(this.programData.tenantProgram.name);
        this.programForm.controls.name.setValue(this.programData.tenantProgram.name);
        this.programForm.controls.description.setValue(this.programData.tenantProgram.description);
        this.programForm.controls.programType.setValue(this.programData.tenantProgram.programType);
        this.setReadOnlyRule();
        this.setConfiguredProgramExclusions(this.programData.tenantProgram.configuration.exclusions);
        for (const index in rule) {
          if (rule.hasOwnProperty(index) && rule[index] !== undefined) {
            const buildFormGroup = this.buildRuleComponentForm(new ConfiguredRule(rule[index]), index);
            this.getConfigurationRuleFormArray.push(buildFormGroup);
          }
        }
        this.programDataService.setProgramActionDestinationsFrom(this.actionDestinations);
      }, (error: any) => {
        this.popupService.showErrorMessage(error.error.statusMessage);
      });
  }

  getValuesFromEventSourceMap(): any {
    let eventSourceMap: Map<string, EventSource>;
    if (this.searchEventSourceMap.size > 0) {
      eventSourceMap = this.searchEventSourceMap;
    } else {
      eventSourceMap = this.eventSourceMap;
    }
    if (eventSourceMap !== undefined && eventSourceMap.size > 0) {
      return Array.from(eventSourceMap.values());
    }
  }

  generateArray(obj): any {
    return Object.keys(obj).map(key=>{
      return obj[key];
    });
  }

  toggleClass(item): void {
    item.isCollapse = !item.isCollapse;
  }

  get getConfigurationRuleFormArray() :UntypedFormArray {
    if (this.programForm !== undefined) {
      return (<UntypedFormArray>(<UntypedFormGroup> this.programForm.get(Constants.CONFIGURATION)).get(Constants.RULES));
    }
  }

  get getProgramRuleBuilderForm() : any {
    if (this.programForm !== undefined) {
      return (<UntypedFormArray>(<UntypedFormGroup> this.programForm.get(Constants.CONFIGURATION)).get(Constants.RULES)).controls;
    }
  }

  public doRulesExistForPrograms(): boolean {
    return this.getConfigurationRuleFormArray !== undefined && this.getConfigurationRuleFormArray.value.length > 0;

  }

  public programTemplateBelongsToBundle(bundleId: string): boolean {
    return this.filteredPackagedProgramTemplate.get(bundleId) ? this.filteredPackagedProgramTemplate.get(bundleId).length !== 0 : false;
  }

  public getRuleCountForProgram(): any {
    return this.getConfigurationRuleFormArray !== undefined ? this.getConfigurationRuleFormArray.value.length : 0;
  }

  public onRuleDropped(event: DragDrop<DroppedItem>): void {
    this.dragNewRule = true;
    const configuredRule = this.findConfiguredRule(event.item.data.name);
    this.addRuleToList(configuredRule);
  }

  public validateProgramRules(): boolean {
    this.childValidationStatus = true;
    let validationStatus = true;
    this.markFormGroupTouched(this.programForm);
    for (const ruleBuilderComponent of this.childForms.toArray()) {
      if (ruleBuilderComponent.rule.eventKey.eventSourceName === 'Conversant') {
        const isValidated = ruleBuilderComponent.validateCompanyId(ruleBuilderComponent.configuredRuleForm);
        if (!isValidated) {
          validationStatus = false;
        }
      }
      if (!ruleBuilderComponent.rule.isRuleTypePackaged()) {
        ruleBuilderComponent.validateConditionAttributes(ruleBuilderComponent.configuredRuleForm.controls.conditions.value);
        ruleBuilderComponent.validateRuleThenClause();
        ruleBuilderComponent.validateActions();
      }
      if (ruleBuilderComponent.rule.isRuleTypePackaged()) {
        ruleBuilderComponent.validateRuleWhenClause();
        ruleBuilderComponent.validateRuleThenClause();
      }
    }
    if (!this.childValidationStatus) {
      validationStatus = false;
    }
    if (this.customProgramExclusionBuilderComponent) {
      const isValidExclusions = this.customProgramExclusionBuilderComponent.first.validate();
      if (!isValidExclusions) {
        validationStatus = false;
      }
    }
    this.displayValidationMessage(validationStatus);
    return validationStatus;
  }

  public setChildValidationStatus(status: boolean): void {
    this.childValidationStatus = this.childValidationStatus && status;
  }

  public saveProgramRules(): void {
    this.operationStatusMessage = 'Saving program';
    this.showMessage = false;
    if (this.validateProgramRules()) {
      const programRule = this.sendPayload();
      if (this.programData.operation === Operation.ADD) {
        this.addProgramRule(programRule);
      } else {
        this.updateProgramRule(programRule);
      }
    }
    this.showMessage = true;
  }

  public updateTestData(): void {
    const testDatasetList : TestDataSet[] = this.programDataService.programData.tenantProgram.getTestDataset();
    const configuredProgramTestDataMap : ConfiguredProgramTestDataMap = new ConfiguredProgramTestDataMap();
    configuredProgramTestDataMap.getTestDataMapFromDatasetList(testDatasetList);
    const programTestDatasetPayload = { mode: null, testData: {}, configuredProgramTestDataMap: null };
    programTestDatasetPayload.mode = this.programDataService.programData.tenantProgram.mode;
    programTestDatasetPayload.configuredProgramTestDataMap = configuredProgramTestDataMap.toJson();
    const programInstance = ServiceLocator.getProgramService(this.programData.tenantProgram.programType, this.injector);
    programInstance.updateTestRecipients(programTestDatasetPayload, this.programData.parentId,
      this.programData.programId, programInstance.updatetestdataURI).subscribe(
      serviceResponse => {
        const tenantProgram = new ConfiguredProgram(serviceResponse);
        this.programDataService.programData.tenantProgram.configuredProgramTestDataMap = tenantProgram.configuredProgramTestDataMap;
      });
  }

  public searchInputText(searchInput: string): void {
    this.buildRuleTree(this.searchEventSourceMap, searchInput);
    if (this.searchEventSourceMap.size !== 0) {
      this.filteredRuleResult = true;
    }
  }

  public resetSearch(): void {
    this.searchEventSourceMap = new Map<string, EventSource>();
    this.getFilteredExclusions();
  }

  public doRulesExistForEvents(): boolean {
    return this.eventSourceMap && this.eventSourceMap.size === 0;
  }

  public handleDecision(decision: boolean): void {
    if (this.warningModal.getEntityType() === 'CUSTOM_PROGRAM_BUILDER' && decision) {
      this.confirmDeleteRule();
    }
  }

  public showDeleteAlert(rule, index): void {
    this.deleteRule = { 'rule': rule, 'index': index };
    this.warningModal.forEntity('CUSTOM_PROGRAM_BUILDER').launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteConfiguredRule,
      msg: Messages.deleteTestDataOnDeletingRule,
      msg2: [Messages.deleteRuleConfirmation]
    });
  }

  public isButtonDisabled(): boolean {
    return this.programData.tenantProgram.name === undefined || (this.programData.tenantProgram.name.trim() === '')
      || this.programData.tenantProgram.active;
  }

  public setReadOnlyRule(): void {
    this.isReadOnlyRule = this.programDataService.isReadOnly();
  }

  public onTreeLoad(tree, ruleNames): void {
    tree.nodes = ruleNames;
    tree.treeModel.expandAll();
  }

  public launchUnsavedChangesModal(): Observable<any> {
    this.warningModal.launchModal(WarningType.UNSAVED_CHANGES_WARNING, {
      msg2: [Messages.loseUnsavedChanges]
    });
    return this.warningModal.decision.asObservable();
  }

  public onSearchTermInput(searchTerm: string): void {
    if (searchTerm === '') {
      this.clearFilter();
    } else {
      this.filteredRuleNames = this.configuredRuleNames
        .filter(name => name.toLowerCase().includes(searchTerm.toLowerCase()));
      this.filteredPackagedProgramTemplates = this.packagedProgramTemplates
        .filter(name => name.programName.toLowerCase().includes(searchTerm.toLowerCase()));
      this.filteredConfiguredExclusions = this.configuredExclusionDetails
        .filter(exclusion => exclusion.name.toLowerCase().includes(searchTerm.toLowerCase()));
      this.filteredPackagedExclusions = this.packagedExclusionDetails
        .filter(exclusion => exclusion.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }

  public onSearchValueSelected(): void {
    const searchTerm: string = this.searchFormGroup.get('searchTerm').value;
    if (searchTerm) {
      this.searchInputText(searchTerm);
      this.searchExclusion(searchTerm);
      this.searchPackagedProgramTemplates(searchTerm);
    } else {
      this.clearFilter();
    }

  }

  public setConfiguredProgramExclusions(exclusions: string): void {
    this.programExclusions = exclusions ? JSON.parse(exclusions) : [];
  }

  public doExclusionExistForProgram(): boolean {
    return this.programExclusions && this.programExclusions.length > 0;
  }

  public isExclusionPrestine(): boolean {
    return this.customProgramExclusionBuilderComponent.first.isPristine();
  }

  public showDropAreaForProgramTemplateAndRule(): boolean {
    return !this.doRulesExistForPrograms() && !this.doExclusionExistForProgram();
  }

  public buildDroppedItem(type: DroppedItemType, name: string): DroppedItem {
    const droppedItem: DroppedItem = new DroppedItem();
    droppedItem.name = name;
    droppedItem.type = type;
    return droppedItem;
  }

  public onProgramTemplateOrRuleDropped(event: DragDrop<DroppedItem>): void {
    if (event.item.data.type === DroppedItemType.RULE) {
      this.programForm.controls.programType.setValue(ProgramType.SELF_SERVICE_EXTERNAL);
      this.onRuleDropped(event);
    } else {
      this.programForm.controls.programType.setValue(ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED);
      this.initProgramFromTemplate(event);
    }
  }

  private initProgramFromTemplate(event: DragDrop<DroppedItem>): void {
    this.initSelectedProgramTemplate(event.item.data.name);
    this.initProgramExclusions(this.selectedProgramTemplate.exclusions);
    this.getAndInitProgramRules(this.selectedProgramTemplate);
  }

  private initSelectedProgramTemplate(programName: string): void {
    this.selectedProgramTemplate = this.packagedProgramTemplates.find(programTemplate =>
      programTemplate.programName === programName);
    this.selectedProgramTemplate.packagedRuleSettings = JSON.parse(JSON.stringify(this.selectedProgramTemplate.rules));
    this.programDataService.programData.packagedRuleSettings = this.selectedProgramTemplate.packagedRuleSettings;
  }

  private initProgramExclusions(programExclusions: string): void {
    this.programExclusions = [];
    const programExclusionsJson: Record<string, string>[] = JSON.parse(programExclusions);
    const configuredProgramExclusions: ConfiguredExclusion[] = programExclusionsJson.map(programExclusionJson => {
      const configuredExclusion: ConfiguredExclusion = Object.assign(new ConfiguredExclusion(), programExclusionJson);
      configuredExclusion.active = true;
      return configuredExclusion;
    });
    this.programExclusions.push(...configuredProgramExclusions);
  }

  private getAndInitProgramRules(selectedProgramTemplate: Program): void {
    this.programDataService.isApiServiceCalled = true;
    this.packagedProgramService.getProgramDetails(this.parentId, PackagedEventSourceName.CONVERSANT, selectedProgramTemplate.programName)
      .pipe(takeUntil(this.destroyed$)).subscribe((program: Program) => {
        this.selectedTemplateProgramDetails = new Program(program);
        this.initProgramRules(this.selectedTemplateProgramDetails.rules);
        this.programDataService.isApiServiceCalled = false;
      }, error => {
        this.programDataService.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.statusMessage);
      });
  }

  private initProgramRules(rules: Rule[]): void {
    rules.forEach(rule => {
      if (rule.ruleTemplate) {
        const configuredRule: ConfiguredRule = new ConfiguredRule();
        const ruleTemplates: CustomRule[] = JSON.parse(rule.ruleTemplate);
        const ruleTemplate: CustomRule = ruleTemplates.find(ruleTemplate => ruleTemplate.type === RuleTypes.PACKAGED);
        configuredRule.setRule(JSON.stringify(ruleTemplate));
        configuredRule.name = configuredRule.ruleObject.name;
        configuredRule.eventKey = configuredRule.ruleObject.eventKey;
        configuredRule.parentId = this.parentId;
        configuredRule.id = configuredRule.ruleObject.id;
        this.addTemplateRuleToList(configuredRule);
      }
    });
  }

  private addTemplateRuleToList(configuredRule: ConfiguredRule): void {
    this.dragNewRule = true;
    const buildFormGroup = this.buildRuleComponentForm(configuredRule, this.getConfigurationRuleFormArray.length + 1);
    this.getConfigurationRuleFormArray.push(buildFormGroup);
  }

  private addRuleToList(configuredRule: ConfiguredRule): void {
    const buildFormGroup = this.buildRuleComponentForm(configuredRule, this.getConfigurationRuleFormArray.length + 1);
    this.getConfigurationRuleFormArray.insert(0, buildFormGroup);
  }

  private getEntities() {
    this.programDataService.isApiServiceCalled = true;
    this.operationStatusMessage = 'Loading program';
    this.callAPIs().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      this.eventSources = result[0].result;
      this.configuredActions = result[1].result;
      this.actionDestinations = result[2].result;
      this.buildConfiguredRuleObject(result[3].result);
      this.initializeProgramForm();
      this.configuredEntityStates = result[4].result;
      this.configuredEventDetails = result[5].result;
      this.packagedAndConfiguredExclusions = result[6].result;
      this.configuredExclusionDetails = result[6].result.filter(exclusion => exclusion.type === 'Custom');
      this.packagedExclusionDetails = result[6].result.filter(exclusion => exclusion.type === 'Packaged');
      this.buildRuleTree(this.eventSourceMap, '');
      this.packagedAndConfiguredExclusions = result[6].result;
      this.packagedPrograms = result[7].result;
      this.initPackagedProgramTemplates(this.packagedPrograms);
      this.getFilteredExclusions();
      this.getConfiguredRuleNames();
      this.programDataService.isApiServiceCalled = false;
    }, error => {
      this.programDataService.isApiServiceCalled = false;
      this.popupService.showErrorMessage(error.error.statusMessage);
    });
  }

  private buildConfiguredRuleObject(results: any[]) {
    results.forEach(result => this.configuredRules.push(new ConfiguredRule(result)));
  }

  private callAPIs(): Observable<any[]> {
    const configuredEventSources = this.eventSourceService.getEventSources(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredActions = this.actionService.getConfiguredActions(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredActionDestinations = this.actionDestinationService.getActionDestinations(this.parentId)
      .pipe(catchError(() => of({ 'result': [] })));
    const configuredRules = this.ruleService.getConfiguredRules(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredEntityStates = this.entityStateService.getAllConfiguredEntityStates(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredEvents = this.eventSourceService.getConfiguredEvents(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredExclusions = this.exclusionService.getConfiguredExclusionByParent(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const packagedPrograms = this.packagedProgramService.getAllPackagedPrograms(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const apisToCall:any[] = [configuredEventSources, configuredActions, configuredActionDestinations,
      configuredRules, configuredEntityStates, configuredEvents, configuredExclusions, packagedPrograms];
    return forkJoin(apisToCall);
  }

  private buildRuleTree(map: Map<string, EventSource>, searchInput): Map<string, EventSource> {
    for (const ruleObj of this.configuredRules) {
      if (searchInput !== '' && !ruleObj.name.toLowerCase().includes(searchInput.toLowerCase())) {
        continue;
      }
      if (!map.has(ruleObj.eventKey.eventSourceName)) {
        const eventSource = new EventSource();
        eventSource.name = ruleObj.eventKey.eventSourceName;
        map.set(ruleObj.eventKey.eventSourceName, eventSource);
      }
      const eventSource = map.get(ruleObj.eventKey.eventSourceName);
      if (!eventSource.eventType.has(ruleObj.eventKey.eventName)) {
        const eventType = new EventType();
        eventType.name = ruleObj.eventKey.eventName;
        eventSource.eventType.set(ruleObj.eventKey.eventName, eventType);
      }
      const eventType = eventSource.eventType.get(ruleObj.eventKey.eventName);
      eventType.treeNodeForRules.push(CustomProgramBuilder.createTreeNodeForRule(ruleObj.name, CustomProgramBuilder.getCompositeKey(ruleObj)));
    }
    return map;
  }

  private getConfiguredRuleNames(): void {
    const eventSourceNames = this.eventSourceMap.values();
    for (const map of eventSourceNames) {
      for (const eventName of map.eventType.values()) {
        for (const tree of eventName.treeNodeForRules) {
          this.configuredRuleNames.push(tree.name);
        }
      }
    }
  }

  private initializeProgramForm() {
    if (this.inEditMode) {
      this.getConfiguredProgramByID();
    }
    this.programForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      programType: ProgramType.SELF_SERVICE_EXTERNAL,
      description: '',
      configuration: this.formBuilder.group({
        rules: this.formBuilder.array([]) }),
      mode: ProgramMode.TEST
    });
    this.programForm.addControl(Constants.NAME, this.formBuilder.control(
      { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC_HYPHEN_UNDERSCORE)] }));
    this.programForm.addControl(Constants.CONFIGURATION, this.formBuilder.control(''));
  }

  private buildRuleComponentForm(configuredRule: ConfiguredRule, index) {
    if (this.inEditMode && !this.dragNewRule) {
      configuredRule.setRuleString(configuredRule);
    }
    configuredRule.setRuleObject();
    this.dragNewRule = false;
    const configuredRuleForm: UntypedFormGroup = this.formBuilder.group({
      ruleName: this.formBuilder.control(configuredRule.name,
        { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC_HYPHEN_UNDERSCORE)] }),
      ruleDescription: this.formBuilder.control(configuredRule.description),
      eventName: this.formBuilder.control(configuredRule.eventKey.eventSourceName, { validators: [Validators.required] })
    });
    const filteredConfiguredActions = this.getFilteredConfiguredActions(configuredRule);
    this.addNewFormController(configuredRuleForm, Constants.FILTERED_CONFIGURED_ACTIONS, filteredConfiguredActions);
    this.addNewFormController(configuredRuleForm, Constants.CONFIGURED_RULE, configuredRule);
    this.addNewFormController(configuredRuleForm, Constants.RULE_COMPONENT_INDEX, index);
    return configuredRuleForm;
  }

  private getFilteredConfiguredActions(configuredRule: ConfiguredRule):Action[] {
    let filteredConfiguredActions: Action[];
    const sourceprovEventName = configuredRule.eventKey.eventSourceName + '_x_' + configuredRule.eventKey.eventName;
    filteredConfiguredActions = this.configuredActions.filter(
      configuredAction => {
        if (configuredAction.eventId !== undefined
          && ((configuredAction.eventId.eventSourceName + '_x_' + configuredAction.eventId.eventName) === sourceprovEventName)
        ) {
          return configuredAction;
        }
      });
    filteredConfiguredActions = filteredConfiguredActions.filter(
      configuredAction => this.actionDestinations.map(actDest => actDest.actionDestination).includes(configuredAction.actionDestinationId));
    // Added standard actions to filtered action list
    this.configuredActions.forEach(configuredAction => {
      if (configuredAction.entityType === EntityTypes.PACKAGED) {
        filteredConfiguredActions.push(configuredAction);
      }
    });
    return filteredConfiguredActions;
  }

  private addNewFormController(configuredRuleForm: UntypedFormGroup, controllerName, controllerValue): UntypedFormGroup {
    configuredRuleForm.addControl(controllerName, this.formBuilder.control(''));
    configuredRuleForm.controls[controllerName].patchValue(controllerValue);
    configuredRuleForm.controls[controllerName].updateValueAndValidity();
    return configuredRuleForm;
  }

  private findConfiguredRule(ruleName: string): ConfiguredRule {
    return this.configuredRules.find(
      configuredRule => (configuredRule.name === ruleName));
  }

  private displayValidationMessage(validationStatus: boolean) {
    if (validationStatus && this.showMessage) {
      this.popupService.showSuccessMessage(Messages.validateProgramSuccessMessage);
    } else if (!validationStatus) {
      this.popupService.showErrorMessage(Messages.validateProgramErrorMessage);
    }
  }

  private markFormGroupTouched(form: UntypedFormGroup) {
    Object.values(form.controls).forEach(control => {
      control.markAsTouched();
      if ((control as any).controls) {
        this.markFormGroupTouched(control as UntypedFormGroup);
      }
    });
  }

  private sendPayload(): ConfiguredProgram {
    const postRules = [];
    for (const ruleBuilderComponent of this.childForms.toArray()) {
      const rule = ruleBuilderComponent.getRule(ruleBuilderComponent.configuredRuleForm.controls.companyId.value,
        ruleBuilderComponent.rule.eventKey.eventSourceName, ruleBuilderComponent.rule.eventKey.eventName, this.configuredEntityStates);
      const ruleObj = JSON.parse(rule);
      ruleObj[Constants.ID] = ruleBuilderComponent.rule.id;
      ruleObj[Constants.NAME] = ruleBuilderComponent.rule.name;
      postRules.push(ruleObj);
    }
    if (this.programForm.controls.programType.value === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
      this.addScheduleEventActionsInReminderRule(postRules);
      this.addRawRule(this.selectedProgramTemplate, postRules);
    }
    const postExclusions = this.customProgramExclusionBuilderComponent.first.buildExclusions();
    const configuredProgram: ConfiguredProgram = new ConfiguredProgram();
    configuredProgram.name = this.programData.tenantProgram.name;
    configuredProgram.programType = this.programForm.controls.programType.value;
    configuredProgram.description = this.programForm.controls.description.value;
    configuredProgram.mode = this.programForm.controls.mode.value;
    const configuration: Configuration = new Configuration();
    configuration.rules = JSON.stringify(postRules);
    configuration.exclusions = JSON.stringify(postExclusions);
    configuredProgram.configuration = configuration;
    if (this.programForm.controls.programType.value === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
      configuredProgram.packagedProgramName = this.selectedProgramTemplate.programName;
    }
    return configuredProgram;
  }

  private addScheduleEventActionsInReminderRule(postRules: Record<string, unknown>[]): void {
    const scheduledEventActons: {eventKey: EventKey; action: ActionElement}[] = this.getReminderActionsToBeCreated(postRules);
    if (scheduledEventActons && scheduledEventActons.length > 0) {
      scheduledEventActons.forEach(scheduledEventActon => this.addAPIActionReminderRule(scheduledEventActon, postRules));
    }
  }

  private getReminderActionsToBeCreated(postRules: Record<string, unknown>[]): {eventKey: EventKey; action: ActionElement}[] {
    const scheduledEventActons: {eventKey: EventKey; action: ActionElement}[] = [];
    postRules.forEach(rule => {
      const customRule: CustomRule = Object.assign(new CustomRule(), rule);
      const scheduledEventActionsInRule: ActionElement[] = customRule.thenClause.actions.filter(action =>
        action.actionType === ActionType.SCHEDULED_EVENT);
      if (scheduledEventActionsInRule && scheduledEventActionsInRule.length > 0) {
        scheduledEventActionsInRule.forEach(action => {
          const reminderAction: ActionElement = JSON.parse(JSON.stringify(action));
          reminderAction.actionType = ActionType.API;
          const triggerEventName: string = reminderAction.actionSettingsInput[Constants.TRIGGER_ACTION_NAME];
          const scheduledActionId: string = customRule.eventKey.eventSourceName
            + '_' + customRule.eventKey.eventName + '_' + action.product + '_' + reminderAction.actionDelay.delay + '_scheduled_action';
          const triggerActionId: string = customRule.eventKey.eventSourceName
            + '_' + triggerEventName + '_' + action.product + '_' + reminderAction.actionDelay.delay + '_triggered_action';
          action.actionSettingsInput[Constants.TRIGGER_ACTION_ID] = triggerActionId;
          action.id = scheduledActionId;
          reminderAction.id = triggerActionId;
          const reminderActionServiceInput = JSON.parse(reminderAction.actionSettingsInput['serviceInput']);
          delete reminderActionServiceInput[Constants.TRIGGER_ACTION_NAME];
          delete reminderAction.actionSettingsInput[Constants.TRIGGER_ACTION_NAME];
          reminderAction.actionSettingsInput['serviceInput'] = JSON.stringify(reminderActionServiceInput);
          const eventKey: EventKey = new EventKey(customRule.eventKey.eventSourceName, triggerEventName);
          scheduledEventActons.push({ eventKey: eventKey, action: reminderAction });
        });
      }
    });
    return scheduledEventActons;
  }

  private addAPIActionReminderRule(scheduleEventAction: {eventKey: EventKey; action: ActionElement}, rules: Record<string, unknown>[]): void {
    rules.forEach(rule => {
      const customRule: CustomRule = Object.assign(new CustomRule(), rule);
      if (customRule.eventKey.eventSourceName === scheduleEventAction.eventKey.eventSourceName
        && customRule.eventKey.eventName === scheduleEventAction.eventKey.eventName) {
        let actions: ActionElement[] = customRule.thenClause.actions;
        if (!actions) {
          actions = [];
        }
        actions.push(scheduleEventAction.action);
        customRule.thenClause.actions = actions;
      }
    });
  }

  private addRawRule(selectedProgramTemplate: Program, rules: Record<string, unknown>[]): void {
    selectedProgramTemplate.packagedRuleSettings.forEach(packagedRuleSetting => {
      if (!packagedRuleSetting.useCustomTemplate) {
        const rawRool: DroolsRule = new DroolsRule()
          .build(packagedRuleSetting.ruleId, packagedRuleSetting.eventType, selectedProgramTemplate.eventType);
        rules.push(JSON.parse(JSON.stringify(rawRool)));
      }
    });
  }

  private removeActionsFromReminderRule(rules: CustomRule[]): void {
    const reminderActionsToBeRemoved: { eventKey: EventKey; actionId: string }[] = this.getReminderActionsToBeRemoved(rules);
    reminderActionsToBeRemoved.forEach(reminderActionToBeRemoved => this.removeReminderActionFromRule(reminderActionToBeRemoved, rules));
  }

  private getReminderActionsToBeRemoved(rules: CustomRule[]): { eventKey: EventKey; actionId: string }[] {
    const reminderActionsToBeRemoved: { eventKey: EventKey; actionId: string }[] = [];
    rules.forEach(rule => {
      const scheduledEventActionsInRule: ActionElement[] = rule.thenClause.actions.filter(action =>
        action.actionType === ActionType.SCHEDULED_EVENT);
      if (scheduledEventActionsInRule && scheduledEventActionsInRule.length > 0) {
        scheduledEventActionsInRule.forEach(action => {
          const triggerEventName: string = action.actionSettingsInput[Constants.TRIGGER_ACTION_NAME];
          const triggerActionId: string = action.actionSettingsInput[Constants.TRIGGER_ACTION_ID];
          const eventKey: EventKey = new EventKey(rule.eventKey.eventSourceName, triggerEventName);
          reminderActionsToBeRemoved.push({ eventKey: eventKey, actionId: triggerActionId });
        });
      }
    });
    return reminderActionsToBeRemoved;
  }

  private removeReminderActionFromRule(reminderActionToBeRemoved: { eventKey: EventKey; actionId: string }, rules: CustomRule[]): void {
    rules.forEach(rule => {
      if (rule.eventKey.eventSourceName === reminderActionToBeRemoved.eventKey.eventSourceName
        && rule.eventKey.eventName === reminderActionToBeRemoved.eventKey.eventName) {
        rule.thenClause.actions = [];
      }
    });
  }

  private removeRawRule(rules: CustomRule[]): CustomRule[] {
    return rules.filter(rule => rule.type !== RuleTypes.DROOLS);
  }

  private addProgramRule(program) {
    this.programDataService.isApiServiceCalled = true;
    this.customProgramBuilderService.addConfiguredProgram(program, this.programData.parentId).subscribe(
      res => {
        this.programDataService.isDataSaved = true;
        program = new ConfiguredProgram(res);
        this.programDataService.programData.programId = program.id;
        this.programDataService.programData.eventSource = program.configuration.eventSource;
        this.programDataService.programData.operation = Operation.EDIT;
        this.programDataService.programData.tenantProgram = program;
        this.programDataService.setProgramData(this.programDataService.programData);
        this.inEditMode = true;
        this.programDataService.setProgramActionDestinationsFrom(this.actionDestinations);
        this.customProgramExclusionBuilderComponent.first.markAsPristine();
        this.router.navigateByUrl('programs', {skipLocationChange: true}).then( () => {
          this.router.navigateByUrl('/programs/custom/configure/external/'
              + this.programDataService.programData.programId + '/' + 'eventSource' + '/edit/program-rule').catch(() => '');
        });

        this.popupService.showSuccessMessage(Messages.createProgramSuccessMessage, true);
        this.programDataService.isApiServiceCalled = false;
      }, (error: any) => {
        this.programDataService.isDataSaved = false;
        this.displayValidationMessage(false);
        const errorMessage = Messages.createProgramErrorMessage;
        this.setReponseErrorMessage(error, errorMessage);
        this.programDataService.isApiServiceCalled = false;
      });
  }

  private setReponseErrorMessage(error: any, errorMessage: string) {
    if (error.status === 400) {
      if (error.error.statusMessage === Constants.DUPLICATE_ITEM) {
        const duplicateRules = error.error.result.split(';');
        const duplicateRuleNames = [];
        duplicateRules.forEach(ruleMsg => duplicateRuleNames.push(ruleMsg.split('RuleName-')[1]));
        errorMessage = duplicateRules.length + ' duplicate rule found. Rule Name -  ' + duplicateRuleNames.toString();
      } else if (error.error.statusMessage === Constants.DUPLICATE_PROGRAM) {
        errorMessage = error.error.result;
      }
    }
    this.popupService.showErrorMessage(errorMessage);
  }

  private updateProgramRule(program) {
    this.programDataService.isApiServiceCalled = true;
    this.customProgramBuilderService.updateConfiguredProgram(program, this.programData.parentId, this.programData.programId).subscribe(
      res => {
        program = new ConfiguredProgram(res);
        this.programData.tenantProgram = program;
        this.programDataService.programData.programId = program.id;
        this.programDataService.programData.tenantProgram = program;
        if (this.isAnyRuleDeleted) {
          this.updateTestData();
        }
        this.programDataService.setProgramData(this.programDataService.programData);
        this.customProgramExclusionBuilderComponent.first.markAsPristine();
        this.popupService.showSuccessMessage(Messages.updateProgramSuccessMessage);
        this.programDataService.setProgramActionDestinationsFrom(this.actionDestinations);
        this.programDataService.isApiServiceCalled = false;
        this.programDataService.isDataSaved = false;
      }, (error: any) => {
        this.displayValidationMessage(false);
        const errorMessage = Messages.updateProgramErrorMessage;
        this.setReponseErrorMessage(error, errorMessage);
        this.programDataService.isApiServiceCalled = false;
        this.programDataService.isDataSaved = false;
        });
  }

  private confirmDeleteRule() {
    if ( this.deleteRule[Constants.INDEX] !== undefined) {
      this.getConfigurationRuleFormArray.removeAt(this.deleteRule[Constants.INDEX]);
      this.deleteRule = {};
      this.isAnyRuleDeleted = true;
    }
  }

  private initFormGroup(): void {
    this.searchFormGroup = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
  }

  private getFilteredExclusions(): void {
    this.filteredConfiguredExclusionDetails = this.configuredExclusionDetails;
    this.filteredpackagedExclusionDetails = this.packagedExclusionDetails;
  }

  private searchExclusion(searchTerm): void {
    this.filteredpackagedExclusionDetails = this.packagedExclusionDetails.filter(exclusion => exclusion.name === searchTerm);
    this.filteredConfiguredExclusionDetails = this.configuredExclusionDetails.filter(exclusion => exclusion.name === searchTerm);
    if (this.filteredConfiguredExclusionDetails.length !== 0) {
      this.filteredConfiguredExclusionResult = true;
    } else if (this.filteredpackagedExclusionDetails.length !== 0) {
      this.filteredPackagedExclusionResult = true;
    }
  }
  private searchPackagedProgramTemplates(searchTerm: string): void {
    this.filteredPackagedProgramTemplates =
      this.packagedProgramTemplates.filter(packagedProgramTemplate => packagedProgramTemplate.displayName.toLowerCase() === searchTerm.toLowerCase());
    for (const bundel of this.availableBundles) {
      this.filteredPackagedProgramTemplate.set(bundel.Id, this.packagedProgramTemplates.filter(packagedProgramTemplate =>
        (packagedProgramTemplate.displayName.toLowerCase() === searchTerm.toLowerCase()) && (packagedProgramTemplate.bundles.includes(bundel.Id))));
    }
    if (this.filteredPackagedProgramTemplates.length !== 0) {
      this.filteredPackagedProgramTemplateResult = true;
    }
  }

  private clearFilter(): void {
    this.resetSearch();
    this.filteredRuleResult = false;
    this.filteredConfiguredExclusionResult = false;
    this.filteredPackagedExclusionResult = false;
    this.filteredPackagedProgramTemplateResult = false;
    this.filteredPackagedProgramTemplate.clear();
  }

  private initPackagedProgramTemplates(packagedPrograms: Program[]): void {
    const packagedProgramTemplates: Program[] = packagedPrograms.filter((packagedProgram: Program) => {
      packagedProgram = Object.assign(new Program(), packagedProgram);
      return packagedProgram.isPackagedTemplateSupported();
    });
    this.packagedProgramTemplates.push(...packagedProgramTemplates);
  }

}