import { RoleInfo } from '../user-roles/role-info';
import { Type } from '../roleType';
import { ServiceResponse } from '../service-response';
import { Injectable } from '@angular/core'


@Injectable({
  providedIn: 'root'
  })

export class UserGroupDetails {

  id: string;
  name: string;
  description: string;
  roles: RoleInfo[];
  createdDate: number;
  modifiedDate: number;
  modifiedBy: string;
  active: boolean;
  type: Type;

  public constructor(userGroupDetails = null) {
    if (userGroupDetails) {
      Object.assign(this, userGroupDetails);
    }
  }

}