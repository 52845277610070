import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Action } from '../models/action';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  host = environment.host;
  base = environment.base;
  configuredActionUrl = 'action/configured/';
  packagedActionUrl = 'action/packaged/';
  constructor(private http: HttpClient) { }

  getConfiguredActions(parentId: string) {
    const url = this.host + this.base + this.configuredActionUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.get(url, options);
  }

  updateConfiguredAction(parentId: string, id : string, configuredAction : Action) {
    const url = this.host + this.base + this.configuredActionUrl + id;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.put(url, configuredAction, options);
  }

  addConfiguredAction(parentId: string, configuredAction : Action) {
    const url = this.host + this.base + this.configuredActionUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post(url, configuredAction, options);
  }

  deleteConfiguredAction(parentId: string, id: string) {
    const url = this.host + this.base + this.configuredActionUrl + id;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.delete(url, options);
  }

  getConfiguredAction(parentId: string, id: string) {
    const url = this.host + this.base + this.configuredActionUrl + id;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };

    return this.http.get(url, options);
  }

  public getPackagedAction(parentId: string, serviceId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + this.packagedActionUrl + serviceId;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };

    return this.http.get<ServiceResponse>(url, options);
  }

}