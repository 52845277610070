import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants, FormOnSaveAction } from 'src/app/shared/constants';
import { Messages } from 'src/app/shared/message';
import { EntityCopy } from 'src/app/shared/models/entity-copy.model';
import { EntityType } from 'src/app/shared/models/entity-type';
import { ConfiguredExclusion } from 'src/app/shared/models/exclusion/configured-exclusion';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { EntityCopyService } from 'src/app/shared/services/entity-copy.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';

@Component({
  selector: 'app-exclusion-builder-header',
  templateUrl: './exclusion-builder-header.component.html'
})
export class ExclusionBuilderHeaderComponent implements OnInit {

  @Input()
  public action: FormOnSaveAction;

  @Input()
  public configuredExclusion: ConfiguredExclusion;

  @Input()
  public configuredExclusions: ConfiguredExclusion[];

  @Input()
  public readOnly: boolean;

  @Output()
  public saveClicked = new EventEmitter<void>();

  @Output()
  public deleteClicked = new EventEmitter<void>();

  public exclusionHeaderFormGroup: UntypedFormGroup;
  public isApiServiceCalled: boolean;
  public formOnSaveAction = FormOnSaveAction.CREATE;
  public parentId: string;
  public editExclusionId: string;
  public exclusionsRouterLink = Constants.EXCLUSIONS_HOME_ROUTER_LINK;
  public messages = Messages;
  public existingNames: string[];
  public formControlNames = {
    'NAME': 'name'
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  public constructor(
    public authorizationService: AuthorizationService,
    public parentContextService: ParentContextService,
    private entityCopyService: EntityCopyService,
    private popupService: PopupMessageService) {
    this.initInputParams();
  }

  public ngOnInit(): void {
    this.initFormGroup();
    this.initExistingNames();
  }

  public onSave(): void {
    this.saveClicked.emit();
  }

  public onDelete(): void {
    this.deleteClicked.emit();
  }

  public copyConfiguredExclusion(): void {
    this.isApiServiceCalled = true;
    this.entityCopyService.copyEntity(this.parentId, EntityCopy.build(this.configuredExclusion.id, Feature.CFG_EXCLUSION))
      .pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.isApiServiceCalled = false;
        this.popupService.showSuccessMessage(`Copy Successful. New Exclusion '${<string>res['result'].name}' successfully created.`);
      }, error => {
        this.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.result);
      });
  }

  public isSystemExclusion(): boolean {
    return this.configuredExclusion.type === EntityType.PACKAGED;
  }

  public validate(): boolean {
    return this.validateName(this.exclusionHeaderFormGroup.get(this.formControlNames.NAME).value);
  }

  public getName(): string {
    return this.exclusionHeaderFormGroup.get(this.formControlNames.NAME).value;
  }

  public isPristine(): boolean {
    return this.exclusionHeaderFormGroup.pristine;
  }

  public markAsPristine(): void {
    this.exclusionHeaderFormGroup.markAsPristine();
  }

  private initFormGroup(): void {
    this.exclusionHeaderFormGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.configuredExclusion.name)
    });
    if (this.readOnly) {
      this.exclusionHeaderFormGroup.get(this.formControlNames.NAME).disable();
    }
  }

  private initInputParams(): void {
    this.parentId = this.parentContextService.getParentContext();
  }

  private initExistingNames(): void {
    this.existingNames = [];
    const configuredExclusions = this.configuredExclusions.filter(configuredExclusion => this.configuredExclusion.id !== configuredExclusion.id);
    const configuredExclusionNames = configuredExclusions.map(configuredExclusion => configuredExclusion.name);
    this.existingNames.push(...configuredExclusionNames);
  }

  private validateName(name: string): boolean {
    const isValid = this.isValidName(name);
    if (!isValid) {
      this.exclusionHeaderFormGroup.get(this.formControlNames.NAME).setErrors({ required: true });
    }
    return isValid;
  }

  private isValidName(name: string): boolean {
    const valid: boolean = this.validateEmptyName(name);
    if (!valid) {
      return false;
    }
    return this.validateUniqueName(name);
  }

  private validateEmptyName(name: string): boolean {
    if (!name) {
      return false;
    }
    return true;
  }

  private validateUniqueName(name: string): boolean {
    if (this.isNameExist(name)) {
      return false;
    }
    return true;
  }

  private isNameExist(name: string): boolean {
    return this.existingNames.includes(name);
  }

}