<div class="container-fluid">
  <div class="row mt-2 border border-color-secondary rounded">
    <div class="col">
      <div class="row">
        <div class="col pt-4">
          <h2>Do not send if:</h2>
        </div>
      </div>
      <div *ngIf="!getDroppedEntityStateAttribute()" class="row my-2">
        <div class="col">
          <h4 class="text-style-muted-1">Start an exclusion by finding and draging from the left list an entity state associated with the exclusion you would like to take.</h4>
          <div class="drop-list" id="entityStateAttributeDropArea" coreUIDropList [data]="[]" [connectedTo]="exclusionDropAreaConnectedTo" (dropped)="onDropped($event)">
          </div>
        </div>
      </div>
      <div *ngIf="getDroppedEntityStateAttribute() && !getExclusionOperator()" class="container-fluid">
        <div class="row border border-color-secondary rounded my-2">
          <div class="col align-self-center">
            {{ getDroppedEntityStateAttribute().getAttributeDisplayName() }}
          </div>
          <div class="col-auto align-self-center ml-auto">
            <coreui-menu buttonLabel="Add Modifier" menuButtonStyle="link" verticalPosition="bottom" icon="add" iconAlignment="left">
              <coreui-menu-section>
                <button type="button" coreUIMenuItem *ngFor="let exclusionOperator of supportedExclusionOperatorsForDatatype" (click)="onAddModifier(exclusionOperator.value)">{{exclusionOperator.display}}</button>
              </coreui-menu-section>
            </coreui-menu>
          </div>
          <div class="col-auto py-2">
            <button coreUIButton="icon-large" withBorder (click)="onEntityStateAttributeDelete()"><i coreUIIcon="trash"></i></button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ng-template appExclusionConfiguration></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>