
import { ConfiguredProgram } from '../models/configured-program';
import { ProgramType } from '../program-type';
import { PackagedProgramAction } from './action/packaged-program-action';
import { ActionType } from './action/action-type';
import { PackagedProgramRule } from './packaged-program-rule';

export class ConfiguredProgramDetails extends ConfiguredProgram {

  id: string;
  modifiedBy: string;
  modifiedDate: number;
  createdDate : number;
  configuredProgramList: ConfiguredProgramDetails[];

  constructor(response?: Object) {
    super();
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response)));
    }
  }

  public hasExternalTenant(externalTenantId: string) : boolean {
    this.configuredProgramList = [];
    if (this.programType === ProgramType.PACKAGED) {
      const businessUnitIds: string[] = this.getBusinessIdsOfPackagedProgram(this.configuration.rules);
      if (businessUnitIds.includes(externalTenantId)) {
        return true;
      }
    } else if (this.configuration.rule) {
      const rule = JSON.parse(this.configuration.rule);
      if (rule.actions) {
        rule.actions.forEach(action => {
          if (action.actionSettingsInput && action.actionSettingsInput.businessUnitId
              && action.actionSettingsInput.businessUnitId === externalTenantId) {
            return true;
          }
        });
      }
    }
    return false;
  }

  public hasActionDestination(value: string): boolean {
    if (this.programType === ProgramType.PACKAGED) {
      return this.getActionDestinationIds().includes(value);
    } else if (this.configuration.rules) {
      return this.configuration.rules.includes(value);
    }
    return false;
  }

  public hasEventSource(value: string): boolean {
    let isValuePresent = false;
    if (this.configuration.rules) {
      const rules = JSON.parse(this.configuration.rules);
      rules.forEach(rule => {
        if (rule.eventKey && rule.eventKey.eventSourceName && rule.eventKey.eventSourceName.includes(value)) {
          isValuePresent = true;
        }
      });
    }
    return isValuePresent;
  }

  public hasCompany(value: string, companyMap: Map<string, string>): boolean {
    let isValuePresent = false;
    if (this.configuration.rules) {
      const rules = JSON.parse(this.configuration.rules);
      rules.forEach(rule => {
        if (rule.companyId && (value === companyMap.get(rule.companyId))) {
          isValuePresent = true;
        }
      });
    }
    return isValuePresent;
  }

  public getBusinessIdsOfPackagedProgram(strRules: string): string[] {
    const rules = JSON.parse(strRules);
    const businessUnitIds: string[] = [];
    rules.forEach((rule: PackagedProgramRule) => {
      rule = Object.assign(new PackagedProgramRule(), rule);
      if (rule.actions) {
        const apiActions: PackagedProgramAction[] = rule.getActions(ActionType.API_ACTION);
        apiActions.forEach(action => {
          const serviceInput = action.actionSettingsInput['serviceInput'];
          businessUnitIds.push(serviceInput.businessUnitId);
        });
      }
    });
    return businessUnitIds;
  }
}
