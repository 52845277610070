<coreui-radio-group label={{label}} orientation={{orientation}}>
  <div class="custom-control custom-radio" [formGroup]=formGroup>
    <coreui-form-field *ngFor="let item of radioItems" label={{item.label}}>
      <input
        type="radio"
        coreUIRadio
        id={{item.id}}
        class="custom-control-input"
        name={{item.name}}
        value={{item.value}}
        formControlName={{item.formControlName}}
        (change)="callback(e)"
      >
    </coreui-form-field>
  </div>
</coreui-radio-group>
