
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ActionsApiConfigurationComponent } from 'src/app/modules/actions/actions-api-configuration/actions-api-configuration.component';
import { ActionsAwsConfigurationComponent } from 'src/app/modules/actions/actions-aws-configuration/actions-aws-configuration.component';
import { placeHolderSyntaxValidator } from 'src/app/shared/form-validators/placeholder-validator';
import { ActionDestinationTypeMapping } from 'src/app/shared/models/action-destination-type-mapping';
import { ASSOCIATION_API_FIELD_STATUS, ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER,
  ENTITY_TYPE, HAS_ASSOCIATIONS_IDENTIFIER, ID_IDENTIFIER } from 'src/app/shared/models/association/association-constants';
import { ConfiguredEntityAssociationParams } from 'src/app/shared/models/association/configured-entity-association-params';
import { DelayType } from 'src/app/shared/models/delay-type.enum';
import { Mode } from 'src/app/shared/models/mode-enum';
import { AssociationService } from 'src/app/shared/services/association.service';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { Constants, EntityTypes, OperationType } from 'src/app/shared/constants';
import { DelayByAttributeComponent } from 'src/app/shared/component/delay-by-attribute/delay-by-attribute.component';
import { Messages } from 'src/app/shared/message';
import { Pattern } from 'src/app/shared/pattern';
import { TimeDurationComponent } from 'src/app/shared/component/time-duration/time-duration.component';
import { ActionDelay } from 'src/app/shared/models/action-delay';
import { ActionType } from 'src/app/shared/models/action-type';
import { ApiServiceSettings } from 'src/app/shared/models/api-service-settings';
import { AwsServiceSettings } from 'src/app/shared/models/aws-service-settings';
import { ConfiguredEvent, ConfiguredEventId } from 'src/app/shared/models/configured-event.model';
import { Action } from 'src/app/shared/models/action';
import { Header } from 'src/app/shared/models/header';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { ActionService } from 'src/app/shared/services/action.service';
import { EventService } from 'src/app/shared/services/event.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { VelocityValidatorService } from 'src/app/shared/services/velocity-validator.service';
import { EntityType } from 'src/app/shared/entity-type';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { AssociationsTableBuilderService } from 'src/app/shared/services/associations-table-builder.service';
import { PackagedActionDestination } from 'src/app/shared/models/packaged-action-destination';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { EntityCopyService } from 'src/app/shared/services/entity-copy.service';
import { EntityCopy } from 'src/app/shared/models/entity-copy.model';

@Component({
  selector: 'app-actions-configuration',
  templateUrl: './actions-configuration.component.html'
})
export class ActionsConfigurationComponent extends BaseFormDirective implements OnInit, OnDestroy {

  @ViewChild(TimeDurationComponent) delayByTimeControls: TimeDurationComponent;
  @ViewChild(DelayByAttributeComponent) delayByAttributeControl: DelayByAttributeComponent;
  @ViewChild(ActionsAwsConfigurationComponent) awsConfigurationComponent: ActionsAwsConfigurationComponent;
  @ViewChild(ActionsApiConfigurationComponent) apiConfigurationComponent: ActionsApiConfigurationComponent;

  public parentId: string;
  public isApiServiceCalled: boolean;
  public actionDestinations: any[];
  public actionTypes: any[];
  public endPoint: string;
  public resourceName: string;
  public awsRegion: string;
  public actionTypeAWS: any[];
  public actionTypeAPI: any[];
  public actionPreference: string;
  public configuredActions: Action[];
  public configuredAction: Action;
  public isDeleteOperation: boolean;
  public isValueChanged = false;
  public configuredEvents: ConfiguredEvent[] = [];
  public filteredEvents: ConfiguredEvent[];
  public eventSourceProviders: any[] = [];
  public entityTypes = EntityTypes;
  public configuredActionActiveInPrograms = '';
  public inputParams = {
    id: '',
    action: '',
    entityType: ''
  };
  public responseArr = [];
  public isNewAction = false;

  public delay: string;
  public selectedEventSchema: string;
  public operationStatusMessage = '';
  public operationFailure = false;
  public hasAssociation: boolean;
  public associationStatus: ConfiguredEntityAssociationParams;
  public modesList = [
    {
      'id': 'asynchronous',
      'name': 'mode',
      'value': Mode.ASYNCHRONOUS,
      'label': 'Asynchronous',
      'formControlName': 'mode'
    },
    {
      'id': 'synchronous',
      'name': 'mode',
      'value': Mode.SYNCHRONOUS,
      'label': 'Synchronous',
      'formControlName': 'mode'
    }
  ];
  public delayList = [
    {
      'id': 'noDelay',
      'name': 'delayType',
      'value': 'NO_DELAY',
      'label': 'No delay',
      'formControlName': 'delayType'
    },
    {
      'id': 'delayByTime',
      'name': 'delayType',
      'value': 'DURATION',
      'label': 'Delay by time',
      'formControlName': 'delayType'
    },
    {
      'id': 'delayByAttribute',
      'name': 'delayType',
      'value': 'ATTRIBUTE',
      'label': 'Delay by attribute',
      'formControlName': 'delayType'
    }
  ];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private isConfiguredAD = true;


  constructor(private actionService: ActionService,
    private eventService: EventService,
    private actionDestinationService: ActionDestinationService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public parentContext: ParentContextService, route: ActivatedRoute,
    private popupService: PopupMessageService,
    private eventSourceService: EventSourceService,
    private associationService: AssociationService,
    public authorizationService: AuthorizationService,
    private associationsTableBuilderService: AssociationsTableBuilderService,
    private entityCopyService: EntityCopyService) {
    super();
    this.initInputParam(route);
    this.actionDestinations = [];
    this.actionTypes = [];
    this.eventSourceProviders = [];
    this.filteredEvents = [];
  }

  ngOnInit(): void {
    this.parentId = this.parentContext.getParentContext();
    this.operationStatusMessage = 'Loading action';
    this.buildConfiguredActionFormToAdd();
    this.getEntities();
    this.actionTypes = [];
    this.actionDestination.valueChanges.subscribe(() => {
      this.actionPreference = '';
    });
    this.actionType.valueChanges.subscribe(changes => {
      this.actionPreference = changes;
    });
  }
  public get actionTypeControl(): AbstractControl {
    return this.configuredEntityForm.get('actionType');
  }

  public get actionDestinationControl(): AbstractControl {
    return this.configuredEntityForm.get('actionDestination');
  }

  public deleteHeader(controls: UntypedFormArray, index: number): void {
    controls.removeAt(index);
  }

  public addNewHeader(controls: UntypedFormArray): void {
    controls.push(this.initializeHeaders(new Header('', '')));
  }

  public get actionName(): AbstractControl {
    return this.configuredEntityForm.get('actionName');
  }

  public get actionDestination(): AbstractControl {
    return this.configuredEntityForm.get('actionDestination');
  }
  public get actionType(): AbstractControl {
    return this.configuredEntityForm.get('actionType');
  }
  public get eventType(): AbstractControl {
    return this.configuredEntityForm.get('eventType');
  }

  public get eventSourceProvider(): AbstractControl {
    return this.configuredEntityForm.get('eventSourceProvider');
  }

  public get actionDescription(): AbstractControl {
    return this.configuredEntityForm.get('actionDescription');
  }

  public get contentType(): AbstractControl {
    return this.configuredEntityForm.get('contentType');
  }

  public get content(): AbstractControl {
    return this.configuredEntityForm.get('content');
  }

  public get httpMethod(): AbstractControl {
    return this.configuredEntityForm.get('httpMethod');
  }

  public get headers(): AbstractControl {
    return this.configuredEntityForm.get('headers');
  }

  public get delayType(): AbstractControl {
    return this.configuredEntityForm.get('delayType');
  }

  public getinvalidPlaceHolderStatusMsg(controlName: string): string {
    let apiField: string;
    switch (controlName) {
      case 'endPoint':
        apiField = 'API URL';
        break;
      case 'headerName':
        apiField = 'Name';
        break;
      case 'headerValue':
        apiField = 'Value';
        break;
      case 'content':
        apiField = 'Content';
        break;
      default:
        apiField = 'Your input';
        break;
    }
    return `${apiField} ${Messages.actionConfigurationValidationErrorTxt}`;
  }

  public canNavigateAway(): boolean {
    if (this.isDataSaved || this.isDeleteOperation || this.hasAssociation) {
      return true;
    }
    return !(this.inputParams.entityType === EntityTypes.CUSTOM.toLowerCase() &&
        (this.inputParams.action === OperationType.ADD && this.isCreateFormUpdated() ||
            this.inputParams.action === OperationType.EDIT && this.isEditFormUpdated()));
      }

  private isEditFormUpdated(): boolean {
    return JSON.stringify(this.configuredAction) !==
        JSON.stringify(this.getConfiguredAction(this.configuredEntityForm.controls.actionType.value, this.configuredAction));
  }

  private isCreateFormUpdated(): boolean {
    return this.configuredEntityForm.controls.actionName.value !== '' ||
        this.configuredEntityForm.controls.actionDestination.value !== null ||
        this.configuredEntityForm.controls.actionType.value !== null ||
        this.configuredEntityForm.controls.mode.value !== Mode.ASYNCHRONOUS ||
        this.configuredEntityForm.controls.eventSourceProvider.value !== '' ||
        this.configuredEntityForm.controls.actionDescription.value !== '' ||
        this.configuredEntityForm.controls.eventType.value !== '' ||
        this.configuredEntityForm.controls.contentType.value !== 'application/json' ||
        this.configuredEntityForm.controls.content.value !== '' ||
        this.configuredEntityForm.controls.delayType.value !== 'NO_DELAY';
  }

  public onEventSourceProviderChange(eventSourceProvider: string): void {
    this.filteredEvents = this.configuredEvents.filter(event => event.id.eventSourceName === eventSourceProvider);
  }

  public showDeleteConfiguredActionAlert(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteConfiguredAction,
      msg: Messages.confirmDeleteConfiguredAction
    });
  }

  public handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteConfiguredAction();
    }
  }

  public deleteConfiguredAction(): void {
    this.isApiServiceCalled = true;
    this.operationStatusMessage = 'Deleting action';
    this.actionService.deleteConfiguredAction(this.parentId, this.inputParams.id).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isApiServiceCalled = false;
      this.isDeleteOperation = true;
      this.popupService.showDeleteMessage(`Action '${<string> this.configuredEntityForm.controls.actionName.value}' successfully deleted`);
      void this.router.navigate(['actions']);
    }, error => {
      this.isApiServiceCalled = false;
      if (error.status === 403) {
        this.popupService.showErrorMessage(error.error.statusMessage);
      } else {
        this.popupService.showErrorMessage(this.getOperationFailureMessage());
        this.operationFailure = true;
      }
    });
  }

  public saveConfiguredAction(): void {
    this.isSaveClicked = true;
    if (this.apiConfigurationComponent) {
      this.apiConfigurationComponent.configuredAPIActionForm.markAllAsTouched();
    }
    if(!this.validateActionName()){
      this.configuredEntityForm.controls.actionName.setErrors({ 'pattern': true });
    }
    if (this.actionNameHasChanged()){
      this.checkIfActionNameAlreadyExists();
    }
    if (!this.configuredEntityForm.valid || !this.apiConfigurationComponent.configuredAPIActionForm.valid) {
      this.popupService.showErrorMessage(Messages.requiredFields);
    } else {
      const actionTypeSelected = this.configuredEntityForm.controls.actionType.value;
      const configuredAction: Action = new Action();
      this.configuredAction = this.getConfiguredAction(actionTypeSelected, configuredAction);
      if (this.isEditMode()) {
        this.updateConfiguredAction(this.inputParams.id, this.configuredAction);
      } else {
        this.addConfiguredAction(this.configuredAction);
      }
    }
  }

  private validateActionName(): boolean{
    return this.configuredEntityForm.controls.actionName.value.match(Pattern.ALPHA_NUMERIC_HYPHEN_UNDERSCORE_SPACE);
  }

  private actionNameHasChanged(): boolean{
    return this.configuredAction.name != this.configuredEntityForm.controls.actionName.value;
  }

  public copyConfiguredAction(): void {
    this.isApiServiceCalled = true;
    this.entityCopyService.copyEntity(this.parentId, EntityCopy.build(this.inputParams.id, Feature.CFG_ACTION))
      .pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.isApiServiceCalled = false;
        this.popupService.showSuccessMessage(`Copy Successful. New Action '${<string>res['result'].name}' successfully created.`);
      }, error => {
        this.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.result);
      });
  }

  public getConfiguredAction(actionTypeSelected: string, configuredAction: Action): Action {
    switch (actionTypeSelected) {
      case ActionType.API:
        configuredAction = this.createConfigurationActionObject(ActionType.API);
        break;
      case ActionType.LAMBDA:
        configuredAction = this.createConfigurationActionObject(ActionType.LAMBDA);
        break;
    }
    return configuredAction;
  }
  public createConfigurationActionObject(actionType: string): Action {
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = this.configuredEntityForm.controls.eventSourceProvider.value;
    configuredEventId.eventName = this.configuredEntityForm.controls.eventType.value;
    const configuredAction = new Action(this.configuredAction);
    configuredAction.name = this.configuredEntityForm.controls.actionName.value;
    configuredAction.id = this.inputParams.id;
    configuredAction.actionDestinationId = (<string> this.configuredEntityForm.controls.actionDestination.value).split('|')[0];
    configuredAction.eventId = configuredEventId;
    configuredAction.actionType = this.configuredEntityForm.controls.actionType.value;
    configuredAction.description = this.configuredEntityForm.controls.actionDescription.value === null ? '' : this.configuredEntityForm.controls.actionDescription.value;
    configuredAction.setMode(this.configuredEntityForm.controls.mode.value);
    if (actionType === ActionType.LAMBDA) {
      const awsActionSettings: AwsServiceSettings = new AwsServiceSettings();
      awsActionSettings.resourceName = (<string> this.awsConfigurationComponent.configuredAWSActionForm.value['resourceName']).trim();
      this.resourceName = awsActionSettings.resourceName;
      awsActionSettings.awsRegion = this.awsConfigurationComponent.configuredAWSActionForm.controls.awsRegion.value;
      this.awsRegion = awsActionSettings.awsRegion;
      awsActionSettings.type = 'LambdaActionSettings';
      awsActionSettings.contentType = this.configuredEntityForm.controls.contentType.value;
      awsActionSettings.payload = (<string> this.configuredEntityForm.controls.content.value).trim();
      const actionDelay: ActionDelay = new ActionDelay();
      actionDelay.delayType = this.configuredEntityForm.controls.delayType.value;
      if (this.configuredEntityForm.controls.delayType.value === DelayType.DURATION) {
        actionDelay.delay = this.delayByTimeControls.getDelay();
      } else if (this.configuredEntityForm.controls.delayType.value === DelayType.ATTRIBUTE) {
        actionDelay.delay = this.delayByAttributeControl.getDelay();
      }
      awsActionSettings.actionDelay = actionDelay;
      configuredAction.data = JSON.stringify(awsActionSettings);
      return configuredAction;
    } else if (actionType === ActionType.API) {
      const apiActionSettings: ApiServiceSettings = new ApiServiceSettings();
      apiActionSettings.endPoint = (<string> this.apiConfigurationComponent.configuredAPIActionForm.value['endPoint']).trim();
      this.endPoint = apiActionSettings.endPoint;
      apiActionSettings.httpMethod = this.configuredEntityForm.controls.httpMethod.value;
      apiActionSettings.setHeaders(this.configuredEntityForm.controls.headers.value);
      apiActionSettings.contentType = this.configuredEntityForm.controls.contentType.value;
      apiActionSettings.payload = (<string> this.configuredEntityForm.controls.content.value).trim();
      const actionDelay: ActionDelay = new ActionDelay();
      actionDelay.delayType = this.configuredEntityForm.controls.delayType.value;
      if (this.configuredEntityForm.controls.delayType.value === DelayType.DURATION) {
        actionDelay.delay = this.delayByTimeControls.getDelay();
      } else if (this.configuredEntityForm.controls.delayType.value === DelayType.ATTRIBUTE) {
        actionDelay.delay = this.delayByAttributeControl.getDelay();
      }
      apiActionSettings.actionDelay = actionDelay;
      configuredAction.data = JSON.stringify(apiActionSettings);
      return configuredAction;
    } else {
      return null;
    }
  }

  public onDelayOptionChange = (): void => {
    const selectedDelayOption = this.configuredEntityForm.controls.delayType.value;
    if (selectedDelayOption === DelayType.NO_DELAY) {
      this.configuredEntityForm.removeControl('delayByAttribute');
      this.configuredEntityForm.removeControl('durationControls');
    } else if (selectedDelayOption === DelayType.DURATION) {
      this.configuredEntityForm.removeControl('delayByAttribute');
      this.delay = this.delay ? !this.delay.startsWith('$') ? this.delay : 'PT1M' : 'PT1M';
    } else if (selectedDelayOption === DelayType.ATTRIBUTE) {
      this.configuredEntityForm.removeControl('durationControls');
    }
  }

  public onModeChange(): void {
  }

  public getEventSchemaForEventType(): void {
    const eventByName: ConfiguredEvent[] = this.configuredEvents.filter(event => event.name === this.configuredEntityForm.controls.eventType.value);
    if (eventByName.length > 0) {
      this.selectedEventSchema = eventByName[0].schema;
    }
  }


  public invalidateForm(status: boolean): void {
    if (status) {
      this.configuredEntityForm.setErrors({ 'invalid': status });
    } else {
      this.configuredEntityForm.updateValueAndValidity();
    }
  }

  public onItemChange(event: string): void {
    if (event === null) {
      return;
    }
    const actionDestination = this.actionDestinations.find(acd => acd.actionDestination === event);
    const type = actionDestination.type;
    switch (ActionDestinationTypeMapping[type]) {
      case ActionDestinationTypeMapping.AWS:
        this.actionTypes = [];
        this.actionTypes = [{
          'id': ActionDestinationTypeMapping.AWS,
          'name': ActionDestinationTypeMapping.AWS,
          'value': ActionDestinationTypeMapping.AWS
        }];
        this.actionPreference = this.actionTypes[0].id;
        this.configuredEntityForm.removeControl('httpMethod');
        break;
      case ActionDestinationTypeMapping.CUSTOM:
        this.actionTypes = [];
        this.actionTypes = [{
          'id': ActionDestinationTypeMapping.CUSTOM,
          'name': ActionDestinationTypeMapping.CUSTOM,
          'value': ActionDestinationTypeMapping.CUSTOM }];
        this.actionPreference = this.actionTypes[0].id;
        this.configuredEntityForm.addControl('httpMethod', new UntypedFormControl(this.httpMethod));
        break;
    }
  }

  public checkIfActionNameAlreadyExists(): void {
    const isAlreadyTaken = this.configuredActions.find(
      existingConfiguredAction => existingConfiguredAction.name.toLowerCase()
      === (<string> this.configuredEntityForm.controls.actionName.value).toLowerCase());
    if (isAlreadyTaken !== undefined) {
      this.configuredEntityForm.controls.actionName.setErrors({ 'alreadyExist': true });
    }
  }

  public validateJson(): void {
    if (this.configuredEntityForm.controls.content.value !== null
      && (<string> this.configuredEntityForm.controls.content.value).trim().length > 0) {
      try {
        JSON.parse(this.configuredEntityForm.get('content').value);
      } catch (e) {
        this.configuredEntityForm.controls.content.setErrors({ 'invalidJson': true });
      }
    } else {
      this.configuredEntityForm.controls.content.setErrors({ 'required': true });
    }
  }

  public validateActionContent(): void {
    const actionContent = this.configuredEntityForm.controls.content.value;
    if (actionContent !== null && (<string> actionContent).trim().length > 0) {
      if (VelocityValidatorService.isVelocitySyntaxPresent(actionContent)) {
        if (!VelocityValidatorService.isValidVelocityTemplate(actionContent)) {
          this.configuredEntityForm.controls.content.setErrors({ 'invalidVelocitySyntax': true });
        } else if (!VelocityValidatorService.isValidJSON(VelocityValidatorService.removeVelocitySyntaxFromActionContent(actionContent))) {
          this.configuredEntityForm.controls.content.setErrors({ 'invalidJSONInVelocityTemplate': true });
        }

      } else if (!VelocityValidatorService.isValidJSON(actionContent)) {
        this.configuredEntityForm.controls.content.setErrors({ 'invalidJson': true });
      }
    } else {
      this.configuredEntityForm.controls.content.setErrors({ 'required': true });
    }
  }

  public getOperationFailureMessage(): string {
    return Messages.actionOperationFailureMessage;
  }

  public setAssociationInProgress(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = true;
  }

  public setAssociationDone(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = false;
    this.hasAssociation = event[HAS_ASSOCIATIONS_IDENTIFIER];
  }

  public disableUneditableFields(): void {
    this.configuredEntityForm.controls['actionDestination'].disable();
    this.configuredEntityForm.controls['actionType'].disable();
    this.configuredEntityForm.controls['eventSourceProvider'].disable();
    this.configuredEntityForm.controls['eventType'].disable();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public hasAssociations(): boolean {
    return this.hasAssociation;
  }

  public isSaveDisabled(): boolean {
    return this.hasAssociations();
  }

  public hasOperationFailed(): boolean {
    return this.operationFailure || this.hasAssociations();
  }

  public getAssociationsBtnTxt(): string {
    return Messages.viewAssociationsBtnTxt;
  }

  public openAssociationsModal(): void {
    const entityType = 'CONFIGURED_ACTION';
    this.associationsTableBuilderService
      .withEntityType(entityType)
      .withConfiguredEntityAssociationParams(this.associationStatus)
      .buildAssociationTableDataAfterLookup()
      .subscribe(associationTableData => {
        if (associationTableData[ENTITY_TYPE] === entityType) {
          this.launchAssociationsModal(associationTableData.tableData);
        }
      });
    // Track association status - in progress
    this.associationsTableBuilderService.trackAssociationInProgress().subscribe($event => {
      this.setAssociationInProgress($event);
    });
    // Track association status - done
    this.associationsTableBuilderService.trackAssociationDone().subscribe($event => {
      if ($event[ENTITY_TYPE] !== entityType
        || !(ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER in $event)
        || !(HAS_ASSOCIATIONS_IDENTIFIER in $event)
        || !(ID_IDENTIFIER in $event)) {
        return;
      }
      this.setAssociationDone($event);
    });
  }

  private launchAssociationsModal(associationModalDetail: Record<string, any>): void {
    this.infoModal.launchModal({
      'title': associationModalDetail['title'],
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': associationModalDetail['data'],
        'tableConfig': {
          'properties': associationModalDetail['properties'],
          'isDataLoading': associationModalDetail['isDataLoading']
        }
      }
    });
  }

  private getEntities() {
    this.isApiServiceCalled = true;
    this.operationStatusMessage = 'Loading action';
    this.callAPIs().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      const configuredActionsResult = result[0];
      this.configuredActions = configuredActionsResult.result;
      const configuredCustomActionDestinationsResult = result[1];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.actionDestinations = configuredCustomActionDestinationsResult.result.filter(
        actionDestination => actionDestination.type === Constants.CUSTOM || actionDestination.type === Constants.AWS);
      const configuredEventsResult = result[2];
      this.configuredEvents = configuredEventsResult.result;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.eventSourceProviders = result[3].result.filter(configuredEvent => configuredEvent.type === EntityType.SELF_SERVICE);
      if (this.isEditMode() && !this.isNewAction) {
        if (this.isStandardAction()) {
          const packagedActionResult = new Action(result[4].result);
          this.hasConfiguredAD(configuredCustomActionDestinationsResult.result, packagedActionResult);
          this.buildFromPackagedAction(packagedActionResult);
          this.configuredEntityForm.disable();
        } else {
          const configuredActionResult = new Action(result[4].result);
          this.setConfiguredAction(configuredActionResult);
        }
        this.validateAssociationStatus();
      }
      this.isApiServiceCalled = false;
    }, () => {
      this.isApiServiceCalled = false;
    });
  }

  private hasConfiguredAD(actionDestinations: any[], packagedAction: Action): void {
    this.isConfiguredAD = actionDestinations.find(actionDestination => actionDestination.actionDestination === packagedAction.actionProvider) !== undefined;
  }

  private buildFromPackagedAction(packagedAction: Action) : void {
    const packagedAD: PackagedActionDestination = new PackagedActionDestination();
    packagedAD.actionDestination = packagedAction.actionProvider;
    packagedAD.name = packagedAction.actionDestinationId;
    packagedAction.data = JSON.stringify(packagedAction.settings);
    packagedAction.actionDestinationId = packagedAction.actionProvider;
    this.actionDestinations.push(packagedAD);
    this.setConfiguredAction(packagedAction);
  }

  private isEditMode() {
    return this.inputParams.action === OperationType.EDIT;
  }

  private isStandardAction() {
    return this.inputParams.entityType === EntityType.PACKAGED.toLowerCase();
  }

  private callAPIs(): Observable<any[]> {
    const configuredActions = this.actionService.getConfiguredActions(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredCustomActionDestinations = this.actionDestinationService.getActionDestinations(this.parentId)
      .pipe(catchError(() => of({ 'result': [] })));
    const configuredEvents = this.eventService.getConfiguredEvents(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredEventSources = this.eventSourceService.getEventSources(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredAction = this.actionService.getConfiguredAction(this.parentId, this.inputParams.id)
      .pipe(catchError(() => of({ 'result': [] })));
    const packagedAction = this.actionService.getPackagedAction(this.parentId, this.inputParams.id).pipe(catchError(() => of({ 'result': [] })));
    const apisToCall: any[] = [
      configuredActions,
      configuredCustomActionDestinations,
      configuredEvents,
      configuredEventSources
    ];
    if (this.isEditMode()) {
      if (this.isStandardAction()) {
        apisToCall.push(packagedAction);
      } else {
        apisToCall.push(configuredAction);
      }
    }
    return forkJoin(apisToCall);
  }

  private setConfiguredAction(configuredAction: Action) {
    if (!this.isStandardAction()) {
      this.onEventSourceProviderChange(configuredAction.eventId.eventSourceName);
      this.disableUneditableFields();
    }
    this.patchForm(configuredAction);
    this.configuredAction = configuredAction;
    this.configuredAction.description = configuredAction.description === null ? '' : configuredAction.description;
  }

  private patchForm(configuredAction: Action) {
    const parsedData = JSON.parse(configuredAction.data);
    let serviceSettings;
    switch (configuredAction.actionType) {
      case ActionType.API: {
        serviceSettings = Object.create(ApiServiceSettings.prototype);
        this.actionTypes = [{ 'id': ActionType.API, 'name': ActionType.API, 'value': ActionType.API }];
        Object.assign(serviceSettings, parsedData);
        this.endPoint = serviceSettings.endPoint;
        this.setHeaders(serviceSettings.getHeaders(this.inputParams.entityType));
        break;
      }
      case ActionType.LAMBDA: {
        serviceSettings = Object.create(AwsServiceSettings.prototype);
        this.actionTypes = [{ 'id': ActionType.LAMBDA, 'name': ActionType.LAMBDA, 'value': ActionType.LAMBDA }];
        Object.assign(serviceSettings, parsedData);
        this.resourceName = serviceSettings.resourceName;
        this.awsRegion = serviceSettings.awsRegion;
        break;
      }
    }
    const actionDelay = serviceSettings.actionDelay;
    const delayType = actionDelay && actionDelay.delayType ? actionDelay.delayType : DelayType.NO_DELAY;
    const sourceProvider = configuredAction.eventId;
    const eventSourceProvider = sourceProvider ? configuredAction.eventId.eventSourceName : '';
    const eventType = sourceProvider ? configuredAction.eventId.eventName : '';
    this.configuredEntityForm.patchValue({
      id: configuredAction.id,
      actionName: configuredAction.name,
      actionDestination: configuredAction.actionDestinationId,
      actionType: configuredAction.actionType,
      mode: configuredAction.getMode(),
      eventSourceProvider: eventSourceProvider,
      eventType: eventType,
      actionDescription: configuredAction.description,
      httpMethod: serviceSettings.httpMethod,
      contentType: serviceSettings.contentType,
      content: serviceSettings.payload,
      headers: serviceSettings.getHeaders(this.inputParams.entityType),
      delayType: delayType
    });
    this.delay = actionDelay && actionDelay.delayType ? actionDelay.delay : '';
    this.getEventSchemaForEventType();
  }

  private setHeaders(headersData: Header[]) {
    const headers = <UntypedFormArray> this.configuredEntityForm.controls.headers;
    if (headersData !== null) {
      headersData.forEach(h => {
        headers.push(this.initializeHeaders(h));
      });
    }
    return headers;
  }

  private initializeHeaders(h: Header) {
    return this.fb.group({
      headerName: new UntypedFormControl(h.headerName, {
        validators: [Validators.required, Validators.pattern(Pattern.NON_WHITE_SPACE), placeHolderSyntaxValidator()]
      }),
      headerValue: new UntypedFormControl(h.headerValue, { validators: [Validators.required, placeHolderSyntaxValidator()] })
    });
  }


  private buildConfiguredActionFormToAdd(): void {
    this.configuredAction = this.buildEmptyConfiguredAction();
    this.buildForm(this.configuredAction);
  }

  private buildEmptyConfiguredAction(): Action {
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = '';
    configuredEventId.eventName = '';
    const configuredAction: Action = new Action();
    configuredAction.name = '';
    configuredAction.id = '';
    configuredAction.eventId = configuredEventId;
    configuredAction.description = '';
    configuredAction.data = '';

    const actionDelay = new ActionDelay();
    actionDelay.delay = '';
    actionDelay.delayType = DelayType.NO_DELAY;
    return configuredAction;
  }

  private buildForm(configuredAction: Action): void {
    this.configuredEntityForm = this.fb.group({
      id: new UntypedFormControl(configuredAction.id),
      actionName: new UntypedFormControl(configuredAction.name, {
        validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC_SPECIAL_CHAR)]
      }),
      actionDestination: new UntypedFormControl(configuredAction.actionDestinationId, { validators: Validators.required }),
      actionType: new UntypedFormControl(configuredAction.actionType, { validators: Validators.required }),
      mode: new UntypedFormControl(configuredAction.getMode(), { validators: Validators.required }),
      eventSourceProvider: new UntypedFormControl(configuredAction.eventId.eventSourceName, { validators: Validators.required }),
      actionDescription: new UntypedFormControl(configuredAction.description),
      eventType: new UntypedFormControl(configuredAction.eventId.eventName, { validators: Validators.required }),
      contentType: new UntypedFormControl('application/json'),
      httpMethod: new UntypedFormControl('', { validators: Validators.required }),
      content: new UntypedFormControl('', {
        validators: [Validators.required, placeHolderSyntaxValidator()], updateOn: 'blur'
      }),
      headers: this.fb.array([]),
      delayType: new UntypedFormControl(DelayType.NO_DELAY)
    });
  }

  private initInputParam(route: ActivatedRoute) {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.inputParams.action = route.snapshot.paramMap.get('action');
      this.inputParams.id = route.snapshot.paramMap.get('id');
      this.inputParams.entityType = route.snapshot.paramMap.get('type');
    });
  }

  private addConfiguredAction(configuredAction: Action): void {
    this.isApiServiceCalled = true;
    this.actionService.addConfiguredAction(this.parentId, configuredAction).pipe(takeUntil(this.destroyed$)).subscribe(serviceResponse => {
      this.isApiServiceCalled = false;
      this.isNewAction = true;
      this.isDataSaved = true;
      this.isSaveClicked = false;
      this.popupService.showSuccessMessage('Action "' + this.configuredEntityForm.controls.actionName.value + '" successfully added', true);
      this.responseArr.push(serviceResponse);
      this.inputParams.id = this.responseArr[0].result.id;
      this.getEntities();
      this.inputParams.action = OperationType.EDIT;
      this.router.navigateByUrl('actions', {skipLocationChange: true}).then( () => {
        this.router.navigate([`actions/${this.inputParams.action}/${Constants.CUSTOM.toLowerCase()}/${this.inputParams.id}`]);
      });
      this.disableUneditableFields();
    }, error => {
      this.isSaveClicked = false;
      this.isApiServiceCalled = false;
      this.isDataSaved = false;
      if (error.error.statusMessage === 'INVALID_DATA') {
        this.popupService.showErrorMessage(Messages.invalidPlaceholderSyntaxErrorMsg);
      } else {
        this.popupService.showErrorMessage(error.error.result);
      }
    });
  }

  private updateConfiguredAction(id: string, configuredAction: Action) {
    this.isApiServiceCalled = true;
    this.actionService.updateConfiguredAction(this.parentId, id, configuredAction).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const configuredActions = this.actionService.getConfiguredActions(this.parentId).pipe(catchError(() => of({ result: [] })));
      this.configuredActions = configuredActions[0];
      this.isApiServiceCalled = false;
      this.isSaveClicked = false;
      this.popupService.showSuccessMessage('Action "' + this.configuredEntityForm.controls.actionName.value + '" successfully updated');
      this.isDataSaved = false;
    }, error => {
      this.isSaveClicked = false;
      this.isDataSaved = false;
      this.isApiServiceCalled = false;
      if (error.error.statusMessage === 'INVALID_DATA') {
        this.popupService.showErrorMessage(Messages.invalidPlaceholderSyntaxErrorMsg);
      } else {
        this.popupService.showErrorMessage(error.error.result);
      }
    });
  }

  private validateAssociationStatus(): void {
    if (!this.isEditMode()) {
      this.hasAssociation = false;
    }
    this.isApiServiceCalled = true;
    this.associationService.getAssociations(this.parentId, ConfiguredEntityType.ACTION, this.inputParams.id).subscribe(success => {
      this.hasAssociation = ASSOCIATION_API_FIELD_STATUS in success['result'] ? success['result'][ASSOCIATION_API_FIELD_STATUS] : false;
      this.associationStatus = new ConfiguredEntityAssociationParams(null, this.parentId, this.inputParams.id, ConfiguredEntityType.ACTION);
      if (this.hasAssociation) {
        this.configuredEntityForm.disable();
        if (this.configuredAction.actionType === ActionType.LAMBDA) {
          this.awsConfigurationComponent.configuredAWSActionForm.disable();
        }
        this.apiConfigurationComponent.configuredAPIActionForm.disable();
      }
      this.isApiServiceCalled = false;
    },
    error => {
      this.isApiServiceCalled = false;
      this.hasAssociation = false;
      if ((!('result' in error.error)) || (error.error.statusMessage === 'FAILED')) {
        this.popupService.showErrorMessage(Messages.associationRequestErroredMessage);
      } else {
        this.popupService.showErrorMessage(error.error.statusMessage);
      }
    });
  }

}
