import { EVENT_SOURCE } from './association-constants';
import { AssociationTemplateDetail } from './association-template-detail';
import { ConfiguredEntityType } from './configured-entity-type.enum';

export class ConfiguredEntityAssociationParams {

  constructor(
    private apiResponse: Record<string, any>,
    private parentId: string,
    private entityId: string,
    private entityType: ConfiguredEntityType,
    private additionalParams?: Record<string, any>
  ) { }

  get ParentId(): string {
    return this.parentId;
  }

  set ParentId(parentId: string) {
    this.parentId = parentId;
  }

  get EntityId(): string {
    return this.entityId;
  }

  set EntityId(entityId: string) {
    this.entityId = entityId;
  }

  get EntityType(): ConfiguredEntityType {
    return this.entityType;
  }

  set EntityType(entityType: ConfiguredEntityType) {
    this.entityType = entityType;
  }

  get AdditionalParams(): Record<string, any> {
    return this.additionalParams;
  }

  set AdditionalParams(additionalParams: Record<string, any>) {
    this.additionalParams = additionalParams;
  }

  get AssociationTemplateDetail(): AssociationTemplateDetail {
    switch (this.entityType) {
      case ConfiguredEntityType.EVENT:
        return AssociationTemplateDetail.forEvents(this.additionalParams[EVENT_SOURCE]);
      case ConfiguredEntityType.ACTION:
        return AssociationTemplateDetail.forActions();
      case ConfiguredEntityType.RULE:
        return AssociationTemplateDetail.forRules(this.additionalParams[EVENT_SOURCE]);
      case ConfiguredEntityType.EVENT_SOURCE:
        return AssociationTemplateDetail.forEventSources(this.entityId);
      case ConfiguredEntityType.PACKAGED_ACTION_DESTINATION:
        return AssociationTemplateDetail.forPackagedActionDestinations();
      case ConfiguredEntityType.CUSTOM_ACTION_DESTINATION:
        return AssociationTemplateDetail.forCustomActionDestinations();
      case ConfiguredEntityType.ENTITYSTATES:
        return AssociationTemplateDetail.forEntityStates();
      case ConfiguredEntityType.EXCLUSION:
        return AssociationTemplateDetail.forExclusions();
    }
  }

  public get ApiResponse(): Record<string, any> {
    return this.apiResponse;
  }

  public set ApiResponse(apiResponse: Record<string, any>) {
    this.apiResponse = apiResponse;
  }

}