import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { RulesHomeComponent } from './rules-home/rules-home.component';
import { RulesConfigurationComponent } from './rules-configuration/rules-configuration.component';
import { UnsavedChangesGuard } from 'src/app/shared/guard/unsaved-changes.guard';
const routes: Routes = [
  {
    path: 'rules',
    canActivate: [AuthGuard],
    component: RulesHomeComponent
  },
  {
    path: 'rules/:action/:ruleId',
    canActivate: [AuthGuard],
    canDeactivate: [UnsavedChangesGuard],
    component: RulesConfigurationComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RulesRoutingModule { }