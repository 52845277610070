import { UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { StepChangeEvent } from '@epsilon/core-ui';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Messages } from 'src/app/shared/message';
import { PackageType } from 'src/app/shared/models/package-builder/package-type-enum';
import { PackageLevel } from 'src/app/shared/models/package-builder/package-level-enum';
import { ConfiguredRecommendation } from 'src/app/shared/models/package-builder/configured-recommendation';
import { ModelTableColumns } from 'src/app/shared/models/package-builder/model-table-enum';
import { Pattern } from 'src/app/shared/pattern';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { IQDecisionsService } from 'src/app/shared/services/iqdecisions.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { WarningType } from 'src/app/shared/warning-options';
import { TooltipMessages } from 'src/app/shared/tooltip-messages';
import { UtilService } from 'src/app/shared/services/util-service';
import { Component, OnInit } from '@angular/core';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { IQDecisionsEntity } from 'src/app/shared/models/iqdecisions/iqdecisions-entity';
import { RecommendationType } from 'src/app/shared/models/package-builder/recommendartionType';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';

@Component({
  selector: 'app-iqdecisions-multi-model',
  templateUrl: './iqdecisions-multi-model.component.html',
  styleUrls: ['./iqdecisions-multi-model.component.scss']
})
export class IQDecisionsNewMultiModelComponent extends BaseFormDirective implements OnInit {
  public categoryLevelsList = [];
  public choiceTypeList = [];
  public choiceTypeName = '';
  public configuredIQDecisionsList: IQDecisionsEntity[];
  public companyList = [];
  public packageTypesList = [];
  public originalModels = [];
  public configuredRecommendation;
  public modelsProperties = {};
  public modelsTableData = [];
  public isApiServiceCalled: boolean;
  public tooltipMessages = TooltipMessages;
  public messages = Messages;
  private parentId: string;
  public numberOfGroups : number;
  private level: PackageLevel;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private configuredMultiModelPackage;
  private currentStep: number = 0;
  private displayModelParameters : boolean = false;
  public reviewPage : boolean = true;
  public displayGroupValidationMessage  : boolean = false;
  public primaryModels = [];
  public fallbackModels = [];
  private groupNameIncrement : number = 1;
  private publishClicked: boolean = false;
  private displayEditForm: boolean = true;
  private configuredProgramId : string;
  public saveForLater: boolean = false;
  public modelParametersForm: FormGroup = this.fb.group({
    itemsChoiceTypeId: new UntypedFormControl('', Validators.required),
    itemsChoiceTypeName: new UntypedFormControl('', Validators.required),
    itemsPerInputLevel: new UntypedFormControl(1, { validators: [Validators.min(1), Validators.required, Validators.pattern(Pattern.INTEGER)] })
  });

  constructor(
    public authorizationService: AuthorizationService,
    private fb: UntypedFormBuilder,
    private eventSourceService: EventSourceService,
    private parentContext: ParentContextService,
    private iqDecisionsService: IQDecisionsService,
    private router: Router,
    private popupService: PopupMessageService
  ) {
    super();
  }

  ngOnInit() : void {
    if (!environment.showIQDecisions) {
      void this.router.navigate(['programs']);
    }
    this.parentId = this.parentContext.getParentContext();
    this.level = PackageLevel.PACKAGE_INFO;
    this.buildForm();
    this.doGetCompanyDetails();
    this.getAllConfiguredIQDecisions();
  }

  public onStepChange(stepChangeEvent: StepChangeEvent): void {
    this.currentStep = stepChangeEvent.index;
    this.setLevels(stepChangeEvent.index);
    this.configuredEntityForm.controls.packageName.setValue(this.configuredEntityForm.controls.packageName.value.trim());
    this.configuredRecommendation = this.configuredMultiModelPackage = new ConfiguredRecommendation({
      ...this.configuredEntityForm.value,
      parentId: this.parentId,
      level: this.level,
      itemsChoiceType: { id: this.modelParametersForm.controls.itemsChoiceTypeId.value,
        name: this.modelParametersForm.controls.itemsChoiceTypeName.value },
      itemsPerInputLevel: this.configuredEntityForm.controls.modelParametersForm?.value.itemsPerInputLevel
    });
    this.configuredRecommendation.type = PackageType.MULTI;
    this.initModelsTableData();
    this.initModelsDataTableConfig();
  }

  public currentPageValidations() : boolean {
    if(this.level === PackageLevel.MODELS) {
      if(this.hasDuplicateGroupNames()) {
        this.popupService.showErrorMessage(Messages.uniqueGroupNameMessage);
        return false;
      } else if(this.getShares() !== 100) {
        this.popupService.showErrorMessage(Messages.sharePercentageMessage);
        return false;
      } else if(!this.isPrimaryModelsSelected()) {
        this.displayGroupValidationMessage = true;
        this.popupService.showErrorMessage(Messages.primaryModelValidationMessage);
        return false;
      }
    }
    return true;
  }

  private isPrimaryModelsSelected() : boolean {
    let numberOfModelSelected = 0;
    let groupInfosArray = this.configuredEntityForm.get("groupInfos") as FormArray;
    for (let i = 0; i< groupInfosArray.controls.length; i++) {
      groupInfosArray.controls[i].value['primaryModel'] !== null ? numberOfModelSelected++ : numberOfModelSelected;
    }
    return numberOfModelSelected === groupInfosArray.length;
  }

  public disablePageShift() {
  }

  public onNextStepChange(stepChangeEvent: StepChangeEvent): void {
    this.setLevels(stepChangeEvent.index);
  }

  public onPreviousStepChange(stepChangeEvent: StepChangeEvent): void {
  }

  public getCurrentStep(): number {
    return this.currentStep;
  }

  private getAllConfiguredIQDecisions() : void {
    this.iqDecisionsService.getAllConfiguredIQDecisionsRequest(this.parentId)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ConfiguredRecommendation[]) => {
        this.configuredIQDecisionsList =  serviceResponse['result'];
      }, () => {
      });
  }

  public packageNameValidations(): void {
    if(this.configuredEntityForm.controls.packageName.value.trim() === '') {
      this.configuredEntityForm.controls.packageName.setErrors({ 'required': true });
    }
    const isAleradyTaken = this.configuredIQDecisionsList?.find(packageName =>
      packageName.name.toLowerCase() === (<string> this.configuredEntityForm.controls.packageName.value).toLowerCase());
    if (isAleradyTaken !== undefined) {
      this.configuredEntityForm.controls.packageName.setErrors({ 'alreadyExist': true });
    }
  }

  public saveChangesForLater(): void {
    this.modelParametersForm.markAllAsTouched();
    this.saveForLater = true;
    if(this.hasDuplicateGroupNames()) {
      this.popupService.showErrorMessage(Messages.uniqueGroupNameMessage);
    } else if(this.getShares() > 100) {
      this.popupService.showErrorMessage(Messages.sharePercentageMessage);
    } else if(!this.isPrimaryModelsSelected() && this.level === PackageLevel.MODELS) {
      this.displayGroupValidationMessage = true;
      this.popupService.showErrorMessage(Messages.primaryModelValidationMessage);
    } else {
      this.configuredMultiModelPackage = new ConfiguredRecommendation({
        ...this.configuredEntityForm.value, packageName: this.configuredEntityForm.controls.packageName.value.trim(),
        parentId: this.parentId,
        level: this.level,
        itemsChoiceType: { id: this.modelParametersForm.controls.itemsChoiceTypeId?.value,
          name: this.modelParametersForm.controls.itemsChoiceTypeName?.value },
        choiceType: { id: this.configuredEntityForm.controls.choiceTypeId?.value,
          name: this.configuredEntityForm.controls.choiceTypeName?.value },
        itemsPerInputLevel: this.configuredEntityForm.controls.modelParametersForm?.value.itemsPerInputLevel,
        description: this.configuredEntityForm.controls.description.value
      });
      this.addMultiModelPackage(this.configuredMultiModelPackage);
    }
  }

  public doGetPackagesTypes(company: string): void {
    const { id } = company ? JSON.parse(company) : '';
    if (id) {
      this.iqDecisionsService
        .getPackagedTypes(id, this.parentId).subscribe((res) => {
          this.packageTypesList = JSON.parse(UtilService.unescapedJsonString(res["result"].template.metadataJson)).recommendationTypes;
          this.categoryLevelsList = JSON.parse(UtilService.unescapedJsonString(res["result"].template.metadataJson)).choiceTypes;
          this.categoryLevelsList.forEach(val => {
            this.choiceTypeList.push({ id: val.id, name: val.name });
          });
          this.primaryModels = this.fallbackModels = this.originalModels = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).models.map(m => {
            return {
              ...m,
              range: m.trainingStartDate === undefined ? '---' : m.trainingStartDate + '- ' + m.trainingEndDate === undefined ? '---' : m.trainingEndDate
            };
          });
        });
    }
  }

  private setLevels(index: number) : void {
    if (index === 0) {
      this.level = PackageLevel.PACKAGE_INFO;
    } else if (index === 1) {
      this.level = PackageLevel.MODELS;
    } else if (index === 2) {
      this.level = PackageLevel.REVIEW_PUBLISH;
    }
  }

  public selectOptionValue(id: string, name: string): { id: string; name: string } {
    return {
      id,
      name,
    };
  }

  public get packageName(): AbstractControl {
    return this.configuredEntityForm.get('packageName');
  }

  public get maxItemReturned(): AbstractControl {
    return this.configuredEntityForm.get('maxItemReturned');
  }

  public get itemsPerInputLevel(): AbstractControl {
    return this.configuredEntityForm.get('itemsPerInputLevel');
  }

  public showCategoryLevel(): boolean {
    const name = this.configuredEntityForm.get('recommendationType').value;
    if (name) {
      return JSON.parse(name).id === RecommendationType.PRODUCT_CATALOG;
    }
  }

  public cancelButtonClicked(): void {
    void this.router.navigate(["/iqdecisions"]);
  }

  public handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
    }
  }

  public onItemChange(event: string): void {
    this.choiceTypeList.forEach(val => {
      if (event === val.name) {
        this.choiceTypeName = val.name;
        this.configuredEntityForm.patchValue({
          choiceTypeId: val.id,
          choiceTypeName: val.name
        });
      }
    });
  }

  public addPackageLevelInput(name: string): void {
    const { id } = name ? JSON.parse(name) : '';
    if (id === 'product_catalog') {
      this.configuredEntityForm.addControl('choiceTypeId', new UntypedFormControl('', Validators.required));
      this.configuredEntityForm.addControl('choiceTypeName', new UntypedFormControl('', Validators.required));
    } else {
      this.configuredEntityForm.removeControl('choiceTypeId');
      this.configuredEntityForm.removeControl('choiceTypeName');
    }
    this.packageNameValidations();
  }

  public publishRecommendation(): void {
    this.publishClicked = true;
    this.isDataSaved = true;
    this.addMultiModelPackage(this.configuredMultiModelPackage);
  }

  public displayModelParametersMethod(): void {
    this.displayModelParameters = true;
    this.configuredEntityForm.addControl("modelParametersForm", this.modelParametersForm);
  }

  public getShares() : number {
    let totalShare : number = 0;
    let b = this.configuredEntityForm.get("groupInfos") as FormArray;
    for (let i = 0; i< b.controls.length; i++) {
      var y : number = +b.controls[i].value['share'];
      totalShare = totalShare+y;
    }
    return totalShare;
 }

 public hasDuplicateGroupNames() : boolean {
  let newArray = [];
  let groupInfosArray = this.configuredEntityForm.get("groupInfos") as FormArray;
  for (let i = 0; i< groupInfosArray.controls.length; i++) {
    newArray.push(groupInfosArray.controls[i].value['name']);
  }
  return new Set(newArray).size !== newArray.length;
 }

  private buildForm(): void {
    this.configuredEntityForm = this.fb.group({
      company: new UntypedFormControl('', { validators: [Validators.required] }),
      packageName: new UntypedFormControl('',
        { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC_SPACE_HYPHEN), Validators.maxLength(150)] }),
      recommendationType: new UntypedFormControl('', { validators: Validators.required }),
      maxItemReturned: new UntypedFormControl(1, { validators: [Validators.required, Validators.min(1), Validators.max(10000), Validators.pattern(Pattern.INTEGER)] }),
      description: new UntypedFormControl('', {
        validators: [Validators.maxLength(500), Validators.pattern(Pattern.ALPHA_NUMERIC_SPECIAL_CHAR)]
      }),
      groupInfos: this.fb.array([])
    });
  }

  private doGetCompanyDetails(): void {
    this.isApiServiceCalled = true;
    this.eventSourceService.getCompanies(this.parentId, '').subscribe(
      res => {
        this.isApiServiceCalled = false;
        this.companyList = res['result'].find(e => e.eventSource === 'Web')['settings'];
      },
      (error: any) => {
        console.log(error);
      });
  }

  private addMultiModelPackage(configuredRecommendation : ConfiguredRecommendation): void {
    this.isApiServiceCalled = true;
    configuredRecommendation.type = PackageType.MULTI;
    this.iqDecisionsService
      .addIQDecisions(configuredRecommendation, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(serviceResponse => {
        this.isDataSaved = true;
        this.configuredProgramId = serviceResponse['result'].id;
        this.isApiServiceCalled = false;
        this.isSaveClicked = false;
        if(this.publishClicked) {
          this.iqDecisionsService.publishIQDecisions(this.configuredProgramId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.popupService.showSuccessMessage(this.configuredRecommendation.name + ' successfully published', true);
            void this.router.navigate(['/iqdecisions']);
          }, () => {
            this.popupService.showErrorMessage(Messages.errorPublishingRecommendation);
          });
        }
        else {
          this.popupService.showSuccessMessage('Multi model package "' + configuredRecommendation.name + '" successfully added', true);
          void this.router.navigate(['/iqdecisions']);
        }
      }, error => {
        this.isDataSaved = false;
        this.isSaveClicked = false;
        this.isApiServiceCalled = false;
        if(error.error.statusMessage === "REQUIRED") {
          this.popupService.showErrorMessage(Messages.requiredFields);
        } else {
          this.popupService.showErrorMessage(error.error.statusMessage);
        }
      });
  }

  private initModelsDataTableConfig(): void {
    this.modelsProperties = {
      hidePagination: true,
      rowId: 'id',
      showBadgesComponent: true,
      columns: [
        {
          headerText: ModelTableColumns.GroupName,
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: ModelTableColumns.Share,
          key: 'share',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: ModelTableColumns.PrimaryModel,
          key: 'primaryModel',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: ModelTableColumns.FallbackModel,
          key: 'fallbackModel',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private initModelsTableData(): void {
    this.modelsTableData = [];
    this.configuredRecommendation?.metadataConfiguration.groupInfos?.forEach(group => {
      const tableItem = {};
      tableItem['name'] = group.name;
      tableItem['share'] = group.share+'%';
      tableItem['primaryModel'] = group.primaryModel?.name == null ? '--' : group.primaryModel?.name;
      tableItem['fallbackModel'] = group.fallbackModel?.name == null ? '--' : group.fallbackModel?.name;
      this.modelsTableData.push(tableItem);
    });
  }

}
