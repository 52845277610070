import { Condition } from './conditions/condition';
import { EventKey } from '../eventKey.model';
import { ActionElement } from './actions/action-element';
import { EntityStateSchema } from '../entity-states/entity-state-schema';
import { RuleThenClause } from './then-clause/rule-then-clause';
import { RuleWhenClause } from './when-clause';
import { RuleTypes } from '../../constants';

export class CustomRule {

  id: string;
  name: string;
  companyId: string;
  eventKey: EventKey;
  packageName: string;
  condition: Condition;
  actions: ActionElement[];
  whenClause: RuleWhenClause;
  thenClause: RuleThenClause;
  entitySchemas: EntityStateSchema[];
  type: RuleTypes;
  salience: string;
  salientRules: string[];
  templateName: string;

}