import { ConfiguredProgram } from './configured-program';
import { PackagedProgramRule } from './packaged-program-rule';
import { PackagedRuleSettings } from './program';

export class ProgramData {

  parentId: string;
  programId: string;
  eventSource: string;
  eventType: string;
  operation: string;
  tenantProgram: ConfiguredProgram;
  rules: PackagedProgramRule[] = [];
  apiCallCount: any;
  actionDestinations: any[] = [];
  packagedRuleSettings: PackagedRuleSettings[];
  isUnsupportedProgram: boolean;

  isNewProgram(): boolean {
    return this.operation !== null && this.operation === 'add';
  }

}