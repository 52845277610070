import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfiguredEvent, ConfiguredEventId } from '../models/configured-event.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  host = environment.host;
  base = environment.base;
  configuredEventUrl = 'event/configured';
  packagedEventUrl = 'packaged-event';

  constructor(private http: HttpClient) { }

  getConfiguredEvents(parentId: string) {
    const url = this.host + this.base + this.configuredEventUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };

    return this.http.get(url, options);
  }

  getConfiguredEvent(parentId: string, configuredEventId: ConfiguredEventId) {
    const url = this.host + this.base + this.configuredEventUrl + '/' + configuredEventId.eventSourceName + '/' + configuredEventId.eventName;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  getPackagedEvent(parentId: string, configuredEventId: ConfiguredEventId) {
    const url = this.host + this.base + this.packagedEventUrl + '/' + configuredEventId.eventSourceName + '/' + configuredEventId.eventName;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };

    return this.http.get(url, options);
  }

  deleteConfiguredEvent(parentId: string, configuredEventId: ConfiguredEventId) {
    const url = this.host + this.base + this.configuredEventUrl + '/' + configuredEventId.eventSourceName + '/' + configuredEventId.eventName;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };

    return this.http.delete(url, options);
  }

  addConfiguredEvent(parentId: string, configuredEvent: ConfiguredEvent) {
    const url = this.host + this.base + this.configuredEventUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };

    return this.http.post(url, configuredEvent, options);
  }

  updateConfiguredEvent(parentId: string, configuredEventId: ConfiguredEventId, configuredEvent: ConfiguredEvent) {
    const url = this.host + this.base + this.configuredEventUrl + '/' + configuredEventId.eventSourceName + '/' + configuredEventId.eventName;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers: headers
    };

    return this.http.put(url, configuredEvent, options);
  }

}
