import { InjectionToken } from '@angular/core';
import { User } from './models/user-roles/user';
import { Role } from './models/permission/role/role';
import { UserGroupDetails } from './models/user-groups/user-group-details';
import { RolesService } from './services/roles-service-interface';
import { UsersService } from './services/users-service-interface';
import { UserGroupService } from './services/user-group-service-interface';

export const USER_CLASS_TOKEN = new InjectionToken<User>('User');
export const USER_GROUP_CLASS_TOKEN = new InjectionToken<UserGroupDetails>('UserGroupDetails');
export const ROLE_MODEL = new InjectionToken<Role>('Role');
export const ROLES_SERVICE_TOKEN = new InjectionToken<RolesService>('RolesService');
export const USERS_SERVICE_TOKEN = new InjectionToken<UsersService>('RolesService');
export const USER_GROUP_SERVICE_TOKEN = new InjectionToken<UserGroupService>('ConfiguredUserGroupService');

export {};