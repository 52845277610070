/* This file can be replaced during build by using the `fileReplacements` array.
   `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
   The list of file replacements can be found in `angular.json`. */

export const environment = {
  production: false,
//   host: 'https://vvixqckt39.execute-api.us-east-1.amazonaws.com/',
//   base: 'dev/v1/',
//   userPoolId: 'us-east-1_ScrjvBdNc',
  host: 'https://p0wu37gci9.execute-api.us-east-2.amazonaws.com/',
  base: 'dev/v1/',
  userPoolId: 'us-east-2_vHaIUsnfo',
  clientId: '2dhf9be5d51osg7m55q8pkg1as',
  trackingId: 'UA-159852838-1',
  version: 'dev',
  documentationLink: 'https://support.epsilon.com/productsupport?id=kb_view&kb_id=3eeee183db5bff402068d6fa4b9619f9&t=kb_agility_events',
  tableauRedirectPath: 'https://tp.reporting.peoplecloud.epsilon.com/#/site/Events/views/Events/Events',
  performanceInsightsLink: 'https://tp.reporting.peoplecloud.epsilon.com/#/site/Events/views/Events/Events',
  oktaIssuerUrl: 'https://datahub-poc.okta.com/oauth2/default',
  oktaVanityUrl: 'https://epsilonclient.oktapreview.com/oauth2/default',
  oktaClientId: '0oa1lz2undTn4lFF24x7',
  enableAuthorization: true,
  showIQDecisions: true
};

/* For easier debugging in development mode, you can import the following file
   to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.

   This import should be commented out in production mode because it will have a negative impact
   on performance if an error is thrown. */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
