<coreui-modal #basicModal>
    <ng-container *ngIf="modalViewInput !== undefined">
        <ng-template #titleTemplate>
            <h2 class="text-style-1 mb-0">{{ title }}</h2>
        </ng-template>
        <ng-template #titleCloseButtonTemplate>
            <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
        </ng-template>
        <ng-template #contentTemplate>
            <ng-container *ngIf="isShowPlainTextInfoModal()">
                <textarea
                class="form-control"
                #contentTextarea
                readonly>{{ plainTextContent }}</textarea>
            </ng-container>
            <ng-container *ngIf="isTableDataInfoModal()">
                <div>
                    <app-ae-table [isDataLoading]="isTableDataLoading" [properties]="properties"
                    [configuredTableData]="data" class="modal-content horizontal-scrollable"></app-ae-table>
                </div>
            </ng-container>
            <ng-container *ngIf="isIconViewModal()">
            <div class="row horizontal-scrollable">
                <ng-container *ngFor="let item of displayItems">
                    <div class="col-md-3 mb-4">
                        <app-selector-icon (click)="select(item)" [selected]=" !isDisabled(item) && itemSelected === item ? 'card-selected' : ''" [name]="item.Name"
                        [id]="item.Id" [class.card-disabled]="isDisabled(item)" [imageStyle]="isDisabled(item) ? 'opacity-50' : 'opacity-400'"></app-selector-icon>
                    </div>
                </ng-container>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #buttonsTemplate>
            <ng-container *ngIf="!isIconViewModal()">
                <app-ae-button (click)="closeBasicModal()" buttonText="Close"></app-ae-button>
            </ng-container>
            <ng-container *ngIf="isIconViewModal()">
                <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
                <app-ae-button (click)="add()" [disabled]="itemSelected == null" buttonText="Add"></app-ae-button>
            </ng-container>
        </ng-template>
    </ng-container>
</coreui-modal>