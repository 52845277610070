import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ribbon',
  templateUrl: './ribbon.component.html'
})
export class RibbonComponent implements OnInit {

  @Input()
  ribbonStyle: string;

  @Input()
  ribbonMessage: string;

  @Input()
  ribbonHasButton: boolean;

  @Input()
  buttonText: string;

  @Output()
  buttonClick = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitClick($event) {
    this.buttonClick.emit($event);
  }

}
