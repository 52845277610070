<app-base-template>
  <app-coreui-modal-warning></app-coreui-modal-warning>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-auto p-0">
        <bp-collapsible-panel>
          <bp-nav-secondary panel-content [items]="entityStateNavTabs"></bp-nav-secondary>
        </bp-collapsible-panel>
      </div>
      <div class="col px-0">
        <div class="object-page-header pt-4 mb-4">
          <app-entity-states-header></app-entity-states-header>
        </div>
        <app-ae-loading-indicator [isLoading]="isPageLoading" [type]="'loading bar'"></app-ae-loading-indicator>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</app-base-template>
