<!-- TODO: replace native select with ng-select -->
<app-base-template>
  <app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
  <app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
  <app-ae-modal-info></app-ae-modal-info>
  <form *ngIf="!isApiServiceCalled" [formGroup]="configuredEntityForm">
    <div class="pt-4 pl-5 pr-4">
      <div class="row mb-2">
        <div class="col">
          <span
            class="text-style-action"
            buttonType="text-primary"
            icon="chevronLeft"
            iconPosition="left"
            routerLink="/events"
          >&lt; Back to Events</span>
        </div>
      </div>
      <div class="row ">
        <div class="col">
          <div class="form-group mb-0">
            <coreui-form-field for="eventNameControl">
              <div class="Core-FormField-label is-inlineLabel">
                <h1 class="pr-4">
                  Event Name
                </h1>
                <input
                  coreUIInput
                  type="text"
                  id="eventNameControl"
                  class="form-control object-page-header-input"
                  placeholder="Add a unique name for the Event"
                  formControlName="eventName"
                  (keyup)="checkIfEventNameAlreadyExists()"
                />
              </div>
              <coreui-form-error *ngIf="eventName.invalid && isSaveClicked" class="text-color-warning">
                <ng-container *ngIf="eventName.errors.required || eventName.errors.pattern">
                  {{messages.eventNameValidationMessage}}
                </ng-container>
                <ng-container *ngIf="eventName.errors.alreadyExist">
                  {{messages.eventNameDuplicateValidationMessage}}
                </ng-container>
                <ng-container *ngIf="eventName.errors?.maxlength">
                  Event {{messages.nameLimitMessage}}
                </ng-container>
              </coreui-form-error>
            </coreui-form-field>
          </div>
        </div>
        <div class="col"></div>
        <div class="row" *ngIf="inputParams.action === 'edit'">
          <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_EVENT', ['DELETE'])">
            <app-ae-button buttonText="Delete" (click)="showDeleteConfiguredEventAlert()" [disabled]="hasAssociations()">
            </app-ae-button>
          </div>
          <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_EVENT', ['CREATE','UPDATE'])">
            <app-ae-button class="mr-3" buttonText="Copy" (click)="copyConfiguredEvent()"></app-ae-button>
          </div>
        </div>
        <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_EVENT', ['CREATE','UPDATE'])">
          <app-ae-button buttonText="Save" (click)="saveConfiguredEvent()" [disabled]="hasAssociations()"></app-ae-button>
        </div>
      </div>
    </div>
    <div class="container-fluid pt-3 pl-5">
      <app-ribbon *ngIf="hasOperationFailed()"
        ribbonStyle="ribbon-alert"
        [ribbonMessage]="getOperationFailureMessage()"
        ribbonHasButton="true"
        [buttonText]="getAssociationsBtnTxt()"
        (buttonClick)="openAssociationsModal()">
      </app-ribbon>
      <div class="row">
        <div class="col-4">
          <coreui-form-field label="Event Source Provider" for="eventSourceProvider">
            <coreui-select id="eventSourceProvider" formControlName="eventSourceProvider"
              placeholder="Select Event Source Provider">
              <coreui-option [value]="" [disabled]="true">Select Event Source Provider</coreui-option>
              <coreui-option *ngFor="let item of this.configuredEventSources;" [value]="item.eventSource">{{item.eventSource}}
              </coreui-option>
            </coreui-select>
            <coreui-form-error *ngIf="eventSourceProvider.invalid && isSaveClicked">
              <div *ngIf="eventSourceProvider.errors.required"> {{messages.selectEventSource}}
              </div>
            </coreui-form-error>
          </coreui-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <coreui-form-field label="Description (optional)" for="eventDesc">
            <textarea coreUIInput id="eventDesc" formControlName="eventDesc" placeholder="Add description"
              rows="6">
                      </textarea>
            <coreui-form-error *ngIf="eventDesc.invalid && isSaveClicked">
              {{messages.descriptionLimitMessage}}
            </coreui-form-error>
          </coreui-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-9">
          <coreui-form-field label="Event Schema (we currently only support JSON schema draft-07)" for="eventSchema">
            <textarea coreUIInput id="eventSchema" formControlName="eventSchema" placeholder="Add the schema for this event"
              rows="7" (blur)="validateSchema()">
                      </textarea>
            <coreui-form-error *ngIf="eventSchema.invalid && isSaveClicked">
              <div *ngIf="eventSchema.errors.required">
                {{messages.eventSchemaValiadtionMessage}}
              </div>
              <div *ngIf="eventSchema.errors.invalidJsonSchema">
               {{messages.eventSchemaJSONValidationMessage}}
              </div>
            </coreui-form-error>
          </coreui-form-field>
        </div>
      </div>
    </div>
  </form>
</app-base-template>
