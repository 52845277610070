import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormArray, AbstractControl, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/shared/constants';
import { Messages } from 'src/app/shared/message';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { IQDecisionsEntity } from 'src/app/shared/models/iqdecisions/iqdecisions-entity';
import { ShowComponentInTableEntity } from 'src/app/shared/models/iqdecisions/showComponentInTableEntity';
import { ConfiguredRecommendation } from 'src/app/shared/models/package-builder/configured-recommendation';
import { GroupInfoTableColumns } from 'src/app/shared/models/package-builder/groupInfo-table-enum';
import { ModelTableColumns } from 'src/app/shared/models/package-builder/model-table-enum';
import { ModelType } from 'src/app/shared/models/package-builder/modelType';
import { PackageStatus } from 'src/app/shared/models/package-builder/package-status-enum';
import { PackageType } from 'src/app/shared/models/package-builder/package-type-enum';
import { Pattern } from 'src/app/shared/pattern';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { IQDecisionsService } from 'src/app/shared/services/iqdecisions.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { UtilService } from 'src/app/shared/services/util-service';
import { WarningType } from 'src/app/shared/warning-options';

@Component({
  selector: 'app-iqdecisions-view-single-model',
  templateUrl: './iqdecisions-view-single-model.component.html'
})
export class IQDecisionsViewSingleModelComponent extends BaseFormDirective {

  public messages = Messages;
  public packageType = PackageType;
  public packageStatus = PackageStatus;
  public isApiServiceCalled: boolean;
  public configuredRecommendation;
  public programUsageProperties = {};
  public configuredIQDecisionsList: IQDecisionsEntity[];
  public programUsageTableData = [];
  public modelsProperties = {};
  public modelsTableData = [];
  public configuredRecommendationStatus = [];
  public packageTypesList: any;
  public categoryLevelsList = [];
  public choiceTypeList = [];
  public models: any;
  public displayEditForm = false;
  public displayGroupValidationMessage = false;
  public groupInfoNameMaxLength = 75;
  public groupInfoShareMinValue = 1;
  public groupInfoShareMaxValue = 100;
  public descriptionMaxLength = 500;
  public maxItemReturnedMinValue = 1;
  public itemsPerInputLevelMinValue = 1;
  public publishUnpublishButtonText: string = Messages.publishBtnText;

  private configuredRecommendationId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private parentId: any;

  constructor(
    public authorizationService: AuthorizationService,
    private iqDecisionsService: IQDecisionsService,
    private route: ActivatedRoute,
    private parentContext: ParentContextService,
    private popupService: PopupMessageService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {
    super();
    this.parentId = parentContext.getParentContext();
    this.initProgramUsageDataTableConfig();
    this.initModelsDataTableConfig();
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.configuredRecommendationId = route.snapshot.paramMap.get('id');
      this.isApiServiceCalled = true;
      this.iqDecisionsService
        .getIQDecisions(this.configuredRecommendationId, this.parentId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(response => {
          this.configuredRecommendation = response['result'];
          this.doGetPackagesTypes();
          this.buildForm();
          this.isApiServiceCalled = false;
          this.initProgramUsageTableData();
          this.getAllConfiguredIQDecisions();
          this.buildIQDecisionsStatus();
          this.updatePublishUnpublishButtonText();
          if (this.configuredRecommendation.type.toLowerCase() === PackageType.MULTI.toLocaleLowerCase()) {
            this.initModelsTableData();
          }
        });
    });
  }

  public updatePublishUnpublishButtonText(): void {
    if (this.configuredRecommendation.status === this.packageStatus.Published) {
      this.publishUnpublishButtonText = Messages.unpublishBtnText;
    }
  }

  public publishUnpublishIQDecisions(): void {
    if (this.configuredRecommendation.status === this.packageStatus.Unpublished) {
      this.publishIQDecisions();
    } else {
      this.unpublishIQDecisions();
    }
  }

  public disableEditButton(): boolean {
    return this.configuredRecommendation.associations.length !== 0;
  }

  public disableUnpublishedButton(): boolean {
    return !!this.configuredRecommendation.associations.length || this.configuredRecommendation.status === this.packageStatus.Incomplete
      && this.configuredRecommendation.status === this.packageStatus.Unpublished;
  }

  public disableDeleteButton(): boolean {
    return this.configuredRecommendation.status === PackageStatus.Published || this.configuredRecommendation.associations.length !== 0;;
  }

  public publishIQDecisions(): void {
    this.isApiServiceCalled = true;
    this.iqDecisionsService.publishIQDecisions(this.configuredRecommendationId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isApiServiceCalled = false;
      this.popupService.showSuccessMessage(this.configuredRecommendation.name + ' successfully published', true);
      this.cancelEdit();
      this.router.navigateByUrl(Constants.iqdecisions, { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl('iqdecisions');
      });
    }, () => {
      this.isApiServiceCalled = false;
      this.popupService.showErrorMessage(Messages.errorPublishingRecommendation);
    });
  }

  public unpublishIQDecisions(): void {
    this.isApiServiceCalled = true;
    this.iqDecisionsService.unpublishIQDecisions(this.configuredRecommendationId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isApiServiceCalled = false;
      this.popupService.showSuccessMessage(this.configuredRecommendation.name + ' successfully unpublished', true);
      this.cancelEdit();
      this.router.navigateByUrl(Constants.iqdecisions, { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl('iqdecisions/' + this.configuredRecommendation.id);
      });
    }, () => {
      this.isApiServiceCalled = false;
      this.popupService.showErrorMessage(Messages.errorUnpublishingRecommendation);
    });
  }

  public deleteIQDecisions(): void {
    this.isApiServiceCalled = true;
    this.iqDecisionsService.deleteIQDecisions(this.configuredRecommendationId, this.parentId).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isApiServiceCalled = false;
      this.popupService.showSuccessMessage(this.configuredRecommendation.name + ' successfully deleted', true);
      this.router.navigateByUrl(Constants.iqdecisions, { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(Constants.iqdecisions);
      });
    }, () => {
      this.isApiServiceCalled = false;
      this.popupService.showErrorMessage(Messages.errorDeletingRecommendation);
    });
  }

  public doGetPackagesTypes(): void {
    const id = this.configuredRecommendation.company.id;
    if (id) {
      this.iqDecisionsService.getPackagedTypes(id, this.parentId).subscribe(
        res => {
          this.packageTypesList = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).recommendationTypes;
          this.categoryLevelsList = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).choiceTypes;
          this.categoryLevelsList.forEach(val => {
            this.choiceTypeList.push({ id: val.id, name: val.name });
          });
          this.models = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).models.map(m => {
            return {
              ...m,
              range: m.trainingStartDate + '- ' + m.trainingEndDate
            };
          });
        }
      );
    }
  }

  public cancelEdit(): void {
    this.displayEditForm = false;
  }

  public enableEditForm(): void {
    this.displayEditForm = true;
  }

  public editIQDecisions(): void {
    this.warningModal.launchModal(WarningType.CHANGE_PROGRAM_MODE_WARNING, {
      title: Messages.unpublishConfiguredRecommendation,
      msg: Messages.iQDecisionsWarningMessage,
      msg2: [Messages.iQDecisionsConfirmationMessage]
    });
  }

  public saveUpdatedIQDesicion(): void {
    if (this.configuredRecommendation.status === this.packageStatus.Unpublished) {
      this.saveChanges();
    } else {
      this.editIQDecisions();
    }
  }

  public handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.deleteIQDecisions();
    } else if (decision && this.warningModal.warningType === WarningType.CHANGE_PROGRAM_MODE_WARNING) {
      this.saveChanges()
    }
  }

  public showDeleteIQDecisionsAlert(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteConfiguredRecommendation,
      msg: `This action will permanently delete this package ${this.configuredRecommendation.name} and all of its data.`,
      msg2: [Messages.deleteConfiguredRecommedationModalConfirmationMessage]
    });
  }

  public saveChanges(): void {
    this.isApiServiceCalled = true;
    const data = new ConfiguredRecommendation(
      {
        recommendationType: this.configuredRecommendation.metadataConfiguration.packageInfo.recommendationType,
        groupInfos: this.configuredEntityForm.get(Constants.groupInfos).value,
        type: this.configuredRecommendation.type,
        ...this.configuredRecommendation,
        ...this.configuredEntityForm.get(Constants.modelsForm).value,
        parentId: this.parentId,
        choiceType: {
          id: this.configuredEntityForm.controls.choiceTypeId.value,
          name: this.configuredEntityForm.controls.choiceTypeName.value
        },
        description: this.configuredEntityForm.controls.description.value,
        maxItemReturned: this.configuredEntityForm.controls.maxItemReturned.value,
        packageName: this.configuredEntityForm.controls.packageName.value,
        itemsChoiceType: { id: this.configuredEntityForm.controls.itemsChoiceTypeId.value,
          name: this.configuredEntityForm.controls.itemsChoiceTypeName.value },
        itemsPerInputLevel: this.configuredEntityForm.controls.itemsPerInputLevel.value
      });
    this.configuredRecommendation.type === PackageType.MULTI ? data.type = PackageType.MULTI : data.type = PackageType.SINGLE;
    if (this.configuredRecommendation.type === PackageType.SINGLE
      || (this.configuredRecommendation.type === PackageType.MULTI && this.multiModelPageValidations())) {
      this.iqDecisionsService.updateIQDecisions(this.configuredRecommendation.id, data, this.parentId).subscribe(() => {
        this.isApiServiceCalled = false;
        this.popupService.showSuccessMessage(this.configuredEntityForm.controls.packageName.value + ' successfully updated', true);
        this.cancelEdit();
        void this.router.navigateByUrl(Constants.iqdecisions, { skipLocationChange: true }).then(() => {
          void this.router.navigateByUrl('iqdecisions/' + this.configuredRecommendation.id);
        });
      }, () => {
        this.isApiServiceCalled = false;
      });
    } else {
      this.isApiServiceCalled = false;
    }
  }

  public getFormGroupInfosOrModels(): AbstractControl {
    if (this.configuredEntityForm) {
      return this.configuredRecommendation.type === PackageType.MULTI ?
       this.configuredEntityForm.get('groupInfos') : this.configuredEntityForm.get('modelsForm');
    }
  }

  private disableSaveButton(): boolean {
    return (this.configuredRecommendation.type === PackageType.MULTI && this.configuredEntityForm.get(Constants.groupInfos).invalid)
      || (this.configuredRecommendation.type === PackageType.SINGLE && this.configuredEntityForm.get(Constants.modelsForm).invalid)
      || (this.configuredEntityForm.controls.choiceTypeName.invalid || this.configuredEntityForm.controls.itemsPerInputLevel.invalid
        || this.configuredEntityForm.controls.maxItemReturned.invalid || this.configuredEntityForm.controls.maxItemReturned.invalid
        || this.configuredEntityForm.controls.itemsChoiceTypeName.invalid);
  }

  private multiModelPageValidations(): boolean {
    if (this.hasDuplicateGroupNames()) {
      this.popupService.showErrorMessage(Messages.uniqueGroupNameMessage);
      return false;
    } else if (this.getTotalShare() !== 100) {
      this.popupService.showErrorMessage(Messages.sharePercentageMessage);
      return false;
    } else if (!this.isPrimaryModelsSelected()) {
      this.displayGroupValidationMessage = true;
      this.popupService.showErrorMessage(Messages.primaryModelValidationMessage);
      return false;
    }
    return true;
  }

  private isPrimaryModelsSelected(): boolean {
    let numberOfModelSelected = 0;
    const groupInfosArray = this.configuredEntityForm.get(Constants.groupInfos) as FormArray;
    for (let i = 0; i < groupInfosArray.controls.length; i++) {
      groupInfosArray.controls[i].value[ModelType.PRIMARY_MODEL] !== null ? numberOfModelSelected++ : numberOfModelSelected;
    }
    return numberOfModelSelected === groupInfosArray.length;
  }

  private getTotalShare(): number {
    let totalShare = 0;
    const newArray = this.configuredEntityForm.get(Constants.groupInfos) as FormArray;
    for (let i = 0; i < newArray.controls.length; i++) {
      const y: number = +newArray.controls[i].value['share'];
      totalShare = totalShare + y;
    }
    return totalShare;
  }

  private hasDuplicateGroupNames(): boolean {
    const newArray = [];
    const groupInfosArray = this.configuredEntityForm.get(Constants.groupInfos) as FormArray;
    for (let i = 0; i < groupInfosArray.controls.length; i++) {
      newArray.push(groupInfosArray.controls[i].value['name']);
    }
    return new Set(newArray).size !== newArray.length;
  }

  private getAllConfiguredIQDecisions(): void {
    this.iqDecisionsService.getAllConfiguredIQDecisionsRequest(this.parentId)
      .pipe(takeUntil(this.destroyed$)).subscribe((serviceResponse: ConfiguredRecommendation[]) => {
        this.configuredIQDecisionsList = serviceResponse['result'];
      }, () => {
      });
  }

  public packageNameValidations(): void {
    const isAleradyTaken = this.configuredIQDecisionsList.find(packageName =>
      packageName.name.toLowerCase() === (<string>this.configuredEntityForm.controls.packageName.value).toLowerCase());
    if (isAleradyTaken !== undefined) {
      this.configuredEntityForm.controls.packageName.setErrors({ 'alreadyExist': true });
    }
  }

  public get packageName(): AbstractControl {
    return this.configuredEntityForm.controls.packageName;
  }

  private initProgramUsageDataTableConfig(): void {
    this.programUsageProperties = {
      hidePagination: true,
      rowId: 'id',
      showBadgesComponent: true,
      columns: [
        {
          headerText: 'Program Name',
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Status',
          key: 'status',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private initProgramUsageTableData(): void {
    this.configuredRecommendation.associations.forEach(association => {
      const tableItem = {};
      tableItem['id'] = association.targetId;
      tableItem['name'] = association.targetName;
      tableItem['status'] = this.buildColumnBadgeComponentStatus(association.targetStatus, 'spot');
      this.programUsageTableData.push(tableItem);
    });
  }

  private initModelsDataTableConfig(): void {
    this.modelsProperties = {
      hidePagination: true,
      rowId: 'id',
      showBadgesComponent: true,
      columns: [
        {
          headerText: ModelTableColumns.GroupName,
          key: 'name',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: ModelTableColumns.Share,
          key: 'share',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: ModelTableColumns.PrimaryModel,
          key: 'primaryModel',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: ModelTableColumns.FallbackModel,
          key: 'fallbackModel',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'name',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }

  private initModelsTableData(): void {
    this.configuredRecommendation.metadataConfiguration?.groupInfos?.forEach(group => {
      const tableItem = {};
      tableItem[GroupInfoTableColumns.ID] = group.id;
      tableItem[GroupInfoTableColumns.NAME] = group.name;
      tableItem[GroupInfoTableColumns.SHARE] = `${group.share * 100}%`;
      tableItem[GroupInfoTableColumns.PRIMARY_MODEL] = group.primaryModel.name;
      tableItem[GroupInfoTableColumns.FALLBACK_MODEL] = group.fallbackModel?.name;
      this.modelsTableData.push(tableItem);
    });
  }

  private buildColumnBadgeComponentStatus(status: string, type: string): ShowComponentInTableEntity[] {
    const possibleCases = { 'incomplete': 'Pineapple', 'active': 'Lime', 'inactive': 'Plum' };
    const findStatus = Object.keys(possibleCases).indexOf(status.toLowerCase());
    const result = [status, findStatus >= 0 ? possibleCases[status.toLowerCase()] : possibleCases.incomplete];
    const objectData = [{ component: 'ae-badge', type: type.toLowerCase(), msg: result[0], color: result[1] }];
    return objectData;
  }

  private buildIQDecisionsStatus(): void {
    const status: string = this.configuredRecommendation.status;
    const possibleCases = { 'incomplete': 'Pineapple', 'published': 'Lime', 'not published': 'Plum' };
    const findStatus = Object.keys(possibleCases).indexOf(status.toLowerCase());
    const result = [status, findStatus >= 0 ? possibleCases[status.toLowerCase()] : possibleCases.incomplete];
    this.configuredRecommendationStatus = [{ msg: status, color: result[1], type: 'spot' }];
  }

  private buildGroupInfoFrom(): UntypedFormArray {
    const groupInfoFromArray = this.fb.array([]);
    this.configuredRecommendation.metadataConfiguration?.groupInfos?.forEach((control, index) => {
      groupInfoFromArray.push(this.fb.group({
        id: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration.groupInfos[index].id),
        name: new UntypedFormControl(
          this.configuredRecommendation.metadataConfiguration.groupInfos[index].name,
          { validators: [Validators.required, Validators.maxLength(this.groupInfoNameMaxLength)] }),
        share: new UntypedFormControl(
          this.configuredRecommendation.metadataConfiguration.groupInfos[index].share,
          { validators: [Validators.required, Validators.min(this.groupInfoShareMinValue), Validators.max(this.groupInfoShareMaxValue)] }),
        primaryModel: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration.groupInfos[index].primaryModel, Validators.required),
        fallbackModel: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration.groupInfos[index].fallbackModel)
      }));
    });
    return groupInfoFromArray;
  }

  private buildForm(): void {
    this.configuredEntityForm = this.fb.group({
      modelsForm: this.fb.group({
        primaryModel: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration?.primaryModel, Validators.required),
        fallbackModel: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration?.fallbackModel)
      }),
      groupInfos: this.buildGroupInfoFrom(),
      modelParametersForm: this.fb.group({
        itemsChoiceType: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration?.packageInfo.itemsChoiceType),
        itemsPerInputLevel: new UntypedFormControl(
          this.configuredRecommendation.metadataConfiguration?.packageInfo.itemsPerInputLevel,
          { validators: [Validators.required, Validators.min(this.itemsPerInputLevelMinValue), Validators.pattern(Pattern.INTEGER)] })
      }),
      itemsChoiceTypeId: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration?.packageInfo.itemsChoiceType?.id,
        Validators.required),
      itemsChoiceTypeName: new UntypedFormControl(this.configuredRecommendation.metadataConfiguration?.packageInfo.itemsChoiceType?.name,
        Validators.required),
      itemsPerInputLevel: new UntypedFormControl(
        this.configuredRecommendation.metadataConfiguration?.packageInfo?.itemsPerInputLevel,
        { validators: [Validators.required, Validators.min(this.itemsPerInputLevelMinValue), Validators.pattern(Pattern.INTEGER)] }),
      choiceTypeId: new UntypedFormControl(
        JSON.stringify(this.configuredRecommendation.metadataConfiguration?.packageInfo.choiceType?.id),
        { validators: Validators.required }),
      choiceTypeName: new UntypedFormControl(
        JSON.stringify(this.configuredRecommendation.metadataConfiguration?.packageInfo.choiceType?.name),
        { validators: Validators.required }),
      maxItemReturned: new UntypedFormControl(
        this.configuredRecommendation.metadataConfiguration?.packageInfo.itemsToReturn,
        { validators: [Validators.required, Validators.min(this.maxItemReturnedMinValue), Validators.pattern(Pattern.INTEGER)] }),
      description: new UntypedFormControl(this.configuredRecommendation.description, {
        validators: [Validators.maxLength(this.descriptionMaxLength), Validators.pattern(Pattern.ALPHA_NUMERIC_SPECIAL_CHAR)] }),
      packageName: new UntypedFormControl(this.configuredRecommendation.name,
        { validators: [Validators.required, Validators.pattern(Pattern.ALPHA_NUMERIC_NO_SPACE_AS_PREFIX), Validators.maxLength(150)] })
    });
  }

}
