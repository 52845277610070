import { Injectable } from "@angular/core";
@Injectable()
export class BusinessUnit {

  public businessUnitName: string;
  public businessUnitId: string;
  public clientUsername: string;
  public clientPassword: string;
  public apiClientId: string;
  public apiClientSecret: string;
  public company : string;

}