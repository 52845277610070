import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfiguredSchedule } from '../models/configured-schedule/configured-schedule.model';
import { ServiceResponse } from '../models/service-response';

@Injectable({
  providedIn: 'root'
})
export class ConfiguredScheduleService {

  host = environment.host;
  base = environment.base;
  createScheduleUrl = 'schedule/create';
  cancelScheduleUrl = 'schedule/cancel';
  getAllSchedulesUrl = 'schedule/all';

  constructor(private http: HttpClient) {
  }

  createConfiguredSchedule(parentId: string, configuredSchedule: ConfiguredSchedule): Observable<ServiceResponse> {
    const url = this.host + this.base + this.createScheduleUrl;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post<ServiceResponse>(url, configuredSchedule, options);
  }

  getAllConfiguredSchedulesByFeature(parentId: string, configuredSchedule: ConfiguredSchedule): Observable<ServiceResponse> {
    const url = this.host + this.base + this.getAllSchedulesUrl;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.post<ServiceResponse>(url, configuredSchedule, options);
  }

  cancelConfiguredSchedule(parentId: string, configuredSchedule: ConfiguredSchedule): Observable<ServiceResponse> {
    const url = this.host + this.base + this.cancelScheduleUrl;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = {
      headers: headers
    };
    return this.http.put<ServiceResponse>(url, configuredSchedule, options);
  }


}