import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserGroupConfigurationComponent } from './user-group-configuration.component';
import { Type } from '../../../../shared/models/roleType';

@Injectable({
    providedIn: 'root'
})
export class UserGroupConfigurationCanDeactivateGuard implements CanDeactivate<UserGroupConfigurationComponent> {

    constructor() {
    }

    canDeactivate(component: UserGroupConfigurationComponent): boolean | Promise<boolean> | Observable<boolean> {
        if (component.isDataSaved || component.isDeleteOperation) {
            return true;
        }
        if (component.usergroupType === Type.CLIENT.toLowerCase() &&
            (component.operation === 'createUserGroup' && this.isCreateFormUpdated(component) ||
            component.operation === 'editUserGroup' && this.isEditFormUpdated(component))) {
            return component.launchUnsavedChangesModal();
        } else {
            return true;
        }
    }

    private isEditFormUpdated(component: UserGroupConfigurationComponent): boolean {
        return component.userGroupDetails.description !== component.configuredEntityForm.controls.description.value ||
            component.userGroupDetails.active !== component.configuredEntityForm.controls.isActive.value ||
            JSON.stringify(component.userGroupDetails.roles).length !==
            JSON.stringify(component.configuredEntityForm.controls.roles.value).length;
    }

    private isCreateFormUpdated(component: UserGroupConfigurationComponent): boolean {
        return component.configuredEntityForm.controls.name.value !== '' ||
            component.configuredEntityForm.controls.description.value !== '' ||
            !component.configuredEntityForm.controls.isActive.value ||
            component.configuredEntityForm.controls.roles.value.length > 0;
    }
}