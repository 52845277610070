<app-base-template>
  <div class="container-fluid object-page-header pt-4">
    <div class="row align-items-center py-sm-3">
      <div class="col">
        <h1 class="text-style-1 mb-0">Actions</h1>
      </div>
      <div class="col text-right" *ngIf="authorizationService.isAllowed('CFG_ACTION', ['CREATE'])">
        <app-ae-button buttonType="primary" buttonText="Create New Action" routerLink="/actions/add/custom/id" [disabled]="!parentContextService.isParentContextSet()"></app-ae-button>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-5">
    <ng-container *ngIf="!parentContextService.isParentContextSet()">
      <div class="row">
        <div class="col text-center">
          <p class="text-style-muted-3 m-0">Please set up a Parent in Settings to create an action</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="configuredActionTableList">
      <div class="row">
        <div class="col col-sm-6 col-xl-3">
          <app-ae-table-search
            label="Search Actions"
            placeholder="Search by name"
            (searchEmitter)="handleSearch($event)">
          </app-ae-table-search>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-ae-table [isDataLoading]="isDataLoading" [configuredTableData]="configuredActionTableFilteredData"
            [properties]="properties">
          </app-ae-table>
        </div>
      </div>
    </ng-container>
  </div>
</app-base-template>
