<coreui-modal #basicModal>
  <ng-template #titleTemplate>
    <span class="text-style-1">Add an AWS Destination</span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
      <app-ae-button icon="close" (click)="closeBasicModal()" buttonType="icon-large"></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <form novalidate [formGroup]="awsActionDestinationForm" class="horizontal-scrollable">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="awsActionDestinationName">Action Destination Name</label>
            <input type="text" id="awsActionDestinationName" formControlName="awsActionDestinationName" class="form-control" placeholder="Enter a name for this action destination" [readonly]="editMode==true" [ngClass]="{ 'is-invalid': validateForRequired(getAwsActionDestinationNameControl()) }"/>
            <div *ngIf="validateForRequired(getAwsActionDestinationNameControl())" class="invalid-feedback">
              {{getEmptyActionDestinationNameErrorMessage()}}
            </div>
            <div class="invalid-feedback" *ngIf="saveButtonClicked && !isLoading && operationSuccess && notification && !isSuccessOrNetworkErrorNotification() && notification.type==='DUPLICATE_ITEM'" >
              {{notification.message}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="awsActionDestinationDescription">Action Destination Description</label>
            <textarea id="awsActionDestinationDescription" rows="3" formControlName="awsActionDestinationDescription" class="form-control" placeholder="Enter a description for this action destination" [readonly]="editMode==true" [ngClass]="{ 'is-invalid': validateForRequired(getAwsActionDestinationDescriptionControl()) }"></textarea>
            <div *ngIf="validateForRequired(getAwsActionDestinationDescriptionControl())" class="invalid-feedback">
              {{getEmptyActionDestinationDescriptionErrorMessage()}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h2 class="h2">User Credentials</h2>
        </div>
      </div>
      <div class="row" *ngIf="testButtonClicked && !isLoading && operationSuccess && notification && isSuccessOrNetworkErrorNotification()">
        <div class="col">
          <i class="fas fa-check-circle icon-color-success icon-left" aria-hidden="true" *ngIf="notification.type==='SUCCESS'"></i>
          <i class="fas fa-exclamation-circle icon-color-warning icon-left" aria-hidden="true" *ngIf="notification.type==='NETWORK_ERROR'" ></i>
          <span>{{notification.message}}</span>
        </div>
      </div>
      <div class="row" *ngIf="testButtonClicked && !isLoading && operationSuccess && notification && !isSuccessOrNetworkErrorNotification()">
        <div class="col">
          <p class="text-color-warning">{{notification.message}}</p>
        </div>
      </div>
      <app-aws-user-credential></app-aws-user-credential>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <app-ae-button buttonText="Test Connection" buttonType="secondary" class="mr-auto" [disabled]="!isValidForTest()" (click)="test()"></app-ae-button>
    <app-ae-button (click)="saveChanges()" [disabled]="!isValidForSave()"  buttonText="Save"></app-ae-button>
    <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
  </ng-template>
</coreui-modal>
