import { ActionType } from 'src/app/shared/models/action/action-type';

export class AttributeDelta {
  
  public name: string;
  public previousValue: {};
  public currentValue: {};

  constructor(attr?: Object) {
    if (attr) {
      Object.assign(this, JSON.parse(JSON.stringify(attr)));
    }
  }

  public buildNaturalLanguageForModifyAddAction(action: unknown): string {
		if (action['actionType'] != ActionType.API_ACTION) {
			return null;
    }
    
    let deltaValue = action['actionInputs'] == undefined ? action['actions'][0] : action;
    if (deltaValue['actionSettingsInput']['serviceInput']['templateId']) {
      return 'send ' + deltaValue['actionInputs'][1] + ' notification using template  ' 
          + deltaValue['actionSettingsInput']['serviceInput']['templateId']
          + ' with ' + this.getActionDestination(deltaValue['actionInputs'][0]) + ' is created.';
    }

    if (deltaValue['actionSettingsInput']['serviceInput']['allocations']) {
      return 'send ' + deltaValue['actionInputs'][1] + ' notification using multiple templates' 
          + ' with ' + this.getActionDestination(deltaValue['actionInputs'][0]) + ' is created.';
    }

    return 'send ' + deltaValue['actionInputs'][1] + ' with ' 
          + this.getActionDestination(deltaValue['actionInputs'][0]) + ' is created.';    
  }
  
  private getActionDestination(actionDestination: string): string {
		return actionDestination === 'HARMONY' ? 'PCM' : actionDestination;
  }
  
  public buildFromTo(id?: string): any {
    let fromTo = {
      from: this.getAttributeDeltaValue(this.previousValue),
      to: this.getAttributeDeltaValue(this.currentValue)
    };
    if (this.name) {
      const uniqueName = id != undefined ? id + " " + this.name : this.name;
      fromTo["name"] = uniqueName;
    }
    return fromTo;
  }

  private getAttributeDeltaValue(deltaValue: any): any {
    if (typeof deltaValue == "object") {
      if (JSON.stringify(deltaValue).includes("allocationEntities")) {
        return Object.assign({}, ...deltaValue.map(({ allocationEntities, percentage }) => 
          ({[allocationEntities[0]["value"]]: "( " + percentage + "% )"})));
      } 

      if (JSON.stringify(deltaValue).includes("companyName")) {
        return deltaValue.companyName; 
      }
    } else {
      return deltaValue.toString();
    }
  }

  public buildNaturalLanguageForModifyRemoveAction(action: unknown): string {
		if (action == undefined) {
			return 'Action is removed from the program';
		}

		let deltaValue = action['actionInputs'] == undefined ? action['actions'][0] : action;
		if (deltaValue['actionSettingsInput']['serviceInput']['templateId']) {
			return deltaValue['actionInputs'][1] + ' action using template  ' 
				+ deltaValue['actionSettingsInput']['serviceInput']['templateId'] + ' with ' 
				+ this.getActionDestination(deltaValue['actionInputs'][0]) + ' is removed.';
    }
    
    if (deltaValue['actionSettingsInput']['serviceInput']['allocations']) {
			return deltaValue['actionInputs'][1] + ' action using multiple templates with ' 
				+ this.getActionDestination(deltaValue['actionInputs'][0]) + ' is removed.';
    }

		return deltaValue['actionInputs'][1] + ' with ' 
			+ this.getActionDestination(deltaValue['actionInputs'][0]) + ' is removed.';	
	}
} 