import { SupportedEventSources } from './supported-event-sources';
import { EventSource } from '../event-source/event-source';
import { EventType } from '../event-type';

export class EventSourceBuilder {

  public static build(eventSource: SupportedEventSources): EventSource {
    if (eventSource === SupportedEventSources.CONVERSANT) {
      return EventSourceBuilder.buildConversant();
    } else if (eventSource === SupportedEventSources.PROACTIVE) {
      return EventSourceBuilder.buildProactive();
    } else if (eventSource === SupportedEventSources.FACEBOOK) {
      return EventSourceBuilder.buildFacebook();
    } else {
      return EventSourceBuilder.buildOther();
    }
  }

  private static buildConversant(): EventSource {
    const eventTypes: EventType[] = [];
    const conversant: EventSource = new EventSource(SupportedEventSources.CONVERSANT, eventTypes);
    return conversant;
  }

  private static buildProactive(): EventSource {
    const eventTypes: EventType[] = [];
    const proactive: EventSource = new EventSource(SupportedEventSources.PROACTIVE, eventTypes);
    return proactive;
  }

  private static buildFacebook(): EventSource {
    const eventTypes: EventType[] = [];
    const facebook: EventSource = new EventSource(SupportedEventSources.FACEBOOK, eventTypes);
    return facebook;
  }

  private static buildOther(): EventSource {
    const eventTypes: EventType[] = [];
    const other: EventSource = new EventSource(SupportedEventSources.OTHER, eventTypes);
    return other;
  }

}
