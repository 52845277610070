export enum Pattern {
  ALPHA_NUMERIC = '^[A-Za-z0-9]+$',
  ALPHA_NUMERIC_WITH_SPACE = '^[A-Za-z0-9 ]+$',
  ALPHA_NUMERIC_SPACE_HYPHEN = '^[A-Za-z0-9-_ ]+$',
  ALPHA_NUMERIC_HYPHEN_UNDERSCORE = '^[A-Za-z0-9-_]+$',
  ALPHA_NUMERIC_HYPHEN_UNDERSCORE_SPACE = '^[a-zA-Z0-9-_ ]+$',
  ALPHA_NUMERIC_SPECIAL_CHAR = '^[a-zA-Z0-9 @!$&%\'()*+,:;=#-_]*$',
  ALPHA_NUMERIC_EMAIL = '^[A-Za-z0-9@_.-]+$',
  ALPHA_NUMERIC_NO_SPACE_AS_PREFIX = '^[^-\s][ a-zA-Z0-9_\s-]+$',
  URL = 'http(s)?:\\/\\/[\\w.-]+(?:\\.[\\w.-]+)+[\\w-._~:/?#\\[\\]@!$&%\'()*+,;=]+',
  EMAIL = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$',
  PLACEHOLDER = '\\[@(.*?)@\\]',
  NON_WHITE_SPACE = '^[a-zA-Z0-9@!$&%\'()*+,:;=#-]*$',
  INTEGER = '^\\d+$',
  S3_URL = '^(s3|s3a|s3n):\\/\\/.+\\/',
  FLOAT_WITH_2_DECIMALS = '^\\d+(\\.\\d{2})?$',
  JSON_ATTRIBUTE_PATH = '\\$\\.',
  DATE_FORMAT_REGEX = '^[0-9]{1,}[/-][0-9]{1,}[/-][0-9]{2,}',
  VALIDATE_ZERO_AT_BEGINNING = '^[1-9][0-9]*$',
  PASSWORD_VALIDATION = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$'
}