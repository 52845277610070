import { Component, OnInit } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { BaseComponent } from '../../base/base.component';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { IMSService } from 'src/app/shared/services/ims.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LookUps } from './ims-lookup-enum';

@Component({
  selector: 'app-ims-lookup',
  templateUrl: './ims-lookup.component.html'
})
export class IMSLookUpComponent extends BaseComponent implements OnInit {

  public isPageLoading = false;
  public properties = {};
  public imsLookupTableFilteredData = [];
  public imsLookupForm: UntypedFormGroup;
  public imsLookupRecords = [];
  public noResults: boolean;
  public selectedLookupKey: string;
  public searchByAttribute: string;
  public lookUps = LookUps;
  private IMS_CONTEXT_ID = 'ae';
  private parentId: string;

  constructor(parentContextService: ParentContextService,
    public imsService: IMSService,
    public authorizationService: AuthorizationService,
    private fb: UntypedFormBuilder) {
    super();
    this.parentId = parentContextService.getParentContext();
  }

  ngOnInit(): void {
    this.initImsLookupForm();
    this.initDataTableConfig();
  }

  public handleSearch(): void {
    if (this.imsLookupForm.invalid) {
      return;
    }
    const searchInput: string = this.imsLookupForm.get('key').value;
    this.fetchIMSLookupRecords(searchInput);
  }

  public onItemChange(event: string): void {
    this.selectedLookupKey = event;
  }

  // returns keys of enum
  public lookUpKeys(): Array<string> {
    let keys = Object.keys(this.lookUps);
    keys = keys.map(x => x.replace(/_/g, ' '));
    return keys;
  }

  // returns values of enum
  public lookUpVals(): Array<string> {
    const keys = Object.keys(this.lookUps);
    return keys.map(el => Object(this.lookUps)[el]);
  }

  private initImsLookupForm(): UntypedFormGroup {
    this.imsLookupForm = this.fb.group({
      lookUpName: ['', Validators.required],
      key: ['', Validators.required]
    });
    return this.imsLookupForm;
  }

  private fetchIMSLookupRecords(searchInput: string): void {
    const lookUpKey = LookUps[this.selectedLookupKey.replace(' ', '_')];
    const requestBody = { 'parentId': this.parentId, 'contextId': this.IMS_CONTEXT_ID };
    requestBody[lookUpKey] = searchInput;
    this.imsService.fetchIMSLookUpRecord(this.parentId, JSON.stringify(requestBody)).subscribe(
      (res: any) => {
        this.imsLookupRecords = JSON.parse(res.result.httpResponse);
        this.isPageLoading = false;
        this.noResults = res.result.httpResponse.length <= 2;
      }, (error: any) => {
        console.log(error);
        this.isPageLoading = false;
        this.noResults = true;
      });
  }

  private initDataTableConfig(): void {
    this.isPageLoading = true;
    this.properties = {
      columns: [
        {
          headerText: 'Customer Key',
          key: 'customerKey',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Email Address',
          key: 'emailAddress',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Customer ID',
          key: 'customerId',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'SMS Number',
          key: 'smsNumber',
          isSortable: true,
          isColumnDisplayed: true
        },
        {
          headerText: 'Device ID',
          key: 'deviceId',
          isSortable: true,
          isColumnDisplayed: true
        }
      ],
      sort: {
        defaultSortedColumn: 'customerKey',
        defaultSortOrder: 'ascending'
      },
      hasColumnSelector: true,
      hasDisplayDensity: true
    };
  }


}
