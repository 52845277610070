
export enum DeltaCategory {

	NAME = 'NAME',
	ACTIVE = 'ACTIVE',
	MODE = 'MODE',
  COMPANY = 'COMPANY',
  CONDITIONS = 'CONDITIONS',
  ACTIONS = 'ACTIONS',
  REMINDER_ACTIONS = 'REMINDER ACTIONS',
  TEMPLATE_ALLOCATIONS = 'TEMPLATE ALLOCATIONS',
  EXCLUSIONS = 'EXCLUSIONS'

}
