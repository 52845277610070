<main class="container-fluid">
  <div class="row justify-content-center vh-100">
    <div class="col-auto d-flex align-items-center">
      <div class="login-container">
        <form [formGroup]="loginForm" (ngSubmit)="OnSubmit()" class="login-form">
          <img src="assets/img/events-logo.svg" alt="Agility Events" class="logo">
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" id="username" class="form-control" placeholder="Enter your username" formControlName="username" />
          </div>
          <div class="form-group password">
            <label for="password">Password</label>
            <input type="password" id="password" class="form-control" placeholder="Enter your password" formControlName="password"/>
          </div>
          <button type="submit" class="btn mt-3 btn-primary btn-block" [disabled]="!loginForm.valid">Log In</button>
          <p *ngIf="errorMessage!=null" class="text-error">{{ errorMessage }}</p>
          <div class="mt-3">
            <coreui-load-mask [active]="loggingIn"></coreui-load-mask>
          </div>
        </form>
        <footer>
          <small>
            &copy; Copyright 2020, Epsilon Data Management, LLC. All rights reserved.
          </small>
          <small>
            All materials, products, content and forms contained within or accessible by this website are the intellectual property of Epsilon Data Management, LLC and may not be copied, reproduced, modified, adapted, distributed, used, or displayed without Epsilon Data Management, LLC's express written permission.
          </small>
          <small class="version">
            Version {{ version }}
          </small>
        </footer>
      </div>
    </div>
  </div>
</main>
