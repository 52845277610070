import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ExclusionBuilderComponent } from './exclusion-builder.component';
import { EntityTypes, FormOnSaveAction } from '../../../shared/constants';

@Injectable({
    providedIn: 'root'
})
export class ExclusionBuilderCanDeactivateGuard implements CanDeactivate<ExclusionBuilderComponent> {

    constructor() {
    }

    canDeactivate(component: ExclusionBuilderComponent): boolean | Observable<boolean> {
        if (component.isDataSaved || component.userOperationDelete) {
            return true;
        }
        if (component.entityType === EntityTypes.CUSTOM &&
            (component.action === FormOnSaveAction.CREATE && this.isCreateFormUpdated(component) ||
            component.action === FormOnSaveAction.EDIT && this.isEditFormUpdated(component))) {
        return component.launchUnsavedChangesModal();
        }
        return true;
    }

    private isEditFormUpdated(component: ExclusionBuilderComponent): boolean {
        return component.configuredExclusion.name !== component.exclusionBuilderHeaderComponent.getName() ||
            component.configuredExclusion.exclusion !== JSON.stringify(component.exclusionConfigurationBuilderComponent.buildExclusion());
    }

    private isCreateFormUpdated(component: ExclusionBuilderComponent): boolean {
        return component.exclusionBuilderHeaderComponent.getName() !== '' ||
            component.exclusionConfigurationBuilderComponent.droppedEntityStateAttribute !== undefined;
    }
}