<form [formGroup]="formGroup">
  <div class="form-group">
  <coreui-filter [filterStyle]="filterStyle" [autoFocus]="autoFocus" [autoHide]="autoHide" [panelCssClass]="panelCssClass"
    [buttonLabel]="displayButtonLabel" [label]="label" #filter>
    <coreui-checkbox-group orientation="vertical" formArrayName="formName" class="filterCheckboxGroup">
      <coreui-form-field *ngFor="let entity of entityArray; index as i" [label]="entity.label">
        <input type="checkbox" coreUICheckbox coreUITrapFocusInitial [formControlName]="i"/>
      </coreui-form-field>
    </coreui-checkbox-group>
    <div coreUIButtonGroup class="panelButtons">
      <button type="button" coreUIButton="text-primary" (click)="clearFilter()">
          Clear
      </button>
      <button type="button" coreUIButton="text-primary" (click)="applyFilter()">
          Apply
      </button>
    </div>
  </coreui-filter>
  </div>
</form>
