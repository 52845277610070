<coreui-modal #alertModal>
  <ng-container *ngIf="options !== undefined">
    <ng-template #titleTemplate>
      <span coreUIIcon="warning" class="Modal-titleIcon"></span> {{options.title}}
    </ng-template>
    <ng-template #titleCloseButtonTemplate>
        <app-ae-button buttonType="icon-large" (click)="no()" icon="close" ></app-ae-button>
    </ng-template>
    <ng-template #contentTemplate>
      <h3 *ngIf="!options.isAlert" class="h2">
        <i class="fa fa-exclamation-circle fa-lg icon-color-warning mr-2" aria-hidden="true"></i>Warning
      </h3>
      <p *ngIf="options.message">{{options.message}}</p>
      <p *ngFor="let item of options.message2" class="mt-4">{{item}}</p>
    </ng-template>
    <ng-template #buttonsTemplate>
        <app-ae-button *ngIf="!options.isAlert" (click)="yes()" buttonText={{options.btnOkText}}></app-ae-button>
        <app-ae-button (click)="no()" buttonType="secondary" buttonText={{options.btnCancelText}}></app-ae-button>
    </ng-template>
  </ng-container>
</coreui-modal>