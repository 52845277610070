import { Component, Input, OnInit } from '@angular/core';
import { ProgramState } from 'src/app/shared/models/program-state';

@Component({
  selector: 'app-program-state-indicator[programStatus]',
  templateUrl: './program-state-indicator.component.html'
})
export class ProgramStateIndicatorComponent implements OnInit {

  @Input() programStatus: boolean;
  @Input() programMode: string;
  programState = ProgramState;

  constructor() { }

  ngOnInit() {
  }

  programStateStyle(programMode: string): string {
    if (this.programState[programMode] === 'Active') {
      return "complete";
    } else if (this.programState[programMode] === 'Inactive') {
      return "incomplete-primary";
    } else if (this.programState[programMode] === 'Test') {
      return "incomplete-secondary";
    }
  }

}