import { EVENT_SOURCE_PLACEHOLDER } from './association-constants';
import { ConfiguredEntityType } from './configured-entity-type.enum';
import { AssociationTemplate } from './association-template';

export class AssociationTemplateDetail {

    public static forEvents(eventSource: string) {
      const routes = {};
      routes[ConfiguredEntityType.RULE] = AssociationTemplate.RULE.uiPathPart;
      routes[ConfiguredEntityType.WORKFLOW] = AssociationTemplate.WORKFLOW.uiPathPart;
      routes[ConfiguredEntityType.ACTION] = AssociationTemplate.ACTION.uiPathPart;
      routes[ConfiguredEntityType.PROGRAM] = this.replacePlaceholderEventSource(AssociationTemplate.PROGRAM.uiPathPart, eventSource);
      return new AssociationTemplateDetail(AssociationTemplate.EVENT.displayName, AssociationTemplate.EVENT.apiPathPart, routes);
    }

    public static forActions() {
      const routes = {};
      routes[ConfiguredEntityType.RULE] = AssociationTemplate.RULE.uiPathPart;
      routes[ConfiguredEntityType.WORKFLOW] = AssociationTemplate.WORKFLOW.uiPathPart;
      return new AssociationTemplateDetail(AssociationTemplate.ACTION.displayName, AssociationTemplate.ACTION.apiPathPart, routes);
    }

    public static forEntityStates() {
      const routes = {};
      routes[ConfiguredEntityType.RULE] = AssociationTemplate.RULE.uiPathPart;
      routes[ConfiguredEntityType.EXCLUSION] = AssociationTemplate.EXCLUSION.uiPathPart;
      routes[ConfiguredEntityType.WORKFLOW] = AssociationTemplate.WORKFLOW.uiPathPart;
      return new AssociationTemplateDetail(AssociationTemplate.ENTITYSTATES.displayName,
        AssociationTemplate.ENTITYSTATES.apiPathPart, routes);
    }

    public static forRules(eventSource: string) {
      const routes = {};
      routes[ConfiguredEntityType.PROGRAM] = this.replacePlaceholderEventSource(AssociationTemplate.PROGRAM.uiPathPart, eventSource);
      return new AssociationTemplateDetail(AssociationTemplate.PROGRAM.displayName, AssociationTemplate.PROGRAM.apiPathPart, routes);
    }

    public static forEventSources(eventSource: string) {
      const routes = {};
      routes[ConfiguredEntityType.PROGRAM] = this.replacePlaceholderEventSource(AssociationTemplate.PROGRAM.uiPathPart, eventSource);
      routes[ConfiguredEntityType.EVENT] = this.replacePlaceholderEventSource(AssociationTemplate.EVENT.uiPathPart, eventSource);
      routes[ConfiguredEntityType.ACTION] = AssociationTemplate.ACTION.uiPathPart;
      routes[ConfiguredEntityType.RULE] = AssociationTemplate.RULE.uiPathPart;
      return new AssociationTemplateDetail(AssociationTemplate.EVENT_SOURCE.displayName,
        AssociationTemplate.EVENT_SOURCE.apiPathPart, routes);
    }

    public static forPackagedActionDestinations() {
      const routes = {};
      routes[ConfiguredEntityType.PROGRAM] = AssociationTemplate.PROGRAM.uiPathPart;
      return new AssociationTemplateDetail(AssociationTemplate.PACKAGED_ACTION_DESTINATION.displayName,
        AssociationTemplate.PACKAGED_ACTION_DESTINATION.apiPathPart, routes);
    }

    public static forCustomActionDestinations() {
      const routes = {};
      routes[ConfiguredEntityType.ACTION] = AssociationTemplate.ACTION.uiPathPart;
      return new AssociationTemplateDetail(AssociationTemplate.CUSTOM_ACTION_DESTINATION.displayName,
        AssociationTemplate.CUSTOM_ACTION_DESTINATION.apiPathPart, routes);
    }

    public static forExclusions(): AssociationTemplateDetail {
      const routes = {};
      routes[ConfiguredEntityType.PROGRAM] = AssociationTemplate.PROGRAM.uiPathPart;
      routes[ConfiguredEntityType.WORKFLOW] = AssociationTemplate.WORKFLOW.uiPathPart;
      return new AssociationTemplateDetail(AssociationTemplate.PROGRAM.displayName, AssociationTemplate.PROGRAM.apiPathPart, routes);
    }

    private static replacePlaceholderEventSource(routes: object, eventSource: string) {
      Object.keys(routes).forEach(key => {
        routes[key] = (routes[key] as string).replace(EVENT_SOURCE_PLACEHOLDER, eventSource);
      });
      return routes;
    }

    private constructor(private displayName, private apiPathPart, private routes: object) {
    }

    public get DisplayName() {
      return this.displayName;
    }

    public get ApiPathPart() {
      return this.apiPathPart;
    }

    public get Routes() {
      return this.routes;
    }

}
