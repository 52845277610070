import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConfiguredEventDetails } from '../models/configured-event-details';
import { PackagedEventSourceName } from '../models/event-source';
const eventSourcePath = 'event-source/';
const configuredEventPath = 'event/configured';
@Injectable({
  providedIn: 'root'
})
export class EventSourceService {

  host = environment.host;
  base = environment.base;

  constructor(private http: HttpClient) { }

  static getPackagedEventSourceMap(): Map<string, string> {
    const eventSourceMap = new Map<string, string>();
    eventSourceMap.set(PackagedEventSourceName.CONVERSANT, PackagedEventSourceName.EPSILON_TAG);
    return eventSourceMap;
  }

  getCompanies(parentId: string, eventSource: string) {
    const url = this.host + this.base + eventSourcePath + parentId + '/' + eventSource;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }
  getEventSourceProvider(parentId: string) {
    const url = this.host + this.base + eventSourcePath;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  getEventSources(parentId: string): Observable<EventSource[]> {
    const url = this.host + this.base + eventSourcePath + parentId;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get<EventSource[]>(url, options);
  }

  updateEventSource(parentId, eventSource, payload) {
    const url = this.host + this.base + eventSourcePath + parentId + '/' + eventSource;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.put(url, payload, options);
  }

  configureEventSource(parentId, payload) {
    const url = this.host + this.base + eventSourcePath + 'configured';
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.post(url, payload, options);
  }

  deleteCompany(parentId, payload) {
    const url = this.host + this.base + eventSourcePath + 'company';
    return this.http.request('DELETE', url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'parent-id': parentId
      }),
      body: payload
    });
  }
  deleteEventSource(parentId, eventSource) {
    const url = this.host + this.base + eventSourcePath + parentId + '/' + eventSource;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.delete(url, options);
  }
  getConfiguredEvents(parentId) {
    const url = this.host + this.base + configuredEventPath;
    return this.http.get<ConfiguredEventDetails[]>(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

}