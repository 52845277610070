<div class="row mb-3">
  <div class="col">
    <bp-ribbon classes="ribbon {{ ribbonStyle }}">
      <div ribbon-content class="d-flex align-items-center">
        <p class="mr-3 mb-0">{{ ribbonMessage }}</p>
        <app-ae-button *ngIf="ribbonHasButton" (click)="emitClick($event)"
          buttonText="{{ buttonText }}" buttonType="secondary"></app-ae-button>
      </div>
    </bp-ribbon>
  </div>
</div>
