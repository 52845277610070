import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorIconComponent } from 'src/app/shared/component/selector-icon/selector-icon.component';
import { RuleBuilderComponent } from 'src/app/shared/component/rule-builder/rule-builder.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RuleConditionBuilderComponent } from 'src/app/shared/component/rule-condition-builder/rule-condition-builder.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { TimeDurationComponent } from 'src/app/shared/component/time-duration/time-duration.component';
import { DelayByAttributeComponent } from 'src/app/shared/component/delay-by-attribute/delay-by-attribute.component';
import { RibbonComponent } from 'src/app/shared/component/ribbon/ribbon.component';
import { RuleActionBuilderComponent } from 'src/app/shared/component/rule-action-builder/rule-action-builder.component';
import { RuleActionComponent } from 'src/app/shared/component/rule-action/rule-action.component';
import { TableModule, PaginationModule, ActionModule, RibbonModule } from 'epsilon-blueprint';
import { TreeModule } from '@circlon/angular-tree-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RuleThenClauseOperationComponent } from './component/rule-then-clause-operation/rule-then-clause-operation.component';
import { RuleThenClauseOperationBuilderComponent } from './component/rule-then-clause-operation-builder/rule-then-clause-operation-builder.component';
import { RuleThenClauseBuilderComponent } from './component/rule-then-clause-builder/rule-then-clause-builder.component';
import { CoreUIModule } from '@epsilon/core-ui';
import { AEBadgeComponent } from './component/ae-badge/ae-badge.component';
import { AETableComponent } from './component/ae-table/ae-table.component';
import { ModalWarningComponent } from './component/modal/modal-warning/modal-warning.component';
import { CoreuiModalWarningComponent } from './component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { AEModalInfoComponent } from './component/modal/ae-modal-info/ae-modal-info.component';
import { AEButtonComponent } from './component/ae-button/ae-button.component';
import { AELoadingIndicatorComponent } from './component/ae-loading-indicator/ae-loading-indicator.component';
import { AEMultiSelectComponent } from './component/multi-select/ae-multi-select.component';
import { AENotificationComponent } from './component/ae-notification/ae-notification.component';
import { ExclusionConfigurationDirective } from './exclusion/exclusion-configuration/exclusion-configuration.directive';
import { ExclusionConfigurationBooleanOperationComponent } from
  './exclusion/exclusion-configuration/exclusion-configuration-boolean-operation/exclusion-configuration-boolean-operation.component';
import { ExclusionConfigurationLastNOccurrencesComponent }
  from './exclusion/exclusion-configuration/exclusion-configuration-last-n-occurrences/exclusion-configuration-last-n-occurrences.component';
import { ExclusionConfigurationStringOperationComponent } from
  './exclusion/exclusion-configuration/exclusion-configuration-string-operation/exclusion-configuration-string-operation.component';
import { ExclusionConfigurationDateOperationComponent } from
  './exclusion/exclusion-configuration/exclusion-configuration-date-operation/exclusion-configuration-date-operation.component';
import { ExclusionConfigurationLastOccurrencesComponent } from
  './exclusion/exclusion-configuration/exclusion-configuration-last-occurrences/exclusion-configuration-last-occurrences.component';
import { ExclusionConfigurationFrequencyOccurrencesComponent } from
  './exclusion/exclusion-configuration/exclusion-configuration-frequency-occurrences/exclusion-configuration-frequency-occurrences.component';
import { AeFilterComponent } from './component/ae-filter/ae-filter.component';
import { RuleWhenClauseBuilderComponent } from './component/rule-when-clause-builder/rule-when-clause-builder.component';
import { AeTableSearchComponent } from './component/ae-table-search/ae-table-search.component';
import { AeRadioGroupComponent } from './component/ae-radio-group/ae-radio-group.component';
import { AESortComponent } from './component/ae-sort/ae-sort.component';
import { AELoadingScreenComponent } from './component/ae-loading-screen/ae-loading-screen.component';
import { AeToggleComponent } from './component/ae-toggle/ae-toggle.component';
import { ClientFormComponent } from './component/client-form/client-form.component';
import { AESectionHeaderComponent } from './component/ae-section-header/ae-section-header.component';
import { AELeftNavComponent } from './component/ae-left-nav/ae-left-nav.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [
    SelectorIconComponent,
    RuleBuilderComponent,
    RuleConditionBuilderComponent,
    TimeDurationComponent,
    DelayByAttributeComponent,
    DelayByAttributeComponent,
    RibbonComponent,
    RuleActionBuilderComponent,
    RuleActionComponent,
    RuleThenClauseOperationComponent,
    RuleThenClauseOperationBuilderComponent,
    RuleThenClauseBuilderComponent,
    AETableComponent,
    AEBadgeComponent,
    ModalWarningComponent,
    CoreuiModalWarningComponent,
    AEModalInfoComponent,
    AEButtonComponent,
    AELoadingIndicatorComponent,
    AEMultiSelectComponent,
    AENotificationComponent,
    ExclusionConfigurationLastNOccurrencesComponent,
    ExclusionConfigurationDirective,
    ExclusionConfigurationBooleanOperationComponent,
    ExclusionConfigurationStringOperationComponent,
    ExclusionConfigurationDateOperationComponent,
    ExclusionConfigurationLastOccurrencesComponent,
    ExclusionConfigurationFrequencyOccurrencesComponent,
    AeFilterComponent,
    RuleWhenClauseBuilderComponent,
    AeTableSearchComponent,
    AeRadioGroupComponent,
    AESortComponent,
    AELoadingScreenComponent,
    AeToggleComponent,
    ClientFormComponent,
    AESectionHeaderComponent,
    AELeftNavComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TreeModule,
    DragDropModule,
    NgbModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TableModule,
    PaginationModule,
    ActionModule,
    RibbonModule,
    NgSelectModule,
    FormsModule,
    CoreUIModule,
    RouterLink
  ],
  exports: [
    CommonModule,
    SelectorIconComponent,
    RuleBuilderComponent,
    TimeDurationComponent,
    DelayByAttributeComponent,
    AEBadgeComponent,
    AETableComponent,
    ModalWarningComponent,
    CoreuiModalWarningComponent,
    AEModalInfoComponent,
    AEButtonComponent,
    RibbonComponent,
    AELoadingIndicatorComponent,
    AEMultiSelectComponent,
    AENotificationComponent,
    AeTableSearchComponent,
    ExclusionConfigurationLastNOccurrencesComponent,
    ExclusionConfigurationDirective,
    AeFilterComponent,
    AeRadioGroupComponent,
    AESortComponent,
    AELoadingScreenComponent,
    AeToggleComponent,
    ClientFormComponent,
    AESectionHeaderComponent,
    AELeftNavComponent
  ]
})
export class SharedModule { }
