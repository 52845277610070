import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ConfiguredEntityState } from '../models/entity-states/configured-entity-state';
import { EntityType } from '../models/entity-type';

@Injectable({ providedIn: 'root' })
export class EntityStatesService {

  host = environment.host;
  base = environment.base;
  entityStateUrl = 'entity/';
  systemEntityStateUrl = 'packaged/';
  customEntityStateUrl = 'configured/';

  constructor(private http: HttpClient) { }

  getAllConfiguredEntityStates(parentId: string) : Observable<any> {
    const url = this.host + this.base + this.entityStateUrl;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  getConfiguredEntityState(parentId: string, configuredEntityStateId: string, type: string) : Observable<any> {
    if (type === EntityType.PACKAGED) {
      return this.getConfiguredSystemEntityState(parentId, configuredEntityStateId);
    } else {
      return this.getConfiguredCustomEntityState(parentId, configuredEntityStateId);
    }
  }

  getConfiguredSystemEntityState(parentId: string, configuredEntityStateId: string) : Observable<any> {
    const url = this.host + this.base + this.entityStateUrl + this.systemEntityStateUrl + configuredEntityStateId;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  getConfiguredCustomEntityState(parentId: string, configuredEntityStateId: string) : Observable<any> {
    const url = this.host + this.base + this.entityStateUrl + this.customEntityStateUrl + configuredEntityStateId;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  deleteConfiguredCustomEntityState(parentId: string, configuredEntityStateId: string) : Observable<any> {
    const url = this.host + this.base + this.entityStateUrl + this.customEntityStateUrl + configuredEntityStateId;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.delete(url, options);
  }

  addConfiguredCustomEntityState(parentId: string, configuredEntityState: any): Observable<any> {
    const url = this.host + this.base + this.entityStateUrl + 'configured';
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.post(url, configuredEntityState, options);
  }

  updateConfiguredCustomEntityState(parentId: string, configuredEntityState: ConfiguredEntityState): Observable<any> {
    const url = this.host + this.base + this.entityStateUrl + this.customEntityStateUrl + configuredEntityState.id;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.put(url, configuredEntityState, options);
  }

}