import { ComponentFactory, ComponentFactoryResolver, Injectable, Type } from '@angular/core';
import { ExclusionConfigurationDateOperationComponent } from
  '../exclusion/exclusion-configuration/exclusion-configuration-date-operation/exclusion-configuration-date-operation.component';
import { ExclusionConfigurationLastNOccurrencesComponent } from
  '../exclusion/exclusion-configuration/exclusion-configuration-last-n-occurrences/exclusion-configuration-last-n-occurrences.component';
import { ExclusionConfigurationStringOperationComponent } from
  '../exclusion/exclusion-configuration/exclusion-configuration-string-operation/exclusion-configuration-string-operation.component';
import { DataTypes } from '../models/custom-rule/conditions/data-types';
import { BaseExclusionConfiguration } from '../models/exclusion/base-exclusion-configuration';
import { ExclusionOperator } from '../models/exclusion/exclusion-operator';
import { ExclusionConfigurationBooleanOperationComponent } from
  '../exclusion/exclusion-configuration/exclusion-configuration-boolean-operation/exclusion-configuration-boolean-operation.component';
import { ExclusionConfigurationLastOccurrencesComponent } from
  '../exclusion/exclusion-configuration/exclusion-configuration-last-occurrences/exclusion-configuration-last-occurrences.component';
import { ExclusionConfigurationFrequencyOccurrencesComponent } from
  '../exclusion/exclusion-configuration/exclusion-configuration-frequency-occurrences/exclusion-configuration-frequency-occurrences.component';

@Injectable({
  providedIn: 'root'
})
export class ExclusionConfiguationComponentBuilderService {

  public constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  public build(exclusionOperator: ExclusionOperator, attributeDataType: DataTypes): ComponentFactory<BaseExclusionConfiguration> {
    const exclusionConfigurationComponent: Type<BaseExclusionConfiguration> = this
      .getExclusionConfigurationComponent(exclusionOperator, attributeDataType);
    if (!exclusionConfigurationComponent) {
      return undefined;
    }
    return this.componentFactoryResolver.resolveComponentFactory(exclusionConfigurationComponent);
  }

  private getExclusionConfigurationComponent(exclusionOperator: ExclusionOperator, attributeDataType: DataTypes): Type<BaseExclusionConfiguration> {
    let exclusionConfigurationComponent: Type<BaseExclusionConfiguration>;
    switch (exclusionOperator) {
      case ExclusionOperator.LATEST_N_OCCURRENCE:
        exclusionConfigurationComponent = ExclusionConfigurationLastNOccurrencesComponent;
        break;
      case ExclusionOperator.LATEST_OCCURRENCE:
        exclusionConfigurationComponent = ExclusionConfigurationLastOccurrencesComponent;
        break;
      case ExclusionOperator.LATEST_FREQUENCY_OCCURRENCE:
        exclusionConfigurationComponent = ExclusionConfigurationFrequencyOccurrencesComponent;
        break;
      case ExclusionOperator.EQ_IGNORECASE:
      case ExclusionOperator.NEQ_IGNORECASE:
      case ExclusionOperator.STARTSWITH_IGNORECASE:
      case ExclusionOperator.ENDSWITH_IGNORECASE:
      case ExclusionOperator.LIKE:
      case ExclusionOperator.NOTLIKE:
      case ExclusionOperator.NOTIN_IGNORECASE:
      case ExclusionOperator.IN_IGNORECASE:
        exclusionConfigurationComponent = ExclusionConfigurationStringOperationComponent;
        break;
      case ExclusionOperator.EQ:
      case ExclusionOperator.NEQ:
        exclusionConfigurationComponent = this.getExclusionComponentByDataType(attributeDataType);
        break;
      case ExclusionOperator.BEFORE:
      case ExclusionOperator.AFTER:
      case ExclusionOperator.LT:
      case ExclusionOperator.LTEQ:
      case ExclusionOperator.GT:
      case ExclusionOperator.GTEQ:
        exclusionConfigurationComponent = ExclusionConfigurationDateOperationComponent;
        break;
      default:
        exclusionConfigurationComponent = undefined;
    }
    return exclusionConfigurationComponent;
  }

  private getExclusionComponentByDataType(attributeDataType: DataTypes): Type<BaseExclusionConfiguration> {
    let exclusionConfigurationComponent: Type<BaseExclusionConfiguration>;
    switch (attributeDataType) {
      case DataTypes.boolean:
        exclusionConfigurationComponent = ExclusionConfigurationBooleanOperationComponent;
        break;
      case DataTypes.date:
        exclusionConfigurationComponent = ExclusionConfigurationDateOperationComponent;
        break;
      default:
        exclusionConfigurationComponent = undefined;
    }
    return exclusionConfigurationComponent;
  }
}
