export const ID_PLACEHOLDER = '[@id@]';
export const EVENT_SOURCE_PLACEHOLDER = '[@event-source@]';
export const NAME_IDENTIFIER = 'name';
export const ID_IDENTIFIER = 'id';
export const TYPE_IDENTIFIER = 'type';
export const ROUTE_IDENTIFIER = 'route';
export const PROGRAM_TYPE_IDENTIFIER = 'programType';
export const ASSOCIATION_API_FIELD_STATUS = 'status';
export const ASSOCIATION_API_FIELD_ASSOCIATIONS = 'associations';
export const EVENT_SOURCE = 'eventSource';
export const ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER = 'isAssociationApiCallInProgress';
export const HAS_ASSOCIATIONS_IDENTIFIER = 'hasAssociations';
export const PACKAGED_PROGRAM_TYPE = 'PACKAGED';
export const ENTITY_TYPE = 'entityType';