import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '@epsilon/core-ui';
import { BaseComponent } from 'src/app/modules/client/base/base.component';

@Component({
  selector: 'app-ae-modal-info',
  templateUrl: './ae-modal-info.component.html',
  styleUrls: ['./ae-modal-info.component.scss']
})
export class AEModalInfoComponent extends BaseComponent implements OnInit {

  @Input()
  public modalViewInput: Record<string, any>;
  @Output()
  public selectedItem: EventEmitter<any> = new EventEmitter();
  @Output()
  public isModalDisplayed = new EventEmitter<any>();
  @ViewChild('basicModal', { static: true })
  private basicModal: ModalComponent;
  @ViewChild('contentTextarea', {static: false}) set contentTextareaRef(element: ElementRef) {
    if (element) {
      this.textarea = element.nativeElement;
      let numNewLines = this.textarea.defaultValue.split("\n").length;
      this.textarea.rows = numNewLines <= 5 ? 5 : numNewLines;
    }
  }

  public textarea: any;
  public title: string;
  public plainTextLabel: string;
  public plainTextContent: any;
  public data: unknown;
  public properties: Record<string, unknown>;
  public isTableDataLoading: boolean;
  public displayItems;
  public itemSelected;

  private isModalShown: boolean;

  constructor() {
    super();
  }

  public ngOnInit(): void {
  }

  public initViewFields(): void {
    this.title = this.modalViewInput.title;
    if (this.isShowPlainTextInfoModal()) {
      this.plainTextContent = this.modalViewInput['content']['data'];
    } else if (this.isTableDataInfoModal()) {
      this.data = this.modalViewInput['content']['data'];
      this.properties = this.modalViewInput['content']['tableConfig']['properties'];
      this.isTableDataLoading = this.modalViewInput['content']['tableConfig']['isDataLoading'];
    } else if (this.isIconViewModal()) {
      this.displayItems = this.modalViewInput['content']['iconViewConfig']['displayItems'];
    }
  }
  
  public hasBasicModalInfo(): boolean {
    return 'title' in this.modalViewInput
    && 'content' in this.modalViewInput
    && this.modalViewInput['content'] !== null
    && 'type' in this.modalViewInput['content'];
  }

  public isShowPlainTextInfoModal(): boolean {
    return this.hasBasicModalInfo() && this.isPlainTextContent();
  }

  public isTableDataInfoModal(): boolean {
    return this.hasBasicModalInfo() && this.isTableContent();
  }

  public isIconViewModal(): boolean {
    return this.hasBasicModalInfo() && this.isIconViewContent();
  }

  public handleModalAction(): void {
    this.closeBasicModal();
  }

  public select(item): void {
    this.itemSelected = this.isDisabled(item) ? null : item;
  }

  public add(): void {
    this.closeBasicModal();
    this.selectedItem.emit(this.itemSelected);
  }

  public isDisabled(item): boolean {
    const disabledAttributeCondition = disabled => disabled.Name === item.Name && disabled.Id === item.Id;
    const disabledItems = this.modalViewInput['content']['iconViewConfig']['disabledItems'];
    return disabledItems.some(disabledAttributeCondition);
  }

  public closeBasicModal(): void {
    void this.basicModal.hide();
    this.isModalShown = false;
    this.isModalDisplayed.emit(this.isModalShown);
  }

  public launchBasicModal(): void {
    void this.basicModal.show();
    this.isModalShown = true;
  }

  public launchModal(modalViewInput: Record<string, any>): void {
    this.modalViewInput = modalViewInput;
    this.initViewFields();
    this.launchBasicModal();
  }

  private isTableContent(): boolean {
    return this.modalViewInput['content']['type'] === 'TABLE'
    && 'data' in this.modalViewInput['content']
    && this.modalViewInput['content']['data'] !== null
    && 'tableConfig' in this.modalViewInput['content']
    && this.modalViewInput['content']['tableConfig'] !== null
    && 'properties' in this.modalViewInput['content']['tableConfig']
    && this.modalViewInput['content']['tableConfig']['properties'] !== null
    && 'isDataLoading' in this.modalViewInput['content']['tableConfig'];
  }

  private isPlainTextContent(): boolean {
    return this.modalViewInput['content']['type'] === 'PLAIN_TEXT'
    && 'data' in this.modalViewInput['content']
    && this.modalViewInput['content']['data'] !== null;
  }

  private isIconViewContent(): boolean {
    return this.modalViewInput['content']['type'] === 'ICON_VIEW'
    && 'iconViewConfig' in this.modalViewInput['content']
    && this.modalViewInput['content']['iconViewConfig'] !== null
    && 'displayItems' in this.modalViewInput['content']['iconViewConfig']
    && this.modalViewInput['content']['iconViewConfig']['displayItems'] !== null
    && 'disabledItems' in this.modalViewInput['content']['iconViewConfig']
    && this.modalViewInput['content']['iconViewConfig']['disabledItems'] !== null;
  }

}
