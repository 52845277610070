<coreui-site-header *ngIf="showNav()" [logoTpl]="eventsLogo" [primaryTpl]="eventsNav"
  [secondaryTpl]="eventsMenu">
  <ng-template #eventsLogo>
      <img class="logo" src="assets/img/events-logo-new.svg" alt="Epsilon Events">
  </ng-template>
  <ng-template #eventsNav>
    <ng-container *ngIf="showNavItems()">
      <div class="nav-select"><app-parent-switcher></app-parent-switcher></div>
      <nav coreUIPrimaryNavigation id="nav-primary" aria-label="Primary">
        <coreui-primary-navigation-item [active]="programsActiveIndicator" *ngIf="authorizationService.isCategoryAccessible('PROGRAMS','OR')">
          <a coreUIPrimaryNavigationItemLink routerLink="/programs">Programs</a>
        </coreui-primary-navigation-item>
        <coreui-primary-navigation-item [active]="eventsActiveIndicator" *ngIf="authorizationService.isCategoryAccessible('EVENTS','OR')">
          <a coreUIPrimaryNavigationItemLink routerLink="/events">Events</a>
        </coreui-primary-navigation-item>
        <coreui-primary-navigation-item [active]="actionsActiveIndicator" *ngIf="authorizationService.isCategoryAccessible('ACTIONS','OR')">
          <a coreUIPrimaryNavigationItemLink routerLink="/actions">Actions</a>
        </coreui-primary-navigation-item>
        <coreui-primary-navigation-item [active]="entityStatesActiveIndicator" *ngIf="authorizationService.isCategoryAccessible('ENTITY_STATES','OR')">
          <a coreUIPrimaryNavigationItemLink routerLink="/entitystates">Entity States</a>
        </coreui-primary-navigation-item>
        <coreui-primary-navigation-item [active]="iqDecisionsActiveIndicator" *ngIf="pageIQDecisionsValidation() && authorizationService.isCategoryAccessible('CONFIGURED_RECOMMENDATION','OR')">
          <a coreUIPrimaryNavigationItemLink routerLink="/iqdecisions">IQ Decisions</a>
        </coreui-primary-navigation-item>
        <coreui-primary-navigation-item [active]="rulesActiveIndicator" *ngIf="authorizationService.isCategoryAccessible('RULES','OR')">
            <a coreUIPrimaryNavigationItemLink routerLink="/rules">Rules</a>
        </coreui-primary-navigation-item>
        <coreui-primary-navigation-item [active]="exclusionsActiveIndicator" *ngIf="authorizationService.isCategoryAccessible('EXCLUSIONS','OR')">
          <a coreUIPrimaryNavigationItemLink routerLink="/exclusions">Exclusions</a>
        </coreui-primary-navigation-item>
        <coreui-primary-navigation-item [active]="clientActiveIndicator" *ngIf="authorizationService.isCategoryAccessible('CLIENT','OR')">
          <a coreUIPrimaryNavigationItemLink routerLink="/client">Client</a>
        </coreui-primary-navigation-item>
      </nav>
    </ng-container>
  </ng-template>
  <ng-template #eventsMenu>
      <coreui-menu buttonLabel="Help" menuButtonStyle="link"
          verticalPosition="bottom" iconAlignment="right">
          <coreui-menu-section>
              <a [href]="documentationLink" target="_blank" coreUIMenuItem>
                  Documentation<i
                      coreUIIcon="newTab"
                      [scale]=".9"></i></a>
              <button type="button" coreUIMenuItem>
                  Version {{buildNumber}}
              </button>
          </coreui-menu-section>
      </coreui-menu>
      <coreui-menu buttonLabel="Account" verticalPosition="bottom" menuButtonStyle="link"
          iconAlignment="right">
          <coreui-menu-section>
              <button type="button" coreUIMenuItem>{{loginServiceLocator.getLoginService().getUserName()}}
              </button>
              <button type="button" (click)="performLogout()" coreUIMenuItem>Logout
              </button>
          </coreui-menu-section>
      </coreui-menu>
      <coreui-menu *ngIf="authorizationService.isCategoryAccessible('PARENT','AND')" verticalPosition="bottom" menuButtonStyle="link" icon="settings">
        <coreui-menu-section>
          <a [href]="" routerLink="/admin/clients" target="_blank" coreUIMenuItem>
            All Clients <i coreUIIcon="newTab" [scale]="1"></i></a>
        </coreui-menu-section>
      </coreui-menu>
      <coreui-menu *ngIf="authorizationService.isCategoryAccessible('PRODUCT_SWITCHER', 'OR') && isOktaUser()" verticalPosition="bottom" menuButtonStyle="link" icon="grid">
          <coreui-menu-section>
            <a [href]="" (click)="authorizeProduct('performanceInsights')" target="_blank" coreUIMenuItem>
              Performance Insights <i coreUIIcon="newTab" [scale]="1"></i></a>
          </coreui-menu-section>
      </coreui-menu>
  </ng-template>
</coreui-site-header>
