import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PackagedProgramStatus, Program } from 'src/app/shared/models/program';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ProgramRelationType } from 'src/app/shared/models/program-relation-type';
import { PackagedEventSource } from 'src/app/shared/models/event-source';
import { Bundle } from 'src/app/shared/models/bundle/Bundle';
import { BundleService } from 'src/app/shared/services/bundle.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';

@Component({
  selector: 'app-program-selector',
  templateUrl: './program-selector.component.html',
  styleUrls: ['./program-selector.component.scss']
})
export class ProgramSelectorComponent implements OnInit {

  public isApiCallInProgress: boolean;
  public parentId: string;
  public parentVerticalType: string;
  public programsForDisplay: any[] = [];
  public programsByVerticalType: any[] = [];
  public programsByBundle: any[] = [];
  public selectedProgram: any;
  public currentSelection: string;
  public availableBundles: Bundle[];

  private htmlTabRoleMapping: object = {};

  constructor(
    public service: ProgramService,
    public bundleService: BundleService,
    private router: Router,
    private context: ParentContextService,
  ) {
  }

  ngOnInit(): void {
    this.availableBundles = this.bundleService.getBundles();
    this.currentSelection = this.bundleService.getDefaultBundle().Id;
    this.initHtmlTabRoleMapping();
    this.parentId = this.context.getParentContext();
    this.parentVerticalType = this.context.getParentVerticalType();
    this.getAllPrograms();

  }

  openProgramHome(): void {
    this.router.navigate(['programs']);
  }

  private getAllPrograms(): void  {
      if (this.programsForDisplay) {
        this.isApiCallInProgress = true;
        this.service.getAllPrograms(this.parentId).subscribe((res: Program[]) => {
          this.setupProgramsForDisplay(res['result']);
          this.filterProgramsByVerticalType();
          this.filterProgramsByBundle();
        },
        (error: any) => {
          console.log(error);
          this.isApiCallInProgress = false;
        });
      }
  }

  private filterProgramsByVerticalType(): void {
    if (this.parentVerticalType) {
      this.programsForDisplay.forEach(program => {
        if ((!program.verticalType || program.verticalType === this.parentVerticalType) &&
        (!program.parentId || program.parentId === this.parentId)) {
          this.programsByVerticalType.push(program);
        }
      });
    } else {
      this.programsForDisplay.forEach(program => {
        if (!program.parentId || program.parentId === this.parentId) {
          this.programsByVerticalType.push(program);
        }
      });
    }
  }

  private filterProgramsByBundle(): void {
    this.programsByVerticalType.forEach(program => {
      if (program.bundles && program.bundles.includes(this.currentSelection)) {
        this.programsByBundle.push(program);
      }
    });
  }

  private setupProgramsForDisplay(programs: Program[]): void {
    for (const program of programs) {
      const programForDisplay: any = [];
      programForDisplay.name = program.displayName;
      programForDisplay.eventSource = program.eventSource;
      programForDisplay.eventSourceName = program.eventSourceName;
      programForDisplay.description = program.description;
      programForDisplay.verticalType = program.verticalType;
      programForDisplay.parentId = program.parentId;
      programForDisplay.bundles = program.bundles;
      programForDisplay.programRef = program;
      programForDisplay.disabled = false;
      programForDisplay.selected = false;
      programForDisplay.reminderProgram = undefined;
      if (program.status && program.status === PackagedProgramStatus.DISABLED) {
        programForDisplay.disabled = true;
      }
      if (program.relatedProgramSettings && program.relatedProgramSettings.length > 0) {
        for (const relatedProgramSetting of program.relatedProgramSettings) {
          if (relatedProgramSetting.relationType && relatedProgramSetting.relationType === ProgramRelationType.REMINDER) {
            programForDisplay.reminderProgram = programs.find(localProgram => localProgram.programName === relatedProgramSetting.name);
            break;
          }
        }
      }
      this.programsForDisplay.push(programForDisplay);
    }
    this.programsForDisplay.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
    this.isApiCallInProgress = false;
  }

  public selectProgram(selectedProgram: any): void {
    if (selectedProgram.selected) {
      this.selectedProgram.selected = false;
      this.selectedProgram = undefined;
    } else {
      this.selectedProgram = selectedProgram;
      const programToDeselect = this.programsForDisplay.find(programForDisplay => programForDisplay.selected);
      if (programToDeselect) {
        programToDeselect.selected = false;
      }
      this.selectedProgram.selected = true;
    }
  }

  public proceedToProgramConfiguration(): void {
    const program: Program = this.selectedProgram.programRef;
    this.router.navigateByUrl(`programs/configure/${program.programName}/${program.eventSource}/add`);
  }

  public getEventSourceDisplayName(eventSourceName: string): string {
    if (eventSourceName.toUpperCase() === PackagedEventSource.PROACTIV) {
      return PackagedEventSource.OTHER;
    } else {
      return eventSourceName;
    }
  }

  public onSelect($event): void {
    this.currentSelection = $event;
    this.programsByBundle = [];
    this.filterProgramsByBundle();
  }

  public getResultPanelId(bundleTabId: string): string {
    return `${bundleTabId}-PANEL`;
  }

  public getHtmlTabPanelId(bundleId: string): string {
    return this.htmlTabRoleMapping[bundleId];
  }

  private initHtmlTabRoleMapping() {
    this.availableBundles.forEach(bundle => {
      this.htmlTabRoleMapping[bundle.Id] = `${bundle.Id}_PANEL`;
    });
  }

}