<app-base-template>
  <app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
  <app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
  <section class="pt-4 pl-5 pr-4" *ngIf="configuredRecommendation">
    <coreui-breadcrumbs>
      <a routerLink="/iqdecisions" coreUIBreadcrumbLink>{{messages.iqDecisions}}</a>
      <span coreUIBreadcrumbSlash></span>
      <a routerLink="/iqdecisions" coreUIBreadcrumbLink>{{messages.packages}}</a>
      <span coreUIBreadcrumbSlash></span>
      <span coreUIBreadcrumbEnd> {{configuredRecommendation.name}}</span>
    </coreui-breadcrumbs>

    <div class="d-flex align-items-center mb-4">
      <i *ngIf="configuredRecommendation.type === packageType.SINGLE" id="settings-icon" id="single-model-package-icon"
        coreUIIcon="settings"></i>
      <i *ngIf="configuredRecommendation.type === packageType.MULTI" id="settings-icon" id="multi-model-package-icon"
        coreUIIcon="gears"></i>
      <ng-container *ngIf="!displayEditForm; else editName">
        <h1 class="ml-2 mr-2">{{configuredRecommendation.name}} </h1>
        <app-ae-badge [elements]="configuredRecommendationStatus"></app-ae-badge>
      </ng-container>
      <ng-template #editName>
        <form class="remove-margin" [formGroup]="configuredEntityForm">
          <coreui-form-field label="">
            <input type="text" formControlName="packageName" coreUIInput coreUIInlineEdit [isPersistent]="true"
              [isHeader]="true" [highlight]="true" (keyup)="packageNameValidations()" />
							<coreui-form-error *ngIf="packageName.errors?.required">
								<div> {{messages.packageNameRequiredValidationMessage}}</div>
							</coreui-form-error>
							<coreui-form-error *ngIf="packageName.errors?.maxlength">
								<div>{{messages.packageNameMaxLengthValidationMessage}}</div>
							</coreui-form-error>
							<coreui-form-error *ngIf="packageName.errors?.alreadyExist ">
								<div>{{messages.packageNameUniqueValidationMessage}} </div>
							</coreui-form-error>
          </coreui-form-field>
        </form>
      </ng-template>
    </div>

    <!-- HISTORY -->
    <app-iqdecisions-view-history [configuredRecommendation]="configuredRecommendation"></app-iqdecisions-view-history>

    <!-- PACKAGE INFO -->
    <app-iqdecisions-view-model-package-info [isEditMode]="displayEditForm"
      [configuredRecommendation]="configuredRecommendation"
      [packageInfoFormControl]="configuredEntityForm"
      [categoryLevelsList]="choiceTypeList"></app-iqdecisions-view-model-package-info>

    <!-- MODELS SECTION -->
    <app-iqdecisions-view-models [isEditMode]="displayEditForm" [configuredRecommendation]="configuredRecommendation"
      [modelsProperties]="modelsProperties" [models]="models" [modelFormGroup]="getFormGroupInfosOrModels()"
      [modelsTableData]="modelsTableData"></app-iqdecisions-view-models>

    <!-- MODEL PARAMETERS -->
    <app-iqdecisions-view-model-parameters [isEditMode]="displayEditForm"
      [configuredRecommendation]="configuredRecommendation"
      [modelParametersFormControl]="configuredEntityForm"></app-iqdecisions-view-model-parameters>

    <!-- PROGRAM USAGE -->
    <app-iqdecisions-view-program-usage [programUsageProperties]="programUsageProperties"
      [programUsageTableData]="programUsageTableData"></app-iqdecisions-view-program-usage>

    <!-- ACTION BUTTONS SECTION -->
    <div class="row pb-2" *ngIf="!displayEditForm; else editButtons">
      <div class="col-5 pt-3">
        <app-ae-button routerLink="/iqdecisions" [buttonText]="messages.backBtnText" class="mr-2" buttonType="primary"
          type="button"></app-ae-button>
        <app-ae-button (click)="publishUnpublishIQDecisions()" [disabled]="disableUnpublishedButton()"
          [buttonText]="publishUnpublishButtonText" class="mr-2" buttonType="secondary" type="button"
          *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['UPDATE'])"></app-ae-button>
        <app-ae-button [buttonText]="messages.editBtnText" class="mr-2" buttonType="secondary" type="button"
          (click)="enableEditForm()" [disabled]="disableEditButton()"
          *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['UPDATE'])"></app-ae-button>
        <app-ae-button type="button" buttonText="Delete" (click)="showDeleteIQDecisionsAlert()"
          [disabled]="disableDeleteButton()" *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['DELETE'])">
        </app-ae-button>
      </div>
    </div>
    <ng-template #editButtons>
      <div class="row pb-2">
        <div class="col-5 pt-3">
          <app-ae-button (click)="saveUpdatedIQDesicion()" [buttonText]="messages.saveChangesBtnText" class="mr-2" buttonType="primary"
            type="button" [disabled]="disableSaveButton()"
            *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['UPDATE'])"></app-ae-button>
          <app-ae-button (click)="cancelEdit()" [buttonText]="messages.cancelBtnTxt" class="mr-2" buttonType="secondary"
            type="button"></app-ae-button>
          <app-ae-button type="button" buttonText="Delete" (click)="showDeleteIQDecisionsAlert()"
            [disabled]="disableDeleteButton()" *ngIf="authorizationService.isAllowed('CONFIGURED_RECOMMENDATION', ['DELETE'])">
          </app-ae-button>
        </div>
      </div>
    </ng-template>
  </section>
</app-base-template>
