import { Messages } from '../message';

export class EntityType {

  public static PACKAGED = Messages.packagedEntityType;
  public static CUSTOM = Messages.customEntityType;

  public static getDisplayName(type: string): string {
    switch (type) {
      case EntityType.PACKAGED:
        return Messages.systemEntityType;
      case EntityType.CUSTOM:
        return Messages.userDefinedEntityType;
      default:
        return '';
    }
  }

}