<app-base-template>
  <div class="container-fluid object-page-header pt-4">
    <div class="row align-items-center py-sm-3">
      <div class="col">
        <h1 class="text-style-1 mb-0">Entity States</h1>
      </div>
      <div class="col text-right" *ngIf="authorizationService.isAllowed('CFG_ENT_STATE', ['CREATE'])">
        <app-ae-button buttonType="primary" buttonText="Create Entity State" routerLink="entitystates/custom/configure/id/Custom/add" [disabled]="!parentContext.isParentContextSet()"></app-ae-button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!parentContext.isParentContextSet()">
    <div class="container-fluid">
      <div class="row">
        <div class="col text-center">
          <p class="text-style-muted-3 m-0">Please set up a Parent in Settings to create a program</p>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="container-fluid pt-5">
    <ng-container *ngIf="configuredEntityStateTableList">
      <div class="row">
        <div class="col col-sm-6 col-xl-3">
          <app-ae-table-search
            label="Search Entity States"
            placeholder="Search by name"
            (searchEmitter)="handleSearch($event)">
          </app-ae-table-search>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-ae-table [isDataLoading]="isPageLoading" [configuredTableData]="configuredEntityStateTableFilteredData"
            [properties]="properties">
          </app-ae-table>
        </div>
      </div>
    </ng-container>
  </div>
</app-base-template>

<ng-template #dateTemplateColumn let-entitystate>
  {{entitystate.modifiedDate | date: 'MM/dd/yyyy h:mmaaaaa\'m\''}}
</ng-template>

<ng-template #expandableRowsTemplate let-entitystate>
  <p>{{ entitystate.description }}</p>
</ng-template>
