import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { environment } from 'src/environments/environment';
import { AENotificationComponent } from '../ae-notification/ae-notification.component';

@Component({
  selector: 'app-base-template',
  templateUrl: './base-template.component.html'
})
export class BaseTemplateComponent implements AfterViewInit {

  @ViewChild('notification', { static: true })
  public notificationComponent: AENotificationComponent;

  constructor(
    public popupService: PopupMessageService,
    private parentContextService: ParentContextService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    /** Capturing router events and forwarding them to Google Analytics **/
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('create', environment.trackingId, 'auto');
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
    this.initParentContext();
  }

  ngAfterViewInit() : void {
    if (this.popupService.getShowNotification()) {
      this.popupService.setShowNotification(false);
      this.popupService.emitNextPage();
    }
  }

  initParentContext() {
    let parentContext = '';
    this.parentContextService.parentContext = new BehaviorSubject<string>(
      parentContext
    );
    this.route.queryParams.subscribe(value => {
      if (value[ParentContextService.PARENT_ID]) {
        parentContext = value[ParentContextService.PARENT_ID];
        this.parentContextService.setParentContext(parentContext);
        this.parentContextService.parentContext.next(parentContext);
        this.parentContextService.setParentId(parentContext);
      } else if (
        this.parentContextService.getParentContext() !== null
        && this.parentContextService.getParentContext() !== undefined
      ) {
        parentContext = this.parentContextService.getParentContext();
        this.parentContextService.parentContext.next(parentContext);
        this.parentContextService.setParentId(parentContext);
      }
    });
  }

  skipToMain() {
    event.preventDefault();
    location.hash = 'mainContent';
  }

}
