
import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProgramWarningOptions } from './program-warning-options';

@Injectable()
export class ProgramWarningState {

  options: ProgramWarningOptions;
  modal: NgbModalRef;

}