<app-ae-modal-info></app-ae-modal-info>
<app-coreui-modal-warning></app-coreui-modal-warning>
<div *ngIf="apiCallCount==0"  class="container-fluid ml-2">
  <form [formGroup]="configuredEntityForm" (change)="setFormValueChanges()">
    <app-ribbon *ngIf="hasOperationFailed()"
      ribbonStyle="ribbon-alert" 
      [ribbonMessage]="getOperationFailureMessage()" 
      ribbonHasButton="true" 
      [buttonText]="getAssociationsBtnTxt()" 
      (buttonClick)="openAssociationsModal()">
    </app-ribbon>
    <div>
      <p class="text-style-2">Properties</p>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-12 col-md-6" (keyup)="setFormValueChanges()">
          <label for="name">Name</label>
          <input type="text" class="form-control" formControlName="name" id="name" placeholder="Entity State Name"
            required="required">
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="configuredEntityStateType=== entityType.CUSTOM && operation === operationType.EDIT">
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="name">Entity State Id</label>
          <input type="text" class="form-control" value={{configuredEntityStateId}} disabled>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-12 col-md-6">
          <label for="description">Description (optional)</label>
          <textarea class="form-control" formControlName="description" id="description" rows="3"></textarea>
        </div>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-md-auto px-0">
        <p class="text-style-3">Record Time-to-Live</p>
      </div>
      <div class="tooltip-content px-1">
        <ng-template #tooltip>
          Setting a time-to-live will determine <br>
          how long to retain this entity state's <br>
          records before they are deleted
        </ng-template>
        <a [coreUITooltip]="tooltip" position="right">
          <i coreUIIcon="help" class="u-colorBlue50 u-fontSizeInherit" scale="1"></i>
        </a>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-md-2">
          <label for="timeToLive">Record expire after</label>
          <input type="text" class="form-control" formControlName="timeToLive" id="timeToLive">
        </div>
        <div class="col-md-auto pt-4 px-0 mt-2">
          <p>Days</p>
        </div>
      </div>
      <div class="text-color-warning" *ngIf="configuredEntityForm.controls.timeToLive.errors?.min ||
          configuredEntityForm.controls.timeToLive.errors?.max">
        Please enter a number between 1 and 365
      </div>
      <div class="text-color-warning" *ngIf="configuredEntityForm.controls.timeToLive.value > 30 &&
          configuredEntityForm.controls.timeToLive.value < 365">
        The record time-to-live setting exceeds 30 days. Please <br>
        make sure that this entity state does not contain any PII <br>
        (Personally identifiable information).
      </div>
    </div>
  </form>
</div>