export class InputSettingDetails {

  value: string;
  valueType: string;
  valueSubtype: string;

}


export class DelayInputDetails {

  delay: string;
  delayType: string;
  delaySubtype: string;

}