<fieldset>
  <h2>{{durationTitle}}</h2>
  <ng-container [formGroup]="parentForm.controls.durationControls">
    <div class="row align-items-center">
      <div class="col-auto">
        <label [for]="index + '_daysSelector'">Days</label>
      </div>
      <div class="col px-0">
        <coreui-select [id]="index + '_daysSelector'" formControlName="days" (blur)="validateDuration()">
          <coreui-option *ngFor="let day of dayList" [value]="day">
            {{day}}
          </coreui-option>
        </coreui-select>
      </div>
      <div class="col-auto">
        <label [for]="index + '_hoursSelector'">Hours</label>
      </div>
      <div class="col px-0">
        <coreui-select [id]="index + '_hoursSelector'" formControlName="hours" (blur)="validateDuration()">
          <coreui-option *ngFor="let hour of hourList" [value]="hour">
            {{hour}}
          </coreui-option>
        </coreui-select>
      </div>
      <div class="col-auto">
        <label [for]="index + '_minutesSelector'">Minutes</label>
      </div>
      <div class="col pl-0">
        <coreui-select [id]="index + '_minutesSelector'" formControlName="minutes" (blur)="validateDuration()">
          <coreui-option *ngFor="let minute of minuteList" [value]="minute">
            {{minute}}
          </coreui-option>
        </coreui-select>
      </div>
    </div>
  </ng-container>
</fieldset>