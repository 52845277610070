import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ae-loading-indicator',
  templateUrl: './ae-loading-indicator.component.html'
})
export class AELoadingIndicatorComponent {

  @Input() public isLoading: boolean;
  @Input() public label: string;
  @Input() public type: string;

  constructor() { }

}
