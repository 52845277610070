import { Injectable, ViewChild, Component, OnInit } from '@angular/core';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ActivatedRoute } from '@angular/router';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { PackagedProgramRule } from 'src/app/shared/models/packaged-program-rule';
import { Configuration } from 'src/app/shared/models/configuration';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { ProgramSharedDataService } from '../program-shared-data.service';
import { ProgramData } from 'src/app/shared/models/program-data';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ProgramsHeaderComponent } from '../programs-header/programs-header.component';
import { ConfiguredEvent, ConfiguredEventId } from '../../../shared/models/configured-event.model';
import { EventService } from '../../../shared/services/event.service';
import { LoginServiceLocator } from '../../../shared/services/login.service-locator';

@Component({
  selector: 'app-programs-configuration',
  templateUrl: './programs-configuration.component.html'
})
@Injectable()
export class ProgramsConfigurationComponent implements OnInit {

  @ViewChild(ProgramsHeaderComponent, { static: true }) header: ProgramsHeaderComponent;
  eventsource: string;
  operation: string;
  configuredProgramId: string;
  eventType: string;
  isEdit = false;
  public programsNavTabs = [];
  rules: PackagedProgramRule[];
  tenantProgram = new ConfiguredProgram();
  tenantConfiguration = new Configuration();
  parentId: string;
  apiCallCount = 0;
  public programData: ProgramData;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);


  constructor(
    private programService: ProgramService,
    private loginServiceLocator: LoginServiceLocator,
    private eventService: EventService,
    private parentContextService: ParentContextService,
    private route: ActivatedRoute,
    private programDataService: ProgramSharedDataService) {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.eventsource = route.snapshot.paramMap.get('eventsource');
      this.operation = route.snapshot.paramMap.get('action');
      this.configuredProgramId = route.snapshot.paramMap.get('id');
    });
    this.programData = new ProgramData();
    this.initProgramNavTabs();
  }

  ngOnInit() {
    this.parentId = this.parentContextService.getParentContext();
    this.tenantConfiguration.configuredActionSettingList = '';
    this.rules = new Array<PackagedProgramRule>();
    this.tenantProgram.configuration = this.tenantConfiguration;
    this.programData.eventSource = this.eventsource;
    this.programData.operation = this.operation;
    if (this.operation == 'add') {
      this.tenantProgram.configuration.eventSource = this.eventsource;
      this.tenantProgram.programType = 'PACKAGED';
    } else if (this.operation == 'edit' || this.operation == 'validate') {
      this.doGetTenantSubscription();
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
    this.tenantConfiguration.eventSource = this.eventsource;
    this.programData.parentId = this.parentId;
    this.programData.programId = this.configuredProgramId;
    this.programData.tenantProgram = this.tenantProgram;
    this.programData.apiCallCount = 1;
    this.programDataService.setProgramData(this.programData);
    this.programDataService.isDataSaved = false;
    this.programDataService.isDeleteOperation = false;
    this.programDataService.header = this.header;
  }

  doGetTenantSubscription() {
    this.programService.getConfiguredProgramDetails(this.parentId, this.configuredProgramId)
      .pipe(takeUntil(this.destroyed$)).subscribe(
        res => {
          this.tenantProgram = new ConfiguredProgram(res);
          this.tenantConfiguration = this.tenantProgram.configuration;
          this.rules = JSON.parse(this.tenantProgram.configuration.rules);
          this.programData.tenantProgram = this.tenantProgram;
          this.programData.rules = this.rules;
          this.programData.programId = this.tenantProgram.id;
          for (const rule of this.programData.rules) {
            this.initPackagedEventSchema(this.programData.tenantProgram.configuration.eventSource, rule.ruleId.split('_x_')[1]);
          }
        },
        (error: any) => {
        });
  }

  public initPackagedEventSchema(eventSourceName: string, eventName: string): void {
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = eventSourceName;
    configuredEventId.eventName = eventName;
    this.eventService.getPackagedEvent(this.parentId, configuredEventId).pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        const configuredEvent = new ConfiguredEvent(value.result);
        this.programDataService.addToEventSchemaMap(configuredEvent);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private initProgramNavTabs(): void {
    this.programsNavTabs = [
      {
        text: 'Define',
        route: this.eventsource !== 'eventSource' ? './rule' : './program-rule'
      },
      {
        text: 'Schedule',
        route: './schedule'
      },
      {
        text: 'Simulate',
        route: './simulate'
      }
    ];
  }
}
