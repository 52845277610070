export const Messages = {
  invalidDataInputOrNetworkError: 'This destination cannot be reached at this time. If this error persists, please contact your support team. ',
  credentialsAreInvalid: 'The credentials you have entered are invalid. Please check your username or password and try again.',
  actionDestinationNameRequired: 'Please enter a name for your action destination',
  successfulConnection: 'Successful connection!',
  duplicateName: 'This action destination name already exists. Please enter a different name.',
  emptyActionDestinationName: 'Please enter a name for your action destination',
  emptyActionDestinationDescription: 'Please enter a description for your action destination',
  emptyAuthUrl: 'The AUTH_METHOD link you have entered is invalid. Please check your URL and try again.',
  emptyUserId: 'Please enter a client username',
  emptyPassword: 'Please enter a client password',
  emptyClientId: 'Please enter a client ID',
  emptyClientSecret: 'Please enter a client secret',
  emptyGrantType: 'Please select a grant type',
  emptyScope: 'Please enter a scope',
  emptyUserGroup: 'Please select User Group(s)',
  userAuthError: 'User Authentication Failure.',
  invalidBUInfoError: 'Business Unit Information Invalid.',
  invalidDataError: 'Invalid Data Input or Network Error.',
  companyIDMappedError: 'CompanyIDs are associated with another BusinessUnitId.',
  deleteTestDataOnDeletingRule: 'Deleting a rule may impact saved test recipient data. If the data is associated with the deleted rule, it will also be deleted. This action cannot be undone.',
  deleteRuleConfirmation: 'Are you sure you want to delete this rule?',
  invalidPlaceholderSyntaxErrorMsg: 'Input contains invalid placeholder syntax. Placeholders must be alphanumeric and not contain spaces. Hyphens and underscores are allowed.',
  invalidEmailPlaceholderSyntaxErrorMsg: 'Input contains invalid placeholder syntax. Placeholders must be alphanumeric and not contain spaces. These characters are allowed: . @ - _',
  emptyUserName: 'Please enter a username',
  emptyRegion: 'Please enter a region',
  emptyAccessKey: 'Please enter access key',
  emptySecretAccessKey: 'Please enter secret access key',
  ruleOperationFailureMessage: 'This rule is being used in one or more active programs. To edit or delete this rule, you must first remove it from all active programs or set those programs to inactive.',
  actionOperationFailureMessage: 'This action is being used in one or more active rules or programs. To edit or delete this action, you must first remove it from all active programs and rules or set the programs and rules to inactive.',
  entityOperationFailureMessage: 'This entity state is being used in one or more active rules or programs. To edit or delete this enity state, you must first remove it from all active programs and rules or set the programs and rules to inactive.',
  eventOperationFailureMessage: 'This event is being used in one or more active rules or programs. To edit or delete this event, you must first remove it from all active programs and rules or set the programs and rules to inactive.',
  exclusionOperationFailureMessage: 'This exclusion is being used in one or more Programs. To edit or delete this exclusion, you must first remove it from all associated programs.',
  actionDestinationOperationFailureMessage: 'This action destination is being used in one or more actions. To edit or remove this destination, you must first delete all actions that use this destination.',
  eventSourceOperationFailureMessage: 'This event source provider is being used in one or more events, actions, active rules, or programs. To remove this provider, you must first delete all events and actions that use this provider, and deactivate or delete rules and programs.',
  saveEntityStateSuccessMessage: 'Entity state created successfully',
  createEntityStateSuccessMessage: 'Entity state created successfully',
  createEntityStateErrorMessage: 'Error while creating entity state.',
  saveEntityStateErrorMessage: 'Error while saving entity state.',
  saveEventErrorMessage: 'Error while adding event',
  updateEventErrorMessage: 'Error while updating event',
  nameLimitMessage: 'Name cannot exceed 100 characters.',
  eventNameValidationMessage: 'Please enter a unique event name. The name must contain only alphanumeric characters.',
  eventNameDuplicateValidationMessage: 'This event name already exists. Please enter a unique event name. The name must contain only alphanumeric characters.',
  eventSchemaValiadtionMessage: 'Please enter a schema for this event',
  eventSchemaJSONValidationMessage: ' Your event schema is invalid. Please check the schema definition for syntax errors.',
  descriptionLimitMessage: 'Description cannot exceed 250 characters.',
  createProgramSuccessMessage: 'Program successfully created',
  createProgramErrorMessage: 'Error while creating program.',
  updateProgramSuccessMessage: 'Program successfully updated',
  updateProgramErrorMessage: 'Error while creating program.',
  validateProgramSuccessMessage: 'Program successfully validated',
  validateProgramErrorMessage: 'Error while validating program.',
  saveCustomSettingsSuccessMessage: 'Custom settings saved successfully',
  saveCustomSettingsErrorMessage: 'Error while saving custom settings',
  programSimulationEventRecordsErrorMessage: 'Please add event records to your events',
  programSimulationNotActiveErrorMessage: 'Please set your program to either Test or Active status to run this simulation.',
  updateRuleErrorMessage: 'Error while updating rule',
  updateUserGroupErrorMessage: 'Error while updating User Group',
  addActionsErrorMessage: 'Please add actions to rule',
  saveModeAndDatasetSuccessMessage: 'Saved successfully',
  saveModeAndDatasetFailureMessage: 'Error in saving Program mode and dataset',
  datasetValidationErrorMessage: 'Error in validating dataset',
  minSearchErrorMsg: 'Please enter at least three characters to search programs',
  requiredFields: 'Please complete the required fields',
  incompleteRule: 'Please complete your rule',
  associationRequestErroredMessage: 'Error getting associations.',
  emptyAssociationsMessage: 'Another user has removed or modifed this {object type}’s association. Please close this modal to see the latest updates.',
  loadingAssociationsMessage: 'Loading Associations',
  eventSourceCompanyOperationFailureMessage: 'This company is being used in one or more programs. To remove this provider, you must first deactivate or delete all programs that use this company.',
  actionDestinationBusinessUnitOperationFailureMessage: 'This business unit is being used in one or more programs. To edit or remove this destination, you must first deactivate or delete all programs that use this destination.',
  actionDestinationBusinessUnitCompanyAlreadyAssociated: 'CompanyIDs are associated with another BusinessUnitId',
  deleteEventModalWarningMessage: 'Deleting this event will affect the programs and rules that it is being used in. This cannot be undone.',
  deleteEventModalConfirmationMessage: 'Are you sure you want to delete this event?',
  deleteConfiguredRecommedationModalConfirmationMessage: 'Are you sure you want to delete this package?',
  deleteEntityContentModalConfirmationMessage: 'Are you sure you want to delete this RMN content?',
  deleteEntityContentActionMessage: 'This action will permanently delete this RMN content',
  deleteEntityContentSuccessMessage: 'RMN content successfully deleted',
  saveRule: 'Saving rule',
  enterUniqueName: 'Please enter a unique name',
  enterUniqueRoleName: 'Please enter a unique role name',
  selectEventSource: 'Please select an event source provider',
  selectCompanyName: 'Please select a company name',
  selectEvent: 'Please select an event',
  enterValidS3BucketPath: 'Please enter a valid S3 bucket path',
  enterTimeIncrement: 'Please enter a time increment greater than 0',
  enterLesserHoursIncrement: 'Please enter a number between 1 and 23',
  enterLesserDaysIncrement: 'Please enter a number between 1 and 182',
  enterLesserWeeksIncrement: 'Please enter a number between 1 and 26',
  selectTimePeriod: 'Please select a time period',
  enterValidDateTime: 'Please enter a date in the format of MM/DD/YYYY, HH:MM',
  selectDateTimeInfutureAfterStartDateTime: 'Please select a future date and time that is 1 hour after your selected start date',
  selectDateTimeInfuture: 'Please select a future date and time',
  selectDateTimeWithinOneYear: 'Please select date and time within 1 year',
  loadingConfiguredBatchExtract: 'Loading batch extracts',
  deletingConfiguredBatchExtract: 'Deleting your batch extract',
  deletedConfiguredBatchExtract: 'Batch extract was successfully deleted',
  unableToDeleteConfiguredBatchExtract: 'We are unable to delete this batch extract at this time',
  savingConfiguredBatchExtract: 'Saving your batch extract',
  savedConfiguredBatchExtract: 'Batch extract successfully saved',
  unableToSaveConfiguredBatchExtract: 'We are unable to save this batch extract at this time',
  wholeNumberOrDecimalWithTwoPrecision: 'Please enter a whole number or decimal number with two precision.',
  wholeNumber: 'Please enter a whole number.',
  deletedUserGroups: 'User Group was successfully deleted',
  deletedRole: 'Role was successfully deleted',
  unableToDeleteUserGroups: 'We are unable to delete this User Group at this time',
  unableToDeleteRole: 'We are unable to delete this Role at this time',
  unableToGetRoles: 'We are unable to get roles at this time',
  unableToGetRole: 'We are unable to get role at this time',
  userGroupMandatoryFieldErrorMessage: 'Please complete the required fields.',
  loadingUserGroupMessage: 'loading user group',
  deleteUserGroupWarningMessage: 'Deleting this user group will impact the access permissions of the associated users. This action cannot be undone.',
  deleteUserGroupWarningMessage2: 'Are you sure you want to delete this user group?',
  deleteRoleWarningMessage: 'Deleting this role will also remove its association with any user groups and potentially impact access for associated users. This action cannot be undone.',
  deleteRoleWarningMessage2: 'Are you sure you want to delete this role?',
  apiUser: 'API User',
  uiUser: 'UI User',
  createNewUser: 'Create New User',
  nameRequired: 'Please enter a name',
  idRequired: 'Please enter an ID',
  emailRequired: 'Please enter an email address',
  secretQuestionRequired: 'Please enter a secret question',
  secretAnswerRequired: 'Please enter the secret answer',
  passwordRequired: 'Please enter a unique password',
  invalidPassword: 'Please enter an 8 character password with at least one uppercase letter, lowercase letter, number, and special character',
  enterName: 'Enter a name',
  userNameRequired: 'Please enter a username',
  startDateRequired: 'Please enter a start date',
  endDateRequired: 'Please enter an end date or select \'No End Date\'',
  enterValue: 'Please enter a value',
  selectValue: 'Please select a value',
  rolesRequest: 'Getting roles',
  roleCreateRequest: 'Saving your role',
  rolesRequestErrorMessage: 'Error getting roles',
  roleCreateErrorMessage: 'We are unable to save this role at this time',
  roleCreateSuccessMessage: 'Role successfully saved',
  roleEditErrorMessage: 'We are unable to edit role at this time',
  errorOnPage: 'Please configure the exclusion and fix the error on this page.',
  unsavedChanges: 'Unsaved Changes',
  leaveWithoutSaving: 'Are you sure you want to leave without saving?',
  discardChanges: 'Yes, Discard Changes',
  keepEditing: 'No, Keep Editing',
  deleteBtnTxt: 'Delete',
  cancelBtnTxt: 'Cancel',
  removeEventSourceProvider: 'Remove Event Source Provider',
  confirmRemoveEventSourceProvider: 'Are you sure you want to remove this event source provider?',
  deleteCompany: 'Delete Company',
  confirmDeleteCompany: 'Are you sure you want to delete this company?',
  removeActionDestination: 'Remove Action Destination',
  confirmRemoveActionDestination: 'Are you sure you want to remove this action destination?',
  removeActionDestinationConsequence: 'Removing an action destination will also remove all business units added to this destination.',
  deleteBusinessUnit: 'Delete Business Unit',
  confirmDeleteBusinessUnit: 'Are you sure you want to delete this Business Unit?',
  deleteConfiguredProgram: 'Delete Program',
  deleteConfiguredEvent: 'Delete Event',
  deleteConfiguredRecommendation: 'Delete Package',
  deleteEntityContent: 'Delete RMN Content',
  deleteConfiguredAction: 'Delete Action',
  confirmDeleteConfiguredAction: 'Are you sure you want to delete this action?',
  deleteEntityState: 'Delete Entity State',
  deleteConfiguredRule: 'Delete Rule',
  deleteBatchExtract: 'Delete Batch Extract',
  deleteBatchExtractConsquence: 'Deleting a batch extract cannot be undone.',
  confirmDeleteBatchExtract: 'Are you sure you want to delete this batch extract?',
  resetToDefaultSettings: 'Reset to Default Settings',
  resetToDefaultSettingsConsequence: 'This will reset all non-default selections to the default selection. This can not be undone.',
  confirmResetToDefaultSettings: 'Are you sure you want to reset to default settings?',
  deleteRole: 'Delete Role',
  deleteUserGroups: 'Delete User Groups',
  associationsTitle: 'Associations',
  continue: 'Yes, Continue',
  testStatus: 'Test Status',
  liveStatus: 'Live Status',
  testModeMsg: 'Setting this program to Test will send actions only to the email addresses in your test recipient list.',
  liveModeMsg: 'Setting this program to Live will send actions to all customers who recieve the program’s events.',
  confirmTestMode: 'Are you sure you want to set this program to Test?',
  confirmLiveMode: 'Are you sure you want to set this program to Live?',
  activateProgram: 'Activate Program',
  deactivateProgram: 'Deactivate Program',
  deactivateProgramFailure: 'Program deactivation not successful',
  closeTxt: 'Close',
  errorTxt: 'Error',
  loseUnsavedChanges: 'You will lose all unsaved changes.',
  loadingAssociations: 'Loading Associations',
  viewAssociationsBtnTxt: 'View Associations',
  maxLength: 'Please enter a limit between 1 and 100',
  selectAnEventPlaceholderTxt: 'Select an event',
  eventsLabel: 'Events',
  userGroupsLabel: 'User Groups',
  selectUserGroupsPlaceholderTxt: 'Select user groups',
  rolesLabel: 'Roles (optional)',
  optionalLabel: '(optional)',
  selectRolesPlaceholderTxt: 'Select roles',
  exclusionSaved: 'Exclusion successfully saved.',
  exclusionSaveFailed: 'We are unable to save this exclusion at this time',
  exclusionDeleted: 'Exclusion successfully deleted.',
  exclusionDeleteFailed: 'We are unable to delete this exclusion at this time',
  deleteExclusion: 'Delete exclusion',
  deleteExclusionConsquence: 'Deleting an exclusion cannot be undone.',
  confirmDeleteExclusion: 'Are you sure you want to delete this exclusion?',
  actionConfigurationValidationErrorTxt: 'contains invalid placeholder syntax. Placeholders must be alphanumeric and not contain spaces. Hyphens and underscores are allowed.',
  unauthorizedUser: 'User is not authorized to perform this request',
  externalSystemLoginError: 'User is not authorized. Please contact support.',
  externalSystemUrlError: 'Unable to load Events. Please contact support.',
  externalSystemSessionError: 'User session is expired. Please login again.',
  incompleteRuleError: 'Please check all the values entered before saving',
  incompleteAttributeComparison: 'Please add another attribute to compare',
  packagedEntityType: 'Packaged',
  customEntityType: 'Custom',
  systemEntityType: 'System',
  userDefinedEntityType: 'User-defined',
  externalUrlNotFound: 'Product switcher is not configured. Please contact administrator.',
  // Parent Creator
  parentNameRequiredError: 'Please enter a unique client name. The client name may contain alphanumeric and spaces.',
  parentNameAlreadyExistsError: 'This client name already exists. Please enter a unique client name. The name may contain alphanumeric and spaces.',
  parentIdAlreadyExistsError: 'This client id already exists. Please enter a unique client id. The id must contain only alphanumeric characters.',
  parentsRequestError: 'Error getting clients',
  parentIdRequiredError: 'Please enter client id. The id must contain only alphanumeric characters.',
  parentNameLabel: 'Client Name',
  parentIdLabel: 'Client ID',
  verticalTypeLabel: 'Vertical Type',
  reportingEnabledLabel: 'Reporting Enabled (optional)',
  onBoardingParentTxt: 'Onboarding Client',
  parentDetailsTxt: 'Client Details',
  addParentName: 'Add a unique name for the client',
  addParentId: 'Add a unique client id',
  saveBtnTxt: 'Save',
  parentsBackBtnTxt: 'Back to Clients',
  parent: 'Client',
  parents: 'clients',
  onlyAlphanumericCharactersError: 'The name must contain only alphanumeric characters',
  // IQ Desicions
  unpublishPackage: 'Unpublish Package',
  confirmUnpublishPackage: 'Are you sure you want to unpublish this package?',
  iqDecisions: 'IQ Decisions',
  unpublishConfiguredRecommendation : 'Edit Package',
  packageName: 'Package Name',
  packages: 'Packages',
  companyError: 'Please select a company',
  packageTypeError: 'Please select a package type',
  packageNameError: 'Please enter a package name',
  packageLevelError: 'Please select a category level',
  maxItemError: 'Max Items to Return must be greater than 0',
  modelsSubHeader: 'Models',
  primaryModel: 'Primary Model',
  fallbackModel: 'Fallback Model',
  publishBtnText: 'Publish',
  unpublishBtnText: 'Unpublish',
  saveForLaterBtnText: 'Save for Later',
  singleModelPackage: 'New Single-Model Package',
  multiModelPackage: 'New Multi-Model Package',
  packageInfoSubHeader: 'Package Info',
  modelParameterSubheader: 'Model Parameters',
  selectModel: 'Select Model',
  parentIdAndCompanyIdNotFound: 'The selected Company is not valid for package creation. Please select a valid Company.',
  selectModelHeaderMessage: 'To compare performance of one package model against another model, a set of fixed options, or a randomized control group, select your primary and fallback models for your defined group(s).',
  iQDecisionsWarningMessage : 'This package is published, Updating the package will unpublish it.',
  iQDecisionsConfirmationMessage : 'Do you want to continue to update the package?',
  newGroupButtonText: '+ New Group',
  packageNameRequiredValidationMessage: 'Please enter the package name',
  packageNameMaxLengthValidationMessage: 'Package name cannot be more than 150 characters',
  packageNameUniqueValidationMessage: 'Please enter a unique package name',
  sharePercentageMessage: 'Share percentages must sum to 100.',
  primaryModelValidationMessage: 'Please select a primary model for the groups.',
  primaryModelValidationperGroup: 'Please select a primary model.',
  uniqueGroupNameMessage: 'Group names must be unique.',
  shareValuesEmptyMessage: 'Share values cannot be empty',
  sharesMaxValueMessage: 'Share values cannot be more than 100',
  sharesMinValueMessage: 'Share values cannot be less than 1',
  groupNameEmptyMessage: 'Group name cannot be empty',
  groupNameMaxValueMessage: 'Group name cannot more than 75 characters',
  programUsageSubHeader: 'Program Usage',
  historySubHeader: 'History',
  backBtnText: 'Back',
  editBtnText: 'Edit',
  updateBtnText: 'Update',
  company: 'Company',
  packageType: 'Package Type',
  categoryLevel: 'Category Level',
  MaxItemsReturn: 'Max Items to Return',
  description: 'Description',
  descriptionValidation: 'Description cannot exceed 500 characters.',
  publication: 'Initial Publication',
  lastModification: 'Last Modification',
  lastLogin: 'Last Logged In',
  currentVersion: 'Current Version',
  errorPublishingRecommendation: 'Error publishing recommendation',
  errorDeletingRecommendation: 'Error deleting recommendation',
  errorUnpublishingRecommendation: 'Error unpublishing recommendation',
  errorDeletingPackage: 'Error in deleting package',
  errorDeletingEntityContent: 'Error in deleting entity content',
  saveChangesBtnText: 'Save Changes',
  modelParametersMessage: 'Model Parameters',
  inputMessage: 'Input',
  itemsPerInputLevelMessage: 'Items per Input Level',
  selectInputText: 'Select Input',
  selectCategoryLevelOption: 'Select Category Level',
  maxItemReturnedRequiredMessage: 'Please enter a value for Max Items to Return',
  maxItemReturnedMinValueMessage: 'Minimum value entered must be greater than 0',
  maxItemReturnedMaxValueMessage: 'Maximum value entered must be less than 10000',
  itemsPerInputLevelRequiredMessage: 'Please enter a value for Items per Input Level',
  itemsPerInputLevelMinValueMessage: 'Minimum value entered must be greater than 0',
  inputRequiredMessage: 'Please select a value for input',
  recommendationNameExistError: 'This package name already exists. Please enter a unique package name',
  recommendationDesciption: 'Please enter a description',
  addDescriptionLabel: 'Add description',
  selectPackageTypeOption: 'Select Package Type',
  selectCompanyOption: 'Select Company',
  successfullyPublishedMessage: 'successfully published',
  successfullyUnPublishedMessage: ' successfully unpublished',
  // RMN
  companyId: 'Company ID',
  contentId: 'Content ID',
  companyIdPlaceHolder: 'Enter Company ID',
  contentIdPlaceHolder: 'Enter a unique Content ID',
  content: 'Content',
  contentPlaceHolder: 'Add Image File Here',
  contentUrl: 'Content URL',
  budget: 'Budget',
  budgetPlaceHolder: 'Enter Budget',
  adminUserGroup: 'System UserGroups Overview',
  expiration: 'Expiration',
  uploadValidImage: 'Please upload a valid image file',
  clickThroughUrl: 'Click Through URL',
  contentUrlPlaceHolder: 'Enter Content URL',
  clickThroughUrlPlaceHolder: 'Enter Click Through URL',
  clickToExpand: 'Click to expand',
  companyIdRequired: 'Please enter company id',
  campaignIdRequired: 'Please enter campaign id',
  bannerTypeRequired: 'Please enter banner type',
  budgetRequired: 'Please enter numerical value only',
  imageRequired: 'Please add image',
  imageUrlRequired: 'Please enter the image URL. URLs should start with ‘http://’ or ‘https://’ and contain at least one character.',
  imageUrlError: 'Your image URL is invalid. Please check your URL for errors. URLs should start with ‘http://’ or ‘https://’ and contain atleast one character.',
  contentNameRequired: 'Please enter content name',
  contentIDRequired: 'Please enter unique content id',
  contentIDExists: 'This Content Id already exists',
  invalidExpiryDate: 'Please enter a future date',
  contentTypeRequired: 'Please enter content type',
  contentUrlRequired: 'Please enter content URL',
  contentUrlError: 'Your content URL is invalid. Please check your URL for errors. URLs should start with ‘http://’ or ‘https://’ and contain atleast one character.',
  clickThroughUrlRequired: 'Please enter clickthrough URL. URLs should start with ‘http://’ or ‘https://’ and contain at least one character.',
  clickThroughUrlError: 'Your clickthrough URL is invalid. Please check your URL for errors. URLs should start with ‘http://’ or ‘https://’ and contain atleast one character.',
  parentSettingMessageForEntityContent: 'Please set up a Parent in Settings to get RMN contents',
  entityContentHomePageHeader: 'RMN Content',
  supportPageNavEntityContentLabel: 'RMN Content',
  entityContentUpdateSuccessMessage: 'RMN content successfully updated.',
  entityContentInsertSuccessMessage: 'RMN content successfully inserted.',
  insertRMNDataLabel: 'Insert RMN content',
  updateRMNDataLabel: 'Update RMN content',
  createRMNContentButtonText: 'Create RMN Content',
  backRMNContentText: 'Back to RMN Content',
  insertButton: 'Insert',
  updateButton: 'Update'
};