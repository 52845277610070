import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { Parent } from 'src/app/shared/models/parent';
import { LoginCallback } from 'src/app/shared/services/cognito.login.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { LoginServiceLocator } from 'src/app/shared/services/login.service-locator';

@Component({
  selector: 'app-parent-switcher',
  templateUrl: './parent-switcher.component.html',
  styleUrls: ['./parent-switcher.component.scss']
})
export class ParentSwitcherComponent implements OnInit, OnDestroy {

  parentsList: Parent[];
  filteredParentList: Parent[];
  selectedParent: Parent;
  displayText: string;
  searchBarText: string;
  isAlive = new Subject<boolean>();
  private selectedParentId: string;

  constructor(
    private router: Router,
    private parentContextService: ParentContextService,
    private authorizationService: AuthorizationService,
    public loginServiceLocator?: LoginServiceLocator
  ) {
    this.isAlive.next(true);
  }

  ngOnInit() {
    this.displayText = 'Tenant / Client Switcher';
    this.parentsList = this.parentContextService.getParentListContext();
    if (this.parentsList) {
      this.parentsList.sort((previous: Parent, next : Parent) => (previous.name < next.name ? -1 : 1));
    }
    this.parentContextService.parentContext.subscribe(value => {
      this.selectedParentId = value;
    });
    if (this.selectedParentId && this.parentsList) {
      const parent = this.parentsList.filter(p => p.id === this.selectedParentId)[0];
      this.displayText = parent.name;
      this.filteredParentList = this.parentsList;
    } else if (!this.authorizationService.isAuthorizationEnabled()) {
      this.fetchParentsList();
    }
  }

  public selectParent(parent: Parent): void {
    this.selectedParent = parent;
    this.parentContextService.setParentContext(this.selectedParent.id);
    this.parentContextService.setParentVerticalType(this.selectedParent.verticalType);
    if (!this.loginServiceLocator.getLoginService().isLoggedIn()) {
      this.loginServiceLocator.getLoginService().logout();
    }
    this.authorizationService.initializePermissionsOnParentSwitch(this, this.loginServiceLocator.getLoginService().getUserName());
  }

  public authorizationSuccessful(routeTo: string, loginCallback: LoginCallback) : void {
    console.log('authorizationSuccessful result: ' + routeTo);
    this.configureParentSelection(routeTo);
  }

  public authorizationFailed(message: string, loginCallback: LoginCallback) : void {
    console.log('authorizationFailed result: ' + message);
    this.propogateNoParentConfigured();
  }

  public filterParentsBasedOnSearch(): Parent[] {
    if (this.searchBarText === undefined || this.searchBarText === '') {
      return this.parentsList;
    }
    const result = this.parentsList.filter(parent =>
      parent.name.toLowerCase().includes(this.searchBarText.toLowerCase())
    );
    if (result.length === 0) {
      return this.parentsList;
    } else {
      return result;
    }
  }

  public filterParentsBySearchTerm(searchText: string): void {
    this.filteredParentList = searchText
      ? this.parentsList.filter(
        parent =>
          parent.name.toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
      )
      : this.parentsList;
  }

  ngOnDestroy(): void {
    this.isAlive.next(false);
  }

  private fetchInitialParent(): void {
    if (this.parentsList.length > 0) {
      this.parentContextService.parentContext.subscribe(value => {
        const parent = this.parentsList.filter(p => p.id === value)[0];
        if (parent) {
          this.displayText = parent.name;
        } else {
          this.selectedParent = this.parentsList[0];
          this.configureParentSelection();
        }
      });
    } else {
      this.propogateNoParentConfigured();
    }
  }

  private fetchParentsList(): void {
    this.displayText = 'Fetching Tenants';
    this.parentContextService.getParentsList().subscribe(
      respone => {
        this.parentsList = respone['result'];
        this.fetchInitialParent();
        this.parentContextService.setParentListContext(this.parentsList);
        const parent = this.parentsList.filter(p => p.id === this.selectedParentId)[0];
        this.displayText = parent.name;
        this.filteredParentList = this.parentsList;
      },
      error => {
        console.log(error);
        if (error.error.statusMessage === 'NOT_FOUND') {
          this.propogateNoParentConfigured();
        }
      }
    );
  }

  private configureParentSelection(routeTo?: string): void {
    this.parentContextService.setParentContext(this.selectedParent.id);
    this.parentContextService.setParentVerticalType(this.selectedParent.verticalType);
    this.parentContextService.parentContext.next(this.selectedParent.id);
    this.parentContextService.setParentId(this.selectedParent.id);
    if (routeTo) {
      void this.router.navigateByUrl(routeTo);
    } else {
      void this.router.navigateByUrl('/programs');
    }
  }

  private propogateNoParentConfigured() : void {
    this.displayText = 'No Parent Configured';
    this.parentContextService.setParentId('NO_PARENT_CONFIGURED');
    this.router.navigateByUrl('/programs');
  }

}
