import { v4 as uuid } from 'uuid';
import { ActionDestination } from './action-destination';
import { ActionSettings } from './ActionSettings';
import { ActionDestinationType } from './action-destination-type';
import { OauthActionSettings } from './oauth-action-settings';

export class CustomActionDestination implements ActionDestination {

  parentId: string;
  name: string;
  description: string;
  oauthActionSettings: ActionSettings;

  public buildPostPayload() {
    const oauthActionSettingInstance = this.oauthActionSettings as OauthActionSettings;
    return {
      'parentId': `${this.parentId}`,
      'actionDestination': `${uuid()}`,
      'type': ActionDestinationType.CUSTOM,
      'name': `${this.name}`,
      'description': `${this.description}`,
      'actionSettings': (oauthActionSettingInstance !== undefined) ? [oauthActionSettingInstance.buildActionSettings()] : []
    };
  }

  public buildUpdatePayload() {
    const oauthActionSettingInstance = this.oauthActionSettings as OauthActionSettings;
    return {
      'name': `${this.name}`,
      'description': `${this.description}`,
      'type': ActionDestinationType.CUSTOM,
      'actionSettings': (oauthActionSettingInstance !== undefined) ? [oauthActionSettingInstance.buildActionSettings()] : []
    };
  }

  public buildTestConnectionPayload() {
    const oauthActionSettingInstance = this.oauthActionSettings as OauthActionSettings;
    return oauthActionSettingInstance.buildJSONActionSettings();
  }

}
