<app-ae-loading-indicator [isLoading]="programDataService.apiCallCount> 0"
                          [type]="'loading bar'"></app-ae-loading-indicator>
<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
<ng-container *ngIf="isAllocationsModalDisplayed()">
    <app-allocation
            [serviceName]="getServiceNameForAllocation()"
            [businessUnitId]="this.getBusinessUnitIdForAllocation()"
            [allocations]="getAllocationCollection()"
            (isModalDisplayed)="setAllocationsModalDisplayStatus($event)"
            (allocationConfiguration)="captureConfiguredAllocation($event)"></app-allocation>
</ng-container>
<ng-container *ngIf="programDataService.apiCallCount==0 && isUnsupportedProgram" class="container-fluid mb-3">
    <div class="row">
        <div class="col">
            <bp-ribbon classes="ribbon ribbon-info"
                       text="This program type has been deprecated. You can now add reminders to an action within the same program. Please delete this program and add reminder actions to a new or existing program."></bp-ribbon>
        </div>
    </div>
</ng-container>
<div *ngIf="programDataService.apiCallCount==0" class="container-fluid mb-3">
    <ng-container *ngIf="programTemplateHaveChanged && isEdit">
        <div class="row">
            <div class="col">
                <bp-ribbon *ngIf="tenantProgram.active" classes="ribbon ribbon-info"
                           text="The template for your program has been updated. Please deactivate and reactivate the program to get the latest updates."></bp-ribbon>
                <bp-ribbon *ngIf="!tenantProgram.active" classes="ribbon ribbon-info"
                           text="The template for your program has been updated."></bp-ribbon>
            </div>
        </div>
    </ng-container>
    <div class="row py-2 justify-content-end" *ngIf="authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE'])">
        <div class="col-auto">
            <div ngbTooltip="{{displayTexts.DISABLED_SAVE_BTN_TOOLTIP}}" [disableTooltip]="!tenantProgram.active"
                 placement="bottom-right auto">
                <app-ae-button [buttonText]="'Save'" [disabled]="isSaveBtnDisabled()"
                               (click)="doPostConfiguredProgram()"></app-ae-button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="programDataService.apiCallCount==0">
    <coreui-accordion>
        <div>
            <coreui-accordion-pane label="Rule" [active]="true">
                <div class="row">
                    <div class="col-auto"></div>
                </div>
                <div *ngIf="isRuleExpanded" class="mb-3">
                    <fieldset [disabled]="isReadOnly">
          <span class="row py-1 ml-4 pl-1">
               If
               </span>
                        <div class="border py-1 pl-1 mx-4 my-2">
                            <div class="row py-1 mx-0 my-2">
                                <div class="col-auto px-md-3 justify-content-center">
                                    <label for="eventType" class="sr-only">Event Type:</label>
                                    <coreui-select id="eventType">
                                        <coreui-option>{{eventType}}</coreui-option>
                                    </coreui-select>
                                </div>
                                <span class="pt-2">event from</span>
                                <div class="col-auto px-md-3">
                                    <label for="eventSource" class="sr-only">Eventsource:</label>
                                    <coreui-select id="eventSource">
                                        <coreui-option>{{eventSourceName}}</coreui-option>
                                    </coreui-select>
                                </div>
                                <span class="pt-2">for</span>
                                <div class="col-auto px-md-3">
                                    <label for="company" class="sr-only">Company:</label>
                                    <coreui-select id="company" [(ngModel)]="programDataService.companyId"
                                                   placeholder="Choose company">
                                        <coreui-option [value]="undefined" [disabled]="false">Choose company
                                        </coreui-option>
                                        <coreui-option *ngFor="let item of this.companyList | keyvalue"
                                                       value="{{item.value.companyId}}">
                                            {{item.value.companyName}}
                                        </coreui-option>
                                    </coreui-select>
                                </div>
                                <span class="pt-2">is received</span>
                                <ng-container *ngIf="programDataService.programInput['USER_AUTHENTICATED']">
                                  <span class="ml-2 pt-2">from</span>
                                  <div class="col-auto px-md-3">
                                    <label for="userType" class="sr-only">UserType:</label>
                                    <coreui-select id="userType" [(ngModel)]="programDataService.programInput['USER_AUTHENTICATED'].value">
                                      <coreui-option value="Yes">Authenticated</coreui-option>
                                      <coreui-option value="No">UnAuthenticated</coreui-option>
                                    </coreui-select>
                                  </div>
                                  <span class="pt-2">User</span>
                                </ng-container>
                                <ng-container *ngIf="programDataService.programInput['EXECUTE_ACTIONS_BY_CUSTOMER_TYPE']">
                                    <span class="ml-2 pt-2">from</span>
                                    <div class="col-auto px-md-3">
                                        <coreui-select id="isExistingCustomer" [(ngModel)]="programDataService.programInput['EXECUTE_ACTIONS_BY_CUSTOMER_TYPE'].value">
                                            <coreui-option value="NEW">New Customers Only</coreui-option>
                                            <coreui-option value="ALL">Any Customer</coreui-option>
                                        </coreui-select>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <span class="pt-3 ml-4 pl-1">
               Then
               </span>
                        <ng-container *ngIf="tenantProgram.packagedProgramName === 'Purchase' || tenantProgram.packagedProgramName === 'TH_Confirmation'">
                            <div class="row border py-1 pl-3 mx-4 my-2">
                                <div class="col-auto mt-2 mb-2 px-1">
                                    Update the customer entity state and cancel future scheduled events
                                </div>
                            </div>
                        </ng-container>
                        <ng-container
                                *ngFor="let item of programDataService.ruleApiActionMap | keyvalue: originalOrder | filter: filterConfigActions; last as last">
                            <!-- TODO: Use CSS to draw decorative line -->
                            <div *ngIf="item.value.length > 0 && this.isScheduledRule(item.key)"
                                 class="row border-top mx-0 my-4"></div>
                            <div class="row border py-1 pl-3 mx-4 my-2"
                                 *ngFor="let action of item.value; let actionIndex=index">
                                <div class="col-auto">
                                    <div class="row">
                                        <div class="col-auto mt-2 px-1">Send</div>
                                        <div class="col-auto px-1">
                                            <label for="actionDestination" class="sr-only">Action Destination:</label>
                                            <coreui-select id="actionDestination" [(ngModel)]="action.actionInputs[0]"
                                                           (blur)="onActionDestinationChange(item.key, action)">
                                                <coreui-option value="" [disabled]="true">Select an action destination
                                                </coreui-option>
                                                <coreui-option
                                                        *ngFor="let product of getDedupedProductNames(availableServicesPerRuleMapping[item.key]) | keyvalue"
                                                        value="{{product.key}}">
                                                    {{product.value}}
                                                </coreui-option>
                                            </coreui-select>
                                        </div>
                                        <div class="col-auto px-1">
                                            <label for="service" class="sr-only">Service: </label>
                                            <coreui-select id="service" label="Select service"
                                                           [(ngModel)]="action.actionInputs[1]"
                                                           (blur)="onServiceChange(item.key, action, selectedService.value, actionIndex)"
                                                           #selectedService>
                                                <coreui-option value="" [disabled]="true">Select a service
                                                </coreui-option>
                                                <coreui-option
                                                        *ngFor="let serviceMapping of getServicesInSelectedProduct(availableServicesPerRuleMapping[item.key],action.actionInputs[0])"
                                                        value="{{serviceMapping.service}}">
                                                    {{serviceMapping.service}}
                                                </coreui-option>
                                            </coreui-select>
                                        </div>
                                        <div *ngIf="isIqRecommendationAction(action)" class="col-auto mt-2 px-1">
                                            <span *ngIf="isReminderRule(item.key)"> reminder </span>
                                            notification
                                        </div>
                                        <ng-container *ngIf="action.actionInputs[0].includes('HARMONY') || action.actionInputs[0] === 'SFMC'">
                                            <div class="col-auto mt-2 px-1">
                                                for
                                            </div>
                                            <div class="col-auto px-1">
                                                <label for="businessUnit" class="sr-only">Business Unit: </label>
                                                <coreui-select id="businessUnit" label="Select business unit"
                                                               [(ngModel)]="action.actionSettingsInput.serviceInput.businessUnitId">
                                                    <coreui-option value="" [disabled]="true">Select a business unit
                                                    </coreui-option>
                                                    <div *ngFor="let businessunitMap of this.businessunitList|keyvalue;">   
                                                        <div *ngIf="action.actionInputs[0]===businessunitMap.key">                                          
                                                            <coreui-option *ngFor="let item of businessunitMap.value|keyvalue;" 
                                                                value="{{item.value.buId}}">                                                            
                                                                    {{item.value.buName}}
                                                            </coreui-option>
                                                        </div>
                                                    </div>
                                                </coreui-select>
                                            </div>
                                            <div class="col-auto mt-2 px-1" *ngIf="!doesTemplateExist(action)">using
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="action.actionInputs[0].includes('HARMONY')">
                                            <div class="col-auto px-1"
                                                 *ngIf="doAllocationsExists(action, actionIndex) && !doesTemplateExist(action)">
                                                <label for="templateId-{{item.key}}-{{actionIndex}}"
                                                       class="sr-only"></label>
                                                <input id="templateId-{{item.key}}-{{actionIndex}}" type="text"
                                                       class="form-control"
                                                       [(ngModel)]="action.actionSettingsInput.serviceInput.templateId"
                                                       (change)="action.actionSettingsInput.serviceInput.templateId=action.actionSettingsInput.serviceInput.templateId.trim()"
                                                       placeholder="Enter template ID"/>
                                            </div>
                                            <div class="col-auto mt-2 px-1 mr-2"
                                                 *ngIf="getAllocations(action) && !doesTemplateExist(action)"
                                                 (click)="expandMultipleTemplates = !expandMultipleTemplates">
                      <span class="font-weight-semi-bold">
                              Multiple Templates
                              ({{action.actionSettingsInput.serviceInput.allocations.length}}) <i aria-hidden="true"
                                                                                                  [class]="expandMultipleTemplates ? 'fa fa-angle-down fa-fw' : 'fa fa-angle-right fa-fw'"></i>
                              </span>
                                                <ng-container *ngIf="expandMultipleTemplates">
                                                    <ng-container
                                                            *ngFor="let allocation of action.actionSettingsInput.serviceInput.allocations; let allocationIndex=index">
                                                        <ng-container
                                                                *ngFor="let allocationEntities of allocation.allocationEntities; let allocationEntityIndex=index">
                                                            <div class="row">
                                                                <div class="col">
                                                                    {{allocationEntities.value == '' && allocationIndex > 0 ? 'Do not send' : allocationEntities.value}}
                                                                    <span class="font-weight-semi-bold">
                                             ({{allocation.percentage}}%)
                                             </span>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                          </ng-container>
                                          <ng-container *ngIf="action.actionInputs[0].includes('SFMC')">
                                            <div class="col-auto px-1">
                                              <label for="event_definition_key-{{item.key}}-{{actionIndex}}" class="sr-only"></label>
                                              <input id="event_definition_key-{{item.key}}-{{actionIndex}}" type="text" class="form-control"
                                                [(ngModel)]="action.actionSettingsInput.serviceInput.event_definition_key"
                                                (change)="action.actionSettingsInput.serviceInput.event_definition_key=action.actionSettingsInput.serviceInput.event_definition_key.trim()"
                                                placeholder="Enter event definition key" />
                                            </div>
                                          </ng-container>
                                          <ng-container *ngIf="(action.actionInputs[0].includes('HARMONY') || action.actionInputs[0] === 'SFMC') && 
                                          this.isScheduledRule(item.key) && isAsynchronousAction(action)">
                                                <div class="col-auto mt-2 px-1">after</div>
                                                <div class="col-md-1 px-1">
                                                    <label for="delayHours-{{item.key}}-{{actionIndex}}"
                                                           class="sr-only">Delay hours:</label>
                                                    <input type="text" id="delayHours-{{item.key}}-{{actionIndex}}"
                                                           class="form-control" placeholder="Enter hours"
                                                           #delayHours="ngModel"
                                                           [(ngModel)]="programDataService.apiActionIdScheduleMap[action.id]['delay']"
                                                           [ngModelOptions]="{updateOn: 'blur'}"
                                                           (ngModelChange)="validateDelay(action.id, item.key, programDataService.apiActionIdScheduleMap[action.id]['delay'], programDataService.apiActionIdScheduleMap[action.id]['delaySubtype'])"/>
                                                    <div class="text-error"
                                                         *ngIf="(delayHours.touched || delayHours.dirty) && delayValidationStatus[action.id] === false">
                                                        {{delayValidationMessage[action.id]}}
                                                    </div>
                                                </div>
                                                <div class="col-auto px-1">
                                                    <label for="delayTimeUnit" class="sr-only">Delay Unit: </label>
                                                    <coreui-select id="delayTimeUnit"
                                                                   [(ngModel)]="programDataService.apiActionIdScheduleMap[action.id]['delaySubtype']"
                                                                   (valueChange)="validateDelay(action.id, item.key, programDataService.apiActionIdScheduleMap[action.id]['delay'],
                   									 programDataService.apiActionIdScheduleMap[action.id]['delaySubtype'])">
                                                        <coreui-option value="" [disabled]="true">Select an increment
                                                        </coreui-option>
                                                        <coreui-option *ngFor="let time of this.timeDimensionUnit;"
                                                                       value="{{time}}">
                                                            {{time | titlecase}}
                                                        </coreui-option>
                                                    </coreui-select>
                                                </div>
                                            </ng-container>
                                          <ng-container *ngIf="action.actionInputs[0].includes('HARMONY')">
                                            <div class="col-auto" *ngIf="!isReadOnly">
                                                <app-ae-button [buttonText]="'Add Templates'" class="px-1 py-1"
                                                               buttonType="secondary"
                                                               (click)="addTemplates(item, actionIndex)"
                                                               *ngIf="!doesTemplateExist(action) && isAsynchronousAction(action)"></app-ae-button>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="action.actionInputs[0]==='IQ' && isIqRecommendationAction(action)">
                                            <div class="col-auto mt-2 px-1">
                                                for
                                            </div>
                                            <div class="col-auto px-1">
                                                <input type="text" id="businessUnit" class="form-control"
                                                       placeholder="Enter business unit Id"
                                                       [(ngModel)]="action.actionSettingsInput.serviceInput.businessUnitId"
                                                       [ngModelOptions]="{updateOn: 'blur'}"/>
                                            </div>
                                            <div *ngIf="isIqRecommendationAction(action)" class="col-auto mt-2 px-1">for</div>
                                            <ng-container
                                                    *ngIf="iqServiceActionIndex[actionIndex] === iqServices.mostPopularDepartment">
                                                <div class="col-auto px-1">
                                                    <input type="text" id="iqProductCount-{{item.key}}-{{actionIndex}}"
                                                           class="form-control"
                                                           placeholder="Enter number of products"
                                                           [(ngModel)]="action.actionSettingsInput.serviceInput.productCount"
                                                           [ngModelOptions]="{updateOn: 'blur'}"/>
                                                </div>
                                                <div class="col-auto mt-2 px-1">
                                                    products
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="iqServiceActionIndex[actionIndex]=== iqServices.departmentRecommendation">
                                                <div class="col-auto px-1">
                                                    <input type="text"
                                                           id="iqDepartmentCount-{{item.key}}-{{actionIndex}}"
                                                           class="form-control"
                                                           placeholder="Enter number of departments"
                                                           [(ngModel)]="action.actionSettingsInput.serviceInput.departmentCount"
                                                           [ngModelOptions]="{updateOn: 'blur'}"/>
                                                </div>
                                                <div class="col-auto mt-2 px-1">
                                                    departments
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <div class="col-auto" *ngIf="!isReadOnly">
                                            <app-ae-button [buttonText]="'Delete'" class="u-mb-5 py-1"
                                                           (click)="this.deleteAction(item.key, actionIndex)"
                                                           *ngIf="showDeleteButton(item.key, item.value.length)"></app-ae-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="tenantProgram.packagedProgramName === this.currysRMNEntityContent">
                                <div class="row border py-1 pl-3 mx-4 my-2">
                                    <div class="col-auto my-2 px-1">
                                        Return content using
                                    </div>
                                    <div class="col-auto px-1">
                                        <label for="rmnProgramId" class="sr-only">CAS Id:</label>
                                        <coreui-select id="rmnProgramId">
                                            <coreui-option>CAS_Id</coreui-option>
                                        </coreui-select>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="item.value.length > 0 && authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE'])"
                                 class="row mx-0 pb-3">
                                <div class="col">
                                    <app-ae-button
                                            buttonText="{{getAddActionButtonLabel(item.key)}}"
                                            class="p-0"
                                            icon="add"
                                            iconPosition="left"
                                            buttonType="text-primary"
                                            [disabled]="item.value.length === getMaxAllowedActions(item.key)"
                                            (click)="this.addAction(item.key)"
                                    ></app-ae-button>
                                    <div *ngIf="item.value.length === getMaxAllowedActions(item.key)">
                                        {{getMaxActionsAddedErrorMessage(item.key)}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="showAddReminderLink() && authorizationService.isAllowed('PKG_PRG', ['CREATE','UPDATE'])"
                             class="row border-top mx-0 pt-3 pb-3">
                            <div class="col">
                                <app-ae-button
                                        buttonText="Add a reminder action"
                                        class="p-0"
                                        icon="add"
                                        iconPosition="left"
                                        buttonType="text-primary"
                                        (click)="this.addScheduledAction(withReminderRule)"
                                ></app-ae-button>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </coreui-accordion-pane>
        </div>
    </coreui-accordion>
    <coreui-accordion>
        <div>
            <ng-container *ngIf="applicableExclusions.length > 0" class="border-top pb-3">
                <coreui-accordion-pane label="{{eventSource === 'Web' ? 'Filters' : 'Exclusions'}}" [active]="true">
                    <div *ngIf="isExclusionExpanded" class="mb-2 mx-4">
                        <div *ngIf="eventSource !== 'Web'" class="border py-1 pl-3 mt-2"><span class="align-middle pt-2 mr-5">Do not send if </span>
                        </div>
                        <ng-container *ngFor="let exclusion of applicableExclusions; let exclusionIndex=index">
                            <fieldset [disabled]="isReadOnly">
                                <div class="row border py-1 pl-3 my-2 mr-0 ml-4">
                                    <ng-container *ngFor="let uiControl of exclusion">
                                        <ng-container *ngIf="uiControl['type'] === UIControlType.TEXT">
                                            <div class="col-auto pt-2 px-1">
                                                {{uiControl['value']}}
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="uiControl['type'] === UIControlType.TEXTBOX">
                                            <div class="col-md-1 px-2">
                                                <label for="{{uiControl['value']}}-id" class="sr-only">uiControl['value']</label>
                                                <input type="text" id="{{uiControl['value']}}-id" class="form-control"
                                                       required placeholder="Enter input"
                                                       [(ngModel)]="programDataService.programInput[uiControl['value']]['value']"
                                                       (focusout)="validateInputSetting(uiControl['value'])"/>
                                                <div class="text-error"
                                                     *ngIf="isInvalidInput[uiControl['value']] !== undefined && isInvalidInput[uiControl['value']]">
                                                    {{invalidInputErrorMessage[uiControl['value']]}}
                                                </div>
                                                <div *ngIf="this.showWarningThreshold[uiControl['value']] != undefined"
                                                     class="text-style-muted-2">
                                                    {{showWarningThreshold[uiControl['value']]}}
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="uiControl['type'] === UIControlType.SELECTBOX">
                                            <div class="col-auto px-1">
                                                <label for="timeUnit" class="sr-only">Time Unit: </label>
                                                <coreui-select id="timeUnit"
                                                               [(ngModel)]="programDataService.programInput[uiControl['value']]['valueSubtype']"
                                                               (valueChange)="validateInputSetting(uiControl['value'])">
                                                    <coreui-option value="" [disabled]="true">Select an increment
                                                    </coreui-option>
                                                    <coreui-option *ngFor="let time of this.timeDimensionUnit;"
                                                                   value="{{time}}">
                                                        {{time | titlecase}}
                                                    </coreui-option>
                                                </coreui-select>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <ng-container *ngIf="applicableExclusions.length-1 > exclusionIndex">
                                    <span class="mt-1 ml-5 bg-dark text-white px-1">OR</span>
                                </ng-container>
                            </fieldset>
                        </ng-container>
                    </div>
                </coreui-accordion-pane>
            </ng-container>
        </div>
    </coreui-accordion>
</div>
 