import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/service-response';
import { ConfiguredRole } from '../models/permission/role/configured-role';
import { RolesService } from './roles-service-interface';
import { Type } from '../models/roleType';
import { Feature } from '../models/permission/feature/role-permission-constants';
import { PackagedRolesService } from './packaged-roles.service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguredRolesService implements RolesService {

  private static readonly roleServiceUrl = 'admin/role';
  private static readonly configuredRolesUrl: string = ConfiguredRolesService.roleServiceUrl + '/configured';
  private static readonly featuresUrl: string = ConfiguredRolesService.roleServiceUrl + '/features';
  private static readonly queryString: string = '?active=true&limited=true';
  private static readonly navPath: string = '/client/admin/roles/';
  private static readonly createRoleRouterLink = '/client/admin/roles/' + Type.CLIENT.toLowerCase();
  private static readonly roleType = Type.CLIENT;
  public rolesLandingRouter: string;
  private http: HttpClient;
  private host: string = environment.host;
  private base: string = environment.base;

  public constructor(http: HttpClient, packagedRoleService: PackagedRolesService) {
    this.http = http;
  }

  public getAllRoles(parentId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredRolesService.configuredRolesUrl;
    return this.http.get<ServiceResponse>(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  public getActiveAndLimitedRoles(parentId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredRolesService.configuredRolesUrl + ConfiguredRolesService.queryString;
    return this.http.get<ServiceResponse>(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  public deleteRoleById(parentId: string, id: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredRolesService.configuredRolesUrl + '/' + id;
    return this.http.delete<ServiceResponse>(url, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }

  public createRole(parentId: string, configuredRole: ConfiguredRole): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredRolesService.configuredRolesUrl;
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers
    };
    return this.http.post<ServiceResponse>(url, configuredRole, options);
  }

  public getAllFeatures(parentId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredRolesService.featuresUrl + '/all';
    const headers: HttpHeaders = new HttpHeaders()
      .append('parent-id', parentId);
    const options = {
      headers
    };
    return this.http.get<ServiceResponse>(url, options);
  }

  public getFeatureName(): string {
    return Feature.CFG_ROLE;
  }

  public getNavPath(): string {
    return ConfiguredRolesService.navPath;
  }

  public getRoleType(): string{
    return ConfiguredRolesService.roleType;
  }

  public getCreateRouterLink(): string{
    return ConfiguredRolesService.createRoleRouterLink;
  }

  public getRoleById(roleType: string, roleId: string, parentId: string): Observable<ServiceResponse> {
     let url = '';
    if (roleType == Type.CLIENT.toLowerCase()){
       url = this.host + this.base + ConfiguredRolesService.configuredRolesUrl + '/' + roleId;
    } else if (roleType == Type.GLOBAL.toLowerCase()){
       url = this.host + this.base + PackagedRolesService.packagedRolesUrl + '/' + PackagedRolesService.partitionKey + '/' + roleId;
    }
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get<ServiceResponse>(url, options);
  }
  
  public updateRole(parentId: string, configuredRole: ConfiguredRole, roleId: string): Observable<ServiceResponse> {
    const url = this.host + this.base + ConfiguredRolesService.configuredRolesUrl + '/' + roleId;
    return this.http.put<ServiceResponse>(url, configuredRole, {
      headers: new HttpHeaders({
        'parent-id': parentId
      })
    });
  }
}
