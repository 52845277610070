import { AttributeCondition } from './attribute-condition';
import { DateIntegerOperator, DateOperators, IntegerOperators, Operator, RuleOperators, StringOperators, BooleanOperators, ArrayOperators, NullOperators } from '../operators/rule-operators';
import { DataTypes } from './data-types';
import { ContainerConditionElement } from './container-condition-element';
import { ValueType } from './value-type';
import { Duration } from 'luxon';
import { DurationUnits } from '../../exclusion/duration-units';

export class AttributeConditionElement extends ContainerConditionElement {

  constructor(
    public isOperatorSelected: boolean,
    public isValueProvided: boolean,
    public attributeCondition: AttributeCondition,
    public typeOfValue: string,
    public errorOnValues: string = ''
  ) {
    super();
    if (this.typeOfValue === '') {
      if (this.attributeCondition.dataType !== DataTypes.array) {
        this.typeOfValue = this.attributeCondition.dataType;
      }
      if (this.attributeCondition.dataType === DataTypes.array) {
        const typeOfAttr = typeof this.attributeCondition.values[0];
        this.typeOfValue = DataTypes[typeOfAttr];
        if (this.attributeCondition.operator === 'CONTAINS') {
          this.attributeCondition.operator = 'CONTAINS_IGNORECASE';
        } else if (this.attributeCondition.operator === 'NOTCONTAINS') {
          this.attributeCondition.operator = 'NOTCONTAINS_IGNORECASE';
        }
      } else if (this.attributeCondition.dataType === DataTypes.string) {
        if (this.attributeCondition.operator === 'EQ') {
          this.attributeCondition.operator = 'EQ_IGNORECASE';
        } else if (this.attributeCondition.operator === 'NEQ') {
          this.attributeCondition.operator = 'NEQ_IGNORECASE';
        } else if (this.attributeCondition.operator === 'IN') {
          this.attributeCondition.operator = 'IN_IGNORECASE';
        } else if (this.attributeCondition.operator === 'NOTIN') {
          this.attributeCondition.operator = 'NOTIN_IGNORECASE';
        } else if (this.attributeCondition.operator === 'STARTSWITH') {
          this.attributeCondition.operator = 'STARTSWITH_IGNORECASE';
        } else if (this.attributeCondition.operator === 'ENDSWITH') {
          this.attributeCondition.operator = 'ENDSWITH_IGNORECASE';
        }
      }
      if (!attributeCondition.hasOwnProperty('valueType')) {
        attributeCondition.valueType = ValueType.CONSTANT;
      }
      if (attributeCondition.valueType === ValueType.CONSTANT && this.isAbsoluteDateOperator() && !this.isBeforeOccurrenceOperator()) {
        const date = new Date(+this.attributeCondition.values[0]);
        this.attributeCondition.values[0] = date;
      }
      if (attributeCondition.valueType === ValueType.CONSTANT && this.isBeforeOccurrenceOperator()) {
        this.initDurationDetails(this.attributeCondition);
      }
    }
  }

  public getAttributeCondition() {
    return this.parseCondition();
  }

  public isBooleanDataType() {
    return this.attributeCondition.dataType === DataTypes.boolean
        || (this.attributeCondition.dataType === DataTypes.array && this.typeOfValue === DataTypes.boolean);
  }

  public isRelativeDateOperator() {
    const operatorsList = RuleOperators.getOperatorsForDatatype(this.attributeCondition.dataType);
    const isRelative = this.isDateDataType() && (<DateIntegerOperator>operatorsList[this.attributeCondition.operator]).relative;
    return isRelative;
  }

  public isDateDataType() {
    return this.attributeCondition.dataType === DataTypes.date;
  }

  public isAbsoluteDateOperator() {
    return this.isDateDataType() && !this.isRelativeDateOperator();
  }

  public isNullOrNotNullOperator(): boolean {
    const operators = new RuleOperators(this.attributeCondition.dataType.toLowerCase());
    if (this.attributeCondition.operator === NullOperators.ISNULL || this.attributeCondition.operator === NullOperators.NOTISNULL) {
      return operators.operators.some(op => op.value === this.attributeCondition.operator);
    }
  }

  public isSameAttributeCompare(): boolean {
    const operators = new RuleOperators(this.attributeCondition.dataType.toLowerCase());
    if (this.attributeCondition.operator !== NullOperators.ISNULL && this.attributeCondition.operator !== NullOperators.NOTISNULL) {
      return operators.operators.some(op => op.value === this.attributeCondition.operator);
    }
    return false;
  }

  public isAttributeCompare(): boolean {
    if (this.attributeCondition.operator === DateOperators.IS_COMPARE_TIME.value ||
      this.attributeCondition.operator === IntegerOperators.IS_COMPARE_NUMBER.value) {
      this.attributeCondition.valueType = ValueType.ARRAY;
      return true;
    }
    return false;
  }

  public isBeforeOccurrenceOperator(): boolean {
    return this.attributeCondition.operator === DateOperators.IS_BEFORE_OCCURANCE.value;
  }

  public isCsvOperator() {
    const operatorsList = RuleOperators.getOperatorsForDatatype(this.attributeCondition.dataType);
    const isValueCsv = (<Operator>operatorsList[this.attributeCondition.operator]).csv;
    return isValueCsv;
  }

  private parseCondition() {
    const inputValues: string = this.attributeCondition.values.toString();
    let values = [];
    if (this.attributeCondition.dataType === DataTypes.boolean) {
      const parsedInputValue: boolean = JSON.parse(inputValues);
      values.push(parsedInputValue);
      this.attributeCondition.values = values;
      return this.attributeCondition;
    }
    // If csv
    const operatorsList = RuleOperators.getOperatorsForDatatype(this.attributeCondition.dataType);
    if ((<Operator>operatorsList[this.attributeCondition.operator]).csv) {
      values = inputValues.split(',');
    } else {
      values.push(inputValues);
    }
    if (this.attributeCondition.dataType === DataTypes.number) {
      values = values.map(function(item) {
        return +item;
      });
    }
    if (this.attributeCondition.dataType === DataTypes.array) {
      let transformedValues = [];
      if (this.typeOfValue === DataTypes.boolean) {
        const value: boolean = JSON.parse(values[0].toLowerCase());
        transformedValues.push(value);
      }
      if (this.typeOfValue === DataTypes.number) {
        const value = values.map(function(item) {
          return +item;
        });
        transformedValues = value;
      }
      if (this.typeOfValue === DataTypes.string) {
        transformedValues = values;
      }
      values = transformedValues;
    }
    this.attributeCondition.values = values;
    return this.attributeCondition;
  }

  private initDurationDetails(attributeCondition: AttributeCondition): void {
    let jsonDurationUnit = DurationUnits.MINUTES.toString();
    let duration = '';
    if (attributeCondition.values[1]) {
      const jsonDuration = Duration.fromISO(attributeCondition.values[1]).toObject();
      jsonDurationUnit = Object.keys(jsonDuration).shift();
      duration = jsonDuration[jsonDurationUnit];
    }
    attributeCondition.values[1] = duration;
    attributeCondition.values[2] = DurationUnits[jsonDurationUnit.toLocaleUpperCase()];
  }

}
