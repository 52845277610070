import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Messages } from 'src/app/shared/message';
import { PackageType } from 'src/app/shared/models/package-builder/package-type-enum';
import { ModelType } from 'src/app/shared/models/package-builder/modelType';
import { Pattern } from 'src/app/shared/pattern';
import { IQDecisionsService } from 'src/app/shared/services/iqdecisions.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { UtilService } from 'src/app/shared/services/util-service';
import { GroupInfoTableColumns } from 'src/app/shared/models/package-builder/groupInfo-table-enum';
import { ModelParameterType } from 'src/app/shared/models/package-builder/model-parameter-type-enum';

@Component({
  selector: 'app-iqdecisions-view-models',
  templateUrl: './iqdecisions-view-models.component.html',
})
export class IqdecisionsViewModelsComponent implements OnInit {

  @Input() configuredRecommendation;
  @Input() modelFormGroup;
  @Input() modelsProperties;
  @Input() modelsTableData;
  @Input() isEditMode = true;
  @Input() models: any;
  public originalModels = [];
  private parentId: string;
  public temp : string;

  constructor(
    private parentContext: ParentContextService,
    private iqDecisionsService: IQDecisionsService
  ) {}

  ngOnInit() : void {
   this.initModelsTableData();
   this.parentId = this.parentContext.getParentContext();
   this.doGetPackagesTypes(this.configuredRecommendation.company.id);
  }

  public messages = Messages;
  public packageType = PackageType;
  public modelType = ModelType;

  public getFallBackModelName(): string {
    return this.configuredRecommendation.metadataConfiguration.groupInfos[0].fallbackModel.name;
  }

  public getSelectedModel(value, type): void {
    this.modelFormGroup.patchValue({
      [type]: value
    });

    if (type === ModelType.PRIMARY_MODEL && this.displayModelParameters(value.id)) {
      this.modelFormGroup.addControl(ModelParameterType.ITEM_CHOICE_TYPE, new UntypedFormControl('', Validators.required));
      this.modelFormGroup.addControl(
        ModelParameterType.ITEMS_PER_INPUT_LEVEL,
        new UntypedFormControl(1, { validators: [Validators.required, Validators.min(1), Validators.pattern(Pattern.INTEGER)] })
      );
    } else {
      this.modelFormGroup.removeControl(ModelParameterType.ITEM_CHOICE_TYPE);
      this.modelFormGroup.removeControl(ModelParameterType.ITEMS_PER_INPUT_LEVEL);
    }
  }

  public displayModelParameters(values: string) : boolean {
    const value = this.modelFormGroup.get(ModelType.PRIMARY_MODEL).value?.id;

    if (this.modelFormGroup.get(ModelType.PRIMARY_MODEL).value?.id) {
      return !!this.models.find((m) => m.id === value).itemsChoiceType;
    }
  }

  public get fallbackModelList(): Array<any> {
    return [...this.models].filter(m => m.id !== this.modelFormGroup.get(ModelType.PRIMARY_MODEL).value?.id);
  }

  private initModelsTableData(): void {
    this.modelsTableData = [];
    this.configuredRecommendation?.metadataConfiguration?.groupInfos?.forEach(group => {
      const tableItem = {};
      tableItem[GroupInfoTableColumns.NAME] = group.name;
      tableItem[GroupInfoTableColumns.SHARE] = group.share+'%';
      tableItem[GroupInfoTableColumns.PRIMARY_MODEL] = group.primaryModel?.name == null ? '--' : group.primaryModel?.name;
      tableItem[GroupInfoTableColumns.FALLBACK_MODEL] = group.fallbackModel?.name == null ? '--' : group.fallbackModel?.name;
      this.modelsTableData.push(tableItem);
    });
  }

  public doGetPackagesTypes(company: string): void {
    this.iqDecisionsService
      .getPackagedTypes(company, this.parentId).subscribe((res) => {
        this.originalModels = JSON.parse(UtilService.unescapedJsonString(res['result'].template.metadataJson)).models.map(m => {
          return {
            ...m,
            range: m.trainingStartDate === undefined ? '---' : m.trainingStartDate + '- ' + m.trainingEndDate === undefined ? '---' : m.trainingEndDate
          };
        });
        this.originalModels = JSON.parse(JSON.stringify(this.originalModels));
      }, error => {
      });
  }
}
