<form name="exclusionConfigurationForm" [formGroup]="exclusionConfigurationFormGroup">
    <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMFORTABLE" class="container-fluid">
      <div class="row my-2">
        <div class="col-auto border border-color-secondary rounded align-self-center py-2">
          <div class="row">
            <div class="col-auto py-2 pr-3">
              Latest
            </div>
          </div>
        </div>
      </div>
      <div class="row border border-color-secondary rounded mb-2">
        <div class="col align-self-center py-3">
          {{ getAttributeName() | lowercase }}
        </div>
        <div class="col-auto py-2 ml-auto">
          <button coreUIButton="icon-large" withBorder [disabled]="!canDeleteExclusionConfiguration()" (click)="onEntityStateAttributeDelete()"><i cuikIcon="trash"></i></button>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-auto border border-color-secondary rounded align-self-center py-2">
          <div class="row">
            <div class="col-auto py-2 pr-1">
              Less than
            </div>
            <div class="col-auto px-1 inline-form-field">
              <coreui-form-field>
                <input type="text" coreUIInput [cuikDecimalMask]="decimalMaskConfig" name="duration" formControlName="duration" />
                <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
              </coreui-form-field>
            </div>
            <div class="col-auto pl-1 pr-3">
              <coreui-select formControlName="durationUnit">
                <coreui-option *ngFor="let durationUnitName of durationUnitList" [value]="durationUnitName" [selected]="exclusionConfigurationFormGroup.controls[formControlNames.DURATION_UNIT].value === durationUnitName">{{durationUnitName | lowercase}}</coreui-option>
              </coreui-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMPACT" class="col-auto border border-color-secondary rounded align-self-center py-2">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-auto py-2 pl-2 pr-0">
              Latest
            </div>
            <div class="col-auto py-2 pl-1 pr-0">
              {{ getAttributeName() | lowercase }}
            </div>
            <div class="col-auto py-2 pl-1 pr-0">
              less than
            </div>
            <div class="col-auto pl-1 pr-0 inline-form-field">
              <coreui-form-field>
                <input type="text" coreUIInput [cuikDecimalMask]="decimalMaskConfig" name="duration" formControlName="duration" />
                <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
              </coreui-form-field>
            </div>
            <div class="col-auto pl-1 pr-0 inline-form-field">
              <coreui-select formControlName="durationUnit">
                <coreui-option *ngFor="let durationUnitName of durationUnitList" [value]="durationUnitName" [selected]="exclusionConfigurationFormGroup.controls[formControlNames.DURATION_UNIT].value === durationUnitName">{{durationUnitName | titlecase}}</coreui-option>
              </coreui-select>
            </div>
          </div>
        </div>
        <div class="col-auto align-self-center">
          <div class="row">
            <div class="col-auto ml-auto px-2">
              <app-ae-button [disabled]="!canDeleteExclusionConfiguration()" (click)="onEntityStateAttributeDelete()" buttonText="Delete"></app-ae-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>