import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EntityStateLookup } from 'src/app/shared/models/entity-state-lookup.model';
import { EntityStateLookupService } from 'src/app/shared/services/entitystate-lookup.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';

@Component({
  selector: 'app-entity-state-lookup',
  templateUrl: './entity-state-lookup.component.html'
})
export class EntityStateLookupComponent implements OnInit {

  public lookupForm: UntypedFormGroup;
  public parentId: string;
  public isApiServiceCalled: boolean;
  public isSubmitted: boolean;
  public entiyStateData: string;

  constructor(
    private fb: UntypedFormBuilder,
    public entityStateService: EntityStateLookupService,
    public parentContextService: ParentContextService
  ) {
    this.parentId = this.parentContextService.getParentContext();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  public buildForm(): void {
    this.lookupForm = this.fb.group({
      companyId: '',
      entiyStateData: new UntypedFormControl({ value: '', disabled: true}),
      key: ['', Validators.required]
    });
  }

  public lookupEntityState(): void {
    if (this.lookupForm.invalid) {
      return;
    }
    this.isSubmitted = true;
    this.getEntityStateByLookup(this.lookupForm.get('companyId').value,
      this.lookupForm.get('key').value);
  }

  private getEntityStateByLookup(companyId: string, key: string): void {
    this.isApiServiceCalled = true;
    this.entiyStateData = '';
    this.entityStateService.fetchEntityStateLookUpRecord(new EntityStateLookup(this.parentId, companyId, key)).subscribe(
      (res: any) => {
        this.entiyStateData = JSON.stringify(res.result.data, undefined, 4);
        this.isApiServiceCalled = false;
        this.lookupForm.controls.entiyStateData.setValue(this.entiyStateData);
      }, () => {
        this.isApiServiceCalled = false;
      });
  }

}