import { ServiceResponse } from '../service-response';
import { UserGroupInfo } from '../permission/user/user-group-info';
import { User } from './user';

export class APIUser extends User {

  public password: string;
  public clientSecret: string;
  public clientId: string;
  public authUrl: string;
  public email: string;
  public secretQuestion: string;
  public secretAnswer: string;
  public userId: string;
  public parentId: string;

  public constructor(response?: ServiceResponse) {
    super();
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response.result)));
    }
  }

}