import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const actionDestinationPath = 'action-destination/';
const actionSettingPath = 'action-setting/';
@Injectable({
  providedIn: 'root'
})
export class ActionDestinationService {

  host = environment.host;
  base = environment.base;


  constructor(private http: HttpClient) { }

  getActionDestinations(parentId: string) {
    const url = this.host + this.base + actionDestinationPath + parentId;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get<string[]>(url, options);
  }

  getBUs(parentId: string, product: string) {
    const url = this.host + this.base + actionDestinationPath + parentId + '/' + product;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

  getAllActionDestinations(parentId: string) {
    const url = this.host + this.base + actionDestinationPath;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get<string[]>(url, options);
  }
  configureActionDestination(parentId: string, configurationDetails: string) {
    const url = this.host + this.base + actionDestinationPath + 'configured';
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.post(url, configurationDetails, options);
  }

  deleteConfiguredActionDestination(parentId: string, actionDestination: string) {
    const url = this.host + this.base + actionDestinationPath + parentId + '/' + actionDestination;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.delete(url, options);
  }

  deleteBusinessUnit(parentId: string, businessUnitDetails: string) {
    const url = this.host + this.base + actionDestinationPath + actionSettingPath;
    return this.http.request('DELETE', url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'parent-id': parentId
      }),
      body: businessUnitDetails
    });

  }

  saveActionSettingConfiguration(businessUnitDetails: string, parentId: string, actionDestination: string) {
    const url = this.host + this.base + actionDestinationPath + parentId + '/' + actionDestination;
    return this.http.request('PUT', url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'parent-id': parentId
      }),
      body: businessUnitDetails
    });

  }

  testCustomActionDestinationConnection(parentId: string, authenticationDetails: any) {
    const url = this.host + this.base + actionDestinationPath + 'configured/test';
    return this.http.request('PUT', url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'parent-id': parentId
      }),
      body: authenticationDetails
    });
  }
  testIamActionDestinationConnection(parentId: string, authenticationDetails: any) {
    const url = this.host + this.base + actionDestinationPath + 'configured/test?authenticationType=IAM';
    return this.http.request('PUT', url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'parent-id': parentId
      }),
      body: authenticationDetails
    });
  }

  getActionDestination(id: string, parentId: string) {
    const url = `${this.host}${this.base}${actionDestinationPath}${parentId}/${id}`;
    const headers: HttpHeaders = new HttpHeaders().append('parent-id', parentId);
    const options = { headers: headers };
    return this.http.get(url, options);
  }

}