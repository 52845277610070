<app-base-template>
  <div class="container-fluid object-page-header pt-4">
    <div class="row align-items-center py-sm-3">
      <div class="col">
        <h1 class="text-style-1 mb-0">Rules</h1>
      </div>
      <div class="col text-right" *ngIf="authorizationService.isAllowed('CFG_RULE', ['CREATE'])">
        <app-ae-button buttonType="primary" buttonText="Create New Rule" routerLink="/rules/add/id" [disabled]="!parentContextService.isParentContextSet()"></app-ae-button>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-5">
  <ng-container *ngIf="configuredRuleTableList">
    <div class="row">
      <div class="col col-sm-6 col-xl-3">
        <app-ae-table-search
          label="Search Rules"
          placeholder="Search by name"
          (searchEmitter)="handleSearch($event)">
        </app-ae-table-search>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-ae-table [isDataLoading]="isDataLoading" [properties]="properties"
          [configuredTableData]="configuredRuleTableFilteredData">
        </app-ae-table>
      </div>
    </div>
  </ng-container>
  </div>
</app-base-template>
