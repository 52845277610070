import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormOnSaveAction } from 'src/app/shared/constants';
import { ExclusionConfigurationDirective } from 'src/app/shared/exclusion/exclusion-configuration/exclusion-configuration.directive';
import { AttributeCondition } from 'src/app/shared/models/custom-rule/conditions/attribute-condition';
import { DataTypes } from 'src/app/shared/models/custom-rule/conditions/data-types';
import { BaseExclusionConfiguration } from 'src/app/shared/models/exclusion/base-exclusion-configuration';
import { ConfiguredExclusion } from 'src/app/shared/models/exclusion/configured-exclusion';
import { Exclusion } from 'src/app/shared/models/exclusion/exclusion';
import { ExclusionDisplayMode } from 'src/app/shared/models/exclusion/exclusion-display-mode';
import { ExclusionOperator } from 'src/app/shared/models/exclusion/exclusion-operator';
import { ExclusionConfiguationComponentBuilderService } from 'src/app/shared/services/exclusion-configuation-component-builder.service';

@Component({
  selector: 'app-custom-program-exclusion-configuation',
  templateUrl: './custom-program-exclusion-configuation.component.html',
  styleUrls: ['./custom-program-exclusion-configuation.component.scss']
})
export class CustomProgramExclusionConfiguationComponent implements OnInit, OnDestroy {

  @ViewChild(ExclusionConfigurationDirective, { static: true })
  public appExclusionConfiguration!: ExclusionConfigurationDirective;

  @Input()
  public configuredExclusion: ConfiguredExclusion;
  @Input()
  public readOnly: boolean;
  @Output()
  public deleteExclusionConfiguration = new EventEmitter<void>();

  public exclusionOperator: ExclusionOperator;
  public attributeCondition: AttributeCondition;

  private exclusionConfigurationComponentRef: ComponentRef<BaseExclusionConfiguration>;

  public constructor(private exclusionConfiguationComponentBuilderService: ExclusionConfiguationComponentBuilderService) {
  }

  public ngOnInit(): void {
    this.initExclusionAttributeCondition();
    this.createExclusionConfigurationComponent(this.exclusionOperator);
  }

  public ngOnDestroy(): void {

  }

  public validate(): boolean {
    return this.exclusionConfigurationComponentRef.instance.validate();
  }

  public buildExclusion(): ConfiguredExclusion {
    const configuredExclusion = Object.assign(new ConfiguredExclusion(), this.configuredExclusion);
    const attributeConditionValues = this.exclusionConfigurationComponentRef.instance.buildAttributeConditionValues();
    this.attributeCondition.values = attributeConditionValues;
    const exclusion: Exclusion = Object.assign(new Exclusion(), configuredExclusion.getExclusion());
    exclusion.setAttributeCondition(this.attributeCondition);
    this.configuredExclusion.exclusion = JSON.stringify(exclusion);
    return this.configuredExclusion;
  }

  public isPristine(): boolean {
    return this.exclusionConfigurationComponentRef.instance.isPristine();
  }

  public markAsPristine(): void {
    this.exclusionConfigurationComponentRef.instance.markAsPristine();
  }

  private initExclusionAttributeCondition(): void {
    const configuredExclusion: ConfiguredExclusion = Object.assign(new ConfiguredExclusion(), this.configuredExclusion);
    const exclusion: Exclusion = Object.assign(new Exclusion(), configuredExclusion.getExclusion());
    this.attributeCondition = exclusion.getAttributeCondition();
    this.exclusionOperator = ExclusionOperator[this.attributeCondition.operator];
  }

  private createExclusionConfigurationComponent(exclusionOperator: ExclusionOperator): void {
    const componentFactory = this.exclusionConfiguationComponentBuilderService.build(exclusionOperator,  this.attributeCondition.dataType as DataTypes);
    if (!componentFactory) {
      return;
    }
    const viewContainerRef = this.appExclusionConfiguration.viewContainerRef;
    viewContainerRef.clear();
    this.exclusionConfigurationComponentRef = viewContainerRef.createComponent<BaseExclusionConfiguration>(componentFactory);
    this.exclusionConfigurationComponentRef.instance.action = FormOnSaveAction.EDIT;
    this.exclusionConfigurationComponentRef.instance.attributeName = this.attributeCondition.name;
    this.exclusionConfigurationComponentRef.instance.exclusionDisplayMode = ExclusionDisplayMode.COMPACT;
    this.exclusionConfigurationComponentRef.instance.allowDelete = !this.readOnly;
    this.exclusionConfigurationComponentRef.instance.attributeConditionValues = this.attributeCondition.values;
    this.exclusionConfigurationComponentRef.instance.attributeDataType = this.attributeCondition.dataType as DataTypes;
    this.exclusionConfigurationComponentRef.instance.exclusionOperator = this.exclusionOperator;
    this.exclusionConfigurationComponentRef.instance.readOnly = this.readOnly;
    this.exclusionConfigurationComponentRef.instance.deleteExclusionConfiguration.subscribe(() => this.deleteExclusionConfigurationComponent());
  }

  private deleteExclusionConfigurationComponent(): void {
    if (this.exclusionConfigurationComponentRef) {
      this.exclusionConfigurationComponentRef.destroy();
      this.deleteExclusionConfiguration.emit();
    }
  }

}