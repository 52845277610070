import { Delta } from './delta';
import { AttributeDelta } from './attribute-delta';
import { DeltaCategory } from './delta-category';

export class AuditActivity {
	
	deltaOperation: string;
	title: string;
	name: string;
	icon: string;
	deltas: any[] = [];
	
	public get getDeltas(): any[] {
		return this.deltas;
	}
	
	public build(op: string, name: string, title: string, icon: string): AuditActivity {
		this.deltaOperation = op;
		this.name = name;
		this.title = title;
		this.icon = icon;
		return this;
	}

	public buildAddActivitiesForModifyOperation(delta: Delta, activities: AuditActivity[]): void {
		switch (delta.category.toUpperCase()) {
			case DeltaCategory.ACTIONS:
			case DeltaCategory.REMINDER_ACTIONS:
				let action = delta.attributeDeltas[0]["currentValue"][0] != undefined ? delta.attributeDeltas[0]["currentValue"][0] :
											delta.attributeDeltas[0]["currentValue"];
				const desc = new AttributeDelta(delta.attributeDeltas[0]).buildNaturalLanguageForModifyAddAction(action);
				if (desc) {
					const title = delta.category.toLowerCase().includes("reminder") ? "Added Reminder Action" : "Added Action";
					let activity = new AuditActivity().build("add", null, title, "add");
					activity.getDeltas.push(desc);
					activities.push(activity);
				}
				break;
		
			case DeltaCategory.TEMPLATE_ALLOCATIONS:
				let activity = new AuditActivity().build("add", null, "Added Template Allocations", "add");	
				if (delta.attributeDeltas) {
					for (let attr of delta.attributeDeltas) {
						activity.getDeltas.push(new AttributeDelta(attr).buildFromTo());
					}
					activities.push(activity);
				}
				break;
		}
	}
	
	public buildReplaceActivitiesForModifyOperation(delta: Delta, activities: AuditActivity[]): void {
		let activity: AuditActivity = new AuditActivity();
		let name;
		switch (delta.category.toUpperCase()) {
			case DeltaCategory.MODE:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Mode", "edit");
				break;
			
			case DeltaCategory.ACTIVE:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Status", "edit");
				break;

			case DeltaCategory.NAME:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Name", "edit");
				break;	
			
			case DeltaCategory.COMPANY:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Company", "edit");
				break;
			
			case DeltaCategory.CONDITIONS:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Conditions", "edit");
				break;
			
			case DeltaCategory.ACTIONS:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Actions", "edit");
				break;
	
			case DeltaCategory.REMINDER_ACTIONS:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Reminder Actions", "edit");
				break;

			case DeltaCategory.TEMPLATE_ALLOCATIONS:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Template Allocations", "edit");
				break;

			case DeltaCategory.EXCLUSIONS:
				activity = activity.build(delta.operation, delta.deltaId, "Edited Exclusions", "edit");
				name = delta.deltaId;
				break;
			
			default :
				activity = activity.build(delta.operation, delta.deltaId, "Edited", "edit");
		}

		if (activity) {
			for (let attr of delta.attributeDeltas) {
				activity.getDeltas.push(new AttributeDelta(attr).buildFromTo(name));
			}
			activities.push(activity);
		}	
	}

	public buildRemoveActivitiesForModifyOperation(delta: Delta, activities: AuditActivity[]): void {
		switch (delta.category.toUpperCase()) {
			case DeltaCategory.ACTIONS:
			case DeltaCategory.REMINDER_ACTIONS:
				let action = delta.attributeDeltas[0]["previousValue"][0] != undefined ? delta.attributeDeltas[0]["previousValue"][0] :
											delta.attributeDeltas[0]["previousValue"];
				const desc = new AttributeDelta(delta.attributeDeltas[0]).buildNaturalLanguageForModifyRemoveAction(action);
				if (desc) {
					const title = delta.category.toLowerCase().includes("reminder") ? "Deleted Reminder Action" : "Deleted Action";
					let activity = new AuditActivity().build(delta.operation, null, title, "error");
					activity.getDeltas.push(desc);
					activities.push(activity);
				}
				break;
		
			case DeltaCategory.TEMPLATE_ALLOCATIONS:
				let activity = new AuditActivity().build(delta.operation, null, "Deleted Template Allocations", "error");	
				if (delta.attributeDeltas) {
					for (let attr of delta.attributeDeltas) {
						activity.getDeltas.push(new AttributeDelta(attr).buildFromTo());
					}
					activities.push(activity);
				}
				break;
		}
	}

	public getExclusionMappings() : {} { 
		let exclusionMappings = {
			"MSG_COUNT_IN_LAST_N_DAYS": "LAST_N_DAYS_FOR_MSG_COUNT",
			"MSG_COUNT_IN_LAST_N_HOURS": "LAST_N_HOURS_FOR_MSG_COUNT",
			"PURCHASES_IN_LAST_N_DAYS": "LAST_N_DAYS_FOR_PURCHASE_COUNT",
			"VIEWED_WISHLIST_COUNT": "VIEWED_WISHLIST_IN_LAST_N_PERIOD",
			"VIEWED_FAVORITE_COUNT": "VIEWED_FAVORITE_IN_LAST_N_PERIOD",
			"VIEWED_SEARCH_COUNT": "VIEWED_SEARCH_IN_LAST_N_PERIOD",
			"VIEWED_CART_COUNT": "VIEWED_CART_IN_LAST_N_PERIOD",
			"VIEWED_CATEGORY_COUNT": "VIEWED_CATEGORY_IN_LAST_N_PERIOD",
			"VIEWED_PRODUCT_COUNT": "VIEWED_PRODUCT_IN_LAST_N_PERIOD",
			"VIEWED_HOMEPAGE_COUNT": "VIEWED_HOMEPAGE_IN_LAST_N_PERIOD",
			"VIEWED_CHECKOUT_COUNT": "VIEWED_CHECKOUT_IN_LAST_N_PERIOD",
			"VIEWED_TH_HOMEPAGE_COUNT": "VIEWED_TH_HOMEPAGE_IN_LAST_N_PERIOD",
			"VIEWED_TH_RESERVATION_DETAILS_COUNT": "VIEWED_TH_RESERVATION_DETAILS_IN_LAST_N_PERIOD",
			"VIEWED_TH_PROPERTY_BROWSE_COUNT": "VIEWED_TH_PROPERTY_BROWSE_IN_LAST_N_PERIOD",
			"VIEWED_TH_SEARCH_COUNT": "VIEWED_TH_SEARCH_IN_LAST_N_PERIOD",
		}
		return exclusionMappings;
	};

}
