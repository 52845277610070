import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RulesHomeComponent } from './rules-home/rules-home.component';
import { RulesConfigurationComponent } from './rules-configuration/rules-configuration.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { RulesRoutingModule } from './rules-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { TableModule, PaginationModule, ActionModule, LoadingIndicatorModule, CollapsiblePanelModule } from 'epsilon-blueprint';
import { TreeModule } from '@circlon/angular-tree-component';
import { CoreUIModule } from '@epsilon/core-ui';

@NgModule({
  declarations: [RulesHomeComponent, RulesConfigurationComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RulesRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    TreeModule,
    DragDropModule,
    BaseTemplateModule,
    TableModule,
    PaginationModule,
    ActionModule,
    LoadingIndicatorModule,
    CollapsiblePanelModule,
    CoreUIModule
  ],
  providers: [DatePipe]
})
export class RulesModule { }