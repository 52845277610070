<app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
<div class="container-fluid object-page-header">
  <div class="row align-items-center u-py-25 h-10">
    <div class="col">
      <h1 class="pr-4">Batch Extracts</h1>
    </div>
    <div class="col-auto mr-4" *ngIf="authorizationService.isAllowed('CFG_BATCH_EXTRACT', ['CREATE'])">
      <app-ae-button buttonType="primary" (click)="handleAddBatchExtractAction()" [disabled]="!parentContextService.isParentContextSet()" buttonText="Create New Batch Extract"></app-ae-button>
    </div>
    <div *ngIf="authorizationService.isAllowed('CFG_BATCH_EXTRACT', ['READ'])">
      <ng-container *ngIf="isBatchExtractConfigurationModalDisplayed()">
        <app-configure-batch-extract
        [action]="getActionType()"
        [parentId]="getParentId()"
        [id]="getId()"
        [eventSources]="getEventSources()"
        [packagedConfiguredPrograms]="getPackagedConfiguredPrograms()"
        [configuredBatchExtracts]="getConfiguredBatchExtracts()"
        (isModalDisplayed)="setBatchExtractConfigurationModalStatus($event)"
        (batchExtractAction)="captureBatchExtractAction($event)"></app-configure-batch-extract>
      </ng-container>
    </div>
  </div>
</div>
<app-ae-loading-indicator [isLoading]="isOperationInProcess()" [type]="'loading bar'"></app-ae-loading-indicator>
<div class="container-fluid my-3" *ngIf="!isOperationInProcess()">
  <ng-container *ngIf="!parentContextService.isParentContextSet()">
    <div class="row">
      <div class="col text-center">
        <p class="text-style-muted-3 m-0">Please set up a Parent in Settings to create a Batch Extract</p>
      </div>
    </div>
  </ng-container>
  <div class="row" >
    <div class="col-12">
      <app-ae-table [isDataLoading]="isDataLoading" [properties]="properties"
      [configuredTableData]="configuredBatchExtractsTableFilteredData" (actionClick)="actionClick($event)">
    </app-ae-table>
    </div>
  </div>
</div>
<ng-template #modifiedDate let-rowData>
  {{ rowData.modifiedDate | date:'yy/dd/MM' }}
</ng-template>
