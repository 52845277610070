import { DataTypes } from '../custom-rule/conditions/data-types';
import { BooleanOperators } from '../custom-rule/operators/rule-operators';

export enum ExclusionOperator {
  EQ_IGNORECASE = 'EQ_IGNORECASE',
  NEQ_IGNORECASE = 'NEQ_IGNORECASE',
  ENDSWITH_IGNORECASE = 'ENDSWITH_IGNORECASE',
  STARTSWITH_IGNORECASE = 'STARTSWITH_IGNORECASE',
  LIKE = 'LIKE',
  NOTLIKE = 'NOTLIKE',
  IN_IGNORECASE = 'IN_IGNORECASE',
  NOTIN_IGNORECASE = 'NOTIN_IGNORECASE',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  EQ = 'EQ',
  NEQ = 'NEQ',
  LT = 'LT',
  LTEQ = 'LTEQ',
  GT = 'GT',
  GTEQ = 'GTEQ',
  LATEST_OCCURRENCE = 'LATEST_OCCURRENCE',
  LATEST_N_OCCURRENCE = 'LATEST_N_OCCURRENCE',
  LATEST_FREQUENCY_OCCURRENCE = 'LATEST_FREQUENCY_OCCURRENCE',
  IN = 'IN',
  NOTIN = 'NOTIN',
  ISNULL = 'ISNULL',
  NOTISNULL = 'NOTISNULL'
}

export class Operators {

  value: ExclusionOperator;
  display: string;
  constructor(value: ExclusionOperator, display: string) {
    this.value = value;
    this.display = display;
  }

}

export const StringExclusionOperators = {
  EQ_IGNORECASE: new Operators(ExclusionOperator.EQ_IGNORECASE, 'Equals'),
  NEQ_IGNORECASE: new Operators(ExclusionOperator.NEQ_IGNORECASE, 'Does not equal'),
  ENDSWITH_IGNORECASE: new Operators(ExclusionOperator.ENDSWITH_IGNORECASE, 'Ends with'),
  STARTSWITH_IGNORECASE: new Operators(ExclusionOperator.STARTSWITH_IGNORECASE, 'Starts with'),
  LIKE: new Operators(ExclusionOperator.LIKE, 'Matches'),
  NOTLIKE: new Operators(ExclusionOperator.NOTLIKE, 'Does not match'),
  IN_IGNORECASE: new Operators(ExclusionOperator.IN_IGNORECASE, 'In'),
  NOTIN_IGNORECASE: new Operators(ExclusionOperator.NOTIN_IGNORECASE, 'Is not in')
};

export const IntegerExclusionOperators = {
  EQ: new Operators(ExclusionOperator.EQ, 'Equals'),
  NEQ: new Operators(ExclusionOperator.NEQ, 'Does not equal'),
  LT: new Operators(ExclusionOperator.LT, 'Less than'),
  LTEQ: new Operators(ExclusionOperator.LTEQ, 'Less than or equal to'),
  GT: new Operators(ExclusionOperator.GT, 'Greater than'),
  GTEQ: new Operators(ExclusionOperator.GTEQ, 'Greater than or equal to'),
  IN: new Operators(ExclusionOperator.IN, 'In'),
  NOTIN: new Operators(ExclusionOperator.NOTIN, 'Is not in'),
  ISNULL: new Operators(ExclusionOperator.NEQ, 'Is null'),
  NOTISNULL: new Operators(ExclusionOperator.NEQ, 'Is not null')
};

export const ArrayExclusionOperators = {
  LATEST_N_OCCURRENCE: new Operators(ExclusionOperator.LATEST_N_OCCURRENCE, 'Latest N Occurrences'),
  LATEST_FREQUENCY_OCCURRENCE: new Operators(ExclusionOperator.LATEST_FREQUENCY_OCCURRENCE, 'Frequency of Occurrences')
};

export const BooleanExclusionOperators = {
  EQ: new Operators(ExclusionOperator.EQ, 'Equals'),
  NEQ: new Operators(ExclusionOperator.NEQ, 'Does not equal')
};

export const DateExclusionOperators = {
  EQ: new Operators(ExclusionOperator.EQ, 'Equals'),
  NEQ: new Operators(ExclusionOperator.NEQ, 'Does not equal'),
  BEFORE: new Operators(ExclusionOperator.LT, 'Is before'),
  AFTER: new Operators(ExclusionOperator.LTEQ, 'Is after'),
  LT: new Operators(ExclusionOperator.LT, 'Is less than'),
  LTEQ: new Operators(ExclusionOperator.LTEQ, 'Is on or before'),
  GT: new Operators(ExclusionOperator.GT, 'Is greater than'),
  GTEQ: new Operators(ExclusionOperator.GTEQ, 'Is on or after'),
  LATEST_OCCURRENCE: new Operators(ExclusionOperator.LATEST_OCCURRENCE, 'Latest Occurrence')
};

export const ExclusionOperatorDisplayNameMap: Record<ExclusionOperator, string> = {
  [ExclusionOperator.EQ]: BooleanOperators.EQ.display,
  [ExclusionOperator.NEQ]: BooleanOperators.NEQ.display,
  [ExclusionOperator.EQ_IGNORECASE]: 'Equals',
  [ExclusionOperator.NEQ_IGNORECASE]: 'Does not equal',
  [ExclusionOperator.ENDSWITH_IGNORECASE]: 'Ends with',
  [ExclusionOperator.STARTSWITH_IGNORECASE]: 'Starts with',
  [ExclusionOperator.LIKE]: 'Matches',
  [ExclusionOperator.NOTLIKE]: 'Does not match',
  [ExclusionOperator.IN_IGNORECASE]: 'In',
  [ExclusionOperator.NOTIN_IGNORECASE]: 'Is not in',
  [ExclusionOperator.BEFORE]: 'Is before',
  [ExclusionOperator.AFTER]: 'Is after',
  [ExclusionOperator.LT]: 'Is less than',
  [ExclusionOperator.LTEQ]: 'Is on or before',
  [ExclusionOperator.GT]: 'Is greater than',
  [ExclusionOperator.GTEQ]: 'Is on or after',
  [ExclusionOperator.LATEST_OCCURRENCE]: 'Latest Occurrence',
  [ExclusionOperator.LATEST_N_OCCURRENCE]: 'Latest N Occurrences',
  [ExclusionOperator.LATEST_FREQUENCY_OCCURRENCE]: 'Frequency of Occurrences',
  [ExclusionOperator.IN]: 'In',
  [ExclusionOperator.NOTIN]: 'Is not in',
  [ExclusionOperator.ISNULL]: 'Is null',
  [ExclusionOperator.NOTISNULL]: 'Is not null'
};

export class ExclusionOperators {

  exclusionOperators: Operators[];
  constructor(type: string) {
    switch (type.toUpperCase()) {
      case DataTypes.string: {
        this.exclusionOperators = Object.values(StringExclusionOperators);
        break;
      }
      case DataTypes.number: {
        this.exclusionOperators = Object.values(IntegerExclusionOperators);
        break;
      }
      case DataTypes.array: {
        this.exclusionOperators = Object.values(ArrayExclusionOperators);
        break;
      }
      case DataTypes.boolean: {
        this.exclusionOperators = Object.values(BooleanExclusionOperators);
        break;
      }
      case DataTypes.date: {
        this.exclusionOperators = Object.values(DateExclusionOperators);
        break;
      }
    }
  }

}
