import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityLookupService {

  host = environment.host;
  base = environment.base;
  lookUpUrl = 'event-activity/lookup';

  constructor(private http: HttpClient) { }

  public getEventActivityLookUp(activityLookupRequest: string): Observable<any> {
    const url = this.host + this.base + this.lookUpUrl;
    return this.http.post(url, activityLookupRequest);
  }

}