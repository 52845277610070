<app-base-template>
    <app-coreui-modal-warning></app-coreui-modal-warning>
    <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-auto p-0">
       <bp-collapsible-panel>
            <bp-nav-secondary panel-content [items]="programsNavTabs"></bp-nav-secondary>
       </bp-collapsible-panel>
      </div>
      <div class="col px-0">
          <app-programs-header></app-programs-header>
          <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</app-base-template>
