import { PackagedProgramAction } from './action/packaged-program-action';
import { ActionType } from './action/action-type';
import { ExternalSystemSource } from './external-system-source-enum';

export class PackagedProgramRule {

  ruleId: string;
  product: string;
  service: string;
  actions: string;

  public buildPackagedProgramRule(ruleId: string, actions: PackagedProgramAction[]): PackagedProgramRule {
    const packagedProgramRule: PackagedProgramRule = new PackagedProgramRule();
    packagedProgramRule.ruleId = ruleId;
    const updatedActions = JSON.parse(JSON.stringify(actions));
    updatedActions.forEach(action => {
      if (action.actionSettingsInput['serviceInput'].allocations !== undefined
      && action.actionSettingsInput['serviceInput'].allocations instanceof Array) {
        action.actionSettingsInput['serviceInput'].allocations = JSON.stringify(action.actionSettingsInput['serviceInput'].allocations);
      }
      if (action.actionInputs[0] === ExternalSystemSource.IQ ) {
        action.actionType = ActionType.JAR;
        action.actionSettingsInput['serviceInput'] = null;
      }
      action.actionSettingsInput['serviceInput'] = JSON.stringify(action.actionSettingsInput['serviceInput']);
    });
    packagedProgramRule.actions = JSON.stringify(updatedActions);
    return packagedProgramRule;
  }

  public getAllActions(): PackagedProgramAction[] {
    return JSON.parse(this.actions).length > 0 ? JSON.parse(this.actions) : [new PackagedProgramAction()];
  }

  public getActions(actionType: ActionType) {
    let actions: PackagedProgramAction[] = [];
    if (actionType === ActionType.API_ACTION || actionType === ActionType.API) {
      actions = this.getApiActions();
    } else if (actionType === ActionType.SCHEDULED_EVENT) {
      actions = this.getScheduledActions();
    } else if (actionType === ActionType.JAR){
      actions = this.getJarActions();
    }
    return actions;
  }

  private getJarActions(): PackagedProgramAction[] {
    const actions: PackagedProgramAction[] = typeof this.actions==='string'?JSON.parse(this.actions):this.actions;
    const apiActions: PackagedProgramAction[] = actions.filter(action => action.actionType === ActionType.JAR);
    return apiActions;
  }

  private getApiActions(): PackagedProgramAction[] {
    const actions: PackagedProgramAction[] = typeof this.actions==='string'?JSON.parse(this.actions):this.actions;
    const apiActions: PackagedProgramAction[] = actions.filter(action => (action.actionType === ActionType.API_ACTION || action.actionType === ActionType.API));
    apiActions.forEach(action => {
      const serviceInput = JSON.parse(action.actionSettingsInput['serviceInput']);
      if(serviceInput.allocations !== undefined && (!(serviceInput.allocations instanceof Array))){
        serviceInput['allocations'] = JSON.parse(serviceInput.allocations);
      }
      action.actionSettingsInput['serviceInput'] = serviceInput;
    });
    return apiActions;
  }

  private getScheduledActions(): PackagedProgramAction[] {
    const actions: PackagedProgramAction[] = JSON.parse(this.actions);
    const apiActions: PackagedProgramAction[] = actions.filter(action => action.actionType === ActionType.SCHEDULED_EVENT);
    return apiActions;
  }

}