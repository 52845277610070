import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Parent } from '../models/parent';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParentService {

  private parentUrl: string;

  constructor(private http: HttpClient) {
     this.parentUrl = environment.host + environment.base + 'parent';
  }

  public addParent(parent: Parent) : Observable<any>  {
    return this.http.post(this.parentUrl, parent);
  }

  public getParent(id: string) : Observable<any>  {
    return this.http.get(this.parentUrl + "/" + id);
  }

  public getParents() : Observable<any>  {
    return this.http.get(this.parentUrl);
  }

  public updateParent(id: string, parent: Parent) : Observable<any>  {
    return this.http.put(this.parentUrl + "/" + id, parent);
  }
}

