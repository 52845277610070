import { Condition } from './condition';
import { ConditionType } from './condition-type';

export class NotCondition implements Condition {

  public type: ConditionType;
  private condition: Condition;

  constructor(condition: Condition) {
    this.condition = condition;
    this.type = ConditionType.NOT;
  }

  public add(condition: Condition): NotCondition {
    this.condition = condition;
    return this;
  }

}