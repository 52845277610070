export class ConfiguredBatchExtractTableRow {

  id: string;
  name: string;
  eventSource: string;
  eventNames: string;
  companyName: string;
  schedule: string;
  recurrence: string;
  modifiedDate: string;
  routerLink: string;
  eventSourceName: string;
  modifiedBy: string;
}