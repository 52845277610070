import { Component, OnInit, ViewChild } from '@angular/core';
import { ProgramSharedDataService } from '../program-shared-data.service';
import { ProgramData } from 'src/app/shared/models/program-data';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { Program, PackagedRuleSettings, RelatedRulesSettings, RulesRelationType, UIControlType }
  from 'src/app/shared/models/program';
import { Configuration, EXCLUSIONS } from 'src/app/shared/models/configuration';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { Router } from '@angular/router';
import { DisplayTextService } from 'src/app/shared/services/display-text.service';
import { Rule } from 'src/app/shared/models/rule';
import { PackagedProgramService } from 'src/app/shared/services/packaged-program.service';
import { PackagedProgramAction } from 'src/app/shared/models/action/packaged-program-action';
import { ActionType } from 'src/app/shared/models/action/action-type';
import { PackagedProgramRule } from 'src/app/shared/models/packaged-program-rule';
import * as moment from 'moment';
import { Constants } from 'src/app/shared/constants';
import { Configurations } from 'src/app/shared/configurations';
import { ExclusionDetails } from 'src/app/shared/exclusion-details';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Allocation } from 'src/app/shared/models/allocations/allocation';
import { AllocationEntity } from 'src/app/shared/models/allocations/allocation-entity';
import { JSONFileService } from 'src/app/shared/services/json-file-service';
import { PackagedProgramWithoutReminder } from 'src/app/shared/packaged-program-without-reminder.enum';
import { InputSettingDetails, DelayInputDetails } from 'src/app/shared/models/input-setting-details';
import { DelayType } from 'src/app/shared/models/delay-type.enum';
import { DurationInputSubTypes } from 'src/app/shared/models/duration-input-sub-types';
import { Pattern } from 'src/app/shared/pattern';
import { Messages } from 'src/app/shared/message';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { Mode } from 'src/app/shared/models/mode-enum';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { Observable } from 'rxjs';
import { WarningType } from 'src/app/shared/warning-options';
import { DataTypes } from 'src/app/shared/models/custom-rule/conditions/data-types';
import { IQServiceNames } from 'src/app/shared/iq-service';
import { BaseFormDirective } from '../../../shared/models/base-form-configuration/base-form.directive';
import { LoginServiceLocator } from '../../../shared/services/login.service-locator';
import { ExternalSystemSource } from '../../../shared/models/external-system-source-enum';
import { int } from 'aws-sdk/clients/datapipeline';

const WITHOUT_SCHEDULE_ACTION_REMINDER_DEFAULT_VALUE = '-1';
const WITH_SCHEDULE_ACTION_REMINDER_DEFAULT_VALUE = '1';
const DEFAULT_VALUE_TYPE = DelayType.DURATION;
const DEFAULT_VALUE_SUBTYPE = 'HOURS';

@Component({
  selector: 'app-program-rule',
  templateUrl: './program-rule.component.html'
})
export class ProgramRuleComponent extends BaseFormDirective implements OnInit {

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  public warningModal: CoreuiModalWarningComponent;
  public currysRMNEntityContent = PackagedProgramWithoutReminder.CurrysRMNEntityContent;

  isRuleExpanded = true;
  expandMultipleTemplates = true;
  isExclusionExpanded = true;
  programData: ProgramData;
  isEdit = false;
  companyList = new Map<string, string>();
  programDetails: Program = new Program();
  eventType: string;
  packagedProgramId: string;
  configuredProgramId: string;
  packagedProgramRules: PackagedProgramRule[];
  tenantProgram = new ConfiguredProgram();
  newCreatedProgram = new ConfiguredProgram();
  businessunitList = new Map<string, Map<string, string>>();
  public isInvalidInput: Map<string, boolean>;
  public invalidInputErrorMessage: Map<string, string>;
  public showWarningThreshold: Map<string, string>;
  public isReadOnly = false;
  availableServicesPerRuleMapping = {};
  eventSource: string;
  eventSourceName: string;
  programDetailsPath: string;
  public programTemplateHaveChanged = false;
  public PROGRAM_EXCLUSIONS = EXCLUSIONS;
  public UIControlType = UIControlType;
  public applicableExclusions = [];
  public delayValidationStatus = {};
  public relatedRulesSettingsForSchedules: Map<string, string[]> = new Map<string, string[]>();
  public withReminderRule: string;
  public isUnsupportedProgram = false;
  public timeDimensionUnit: string[] = [];
  public delayValidationMessage = new Map<string, string>();
  public eventAttrVal: any;
  public isDataSaved = false;
  public iqServiceActionIndex: string[] = [];
  public iqServices = IQServiceNames;
  public ruleApiActionMapClone = '';
  public defaultActionDestination = 'HARMONY';
  public welcomeJournyRuleId = 'WelcomeJourneyWithReminder_x_WelcomeJourney';
  private isAllocationsModalShown: boolean;
  private allocatableActions: any;
  private allocatableActionIndex: number;

  constructor(public packagedService?: PackagedProgramService,
    public programDataService?: ProgramSharedDataService,
    private actionDestinationService?: ActionDestinationService,
    private loginServiceLocator?: LoginServiceLocator,
    private popupService?: PopupMessageService,
    private router?: Router,
    private eventSourceService?: EventSourceService,
    public displayTexts?: DisplayTextService,
    public modal?: NgbModal,
    public jsonFileService?: JSONFileService,
    public authorizationService?: AuthorizationService) {
    super();
    this.programData = new ProgramData();
  }

  ngOnInit(): void {
    this.programDataService.canNavigateAway = false;
    this.programDataService.ruleApiActionMap = new Map<string, PackagedProgramAction[]>();
    this.prepareDurationUnits();
    this.programDetailsPath = this.packagedService.programDetailsPath;
    this.programData = this.programDataService.getProgramData();
    this.packagedProgramRules = [];
    this.tenantProgram.configuration = new Configuration();
    this.isInvalidInput = new Map<string, boolean>();
    this.invalidInputErrorMessage = new Map<string, string>();
    this.showWarningThreshold = new Map<string, string>();
    this.tenantProgram = this.programData.tenantProgram;
    this.eventSource = this.programData.eventSource;
    this.doGetCompanyDetails();
    if (this.programData.operation === 'add') {
      this.packagedProgramId = this.programData.programId;
      this.doGetProgramDetails();
    } else if (this.programData.operation === 'edit' || this.programData.operation === 'validate') {
      this.programData = this.programDataService.getProgramData(); // Addresses slow observable
      this.eventType = this.programData.tenantProgram.configuration.eventType;
      this.configuredProgramId = this.programData.programId;
      this.doGetConfiguredProgramDetails();
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  }

  public canNavigateAway(): boolean {
    if (this.programDataService.isDataSaved || this.programDataService.isDeleteOperation || this.programDataService.apiCallCount !== 0 ||
        this.programDataService.isReadOnly() || !this.loginServiceLocator.getLoginService().isLoggedIn()) {
      this.programDataService.canNavigateAway = true;
      return true;
    }
    this.programDataService.canNavigateAway = !this.isAnyUnsavedDataInRuleComponent() && this.programDataService.header.canDeactivate();
    return this.programDataService.canNavigateAway;
  }

  private isAnyUnsavedDataInRuleComponent(): boolean {
    return this.compareRuleActions()
        || (JSON.stringify(this.programDataService.programInput) !==
            this.programDataService.programData.tenantProgram.configuration.programInput)
        || this.programDataService.companyId !== this.programDataService.programData.tenantProgram.configuration.companyId;
  }

  private compareRuleActions(): boolean {
    let actionMap;
    Array.from(this.programDataService.ruleApiActionMap.entries()).forEach(array => {
      array[1].forEach( arr => {
        actionMap = arr.actionInputs.filter( arg => arg !== null);
        arr.actionInputs = actionMap;
      });
    });
    return this.ruleApiActionMapClone !==
        JSON.stringify(Array.from(this.programDataService.ruleApiActionMap))
        || this.programDataService.apiActionIdScheduleMapClone !==
        JSON.stringify(Array.from(this.programDataService.apiActionIdScheduleMap.entries()));
  }

  doGetCompanyDetails(): void {
    this.eventSourceService.getCompanies(this.programData.parentId, this.eventSource).subscribe(
      res => {
        this.companyList = res['result']['settings'];
      },
      (error: any) => { });
  }

  doGetProgramDetails(): void {
    this.programDataService.apiCallCount++;
    this.packagedService.getProgramDetails(this.programData.parentId, this.programData.eventSource, this.packagedProgramId).subscribe(
      (res: any) => {
        this.doGetBUDetails(this.defaultActionDestination);
        this.programDetails = new Program(res);
        this.programDataService.programData.packagedRuleSettings = this.programDetails.packagedRuleSettings;
        this.eventType = this.programDetails.eventType;
        this.eventSource = this.programDetails.eventSource;
        this.eventSourceName = this.programDetails.eventSourceName;
        this.tenantProgram.packagedProgramName = this.programDetails.programName;
        this.tenantProgram.description = this.programDetails.description;
        this.setDefaultValueForExistingProgram();
        this.initConfigAction();
        this.programDataService.programData.tenantProgram.active = false;
        this.doSetAvailableServicesPerRuleMapping(this.programDetails.rules);
        this.doSetRelatedRulesSettingsForSchedules();
        this.setRuleActionClones();
        this.programDataService.programData.tenantProgram.configuration.programInput = JSON.stringify(this.programDataService.programInput);
        this.isReadOnly = this.programDataService.isReadOnly();
        this.programDataService.apiCallCount--;
      },
      (error: any) => {
        this.programDataService.apiCallCount--;
      });
  }

  doGetProgramSettingDetails(packagedProgramName: string): void {
    this.programDataService.apiCallCount++;
    this.packagedService.getProgramDetails(this.programData.parentId, this.programData.eventSource, packagedProgramName).subscribe(
      (res: any) => {
        this.programDetails = new Program(res);
        this.setDefaultValueForExistingProgram();
        this.doSetAvailableServicesPerRuleMapping(this.programDetails.rules);
        this.doSetRelatedRulesSettingsForSchedules();
        this.programDataService.programData.packagedRuleSettings = this.programDetails.packagedRuleSettings;
        this.setRuleActionClones();
        this.programDataService.apiCallCount--;
      },
      (error: any) => {
        const errorMessage: string = error.error.statusMessage as string;
        if (errorMessage === 'NOT_FOUND') {
          this.isUnsupportedProgram = true;
          this.programData.isUnsupportedProgram = true;
        }
        this.programDataService.apiCallCount--;
      });
  }

  initConfigAction(): void {
    this.programDetails.rules.forEach(rule => {
      const packagedProgramRule = new PackagedProgramRule();
      packagedProgramRule.ruleId = this.buildProgramRuleId(rule.ruleId, rule.eventType);
      const apiActions: PackagedProgramAction[] = [];
      if (!this.isReminderRule(packagedProgramRule.ruleId)) {
        this.programDataService.ruleApiActionMap.set(packagedProgramRule.ruleId, apiActions);
      }
      if (rule.actionDestinationSettings.length > 0) {
        if (this.isReminderRule(packagedProgramRule.ruleId)) {
          this.withReminderRule = packagedProgramRule.ruleId;
          this.setDefaultValueForReminderDelay(packagedProgramRule.ruleId,
            WITHOUT_SCHEDULE_ACTION_REMINDER_DEFAULT_VALUE, DEFAULT_VALUE_TYPE, DEFAULT_VALUE_SUBTYPE);
        } else {
          this.addAction(packagedProgramRule.ruleId);
        }
      }
    });
  }

  doGetConfiguredProgramDetails(): void {
    this.programDataService.apiCallCount++;
    this.packagedService.getConfiguredProgramDetails(this.programData.parentId, this.configuredProgramId).subscribe(
      res => {
        this.tenantProgram = new ConfiguredProgram(res);
        this.programDataService.programData.tenantProgram = new ConfiguredProgram(res);
        this.programDataService.companyId = this.tenantProgram.configuration.companyId;
        this.eventSource = this.tenantProgram.configuration.eventSource;
        this.eventSourceName = this.tenantProgram.configuration.eventSourceName;
        this.eventType = this.tenantProgram.configuration.eventType;
        if (this.tenantProgram.configuration.programInput !== undefined) {
          this.programDataService.programInput = JSON.parse(this.tenantProgram.configuration.programInput);
        }
        this.packagedProgramRules = JSON.parse(this.tenantProgram.configuration.rules);
        this.getActionDestinationFromConfiguredProgramRule().forEach(ruleAction => {
          this.doGetBUDetails(ruleAction);
        });
        this.initRuleApiActionMap();
        this.initApiActionIdScheduleMap();
        this.programDataService.programData.rules = this.packagedProgramRules;
        this.doGetProgramSettingDetails(this.tenantProgram.packagedProgramName);
        this.programDataService.programData.tenantProgram.configuration.programInput = JSON.stringify(this.programDataService.programInput);
        this.isReadOnly = this.programDataService.isReadOnly();
        this.programDataService.apiCallCount--;
      },
      (error: any) => {
        this.programDataService.apiCallCount--;
      });
  }

  private getActionDestinationFromConfiguredProgramRule(): Set<string> {
    const ruleActionDestination = new Set<string>();
    this.packagedProgramRules.forEach(rule => {
      JSON.parse(rule.actions).forEach(action => {
        if (action.actionInputs !== undefined) {
          ruleActionDestination.add(action.actionInputs[0]);
        }
      });
    });
    return ruleActionDestination;
  }

  doGetBUDetails(actionDestination: string): void {
    this.actionDestinationService.getBUs(this.programData.parentId, actionDestination).subscribe(
      (res: any) => {
        this.businessunitList.set(actionDestination, res.result.actionSettings);
      },
      (error: any) => {
      });
  }

  filterConfigActions = actions => { // callback function for filter pipe
    return actions.value.length > 0; // return true if configured action has actions
  };

  doPostConfiguredProgram(): void {
    this.tenantProgram.name = this.tenantProgram.name.trim();
    this.updateConfiguredProgram();
    if (this.programData.operation === 'edit' || this.programData.operation === 'validate') {
      this.packagedService.updateConfiguredProgram(
        this.tenantProgram, this.programData.parentId, this.programData.programId)
        .subscribe(
          res => {
            this.programDataService.programData.tenantProgram = new ConfiguredProgram(res);
            this.packagedProgramRules = JSON.parse(this.programDataService.programData.tenantProgram.configuration.rules);
            this.initRuleApiActionMap();
            this.initApiActionIdScheduleMap();
            this.setRuleActionClones();
            this.programTemplateHaveChanged = false;
            this.popupService.showSuccessMessage(Messages.updateProgramSuccessMessage);
            this.programDataService.isDataSaved = false;
          },
          (error: any) => {
            this.popupService.showErrorMessage(Messages.updateProgramErrorMessage);
          });
    } else {
      this.tenantProgram.mode = 'TEST';// default mode.
      this.packagedService.addConfiguredProgram(this.tenantProgram, this.programData.parentId).subscribe(
        res => {
          this.programDataService.isDataSaved = true;
          this.newCreatedProgram = new ConfiguredProgram(res);
          this.programDataService.programData.programId = this.newCreatedProgram.id;
          this.tenantProgram = this.newCreatedProgram;
          this.programDataService.programData.tenantProgram = this.newCreatedProgram;
          this.programDataService.programData.operation = 'edit';
          this.programDataService.programData.eventType = this.newCreatedProgram.id;
          this.router.navigateByUrl('programs', {skipLocationChange: true}).then( () => {
            this.router.navigateByUrl('/programs/configure' + '/' +
                this.programDataService.programData.programId + '/' + this.tenantProgram.configuration.eventSource + '/edit/rule');
          });
          this.popupService.showSuccessMessage(Messages.createProgramSuccessMessage, true);
        },
        (error: any) => {
          this.programDataService.isDataSaved = false;
          let errorMessage = Messages.createProgramErrorMessage;
          if (error.status === 400) {
            if (error.error.statusMessage === 'DUPLICATE_ITEM') {
              errorMessage = error.error.result;
            }
            if (error.error.statusMessage === 'DUPLICATE_PROGRAM') {
              errorMessage = `Another program with the name '${this.tenantProgram.name}' already exists.`;
            }
          }
          this.popupService.showErrorMessage(errorMessage);
        });
    }
  }

  updateConfiguredProgram(): void {
    this.tenantProgram.configuration.companyId = this.programDataService.companyId;
    this.tenantProgram.configuration.eventType = this.eventType;
    this.tenantProgram.configuration.eventSource = this.eventSource;
    this.tenantProgram.configuration.eventSourceName = this.eventSourceName;
    this.tenantProgram.active = false;
    if (this.tenantProgram.mode === null) {
      this.tenantProgram.mode = 'TEST';// default mode.
    }
    this.tenantProgram.programType = 'PACKAGED';
    this.packagedProgramRules = this.buildPackagedProgramRules();
    this.linkApiActionAndScheduleAction();
    const rules = JSON.stringify(this.packagedProgramRules);
    this.tenantProgram.configuration.rules = rules;
    this.tenantProgram.configuration.programInput = JSON.stringify(this.programDataService.programInput);
    this.tenantProgram.name = this.programData.tenantProgram.name;
    if (!this.tenantProgram.description) {
      // It should be removed once we have description input box in program configuration page
      this.tenantProgram.description = this.programDetails.description;
    }
    this.programDataService.programData.tenantProgram = this.tenantProgram;
    this.programDataService.programData.rules = this.packagedProgramRules;
  }

  public setExclusions(): void {
    if (this.programDetails.inputSettings && (Object.keys(this.programDetails.inputSettings).sort().toString()
      !== Object.keys(this.programDataService.programInput).sort().toString())) {
      this.programTemplateHaveChanged = true;
    }
    if (this.programDetails.inputSettings && !this.tenantProgram.active) {
      // remove exclusion from programInput if it not present in program template
      Object.keys(this.programDataService.programInput).forEach(exclusion => {
        if (!this.programDetails.inputSettings[exclusion]) {
          delete this.programDataService.programInput[exclusion];
        }
      });
      // add exclusion to programInput if it not present in configured program
      Object.keys(this.programDetails.inputSettings).forEach(exclusion => {
        if (!this.programDataService.programInput[exclusion]) {
          const inputSettingDetails = new InputSettingDetails();
          inputSettingDetails.value = (exclusion in this.programDetails.inputSettings)
            ? this.programDetails.inputSettings[exclusion].defaultValue : 0;
          inputSettingDetails.valueType = this.programDetails.inputSettings[exclusion].valueType;
          inputSettingDetails.valueSubtype = this.programDetails.inputSettings[exclusion].valueSubtype;
          this.programDataService.programInput[exclusion] = inputSettingDetails;
        }
      });
    }
    this.prepareApplicableExclusions();
  }

  prepareApplicableExclusions(): void {
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_MSG_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.MSG_COUNT_IN_LAST_N_HOURS]) {
      this.applicableExclusions.push(ExclusionDetails.HourlyExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_DAYS_FOR_PURCHASE_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.PURCHASES_IN_LAST_N_DAYS]) {
      this.applicableExclusions.push(ExclusionDetails.PurchaseExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_DAYS_FOR_SUBMIT_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.SUBMITS_IN_LAST_N_DAYS]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonCardExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_DAYS_FOR_MSG_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.MSG_COUNT_IN_LAST_N_DAYS]) {
      this.applicableExclusions.push(ExclusionDetails.WeeklyExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_WISHLIST_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_WISHLIST_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedWishlistExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_FAVORITE_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_FAVORITE_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedFavoritesExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_SEARCH_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_SEARCH_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedSearchExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_CART_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_CART_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedCartExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_CATEGORY_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_CATEGORY_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedCategoryExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_PRODUCT_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_PRODUCT_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedProductExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_HOMEPAGE_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_HOMEPAGE_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedHomePageExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_CHECKOUT_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_CHECKOUT_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedCheckoutExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_HOMEPAGE_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_HOMEPAGE_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedTHHomePageExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_RESERVATION_DETAILS_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_RESERVATION_DETAILS_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedTHReservationDetailsExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_PROPERTY_BROWSE_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_PROPERTY_BROWSE_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedTHPropertyBrowseExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_SEARCH_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.VIEWED_TH_SEARCH_IN_LAST_N_PERIOD]) {
      this.applicableExclusions.push(ExclusionDetails.ViewedTHSearchExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_CART]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonCartExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_CHECKOUT]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonCheckoutExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_PRODUCT]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonProductExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_CATEGORY]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonCategoryExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_SEARCH]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonSearchExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_PROMOTION]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonPromotionExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_WISHLIST]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonWishlistExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_ABANDONED_FAVORITES]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonFavoritesExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.SUBMITS_IN_LAST_N_DAYS]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonCardExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.SIGNUP_IN_LAST_N_DAYS]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonSignupExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_DAYS_FOR_CONFIRMATION_COUNT]
      && this.programDataService.programInput[EXCLUSIONS.CONFIRMATION_IN_LAST_N_DAYS]) {
      this.applicableExclusions.push(ExclusionDetails.ConfirmationExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_TH_ABANDONED_RESERVATION_DETAILS]) {
      this.applicableExclusions.push(ExclusionDetails.AbandonReservationDetailsExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LAST_N_HOURS_FOR_TH_ABANDONED_SEARCH]) {
      this.applicableExclusions.push(ExclusionDetails.THAbandonSearchExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.PERCENTAGE_VARIANCE]) {
      this.applicableExclusions.push(ExclusionDetails.VarianceExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.DROP_BRAND_NAME]) {
      this.applicableExclusions.push(ExclusionDetails.BrandNameExclusion);
    }
    if (this.programDataService.programInput[EXCLUSIONS.HIGHEST_PRICED_FILTER]) {
      this.applicableExclusions.push(ExclusionDetails.HighestPricedProductsFilter);
    }
    if (this.programDataService.programInput[EXCLUSIONS.LOWEST_PRICED_FILTER]) {
      this.applicableExclusions.push(ExclusionDetails.LowestPricedProductsFilter);
    }
  }

  setDefaultValueForExistingProgram(): void {
    if (this.tenantProgram.configuration.programInput === undefined) {
      this.programDataService.programInput = new Map<string, InputSettingDetails>();
    }
    this.setExclusions();
    this.programDetails.rules.forEach(rule => {
      const eventDelayType = rule.eventType + Constants.SUFFIX_DELAY;
      if (this.isEventDelayNotConfigured(eventDelayType)) {
        this.programDataService.programInput[eventDelayType] = this.programDetails.inputSettings[eventDelayType].defaultValue;
      }
    });
  }

  public validateInputSetting(inputField: string): void {
    this.showWarningThreshold[inputField] = undefined;
    const value: string = this.programDataService.programInput[inputField]['value'];
    const inputSettingValue = Number(value);
    const valueType: string = this.programDataService.programInput[inputField]['valueType'];
    if (valueType === DataTypes.string) {
      return;
    }
    const valueSubtype: string = this.programDataService.programInput[inputField]['valueSubtype'];
    const minAllowedValue = this.programDetails.inputSettings[inputField].minValue;
    const maxAllowedValue = this.programDetails.inputSettings[inputField].maxValue;
    if (valueType === DelayType.DURATION) {
      if (!this.isValidNumber(value)) {
        this.isInvalidInput[inputField] = true;
        this.invalidInputErrorMessage[inputField] = Messages.wholeNumberOrDecimalWithTwoPrecision;
        return;
      }
      if (!this.isPrecisionSupportedForTimeUnit(inputSettingValue, valueSubtype)) {
        this.isInvalidInput[inputField] = true;
        this.invalidInputErrorMessage[inputField] = Messages.wholeNumber;
        return;
      }
      const durationNotInLimitMessage = this.validateDurationLimit(inputField, inputSettingValue);
      if (durationNotInLimitMessage) {
        this.isInvalidInput[inputField] = true;
        this.invalidInputErrorMessage[inputField] = durationNotInLimitMessage;
        return;
      }
    } else {
      if (!this.isValidWholeNumber(value)) {
        this.isInvalidInput[inputField] = true;
        this.invalidInputErrorMessage[inputField] = Messages.wholeNumber;
        return;
      }
      if (!this.isInputValueInLimit(inputSettingValue, minAllowedValue, maxAllowedValue)) {
        this.isInvalidInput[inputField] = true;
        const minInputValue: number = this.programDetails.inputSettings[inputField].minValue;
        const maxInputValue: number = this.programDetails.inputSettings[inputField].maxValue;
        const message = `Enter a number between ${minInputValue} and ${maxInputValue}`;
        this.invalidInputErrorMessage[inputField] = message;
        return;
      }
    }
    const spamThreshold: string = this.programDetails.inputSettings[inputField].spamThreshold;
    if (this.isSpamThresholdCrossed(inputSettingValue, spamThreshold)) {
      const spamThresholdMesage = `Sending more than ${spamThreshold} messages within a short timeframe could be considered spam`;
      this.showWarningThreshold[inputField] = spamThresholdMesage;
      return;
    }
    this.isInvalidInput[inputField] = false;
    this.invalidInputErrorMessage[inputField] = undefined;
  }

  doSetAvailableServicesPerRuleMapping(rules: Rule[]): void {
    rules.forEach(rule => {
      const availableService = [];
      const key = this.buildProgramRuleId(rule.ruleId, rule.eventType);
      if (this.isReminderRule(key)) {
        this.withReminderRule = key;
      }
      rule.actionDestinationSettings.forEach(service => {
        availableService.push({ 'product': service.product, 'service': service.service,
          'productName': service.productName, 'actionMode': service.actionMode });
      });
      this.availableServicesPerRuleMapping[key] = availableService;
    });
  }

  getDedupedProductNames(serviceMapping: object[]) {
    const availableProducts: Map<string, string> = new Map<string, string>();
    if (serviceMapping) {
      serviceMapping.forEach(mapping => {
        if (!availableProducts.has(mapping['product'])) {
          availableProducts.set(mapping['product'], mapping['productName']);
        }
      });
    }
    return availableProducts;
  }

  public originalOrder(a, b): number {
    return 0;
  }

  public addAction(ruleId: string): void {
    if (this.isScheduledRule(ruleId)) {
      this.addScheduledAction(ruleId);
    } else {
      this.addAPIAction(ruleId);
    }
  }

  public deleteAction(ruleId: string, action: number): void {
    if (this.isScheduledRule(ruleId)) {
      this.deleteScheduledAction(ruleId, action);
    } else {
      this.deleteApiAction(ruleId, action);
    }
  }

  public showDeleteButton(ruleId: string, actionCount: number): boolean {
    return this.isReminderRule(ruleId) ? actionCount > 0 : actionCount > 1;
  }

  public getMaxAllowedActions(ruleId: string): number {
    let maxAllowedActions: number;
    if (this.isReminderRule(ruleId)) {
      maxAllowedActions = Configurations.MAX_ALLOWED_REMINDERS;
    } else {
      if (ruleId === this.welcomeJournyRuleId) {
        maxAllowedActions = Configurations.MAX_ALLOWED_ACTIONS_WELCOME_JOURNEY;
      } else {
        maxAllowedActions = Configurations.MAX_ALLOWED_ACTIONS;
      }
    }
    return maxAllowedActions;
  }

  public getAddActionButtonLabel(ruleId: string): string {
    let actionButtonLabel: string;
    if (this.isReminderRule(ruleId)) {
      if (Object.keys( this.programDataService.apiActionIdScheduleMap).length > 0) {
        actionButtonLabel = 'Add another reminder action';
      }
    } else {
      actionButtonLabel = 'Add another action';
    }
    return actionButtonLabel;
  }

  public getMaxActionsAddedErrorMessage(ruleId: string): string {
    let maxActionsAddedErrorMessage: string;
    const maxActionsAllowed: number = this.getMaxAllowedActions(ruleId);
    if (this.isReminderRule(ruleId)) {
      maxActionsAddedErrorMessage = `The maximum number of ${maxActionsAllowed} reminders has been added`;
    } else {
      maxActionsAddedErrorMessage = `The maximum number of ${maxActionsAllowed} actions has been added`;
    }
    return maxActionsAddedErrorMessage;
  }

  public buildPackagedProgramRules(): PackagedProgramRule[] {
    const packagedProgramRules: PackagedProgramRule[] = [];
    this.programDataService.ruleApiActionMap.forEach((actions: PackagedProgramAction[], ruleId: string) => {
      const packagedProgramRule: PackagedProgramRule
      = new PackagedProgramRule().buildPackagedProgramRule(ruleId, actions);
      packagedProgramRules.push(packagedProgramRule);
    });
    return packagedProgramRules;
  }

  public validateDelay(actionId: string, ruleId: string, delay: string, delayUnit: string): void {
    if (!this.isValidNumber(delay)) {
      this.delayValidationStatus[actionId] = false;
      this.delayValidationMessage[actionId] = Messages.wholeNumberOrDecimalWithTwoPrecision;
      return;
    }
    const inputDelay = Number(delay);
    if (!this.isPrecisionSupportedForTimeUnit(inputDelay, delayUnit)) {
      this.delayValidationStatus[actionId] = false;
      this.delayValidationMessage[actionId] = Messages.wholeNumber;
      return;
    }

    const durationNotInLimitMessage = this.validateDurationLimit(this.getInputSettingsDelayName(ruleId), inputDelay, delayUnit);
    if (durationNotInLimitMessage) {
      this.delayValidationStatus[actionId] = false;
      this.delayValidationMessage[actionId] = durationNotInLimitMessage;
      return;
    }
    this.delayValidationStatus[actionId] = true;
    return;
  }

  public hasInvalidDelayInActions(): boolean {
    return Object.values(this.delayValidationStatus).filter(valid => !valid).length > 0;
  }

  public isReminderRule(ruleId: string): boolean {
    const eventType: string = this.getEventTypeFromProgramRuleId(ruleId);
    return eventType.endsWith('Reminder');
  }

  public isScheduledRule(ruleId: string): boolean {
    let isScheduledRule = false;
    const inputSettingsDelayName = this.getInputSettingsDelayName(ruleId);
    const eventTypeDelay = this.programDataService.programInput[inputSettingsDelayName];
    if (eventTypeDelay) {
      isScheduledRule = true;
    }
    return isScheduledRule;
  }

  public getDelayMinValue(ruleId: string): string {
    const delayName = this.getInputSettingsDelayName(ruleId);
    const minAllowed = this.programDetails.inputSettings[delayName].minValue;
    return minAllowed;
  }

  public getDelayMaxValue(ruleId: string): string {
    const delayName = this.getInputSettingsDelayName(ruleId);
    const maxAllowed = this.programDetails.inputSettings[delayName].maxValue;
    return maxAllowed;
  }

  public getServicesInSelectedProduct(availableProdutServiceMapping: any, selectedProduct: string) {
    if (availableProdutServiceMapping) {
      return availableProdutServiceMapping.filter(entry => entry.product === selectedProduct);
    }
  }

  public getActionSettingsInSelectedservice(availableProdutServiceMapping: any, selectedService: string) : any {
    if (availableProdutServiceMapping) {
      return availableProdutServiceMapping.find(entry => entry.service === selectedService);
    }
  }


  public isSaveBtnDisabled(): boolean {
    return this.programData.tenantProgram.name === undefined
      || this.programData.tenantProgram.name.trim() === '' || this.programData.tenantProgram.active
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.MSG_COUNT_IN_LAST_N_HOURS]
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.MSG_COUNT_IN_LAST_N_DAYS]
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.PURCHASES_IN_LAST_N_DAYS]
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.SUBMITS_IN_LAST_N_DAYS]
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.LAST_N_HOURS_FOR_MSG_COUNT]
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.LAST_N_DAYS_FOR_MSG_COUNT]
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.LAST_N_DAYS_FOR_PURCHASE_COUNT]
      || this.isInvalidInput[this.PROGRAM_EXCLUSIONS.LAST_N_DAYS_FOR_SUBMIT_COUNT]
      || this.hasInvalidDelayInActions()
      || this.isUnsupportedProgram;
  }

  public onActionDestinationChange(ruleId: string, action: PackagedProgramAction) {
    if(!this.businessunitList.get(action.actionInputs[0])) {
      this.doGetBUDetails(action.actionInputs[0]);
    }
    const apiActions: PackagedProgramAction[] = this.programDataService.ruleApiActionMap.get(ruleId);
    const actionTobeUpdated: PackagedProgramAction = apiActions.find(nextAction => nextAction.id === action.id);
    const services
    = this.getServicesInSelectedProduct(this.availableServicesPerRuleMapping[ruleId], action.actionInputs[0]);
    actionTobeUpdated.actionInputs[1] = services[0].service;
    this.resetActionSettingsServiceInput(actionTobeUpdated);
  }

  public onServiceChange(ruleId: string, action: PackagedProgramAction, selectedService: string, index: number) : void {
    const apiActions: PackagedProgramAction[] = this.programDataService.ruleApiActionMap.get(ruleId);
    const actionTobeUpdated: PackagedProgramAction = apiActions.find(nextAction => nextAction.id === action.id);
    const actionsettings = this.getActionSettingsInSelectedservice(this.availableServicesPerRuleMapping[ruleId], action.actionInputs[1]);
    actionTobeUpdated.actionInputs[2] = actionsettings.actionMode;
    if (action.actionInputs[0] === 'IQ') {
      this.iqServiceActionIndex[index] = selectedService;
    }
    this.resetActionSettingsServiceInput(actionTobeUpdated);
  }

  private resetActionSettingsServiceInput(action: PackagedProgramAction) {
    if (action.actionSettingsInput !== null && action.actionSettingsInput !== undefined &&
      action.actionSettingsInput['serviceInput'] !== null && action.actionSettingsInput['serviceInput'] !== undefined) {
      action.actionSettingsInput['serviceInput'].businessUnitId = '';
      action.actionSettingsInput['serviceInput'].templateId = '';
      if ((action.actionSettingsInput['serviceInput']).hasOwnProperty('event_definition_key')) {
        delete action.actionSettingsInput['serviceInput'].event_definition_key;
      }
    }
  }

  public getServiceNameForAllocation(): String {
    return this.allocatableActions.value[this.allocatableActionIndex].actionInputs[1];
  }

  public getBusinessUnitIdForAllocation(): String {
    return this.getBusinessUnitName(this.businessunitList,
      this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput.businessUnitId);
  }

  public getAllocationCollection(): Allocation[] {
    if (this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput.allocations === undefined) {
      const allocations: Allocation[] = [];
      const allocationEntities:AllocationEntity[] = [];
      allocationEntities.push(new AllocationEntity('templateId',
        this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput.templateId));
      allocations.push(new Allocation(allocationEntities, 100));
      return allocations;
    }
    return (JSON.parse(JSON.stringify(this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput.allocations)));
  }

  public addTemplates(actions: any, index: number): void {
    this.allocatableActions = actions;
    this.allocatableActionIndex = index;
    this.isAllocationsModalShown = true;
  }

  public captureConfiguredAllocation(result: any): void {
    if (!result) {
      return;
    }
    if (result.length === 1 && result[0].allocationEntities[0].type === 'templateId') {
      this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput.templateId = result[0].allocationEntities[0].value;
      delete this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput['allocations'];
    } else if (result.length > 1) {
      this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput.allocations = result;
      delete this.allocatableActions.value[this.allocatableActionIndex].actionSettingsInput.serviceInput['templateId'];
    }
  }

  public doAllocationsExists(action, actionIndex): boolean {
    if (action.actionSettingsInput.serviceInput.allocations === undefined
       || (action.actionSettingsInput.serviceInput.allocations !== undefined
        && action.actionSettingsInput.serviceInput.allocations.length === 1)) {
      return true;
    }
    return false;
  }

  public getAllocations(action) {
    if (action.actionSettingsInput.serviceInput.allocations !== undefined
      && !(action.actionSettingsInput.serviceInput.allocations instanceof Array)) {
      action.actionSettingsInput.serviceInput.allocations =
      JSON.parse(action.actionSettingsInput.serviceInput.allocations);
      return action.actionSettingsInput.serviceInput.allocations;
    }
    return action.actionSettingsInput.serviceInput.allocations;
  }

  public doesTemplateExist(action: PackagedProgramAction): boolean {
    if (action.actionInputs[1] === Constants.PROFILE_SEARCH || action.actionInputs[1] === Constants.PROFILE_UPDATE ||
      action.actionInputs[1] === Constants.PROFILE_GET) {
      return true;
    }
    return false;
  }

  public isAsynchronousAction(actionRow: PackagedProgramAction): boolean {
    return new PackagedProgramAction(actionRow).isAsynchronous();
  }

  public addScheduledAction(ruleId: string): void {
    if (this.isReminderRule(ruleId)
    && (!this.programDataService.ruleApiActionMap.get(ruleId) || (this.programDataService.ruleApiActionMap.get(ruleId) && this.programDataService.ruleApiActionMap.get(ruleId).length === 0))) {
      const apiActions: PackagedProgramAction[] = [];
      this.programDataService.ruleApiActionMap.set(ruleId, apiActions);
      this.setDefaultValueForReminderDelay(ruleId, WITH_SCHEDULE_ACTION_REMINDER_DEFAULT_VALUE, DEFAULT_VALUE_TYPE, DEFAULT_VALUE_SUBTYPE);
    }
    const apiAction: PackagedProgramAction = this.addAPIAction(ruleId);
    const delayName = this.getInputSettingsDelayName(ruleId);
    const delayInputDetails = new DelayInputDetails();
    const delayDetails = Object.assign(this.programDataService.programInput[delayName]);
    delayInputDetails.delay = delayDetails.value;
    delayInputDetails.delayType = delayDetails.valueType ? delayDetails.valueType : 'DURATION';
    delayInputDetails.delaySubtype = delayDetails.valueSubtype ? delayDetails.valueSubtype : 'HOURS';
    this.programDataService.apiActionIdScheduleMap[apiAction.id] = delayInputDetails;
    // to make an entry into entries array
    this.programDataService.apiActionIdScheduleMap.set(apiAction.id, delayInputDetails);
  }

  public showAddReminderLink() : boolean {
    let showAddAReminderLink = false;
    if (!(this.programDetails.programName in PackagedProgramWithoutReminder) && (!this.programDataService.ruleApiActionMap.get(this.withReminderRule)
      || (this.programDataService.ruleApiActionMap.get(this.withReminderRule) && this.programDataService.ruleApiActionMap.get(this.withReminderRule).length === 0))) {
      showAddAReminderLink = true;
    }
    return showAddAReminderLink;
  }

  public isAllocationsModalDisplayed(): boolean {
    return this.isAllocationsModalShown;
  }

  public setAllocationsModalDisplayStatus($event: boolean): void {
    this.isAllocationsModalShown = $event;
  }

  public launchUnsavedChangesModal(): Observable<any> {
    this.warningModal.launchModal(WarningType.UNSAVED_CHANGES_WARNING, {
      msg2: [Messages.loseUnsavedChanges]
    });
    return this.warningModal.decision.asObservable();
  }

  public isIqRecommendationAction(action: PackagedProgramAction): boolean {
    return action.actionInputs[1] !== this.iqServices.dmsRecommendationFunction &&
        action.actionInputs[1] !== this.iqServices.formulaRecommendationFunction &&
        action.actionInputs[1] !== this.iqServices.mppRecommendationFunction &&
        action.actionInputs[1] !== this.iqServices.offerRecommendation;
  }

  public handleDecision(decision: boolean): void {
    if (this.warningModal.warningType === WarningType.UNSAVED_CHANGES_WARNING && decision) {
      this.programDataService.header.discardProgramNameChanges();
    }
  }

  private doSetRelatedRulesSettingsForSchedules() {
    const packagedRuleSettingsWithSchedules: PackagedRuleSettings[]
    = this.programDetails.getPackagedRuleSettingsByRelationType(RulesRelationType.SCHEDULES);
    packagedRuleSettingsWithSchedules.forEach(nextPackagedRuleSetting => {
      const ruleIdToHoldSchedules
      = this.buildProgramRuleId(nextPackagedRuleSetting.ruleId, nextPackagedRuleSetting.eventType);
      let ruleIdsToBeScheduled: string[] = [];
      nextPackagedRuleSetting.relatedRulesSettings.forEach(nextRelatedRulesSettings => {
        ruleIdsToBeScheduled = this.getRuleIdsToBeScheduled(nextRelatedRulesSettings);
      });
      this.relatedRulesSettingsForSchedules.set(ruleIdToHoldSchedules, ruleIdsToBeScheduled);
    });
  }

  private getRuleIdsToBeScheduled(relatedRulesSettings: RelatedRulesSettings): string[] {
    const ruleIdsToBeScheduled: string[] = [];
    relatedRulesSettings.packagedRuleIds.forEach(ruleId => {
      ruleIdsToBeScheduled.push(this.buildProgramRuleId(ruleId, this.programDetails.getRuleEventType(ruleId)));
    });
    return ruleIdsToBeScheduled;
  }

  private initRuleApiActionMap() {
    this.packagedProgramRules.forEach(nextPackagedProgramRule => {
      const packagedProgramRule: PackagedProgramRule
      = Object.assign(new PackagedProgramRule(), nextPackagedProgramRule);
      packagedProgramRule.getAllActions().forEach(action => {
        if (action.actionType === ActionType.JAR) {
          this.programDataService.ruleApiActionMap.set(packagedProgramRule.ruleId, packagedProgramRule.getActions(ActionType.JAR));
          this.initIqServiceAction(packagedProgramRule);
        } else {
          this.programDataService.ruleApiActionMap.set(packagedProgramRule.ruleId, packagedProgramRule.getActions(ActionType.API_ACTION));
        }
      });
    });
  }

  private initIqServiceAction(packagedProgramRule: PackagedProgramRule) {
    let i = 0;
    packagedProgramRule.getActions(ActionType.JAR).forEach(action => {
      this.iqServiceActionIndex[i] = action.actionInputs[1];
      i = i + 1;
    });
  }

  private initApiActionIdScheduleMap() {
    this.packagedProgramRules.forEach(nextPackagedProgramRule => {
      const packagedProgramRule: PackagedProgramRule
      = Object.assign(new PackagedProgramRule(), nextPackagedProgramRule);
      const scheduledAction = packagedProgramRule.getActions(ActionType.SCHEDULED_EVENT);
      scheduledAction.forEach(action => {
        const delayInputDetails = new DelayInputDetails();
        const delay = action.actionDelay.delay;
        delayInputDetails.delay = delay;
        delayInputDetails.delayType = (action.actionDelay.delayType ? action.actionDelay.delayType : 'DURATION');
        delayInputDetails.delaySubtype = (action.actionDelay.delaySubtype ? action.actionDelay.delaySubtype : 'HOURS');
        this.programDataService.apiActionIdScheduleMap[action.actionSettingsInput['triggerActionId']] = delayInputDetails;
        // to make an entry into entries array
        this.programDataService.apiActionIdScheduleMap.set(action.actionSettingsInput['triggerActionId'], delayInputDetails);
      });
    });
    this.programDataService.apiActionIdScheduleMapClone = JSON.stringify(Array.from(this.programDataService.apiActionIdScheduleMap.entries()));
  }


  private addAPIAction(ruleId: string): PackagedProgramAction {
    const programDetailsRuleId = this.getRuleIdFromProgramRuleId(ruleId);
    const rule = this.programDetails.rules.find(nextRule => nextRule.ruleId === programDetailsRuleId);
    const service = rule.actionDestinationSettings[0];
    const apiAction = new PackagedProgramAction().buildApiAction(service.product, service.service, '', '');
    const apiActions = this.programDataService.ruleApiActionMap.get(ruleId);
    apiActions.push(apiAction);
    if (apiAction.actionInputs[0] === ExternalSystemSource.IQ) {
      this.setIqServiceActionIndex(apiActions.length - 1, apiAction.actionInputs[1]);
    }
    return apiAction;
  }

  private setIqServiceActionIndex(i: int, actionInput: string): void {
    this.iqServiceActionIndex[i] = actionInput;
  }

  private deleteApiAction(ruleId: string, action: number) {
    const apiActions = this.programDataService.ruleApiActionMap.get(ruleId);
    apiActions.splice(action, 1);
  }

  private deleteScheduledAction(ruleId: string, action: number) {
    const apiActions = this.programDataService.ruleApiActionMap.get(ruleId);
    const deletedAction: PackagedProgramAction = apiActions.splice(action, 1)[0];
    this.programDataService.apiActionIdScheduleMap.delete(deletedAction.id);
    this.programDataService.apiActionIdScheduleMapClone =
        JSON.stringify(Array.from(this.programDataService.apiActionIdScheduleMap.entries()));
    if (apiActions.length === 0) {
      this.programDataService.ruleApiActionMap.delete(ruleId);
      this.setDefaultValueForReminderDelay(ruleId,
          WITHOUT_SCHEDULE_ACTION_REMINDER_DEFAULT_VALUE, DEFAULT_VALUE_TYPE, DEFAULT_VALUE_SUBTYPE);
    }
  }

  private setDefaultValueForReminderDelay(ruleId: string, defaultValue: string, valueType: DelayType, valueSubtype: string) {
    const eventType: string = this.getEventTypeFromProgramRuleId(ruleId);
    const eventDelayType = eventType + Constants.SUFFIX_DELAY;
    const inputSettingDetails = new InputSettingDetails();
    inputSettingDetails.value = defaultValue;
    inputSettingDetails.valueType = valueType;
    inputSettingDetails.valueSubtype = valueSubtype;
    this.programDataService.programInput[eventDelayType] = inputSettingDetails;
  }

  private linkApiActionAndScheduleAction(): void {
    this.relatedRulesSettingsForSchedules.forEach((ruledIdsToBeScheduled: string[], ruleIdToHoldSchedules: string) => {
      ruledIdsToBeScheduled.forEach(nextRuledIdToBeScheduled => {
        this.addScheduledActionsInRule(ruleIdToHoldSchedules, nextRuledIdToBeScheduled);
      });
    });
  }

  private addScheduledActionsInRule(ruleIdToHoldSchedules: string, ruleIdToBeScheduled: string) {
    const ruleToHoldSchedules: PackagedProgramRule = this.packagedProgramRules.find(nextPackagedProgramRule =>
      ruleIdToHoldSchedules === nextPackagedProgramRule.ruleId);
    const ruleToBeScheduled: PackagedProgramRule = this.packagedProgramRules.find(nextPackagedProgramRule =>
      ruleIdToBeScheduled === nextPackagedProgramRule.ruleId);
    const ruleActionsWithScheduledActions = JSON.parse(ruleToHoldSchedules.actions);
    if (!ruleToBeScheduled) {
      return;
    }
    const apiActions = JSON.parse(ruleToBeScheduled.actions);
    apiActions.forEach((apiAction: PackagedProgramAction) => {
      if (apiAction.actionInputs[2] !== Mode.SYNCHRONOUS.toLowerCase()) {
        const triggerEventName = this.getEventTypeFromProgramRuleId(ruleToBeScheduled.ruleId);
        const durationHours = Number(this.programDataService.apiActionIdScheduleMap[apiAction.id]['delay']);
        const delayType = this.programDataService.apiActionIdScheduleMap[apiAction.id]['delayType'];
        const delaySubtype = this.programDataService.apiActionIdScheduleMap[apiAction.id]['delaySubtype'];
        const scheduledAction = new PackagedProgramAction().buildScheduledAction(triggerEventName, durationHours, delayType, delaySubtype);
        // link api action id into scheduled action
        scheduledAction.actionSettingsInput['triggerActionId'] = apiAction.id;
        ruleActionsWithScheduledActions.push(scheduledAction);
        // link scheduled action id into api action
        apiAction.actionSettingsInput['scheduledActionId'] = scheduledAction.id;
      }
    });
    ruleToBeScheduled.actions = JSON.stringify(apiActions);
    ruleToHoldSchedules.actions = JSON.stringify(ruleActionsWithScheduledActions);
  }

  private getEventTypeFromProgramRuleId(ruleId: string): string {
    const ruleIdParts: string[] = ruleId.split(Constants.DB_KEY_SEPARATOR);
    return ruleIdParts[1];
  }

  private getRuleIdFromProgramRuleId(ruleId: string): string {
    const ruleIdParts: string[] = ruleId.split(Constants.DB_KEY_SEPARATOR);
    return ruleIdParts[0];
  }

  private buildProgramRuleId(ruleId: string, eventType: string): string {
    return ruleId + Constants.DB_KEY_SEPARATOR + eventType;
  }

  private getInputSettingsDelayName(ruleId: string): string {
    return this.getEventTypeFromProgramRuleId(ruleId) + Constants.SUFFIX_DELAY;
  }

  private getBusinessUnitName(map, searchValue) {
    for (let [key, value] of map.entries()) {
      if (value.buId === searchValue) {
        return value.buName;
      }
    }
  }

  private isEventDelayNotConfigured(eventDelayType: string): boolean {
    return this.programDetails.inputSettings && this.programDetails.inputSettings.hasOwnProperty(eventDelayType)
    && !this.programDataService.programInput[eventDelayType];
  }

  private prepareDurationUnits() {
    this.timeDimensionUnit = Object.keys(DurationInputSubTypes).filter((nextDurationInputSubTypes: DurationInputSubTypes) =>
      nextDurationInputSubTypes !== DurationInputSubTypes.MILLIS
      && nextDurationInputSubTypes !== DurationInputSubTypes.MONTHS
      && nextDurationInputSubTypes !== DurationInputSubTypes.YEARS).map(key => DurationInputSubTypes[key]);
  }

  private getTimeValueByUnit(delayValue: number, unit: string): number {
    switch (unit) {
      case DurationInputSubTypes.WEEKS:
        delayValue = Math.floor(delayValue / (7 * 24 * 60 * 60));
        break;
      case DurationInputSubTypes.DAYS:
        delayValue = delayValue / (24 * 60 * 60);
        break;
      case DurationInputSubTypes.HOURS:
        delayValue = delayValue / (60 * 60);
        break;
      case DurationInputSubTypes.MINUTES:
        delayValue = delayValue / 60;
        break;
      default:
        break;
    }
    return delayValue;
  }

  private isValidNumber(inputNumber: string): boolean {
    return new RegExp(Pattern.FLOAT_WITH_2_DECIMALS).test(inputNumber);
  }

  private isValidWholeNumber(inputNumber: string): boolean {
    return new RegExp(Pattern.INTEGER).test(inputNumber);
  }

  private isPrecisionSupportedForTimeUnit(inputNumber: number, timeUnit: string): boolean {
    if ((timeUnit === DurationInputSubTypes.SECONDS || timeUnit === DurationInputSubTypes.MINUTES)
      && inputNumber % 1 > 0) {
      return false;
    } else {
      return true;
    }
  }

  private validateDurationLimit(inputSetting: string, inputSettingValue: number, durationUnit?: string): string {
    const minAllowedValue = this.programDetails.inputSettings[inputSetting].minValue;
    const maxAllowedValue = this.programDetails.inputSettings[inputSetting].maxValue;
    if (!durationUnit) {
      durationUnit = this.programDataService.programInput[inputSetting]['valueSubtype'];
    }
    const minDelayInSeconds = moment.duration(minAllowedValue).asSeconds();
    const maxDelayInSeconds = moment.duration(maxAllowedValue).asSeconds();
    const inputSettingValueInSeconds = moment.duration(
      inputSettingValue, durationUnit.toLowerCase() as moment.unitOfTime.DurationConstructor).asSeconds();
    if (!this.isInputValueInLimit(inputSettingValueInSeconds, minDelayInSeconds, maxDelayInSeconds)) {
      let minInputValue: number = this.getTimeValueByUnit(minDelayInSeconds, durationUnit);
      minInputValue = minInputValue > 1 ? minInputValue : 1;
      const maxInputValue: number = this.getTimeValueByUnit(maxDelayInSeconds, durationUnit);
      return `Enter a number between ${minInputValue} and ${maxInputValue}`;
    }
    return '';
  }

  private isInputValueInLimit(inputNumber: number, minAllowed: number, maxAllowed: number): boolean {
    if (inputNumber < minAllowed || inputNumber > maxAllowed) {
      return false;
    }
    return true;
  }

  private isSpamThresholdCrossed(inputSettingValue: number, spamThreshold: string): boolean {
    if (spamThreshold && !isNaN(Number(spamThreshold)) && inputSettingValue > Number(spamThreshold)) {
      return true;
    }
    return false;
  }

  private setRuleActionClones(): void {
    let actionMap;
    Array.from(this.programDataService.ruleApiActionMap.entries()).forEach(array => {
      array[1].forEach( arr => {
        actionMap = arr.actionInputs.filter( arg => arg !== null);
        arr.actionInputs = actionMap;
      });
    });
    this.ruleApiActionMapClone = JSON.stringify(Array.from(this.programDataService.ruleApiActionMap.entries()));
    this.programDataService.apiActionIdScheduleMapClone =
        JSON.stringify(Array.from(this.programDataService.apiActionIdScheduleMap.entries()));
  }

}
