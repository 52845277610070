<form name="exclusionConfigurationForm" [formGroup]="exclusionConfigurationFormGroup">
  <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMFORTABLE" class="container-fluid">
    <div class="row border border-color-secondary rounded my-2">
      <div class="col align-self-center py-3">
        {{ getAttributeName() | lowercase }}
      </div>
      <div class="col-auto py-2 ml-auto">
        <button coreUIButton="icon-large" withBorder [disabled]="!canDeleteExclusionConfiguration()"
          (click)="onEntityStateAttributeDelete()"><i coreUIIcon="trash"></i></button>
      </div>
    </div>
    <div class="row border border-color-secondary rounded my-2">
      <div class="col-auto py-3">
        {{displayExclusionOperator | lowercase}}
      </div>
      <div class="col-auto mt-2 px-0 inline-form-field" *ngIf= !isBeforeAfterOperator()>
        <coreui-form-field>
          <input type="text" coreUIInput formControlName="conditionValue" placeholder="Enter a date" withIcon
            [owlDateTime]="valuePicker">
          <i coreUIIcon="calendar" scale="1.5" class="date-time-picker-calendar-icon"
            [owlDateTimeTrigger]="valuePicker"></i>
          <owl-date-time #valuePicker></owl-date-time>
          <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
        </coreui-form-field>
      </div>
      <div class="col-auto px-0" *ngIf= isBeforeAfterOperator()>
        <div class="row">
          <div class="col-auto mt-2 inline-form-field px-3">
            <coreui-form-field>
              <input type="text" [coreUIDecimalMask]="decimalMaskConfig" coreUIInput name="conditionValue"
                formControlName="conditionValue" placeholder="Enter the value" />
              <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
            </coreui-form-field>
          </div>
          <diV class="col-auto mt-2 px-0">
            <coreui-select formControlName="durationUnit" placeholder="Select a value">
              <coreui-option *ngFor="let durationUnitName of durationUnitList" [value]="durationUnitName"
                [selected]="exclusionConfigurationFormGroup.controls[formControlNames.DURATION_UNIT].value === durationUnitName">
                {{durationUnitName | lowercase}}
              </coreui-option>
            </coreui-select>
          </diV>
          <div class="col-auto py-3">
            <label *ngIf="exclusionOperator === 'BEFORE'">ago</label>
            <label *ngIf="exclusionOperator === 'AFTER'">from now</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="exclusionDisplayMode === exclusionDisplayModes.COMPACT"
    class="col-auto border border-color-secondary rounded align-self-center py-2">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-auto py-2 pl-2 pr-0">
            {{ getAttributeName() | lowercase }}
          </div>
          <div class="col-auto py-2 pl-1 pr-0">
            {{ displayExclusionOperator | lowercase}}
          </div>
          <div class="col-auto mt-1 px-2 inline-form-field" *ngIf= !isBeforeAfterOperator()>
            <coreui-form-field>
              <input type="text" coreUIInput formControlName="conditionValue" placeholder="Enter a date" withIcon
                [owlDateTime]="valuePicker">
              <i coreUIIcon="calendar" scale="1.5" class="date-time-picker-calendar-icon"
                [owlDateTimeTrigger]="valuePicker"></i>
              <owl-date-time #valuePicker></owl-date-time>
              <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
            </coreui-form-field>
          </div>
          <div class="col-auto" *ngIf= isBeforeAfterOperator()>
            <div class="row">
              <div class="col-auto mt-1 px-2 inline-form-field">
                <coreui-form-field>
                  <input type="text" [coreUIDecimalMask]="decimalMaskConfig" coreUIInput name="conditionValue"
                    formControlName="conditionValue" placeholder="Enter the value" />
                  <coreui-form-error>{{messages.enterValue}}</coreui-form-error>
                </coreui-form-field>
              </div>
              <diV class="col-auto mt-1 px-2 inline-form-field">
                <coreui-select formControlName="durationUnit" placeholder="Select a value">
                  <coreui-option *ngFor="let durationUnitName of durationUnitList" [value]="durationUnitName"
                    [selected]="exclusionConfigurationFormGroup.controls[formControlNames.DURATION_UNIT].value === durationUnitName">
                    {{durationUnitName | lowercase}}
                  </coreui-option>
                </coreui-select>
              </diV>
              <div class="col-auto py-2 pl-1 pr-0">
                <label *ngIf="exclusionOperator === 'BEFORE'">ago</label>
                <label *ngIf="exclusionOperator === 'AFTER'">from now</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto align-self-center">
        <div class="row">
          <div class="col-auto ml-auto px-2">
            <button coreUIButton="feedback-error-secondary" [disabled]="!canDeleteExclusionConfiguration()"
              (click)="onEntityStateAttributeDelete()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
