<app-base-template>
    <app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
    <app-coreui-modal-warning (decision)="handleDecision($event)"></app-coreui-modal-warning>
    <app-ae-modal-info></app-ae-modal-info>
    <form *ngIf="!isApiServiceCalled" [formGroup]="configuredEntityForm">
    <div class="container-fluid object-page-header pt-4">
       <div class="row mb-2">
          <div class="col">
             <app-ae-button buttonText="Back to Rules" iconPosition="left" icon="chevronLeft" [routerLink]="'/rules'"></app-ae-button>
          </div>
       </div>
       <div class="row align-items-top u-pb-25">
          <div class="col">
             <div class="form-group mb-0">
                <input type="text" id="ruleNameControl" class="form-control object-page-header-input"
                   formControlName="ruleName" placeholder="Rule Name" (keyup)="validateRuleNameUniqueness()"/>
                <label for="ruleNameControl" class="object-header-label">Rule</label>
                <div *ngIf="ruleName.invalid && isSaveClicked" class="text-color-warning">
                   <ng-container *ngIf="ruleName.errors.required"> Please enter a rule name </ng-container>
                   <ng-container *ngIf="ruleName.errors.alreadyExist"> Rule name
                      <span class="font-weight-bold">
                      {{ruleName.value}}
                      </span> already exists.
                   </ng-container>
                </div>
             </div>
          </div>
         <div class="row" *ngIf="inputParams.action === operationType.EDIT">
            <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_RULE', ['DELETE'])">
               <app-ae-button class="ml-4" buttonText="Delete" (click)="showDeleteConfiguredRuleAlert()"
                  [disabled]="isDeleteDisabled()">
               </app-ae-button>
            </div>
            <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_RULE', ['CREATE','UPDATE'])">
               <app-ae-button class="mr-3" buttonText="Copy" (click)="copyConfiguredRule()"></app-ae-button>
            </div>
         </div>
          <div class="col-auto pl-0" *ngIf="authorizationService.isAllowed('CFG_RULE', ['CREATE','UPDATE'])">
             <app-ae-button buttonText="Save" (click)="doSaveConfiguredRule()" [disabled]="hasAssociations()"></app-ae-button>
          </div>
       </div>
    </div>
    <div class="container-fluid pt-4">
       <app-ribbon *ngIf="hasOperationFailed()"
       ribbonStyle="ribbon-alert"
       [ribbonMessage]="getOperationFailureMessage()"
       ribbonHasButton="true"
       [buttonText]="getAssociationsBtnTxt()"
       (buttonClick)="openAssociationsModal()">
       </app-ribbon>
       <div class="row">
          <bp-collapsible-panel bpID="leftPane">
             <div panel-content class="mx-3 horizontal-scrollbar-disabled">
                <div class="row">
                   <div class="col">
                      <div class="form-group">
                         <label for="eventSourceProvider">Event Source Provider</label>
                         <coreui-select id="eventSourceProvider" #selectedEventSource
                         (blur)="this.onEventSourceChanged(selectedEventSource.value)" [(ngModel)]="eventSourceProvider"
                         [ngModelOptions]="{standalone: true}" [disabled]="isReadOnlyRule">
                         <coreui-option [value]=undefined [disabled]="true">
                         Select an event source
                         </coreui-option>
                         <coreui-option *ngFor="let item of eventSources" value="{{item.eventSource}}">
                            {{item.name}}
                         </coreui-option>
                         </coreui-select>
                      </div>
                   </div>
                </div>
                <div class="row">
                   <div class="col">
                      <div class="form-group">
                         <label for="eventNameControl">Event</label>
                         <coreui-select id="eventNameControl" formControlName="eventName" [disabled]="eventSourceProvider ? null : true"
                         (blur)="this.loadRightPanel(this.configuredEntityForm.controls.eventName.value, true)">
                         <coreui-option value="" [disabled]="true" [selected]="true">Select an event</coreui-option>
                         <coreui-option *ngFor="let item of events" value="{{item}}">
                            {{item}}
                         </coreui-option>
                         </coreui-select>
                         <div *ngIf="eventName.invalid && (eventName.dirty || eventName.touched)" class="text-color-warning">
                            <div *ngIf="eventName.errors.required"> Please select an event</div>
                         </div>
                      </div>
                   </div>
                </div>
                <div coreUIScrollIndicatorTarget class="vertical-scrollable-leftpane">
                   <coreui-accordion>
                      <coreui-accordion-pane label="Event Attributes" [active]="ruleConfigurations" *ngIf="attributesExist">
                      <tree-root *ngIf="attributesExist" [nodes]="eventTreeNodes"
                      [options]="options" (initialized)="onTreeLoad($event)" (updateData)="onTreeLoad($event)">
                      </tree-root>
                      </coreui-accordion-pane>
                      <coreui-accordion-pane label="Actions" [active]="ruleConfigurations" *ngIf="actionExist">
                      <coreui-accordion>
                        <ng-container *ngFor="let action of actionTreeNodes | keyvalue; let i=index">
                           <coreui-accordion-pane label="{{ action.key }}" [active]="actions">
                              <tree-root [nodes]="action.value" [options]="options" (initialized)="onActionTreeLoad($event)" (updateData)="onActionTreeLoad($event)"></tree-root>
                           </coreui-accordion-pane>
                        </ng-container>
                      </coreui-accordion>
                      </coreui-accordion-pane>
                      <coreui-accordion-pane label="Entity States" [active]="ruleConfigurations" *ngIf="entityStateExist">
                      <coreui-accordion>
                         <ng-container *ngFor="let entityState of entityStateTreeNodeList | keyvalue; let i=index">
                            <coreui-accordion-pane label="{{ entityState.key }}" [active]="entityStates">
                               <tree-root [nodes]="entityState.value" [options]="options" (initialized)="onEntityStatesTreeLoad($event)" (updateData)="onEntityStatesTreeLoad($event)"></tree-root>
                            </coreui-accordion-pane>
                         </ng-container>
                      </coreui-accordion>
                      </coreui-accordion-pane>
                   </coreui-accordion>
                </div>
             </div>
          </bp-collapsible-panel>
          <div id="rightPane" class="col">
             <div *ngIf="isSaveClicked && !attributesExist" class="text-color-warning">
                Please select an event and add at least one action to complete your rule
             </div>
             <div class="row">
                <div class="col">
                   <app-rule-builder  [eventSources]="eventSources" [configuredEventDetails] ="configuredEventDetails" [actionDestinations]="actionDestinations"
                   [formGroup]="configuredEntityForm"
                   [filteredConfiguredActions]="filteredConfiguredActions" [rule]="configuredRule"
                   [attributeMap]="attributeMap" [isReadOnlyRule]="isReadOnlyRule"
                   [ruleComponentIndex]="ruleComponentIndex" [validationRequired]="false" [selectedEventSchema]="selectedEventSchema">
                   </app-rule-builder>
                </div>
             </div>
             <div class="row border-bottom border-top py-1 my-5">
                <div class="col">
                   <coreui-accordion>
                      <coreui-accordion-pane label="Description" [active]="description">
                         <div class="row">
                            <div class="col">
                            </div>
                         </div>
                         <div class="row">
                            <div class="col">
                               <label for="ruleDescription">Rule Description (optional)</label>
                               <textarea id="ruleDescription" formControlName="ruleDescription" class="form-control"
                                  placeholder="Enter rule description" rows="4"></textarea>
                            </div>
                         </div>
                      </coreui-accordion-pane>
                   </coreui-accordion>
                </div>
             </div>
          </div>
       </div>
    </div>
    </form>
 </app-base-template>
