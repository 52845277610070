import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseTemplateComponent } from './base-template.component';
import { PrimaryNavigationComponent } from './primary-navigation/primary-navigation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ParentSwitcherComponent } from './primary-navigation/parent-switcher/parent-switcher.component';
import { RouterModule } from '@angular/router';
import { CoreUIModule } from '@epsilon/core-ui';
import { SharedModule } from '../../shared.module';


@NgModule({
  declarations: [
    BaseTemplateComponent,
    PrimaryNavigationComponent,
    ParentSwitcherComponent
  ],
  imports: [CommonModule, NgbModule, FormsModule, RouterModule, CoreUIModule, SharedModule],
  exports: [BaseTemplateComponent]
})
export class BaseTemplateModule {}