import { Condition } from './condition';
import { ConditionType } from './condition-type';
import { ContainerCondition } from './container-condition';

export class AndCondition implements ContainerCondition {

  type: ConditionType;
  conditions: Condition[];

  constructor(conditions: Condition[]) {
    this.conditions = conditions;
    this.type = ConditionType.AND;
  }

  public add(condition: Condition): AndCondition {
    this.conditions.push(condition);
    return this;
  }

}