import { Component, OnInit, OnDestroy, Injectable, Injector, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ProgramSharedDataService } from '../program-shared-data.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { takeUntil } from 'rxjs/operators';
import { ProgramData } from 'src/app/shared/models/program-data';
import { ConfiguredProgram } from 'src/app/shared/models/configured-program';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { DisplayTextService } from 'src/app/shared/services/display-text.service';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { CoreuiModalWarningComponent } from 'src/app/shared/component/modal/coreui-modal-warning/coreui-modal-warning.component';
import { Messages } from 'src/app/shared/message';
import { CustomProgramBuilderService } from 'src/app/shared/services/custom-program-builder.service';
import { ConfiguredEvent, ConfiguredEventId } from 'src/app/shared/models/configured-event.model';
import { EventService } from 'src/app/shared/services/event.service';
import { CustomRule } from 'src/app/shared/models/custom-rule/custom-rule';
import { DroolsRule } from 'src/app/shared/models/rule/drools-rule';
import { RuleTypes, Constants } from 'src/app/shared/constants';
import { ProgramType } from 'src/app/shared/program-type';
import { EntityCopy } from 'src/app/shared/models/entity-copy.model';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { EntityCopyService } from 'src/app/shared/services/entity-copy.service';
@Component({
  selector: 'app-programs-header',
  templateUrl: './programs-header.component.html'
})

@Injectable()
export class ProgramsHeaderComponent implements OnInit, OnDestroy {

  @ViewChild(CoreuiModalWarningComponent, { static: true })
  private warningModal: CoreuiModalWarningComponent;

  eventsource: string;
  isApiCallForNameUpdate: boolean;
  isProgramNameInputFocused = false;
  persistedConfiguredProgram = new ConfiguredProgram();
  apiCallCount = 0;
  isEdit = false;
  parentId: string;
  operation: string;
  configuredProgramId: string;
  public programName = '';
  isDuplicateProgramName: boolean;
  public programData: ProgramData;
  public authfeatureName;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private isDeleteConfiguredProgramAlertShown: boolean;
  private deleteConfiguredProgramWarningOption;

  public isChangeLogModalShown: boolean;

  constructor(
    private programService: ProgramService,
    private eventService: EventService,
    private router: Router,
    private popupService: PopupMessageService,
    private programDataService: ProgramSharedDataService,
    private parentContextService: ParentContextService,
    private route: ActivatedRoute,
    public displayTexts: DisplayTextService,
    public injector: Injector,
    public authorizationService: AuthorizationService,
    private entityCopyService: EntityCopyService,
    private customProgramBuilderService?: CustomProgramBuilderService
    ) {
    this.programData = new ProgramData();
    this.parentId = this.parentContextService.getParentContext();
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.eventsource = route.snapshot.paramMap.get('eventsource');
      this.operation = route.snapshot.paramMap.get('action');
      this.configuredProgramId = route.snapshot.paramMap.get('id');
    });
  }

  public ngOnInit(): void {
    this.programDataService.currentApiFlag.subscribe(isApiCallForNameUpdate => this.isApiCallForNameUpdate = isApiCallForNameUpdate);
    this.programData = this.programDataService.getProgramData();
    this.persistedConfiguredProgram = this.programData.tenantProgram;
    this.programData.parentId = this.parentId;
    if (this.programData.operation === 'edit' || this.programData.operation === 'validate') {
      this.doGetConfiguredProgramDetails();
      if (this.programDataService.eventSchemaMap) {
        this.buildEventSchemaMap();
      }
      this.isEdit = true;
    } else {
      this.persistedConfiguredProgram.name = '';
    }
    this.programData.tenantProgram = this.persistedConfiguredProgram;
    this.programData.apiCallCount = 1;
    this.programDataService.setProgramData(this.programData);
    this.authfeatureName = this.persistedConfiguredProgram.getAuthorizationFeatureName();
  }

  doGetConfiguredProgramDetails(): void {
    this.apiCallCount++;
    this.programService.getConfiguredProgramDetails(this.programData.parentId, this.configuredProgramId)
      .subscribe( res => {
        this.persistedConfiguredProgram = new ConfiguredProgram(res);
        this.apiCallCount--;
        this.programName = this.persistedConfiguredProgram.name ? this.persistedConfiguredProgram.name : '';
        this.authfeatureName = this.persistedConfiguredProgram.getAuthorizationFeatureName();
      }, () => {
        this.apiCallCount--;
      });
  }

  public buildEventSchemaMap(): void {
    this.apiCallCount++;
    this.customProgramBuilderService.getConfiguredProgramDetails(this.programData.parentId, this.programData.programId).subscribe(
      res => {
        this.programData.tenantProgram = new ConfiguredProgram(res);
        const rules: CustomRule[] = JSON.parse(res['result'].configuration.rules);
        if (this.programData.tenantProgram.programType === ProgramType.SELF_SERVICE_EXTERNAL) {
          this.buildSelfServiceExternalProgramEventSchema(rules);
        } else if (this.programData.tenantProgram.programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
          this.buildSelfServiceExternalPackagedProgramEventSchema(rules);
        }
        this.apiCallCount--;
      }, () => {
        this.apiCallCount--;
      });
  }

  public initConfiguredEvent(eventSourceName: string, eventName: string): void {
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = eventSourceName;
    configuredEventId.eventName = eventName;
    this.eventService.getConfiguredEvent(this.parentId, configuredEventId).pipe(takeUntil(this.destroyed$)).subscribe(
      (value: any) => {
        const configuredEvent = new ConfiguredEvent(value.result);
        this.programDataService.addToEventSchemaMap(configuredEvent);
      }
    );
  }

  public initPackagedEventSchema(eventSourceName: string, eventName: string): void {
    const configuredEventId: ConfiguredEventId = new ConfiguredEventId();
    configuredEventId.eventSourceName = eventSourceName;
    configuredEventId.eventName = eventName;
    this.apiCallCount++;
    this.eventService.getPackagedEvent(this.parentId, configuredEventId).pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        const configuredEvent = new ConfiguredEvent(value.result);
        this.programDataService.addToEventSchemaMap(configuredEvent);
        this.apiCallCount--;
      }, () => {
        this.apiCallCount--;
      });
  }

  public copyConfiguredProgram(): void {
    this.programDataService.isApiServiceCalled = true;
    const feature = (this.programData.tenantProgram.programType === ProgramType.PACKAGED) ? Feature.PKG_PRG : Feature.CUST_PRG;
    this.entityCopyService.copyEntity(this.parentId, EntityCopy.build(this.programData.programId, feature))
      .pipe(takeUntil(this.destroyed$)).subscribe((res) => {
        this.programDataService.isApiServiceCalled = false;
        this.popupService.showSuccessMessage(`Copy Successful. New Program '${<string>res['result'].name}' successfully created.`);
      }, error => {
        this.programDataService.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.result);
      });
  }

  updateProgramName(): void {
    this.persistedConfiguredProgram.name = this.persistedConfiguredProgram.name.trim();
    this.programDataService.setApiCallForNameUpdate(true);
    // Check program name is empty
    this.isDuplicateProgramName = false;
    if (this.persistedConfiguredProgram.name.length === 0) {
      this.isProgramNameInputFocused = false;
      return;
    }
    // Check program name is same
    if (this.persistedConfiguredProgram.name === this.programName) {
      this.programDataService.setApiCallForNameUpdate(false);
      this.isProgramNameInputFocused = false; // disappear save button without making any change
      return;
    }
    this.isProgramNameInputFocused = false; // disappear save button without making any change
    this.programService.updateNameInConfiguredProgram(this.programData.parentId, this.programData.programId,
      this.persistedConfiguredProgram.name).pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.isDuplicateProgramName = false;
        this.programName = this.persistedConfiguredProgram.name;
        this.programDataService.programData.tenantProgram.name = this.programName;
        this.programDataService.setApiCallForNameUpdate(false);
      }, (error: any) => {
        if (error.status === 400) {
          this.isDuplicateProgramName = true;
        }
        this.programDataService.setApiCallForNameUpdate(false);
      }
    );
  }

  public removeConfiguredProgram(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteConfiguredProgram,
      msg: `Are you sure you want to delete program ${this.persistedConfiguredProgram.name} ?`
    });
  }

  public isDeleteConfiguredProgramAlertDisplayed(): boolean {
    return this.isDeleteConfiguredProgramAlertShown;
  }

  public getDeleteConfiguredProgramWarningOption(): any {
    return this.deleteConfiguredProgramWarningOption;
  }

  public handleDeleteConfiguredProgramDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.programService.deleteConfiguredProgram(this.programData.parentId, this.programData.programId)
        .pipe(takeUntil(this.destroyed$)).subscribe(
          () => {
            this.programDataService.isDeleteOperation = true;
            this.popupService.showDeleteMessage(`Configured program ${this.programData.tenantProgram.name} deleted successfully.`);
            void this.router.navigate(['programs']);
          }, (error: any) => {
            if (error.status === 403) {
              this.popupService.setByResponse(error);
            } else {
              this.popupService.showErrorMessage(`Error deleting configured program ${this.persistedConfiguredProgram.name}.`);
            }
          });
    }
    this.isDeleteConfiguredProgramAlertShown = false;
  }

  discardProgramNameChanges(): void {
    this.persistedConfiguredProgram.name = this.programName;
    this.isProgramNameInputFocused = false;
  }

  public canDeactivate(): boolean {
    return this.programName === this.persistedConfiguredProgram.name;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private buildSelfServiceExternalPackagedProgramEventSchema(rules: CustomRule[]): void {
    const eventSource = rules.find(rule => rule.type === RuleTypes.PACKAGED).eventKey.eventSourceName;
    rules.forEach(rule => {
      if (rule.type === RuleTypes.PACKAGED) {
        this.initPackagedEventSchema(rule.eventKey.eventSourceName, rule.eventKey.eventName);
      } else if (rule.type === RuleTypes.DROOLS) {
        const drrolsRule: DroolsRule = rule as unknown as DroolsRule;
        this.initPackagedEventSchema(eventSource, drrolsRule.ruleId.split(Constants.DB_KEY_SEPARATOR)[1]);
      } else {
        this.initConfiguredEvent(rule.eventKey.eventSourceName, rule.eventKey.eventName);
      }
    });
  }

  private buildSelfServiceExternalProgramEventSchema(rules: CustomRule[]): void {
    rules.forEach(rule => {
      if (rule.eventKey) {
        this.initConfiguredEvent(rule.eventKey.eventSourceName, rule.eventKey.eventName);
      }
    });
  }

  public onChangeLogModalAction(): void {
    this.isChangeLogModalShown = true;
  }

  public setChangeLogModalStatus($event: boolean): void {
    this.isChangeLogModalShown = $event;
  }

}