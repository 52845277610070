<app-base-template>
  <div class="container-fluid object-page-header pt-4">
    <div class="row align-items-center py-sm-3">
      <div class="col">
        <h1 class="text-style-1 mb-0">Programs</h1>
      </div>
      <div class="col-auto" *ngIf="authorizationService.isAllowed('CUST_PRG', ['CREATE']) || authorizationService.isAllowed('PKG_PRG', ['CREATE'])">
        <coreui-menu buttonLabel="Create New Program" buttonType="primary" menuButtonStyle="button" iconAlignment="right"
          [disabled]="!parentContext.isParentContextSet()">
          <coreui-menu-section>
            <a coreUIMenuItem routerLink="./custom/configure/external/programId/eventSource/add"
              *ngIf="authorizationService.isAllowed('CUST_PRG', ['CREATE'])">Custom Program</a>
            <button type="button" coreUIMenuItem (click)="openNewProgramSelector()"
              *ngIf="authorizationService.isAllowed('PKG_PRG', ['CREATE'])">Packaged Program</button>
          </coreui-menu-section>
        </coreui-menu>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!parentContext.isParentContextSet()">
    <div class="container-fluid">
      <div class="row">
        <div class="col text-center">
          <p class="text-style-muted-3 m-0">Please set up a Parent in Settings to create a program</p>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loadingSpinner>
  </ng-template>

  <div class="container-fluid pt-5 mt-2">
    <ng-container
      *ngIf="!isConfiguredProgramsLoading &&
      !fetchingAllBUs && !fetchingAllCompanies &&
      parentContext.isParentContextSet(); else loadingSpinner"
    >
      <div class="row">
        <div class="col-12 col-lg">
          <div class="row">
            <div class="col col-sm-6 col-xl-3">
              <app-ae-table-search
                label="Search Programs"
                placeholder="Search by name"
                (searchEmitter)="handleSearch($event)">
              </app-ae-table-search>
            </div>
            <div class="col-auto align-self-center">
              <button
                type="button"
                (click)="showFilters=!showFilters"
                class="btn btn-text"
                data-toggle="collapse"
                data-target="#collapseFilters"
                aria-expanded="true"
                aria-controls="collapseFilters"
              >
                <i class="fas fa-filter icon-left" aria-hidden="true"></i>Filter
              </button>
            </div>
          </div>
          <div [ngClass]="showFilters ? 'show' : 'collapse'" id="collapseFilters">
            <div class="row">
              <div *ngIf="listView" class="col-sm-6 col-xl-3">
                <app-ae-sort-by
                [sortByOptions] = "sortByOptions"
                (sortByValue)="sortConfiguredPrograms($event)">
              </app-ae-sort-by>
              </div>
              <div class="col-sm-6 col-xl-3">
                <app-ae-filter
                  buttonLabel = "Select Action Destinations"
                  label = "Filter by Action Destinations"
                  entityArrayName = "action_destination"
                  [cachedValue] = userSavedFiltersForCurrentParent
                  [entityArray] = actionDestinationsForParent
                  (filterEmitter)="filterByOptions($event)"
                  #actionDestinationsFilter>
                </app-ae-filter>
              </div>

              <div class="col-sm-6 col-xl-3">
                <app-ae-filter
                  buttonLabel = "Select Business Units"
                  label = "Filter by Business Units"
                  entityArrayName = "business_unit"
                  [cachedValue] = userSavedFiltersForCurrentParent
                  [entityArray] = bUnitsForParent
                  (filterEmitter)="filterByOptions($event)"
                  #businessUnitFilter>
                </app-ae-filter>
              </div>
              <div class="col-sm-6 col-xl-3">
                <app-ae-filter
                  buttonLabel = "Select Companies"
                  label = "Filter by Companies"
                  entityArrayName = "company"
                  [cachedValue] = userSavedFiltersForCurrentParent
                  [entityArray] = companiesForParent
                  (filterEmitter)="filterByOptions($event)"
                  #companyFilter>
                </app-ae-filter>
              </div>
              <div class="col-sm-6 col-xl-3">
                <app-ae-filter
                  buttonLabel = "Select Event Source Providers"
                  label = "Filter by Event Source Providers"
                  entityArrayName = "event_source_provider"
                  [cachedValue] = userSavedFiltersForCurrentParent
                  [entityArray] = eventSourceProvidersForParent
                  (filterEmitter)="filterByOptions($event)"
                  #eventSourceFilter>
                </app-ae-filter>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto ml-lg-auto mb-4 mt-lg-4">
          <bp-toggle
            toggleGroupName="toggle-view"
            [toggles]="viewToggles"
            (change)="toggleView($event)"
            [(checkedToggle)]="programViewToggle"
          ></bp-toggle>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="listView">
      <ng-container *ngFor="let program of configuredProgramList; let i=index;">
        <div class="row">
          <div class="col">
            <div class="card u-mb-20">
              <div class="card-header d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <h2 class="card-heading">{{program.name}}</h2>
                    <div
                      *ngIf="!isCollapseProgram[i]"
                      class="card-icon-toggle"
                      (click)="isCollapseProgram[i] = !isCollapseProgram[i]"
                    >
                      <i class="fa fa-angle-down fa-2x" aria-expanded="true"></i>
                      <p class="sr-only">
                        Collapse
                      </p>
                    </div>
                    <div
                      *ngIf="isCollapseProgram[i]"
                      class="card-icon-toggle"
                      (click)="isCollapseProgram[i] = !isCollapseProgram[i]"
                    >
                      <i class="fa fa-angle-right fa-2x" aria-expanded="false"></i>
                      <p class="sr-only">
                        Expand
                      </p>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="description-list">
                    <span class="description-list-label">Status</span>
                    <div class="description-list-val">
                      <app-program-state-indicator
                        [programStatus]="program.active"
                        [programMode]="program.mode"
                      ></app-program-state-indicator>
                    </div>
                  </div>
                  <p class="description-list" *ngIf="program.configuration.companyId">
                    <span class="description-list-label">Company</span>
                    <span class="description-list-val">
                      {{companyMap.get(program.configuration.companyId)}}
                    </span>
                  </p>
                  <p class="description-list" *ngIf="programBusinessUnitMap.get(program.id)">
                    <span class="description-list-label">Business Unit</span>
                    <span class="description-list-val">
                      {{businessUnitsForParent.get(programBusinessUnitMap.get(program.id))}}
                    </span>
                  </p>
                  <p class="description-list">
                    <span class="description-list-label">Modified Date</span>
                    <span class="description-list-val">
                      {{program.modifiedDate | date: 'MM/dd/yyyy h:mmaaaaa\'m\''}}
                    </span>
                  </p>
                  <p class="description-list">
                    <span class="description-list-label">Modified By</span>
                    <span class="description-list-val">
                      {{program.modifiedBy}}
                    </span>
                  </p>
                  <a class="btn btn-outline-primary btn-sm"
                    [routerLink]="getRouterLink(program)"
                     (click)="routeProgram(program)"
                    routerLinkActive="router-link-active"
                  >
                    View Program
                  </a>
                </div>
              </div>
              <div [ngbCollapse]="isCollapseProgram[i]" class="card-body">
                <p *ngIf="program.description || program.programType === 'SELF_SERVICE_EXTERNAL' || program.programType === 'SELF_SERVICE_EXTERNAL_PACKAGED'; else defaultProgramDescription">
                  {{program.description}}
                </p>
                <ng-template #defaultProgramDescription>
                  <p>
                    If {{program.configuration.eventType}} is received from {{program.configuration.eventSource}} {{program.configuration.companyId}}
                  </p>
                  <p class="mb-0">
                    Then send to {{program.configuration.eventType}} Customer Keys with {{program.configuration.eventType}} Product IDs using Action Destination from Business Unit With Action Name
                  </p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!listView && configuredProgramTableList" >
      <div class="row">
        <div class="col-12">
          <app-ae-table [isDataLoading]="isDataLoading" [properties]="properties"
          [configuredTableData]="configuredProgramTableFilteredData">
        </app-ae-table>
        </div>
      </div>
    </ng-container>
  </div>
</app-base-template>

<ng-template #dateTemplateColumn let-program>
  {{program.modifiedDate | date: 'MM/dd/yyyy h:mmaaaaa\'m\''}}
</ng-template>

<ng-template #expandableRowsTemplate let-program>
  <p>{{ program.programDescription }}</p>
</ng-template>
