<form novalidate
 [formGroup]="oauthCredentialForm">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="authUrl">AUTH URL</label>
        <input type="url"
               id="authUrl"
               class="form-control"
               placeholder="Enter your AUTH url"
               [readonly]="editMode == true"
               formControlName="authUrl"
               [ngClass]="{ 'is-invalid': validateForRequired(getAuthUrlControl()) }" />
        <div *ngIf="validateForRequired(getAuthUrlControl())" class="invalid-feedback">
          {{getEmptyAuthUrlErrorMessage()}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="userId">Client Username</label>
        <input type="text"
          id="userId"
          class="form-control"
          placeholder="Enter your client username"
          formControlName="userId"
          [ngClass]="{ 'is-invalid': validateForRequired(getUserIdControl()) }" />
        <div *ngIf="validateForRequired(getUserIdControl())" class="invalid-feedback">
          {{getEmptyUserIdMessage()}}
        </div>
      </div>
    </div>
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="password">Client Password</label>
        <input type="password"
          id="password"
          class="form-control"
          placeholder="Enter your client password"
          formControlName="password"
          [ngClass]="{ 'is-invalid': validateForRequired(getPasswordControl()) }" />
        <div *ngIf="validateForRequired(getPasswordControl())" class="invalid-feedback">
          {{getEmptyPasswordMessage()}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="clientId">Client ID</label>
        <input type="text"
          id="clientId"
          class="form-control"
          placeholder="Enter your client ID"
          formControlName="clientId"
          [ngClass]="{'is-invalid': validateForRequired(getClientIdControl()) }" />
        <div *ngIf="validateForRequired(getClientIdControl())" class="invalid-feedback">
          {{getEmptyClientIdMessage()}}
        </div>
      </div>
    </div>
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="clientSecret">Client Secret</label>
        <input type="password"
          id="clientSecret"
          class="form-control"
          placeholder="Enter your client secret"
          formControlName="clientSecret"
          [ngClass]="{ 'is-invalid': validateForRequired(getClientSecretControl()) }" />
        <div *ngIf="validateForRequired(getClientSecretControl())" class="invalid-feedback">
          {{getEmptyClientSecretMessage()}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="grantType">Grant Type</label>
        <coreui-select id="grantType" formControlName="grantType"
          [ngClass]="{'is-invalid': validateForRequired(getClientSecretControl())}">
          <coreui-option value="">Select your grant type</coreui-option>
          <coreui-option value="PASSWORD">Password</coreui-option>
        </coreui-select>
        <div *ngIf="validateForRequired(getGrantTypeControl())" class="invalid-feedback">
          {{getEmptyGrantTypeMessage()}}
        </div>
      </div>
    </div>
    <div class="col col-sm-6">
      <div class="form-group">
        <label for="scope">Scope</label>
        <input type="text"
          id="scope"
          class="form-control"
          placeholder="Enter your scope"
          formControlName="scope"
          [ngClass]="{ 'is-invalid': validateForRequired(getScopeControl()) }" />
        <div *ngIf="validateForRequired(getScopeControl())" class="invalid-feedback">
          {{getEmptyScopeMessage()}}
        </div>
      </div>
    </div>
  </div>
</form>
