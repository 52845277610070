import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Duration } from 'luxon';
import { FormOnSaveAction } from 'src/app/shared/constants';
import { DurationUnitList, DurationUnits } from 'src/app/shared/models/exclusion/duration-units';
import { ExclusionOperator, ExclusionOperatorDisplayNameMap } from 'src/app/shared/models/exclusion/exclusion-operator';
import { ExclusionConfigurationBaseOperator } from 'src/app/shared/exclusion/exclusion-configuration/exclusion-configuration-base-operator';

@Component({
  selector: 'app-exclusion-configuration-date-operation',
  templateUrl: './exclusion-configuration-date-operation.component.html',
  styleUrls: ['./exclusion-configuration-date-operation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExclusionConfigurationDateOperationComponent extends ExclusionConfigurationBaseOperator implements OnInit {

  public formControlNames = {
    'CONDITION_VALUE': 'conditionValue',
    'DURATION_UNIT': 'durationUnit'
  };
  public durationUnitList = ['MINUTES', 'HOURS', 'DAYS'];

  private inputValue: unknown;
  private inputDurationUnit: DurationUnits;

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.initInput();
    this.initFormGroup();
  }

  public validate(): boolean {
    const conditionValue = this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).value;
    return this.validateConditionValue(conditionValue);
  }

  public buildAttributeConditionValues(): unknown[] {
    const conditionValue : any = this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).value;
    let attributeConditionValues = [conditionValue];

    if (this.isBeforeAfterOperator()) {
      const durationUnit: DurationUnits = this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).value;
      attributeConditionValues = [conditionValue, durationUnit.charAt(0).toLowerCase()];
      return attributeConditionValues;
    }
    return this.parseDateValuesToMilliSecond(attributeConditionValues[0]);
  }

  private parseDateValuesToMilliSecond(inputValue : Date) {
    const values: string[] = [];
    let valueInMilliSeconds = Date.parse(inputValue.toString()).toString();
    if (this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).dirty) {
      valueInMilliSeconds = (inputValue).setSeconds(0, 0).toString();
    }
    values.push(valueInMilliSeconds);
    return values;
  }

  private parseMilliSecondsToDate(input: string) {
    const date = new Date(+input);
    return date;
  }

  private initInput(): void {
    this.inputValue = undefined;
    this.inputDurationUnit = DurationUnits.MINUTES;
    this.displayExclusionOperator = ExclusionOperatorDisplayNameMap[this.exclusionOperator];
    if (this.action === FormOnSaveAction.EDIT) {
      this.inputValue = this.parseMilliSecondsToDate(this.attributeConditionValues[0] as string);
      if (this.isBeforeAfterOperator()) {
        this.initDurationDetails(this.attributeConditionValues[1] as string);
      }
    }
  }

  private initDurationDetails(durationUnit: string): void {
    this.inputValue = this.attributeConditionValues[0];
    for (const duration of this.durationUnitList) {
      if (duration.charAt(0).toLowerCase() === durationUnit) {
        this.inputDurationUnit = DurationUnits[duration];
        break;
      }
    }
  }

  private initFormGroup(): void {
    this.exclusionConfigurationFormGroup = new UntypedFormGroup({
      conditionValue: new UntypedFormControl(this.inputValue)
    });
    if (this.readOnly) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).disable();
    }
    this.initBeforeAfterOperator();
  }

  private initBeforeAfterOperator(): void {
    if (this.isBeforeAfterOperator()) {
      this.exclusionConfigurationFormGroup.addControl(this.formControlNames.DURATION_UNIT, new UntypedFormControl(this.inputDurationUnit.toString()));
      if (this.readOnly) {
        this.exclusionConfigurationFormGroup.get(this.formControlNames.DURATION_UNIT).disable();
      }
    }
  }

  private isBeforeAfterOperator(): boolean {
    return this.exclusionOperator === ExclusionOperator.BEFORE || this.exclusionOperator === ExclusionOperator.AFTER;
  }

  private validateConditionValue(conditionValue: string): boolean {
    if (!conditionValue) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).setErrors({ required: true });
      this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).markAsDirty();
      return false;
    }
    return true;
  }

}