import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActionsRoutingModule } from './actions-routing.module';
import { ActionsConfigurationComponent } from './actions-configuration/actions-configuration.component';
import { ActionsHomeComponent } from './actions-home/actions-home.component';
import { ActionsAwsConfigurationComponent } from './actions-aws-configuration/actions-aws-configuration.component';
import { ActionsApiConfigurationComponent } from './actions-api-configuration/actions-api-configuration.component';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { TableModule, PaginationModule, ActionModule, RibbonModule, LoadingIndicatorModule, RadioButtonModule } from 'epsilon-blueprint';
import { CoreUIModule } from '@epsilon/core-ui';

@NgModule({
  declarations: [
    ActionsConfigurationComponent,
    ActionsHomeComponent,
    ActionsAwsConfigurationComponent,
    ActionsApiConfigurationComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    ActionsRoutingModule,
    SharedModule,
    FormsModule,
    BaseTemplateModule,
    TableModule,
    PaginationModule,
    ActionModule,
    RibbonModule,
    LoadingIndicatorModule,
    RadioButtonModule,
    CoreUIModule
  ]
})
export class ActionsModule { }