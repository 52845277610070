<coreui-notification notificationStyle="error" messageDuration="5000" [isPersistent]="false" #formError>{{errorMessage}}
</coreui-notification>
<div class="container-fluid object-page-header">
	<div class="row align-items-center u-py-25 h-10">
		<div class="col">
			<h1 class="pr-4">Event Source Providers</h1>
		</div>
	</div>
</div>
<app-ae-loading-indicator [isLoading]="operationInProgress" [type]="'loading bar'"></app-ae-loading-indicator>
<app-coreui-modal-warning (decision)="handleDeleteDecision($event)"></app-coreui-modal-warning>
<div class="container-fluid" *ngIf="!operationInProgress">
	<form class="my-3" [formGroup]="eventSourceProvidersForm">
		<ng-container formArrayName="eventSource">
			<coreui-accordion>
				<ng-container
					*ngFor="let source of eventSourceProvidersForm.get('eventSource')['controls']; let i=index">
					<coreui-accordion-pane [active]="eventSourceProvidersResult">
						<ng-template #header *ngIf="source.value.type !== 'PACKAGED'">{{source.value.id}}</ng-template>
						<ng-template #header *ngIf="source.value.type === 'PACKAGED'">{{source.value.displayName}}
						</ng-template>
						<div class="row my-3">
							<div class="col">
								<div [formGroupName]="i" class="card border-color-secondary rounded-0">
									<div class="card-header bg-transparent">
										<div class="row">
											<div class="col">
												<input formControlName="id" hidden="true" />
											</div>
											<div class="col-sm-auto ml-auto">
												<app-ae-button
													*ngIf="isCustomEventSource(source.value.type) && authorizationService.isAllowed('CFG_EVENT_SRC', ['UPDATE'])"
													[buttonText]="'Edit'" [disabled]="isEditDisabled(source.value.id)"
													(click)="editCustomEventSource(source, i)"></app-ae-button>
												<app-ae-button class="ml-2"
													*ngIf="authorizationService.isAllowed('EVENT_SRC', ['DELETE']) || authorizationService.isAllowed('CFG_EVENT_SRC', ['DELETE'])"
													[buttonText]="'Remove'" [disabled]="isRemoveDisabled(source)"
													(click)="showDeleteEventSourceAlert(source.value.id, i)">
												</app-ae-button>
											</div>
										</div>
									</div>
									<div id="card-body-{{i}}" class="card-body collapse show p-3"
										[attr.aria-labelledby]="'#card-header-' + i">
										<div class="row">
											<div class="col">
												<app-ribbon *ngIf="hasOperationFailed(i, source.value.id)"
													ribbonStyle="ribbon-alert"
													[ribbonMessage]="getOperationFailureMessage()"
													ribbonHasButton="true" [buttonText]="getAssociationsBtnTxt()"
													(buttonClick)="openAssociationsModal(source.value.id)">
												</app-ribbon>
											</div>
										</div>
										<ng-container formArrayName="settings"
											*ngIf="isPackagedEventSource(source.value.type)">
											<ng-container
												*ngFor="let compDetails of source.get('settings').controls; let companyIndex = index">
												<div [formGroupName]="companyIndex">
													<div class="row my-3">
														<div class="col-md-4">
															<label for="companyName-{{i}}-{{companyIndex}}">Company
																Name</label>
															<input type="text" id="companyName-{{i}}-{{companyIndex}}"
																formControlName="companyName" class="form-control"
																[keyup]="validateEnteredCompanyExists(source.get('settings').controls, companyIndex)" />
															<div *ngIf="compDetails.get('companyName').invalid && compDetails.get('companyName').hasError('required') && compDetails.get('companyName').touched "
																class="text-color-warning">
																Company name is required
															</div>
															<div *ngIf="compDetails.get('companyName').invalid && compDetails.get('companyName').hasError('alreadyExists')"
																class="text-color-warning">
																Company name already exists
															</div>
														</div>
														<div class="col-md-4">
															<label for="companyId-{{i}}-{{companyIndex}}">Company
																Id</label>
															<input type="text" id="companyId-{{i}}-{{companyIndex}}"
																formControlName="companyId" is-invalid="true"
																class="form-control"
																[keyup]="validateEnteredCompanyExists(source.get('settings').controls, companyIndex)" />
															<div *ngIf="compDetails.get('companyId').invalid && compDetails.get('companyId').hasError('required') && compDetails.get('companyId').touched "
																class="text-color-warning">
																Company Id is required
															</div>
															<div *ngIf="compDetails.get('companyId').invalid && compDetails.get('companyId').hasError('alreadyExists')"
																class="text-color-warning">
																Company Id already exists
															</div>
														</div>
														<div class="col-sm-auto pt-4">
															<app-ae-button
																*ngIf="compDetails.get('companyId').disabled && (isPackagedEventSource(source.value.type) && authorizationService.isAllowed('CFG_EVENT_SRC', ['CREATE']))"
																[buttonText]="'Delete'"
																(click)="showDeleteCompanyAlert(source.value.id, source.controls.settings, companyIndex)">
															</app-ae-button>
															<app-ae-button
																*ngIf="!compDetails.get('companyId').disabled"
																[buttonText]="'Save'"
																[disabled]="compDetails.get('companyId').invalid || compDetails.get('companyName').invalid"
																(click)="saveCompany(source.value.id, source.controls.settings, companyIndex)">
															</app-ae-button>
															<app-ae-button class="ml-2"
																*ngIf="!compDetails.get('companyId').disabled"
																[buttonText]="'Cancel'"
																(click)="removeUnsavedCompany(source.controls.settings, companyIndex)">
															</app-ae-button>
														</div>
													</div>
												</div>
											</ng-container>
											<div class="row"
												*ngIf="(isPackagedEventSource(source.value.type) && authorizationService.isAllowed('CFG_EVENT_SRC', ['CREATE']))">
												<div class="col">
													<app-ae-button *ngIf="source.get('settings').length == 0"
														iconPosition="left" icon="add" [buttonText]="'Add a Company'"
														buttonType="text-primary"
														(click)="addNewCompany(source.controls.settings)">
													</app-ae-button>
													<app-ae-button *ngIf="source.get('settings').length > 0"
														iconPosition="left" icon="add"
														[buttonText]="'Add Another Company'" buttonType="text-primary"
														(click)="addNewCompany(source.controls.settings)">
													</app-ae-button>
												</div>
											</div>
										</ng-container>
										<div class="row"
											*ngIf="isCustomEventSource(source.value.type) && source.value.description!=='null'">
											<div class="col">
												{{source.value.description}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</coreui-accordion-pane>
				</ng-container>
			</coreui-accordion>
			<div class="row mt-3 mb-4">
				<div class="col" *ngIf="authorizationService.isAllowed('CFG_EVENT_SRC', ['CREATE'])">
					<app-ae-button *ngIf="eventSourceProvidersForm.get('eventSource')['length'] == 0"
						iconPosition="left" icon="add" [buttonText]="'Add an Event Source Provider'"
						buttonType="text-primary" (click)="open()"></app-ae-button>
					<app-ae-button *ngIf="eventSourceProvidersForm.get('eventSource')['length'] > 0" iconPosition="left"
						icon="add" [buttonText]="'Add Another Event Source Provider'" buttonType="text-primary"
						(click)="open()"></app-ae-button>
					<ng-container *ngIf="isCustomEventSourceModalDisplayed()">
						<app-add-custom-event-source-modal
							[existingEventSources]="getExistingEventSourceProvidersAsDisplayItems()"
							[customEventSource]="getCustomEventSource()" [editMode]="getEditMode()"
							[eventSourcesNotAllowed]="getEventSourcesNotAllowed()"
							(isModalDisplayed)="setCustomEventSourceModalDisplayStatus($event)"
							(configuredCustomEventSource)="captureConfiguredCustomEventSource($event)">
						</app-add-custom-event-source-modal>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</form>
</div>
<app-ae-modal-info (selectedItem)="captureSelectedItem($event)"></app-ae-modal-info>
