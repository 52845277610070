<app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <p class="text-style-muted-1 m-0 pb-3">Insert a single record in IMS</p>
    </div>
  </div>
  <form class="Core-Form" [formGroup]="insertForm" (ngSubmit)="insertRecord(formGroupDirective)"
    #formGroupDirective="ngForm">
    <coreui-form-field label="Customer Key">
      <input coreUIInput name="customerKey" formControlName="customerKey" placeholder="Enter customer key" />
      <coreui-form-error>Please enter customer key</coreui-form-error>
    </coreui-form-field>
    <coreui-form-field label="Customer Id">
      <input coreUIInput name="customerId" formControlName="customerId" placeholder="Enter customer id" />
      <coreui-form-error>Please enter customer id</coreui-form-error>
    </coreui-form-field>
    <coreui-form-field label="Email Address">
      <input coreUIInput name="emailAddress" formControlName="emailAddress" placeholder="Enter email address"
        email="true" />
      <coreui-form-error>Please enter valid email address</coreui-form-error>
    </coreui-form-field>
    <coreui-form-field label="SMS Number">
      <input coreUIInput name="smsNumber" formControlName="smsNumber" placeholder="Enter SMS number" />
    </coreui-form-field>
    <coreui-form-field label="Device Id">
      <input coreUIInput name="deviceId" formControlName="deviceId" placeholder="Enter device id" />
    </coreui-form-field>
    <app-ae-button buttonType="submit" buttonText="Insert">
    </app-ae-button>
  </form>
</div>
