<coreui-modal #basicModal>
  <ng-template #titleTemplate>
    <h2 class="text-style-1 mb-0">{{getModalTitle()}} Batch Extract</h2>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
      <app-ae-button buttonType="icon-large" (click)="closeBasicModal()" icon="close" ></app-ae-button>
  </ng-template>
  <ng-template #contentTemplate>
    <form name="configureBatchExtractForm" [formGroup]="configureBatchExtractFormGroup">
      <div class="horizontal-scroll">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <coreui-form-field label="Name">
              <input coreUIInput type="text" formControlName="name" placeholder="Enter a name">
                <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.name.invalid">
                  <div *ngIf="configureBatchExtractFormGroup.controls.name.errors.required || configureBatchExtractFormGroup.controls.name.errors.alreadyExists" class="text-color-warning">
                    {{messages.enterUniqueName}}
                  </div>
                </ng-container>
              </coreui-form-field>
              <p class="text-style-muted-1">Batch extracts cannot be edited once they are running.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2 class="h2">Select an Event</h2>
            <p class="text-style-muted-1">Batch extract is only available for packaged programs.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 form-group">
            <coreui-form-field label="Event Source Provider">
              <coreui-select formControlName="eventSource" placeholder="Select an event source provider">
                <coreui-option *ngFor="let item of eventSourceSelectOptions" [value]="item.value">{{item.label}}</coreui-option>
              </coreui-select>
              <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.eventSource.invalid">
                <div *ngIf="configureBatchExtractFormGroup.controls.eventSource.errors.required" class="text-color-warning">
                  {{messages.selectEventSource}}
                </div>
              </ng-container>
            </coreui-form-field>
          </div>
          <div class="col-6">
            <div class="form-group">
              <coreui-form-field label="Company Name">
                <coreui-select formControlName="companyId" placeholder="Select a company name">
                  <coreui-option *ngFor="let item of companySelectOptions" [value]="item.value">{{item.label}}</coreui-option>
                </coreui-select>
                <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.companyId.invalid">
                  <div *ngIf="configureBatchExtractFormGroup.controls.companyId.errors.required" class="text-color-warning">
                    {{messages.selectCompanyName}}
                  </div>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <app-ae-multi-select
                [formGroup]="configureBatchExtractFormGroup"
                [multiSelectFormControlName]="EventNamesFormControlName"
                [label]="EventsLabel"
                [placeholder]="PlaceholderTxt"
                [multiSelectDataStream]="MultiSelectDataStream"
                [isDisabled]="isDisabled()"></app-ae-multi-select>
              <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.eventNames.invalid">
                <div *ngIf="configureBatchExtractFormGroup.controls.eventNames.errors.required" class="text-color-warning">
                  {{messages.selectEvent}}
                </div>
                <div *ngIf="configureBatchExtractFormGroup.controls.eventNames.errors.alreadyConfigured" class="text-color-warning">
                  {{configureBatchExtractFormGroup.controls.eventNames.errors.alreadyConfigured}}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2 class="h2">S3 Bucket Information</h2>
            <p class="text-style-muted-1">Copy and paste the path for your S3 bucket.</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <coreui-form-field label="S3 Bucket Path">
                <input coreUIInput type="text" formControlName="exportDestinationUrl" placeholder="s3://batch-export-destination">
                <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.exportDestinationUrl.invalid">
                  <div *ngIf="configureBatchExtractFormGroup.controls.exportDestinationUrl.errors.required || configureBatchExtractFormGroup.controls.exportDestinationUrl.errors.pattern" class="text-color-warning">
                    {{messages.enterValidS3BucketPath}}
                  </div>
                </ng-container>
              </coreui-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2 class="h2">Schedule</h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <label for="startTime">Start date</label>
                <input type="text" id="startTime" formControlName="startTime" class="form-control" placeholder="Enter or select a date" [min]="getMinStartTime()" [max]="getMaxStartTime()" [owlDateTime]="startTimePicker">
                <div class="input-group-append">
                  <app-ae-button
                    buttonType="icon-large"
                    icon="calendar"
                    [owlDateTimeTrigger]="startTimePicker">
                  </app-ae-button>
                </div>
                <owl-date-time #startTimePicker></owl-date-time>
              </div>
              <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.startTime.invalid">
                <div *ngIf="configureBatchExtractFormGroup.controls.startTime.errors.required || configureBatchExtractFormGroup.controls.startTime.errors.owlDateTimeParse" class="text-color-warning">
                  {{messages.enterValidDateTime}}
                </div>
                <div *ngIf="configureBatchExtractFormGroup.controls.startTime.errors.owlDateTimeMin" class="text-color-warning">
                  {{messages.selectDateTimeInfuture}}
                </div>
                <div *ngIf="configureBatchExtractFormGroup.controls.startTime.errors.owlDateTimeMax" class="text-color-warning">
                  {{messages.selectDateTimeWithinOneYear}}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-auto px-lg-0 mt-4 pt-2">
            <span>to</span>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <label for="endTime">End date</label>
                <input type="text" id="endTime" formControlName="endTime" class="form-control" placeholder="Enter or select a date" [min]="getMinimumEndTime()" [max]="getMaximumEndTime()" [owlDateTime]="endTimePicker">
                <div class="input-group-append">
                  <app-ae-button
                    buttonType="icon-large"
                    icon="calendar"
                    [owlDateTimeTrigger]="endTimePicker">
                  </app-ae-button>
                </div>
                <owl-date-time #endTimePicker></owl-date-time>
              </div>
              <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.endTime.invalid">
                <div *ngIf="configureBatchExtractFormGroup.controls.endTime.errors.required || configureBatchExtractFormGroup.controls.endTime.errors.owlDateTimeParse" class="text-color-warning">
                  {{messages.enterValidDateTime}}
                </div>
                <div *ngIf="configureBatchExtractFormGroup.controls.endTime.errors.owlDateTimeMin" class="text-color-warning">
                  {{messages.selectDateTimeInfutureAfterStartDateTime}}
                </div>
                <div *ngIf="configureBatchExtractFormGroup.controls.endTime.errors.owlDateTimeMax" class="text-color-warning">
                  {{messages.selectDateTimeWithinOneYear}}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3 class="h3">Send Frequency</h3>
          </div>
        </div>
        <fieldset>
          <div class="row">
            <legend class="col-12">Send every</legend>
            <div class="col-6">
              <div class="form-group">
                <coreui-form-field label="Time increment">
                  <input coreUIInput type="text" formControlName="interval" placeholder="Enter a number">
                  <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.interval.invalid">
                    <div *ngIf="configureBatchExtractFormGroup.controls.interval.errors.required || configureBatchExtractFormGroup.controls.interval.errors.min || configureBatchExtractFormGroup.controls.interval.errors.pattern" class="text-color-warning">
                      {{messages.enterTimeIncrement}}
                    </div>
                    <div *ngIf="validateMaxIntervalFrequency('HOURS')" class="text-color-warning">
                      {{messages.enterLesserHoursIncrement}}
                    </div>
                    <div *ngIf="validateMaxIntervalFrequency('DAYS')" class="text-color-warning">
                      {{messages.enterLesserDaysIncrement}}
                    </div>
                    <div *ngIf="validateMaxIntervalFrequency('WEEKS')" class="text-color-warning">
                      {{messages.enterLesserWeeksIncrement}}
                    </div>
                  </ng-container>
                </coreui-form-field>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <coreui-form-field label="Time Period">
                  <coreui-select formControlName="frequency" placeholder="Select time period">
                    <coreui-option *ngFor="let item of frequencySelectOptions" [value]="item.value">{{item.label}}</coreui-option>
                  </coreui-select>
                  <ng-container *ngIf="isSaveClicked && configureBatchExtractFormGroup.controls.frequency.invalid">
                    <div *ngIf="configureBatchExtractFormGroup.controls.frequency.errors.required" class="text-color-warning">
                      {{messages.selectTimePeriod}}
                    </div>
                  </ng-container>
                </coreui-form-field>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </ng-template>
  <ng-template #buttonsTemplate>
    <app-ae-button *ngIf="action === 'EDIT' && authorizationService.isAllowed('CFG_BATCH_EXTRACT', ['DELETE'])"  class="mr-auto" (click)="onDelete()" buttonText="Delete Batch Extract"></app-ae-button>
    <app-ae-button (click)="closeBasicModal()" buttonText="Cancel"></app-ae-button>
    <app-ae-button *ngIf="authorizationService.isAllowed('CFG_BATCH_EXTRACT', ['CREATE', 'UPDATE'])" (click)="onSave()" buttonText="Save"></app-ae-button>
  </ng-template>
</coreui-modal>
