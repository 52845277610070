import { Component, OnInit } from '@angular/core';
import { WarningState } from 'src/app/shared/warning-state';
import { WarningOptions } from 'src/app/shared/warning-options';

@Component({
  selector: 'app-modal-warning',
  templateUrl: './modal-warning.component.html'
})
export class ModalWarningComponent implements OnInit {

  ngOnInit() { }

  options: WarningOptions;

  constructor(private state: WarningState) {
    this.options = state.options;
  }

  yes() {
    this.state.modal.close(true);
  }

  no() {
    this.state.modal.close(false);
  }

}