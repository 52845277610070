import { Component, OnInit } from '@angular/core';
import { FormOnSaveAction } from '../../../constants';
import { ExclusionConfigurationPrimitiveOperator } from '../exclusion-configuration-primitive-operator';

@Component({
  selector: 'app-exclusion-configuration-boolean-compare',
  templateUrl: './exclusion-configuration-boolean-operation.component.html',
})
export class ExclusionConfigurationBooleanOperationComponent extends ExclusionConfigurationPrimitiveOperator implements OnInit {

  public constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.action === FormOnSaveAction.CREATE) {
      this.exclusionConfigurationFormGroup.get(this.formControlNames.CONDITION_VALUE).patchValue(true);
    }
  }

}
