import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExclusionsRoutingModule } from './exclusions-routing.module';
import { ExclusionsHomeComponent } from './exclusions-home/exclusions-home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { BaseTemplateModule } from 'src/app/shared/component/base-template/base-template.module';
import { ActionModule, LoadingIndicatorModule, PaginationModule } from 'epsilon-blueprint';
import { CoreUIModule } from '@epsilon/core-ui';
import { ExclusionBuilderComponent } from './exclusion-builder/exclusion-builder.component';
import { ExclusionBuilderHeaderComponent } from './exclusion-builder-header/exclusion-builder-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EntityStateAttributeComponent } from './entity-state-attribute/entity-state-attribute.component';
import { ExclusionConfigurationBuilderComponent } from './exclusion-configuration-builder/exclusion-configuration-builder.component';

@NgModule({
  declarations: [
    ExclusionsHomeComponent,
    ExclusionBuilderComponent,
    ExclusionBuilderHeaderComponent,
    EntityStateAttributeComponent,
    ExclusionConfigurationBuilderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ExclusionsRoutingModule,
    NgbModule,
    SharedModule,
    BaseTemplateModule,
    PaginationModule,
    ActionModule,
    LoadingIndicatorModule,
    CoreUIModule
  ]
})
export class ExclusionsModule { }