import { Base64 } from "aws-sdk/clients/ecr";
import { Constants } from "../constants";

export class EntityContent {

  public parentId: string;
  public contentName: string;
  public fieldName: string;
  public contentType: string;
  public companyId: string;
  public budget: string;
  public contentId: string;
  public clickThroughUrl: string;
  public contentUrl : string;
  public expirationTime: number;
  public content: Base64;


  constructor(parentId: string, fieldName: string, contentType: string, companyId: string, contentId: string, clickThroughUrl: string, contentUrl : string, expirationTime: number) {
    this.parentId = parentId;
    this.fieldName = fieldName;
    this.contentType = contentType;
    this.companyId = companyId;
    this.contentId = contentId;
    this.clickThroughUrl = clickThroughUrl;
    this.contentUrl = contentUrl;
    this.expirationTime = expirationTime;
  }

  public getContentKey(): string {
    return this.companyId === null ? this.parentId + Constants.DB_KEY_SEPARATOR + this.contentId : this.parentId + Constants.DB_KEY_SEPARATOR + this.contentId + Constants.DB_KEY_SEPARATOR + this.companyId;
  }

  getEntityContentPath(): string {
    return this.companyId === null ? this.contentId : this.companyId + '/' + this.contentId;
  }

}