import { UserType } from '../../constants';
export class ConfiguredUserTableRow {

  public id: string;
  public name: string;
  public userName: string;
  public status: string;
  public lastLoginDate: string;
  public startDate: string;
  public endDate: string;
  public createdDate: string;
  public modifiedDate: string;
  public modifiedBy: string;
  public userType: UserType;

}