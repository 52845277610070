import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { int } from 'aws-sdk/clients/datapipeline';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ASSOCIATION_API_FIELD_STATUS, ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER,
  ENTITY_TYPE, HAS_ASSOCIATIONS_IDENTIFIER, ID_IDENTIFIER } from 'src/app/shared/models/association/association-constants';
import { ConfiguredEntityAssociationParams } from 'src/app/shared/models/association/configured-entity-association-params';
import { ConfiguredEntityType } from 'src/app/shared/models/association/configured-entity-type.enum';
import { Company } from 'src/app/shared/models/company';
import { EntityTypes, ActionTypes, OperationType, RuleTypes } from 'src/app/shared/constants';
import { ConfiguredEventDetails } from 'src/app/shared/models/configured-event-details';
import { ConfiguredEvent } from 'src/app/shared/models/configured-event.model';
import { ConfiguredRule } from 'src/app/shared/models/configured-rule.model';
import { Action } from 'src/app/shared/models/action';
import { CustomRule } from 'src/app/shared/models/custom-rule/custom-rule';
import { EventSource } from 'src/app/shared/models/event-source';
import { EventKey } from 'src/app/shared/models/eventKey.model';
import { ActionDestinationService } from 'src/app/shared/services/action-destination.service';
import { ActionService } from 'src/app/shared/services/action.service';
import { AssociationService } from 'src/app/shared/services/association.service';
import { EventSchemaParserService } from 'src/app/shared/services/event-schema-parser.service';
import { EventSourceService } from 'src/app/shared/services/event-source.service';
import { ParentContextService } from 'src/app/shared/services/parent-context.service';
import { PopupMessageService } from 'src/app/shared/services/popup-message.service';
import { RuleService } from 'src/app/shared/services/rule.service';
import { Messages } from 'src/app/shared/message';
import { RuleBuilderComponent } from 'src/app/shared/component/rule-builder/rule-builder.component';
import * as uuid from 'uuid';
import { EntityStatesService } from 'src/app/shared/services/entitystates.service';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { ContainerCondition } from 'src/app/shared/models/custom-rule/conditions/container-condition';
import { SourceType } from 'src/app/shared/models/custom-rule/conditions/source-type';
import { AttributeMetadata } from 'src/app/shared/models/custom-rule/conditions/attribute-metadata';
import { EntityType } from 'src/app/shared/entity-type';
import { AuthorizationService } from 'src/app/shared/services/authorization-service';
import { WarningType } from 'src/app/shared/warning-options';
import { AssociationsTableBuilderService } from 'src/app/shared/services/associations-table-builder.service';
import { BaseFormDirective } from 'src/app/shared/models/base-form-configuration/base-form.directive';
import { RuleWhenClause } from 'src/app/shared/models/custom-rule/when-clause';
import { EntityCopy } from 'src/app/shared/models/entity-copy.model';
import { Feature } from 'src/app/shared/models/permission/feature/role-permission-constants';
import { EntityCopyService } from 'src/app/shared/services/entity-copy.service';

@Component({
  selector: 'app-rules-configuration',
  templateUrl: './rules-configuration.component.html',
  styleUrls: ['./rules-configuration.component.scss']
})
export class RulesConfigurationComponent extends BaseFormDirective implements OnInit, OnDestroy {

  @ViewChild(RuleBuilderComponent) child: RuleBuilderComponent;

  public parentId: string;
  public selectedEventSchema: string;
  public operationFailure = false;
  public responseArr = [];
  public hasAssociation: boolean;
  public operationType = OperationType;
  public associationStatus: ConfiguredEntityAssociationParams;
  public inputParams = {
    action: '',
    ruleId: ''
  };
  public eventSources: EventSource[];
  public isApiServiceCalled: boolean;
  public entityStateTreeNodeList: [] = [];
  public configuredEntityStates: ConfiguredEntityState[];
  public entityStateExist = false;
  public operationStatusMessage = '';

  private ruleId: string;
  private eventSourceProvider: string;
  private initialEventSourceProvider: string;
  private isDescriptionExpanded = false;
  private isEditRule = false;
  private isReadOnlyRule = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private configuredEvents: ConfiguredEvent[];
  private configuredEventDetails: ConfiguredEventDetails[];
  private events: string[];
  private schemaMap: object = {};
  private companyList: Company[];
  private incomingEventType = '';
  private attributeMap: { [key: string]: AttributeMetadata };
  private attributesExist = false;
  private eventTreeNodes: any;
  private configuredRuleActiveInPrograms = '';
  private actionExist = false;
  private actionTreeNodes: any[] = [];
  private configuredRules: ConfiguredRule[] = [];
  private configuredRule: ConfiguredRule;
  private configuredActions: Action[] = [];
  private filteredConfiguredActions: Action[] = [];
  private actionDestinations = [];
  private ruleComponentIndex: int = 0;
  private initialConditionsLength: int = 0;
  private initialActionsLength: int = 0;
  private initialOperationsLength: int = 0;
  private isDeleteOperation = false;

  private options = {
    // enable drag-n-drop feature
    allowDrag: (node: { isLeaf: boolean }) => node.isLeaf,
    allowDrop: () => {
      return false;
    },
    // enable drag-n-copy
    getNodeClone: (node: { data: { name: string } }) => ({
      ...node.data,
      id: uuid.v4(),
      name: `copy of ${node.data.name}`
    }),
    dropSlotHeight: 3
  };

  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private parentContext: ParentContextService,
    private popupService: PopupMessageService,
    private ruleService: RuleService,
    private eventSourceService: EventSourceService,
    private eventSchemaParser: EventSchemaParserService,
    private actionDestinationService: ActionDestinationService,
    private actionService: ActionService,
    private associationService: AssociationService,
    private entityStateService: EntityStatesService,
    public authorizationService: AuthorizationService,
    private associationsTableBuilderService: AssociationsTableBuilderService,
    private entityCopyService: EntityCopyService) {
    super();
    this.initInputParam(route);
  }

  ngOnInit(): void {
    this.parentId = this.parentContext.getParentContext();
    this.buildconfiguredEntityFormToAdd();
    this.getEntities();
  }

  public setAssociationInProgress(event: any): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = true;
    this.operationStatusMessage = Messages.loadingAssociations;
  }

  public setAssociationDone(event: Record<string, any>): void {
    if (event === null || event === undefined) {
      return;
    }
    this.isApiServiceCalled = false;
    this.hasAssociation = event[HAS_ASSOCIATIONS_IDENTIFIER];
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public hasAssociations(): boolean {
    return this.hasAssociation;
  }

  public hasOperationFailed(): boolean {
    return this.operationFailure || this.hasAssociations();
  }

  public isDeleteDisabled(): boolean {
    return this.isReadOnlyRule || this.hasAssociations();
  }

  public getOperationFailureMessage(): string {
    return Messages.ruleOperationFailureMessage;
  }

  public get ruleName(): AbstractControl {
    return this.configuredEntityForm.get('ruleName');
  }

  public validateRuleNameUniqueness(): void {
    if (!this.isEditRule) {
      this.checkIfRuleNameAlreadyExists();
    } else if (this.isEditRule && this.configuredRule['name'].toLowerCase() !== this.configuredEntityForm.controls.ruleName.value.toLowerCase()) {
      this.checkIfRuleNameAlreadyExists();
    }
  }

  public doSaveConfiguredRule(): void {
    this.isSaveClicked = true;
    const configuredRule: ConfiguredRule = this.buildConfiguredRule();
    if (!this.configuredEntityForm.valid) {
      this.popupService.showErrorMessage(Messages.requiredFields);
    } else {
      delete configuredRule.ruleObject;
      if (configuredRule && configuredRule.rule) {
        this.operationStatusMessage = Messages.saveRule;
        if (this.isEditRule) {
          this.updateConfiguredRule(configuredRule);
        } else {
          this.addConfiguredRule(configuredRule);
        }
      }
    }
  }

  public showDeleteConfiguredRuleAlert(): void {
    this.warningModal.launchModal(WarningType.DELETE_ENTITY_WARNING, {
      title: Messages.deleteConfiguredRule,
      msg: Messages.deleteRuleConfirmation
    });
  }

  public handleDecision(decision: boolean): void {
    if (decision && this.warningModal.warningType === WarningType.DELETE_ENTITY_WARNING) {
      this.isDeleteOperation = true;
      this.confirmDeleteConfiguredRule();
    }
  }

  public getAssociationsBtnTxt(): string {
    return Messages.viewAssociationsBtnTxt;
  }

  public openAssociationsModal(): void {
    const entityType = 'CONFIGURED_RULE';
    this.associationsTableBuilderService
      .withEntityType(entityType)
      .withConfiguredEntityAssociationParams(this.associationStatus)
      .buildAssociationTableDataAfterLookup()
      .subscribe(associationTableData => {
        if (associationTableData[ENTITY_TYPE] === entityType) {
          this.launchAssociationsModal(associationTableData.tableData);
        }
      });
    // Track association status - in progress
    this.associationsTableBuilderService.trackAssociationInProgress().subscribe($event => {
      this.setAssociationInProgress($event);
    });
    // Track association status - done
    this.associationsTableBuilderService.trackAssociationDone().subscribe($event => {
      if ($event[ENTITY_TYPE] !== entityType
        || !(ASSOCIATION_CALL_IN_PROGRESS_IDENTIFIER in $event)
        || !(HAS_ASSOCIATIONS_IDENTIFIER in $event)
        || !(ID_IDENTIFIER in $event)) {
        return;
      }
      this.setAssociationDone($event);
    });
  }

  private launchAssociationsModal(associationModalDetail: Record<string, any>): void {
    this.infoModal.launchModal({
      'title': associationModalDetail['title'],
      'description': '',
      'content': {
        'type': 'TABLE',
        'data': associationModalDetail['data'],
        'tableConfig': {
          'properties': associationModalDetail['properties'],
          'isDataLoading': associationModalDetail['isDataLoading']
        }
      }
    });
  }

  private onEntityStatesTreeLoad(tree: { treeModel: { expandAll: () => void } }): void {
    tree.treeModel.expandAll();
  }

  private initInputParam(route: ActivatedRoute) {
    route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      this.inputParams.action = route.snapshot.paramMap.get('action');
      this.inputParams.ruleId = route.snapshot.paramMap.get('ruleId');
      if (this.inputParams.action === OperationType.EDIT) {
        this.isEditRule = true;
      }
    });
  }

  private getEntities() {
    this.isApiServiceCalled = true;
    this.operationStatusMessage = 'Loading rule';
    this.callAPIs().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      this.eventSources = result[0].result.filter(configuredEvent => configuredEvent.type === EntityType.SELF_SERVICE);
      this.configuredEventDetails = result[1].result;
      this.configuredActions = result[2].result;
      this.actionDestinations = result[3].result;
      this.configuredRules = result[4].result;
      this.configuredEntityStates = result[5].result;
      if (this.isEditRule) {
        this.configuredRule = new ConfiguredRule(result[6].result);
        this.configuredRule.companyId = this.configuredRule.companyId === null ? '' : this.configuredRule.companyId;
        this.configuredRule.description = this.configuredRule.description === null ? '' : this.configuredRule.description;
        this.configuredRule.setRuleObject();
        this.eventSourceProvider = this.configuredRule.ruleObject.eventKey.eventSourceName;
        this.initialConditionsLength = this.setConditionLength(this.configuredRule);
        this.initialActionsLength = this.configuredRule.ruleObject.actions.length;
        this.initialOperationsLength = this.setOperationLength(this.configuredRule);
        this.validateAssociationStatus();
      }
      this.isApiServiceCalled = false;
      this.setupComponent();
    }, error => {
      this.isApiServiceCalled = false;
    });
  }

  private setConditionLength(configuredRule: ConfiguredRule): int {
    const condition = configuredRule.ruleObject.condition as ContainerCondition;
    return condition.conditions ? condition.conditions.length : 0;
  }

  private setOperationLength(configuredRule: ConfiguredRule): int {
    const then = configuredRule.ruleObject.thenClause;
    return then ? then.operations.length : 0;
  }

  private setupComponent() {
    this.initEventsOfEventSource(this.eventSourceProvider);
    this.initialEventSourceProvider = this.eventSourceProvider;
    if (this.isEditRule) {
      this.buildconfiguredEntityFormToEdit();
      this.loadRightPanel(this.configuredEntityForm.controls.eventName.value, false);
    }
  }

  private getFilteredConfiguredActions(eventName: string) {
    const sourceprovEventName = this.eventSourceProvider + '_x_' + eventName;
    this.filteredConfiguredActions = this.configuredActions.filter(
      configuredAction => (configuredAction.eventId &&
        ((configuredAction.eventId.eventSourceName + '_x_' + configuredAction.eventId.eventName) === sourceprovEventName)));
    this.filteredConfiguredActions = this.filteredConfiguredActions.filter(
      configuredAction => this.actionDestinations.map(actDest => actDest.actionDestination).includes(configuredAction.actionDestinationId));
    // Added standard actions to filtered action list
    this.configuredActions.forEach(configuredAction => {
      if (configuredAction.entityType === EntityTypes.PACKAGED) {
        this.filteredConfiguredActions.push(configuredAction);
      }
    });
  }

  private callAPIs(): Observable<any[]> {
    const configuredEventSources = this.eventSourceService.getEventSources(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredEvents = this.eventSourceService.getConfiguredEvents(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredActions = this.actionService.getConfiguredActions(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredActionDestinatiions = this.actionDestinationService.getActionDestinations(this.parentId)
      .pipe(catchError(() => of({ 'result': [] })));
    const configuredRules = this.ruleService.getConfiguredRules(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredEntityStates = this.entityStateService.getAllConfiguredEntityStates(this.parentId).pipe(catchError(() => of({ 'result': [] })));
    const configuredRule = this.ruleService.getConfiguredRulebyId(this.parentId, this.inputParams.ruleId)
      .pipe(catchError(() => of({ 'result': [] })));
    const apisToCall: any[] = [
      configuredEventSources,
      configuredEvents,
      configuredActions,
      configuredActionDestinatiions,
      configuredRules,
      configuredEntityStates
    ];
    if (this.isEditRule) {
      apisToCall.push(configuredRule);
    }
    return forkJoin(apisToCall);
  }

  private initEventsOfEventSource(eventSrc: string) {
    this.events = [];
    this.configuredEventDetails.forEach(event => {
      const id: Map<string, string> = event.id;
      if (id['eventSourceName'] === eventSrc) {
        this.events.push(id['eventName']);
        this.schemaMap[id['eventName']] = event.schema;
        this.selectedEventSchema = this.schemaMap[id['eventName']];
      }
    });
  }

  private buildconfiguredEntityFormToAdd() {
    this.operationStatusMessage = 'Loading rule';
    this.configuredRule = this.getEmptyConfiguredRule();
    this.buildForm(this.configuredRule);
  }

  private buildconfiguredEntityFormToEdit() {
    this.patchForm(this.configuredRule);
  }

  private buildForm(configuredRule: ConfiguredRule) {
    if (!this.configuredRule || !this.configuredRule.eventKey) {
      this.configuredRule = new ConfiguredRule();
      const eventKey: EventKey = new EventKey();
      eventKey.eventSourceName = '';
      eventKey.eventName = '';
      this.configuredRule.eventKey = eventKey;
    }

    this.configuredEntityForm = this.formBuilder.group({
      ruleName: new UntypedFormControl(configuredRule.name, { validators: [Validators.required] }),
      ruleDescription: new UntypedFormControl(configuredRule.description),
      eventName: new UntypedFormControl(configuredRule.ruleObject.eventKey.eventSourceName, { validators: [Validators.required] })
    });
  }

  private getEmptyConfiguredRule(): ConfiguredRule {
    const configuredRule: ConfiguredRule = new ConfiguredRule();
    configuredRule.name = '';
    configuredRule.description = '';
    const customRule: CustomRule = new CustomRule();
    customRule.companyId = '';
    const eventKey: EventKey = new EventKey();
    eventKey.eventName = '';
    eventKey.eventSourceName = '';
    configuredRule.eventKey = eventKey;
    customRule.eventKey = eventKey;
    customRule.actions = [];
    customRule.whenClause = new RuleWhenClause();
    customRule.type = RuleTypes.CUSTOM;
    configuredRule.setRule(JSON.stringify(customRule));
    return configuredRule;
  }

  private patchForm(configuredRuleToEdit: ConfiguredRule) {
    this.configuredEntityForm.patchValue({
      ruleName: configuredRuleToEdit.name,
      ruleDescription: configuredRuleToEdit.description,
      eventName: configuredRuleToEdit.ruleObject.eventKey.eventName
    });
    if (this.isReadOnlyRule) {
      this.configuredEntityForm.controls.ruleName.disable();
      this.configuredEntityForm.controls.eventName.disable();
      this.configuredEntityForm.controls.ruleDescription.disable();
    }
  }

  private resetEventControl() {
    this.configuredEntityForm.controls.eventName.patchValue('');
    this.configuredEntityForm.controls.eventName.updateValueAndValidity();
  }

  private onEventSourceChanged(eventSourceProvider: string) {
    this.resetEventControl();
    this.initEventsOfEventSource(eventSourceProvider);
    this.actionTreeNodes = [];
    this.actionExist = false;
    this.eventTreeNodes = [];
    this.attributesExist = false;
    this.configuredRule.eventKey.eventSourceName = eventSourceProvider;
    this.configuredRule.eventKey.eventName = '';
    this.configuredRule.companyId = '';
    this.incomingEventType = '';
    this.child.resetRightPanel(true);
    this.configuredEntityForm.updateValueAndValidity();
  }

  private loadRightPanel(eventName: string, isEventChanged: boolean) {
    this.getFilteredConfiguredActions(eventName);
    this.getTree(eventName);
    this.getActionTree(eventName);
    if (isEventChanged || this.configuredRule.eventKey.eventName === '') {
      this.configuredRule.eventKey.eventName = eventName;
      this.child.resetRightPanel(false);
      this.configuredEntityForm.updateValueAndValidity();
    }
    this.getEntityStateTreeNodes();
  }

  public canNavigateAway(): boolean {
    if (this.isDataSaved || this.isDeleteOperation || this.hasAssociation) {
      return true;
    }
    if (this.inputParams.action === OperationType.ADD && this.isCreateFormUpdated() ||
        this.inputParams.action === OperationType.EDIT && this.isEditFormUpdated()) {
      return false;
    }
    return true;
  }

  private isEditFormUpdated(): boolean {
    return JSON.stringify(this.configuredRule) !==
        JSON.stringify(this.buildConfiguredRule());
  }

  private isCreateFormUpdated(): boolean {
    return this.child.rightPaneAttributes.conditions.length !== 0 ||
        this.child.actionBuilder.rightPaneActions.length !== 0 ||
        this.configuredEntityForm.controls.ruleName.value !== '' ||
        this.configuredEntityForm.controls.ruleDescription.value !== '' ||
        this.child.thenClauseBuilderComponent.ruleThenClauseOperationBuilderComponent.rightPaneOperations.length !== 0;
  }

  private isAttributeTreeRenderedForEvent(eventType: string): boolean {
    return this.incomingEventType === eventType;
  }

  private getTree(eventType: string): void {
    if (this.isAttributeTreeRenderedForEvent(eventType)) {
      return;
    }
    this.incomingEventType = eventType;
    this.attributeMap = Object.create({});
    this.selectedEventSchema = this.schemaMap[eventType];
    const treeNodes = this.eventSchemaParser.extractAttributes(JSON.parse(this.schemaMap[eventType]), '$', this.attributeMap, SourceType.EVENT);
    if (treeNodes.length === 0) {
      this.attributesExist = false;
      this.eventTreeNodes = [];
    } else {
      this.attributesExist = true;
      this.eventTreeNodes = treeNodes;
    }
  }

  private getEntityStateTreeNodes() {
    this.configuredEntityStates.forEach((configuredEntityState: ConfiguredEntityState) => {
      const entityStateAttributeMap: { [key: string]: AttributeMetadata } = Object.create({});
      const entityStateTreeNode = this.eventSchemaParser.extractAttributes(JSON.parse(configuredEntityState.schema),
        '$', entityStateAttributeMap, SourceType.ENTITY_STATE);
      if (entityStateTreeNode.length > 0) {
        this.entityStateTreeNodeList[configuredEntityState.name] = entityStateTreeNode;
      }
      if (entityStateAttributeMap && Object.keys(entityStateAttributeMap).length > 0) {
        Object.keys(entityStateAttributeMap).forEach(nextEntityStateAttributeKey => {
          const entityStateAttribute: AttributeMetadata = entityStateAttributeMap[nextEntityStateAttributeKey];
          entityStateAttribute.entityStateName = configuredEntityState.name;
          entityStateAttribute.entityStateId = configuredEntityState.id;
          this.attributeMap[entityStateAttribute['id']] = entityStateAttribute;
        });
      }
    });
    this.entityStateExist = Object.keys(this.entityStateTreeNodeList).length > 0;
  }

  private onTreeLoad(tree: { treeModel: { expandAll: () => void } }): void {
    tree.treeModel.expandAll();
  }

  private getActionTree(eventType: string): void {
    this.buildActionTree(eventType);
    if (Object.keys(this.actionTreeNodes).length > 0) {
      this.actionExist = true;
    } else {
      this.actionExist = false;
    }
  }

  private onActionTreeLoad(tree: { treeModel: { expandAll: () => void } }): void {
    tree.treeModel.expandAll();
  }

  private buildActionTree(eventType: string) {
    this.actionTreeNodes = [];
    const standardActions = [];
    const customActions = [];
    this.filteredConfiguredActions.forEach(configuredAction => {
      if (configuredAction.entityType === EntityTypes.PACKAGED &&
       this.actionDestinations.find(actionDestination => actionDestination.actionDestination === configuredAction.actionProvider) !== undefined) {
        standardActions.push(this.eventSchemaParser.buildNode(SourceType.ACTION, configuredAction['id'],
          configuredAction['name'], ''));
      } else if (configuredAction.entityType === EntityTypes.CUSTOM && eventType === configuredAction.eventId.eventName) {
        customActions.push(this.eventSchemaParser.buildNode(SourceType.ACTION, configuredAction['id'],
          configuredAction['name'], ''));
      }
    });
    if (standardActions.length > 0) {
      this.actionTreeNodes[ActionTypes.STANDARD_ACTIONS] = standardActions;
    }
    if (customActions.length > 0) {
      this.actionTreeNodes[ActionTypes.CUSTOM_ACTIONS] = customActions;
    }
  }

  private confirmDeleteConfiguredRule() {
    this.isApiServiceCalled = true;
    this.operationStatusMessage = 'Deleting rule';
    this.ruleService.deleteConfiguredRule(this.parentId, this.configuredRule['id']).pipe(takeUntil(this.destroyed$)).subscribe(serviceResponse => {
      this.isApiServiceCalled = false;
      this.popupService.showDeleteMessage(`Rule '${this.configuredRule.name}' deleted successfully`);
      this.router.navigate(['rules']).catch(() => '');
    }, error => {
      this.isApiServiceCalled = false;
      if (error.status === 403) {
        this.popupService.showErrorMessage(error.error.statusMessage);
      } else {
        this.popupService.showErrorMessage(this.getOperationFailureMessage());
        this.operationFailure = true;
      }
    });
  }

  private addConfiguredRule(configuredRule: ConfiguredRule) {
    this.isApiServiceCalled = true;
    this.ruleService.addConfiguredRule(this.parentId, configuredRule).pipe(takeUntil(this.destroyed$)).subscribe(serviceResponse => {
      this.responseArr.push(serviceResponse);
      this.isDataSaved = true;
      this.inputParams.ruleId = this.responseArr[0].result.id;
      this.isApiServiceCalled = false;
      this.isSaveClicked = false;
      this.isEditRule = true;
      this.getEntities();
      this.popupService.showSuccessMessage('Rule "' + configuredRule.name + '" successfully added', true);
      this.inputParams.action = OperationType.EDIT;
      this.router.navigateByUrl('rules', {skipLocationChange: true}).then( () => {
        this.router.navigate([`rules/${this.inputParams.action}/${this.inputParams.ruleId}`]);
      });
    }, error => {
      this.isDataSaved = false;
      this.isSaveClicked = false;
      this.isApiServiceCalled = false;
      this.popupService.showErrorMessage(Messages.requiredFields);
    });
  }

  private updateConfiguredRule(configuredRule: ConfiguredRule) {
    configuredRule.id = this.configuredRule['id'];
    this.isApiServiceCalled = true;
    this.operationFailure = false;
    this.ruleService.updateConfiguredRule(this.parentId, configuredRule.id, configuredRule).pipe(takeUntil(this.destroyed$))
      .subscribe(serviceResponse => {
        this.isApiServiceCalled = false;
        this.getEntities();
        this.configuredRule = new ConfiguredRule(serviceResponse['result']);
        this.isSaveClicked = false;
        this.popupService.showSuccessMessage('Rule "' + configuredRule.name + '" successfully updated');
        this.isDataSaved = false;
      }, error => {
        this.isSaveClicked = false;
        this.isDataSaved = false;
        this.isApiServiceCalled = false;
        if (error.status === 403) {
          this.popupService.showErrorMessage(Messages.updateRuleErrorMessage);
        } else {
          this.popupService.showErrorMessage(this.getOperationFailureMessage());
          this.operationFailure = true;
        }
      });
  }

  private buildConfiguredRule(): ConfiguredRule {
    const configuredRule: ConfiguredRule = new ConfiguredRule(this.configuredRule);
    const eventKey: EventKey = new EventKey();
    eventKey.eventName = this.configuredEntityForm.controls.eventName.value;
    eventKey.eventSourceName = this.eventSourceProvider;
    configuredRule.eventKey = eventKey;
    configuredRule.parentId = this.parentId;
    configuredRule.companyId = this.child.isCompanyApplicable(this.eventSourceProvider) ? this.configuredEntityForm.controls.companyId.value : '';
    configuredRule.name = this.configuredEntityForm.controls.ruleName.value;
    configuredRule.description = this.configuredEntityForm.controls.ruleDescription.value === null ? '' :
        this.configuredEntityForm.controls.ruleDescription.value;
    configuredRule.descriptionAutoGenerated = false;
    const rule = this.child.getRule(
      configuredRule.companyId, eventKey.eventSourceName, eventKey.eventName, this.configuredEntityStates);
    if (rule) {
      configuredRule.setRule(rule);
      return configuredRule;
    } else {
      return;
    }
  }

  public copyConfiguredRule(): void {
    this.isApiServiceCalled = true;
    this.entityCopyService.copyEntity(this.parentId, EntityCopy.build(this.inputParams.ruleId, Feature.CFG_RULE))
      .pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.isApiServiceCalled = false;
        this.popupService.showSuccessMessage(`Copy Successful. New Rule '${<string>res['result'].name}' successfully created`);
      }, error => {
        this.isApiServiceCalled = false;
        this.popupService.showErrorMessage(error.error.result);
      });
  }

  private checkIfRuleNameAlreadyExists() {
    const isAleradyExists = this.configuredRules.find(existingConfiguredRule =>
      existingConfiguredRule.name.toLowerCase() === this.configuredEntityForm.controls.ruleName.value.toLowerCase());
    if (isAleradyExists !== undefined) {
      this.configuredEntityForm.controls.ruleName.setErrors({ 'alreadyExist': true });
    }
  }

  private eventName(): AbstractControl {
    return this.configuredEntityForm.get('eventName');
  }

  private validateAssociationStatus(): void {
    if (!this.isEditRule) {
      this.hasAssociation = false;
    }
    this.isApiServiceCalled = true;
    this.associationService.getAssociations(this.parentId, ConfiguredEntityType.RULE, this.inputParams.ruleId).subscribe(success => {
      this.hasAssociation = ASSOCIATION_API_FIELD_STATUS in success['result'] ? success['result'][ASSOCIATION_API_FIELD_STATUS] : false;
      this.associationStatus = new ConfiguredEntityAssociationParams(
        null, this.parentId, this.inputParams.ruleId, ConfiguredEntityType.RULE, { EVENT_SOURCE: this.eventSourceProvider });
      this.isApiServiceCalled = false;
    },
    error => {
      this.isApiServiceCalled = false;
      this.hasAssociation = false;
      if ((!('result' in error.error)) || (error.error.statusMessage === 'FAILED')) {
        this.popupService.showErrorMessage(Messages.associationRequestErroredMessage);
      } else {
        this.popupService.showErrorMessage(error.error.statusMessage);
      }
    });
  }

}
