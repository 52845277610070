import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WarningOptions } from './warning-options';

@Injectable()
export class WarningState {

  options: WarningOptions;
  modal: NgbModalRef;

}