<form novalidate [formGroup]="configuredAWSActionForm">
  <div class="form-group">
    <label for="resourceName">Amazon Resource Name</label>
    <input
      type="text"
      id="resourceName"
      class="form-control"
      placeholder="Enter an Amazon Resource Name for this Action"
      formControlName="resourceName"
    />
    <div *ngIf="resourceName.invalid && isSaveClicked" class="text-color-warning">
      <ng-container *ngIf="resourceName.errors.required">
        Please enter an Amazon Resource Name for this Action
      </ng-container>
    </div>
  </div>
  <div class="form-group">
    <label for="awsRegion">Region</label>
    <select 
      id="awsRegion" 
      class="form-control" 
      placeholder="Enter an AWS Region for this Action" 
      formControlName="awsRegion"
    >
      <option value="null" disabled selected>Select a Region</option>
      <option *ngFor="let item of regionsJson;" [value]="item.id">{{item.name}}</option>
    </select>
    <div *ngIf="awsRegion.invalid && isSaveClicked" class="text-color-warning">
      <ng-container *ngIf="awsRegion.errors.required">
        Please enter an AWS Region for this Action
      </ng-container>
    </div>
  </div>
</form>