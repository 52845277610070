import { Configuration } from './configuration';
import { Testdata } from './testdata';
import { ProgramType } from 'src/app/shared/program-type';
import { ConfiguredProgramTestDataMap } from './configured-program-test-data/configured-program-test-data-map';
import { ConfiguredProgramDetails } from 'src/app/shared/models/configured-program-details';
import { PackagedProgramRule } from './packaged-program-rule';
import { PackagedProgramAction } from './action/packaged-program-action';
import { ActionType } from './action/action-type';
import { EventType } from 'src/app/shared/event-type';
import { TestDataSet } from 'src/app/shared/models/configured-progra-test-data/test-data-set.model';
import { ConfiguredProgramTestData } from './configured-program-test-data/configured-program-test-data';
import { Constants, RuleTypes } from 'src/app/shared/constants';
import { ConfiguredRule } from './configured-rule.model';
import { HarmonyRTMAction } from './custom-rule/actions/harmony-rtm-action';
import { Feature } from './permission/feature/role-permission-constants';
import { EntityType } from '../entity-type';
import { Program } from './program';
import { CustomRule } from './custom-rule/custom-rule';

export class ConfiguredProgram {

  programType: string;
  name: string;
  description: string;
  id: string;
  mode: string;
  testData: Testdata;
  configuredProgramTestDataMap: ConfiguredProgramTestDataMap;
  configuration: Configuration;
  packagedProgramName: string;
  active: boolean;

  constructor(response?: Object) {
    if (response) {
      Object.assign(this, JSON.parse(JSON.stringify(response['result'])));
      this.testData = new Testdata(response['result']['testData']);
      this.initConfiguredProgramTestDataMap(response['result']['configuredProgramTestDataMap']);
    }
  }

  public initConfiguredProgramTestDataMap(configuredProgramTestData: any) {
    if (configuredProgramTestData !== null && Object.keys(configuredProgramTestData).length > 0) {
      this.configuredProgramTestDataMap = new ConfiguredProgramTestDataMap().fromJson(configuredProgramTestData);
    } else {
      this.configuredProgramTestDataMap = new ConfiguredProgramTestDataMap();
    }
  }

  isPackagedProgram() {
    return this.programType === ProgramType.PACKAGED;
  }

  isCustomProgram() {
    return this.programType === ProgramType.SELF_SERVICE;
  }

  isCustomWithExternalRules() {
    return this.programType === ProgramType.SELF_SERVICE_EXTERNAL;
  }

  public isCustomWithExternalPackagedRules() {
    return this.programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED;
  }

  public getFeature(): Feature {
    if (this.isPackagedProgram()) {
      return Feature.PKG_PRG;
    }
    return Feature.CUST_PRG; 
  }

  public getActionDestinationIds() {
    const programActionDestinationIds = new Set();
    if (this.configuration.rules !== undefined) {
      if (this.isPackagedProgram()) {
        return this.getActionDestinationIdsFromPackagedProram();
      }
      let rules: CustomRule[] = JSON.parse(this.configuration.rules);
      if (this.isCustomWithExternalPackagedRules()) {
        rules = rules.filter(rule => rule.type !== RuleTypes.DROOLS);
        rules.forEach(rule => rule.thenClause.actions.forEach(action => programActionDestinationIds.add(action['product'])));
        return Array.from(programActionDestinationIds.values());
      }
      rules.forEach(rule => rule.actions.forEach(action => programActionDestinationIds.add(action['product'])));
      return Array.from(programActionDestinationIds.values());
    } else {
      return [];
    }
  }

  public getBusinessUnits(rules: PackagedProgramRule[], programType: string): string[] {
    const programBUs: string[] = [];
    rules.forEach((rule: PackagedProgramRule) => {
      rule = Object.assign(new PackagedProgramRule(), rule);
      if (!rule.actions && programType === ProgramType.SELF_SERVICE_EXTERNAL_PACKAGED) {
        const configuredRule = JSON.stringify(rule);
        const configuredRuleObj = JSON.parse(configuredRule);
        if (configuredRuleObj && configuredRuleObj.thenClause && configuredRuleObj.thenClause.actions) {
          (configuredRuleObj.thenClause.actions).forEach((action) => {
            programBUs.push(action.actionSettingsInput.buId);
          });
        }
      } else {
        if (rule.actions) {
          const apiActions: PackagedProgramAction[] = rule.getActions(ActionType.API_ACTION);
          apiActions.forEach(action => {
            const serviceInput = action.actionSettingsInput['serviceInput'];
            if (serviceInput.businessUnitId) {
              programBUs.push(serviceInput.businessUnitId);
            }
            if (serviceInput.buId) {
              programBUs.push(serviceInput.buId);
            }
          });
        }
      }
    });
    return programBUs;
  }

  public getRules(program: ConfiguredProgramDetails): PackagedProgramRule[] {
    const programRules: PackagedProgramRule[] = [];
    const rules = JSON.parse(program.configuration.rules);
    rules.forEach((rule: PackagedProgramRule) => {
      rule = Object.assign(new PackagedProgramRule(), rule);
      programRules.push(rule);
    });
    return programRules;
  }

  public getEventSourceEventTypesMap() : Map<string, string[]> {
    const configuration: Configuration = Object.assign(new Configuration(), this.configuration);
    const eventMap : Map<string, string[]> = configuration.getSourceAndEventsMap(this.programType as ProgramType);
    return eventMap;
  }

  public getTestDataset() : TestDataSet[] {
    let testDatasetList: TestDataSet[] = [];
    if (this.isCustomWithExternalRules()) {
      if (this.configuredProgramTestDataMap.testDataMap.size > 0) {
        testDatasetList = this.getTestDataSetFromTestDataMapForExternalCustomProgram();
      } else if (this.configuredProgramTestDataMap.testDataMap.size == 0) {
        testDatasetList = this.getTestDatasetFromOldTestData();
      }
    } else if (this.isCustomProgram()) {
      testDatasetList = this.getTestDatasetFromOldTestdataCustomProgram();
    } else if (this.isPackagedProgram()) {
      if (this.configuredProgramTestDataMap.testDataMap.size) {
        testDatasetList = this.getTestDataSetFromTestDataMapForPackageProgram();
      }
    } else if (this.isCustomWithExternalPackagedRules() && this.configuredProgramTestDataMap.testDataMap.size > 0) {
      testDatasetList = this.getTestDataSetForSelfServiceExtranalPackageProgram();
    }
    return testDatasetList;
  }

  public getAuthorizationFeatureName(): string {
    if (this.programType === EntityType.PACKAGED) {
      return Feature.PKG_PRG;
    } else if (this.programType === EntityType.SELF_SERVICE
      || this.programType === EntityType.SELF_SERVICE_EXTERNAL || this.programType === EntityType.SELF_SERVICE_EXTERNAL_PACKAGED) {
      return Feature.CUST_PRG;
    }
  }

  public findNewlyAddedEmptyActionRulesFromTemplate(program: Program): Record<string, string>[] {
    const rulesInProgramTemplate = [];
    let missing = [];
    // get action-less rules from packaged program template
    program.rules
      .filter(r => r.actionDestinationSettings.length === 0)
      .forEach( r => rulesInProgramTemplate.push({ ruleId: r.ruleId, eventType: r.eventType }));
    const rulesInConfiguredProgram = this.getNonAPIActionRules();
    // locate missing rules
    if (rulesInConfiguredProgram.length !== 0) {
      missing = rulesInProgramTemplate.filter(rpt => rulesInConfiguredProgram.indexOf(rpt.ruleId) < 0);
    }
    const newRules = [];
    // prepare payload
    // eslint-disable-next-line quotes
    missing.forEach(rule => newRules.push({ ruleId: rule.ruleId + Constants.DB_KEY_SEPARATOR + rule.eventType, actions: "[]" }));
    return newRules;
  }

  private getNonAPIActionRules(): string[] {
    const rulesInConfiguredProgram = [];
    const cpRules: PackagedProgramRule[] = JSON.parse(this.configuration.rules);
    cpRules
      .filter(cpr => this.isNonAPIActionConfiguredRule(JSON.parse(cpr.actions)))
      .forEach(cpr => rulesInConfiguredProgram.push(cpr.ruleId.split(Constants.DB_KEY_SEPARATOR)[0]));
    return rulesInConfiguredProgram;
  }

  private isNonAPIActionConfiguredRule(actions: PackagedProgramAction[]): boolean {
    if (actions.length === 0) {
      return true;
    }
    const actionable = [];
    actions.filter(a => a.actionType !== ActionType.SCHEDULED_EVENT).map(a => actionable.push(a));
    return actionable.length === 0;
  }

  private getActionDestinationIdsFromPackagedProram():string[] {
    const rules = JSON.parse(this.configuration.rules);
    const actionDestinationIds: Set<string> = new Set();
    rules.forEach((rule: PackagedProgramRule) => {
      rule = Object.assign(new PackagedProgramRule(), rule);
      if (rule.actions) {
        const apiActions: PackagedProgramAction[] = rule.getActions(ActionType.API_ACTION);
        apiActions.forEach(action => actionDestinationIds.add(action.actionInputs[0]));
      }
    });
    return Array.from(actionDestinationIds);
  }

  private getTestDatasetFromOldTestdataCustomProgram() : TestDataSet[] {
    const testDatasetList: TestDataSet[] = [];
    const testDataModel = this.testData;
    let testData: string;
    let testDataAttributePath: string;
    if (testDataModel.actionDestinationsTestData !== undefined) {
      const harmonyAction: HarmonyRTMAction = new HarmonyRTMAction();
      testDataAttributePath = testDataModel.getTestDataAttributePath(harmonyAction.actionDestination);
      testData = testDataModel.getTestData(harmonyAction.actionDestination);
      const eventSource = this.configuration.eventSource;
      const eventType = this.configuration.eventType;
      testDatasetList.push(new TestDataSet(eventSource, eventType, testDataAttributePath, testData, true));
    }
    return testDatasetList;
  }

  private getTestDatasetFromOldTestData() : TestDataSet[] {
    const testDatasetList: TestDataSet[] = [];
    const testDataModel = this.testData;
    let testData: string;
    let testDataAttributePath: string;
    let rules: CustomRule[] = JSON.parse(this.configuration.rules);
    rules = rules.filter(rule => rule.type !== RuleTypes.DROOLS);
    if (JSON.stringify(this.testData) !== '{}') {
      for (const index in rules) {
        const configuredRule : ConfiguredRule = new ConfiguredRule(rules[index]);
        configuredRule.setRuleString(configuredRule);
        configuredRule.setRuleObject();
        const actionDestination = configuredRule.getActions()[0].actionInputs[0];
        if (actionDestination !== undefined) {
          testData = testDataModel.getTestData(actionDestination);
          testDataAttributePath = testDataModel.getTestDataAttributePath(actionDestination);
        }
        if (testData) {
          testDatasetList.push(new TestDataSet(configuredRule.eventKey.eventSourceName, configuredRule.eventKey.eventName, testDataAttributePath, testData, true));
        }
      }
    }
    return testDatasetList;
  }

  private getTestDataSetFromTestDataMapForPackageProgram(): TestDataSet[] {
    const configuration: Configuration = Object.assign(new Configuration(), this.configuration);
    const testDatasetList: TestDataSet[] = [];
    this.configuredProgramTestDataMap.testDataMap.forEach((configuredProgramTestData: ConfiguredProgramTestData, eventSourceEventTypekey: string) => {
      configuredProgramTestData.eventAttributeTestDataMap.forEach((testData: string[], testDataAttributePath: string) => {
        testDatasetList.push(new TestDataSet(eventSourceEventTypekey.split(Constants.DB_KEY_SEPARATOR)[0],
          eventSourceEventTypekey.split(Constants.DB_KEY_SEPARATOR)[1], testDataAttributePath,
          testData.toString(), true, configuration.eventSourceName));
      });
    });
    return testDatasetList;
  }

  private getTestDataSetFromTestDataMapForExternalCustomProgram() : TestDataSet[] {
    const testDatasetList: TestDataSet[] = [];
    let rules: CustomRule[] = JSON.parse(this.configuration.rules);
    rules = rules.filter(rule => rule.type !== RuleTypes.DROOLS);
    for (const index in rules) {
      const configuredRule : ConfiguredRule = new ConfiguredRule(rules[index]);
      configuredRule.setRuleString(configuredRule);
      configuredRule.setRuleObject();
      const testDataSetListForRule: TestDataSet[] = configuredRule.getTestData(this.configuredProgramTestDataMap);
      if (testDataSetListForRule.length > 0) {
        for (let index = 0; index < testDataSetListForRule.length; index++) {
          testDatasetList.push(testDataSetListForRule[index]);
        }
      }
    }
    return testDatasetList;
  }

  private getTestDataSetForSelfServiceExtranalPackageProgram(): TestDataSet[] {
    const rules: CustomRule[] = JSON.parse(this.configuration.rules);
    const eventSource = rules.find(rule => rule.type === RuleTypes.PACKAGED).eventKey.eventSourceName;
    const testDatasetList: TestDataSet[] = [];
    this.configuredProgramTestDataMap.testDataMap.forEach((configuredProgramTestData: ConfiguredProgramTestData, eventSourceEventTypekey: string) => {
      configuredProgramTestData.eventAttributeTestDataMap.forEach((testData: string[], testDataAttributePath: string) => {
        testDatasetList.push(new TestDataSet(eventSourceEventTypekey.split(Constants.DB_KEY_SEPARATOR)[0],
          eventSourceEventTypekey.split(Constants.DB_KEY_SEPARATOR)[1], testDataAttributePath,
          testData.toString(), true, eventSource));
      });
    });
    return testDatasetList;
  }

}