import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Messages } from 'src/app/shared/message';

@Component({
  selector: 'app-iqdecisions-view-program-usage',
  templateUrl: './iqdecisions-view-program-usage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
  })
export class IqdecisionsViewProgramUsageComponent {

  @Input() programUsageProperties = {};
  @Input() programUsageTableData = {};

  public messages = Messages;

}