<form [formGroup]="configuredRuleForm">
  <div class="row m-0 rule-build-help" *ngIf="this.rule.eventKey.eventName === ''">
    <div class="col text-center">
      <p class="text-style-muted-2 py-4 mb-0">Select an event to get started</p>
    </div>
  </div>
  <div class="row mx-1 border" *ngIf="this.rule.eventKey.eventName !== ''">
    <div class="col-xs-6 py-4 pl-3 pr-1 font-weight-bold">
      {{this.rule.eventKey.eventName}}
    </div>
    <div class="col-xs-6 py-4 px-1">
      event from
    </div>
    <div class="col-xs-6 py-4 px-1 font-weight-bold">
      {{this.rule.eventKey.eventSourceName}}
    </div>
    <div class="col-xs-6 py-4 px-1" *ngIf="this.rule.eventKey.eventSourceName==='Conversant'">
      for company
    </div>
    <div class="col-xs-6 py-3 px-1" *ngIf="this.rule.eventKey.eventSourceName==='Conversant'">
      <div class="form-group mb-0">
        <label for="companyIdControl" class="sr-only">Company ID</label>
        <select id="companyIdControl" class="form-control" formControlName="companyId">
          <option value="" disabled selected>Choose company</option>
          <option *ngFor="let item of this.companyList" value="{{item.companyId}}">
            {{item.companyName}}
          </option>
        </select>
        <div *ngIf="!validateCompanyId(configuredRuleForm)" class="text-color-warning">
          Please select company ID
        </div>
      </div>
    </div>
    <div class="col-xs-6 py-4 px-1">
      is received
    </div>
  </div>
  <div class="row mx-2">
    <div class="col p-0">
      <p class="font-size-lg my-3">
        If
      </p>
    </div>
  </div>
  <app-rule-condition-builder *ngIf="!rule.isRuleTypePackaged()"
    #ruleConditionBuilder
    [isReadOnlyRule]="isReadOnlyRule"
    [rightPaneAttributes]="rightPaneAttributes"
    [attributeMap]="attributeMap"
    [showDeleteButton]="showDeleteButton"
    [validationRequired]="validationRequired">
  </app-rule-condition-builder>
  <app-rule-when-clause-builder *ngIf="rule.isRuleTypePackaged()"
    [isReadOnlyRule]="isReadOnlyRule"
    [ruleWhenClause]="rule.ruleObject.whenClause"
    [attributeMap]="attributeMap"
    [showDeleteButton]="showDeleteButton"
    [validationRequired]="validationRequired">
  </app-rule-when-clause-builder>
  <div class="row mx-2">
    <div class="col p-0">
      <p class="font-size-lg my-3">
        Then
      </p>
    </div>
  </div>
  <app-rule-action-builder *ngIf="!rule.isRuleTypePackaged()"
    #actionBuilder
    [formGroup]="configuredRuleForm"
    [filteredConfiguredActions]="filteredConfiguredActions"
    [actionDestinations]="actionDestinations"
    [selectedEventSchema]="selectedEventSchema"
    [eventSources]="eventSources"
    [configuredEventDetails]="configuredEventDetails"
    [configuredRule]="rule"
    [isReadOnlyRule]="isReadOnlyRule"
    [showDeleteButton]="showDeleteButton"
    [validationRequired]="validationRequired">
  ></app-rule-action-builder>
  <app-rule-then-clause-builder
    [formGroup]="configuredRuleForm"
    [configuredRule]="rule"
    [attributeMap]="attributeMap"
    [filteredConfiguredActions]="filteredConfiguredActions"
    [actionDestinations]="actionDestinations"
    [selectedEventSchema]="selectedEventSchema"
    [eventSources]="eventSources"
    [configuredEventDetails]="configuredEventDetails"
    [isReadOnlyRule]="isReadOnlyRule"
    [showDeleteButton]="showDeleteButton"
    [validationRequired]="validationRequired"
  ></app-rule-then-clause-builder>
</form>
