import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DragDrop } from '@epsilon/core-ui';
import { FormOnSaveAction } from 'src/app/shared/constants';
import { AttributeCondition } from 'src/app/shared/models/custom-rule/conditions/attribute-condition';
import { AttributeMetadata } from 'src/app/shared/models/custom-rule/conditions/attribute-metadata';
import { DataTypes } from 'src/app/shared/models/custom-rule/conditions/data-types';
import { Exclusion } from 'src/app/shared/models/exclusion/exclusion';
import { BaseExclusionConfiguration } from 'src/app/shared/models/exclusion/base-exclusion-configuration';
import {
  ExclusionOperator,
  ExclusionOperatorDisplayNameMap as exclusionOperatorDisplayNameMap,
  ExclusionOperators,
  Operators
} from 'src/app/shared/models/exclusion/exclusion-operator';
import { ConfiguredEntityState } from 'src/app/shared/models/entity-states/configured-entity-state';
import { EntityStateSchema } from 'src/app/shared/models/entity-states/entity-state-schema';
import { AndCondition } from 'src/app/shared/models/custom-rule/conditions/and-condition';
import { ValueType } from 'src/app/shared/models/custom-rule/conditions/value-type';
import { ExclusionDisplayMode } from 'src/app/shared/models/exclusion/exclusion-display-mode';
import { ExclusionConfigurationDirective } from 'src/app/shared/exclusion/exclusion-configuration/exclusion-configuration.directive';
import { ExclusionConfiguationComponentBuilderService } from 'src/app/shared/services/exclusion-configuation-component-builder.service';
import { EntityType } from 'src/app/shared/models/entity-type';
import { NotCondition } from 'src/app/shared/models/custom-rule/conditions/not-condition';

@Component({
  selector: 'app-exclusion-configuration-builder',
  templateUrl: './exclusion-configuration-builder.component.html',
  styleUrls: ['./exclusion-configuration-builder.component.scss']
})
export class ExclusionConfigurationBuilderComponent implements OnInit, OnDestroy {

  @Input()
  public action: FormOnSaveAction;

  @Input()
  configuredEntityStates: ConfiguredEntityState[];

  @Input()
  public attributeMetadataList: AttributeMetadata[];

  @Input()
  public exclusion: Exclusion;

  @Input()
  public readOnly: boolean;

  @ViewChild(ExclusionConfigurationDirective, { static: true })
  public appExclusionConfiguration!: ExclusionConfigurationDirective;

  public droppedEntityStateAttribute: AttributeMetadata;
  public exclusionOperator: ExclusionOperator;
  public exclusionOperatorDisplayNameMap = exclusionOperatorDisplayNameMap;
  public supportedExclusionOperatorsForDatatype: Operators[];
  public exclusionDropAreaConnectedTo = ['systemEntityStateAttributeList', 'customEntityStateAttributeList'];

  private exclusionConfigurationComponentRef: ComponentRef<BaseExclusionConfiguration>;

  public constructor(private exclusionConfiguationComponentBuilderService: ExclusionConfiguationComponentBuilderService) {
  }

  public ngOnInit(): void {
    if (this.action === FormOnSaveAction.EDIT) {
      this.initExclusionConfigurationComponentToEdit();
    }
  }

  public ngOnDestroy(): void {
    this.deleteExclusionConfigurationComponent();
  }

  public onDropped(event: DragDrop<string[]>): void {
    const attributeMetadata: AttributeMetadata = this.attributeMetadataList.find(attributeMetadata => attributeMetadata.id === event.item.data);
    if (attributeMetadata) {
      this.initOnAttributeDrop(attributeMetadata);
    }
  }

  public getDroppedEntityStateAttribute(): AttributeMetadata {
    return this.droppedEntityStateAttribute;
  }

  public getExclusionOperator(): ExclusionOperator {
    return this.exclusionOperator;
  }

  public onEntityStateAttributeDelete(): void {
    this.droppedEntityStateAttribute = undefined;
  }

  public onAddModifier(exclusionOperator: ExclusionOperator): void {
    this.exclusionOperator = exclusionOperator;
    this.createExclusionConfigurationComponent(this.exclusionOperator);
  }

  public validate(): boolean {
    if (!this.exclusionConfigurationComponentRef) {
      return false;
    }
    return this.exclusionConfigurationComponentRef.instance.validate();
  }

  public buildExclusion(): Exclusion {
    if (this.action === FormOnSaveAction.EDIT) {
      return this.buildExclusionToUpdate();
    }
    if (this.action === FormOnSaveAction.CREATE) {
      return this.buildExclusionToCreate();
    }
  }

  public isPristine(): boolean {
    if (!this.exclusionConfigurationComponentRef) {
      return true;
    }
    return this.exclusionConfigurationComponentRef.instance.isPristine();
  }

  public markAsPristine(): void {
    if (this.exclusionConfigurationComponentRef) {
      this.exclusionConfigurationComponentRef.instance.markAsPristine();
    }
  }

  private buildExclusionToCreate(): Exclusion {
    const exclusionCondition: AttributeCondition = this.buildExclusionCondition();
    const exclusion: Exclusion = new Exclusion();
    const entityStateSchema: EntityStateSchema = this.buildEntityStateSchema(exclusionCondition);
    exclusion.setEntityStateSchema(entityStateSchema);
    exclusion.setCondition(new NotCondition(new AndCondition([exclusionCondition])));
    return exclusion;
  }

  private buildExclusionCondition(): AttributeCondition {
    const exclusionCondition: AttributeCondition = new AttributeCondition(
      this.droppedEntityStateAttribute.name,
      this.droppedEntityStateAttribute.path,
      this.droppedEntityStateAttribute.type,
      this.droppedEntityStateAttribute.sourceType
    );
    exclusionCondition.operator = this.exclusionOperator;
    exclusionCondition.entityStateId = this.droppedEntityStateAttribute.entityStateId;
    exclusionCondition.entityStateName = this.droppedEntityStateAttribute.entityStateName;
    exclusionCondition.name = this.droppedEntityStateAttribute.name;
    exclusionCondition.valueType = this.exclusionConfigurationComponentRef.instance.getConditionValueType();
    exclusionCondition.values = this.exclusionConfigurationComponentRef.instance.buildAttributeConditionValues();
    return exclusionCondition;
  }

  private buildEntityStateSchema(attributeCondition: AttributeCondition): EntityStateSchema {
    const configuredEntityState: ConfiguredEntityState = this.configuredEntityStates.find(configuredEntityState =>
      configuredEntityState.id === attributeCondition.entityStateId && configuredEntityState.name === attributeCondition.entityStateName);
    const entityStateSchema: EntityStateSchema = new EntityStateSchema();
    entityStateSchema.id = configuredEntityState.id;
    entityStateSchema.name = configuredEntityState.name;
    entityStateSchema.key = configuredEntityState.entityStateKey;
    entityStateSchema.isSystem = configuredEntityState.type === EntityType.PACKAGED;
    return entityStateSchema;
  }

  private buildExclusionToUpdate(): Exclusion {
    const exclusion: Exclusion = Object.assign(new Exclusion(), this.exclusion);
    const exclusionCondition: AttributeCondition = this.buildExclusionCondition();
    exclusion.setCondition(new NotCondition(new AndCondition([exclusionCondition])));
    return exclusion;
  }

  private createExclusionConfigurationComponent(exclusionOperator: ExclusionOperator): void {
    const componentFactory = this.exclusionConfiguationComponentBuilderService
      .build(exclusionOperator, this.droppedEntityStateAttribute.getAttributeDataType());
    if (!componentFactory) {
      return;
    }
    const viewContainerRef = this.appExclusionConfiguration.viewContainerRef;
    viewContainerRef.clear();
    this.exclusionConfigurationComponentRef = viewContainerRef.createComponent<BaseExclusionConfiguration>(componentFactory);
    this.exclusionConfigurationComponentRef.instance.action = this.action;
    this.exclusionConfigurationComponentRef.instance.attributeName = this.droppedEntityStateAttribute.getAttributeDisplayName();
    this.exclusionConfigurationComponentRef.instance.exclusionDisplayMode = ExclusionDisplayMode.COMFORTABLE;
    this.exclusionConfigurationComponentRef.instance.exclusionOperator = exclusionOperator;
    this.exclusionConfigurationComponentRef.instance.attributeDataType = this.droppedEntityStateAttribute.getAttributeDataType();
    this.exclusionConfigurationComponentRef.instance.allowDelete = true;
    this.exclusionConfigurationComponentRef.instance.attributeMetadataList = this.attributeMetadataList;
    if (this.action === FormOnSaveAction.EDIT) {
      const attributeConditionElement = this.exclusion.getAttributeCondition();
      this.exclusionConfigurationComponentRef.instance.attributeConditionValues = attributeConditionElement.values;
      this.exclusionConfigurationComponentRef.instance.readOnly = this.readOnly;
      this.exclusionConfigurationComponentRef.instance.allowDelete = false;
    }
    this.exclusionConfigurationComponentRef.instance.deleteExclusionConfiguration.subscribe(() => this.deleteExclusionConfigurationComponent());
  }

  private deleteExclusionConfigurationComponent(): void {
    if (this.exclusionConfigurationComponentRef) {
      this.exclusionConfigurationComponentRef.destroy();
    }
    this.exclusionOperator = undefined;
  }

  private initSupportedExclusionOperators(dataType: DataTypes): void {
    dataType = this.getDataType(dataType);
    this.supportedExclusionOperatorsForDatatype = new ExclusionOperators(dataType).exclusionOperators;
  }

  private getDataType(dataType: DataTypes): DataTypes {
    if (this.droppedEntityStateAttribute.format === 'date-time' && dataType === DataTypes.string) {
      return DataTypes.date;
    }
    return dataType;
  }

  private initOnAttributeDrop(attributeMetadata: AttributeMetadata): void {
    this.droppedEntityStateAttribute = Object.assign(new AttributeMetadata(), attributeMetadata);
    this.droppedEntityStateAttribute.type = this.getDataType(attributeMetadata.type);
    this.initSupportedExclusionOperators(attributeMetadata.type);
  }

  private initExclusionConfigurationComponentToEdit(): void {
    const exclusion: Exclusion = Object.assign(new Exclusion(), this.exclusion);
    const attributeCondition: AttributeCondition = exclusion.getAttributeCondition();
    const attributeMetadata: AttributeMetadata = this.attributeMetadataList.find(attributeMetadata => {
      return attributeMetadata.entityStateId === exclusion.getEntityStateSchema().id
      && attributeMetadata.name === exclusion.getAttributeCondition().name;
    });
    this.initOnAttributeDrop(attributeMetadata);
    this.onAddModifier(ExclusionOperator[attributeCondition.operator]);
  }

}
