import { Injectable } from '@angular/core';
import { ActionDestinationService } from './action-destination.service';
import { AuthType } from 'src/app/shared/models/auth-type';
import { CustomActionDestination } from 'src/app/shared/models/custom-action-destination';
import { ActionDestinationServiceInterface } from './action-destination-service-interface';

@Injectable({
  providedIn: 'root'
})
export class OauthActionDestinationService extends ActionDestinationService implements ActionDestinationServiceInterface {

  buildCredentials(formValue: any): CustomActionDestination {
    const custActionDestination = new CustomActionDestination();
    custActionDestination.parentId = formValue.context.getParentContext();
    custActionDestination.name = formValue.getCustomActionDestinationNameControl().value;
    custActionDestination.description = formValue.getCustomActionDestinationDescriptionControl().value;
    if(formValue.getAuthMethodControl().value === AuthType.OAUTH) {
        const oauthAccountSettings = formValue.oauthCredentialComponent.getCredentials();
        custActionDestination.oauthActionSettings = oauthAccountSettings;
    }
    return custActionDestination;
  }
  doPostConfiguredActionDestination(formValue: any) {
    return this.configureActionDestination(formValue.context.parentId, JSON.stringify(this.buildCredentials(formValue).buildPostPayload()));
  }
  doPutConfiguredActionDestination(formValue: any) {
    return this.saveActionSettingConfiguration(JSON.stringify(this.buildCredentials(formValue).buildPostPayload()), formValue.context.parentId, formValue.existingCustomActionDestinationId);
  }

}
