export enum PackagedProgramWithoutReminder {
  FacebookLeadGenHome = 'FacebookLeadGenHome',
  FacebookLeadGenHomeOx='FacebookLeadGenHomeOx',
  LeadGenCheckoutV2 = 'LeadGenCheckoutV2',
  LeadGenHome = 'LeadGenHome',
  Purchase = 'Purchase',
  TH_Confirmation = 'TH_Confirmation',
  WebCategoryRecommendations = 'WebCategoryRecommendations',
  WebCategoryRecommendationsV2 = 'WebCategoryRecommendationsV2',
  CurrysRMNEntityContent = 'CurrysRMNEntityContent'
}