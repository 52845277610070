<app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
<div class="container-fluid my-3">
  <form [formGroup]="activityLookupForm">
    <div class="row mt-3">
      <div class="col-md-2">
        <coreui-form-field class="selectShort">
          <coreui-select id="lookUpKey" formControlName="lookUpKey" placeholder="Select a lookup key" #shortSelect >
            <coreui-option *ngFor="let activityKey of activityKeys" [value]="activityKey">{{activityKeyDisplayName[activityKey]}}</coreui-option>
          </coreui-select>
          <coreui-form-error>Please enter lookup key</coreui-form-error>
        </coreui-form-field>
      </div>
      <div class="col-md-2">
        <coreui-form-field >
          <input coreUIInput formControlName="lookUpValue" placeholder="Enter value" />
          <coreui-form-error>Required</coreui-form-error>
        </coreui-form-field>
      </div>
      <div class="col-md-2 mt-2 pl-5">
        <span>Lookback period</span>
      </div>
      <div class="col-md-2">
        <coreui-form-field class="inlineSelect">
          <coreui-select id="lookBackPeriod" formControlName="lookBackPeriod" #inlineSelect >
            <coreui-option [value]="1">1 day</coreui-option>
            <coreui-option *ngFor="let in of counter(29); let i = 'index+2'" [value]="i">{{ i }} days</coreui-option>
          </coreui-select>
        </coreui-form-field>
      </div>
      </div>
    <div class="row mt-1">
      <div class="col-md-2 mt-2"><span>Company Id</span></div>
      <div class="col-md-2">
        <coreui-form-field class="selectShort">
          <coreui-select id="companyId" formControlName="companyId" placeholder="Select a company" #shortSelect >
            <coreui-option *ngFor="let item of this.companyList"
              value="{{item.companyId}}">{{item.companyName}}</coreui-option>
          </coreui-select>
        </coreui-form-field>
      </div>
      <div class="col-md-2 mt-2 pl-5">Activity type</div>
      <div class="col-md-2">
        <coreui-form-field class="selectShort">
          <coreui-select id="activityType" formControlName="activityType" placeholder="Select a activity type" #shortSelect >
            <coreui-option *ngFor="let activityType of activityTypes" [value]="activityType">{{ activityType }}</coreui-option>
          </coreui-select>
          <coreui-form-error>Please enter activity type</coreui-form-error>
        </coreui-form-field>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <button coreUIButton (click)="handleSearch()">Search</button>
      </div>
    </div>
    <div class="row mt-3" *ngIf="filteredRecords.length > 0 || noResult">
      <div class="col">
        <app-ae-table [isDataLoading]="isPageLoading" [properties]="properties"
        [configuredTableData]="filteredRecords">
        </app-ae-table>
      </div>
    </div>
  </form>
</div>
