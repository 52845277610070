<div class="row pt-4">
  <div class="col">
    <div class="row">
      <div class="col">
        <form name="searchForm" [formGroup]="searchFormGroup">
          <coreui-form-field>
            <coreui-autocomplete #autocomplete name="autocomplete" placeholder="Search" formControlName="searchTerm"
              (blur)="onEntityStateAttributeSelected()" (displayValueChange)="onSearchTermInput($event)">
              <coreui-option *ngFor="let attributeDisplayName of filteredAttributeDisplayNames"
                [value]="attributeDisplayName">
                {{ attributeDisplayName }}
              </coreui-option>
              <ng-template #noResults>
                No results found.
              </ng-template>
            </coreui-autocomplete>
          </coreui-form-field>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>Entity States</p>
      </div>
    </div>
    <div class="vertical-scrollable-leftpane">
      <coreui-accordion>
        <div class="row mb-1">
          <div class="col">
            <coreui-accordion-pane label="Standard Entity States"
              [active]="isEntityStateAccordionActive(filteredSystemEntityStateMetadataList)">
              <coreui-accordion>
                <coreui-accordion-pane *ngFor="let entityStateMetadata of filteredSystemEntityStateMetadataList"
                  label="{{entityStateMetadata.name}}" [active]="filteredResult">
                  <div class="drop-list" id="systemEntityStateAttributeList" coreUIDropList data="[]"
                    [connectedTo]="attributeDragConnectedTo">
                    <div *ngFor="let entityStateAttribute of entityStateMetadata.attributes" class="drag-element"
                      coreUIDrag data="{{entityStateAttribute.id}}">
                      <i coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></i>
                      <span class="pl-2" [coreUITooltip]="getAttributeDisplayName(entityStateAttribute)" position="top">
                        {{ getAttributeDisplayName(entityStateAttribute) }}
                      </span>
                    </div>
                  </div>
                </coreui-accordion-pane>
              </coreui-accordion>
            </coreui-accordion-pane>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col">
            <coreui-accordion-pane label="User Defined Entity States"
              [active]="isEntityStateAccordionActive(filteredCustomEntityStateMetadataList)">
              <coreui-accordion>
                <coreui-accordion-pane *ngFor="let entityStateMetadata of filteredCustomEntityStateMetadataList"
                  label="{{entityStateMetadata.name}}" [active]="filteredResult">
                  <div class="drop-list" id="customEntityStateAttributeList" coreUIDropList data="[]"
                    [connectedTo]="attributeDragConnectedTo">
                    <div *ngFor="let entityStateAttribute of entityStateMetadata.attributes" class="drag-element"
                      coreUIDrag data="{{entityStateAttribute.id}}">
                      <i coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></i>
                      <span class="pl-2" [coreUITooltip]="getAttributeDisplayName(entityStateAttribute)" position="top">
                        {{ getAttributeDisplayName(entityStateAttribute) }}
                      </span>
                    </div>
                  </div>
                </coreui-accordion-pane>
              </coreui-accordion>
            </coreui-accordion-pane>
          </div>
        </div>
      </coreui-accordion>
    </div>
    <div *ngIf="filteredCustomEventMetadataList.length>0">
      <div class="row mt-3">
        <div class="col">
          <p>Events</p>
        </div>
      </div>
      <div class="vertical-scrollable-leftpane">
        <coreui-accordion>
          <coreui-accordion-pane label="{{eventMetadata.name}}" [active]="filteredResult"
            *ngFor="let eventMetadata of filteredCustomEventMetadataList">
            <div class="drop-list" id="eventAttributeList" coreUIDropList data="[]" [connectedTo]="eventAttributeDragConnectedTo">
              <div *ngFor="let eventAttribute of getLeafNodesOfDataAttributeFromEvent(eventMetadata)" class="drag-element"
                coreUIDrag data="{{eventAttribute.id}}">
                <i coreUIIcon="dragHandle" scale="1.5" class="drag-handle"></i>
                <span class="pl-2" [coreUITooltip]="getAttributeDisplayName(eventAttribute)" position="top">
                  {{ getAttributeDisplayName(eventAttribute) }}
                </span>
              </div>
            </div>
          </coreui-accordion-pane>
        </coreui-accordion>
      </div>
    </div>
  </div>
</div>