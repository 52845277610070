import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginServiceLocator } from '../services/login.service-locator';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private loginServiceLocator: LoginServiceLocator) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.loginServiceLocator.getLoginService().isLoggedIn()) {
      console.log('logging out via interceptor');
      this.loginServiceLocator.getLoginService().logout();
      return EMPTY;
    } else {
      const token: string = localStorage.getItem('token');
      const userSource: string = localStorage.getItem('user-source');
      const updatedRequest = req.clone({
        setHeaders: {
          'Authorization': token,
          'user-source': userSource
        }
      });
      return next.handle(updatedRequest).pipe(
        tap(
          event => {

            if (event instanceof HttpResponse) {
              console.log('api call success :', event);
            }
          },
          error => {

            if (event instanceof HttpResponse) {
              console.log('api call error :', event);
            }
          }
        )
      );
    }
  }

}