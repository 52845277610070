<app-ae-loading-indicator [isLoading]="isApiServiceCalled" [type]="'loading bar'"></app-ae-loading-indicator>
<form name="exclusionHeaderForm" [formGroup]="exclusionHeaderFormGroup">
  <div class="row border-bottom border-primary bg-cloud pt-4 pb-4">
    <div class="col">
      <div class="row">
        <div class="col">
          <app-ae-button iconPosition="left" icon="chevronLeft" [routerLink]="exclusionsRouterLink" buttonText="Back to Exclusions">
          </app-ae-button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="row">
            <div class="col text-style-2">
              Exclusion
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <coreui-form-field>
            <input type="text" coreUIInput formControlName="name" placeholder="Exclusion name" />
            <coreui-form-error>{{messages.enterUniqueName}}</coreui-form-error>
          </coreui-form-field>
        </div>
        <div class="col">
          <div class="row">
            <ng-container *ngIf="action === 'edit'">
              <div class="col text-align-right" *ngIf="!readOnly && authorizationService.isAllowed('CFG_EXCLUSION', ['DELETE'])">
                <app-ae-button (click)="onDelete()" buttonText="Delete"></app-ae-button>
              </div>
              <div class="col-auto ml-auto pl-0" *ngIf="!isSystemExclusion() && authorizationService.isAllowed('CFG_EXCLUSION', ['CREATE','UPDATE'])">
                <app-ae-button (click)="copyConfiguredExclusion()" buttonText="Copy"></app-ae-button>
              </div>
            </ng-container>
            <div class="col-auto ml-auto pl-0" *ngIf="!readOnly && authorizationService.isAllowed('CFG_EXCLUSION', ['CREATE','UPDATE'])">
              <app-ae-button (click)="onSave()" buttonText="Save"></app-ae-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>