import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { AwsRegions } from 'src/app/shared/models/aws-regions';

@Component({
  selector: 'app-actions-aws-configuration',
  templateUrl: './actions-aws-configuration.component.html'
})
export class ActionsAwsConfigurationComponent implements OnInit {

  @Input() resourceNameValue: string;
  @Input() awsRegionValue: string;
  @Input() isSaveClicked: boolean ;
  public regionsJson: any[];

  public configuredAWSActionForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.configuredAWSActionForm = this.fb.group({
      resourceName: [this.resourceNameValue, [Validators.required]],
      awsRegion: [this.awsRegionValue, [Validators.required]]
    });
    this.regionsJson = [];
    Object.keys(AwsRegions).filter(key => !Number(key) && key !== '0').forEach(key => {
      const value: string = AwsRegions[key];
      this.regionsJson.push({ 'id': value, 'name': key, 'value': value });
    });
  }

  get resourceName(): AbstractControl {
    return this.configuredAWSActionForm.get('resourceName');
  }
  get awsRegion(): AbstractControl {
    return this.configuredAWSActionForm.get('awsRegion');
  }

}