import { ServiceSettings } from './service-settings';

export class AwsServiceSettings extends ServiceSettings {

  resourceName: string;
  awsRegion: string;

  constructor() {
    super();
    this.resourceName = '';
    this.awsRegion = '';
  }

}