import { PackageLevel } from './package-level-enum';
import { PackageType } from './package-type-enum';

export class ConfiguredRecommendation {


  public parentId: string;
  public name: string;
  public description: string;
  public company: {
    id: '';
    name: '';
  };
  public level: string;
  public type: string;
  public metadataConfiguration: {
    packageInfo: {
      choiceType: {
        id: '';
        name: '';
      };
      itemsToReturn: string;
      itemsPerInputLevel: string;
      itemsChoiceType: {
        id: '';
        name: '';
      };
      recommendationType: {
        id: '';
        name: '';
      };
    };
    primaryModel: {
      id: '';
      name: '';
    };
    fallbackModel: {
      id: '';
      name: '';
    };
    groupInfos: [];
  };

  constructor({
    company,
    description,
    maxItemReturned,
    recommendationType,
    choiceType,
    packageName,
    parentId,
    primaryModel,
    fallbackModel,
    groupInfos,
    itemsPerInputLevel,
    level,
    itemsChoiceType }: {
    company: string;
    description: string;
    maxItemReturned: string;
    recommendationType: string;
    choiceType: {
      id: '';
      name: '';
    };
    packageName: string;
    parentId: string;
    primaryModel: { id: ''; name: '' };
    fallbackModel: { id: ''; name: '' };
    groupInfos: [];
    itemsPerInputLevel: string;
    level: string;
    itemsChoiceType: {
      id: '';
      name: '';
    };
  }) {

    this.company = typeof company === 'object' ? company : JSON.parse(company);
    this.description = description;
    this.level = level;
    this.metadataConfiguration = {
      packageInfo: {
        itemsToReturn: maxItemReturned,
        recommendationType: recommendationType
          ? (typeof recommendationType === 'object' ? recommendationType : JSON.parse(recommendationType)) : null,
        choiceType: choiceType?.id && choiceType?.name ? (typeof choiceType === 'object' ? choiceType : JSON.parse(choiceType)) : null,
        itemsPerInputLevel: itemsPerInputLevel || null,
        itemsChoiceType: itemsChoiceType ? (typeof itemsChoiceType === 'object' ? itemsChoiceType : JSON.parse(itemsChoiceType)) : null
      },
      primaryModel: primaryModel || null,
      fallbackModel: fallbackModel || null,
      groupInfos: groupInfos || null
    };
    this.name = packageName;
    this.parentId = parentId;
    this.type = PackageType.SINGLE;
  }

}