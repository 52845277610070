import { AfterViewInit, Component, OnInit, QueryList, Input, Output, ViewChildren, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Permission } from 'src/app/shared/models/permission/role/permission';
import { Action } from 'src/app/shared/models/permission/feature/action';
import { ActionType } from 'src/app/shared/models/permission/feature/action-type';
import { FeaturePermissionsComponent } from '../feature-permissions/feature-permissions.component';

@Component({
  selector: 'app-category-permissions',
  templateUrl: './category-permissions.component.html',
  styleUrls: ['./category-permissions.component.scss']
  })

export class CategoryPermissionsComponent implements OnInit, AfterViewInit {

  @Input() public categoryPermissionTitle: string;
  @Input() public inputPermissionList: Permission[];
  @Input() public isFormReadOnly: boolean;

  @Output() public categoryPermissionsOutput = new EventEmitter<Action[]>();

  @ViewChildren('featurePermissionsComponent') public featurePermissionsComponentList: QueryList<FeaturePermissionsComponent>;

  public categoryPermissionsFormGroup: UntypedFormGroup;
  private featurePermissionsComponent: FeaturePermissionsComponent;

  ngOnInit(): void {
    this.initCategoryPermissionForm();
  }

  public ngAfterViewInit(): void {
    this.featurePermissionsComponent = this.featurePermissionsComponentList.first;
    this.featurePermissionsComponentList.changes.subscribe((component: QueryList<FeaturePermissionsComponent>) => {
      this.featurePermissionsComponent = component.first;
    });
    this.refreshCategoryPermissionForm();
  }

  public refreshCategoryPermissionForm(): void {
    this.categoryPermissionsFormGroup.setValue({
      categoryReadFormControl: this.featurePermissionsComponent.areAllFeaturePermissionsEqual(true, ActionType.READ) ? true : false,
      categoryWriteFormControl: this.featurePermissionsComponent.areAllFeaturePermissionsEqual(true, ActionType.CREATE) ? true : false,
      categoryDeleteFormControl: this.featurePermissionsComponent.areAllFeaturePermissionsEqual(true, ActionType.DELETE) ? true : false
    });
  }

  public onCategoryPermissionClicked(event, actionType: string): void {
    const selectedActionType = actionType as ActionType;
    const isSelected = event.target.checked;
    this.featurePermissionsComponent.updateAllFeaturePermission(isSelected, selectedActionType);
    this.exportCategoryPermissions();
  }

  public updateCategoryPermissions(selected: boolean, actionType: ActionType): void {
    const allPermissionsEqual = this.featurePermissionsComponent.areAllFeaturePermissionsEqual(selected, actionType);
    switch (actionType) {
      case ActionType.READ:
        this.categoryPermissionsFormGroup.controls.categoryReadFormControl.setValue(allPermissionsEqual ? selected : false);
        break;
      case ActionType.CREATE:
        this.categoryPermissionsFormGroup.controls.categoryWriteFormControl.setValue(allPermissionsEqual ? selected : false);
        break;
      case ActionType.DELETE:
        this.categoryPermissionsFormGroup.controls.categoryDeleteFormControl.setValue(allPermissionsEqual ? selected : false);
        break;
    }
    this.refreshCategoryPermissionForm();
    this.exportCategoryPermissions();
  }

  public buildPermissions(): Permission[] {
    return this.featurePermissionsComponent.buildPermissions();
  }

  public updateAllFeaturePermission(selected: boolean, actionType: ActionType): void {
    this.featurePermissionsComponent.updateAllFeaturePermission(selected, actionType);
  }

  private initCategoryPermissionForm(): void {
    this.categoryPermissionsFormGroup = new UntypedFormGroup({
      categoryReadFormControl: new UntypedFormControl({ value: false, disabled: this.isFormReadOnly }),
      categoryWriteFormControl: new UntypedFormControl({ value: false, disabled: this.isFormReadOnly }),
      categoryDeleteFormControl: new UntypedFormControl({ value: false, disabled: this.isFormReadOnly })
    });
  }

  private exportCategoryPermissions(): void {
    const outputActionList: Action[] = [
      this.buildAction(ActionType.READ, this.categoryPermissionsFormGroup.controls.categoryReadFormControl.value),
      this.buildAction(ActionType.CREATE, this.categoryPermissionsFormGroup.controls.categoryWriteFormControl.value),
      this.buildAction(ActionType.DELETE, this.categoryPermissionsFormGroup.controls.categoryDeleteFormControl.value)];
    this.categoryPermissionsOutput.emit(outputActionList);
  }

  private buildAction(actionType: ActionType, allowed: boolean): Action {
    const action = new Action();
    action.actionType = actionType;
    action.allowed = allowed;
    return action;
  }

}