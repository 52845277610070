/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { UserSource } from 'src/app/shared/models/user-source-enum';
import { OktaLoginService } from 'src/app/shared/services/okta.login.service';

import sampleConfig from '../../app.config';

const DEFAULT_ORIGINAL_URI = window.location.origin;

@Component({
  selector: 'app-okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.scss']
})
export class OktaLoginComponent implements OnInit {

  signIn: any;

  constructor(private oktaAuth: OktaAuthService,
    private oktaLoginService: OktaLoginService) {
    this.signIn = new OktaSignIn({
      baseUrl: sampleConfig.oidc.issuer.split('/oauth2')[0],
      clientId: sampleConfig.oidc.clientId,
      redirectUri: sampleConfig.oidc.redirectUri,
      logo: '/assets/img/events-logo.svg',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in'
        }
      },
      authParams: {
        issuer: sampleConfig.oidc.issuer,
        scopes: sampleConfig.oidc.scopes
      }
    });
    // Post sign-in
    this.oktaAuth.$authenticationState.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.oktaLoginService.onLoginSuccess();
      }
    });
  }

  async ngOnInit() {
    localStorage.setItem('user-source', UserSource.OKTA);
    this.signIn.showSignInToGetTokens({
      el: '#sign-in-widget',
      scopes: sampleConfig.oidc.scopes
    }).then(tokens => {
      /* When navigating to a protected route, the route path will be saved as the `originalUri`
         If no `originalUri` has been saved, then redirect back to the app root */
      const originalUri = this.oktaAuth.getOriginalUri();
      if (originalUri === DEFAULT_ORIGINAL_URI) {
        this.oktaAuth.setOriginalUri('/');
      }

      // Remove the widget
      this.signIn.remove();

      // In this flow the redirect to Okta occurs in a hidden iframe
      void this.oktaAuth.handleLoginRedirect(tokens);
    }).catch(err => {
      // Typically due to misconfiguration
      console.log('okta token unavailable:' + err);
      throw err;
    });
  }

}