<coreui-notification notificationStyle="error" messageDuration="10000" [isPersistent]="false" #formError dismissible="true">{{errorMessage}}</coreui-notification>
<coreui-notification notificationStyle="success" [isPersistent]="false" #formSuccess>{{successMessage}}</coreui-notification>
<app-ae-modal-info></app-ae-modal-info>
<div class="container-fluid my-3">
  <div class="row">
    <div class="col col-sm-6 col-xl-3">
      <!--New search component-->
      <app-ae-table-search
        label="Search"
        placeholder="Search"
        (searchEmitter)="handleSearch($event)"
        #searchComponent>
        </app-ae-table-search>
    </div>
    <div class="col-auto ml-lg-auto mb-4 mt-lg-4" *ngIf="authorizationService.isAllowed('USR', ['CREATE'])">
      <div *ngIf="showDropDownOption" >
        <coreui-menu buttonLabel="{{messages.createNewUser}}" buttonType="primary" menuButtonStype="button">
          <coreui-menu-selection>
            <button type="button" coreUIMenuItem (click)="handleAddAPIUserAction()"
              *ngIf="authorizationService.isAllowed('USR', ['CREATE'])">{{messages.apiUser}}</button>
            <button type="button" coreUIMenuItem (click)="handleAddUIUserAction()"
                *ngIf="authorizationService.isAllowed('USR', ['CREATE'])">{{messages.uiUser}}</button>
          </coreui-menu-selection>
        </coreui-menu>
      </div>
      <div *ngIf="!showDropDownOption">
        <app-ae-button
          buttonText="Add User"
          (click)="handleAddUIUserAction()"
        ></app-ae-button>
      </div>
    </div>
    <div *ngIf="authorizationService.isAllowed('USR', ['READ'])">
      <div *ngIf="userType===constants.UI">
        <ng-container *ngIf="isConfigureUserModalDisplayed()">
          <app-users-configuration
            [action]="getAction()"
            [configuredUsers]="getConfiguredUsers()"
            [userGroupInfo]="userGroupInfo"
            [id]="getId()"
            (isModalDisplayed)="setConfigureUserModalDisplayStatus($event)"
            (configuredUser)="captureConfiguredUserFromModal($event)"
          ></app-users-configuration>
        </ng-container>
      </div>
      <div *ngIf="userType===constants.API">
        <ng-container *ngIf="isConfigureUserModalDisplayed()">
          <app-api-user-configuration
            [action]="getAction()"
            [id]="getId()"
            [apiUsers]="getConfiguredUsers()"
            (isModalDisplayed)="setConfigureUserModalDisplayStatus($event)"
            (apiUser)="captureAPIUserFromModal($event)"
          ></app-api-user-configuration>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid my-3">
  <div class="row" >
    <div class="col-12">
      <app-ae-table [isDataLoading]="isDataLoading" [properties]="properties"
      [configuredTableData]="filteredUsers" (actionClick)="actionClick($event)">
      </app-ae-table>
    </div>
  </div>
</div>
<ng-template #lastLoginDate let-rowData>
  {{ rowData.lastLoginDate | date:'MM/dd/yyyy' }}
</ng-template>
<ng-template #startDate let-rowData>
  {{ rowData.startDate | date:'MM/dd/yyyy' }}
</ng-template>
<ng-template #createdDate let-rowData>
  {{ rowData.createdDate | date:'MM/dd/yyyy' }}
</ng-template>
<ng-template #modifiedDate let-rowData>
  {{ rowData.modifiedDate | date:'MM/dd/yyyy' }}
</ng-template>