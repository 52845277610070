import { IdNameTuple } from "../idNameTuple";

export class GroupInfos {
  name: string;
  share: number;
  primaryModel : IdNameTuple;
  fallbackModel : IdNameTuple;
  public constructor(name: string, share: number) {
    this.name = name;
    this.share = share;
  }
}
