import { DataTypes } from '../../conditions/data-types';

export class Attribute {

  public name: string;
  public path: string;
  public dataType: DataTypes;

  public isAttributeOfDateType(): boolean {
    if (this.dataType === DataTypes.date) {
      return true;
    }
    const nameLowerCase = this.name.toLocaleLowerCase();
    if (nameLowerCase.includes('time') || nameLowerCase.includes('date')) {
      return true;
    }
    return false;
  }

}