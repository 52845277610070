export class EntityStateLookup {

  parentId: string;
  companyId: string;
  key: string;

  public constructor(parentId: string, companyId: string, key: string) {
    this.parentId = parentId;
    this.companyId = companyId;
    this.key = key;
  }

}