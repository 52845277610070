import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { EntityStatesNavComponent } from './entitiystates-left-nav/entity-states-nav.component';
import { EntityStatesPropertiesComponent } from './entity-states-properties/entity-states-properties.component';
import { EntityStatesSchemaComponent } from './entity-states-schema/entity-states-schema.component';
import { EntityStatesHomeComponent } from './entity-states-home/entity-states-home.component';
import { EntityStatesConnectionsComponent } from './entity-states-connections/entity-states-connections.component';
import { UnsavedChangesGuard } from 'src/app/shared/guard/unsaved-changes.guard';


const routes: Routes = [
  {
    path: 'entitystates',
    canActivate: [AuthGuard],
    component: EntityStatesHomeComponent
  },
  {
    path: 'entitystates/system/configure/:id/:type/:action',
    canActivate: [AuthGuard],
    component: EntityStatesNavComponent,
    children: [
      { path: 'properties', component: EntityStatesPropertiesComponent },
      { path: 'schema', component: EntityStatesSchemaComponent },
      { path: 'connections', component: EntityStatesConnectionsComponent },
      { path: '', redirectTo: 'properties', pathMatch: 'prefix' }
    ]
  },
  {
    path: 'entitystates/custom/configure/:id/:type/:action',
    canActivate: [AuthGuard],
    component: EntityStatesNavComponent,
    canDeactivate: [UnsavedChangesGuard],
    children: [
      { path: 'properties', component: EntityStatesPropertiesComponent },
      { path: 'schema', component: EntityStatesSchemaComponent },
      { path: 'connections', component: EntityStatesConnectionsComponent },
      { path: '', redirectTo: 'properties', pathMatch: 'prefix' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntityStatesRoutingModule { }