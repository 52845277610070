import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { RuleBuilderService } from 'src/app/shared/services/rule-builder.service';
import { ContainerActionElement } from 'src/app/shared/models/custom-rule/actions/container-action-element';
import { DropArea } from 'src/app/shared/models/custom-rule/conditions/drop-area';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Action } from 'src/app/shared/models/action';
import { RuleActionComponent } from '../rule-action/rule-action.component';
import { ActionElement } from 'src/app/shared/models/custom-rule/actions/action-element';
import { TreeNode } from '../../models/custom-rule/tree-node';
import { SourceType } from '../../models/custom-rule/conditions/source-type';
import { ConfiguredEventDetails } from 'src/app/shared/models/configured-event-details';
import { EntityTypes } from 'src/app/shared/constants';
import { ConfiguredEntityState } from '../../models/entity-states/configured-entity-state';
import { EntityStateSchema } from '../../models/entity-states/entity-state-schema';
import { EntityType } from '../../models/entity-type';
import { ConfiguredRule } from '../../models/configured-rule.model';
import { ActionType } from '../../models/action-type';

@Component({
  selector: 'app-rule-action-builder',
  templateUrl: './rule-action-builder.component.html',
  styleUrls: ['./rule-action-builder.component.scss']
})
export class RuleActionBuilderComponent implements OnInit {

  @ViewChildren(RuleActionComponent) ruleActionComponents: QueryList<RuleActionComponent>;

  @Input() public formGroup: UntypedFormGroup;
  @Input() public eventSources: EventSource[];
  @Input() public configuredEventDetails: ConfiguredEventDetails[];
  @Input() private filteredConfiguredActions: Action[] = [];
  @Input() private actionDestinations = [];
  @Input() public isReadOnlyRule = false;
  @Input() public showDeleteButton: boolean;
  @Input() public validationRequired: boolean;
  @Input() public selectedEventSchema: string;
  @Input() public configuredRule: ConfiguredRule;

  public rightPaneActions: ContainerActionElement[];
  public droparea = DropArea;

  constructor(private ruleBuilder: RuleBuilderService) { }

  ngOnInit() {
    this.rightPaneActions = [];
    this.formGroup.addControl('actions', new UntypedFormArray([]));
    this.setRightPanelActions(this.configuredRule);
  }

  private setRightPanelActions(configuredRule: ConfiguredRule) {
    if (configuredRule.getActions()) {
      configuredRule.getActions().forEach(action => {
        const configuredActionPayload = this.getConfiguredAction(action, this.filteredConfiguredActions);
        if (this.isValidConfiguredAction(configuredActionPayload)) {
          const configuredAction = new Action(configuredActionPayload);
          if (this.configuredRule.isRuleTypePackaged() && action.actionType === ActionType.SCHEDULED_EVENT) {
            configuredAction.actionType = ActionType.SCHEDULED_EVENT;
          }
          configuredAction.setActionDelay(action.actionDelay);
          const configuredActionDestination = this.getConfiguredActionDestination(configuredAction, this.actionDestinations);
          const placeHoldList = configuredAction.getDynamicActionValueforRule();
          this.rightPaneActions.push({
            configuredAction: configuredAction,
            configuredActionDestination: configuredActionDestination,
            placeholders: placeHoldList,
            actionSettingsInput: action.actionSettingsInput,
            mode: configuredAction.getMode(),
            actionInputs: action.actionInputs
          });
        }
      });
    }
  }

  public canDropOnAction(attribute) {
    return this.isNodeOfTypeAction(attribute.data);
  }

  private isNodeOfTypeAction(attribute: TreeNode) {
    return attribute.nodeType === SourceType.ACTION;
  }

  public onActionDropped($event) {
    if (this.rightPaneActions.length < this.ruleBuilder.MAX_ALLOWED_ACTIONS) {
      const selectedConfiguredAction = new Action(this.filteredConfiguredActions.find(configuredAction => configuredAction.name === $event.element.data.name));
      const selectedConfiguredActionDestination = selectedConfiguredAction.entityType === EntityTypes.PACKAGED ? this.actionDestinations.find(actionDestination => actionDestination.actionDestination === selectedConfiguredAction.actionProvider) :
        this.actionDestinations.find(actionDestination => actionDestination.actionDestination === selectedConfiguredAction.actionDestinationId);
      const placeHoldList = selectedConfiguredAction.getDynamicActionValueforRule();
      this.rightPaneActions.push({
        configuredAction: selectedConfiguredAction,
        configuredActionDestination: selectedConfiguredActionDestination,
        placeholders: placeHoldList,
        actionSettingsInput: null,
        mode: null,
        actionInputs: null
      });
    }
    this.ruleBuilder.toggleDropArea($event, DropArea.ACTION, this.rightPaneActions.length, false);
  }

  public pushActionToController(action: UntypedFormGroup) {
    this.getActionController().push(action);
  }

  public onDeleteAction(index) {
    this.rightPaneActions.splice(index, 1);
    this.getActionController().removeAt(index);
  }

  public getActionController(): UntypedFormArray {
    return this.formGroup.get('actions') as UntypedFormArray;
  }

  public initRightPaneActions() {
    this.rightPaneActions = [];
    const actionController = this.getActionController();
    if (actionController) {
      actionController.clear();
    }
  }

  public buildActions(): ActionElement[] {
    const actionsPayload: ActionElement[] = [];
    const actionComponentsArray = this.ruleActionComponents.toArray();
    for (const index in this.rightPaneActions) {
      const apiAction: ActionElement = actionComponentsArray[index].buildAction();
      actionsPayload.push(apiAction);
    }
    return actionsPayload;
  }

  public validateActions(): boolean {
    let validationStatus = true;
    const actionComponentsArray = this.ruleActionComponents.toArray();
    for (const index in this.rightPaneActions) {
      validationStatus = validationStatus && actionComponentsArray[index].validate();
    }
    return validationStatus;
  }

  public getEntityStateSchemasUsedInActions(entityStateList: ConfiguredEntityState[]): EntityStateSchema[] {
    const entityStateSchemasUsedInActions: EntityStateSchema[] = [];
    const actionsBuilders = this.ruleActionComponents.toArray();
    if (!actionsBuilders) {
      return [];
    }
    const actions: ActionElement[] = actionsBuilders.map(actionsBuilder => actionsBuilder.buildAction());
    actions.forEach(action => entityStateSchemasUsedInActions.push(...this.getEntityStateSchemasUsedInAction(action, entityStateList)));
    const uniqueEntityStateSchemasUsedInActions = this.getUniqueEntityStates(entityStateSchemasUsedInActions);
    return uniqueEntityStateSchemasUsedInActions;
  }

  private getEntityStateSchemasUsedInAction(action: ActionElement, entityStateList: ConfiguredEntityState[]): EntityStateSchema[] {
    const entityStateSchemasUsedInAction: EntityStateSchema[] = [];
    entityStateList.forEach((entityState: ConfiguredEntityState) => {
      const serviceInput: string = action.actionSettingsInput['serviceInput'];
      if (serviceInput.includes(entityState.id)) {
        const entityStateSchema: EntityStateSchema = new EntityStateSchema();
        entityStateSchema.id = entityState.id;
        entityStateSchema.name = entityState.name;
        entityStateSchema.key = entityState.entityStateKey;
        entityStateSchema.isSystem = entityState.type === EntityType.PACKAGED;
        entityStateSchemasUsedInAction.push(entityStateSchema);
      }
    });
    return entityStateSchemasUsedInAction;
  }

  private getUniqueEntityStates(entityStateSchemaList: EntityStateSchema[]): EntityStateSchema[] {
    const entityStateIdAndEntityStateSchemaMap: Map<string, EntityStateSchema> = new Map();
    entityStateSchemaList.forEach(entityState => entityStateIdAndEntityStateSchemaMap.set(entityState.id, entityState));
    const uniqueEntityStateSchemaList: EntityStateSchema[] = Array.from(entityStateIdAndEntityStateSchemaMap.values());
    return uniqueEntityStateSchemaList;
  }

  private getConfiguredAction(action: ActionElement, configuredActions: Action[]): Action {
    // locate packaged action
    let configuredActionPayload = configuredActions.find(configuredAction =>
      configuredAction.serviceId && configuredAction.serviceId === action['serviceId']);
    if (configuredActionPayload) {
      return new Action(configuredActionPayload);
    }
    // locate custom action
    configuredActionPayload = configuredActions.find(configuredAction => configuredAction.id === action['service']);
    if (configuredActionPayload) {
      return new Action(configuredActionPayload);
    }
  }

  private isValidConfiguredAction(configuredAction: Action): boolean {
    if (!configuredAction) {
      return false;
    }
    return configuredAction.isValid();
  }

  private getConfiguredActionDestination(configuredAction: Action, actionDestinations: unknown[]): unknown {
    if (configuredAction.entityType === EntityTypes.PACKAGED) {
      return this.actionDestinations.find(actionDestination => actionDestination['actionDestination'] === configuredAction.actionProvider);
    }
    return actionDestinations.find(actionDestination => actionDestination['actionDestination'] === configuredAction.actionDestinationId);

  }

}
